import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  singleBatch: {},
  loading: true,
  error: '',
  message: '',
}

export const editSingleBatch = createAsyncThunk(
  'editSingleBatch',
  async ({ editId, formData }, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/batches/${editId}`,
        formData,
        {
          headers: {
            Accept: '/',
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      console.log('id from editBatchSlice ', editId)
      console.log('formData from editBatchSlice ', formData)
      console.log('res.data from editBatchSlice  ', res.data)
      return res.data
    } catch (error) {
      console.log(error)
      //@ts-ignore
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetMessageinEditBatch = createAction('resetMessageinEditBatch')
export const resetErrorinEditBatch = createAction('resetErrorinEditBatch')

const editSingleBatchSlice = createSlice({
  name: 'editSingleBatch',
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [editSingleBatch.pending]: (state, action) => {
      state.loading = true
    },
    //@ts-ignore
    [editSingleBatch.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleBatch, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        console.log(
          'this is singleBatch object from edit Batch slice ==>> ',
          singleBatch
        )
        state.message = message
        state.singleBatch = singleBatch
        console.log('this is message from the fulfiled condition', message)
      }
    },
    //@ts-ignore
    [editSingleBatch.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.message = message
      console.log(
        'this is message from reject in edit batch slice ==> ',
        message
      )
      console.log('this is error from reject in edit batch slice ==> ', error)
    },

    [resetErrorinEditBatch]: state => {
      state.error = ''
    },
    [resetMessageinEditBatch]: state => {
      state.message = ''
    },
  },
})

export default editSingleBatchSlice.reducer
