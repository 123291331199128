import moment from 'moment'
import { Select } from 'antd'
import TimeAgo from 'react-timeago'
import Linkify from 'react-linkify'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import {
  Col,
  // Row,
  Button,
  // Modal
} from 'react-bootstrap'
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const readCookie = require('../cookie.js').readCookie
const TODAY = new Date()

const {
  Option,
  // OptGroup
} = Select

class Comment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: this.props.comment,
    }
  }
  formatter(x, y, z) {
    if (y === 'second') return 'few seconds ago'
    else if (x === 1) return x + ' ' + y + ' ' + z
    else return x + ' ' + y + 's ' + z
  }
  deleteCommentByid(id) {
    Swal.fire({
      title: 'Are you sure?',
      html: "<h4 style='color:red; text-align:center; font-size:16px'>It is not recommended to delete an existing comment. Deleting the comment would remove the interaction that took place between you and the aspirant.<br/>Are you sure you still wish to proceed? You cannot reverse this action.</h4>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/swati-lead-comments/delete/' +
            id,
          {
            method: 'DELETE',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              let comment = this.state.comment
              comment['isDeleted'] = true
              this.setState({ comment })
              //this.props.onCommentChange(comment);
              toast.success('Comment deleted successfully!')
            } else {
              toast.error(`Coudn't delete comment`)
            }
          })
      }
    })
  }
  render() {
    return (
      <div
        style={
          window.screen.width < 500
            ? { width: '100%', overflow: 'scroll' }
            : null
        }
      >
        {' '}
        {!this.state.comment.isDeleted && (
          <div className="comment">
            <div className="comment-user-image-container">
              {this.state.comment.createdBy.fullName ? (
                <img
                  className="comment-user"
                  alt={this.state.comment.createdBy.fullName}
                  src={this.state.comment.createdBy.profilePicture}
                  onError={event =>
                    event.target.setAttribute('src', '/images/user.png')
                  }
                  title={this.state.comment.createdBy.fullName}
                />
              ) : (
                <img
                  className="comment-user"
                  alt={this.state.comment.createdBy.ngoName}
                  src={this.state.comment.createdBy.logo}
                  onError={event =>
                    event.target.setAttribute('src', '/images/ngo.png')
                  }
                  title={this.state.comment.createdBy.ngoName}
                />
              )}
            </div>
            <div className="dialogbox">
              <div className="body">
                <span className="tip tip-left"></span>
                <div className="type-followup-container">
                  <span className="type">{this.state.comment.type}</span>
                  {this.state.comment.followUp ? (
                    <span className="follow-up">
                      <b>Follow Up Date:</b>{' '}
                      {moment(this.state.comment.followUp.date).format(
                        'MMM DD, YYYY'
                      )}
                    </span>
                  ) : null}
                </div>
                <div className="comment-content">
                  <div className="message">
                    <Linkify properties={{ target: '_blank' }}>
                      <span>{this.state.comment.body}</span>
                    </Linkify>
                    {this.state.comment.files &&
                    this.state.comment.files.length ? (
                      <div className="files">
                        {this.state.comment.files.map((file, index) => {
                          if (index) {
                            return (
                              <span>
                                ,{' '}
                                <a
                                  key={index}
                                  className="file"
                                  href={file}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  {index ? 'File ' + (index + 1) : 'File'}
                                </a>
                              </span>
                            )
                          } else {
                            return (
                              <a
                                key={index}
                                className="file"
                                href={file}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {index ? 'File ' + (index + 1) : 'File'}
                              </a>
                            )
                          }
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      this.state.comment.body
                        ? 'timestamp'
                        : 'timestamp timestamp-flex'
                    }
                  >
                    {this.state.comment.createdBy.fullName ? (
                      <div>{this.state.comment.createdBy.fullName}</div>
                    ) : this.state.comment.createdBy.ngoName ? (
                      <div>{this.state.comment.createdBy.ngoName}</div>
                    ) : null}
                    <span>
                      <TimeAgo
                        date={moment(this.state.comment.createdAt).toDate()}
                        formatter={this.formatter}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={
                window.screen.width < 500
                  ? { marginLeft: 20, marginTop: 10 }
                  : { marginTop: '10px' }
              }
            >
              {this.state.comment.createdBy._id ===
                JSON.parse(readCookie('userData')).id && (
                <Button
                  variant="danger"
                  onClick={this.deleteCommentByid.bind(
                    this,
                    this.state.comment._id
                  )}
                >
                  {' '}
                  DELETE{' '}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

class CommentList extends Component {
  render() {
    return (
      <div className="comment-list">
        {this.props.comments.map(function (comment, index) {
          if (!comment.isDeleted) {
            return <Comment key={index} comment={comment} />
          } else return null
        })}
      </div>
    )
  }
}

class CommentForm extends Component {
  render() {
    return (
      <div className="comment-form">
        <div className="row form d-flex">
          <div
            className="form-group col-lg-4 col-sm-12 col-xs-12 p-2 m-0"
            // style={{ width: '200px' }}
          >
            <label className="control-label is-imp">Status</label>
            <Select
              style={{ width: '100%' }}
              placeholder="Select Status"
              value={this.props.comment.type}
              onChange={this.props.onCommentChange.bind(this, 'type')}
            >
              <Option value="Called & Received" title="Called & Received">
                Called & Received
              </Option>
              <Option
                value="Called but No Response"
                title="Called but No Response"
              >
                Called but No Response
              </Option>
              <Option value="Visited" title="Visited">
                Visited
              </Option>
              <Option value="General Notes" title="General Notes">
                General Notes
              </Option>
            </Select>
          </div>
          <div className="form-group col-lg-4 col-sm-6 col-xs-6 p-2 m-0">
            <label className="control-label">Upload File(s)</label>
            <input
              className="form-control file-input"
              type="file"
              multiple
              onChange={this.props.onCommentChange.bind(this, 'files')}
            />
          </div>
          <div className="form-group col-lg-4 col-sm-12 col-xs-12 p-2 m-0">
            <label className="control-label is-imp">Follow Up</label>
            <div
              className="follow-up-container"
              style={{ width: '100%', height: '100%' }}
            >
              {/* <input className="form-control" type="number" min={1} max={12} value={this.props.comment.followUp.value} onChange={this.props.onCommentChange.bind(this, 'followUpValue')} />
							<Select style={{ width: '50%' }} placeholder="Select Unit" value={this.props.comment.followUp.unit} onChange={this.props.onCommentChange.bind(this, 'followUpUnit')}>
								<Option value="days" title="Day(s)">Day(s)</Option>
								<Option value="weeks" title="Week(s)">Week(s)</Option>
								<Option value="months" title="Month(s)">Month(s)</Option>
								<Option value="years" title="Year(s)">Year(s)</Option>
							</Select> */}
              <DatePicker
                // selected={interactionDetails !== false ? new Date(interactions[interactionDetails].date) : startDate}
                className="datePicker"
                minDate={TODAY}
                onChange={this.props.onCommentChange.bind(this, 'followUpDate')}
                selected={this.props.comment.followUp.date}
                dateFormat={'dd/MM/yyyy'}
                style={{ width: '100%', height: '100%' }}
                // maxDate={TODAY} followUpDate
                // onChange={(e) => handleDateChange(e, "date")}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label">Description</label>
          <textarea
            className="comment-input"
            placeholder="Description"
            value={this.props.comment.body}
            onChange={this.props.onCommentChange.bind(this, 'body')}
          ></textarea>
        </div>
        <button
          className="btn btn-success react-bs-table-add-btn post-comment"
          onClick={this.props.onCommentSubmit}
          disabled={this.props.saving}
        >
          POST
        </button>
      </div>
    )
  }
}

export default class SingleLeadComments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      addComment: false,
      comments: this.props.comments,
      comment: {
        body: '',
        type: '',
        files: null,
        followUp: { value: '', unit: 'days', date: '' },
      },
    }
  }

  handleCommentSubmit() {
    let comment = this.state.comment

    if (
      comment.type &&
      // comment.followUp.value && comment.followUp.unit
      // &&
      comment.followUp.date
    ) {
      this.setState({ saving: true })
      let formData = new FormData()
      formData.append('body', comment.body)
      formData.append('type', comment.type)
      formData.append('followUp', JSON.stringify(comment.followUp))
      if (comment.files) {
        for (let x = 0; x < comment.files.length; x++) {
          formData.append('files', comment.files[x])
        }
      }

      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/swati-lead-comments/' +
          this.props.leadId,
        {
          method: 'POST',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
          body: formData,
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.props.newCommentAdded(comment)
            this.setState({ comments: data.comments, saving: false })
            this.toggleCommentForm()
          } else {
            this.notifyError(data.errorMsg)
            this.setState({ saving: false })
          }
        })
    } else {
      this.setState({ saving: false })
      this.notifyError('Please fill all the required items')
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  commentChange = (type, value) => {
    let comment = this.state.comment

    if (value.target) {
      if (value.target.files) value = value.target.files
      else value = value.target.value
    }

    if (type === 'followUpValue') comment['followUp']['value'] = value
    else if (type === 'followUpUnit') comment['followUp']['unit'] = value
    else if (type === 'followUpDate')
      comment['followUp']['date'] = new Date(value)
    else comment[type] = value

    this.setState({ comment })
  }

  toggleCommentForm = () => {
    this.setState({
      addComment: !this.state.addComment,
      comment: {
        body: '',
        type: '',
        files: null,
        followUp: { value: '', unit: '' },
      },
    })
  }

  render() {
    return (
      <Col md={12} className="tab-container">
        {!this.state.comments.length ? (
          <div className="no-comments-container">
            <span>No Comments yet</span>
          </div>
        ) : null}
        <div className="comment-box">
          <CommentList
            comments={this.state.comments.filter(comment => !comment.isDeleted)}
          />
          {this.state.addComment ? (
            <CommentForm
              comment={this.state.comment}
              onCommentChange={this.commentChange}
              onCommentSubmit={this.handleCommentSubmit.bind(this)}
              saving={this.state.saving}
            />
          ) : (
            <Col md={12} className="text-center">
              <button
                className="btn btn-success mt15 add-comment-btn"
                onClick={this.toggleCommentForm}
              >
                Add Comment
              </button>
            </Col>
          )}
        </div>
      </Col>
    )
  }
}
