import {
  Col,
  Row,
  Button,
  // Modal
} from 'react-bootstrap'
import React, { Component } from 'react'
import {
  // Steps,
  Select,
  Icon,
  DatePicker,
} from 'antd'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'
import { serialize } from 'object-to-formdata'

const readCookie = require('../cookie.js').readCookie
const specializationsOfEducation = [
  'Agriculture',
  'Architecture',
  'Arts',
  'Business',
  'Commerce',
  'Computer Application',
  'Dentistry',
  'Design',
  'Economics',
  'Education',
  'Engineering',
  'Environment',
  'Language',
  'Law',
  'Liberal Arts',
  'Medicine',
  'Multimedia',
  'Nursing',
  'Nutrition',
  'Performing Arts',
  'Pharmacy',
  'Physiotherapy',
  'Sciences',
  'Social Work',
  'Tourism',
]

const // { Step } = Steps,
  mobileRegex = /^[6-9]\d{9}$/,
  altMobileRegex = /^[0-9]*$/,
  fullNameRegex = /^[a-zA-Z0-9()\/\s\.]+$/
const { Option, OptGroup } = Select

export default class AddNewLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNumberStatus: '',
      leadData: {
        leadInfo: {
          // userType: props.userData.userType._id || "",
          // assignTo: props.userData._id || "",
        },
        basic: {
          mobileNumber: '',
          name: '',
          address: {
            city: '',
            line1: '',
            line2: '',
            state: '',
            district: '',
            block: '',
            region: '',
            country: 'India',
          },
          nativeOf: {
            city: '',
            state: '',
            district: '',
          },
          // phase: '',
          // creditLinked: '',
          gender: '',
          pincode: '',
          source: 'Agent',
          altMobileNumber: '',
          generatedThrough: '',
          numberOfFamilyMember: '',
          numberOfDependents: '',
          educationalQualification: '',
          religion: '',
          category: '',
          pwd: '',
          dob: '',
          identityNumber: '',
          identityType: [],
          candidateConsentDoc: '',
          sidbiMilestone: '',
        },
      },
      errorObj: {},
      allUsers: [],
      isIvrs: false,
      ivrsId: '',
      allStates: [],
      allCities: [],
      allDistricts: [],
      allBlocks: [],
      allUserTypes: [],
      savingLead: false,
      allNativeCities: [],
      allNativeDistricts: [],
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allStates: data.data.states })
        }
      })

    fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-types', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allUserTypes: data.userTypes })
        }
      })

    if (JSON.parse(readCookie('userData')).type === 'partners') {
      let leadData = this.state.leadData,
        leadInfo = {
          assignTo: JSON.parse(readCookie('userData')).udaan
            ? JSON.parse(readCookie('userData')).udaan
            : JSON.parse(readCookie('userData')).ngo._id,
          userType: 'swavalamban-connect-kendra',
        }
      leadData['leadInfo'] = leadInfo
      this.setState({ leadData })
    }

    if (this.props.match.params.ivrsId) {
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/ivrs/' +
          this.props.match.params.ivrsId,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            let ivrsData = data.ivrs,
              leadData = this.state.leadData,
              mobileNumberStatus = '',
              errorObj = this.state.errorObj
            if (ivrsData.mobile.match(/^0/) !== null)
              ivrsData.mobile = ivrsData.mobile.slice(1)
            leadData.basic.mobileNumber = ivrsData.mobile
            if (ivrsData.pincode) leadData.basic.pincode = ivrsData.pincode

            if (ivrsData.mobile.match(mobileRegex) === null) {
              errorObj['mobileNumber'] = 'Invalid Mobile Number!'
              this.setState({ errorObj })
            } else {
              this.fetchMobileNumberData(ivrsData.mobile, ivrsData)
            }
          }
        })
    }
  }

  fetchMobileNumberData = (mobileNumber, ivrsData = {}) => {
    let leadData = this.state.leadData,
      mobileNumberStatus = '',
      errorObj = this.state.errorObj

    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/swati-leads/check-mobile?mobileNumber=' +
        mobileNumber,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          if (data.leadBasicDetails) {
            mobileNumberStatus = 'ok'
            delete errorObj.mobileNumber
            leadData = { basic: data.leadBasicDetails, leadInfo: {} }
            this.setState({ mobileNumberStatus, errorObj, leadData })
          } else if (data.ivrsId) {
            mobileNumberStatus = 'ok'
            delete errorObj.mobileNumber
            if (ivrsData.isTrained)
              leadData.basic.generatedThrough = 'Already trained candidate'
            else if (ivrsData.tcacrId !== null && ivrsData.source)
              leadData.basic.generatedThrough = ivrsData.source
            else leadData.basic.generatedThrough = 'Toll-free call'
            this.setState({
              mobileNumberStatus,
              errorObj,
              leadData,
              isIvrs: true,
              ivrsId: data.ivrsId,
            })
          } else {
            mobileNumberStatus = 'ok'
            this.setState({ mobileNumberStatus })
          }
        } else {
          mobileNumberStatus = 'error'
          errorObj['mobileNumber'] = data.errorMsg
          this.setState({ mobileNumberStatus, errorObj })
          Swal.fire({
            title: 'Oops!',
            text: 'The mobile number already has a Lead.',
            type: 'error',
          }).then(res => {
            window.location.pathname = '/ivrs/leads'
          })
        }
      })
  }
  changeHandler = (x, y, e) => {
    let leadData = this.state.leadData,
      errorObj = this.state.errorObj
    if (x === 'basic' && y === 'city') {
      leadData[x]['address'][y] = e
      if (!leadData[x]['nativeOf'][y]) leadData[x]['nativeOf'][y] = e
    } else if (x === 'basic' && y === 'candidateConsentDoc') {
      var files = e.target.files
      if (files.length) leadData[x][y] = files[0]
      else leadData[x][y] = null
    } else if (x === 'basic' && y === 'block') {
      leadData[x]['address'][y] = e
      // if(!leadData[x]['nativeOf'][y]) leadData[x]['nativeOf'][y] = e;
    } else if (x === 'basic' && y === 'region') {
      leadData[x]['address'][y] = e
      // if(!leadData[x]['nativeOf'][y]) leadData[x]['nativeOf'][y] = e;
    } else if (x === 'basic' && y === 'addressLine1') {
      leadData[x]['address']['line1'] = e.target.value
    } else if (x === 'basic' && y === 'addressLine2') {
      leadData[x]['address']['line2'] = e.target.value
    } else if (x === 'basic' && (y === 'generatedThrough' || y === 'gender')) {
      leadData[x][y] = e
      // } else if (x === 'basic' && y === 'phase') {
      // 	leadData[x][y] = e;
    } else if (x === 'basic' && y === 'nativeCity') {
      leadData[x]['nativeOf']['city'] = e
    } else if (x === 'leadInfo' && y === 'userType') {
      leadData[x]['userType'] = e
      leadData['leadInfo']['assignTo'] = ''
      if (e === 'swavalamban-connect-kendra') {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) this.setState({ allUsers: data.ngos })
          })
      } else if (e === 'udaan') {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/udaan', {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) this.setState({ allUsers: data.udaan })
          })
      } else {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/' + e, {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) this.setState({ allUsers: data.users })
          })
      }
    } else if (e.hasOwnProperty('target')) {
      leadData[x][y] = e.target.value
    } else {
      if (y === 'creditLinked') {
        let text = ''
        if (e === 'Yes')
          text = 'The candidate will be disqualified in this program'
        Swal.fire({
          text,
          title: 'Are you sure about this?',
          focusConfirm: false,
          showCancelButton: true,
          cancelButtonText: 'No, Cancel',
          confirmButtonText: 'Yes, Change',
          cancelButtonAriaLabel: 'No, Cancel',
          confirmButtonAriaLabel: 'Yes, Change',
        }).then(result => {
          if (result.value) {
            leadData[x][y] = e
            if (errorObj.hasOwnProperty(y)) delete errorObj[y]
            this.setState({ leadData, errorObj })
          }
        })
      } else {
        leadData[x][y] = e
      }
    }
    if (errorObj.hasOwnProperty(y)) delete errorObj[y]
    this.setState({ leadData, errorObj })
  }

  checkMobileNumber = () => {
    let mobileNumber = this.state.leadData.basic.mobileNumber,
      mobileNumberStatus = '',
      errorObj = this.state.errorObj
    if (mobileNumber.match(mobileRegex) !== null) {
      delete errorObj.mobileNumber
      this.setState({ mobileNumberStatus: 'loading', errorObj })
      this.fetchMobileNumberData(mobileNumber)
      // fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/check-mobile?mobileNumber=' + this.state.leadData.basic.mobileNumber, {
      // 	method: 'GET',
      // 	headers: {
      // 		'x-auth-token': JSON.parse(readCookie('access_token'))
      // 	}
      // }).then(data => data.json())
      // 	.then(data => {
      // 		if (data.status) {
      // 			if (data.ivrsId) window.location.pathname = "/ivrs-to-lead/" + data.ivrsId;
      // 			else {
      // 				mobileNumberStatus = 'ok';
      // 				delete errorObj.mobileNumber;
      // 				this.setState({ mobileNumberStatus, errorObj });
      // 			}
      // 		} else {
      // 			mobileNumberStatus = 'error';
      // 			errorObj['mobileNumber'] = data.errorMsg;
      // 			this.setState({ mobileNumberStatus, errorObj });
      // 		}
      // 	});
    } else {
      mobileNumberStatus = 'error'
      errorObj['mobileNumber'] = 'Invalid Mobile'
      this.setState({ mobileNumberStatus, errorObj })
    }
  }

  handleStateDistrictChange = (type, value) => {
    var query = '',
      leadData = this.state.leadData,
      errorObj = this.state.errorObj
    if (type === 'district' && this.state.leadData.basic.address.state && value)
      query = `?stateId=${this.state.leadData.basic.address.state}&districtId=${value}`
    else if (type === 'state' && value) query = `?stateId=${value}`
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/location/get-address' + query,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          if (type === 'state') {
            leadData.basic.address.city = ''
            leadData.basic.address.block = ''
            leadData.basic.address.district = ''
            leadData.basic.address.state = value
            if (!leadData.basic.nativeOf.state) {
              leadData.basic.nativeOf.city = ''
              leadData.basic.nativeOf.district = ''
              leadData.basic.nativeOf.state = value
              this.setState({ allNativeDistricts: data.districts })
            }
            this.setState({
              allDistricts: data.districts,
              leadData,
              allCities: [],
            })
          } else if (type === 'district') {
            leadData.basic.address.city = ''
            leadData.basic.address.block = ''
            leadData.basic.address.district = value
            if (!leadData.basic.nativeOf.district) {
              leadData.basic.nativeOf.city = ''
              leadData.basic.nativeOf.district = value
              this.setState({ allNativeCities: data.cities })
            }
            this.setState({
              allCities: data.cities,
              allBlocks: data.blocks,
              leadData,
            })
          }
        }
      })
    if (errorObj.hasOwnProperty(type)) delete errorObj[type]
    this.setState({ errorObj })
  }

  handleNativeStateDistrictChange = (type, value) => {
    var query = '',
      leadData = this.state.leadData,
      errorObj = this.state.errorObj
    if (
      type === 'district' &&
      this.state.leadData.basic.nativeOf.state &&
      value
    )
      query = `?stateId=${this.state.leadData.basic.nativeOf.state}&districtId=${value}`
    else if (type === 'state' && value) query = `?stateId=${value}`

    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/location/get-address' + query,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          if (type === 'state') {
            leadData.basic.nativeOf.city = ''
            leadData.basic.nativeOf.district = ''
            leadData.basic.nativeOf.state = value
            this.setState({
              allNativeDistricts: data.districts,
              leadData,
              allNativeCities: [],
            })
          } else if (type === 'district') {
            leadData.basic.nativeOf.city = ''
            leadData.basic.nativeOf.district = value
            this.setState({ allNativeCities: data.cities, leadData })
          }
        }
      })
    if (type === 'district' && errorObj.hasOwnProperty('nativeDistrict'))
      delete errorObj['nativeDistrict']
    else if (type === 'state' && errorObj.hasOwnProperty('nativeState'))
      delete errorObj['nativeState']
    this.setState({ errorObj })
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === 'string') {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  save = cb => {
    this.setState({ savingLead: true }, () => {
      this.checkErrors(error => {
        if (error) {
          this.setState({ savingLead: false })
          if (cb && typeof cb === 'function') cb(false)
        } else {
          let leadData = JSON.parse(JSON.stringify(this.state.leadData))

          // if (leadData.basic.creditLinked === 'Yes') leadData.basic.creditLinked = true;
          // else leadData.basic.creditLinked = false;

          if (this.state.isIvrs) {
            if (
              this.state.leadData.basic.candidateConsentDoc &&
              typeof this.state.leadData.basic.candidateConsentDoc !== 'string'
            ) {
              let dataToSend = serialize(leadData)
              fetch(
                process.env.REACT_APP_API_URL +
                  '/api/v1/ivrs/convert/' +
                  (this.props.match.params.ivrsId || this.state.ivrsId),
                {
                  method: 'POST',
                  headers: {
                    // 'Content-Type': 'multipart/form-data',
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                  },
                  body: dataToSend,
                }
              )
                .then(response => {
                  return response.json()
                })
                .then(data => {
                  if (data.status) {
                    if (data.disqualified) {
                      Swal.fire({
                        title: 'Successfully converted to Lead!',
                        text: 'This candidate has already been linked to credit and is not qualified for the program',
                        type: 'success',
                      }).then(res => {
                        window.location.pathname = '/ivrs/leads'
                      })
                    } else {
                      this.notifySuccess('Successfully converted to Lead!')
                      if (cb && typeof cb === 'function') cb(data.leadId)
                      else
                        window.location.pathname = `/edit-lead/${data.leadId}`
                    }
                  } else {
                    this.setState({ savingLead: false })
                  }
                })
                .catch(err => {
                  console.log(err)
                  this.setState({ savingLead: false })
                })
            } else {
              fetch(
                process.env.REACT_APP_API_URL +
                  '/api/v1/ivrs/convert/' +
                  (this.props.match.params.ivrsId || this.state.ivrsId),
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                  },
                  body: JSON.stringify(leadData),
                }
              )
                .then(response => {
                  return response.json()
                })
                .then(data => {
                  if (data.status) {
                    if (data.disqualified) {
                      Swal.fire({
                        title: 'Successfully converted to Lead!',
                        text: 'This candidate has already been linked to credit and is not qualified for the program',
                        type: 'success',
                      }).then(res => {
                        window.location.pathname = '/ivrs/leads'
                      })
                    } else {
                      this.notifySuccess('Successfully converted to Lead!')
                      if (cb && typeof cb === 'function') cb(data.leadId)
                      else
                        window.location.pathname = `/edit-lead/${data.leadId}`
                    }
                  } else {
                    this.setState({ savingLead: false })
                  }
                })
                .catch(err => {
                  console.log(err)
                  this.setState({ savingLead: false })
                })
            }
          } else {
            if (
              this.state.leadData.basic.candidateConsentDoc &&
              typeof this.state.leadData.basic.candidateConsentDoc !== 'string'
            ) {
              leadData.candidateConsentDoc =
                this.state.leadData.basic.candidateConsentDoc
              let dataToSend = serialize(leadData)
              console.log(
                '🚀 ~ AddNewLead ~ this.checkErrors ~ leadData:',
                leadData
              )
              fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads', {
                method: 'POST',
                headers: {
                  // 'Content-Type': 'multipart/form-data',
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
                body: dataToSend,
              })
                .then(response => {
                  return response.json()
                })
                .then(data => {
                  if (data.status) {
                    if (data.disqualified) {
                      Swal.fire({
                        title: 'Successfully converted to Lead!',
                        text: 'This candidate has already been linked to credit and is not qualified for the program',
                        type: 'success',
                      }).then(res => {
                        window.location.pathname = '/ivrs/leads'
                      })
                    } else {
                      this.notifySuccess('Successfully added new Lead!')
                      if (cb && typeof cb === 'function') cb(data.leadId)
                      else
                        window.location.pathname = `/edit-lead/${data.leadId}`
                    }
                  } else {
                    this.setState({ savingLead: false })
                  }
                })
                .catch(err => {
                  console.log(err)
                  this.setState({ savingLead: false })
                })
            } else {
              fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
                body: JSON.stringify(leadData),
              })
                .then(response => {
                  return response.json()
                })
                .then(data => {
                  if (data.status) {
                    if (data.disqualified) {
                      Swal.fire({
                        title: 'Successfully converted to Lead!',
                        text: 'This candidate has already been linked to credit and is not qualified for the program',
                        type: 'success',
                      }).then(res => {
                        window.location.pathname = '/ivrs/leads'
                      })
                    } else {
                      this.notifySuccess('Successfully added new Lead!')
                      if (cb && typeof cb === 'function') cb(data.leadId)
                      else
                        window.location.pathname = `/edit-lead/${data.leadId}`
                    }
                  } else {
                    this.setState({ savingLead: false })
                  }
                })
                .catch(err => {
                  console.log(err)
                  this.setState({ savingLead: false })
                })
            }
          }
        }
      })
    })
  }

  checkErrors = cb => {
    let errorObj = this.state.errorObj,
      leadData = this.state.leadData,
      error = false

    if (
      leadData.basic.mobileNumber.match(mobileRegex) === null ||
      this.state.mobileNumberStatus !== 'ok'
    ) {
      errorObj['mobileNumber'] = 'Invalid Mobile Number!'
      error = true
    }
    if (leadData.basic.altMobileNumber) {
      if (leadData.basic.altMobileNumber.match(altMobileRegex) === null) {
        errorObj['altMobileNumber'] = 'Invalid Alt-Mobile Number!'
        error = true
      }
    }
    if (!leadData.basic.name || !fullNameRegex.test(leadData.basic.name)) {
      errorObj['name'] = 'Invalid Full Name!'
      error = true
    }
    if (!leadData.basic.address.state) {
      errorObj['state'] = 'Invalid State!'
      error = true
    }
    if (this.state.allDistricts.length && !leadData.basic.address.district) {
      errorObj['district'] = 'Invalid District!'
      error = true
    }
    if (this.state.allCities.length && !leadData.basic.address.city) {
      errorObj['city'] = 'Invalid City!'
      error = true
    }
    if (this.state.allBlocks.length && !leadData.basic.address.block) {
      errorObj['block'] = 'Invalid Block!'
      error = true
    }
    if (!leadData.basic.address.line1) {
      errorObj['addressLine1'] = 'Invalid Address Line 1!'
      error = true
    }
    if (!leadData.basic.address.region) {
      errorObj['region'] = 'Invalid region option selected!'
      error = true
    }
    // if (!leadData.basic.phase) { errorObj['phase'] = 'Invalid Phase!'; error = true; }
    // else if (leadData.basic.phase === 'Scale-up') {
    // 	if (!leadData.basic.creditLinked) { errorObj['creditLinked'] = 'Please select a correct value!'; error = true; }
    // }
    if (!leadData.basic.gender) {
      errorObj['gender'] = 'Invalid Gender!'
      error = true
    }
    if (
      !leadData.basic.pincode ||
      leadData.basic.pincode.toString().match(/^\d{6}$/) === null
    ) {
      errorObj['pincode'] = 'Invalid Pincode!'
      error = true
    }
    if (!leadData.basic.generatedThrough) {
      errorObj['generatedThrough'] = 'Invalid Generated Through!'
      error = true
    }
    // educationalQualification: '',
    // 	religion: '',
    // 		category: '',
    // 			pwd: '',

    // dob: '',
    // 	identityNumber: '',
    // 		identityType: [],
    // numberOfDependents
    // numberOfFamilyMember
    if (!leadData.basic.educationalQualification) {
      errorObj['educationalQualification'] =
        'Invalid educational qualification selected!'
      error = true
    }
    if (!leadData.basic.religion) {
      errorObj['religion'] = 'Invalid religion option selected!'
      error = true
    }
    if (!leadData.basic.category) {
      errorObj['category'] = 'Invalid category option selected!'
      error = true
    }
    if (!leadData.basic.pwd) {
      errorObj['pwd'] = 'Invalid option selected!'
      error = true
    }
    // if (!leadData.basic.dob) { errorObj['dob'] = 'Invalid option selected!'; error = true; }
    if (
      !leadData.basic.dob ||
      !moment(leadData.basic.dob).isValid() ||
      moment(leadData.basic.dob).year() < 1900 ||
      moment(leadData.basic.dob).year() > 2010
    ) {
      errorObj['dob'] = 'Invalid date of birth selected!'
      error = true
    }
    if (!leadData.basic.identityNumber) {
      errorObj['identityNumber'] = 'Invalid option selected!'
      error = true
    }
    if (!leadData.basic.identityType.length) {
      errorObj['identityType'] = 'Invalid option selected!'
      error = true
    }
    if (!leadData.basic.numberOfDependents) {
      errorObj['numberOfDependents'] = 'Invalid value entered!'
      error = true
    }
    if (!leadData.basic.numberOfFamilyMember) {
      errorObj['numberOfFamilyMember'] = 'Invalid value entered!'
      error = true
    }
    if (JSON.parse(readCookie('userData')).type !== 'partners') {
      if (!leadData.leadInfo.userType) {
        errorObj['userType'] = 'Invalid User Type!'
        error = true
      }
      if (!leadData.leadInfo.assignTo) {
        errorObj['assignTo'] = 'Invalid Assigned To!'
        error = true
      }
    }
    this.setState({ errorObj })
    cb(error)
  }

  render() {
    return (
      <div className="page-container add-new-lead-page">
        <h3 className="mb15 text-center">
          {this.props.match.params.ivrsId ? 'Convert Lead' : 'Add New Lead'}
        </h3>

        <h2 id="basic" className="step-heading">
          BASIC
        </h2>
        <Row className="step-container step-0">
          <Col md={3}>
            <label className="control-label is-imp">Mobile Number</label>
            <div className="mobile-number-input-container">
              <input
                type="text"
                placeholder="Enter Mobile Number"
                value={this.state.leadData.basic.mobileNumber}
                className={'form-control'}
                onChange={this.changeHandler.bind(
                  this,
                  'basic',
                  'mobileNumber'
                )}
                onBlur={this.checkMobileNumber}
                style={
                  this.state.errorObj.hasOwnProperty('mobileNumber')
                    ? { borderColor: '#F68787' }
                    : {}
                }
                disabled={this.props.match.params.ivrsId ? true : false}
              />
              {this.state.mobileNumberStatus ? (
                <div className="mobile-number-status-container">
                  {this.state.mobileNumberStatus === 'loading' ? (
                    <Icon type="loading" style={{ color: '#FBE633' }} />
                  ) : null}

                  {this.state.mobileNumberStatus === 'ok' ? (
                    <Icon type="check" style={{ color: '#78D245' }} />
                  ) : null}

                  {this.state.mobileNumberStatus === 'error' ? (
                    <Icon type="exclamation" style={{ color: '#F68787' }} />
                  ) : null}
                </div>
              ) : null}
            </div>
            {this.state.errorObj.hasOwnProperty('mobileNumber') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.mobileNumber}
              </div>
            ) : null}
          </Col>

          <Col md={3}>
            <label className="control-label is-imp">Full Name</label>
            <input
              type="text"
              placeholder="Enter Name"
              value={this.state.leadData.basic.name}
              className={'form-control'}
              onChange={this.changeHandler.bind(this, 'basic', 'name')}
              style={
                this.state.errorObj.hasOwnProperty('name')
                  ? { borderColor: '#F68787' }
                  : {}
              }
            />
            {this.state.errorObj.hasOwnProperty('name') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.name}
              </div>
            ) : null}
          </Col>

          <Col md={3}>
            <label className="control-label is-imp">State</label>
            <Select
              size="large"
              showSearch
              style={{ width: '100%' }}
              value={this.state.leadData.basic.address.state}
              onChange={this.handleStateDistrictChange.bind(this, 'state')}
              filterOption={(input, option) => this.searchFilter(input, option)}
            >
              <Option value="" disabled>
                Select State
              </Option>
              <OptGroup label="Focus States">
                {this.state.allStates.map((state, stateIndex) => {
                  if (state.inFocusRegion) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else return null
                })}
              </OptGroup>
              <OptGroup label="Not Focussed States">
                {this.state.allStates.map((state, stateIndex) => {
                  if (!state.inFocusRegion) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else return null
                })}
              </OptGroup>
            </Select>
            {this.state.errorObj.hasOwnProperty('state') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.state}
              </div>
            ) : null}
          </Col>

          {this.state.allDistricts.length ? (
            <Col md={3}>
              <label className="control-label is-imp">District</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                value={this.state.leadData.basic.address.district}
                onChange={this.handleStateDistrictChange.bind(this, 'district')}
                disabled={!this.state.leadData.basic.address.state}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="" disabled>
                  Select District
                </Option>
                {this.state.allDistricts.map((district, districtIndex) => {
                  return (
                    <Option key={districtIndex} value={district.name}>
                      {district.name}
                    </Option>
                  )
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty('district') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.district}
                </div>
              ) : null}
            </Col>
          ) : null}

          {this.state.allBlocks.length ? (
            <Col md={3}>
              <label className="control-label is-imp">Block</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                value={this.state.leadData.basic.address.block}
                onChange={this.changeHandler.bind(this, 'basic', 'block')}
                disabled={!this.state.leadData.basic.address.district}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="" disabled>
                  Select Block
                </Option>
                {this.state.allBlocks.map((block, blockIndex) => {
                  return (
                    <Option key={blockIndex} value={block.name}>
                      {block.name}
                    </Option>
                  )
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty('block') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.block}
                </div>
              ) : null}
            </Col>
          ) : null}

          {this.state.allCities.length ? (
            <Col md={3}>
              <label className="control-label is-imp">City</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                value={this.state.leadData.basic.address.city}
                onChange={this.changeHandler.bind(this, 'basic', 'city')}
                disabled={!this.state.leadData.basic.address.district}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="" disabled>
                  Select City
                </Option>
                {this.state.allCities.map((city, cityIndex) => {
                  return (
                    <Option key={cityIndex} value={city.name}>
                      {city.name}
                    </Option>
                  )
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty('city') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.city}
                </div>
              ) : null}
            </Col>
          ) : null}

          <Col md={this.state.allCities.length ? 9 : 12}>
            <label className="control-label is-imp">Address Line 1</label>
            <input
              type="text"
              placeholder="Enter Address Line 1"
              value={this.state.leadData.basic.address.line1}
              className={'form-control'}
              onChange={this.changeHandler.bind(this, 'basic', 'addressLine1')}
              style={
                this.state.errorObj.hasOwnProperty('addressLine1')
                  ? { borderColor: '#F68787' }
                  : {}
              }
            />
            {this.state.errorObj.hasOwnProperty('addressLine1') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.addressLine1}
              </div>
            ) : null}
          </Col>

          <Col md={9}>
            <label className="control-label">Address Line 2</label>
            <input
              type="text"
              placeholder="Enter Address Line 2"
              value={this.state.leadData.basic.address.line2}
              className={'form-control'}
              onChange={this.changeHandler.bind(this, 'basic', 'addressLine2')}
              style={
                this.state.errorObj.hasOwnProperty('addressLine2')
                  ? { borderColor: '#F68787' }
                  : {}
              }
            />
            {this.state.errorObj.hasOwnProperty('addressLine2') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.addressLine2}
              </div>
            ) : null}
          </Col>

          <Col md={3}>
            <label className="control-label is-imp">Pincode</label>
            <input
              type="text"
              placeholder="Pincode"
              value={this.state.leadData.basic.pincode}
              className={'form-control'}
              onChange={this.changeHandler.bind(this, 'basic', 'pincode')}
              style={
                this.state.errorObj.hasOwnProperty('pincode')
                  ? { borderColor: '#F68787' }
                  : {}
              }
              disabled={
                this.state.isIvrs &&
                this.state.leadData.basic.pincode.match(/^\d{6}$/)
                  ? true
                  : false
              }
            />
            {this.state.errorObj.hasOwnProperty('pincode') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.pincode}
              </div>
            ) : null}
          </Col>
          <Col md={3}>
            <label className="control-label is-imp">Region</label>
            <Select
              size="large"
              showSearch
              style={{ width: '100%' }}
              value={this.state.leadData.basic.address.region}
              onChange={this.changeHandler.bind(this, 'basic', 'region')}
              filterOption={(input, option) => this.searchFilter(input, option)}
            >
              <Option value="" disabled>
                Select Region
              </Option>

              {['Rural', 'Urban', 'Semi-Urban'].map((region, regionIndex) => {
                return (
                  <Option key={regionIndex} value={region}>
                    {region}
                  </Option>
                )
              })}
              {/* <input type="text" placeholder="Enter Region" value={this.state.leadData.basic.address.line1} className={'form-control'} onChange={this.changeHandler.bind(this, 'basic', 'addressLine1')} style={this.state.errorObj.hasOwnProperty('addressLine1') ? { borderColor: '#F68787' } : {}} /> */}
            </Select>
            {this.state.errorObj.hasOwnProperty('region') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.region}
              </div>
            ) : null}
          </Col>
          {
            //					<Col md={3}>
            //					<label className="control-label is-imp">Phase</label>
            //					<Select size="large" style={{ width: '100%' }} value={this.state.leadData.basic.phase} onChange={this.changeHandler.bind(this, 'basic', 'phase')}>
            //						<Option value="" disabled>Select Phase</Option>
            //						{/* <Option value="Start-up">Start-up</Option>
            //						<Option value="Scale-up">Scale-up</Option> */}
            //						<Option value="Start-up">Wants to start a new business</Option>
            //						<Option value="Scale-up">Runs a business, Wants to grow it</Option>
            //					</Select>
            //					{this.state.errorObj.hasOwnProperty('phase') ? (
            //						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.phase}</div>
            //					) : (null)}
            //				</Col>
          }

          <Col md={3}>
            <label className="control-label is-imp">Generated Through</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.generatedThrough}
              onChange={this.changeHandler.bind(
                this,
                'basic',
                'generatedThrough'
              )}
              disabled={this.state.isIvrs ? true : false}
            >
              <Option value="" disabled>
                Select Lead Generation Channel
              </Option>
              <Option value="Call">Call</Option>
              <Option value="Visit">Visit</Option>
              <Option value="Referral">Referral</Option>
              <Option value="Activations">Activations</Option>
              <Option value="Toll-free call">Toll-free call</Option>
              <Option value="Social Media" title="Social Media">
                Social Media
              </Option>
              <Option value="DIC" title="DIC">
                DIC
              </Option>
              {/* KVIC
																	KVIB
																	Coir Board
																	Municipality
																	SLBC */}
              <Option value="KVIB" title="KVIB">
                KVIB
              </Option>
              <Option value="KVIC" title="KVIC">
                KVIC
              </Option>
              <Option value="Coir Board" title="Coir Board">
                Coir Board
              </Option>
              <Option value="Municipality" title="Municipality">
                Municipality
              </Option>
              <Option value="SLBC" title="SLBC">
                SLBC
              </Option>
              <Option value="SBI PM SVANidhi" title="SBI PM SVANidhi">
                SBI PM SVANidhi
              </Option>
              <Option value="NULM-Sanctioned" title="NULM-Sanctioned">
                NULM-Sanctioned
              </Option>
              <Option
                value="NULM- Yet to be sanctioned"
                title="NULM- Yet to be sanctioned"
              >
                NULM- Yet to be sanctioned
              </Option>
              {/* <Option value="PMEGP- yet to be sanctioned" title="PMEGP- yet to be sanctioned">PMEGP- yet to be sanctioned</Option>	 */}
              <Option
                value="Bihar CM Udyami-Yojana"
                title="Bihar CM Udyami-Yojana"
              >
                Bihar CM Udyami-Yojana
              </Option>
              <Option value="PMEGP- Pending" title="PMEGP- Pending">
                PMEGP- Pending
              </Option>
              <Option value="PMEGP- Sanctioned" title="PMEGP- Sanctioned">
                PMEGP- Sanctioned
              </Option>
              <Option value="PMEGP- Rejected" title="PMEGP- Rejected">
                PMEGP- Rejected
              </Option>
              <Option
                value="Government Departments"
                title="Government Departments"
              >
                Government Departments
              </Option>
              <Option
                value="Customer Service Point (CSP)"
                title="Customer Service Point (CSP)"
              >
                Customer Service Point (CSP)
              </Option>
              <Option value="Volunteer" title="Volunteer">
                Volunteer
              </Option>
              <Option value="Others" title="Others">
                Others
              </Option>

              {/* 1. 
							2. 
							3.  */}
              {/* {this.state.leadData.basic.generatedThrough === 'Already trained candidate' ? (
								<Option value="Already trained candidate">Already trained candidate</Option>
							) : (null)}
							<Option value="Others">Others</Option> */}
            </Select>
            {this.state.errorObj.hasOwnProperty('generatedThrough') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.generatedThrough}
              </div>
            ) : null}
          </Col>

          <Col md={3}>
            <label className="control-label is-imp">Gender</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.gender}
              onChange={this.changeHandler.bind(this, 'basic', 'gender')}
            >
              <Option value="" disabled>
                Select Gender
              </Option>
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
            {this.state.errorObj.hasOwnProperty('gender') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.gender}
              </div>
            ) : null}
          </Col>

          <Col md={3}>
            <label className="control-label">Alternate Mobile Number</label>
            <input
              type="text"
              placeholder="8976543210"
              value={this.state.leadData.basic.altMobileNumber}
              className={'form-control'}
              onChange={this.changeHandler.bind(
                this,
                'basic',
                'altMobileNumber'
              )}
              style={
                this.state.errorObj.hasOwnProperty('altMobileNumber')
                  ? { borderColor: '#F68787' }
                  : {}
              }
            />
            {this.state.errorObj.hasOwnProperty('altMobileNumber') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.altMobileNumber}
              </div>
            ) : null}
          </Col>

          {JSON.parse(readCookie('userData')).type !== 'partners' ? (
            <Col md={3}>
              <label className="control-label is-imp">Select User Type</label>
              <Select
                size="large"
                style={{ width: '100%' }}
                value={this.state.leadData.leadInfo.userType}
                onChange={this.changeHandler.bind(this, 'leadInfo', 'userType')}
              >
                <Option value="" disabled>
                  Select User Type
                </Option>
                {this.state.allUserTypes.map((userType, userTypeIndex) => {
                  if (userType.customUrl !== 'sidbi') {
                    return (
                      <Option key={userTypeIndex} value={userType.customUrl}>
                        {userType.name}
                      </Option>
                    )
                  } else return null
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty('userType') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.userType}
                </div>
              ) : null}
            </Col>
          ) : null}

          {JSON.parse(readCookie('userData')).type !== 'partners' &&
          this.state.allUsers.length ? (
            <Col md={3}>
              <label className="control-label is-imp">Assign To</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                placeholder="Assign To"
                value={this.state.leadData.leadInfo.assignTo}
                onChange={this.changeHandler.bind(this, 'leadInfo', 'assignTo')}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                {this.state.allUsers.map((user, userIndex) => {
                  if (
                    this.state.leadData.leadInfo.userType ===
                    'swavalamban-connect-kendra'
                  ) {
                    return (
                      <Option key={userIndex} value={user._id}>
                        {user.ngoName}
                      </Option>
                    )
                  } else {
                    return (
                      <Option key={userIndex} value={user._id}>
                        {user.fullName + ' (' + user.email + ')'}
                      </Option>
                    )
                  }
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty('assignTo') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.assignTo}
                </div>
              ) : null}
            </Col>
          ) : null}

          {this.state.leadData.basic.address.state ? (
            <Col md={3}>
              <label className="control-label">Native State</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                value={this.state.leadData.basic.nativeOf.state}
                onChange={this.handleNativeStateDistrictChange.bind(
                  this,
                  'state'
                )}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="" disabled>
                  Select Native State
                </Option>
                <OptGroup label="Focus States">
                  {this.state.allStates.map((state, stateIndex) => {
                    if (state.inFocusRegion) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else return null
                  })}
                </OptGroup>
                <OptGroup label="Not Focussed States">
                  {this.state.allStates.map((state, stateIndex) => {
                    if (!state.inFocusRegion) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else return null
                  })}
                </OptGroup>
              </Select>
              {this.state.errorObj.hasOwnProperty('nativeState') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.nativeState}
                </div>
              ) : null}
            </Col>
          ) : null}

          {this.state.allNativeDistricts.length &&
          this.state.leadData.basic.address.district ? (
            <Col md={3}>
              <label className="control-label">Native District</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                value={this.state.leadData.basic.nativeOf.district}
                onChange={this.handleNativeStateDistrictChange.bind(
                  this,
                  'district'
                )}
                disabled={!this.state.leadData.basic.nativeOf.state}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="" disabled>
                  Select District
                </Option>
                {this.state.allNativeDistricts.map(
                  (district, districtIndex) => {
                    return (
                      <Option key={districtIndex} value={district.name}>
                        {district.name}
                      </Option>
                    )
                  }
                )}
              </Select>
              {this.state.errorObj.hasOwnProperty('nativeDistrict') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.nativeDistrict}
                </div>
              ) : null}
            </Col>
          ) : null}

          {this.state.allNativeCities.length &&
          this.state.leadData.basic.address.city ? (
            <Col md={3}>
              <label className="control-label">Native City</label>
              <Select
                size="large"
                showSearch
                style={{ width: '100%' }}
                value={this.state.leadData.basic.nativeOf.city}
                onChange={this.changeHandler.bind(this, 'basic', 'nativeCity')}
                disabled={!this.state.leadData.basic.nativeOf.district}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="" disabled>
                  Select City
                </Option>
                {this.state.allNativeCities.map((city, cityIndex) => {
                  return (
                    <Option key={cityIndex} value={city.name}>
                      {city.name}
                    </Option>
                  )
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty('nativeCity') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.nativeCity}
                </div>
              ) : null}
            </Col>
          ) : null}

          <Col md={4}>
            <label className="control-label is-imp">
              Educational Qualification
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.educationalQualification}
              onChange={this.changeHandler.bind(
                this,
                'basic',
                'educationalQualification'
              )}
            >
              <Option value="">Select Educational Qualification</Option>
              <Option value="Uneducated">Uneducated</Option>
              <Option value="5th Pass">5th Pass</Option>
              <Option value="8th Pass">8th Pass</Option>
              <Option value="10th Pass">10th Pass</Option>
              <Option value="12th Pass">12th Pass</Option>
              <Option value="Diploma">Diploma</Option>
              <Option value="graduate">Graduate</Option>
              <Option value="post graduate">Post-Graduate</Option>
              <Option value="Doctorate">Doctorate</Option>
            </Select>
            {this.state.errorObj.hasOwnProperty('educationalQualification') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.educationalQualification}
              </div>
            ) : null}
          </Col>

          {['Diploma', 'graduate', 'post graduate', 'Doctorate'].indexOf(
            this.state.leadData.basic.educationalQualification
          ) > -1 ? (
            <Col md={4}>
              <label className="control-label is-imp">
                Specialization Of Education
              </label>
              <Select
                size="large"
                style={{ width: '100%' }}
                value={this.state.leadData.basic.specializationOfEducation}
                onChange={this.changeHandler.bind(
                  this,
                  'basic',
                  'specializationOfEducation'
                )}
              >
                <Option value="">Select Specialization Of Education</Option>
                {specializationsOfEducation.map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  )
                })}
              </Select>
              {this.state.errorObj.hasOwnProperty(
                'specializationOfEducation'
              ) ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.specializationOfEducation}
                </div>
              ) : null}
            </Col>
          ) : null}

          <Col md={4}>
            <label className="control-label is-imp">Religion</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.religion}
              onChange={this.changeHandler.bind(this, 'basic', 'religion')}
            >
              <Option value="">Select Religion</Option>
              <Option value="Christianity">Christianity</Option>
              <Option value="Islam">Islam</Option>
              <Option value="Hinduism">Hinduism</Option>
              <Option value="Buddhism">Buddhism</Option>
              <Option value="Sikhism">Sikhism</Option>
              <Option value="Jainism">Jainism</Option>
              <Option value="Judaism">Judaism</Option>
              <Option value="Nonreligious">Nonreligious</Option>
            </Select>
            {this.state.errorObj.hasOwnProperty('religion') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.religion}
              </div>
            ) : null}
          </Col>

          <Col md={4}>
            <label className="control-label is-imp">Category</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.category}
              onChange={this.changeHandler.bind(this, 'basic', 'category')}
            >
              <Option value="">Select Category</Option>
              <Option value="General">General</Option>
              <Option value="SC">SC</Option>
              <Option value="ST">ST</Option>
              <Option value="OBC">OBC</Option>
              <Option value="Others">Others</Option>
            </Select>
            {this.state.errorObj.hasOwnProperty('category') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.category}
              </div>
            ) : null}
          </Col>

          <Col md={4}>
            <label className="control-label is-imp">
              Is a person with disability (PWD)
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.pwd}
              onChange={this.changeHandler.bind(this, 'basic', 'pwd')}
            >
              <Option value="">Select Yes/No</Option>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
            {this.state.errorObj.hasOwnProperty('pwd') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.pwd}
              </div>
            ) : null}
          </Col>
          <Col md={4}>
            <label className="control-label is-imp">
              Total number of family members
            </label>
            <input
              type="number"
              min={0}
              placeholder="Total family members"
              value={this.state.leadData.basic.numberOfFamilyMember || ''}
              className={'form-control'}
              onChange={this.changeHandler.bind(
                this,
                'basic',
                'numberOfFamilyMember'
              )}
            />
            {this.state.errorObj.hasOwnProperty('numberOfFamilyMember') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.numberOfFamilyMember}
              </div>
            ) : null}
          </Col>

          <Col md={4}>
            <label className="control-label is-imp">
              Total number of dependents
            </label>
            <input
              type="number"
              min={0}
              placeholder="Total dependents"
              value={this.state.leadData.basic.numberOfDependents || ''}
              className={'form-control'}
              onChange={this.changeHandler.bind(
                this,
                'basic',
                'numberOfDependents'
              )}
            />
            {this.state.errorObj.hasOwnProperty('numberOfDependents') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.numberOfDependents}
              </div>
            ) : null}
          </Col>

          {/* <Col md={4}>
					<label className="control-label is-imp">Family Background and Stability</label>
					<Select size="large" style={{ width: '100%' }} value={this.state.leadData.basic.backgroundAndStability} onChange={this.changeHandler.bind(this, 'basic', 'backgroundAndStability')} defaultActiveFirstOption={false}>
						<Option value="" disabled>Select Option</Option>
						<Option value="Stable (Ownership of assets (house/land/shop) by individual/family)">Stable (Ownership of assets (house/land/shop) by individual/family)</Option>
						<Option value="Somewhat stable (No dependents, Other earning members in the family)">Somewhat stable (No dependents, Other earning members in the family)</Option>
						<Option value="Unstable (Only earning member, with dependents)">Unstable (Only earning member, with dependents)</Option>
					</Select>
					{this.state.errorObj.hasOwnProperty('backgroundAndStability') ? (
						<div style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}>{this.state.errorObj.backgroundAndStability}</div>
					) : (null)}
				</Col> */}

          <Col md={4}>
            <label className="control-label is-imp">Date Of Birth</label>
            <DatePicker
              size="large"
              placeholder="Select DOB (DD/MM/YYYY)"
              value={
                moment.isMoment(moment(this.state.leadData.basic.dob))
                  ? this.state.leadData.basic.dob
                  : '' || ''
              }
              format="DD/MM/YYYY"
              onChange={this.changeHandler.bind(this, 'basic', 'dob')}
              style={
                this.state.errorObj.hasOwnProperty('dob')
                  ? { borderColor: '#F68787', width: '100%' }
                  : { width: '100%' }
              }
              disabledDate={this.disabledDate}
              showToday={false}
            />
            {this.state.errorObj.hasOwnProperty('dob') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.dob}
              </div>
            ) : null}
          </Col>

          <Col md={4}>
            <label className="control-label is-imp">Identity Type</label>
            <Select
              mode="multiple"
              size="large"
              style={{ width: '100%' }}
              value={this.state.leadData.basic.identityType}
              onChange={this.changeHandler.bind(this, 'basic', 'identityType')}
              placeholder="Select Identity Type"
            >
              <Option value="Aadhaar">Aadhaar</Option>
              <Option value="PAN">PAN</Option>
              <Option value="Voter ID">Voter ID</Option>
              <Option value="No ID available">No ID available</Option>
            </Select>
            {this.state.errorObj.hasOwnProperty('identityType') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.identityType}
              </div>
            ) : null}
          </Col>

          <Col md={4}>
            <label className="control-label is-imp">Identity Number</label>
            <input
              type="text"
              placeholder="Enter Identity Number"
              value={this.state.leadData.basic.identityNumber}
              className={'form-control'}
              onChange={this.changeHandler.bind(
                this,
                'basic',
                'identityNumber'
              )}
              style={
                this.state.errorObj.hasOwnProperty('identityNumber')
                  ? { borderColor: '#F68787' }
                  : {}
              }
            />
            {this.state.errorObj.hasOwnProperty('identityNumber') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.identityNumber}
              </div>
            ) : null}
          </Col>
          {JSON.parse(readCookie('currentProjectDetails'))._id ===
          '634d34908d1bd908f48b315a' ? (
            <Col md={4}>
              <label className="control-label">Milestone/Batch</label>
              <input
                type="text"
                placeholder="Enter Milestone/Batch"
                disabled={true}
                value={this.state.leadData?.basic?.sidbiMilestone}
                className={'form-control'}
                onChange={this.changeHandler.bind(
                  this,
                  'basic',
                  'sidbiMilestone'
                )}
                style={
                  this.state.errorObj.hasOwnProperty('sidbiMilestone')
                    ? { borderColor: '#F68787' }
                    : {}
                }
              />
              {this.state.errorObj.hasOwnProperty('sidbiMilestone') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.sidbiMilestone}
                </div>
              ) : null}
            </Col>
          ) : (
            <Col md={4}>
              <label className="control-label">Milestone/Batch</label>
              <input
                type="text"
                placeholder="Enter Milestone/Batch"
                value={this.state.leadData?.basic?.sidbiMilestone}
                className={'form-control'}
                onChange={this.changeHandler.bind(
                  this,
                  'basic',
                  'sidbiMilestone'
                )}
                style={
                  this.state.errorObj.hasOwnProperty('sidbiMilestone')
                    ? { borderColor: '#F68787' }
                    : {}
                }
              />
              {this.state.errorObj.hasOwnProperty('sidbiMilestone') ? (
                <div
                  style={{
                    color: '#F68787',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {this.state.errorObj.sidbiMilestone}
                </div>
              ) : null}
            </Col>
          )}

          <Col md={12}>
            <label className="control-label">
              Upload consent/documentation of the candidate, if any (image/PDF)
            </label>
            {this.state.leadData.basic.candidateConsentDoc &&
            typeof this.state.leadData.basic.candidateConsentDoc ===
              'string' ? (
              <>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.leadData.basic.candidateConsentDoc}
                >
                  View File
                </a>
                <p>Cross sign</p>
              </>
            ) : (
              <input
                id="candidateConsentDoc"
                type="file"
                onChange={this.changeHandler.bind(
                  this,
                  'basic',
                  'candidateConsentDoc'
                )}
                style={
                  this.state.errorObj.hasOwnProperty('candidateConsentDoc')
                    ? { borderColor: '#F68787' }
                    : {}
                }
                // onChange={(e) => { if (e.target.files && e.target.files.length) { updateExistingBusinessDetails({ ...businessDetails, existingBusinessPhoto: e.target.files }) } else { updateExistingBusinessDetails({ ...businessDetails, existingBusinessPhoto: e.target.files }) } }}
                accept="image/*, .pdf"
                className="form-control"
              />
            )}
            {this.state.errorObj.hasOwnProperty('candidateConsentDoc') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.state.errorObj.candidateConsentDoc}
              </div>
            ) : null}
          </Col>
        </Row>

        <div className="d-flex justify-content-center mt20">
          <Button
            variant="primary"
            style={{ marginLeft: '20px' }}
            onClick={this.save}
            disabled={this.state.savingLead}
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
}
