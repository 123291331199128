import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit'
import moment from 'moment'

const { ExportCSVButton } = CSVExport
const readCookie = require('../../cookie.js').readCookie

export default function SummaryStats() {
  const [rows, setRows] = useState([])
  const [totalRow, setTotalRow] = useState({})

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/summary-stats', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setRows(data.result || [])
          setTotalRow(data.totalRow || {})
        }
      })
  }, [])
  const columns = [
    {
      text: 'State',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'state',
      // formatter: this.stateFormatter,
      footer: (totalRow.state || 'Grand Total').toString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Follow Up Done',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'followUpDone',
      footer: (totalRow.followUpDone || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Yet to Contact',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'noFollowUp',
      footer: (totalRow.noFollowUp || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Profiled on the portal',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'profilesCreated',
      footer: (totalRow.profilesCreated || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Disqualified',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'disqualified',
      footer: (totalRow.disqualified || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Business Plan Updated on the Portal',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'bplanDone',
      footer: (totalRow.bplanDone || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Loan Rejected',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'loansRejected',
      footer: (totalRow.loansRejected || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Loan Reapplied',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'reappliedLoans',
      footer: (totalRow.reappliedLoans || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Loan Sanctioned Through Mission Support',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'loansSanctioned',
      footer: (totalRow.loansSanctioned || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: 'Loan disbursed through mission support',
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'loansDisbursed',
      footer: (totalRow.loansDisbursed || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
    {
      text: "Enterprise set-up marked on the portal after candidate's acknowledgement",
      sort: true,
      hidden: false,
      csvExport: true,
      export: false,
      searchable: true,
      dataField: 'enterprisesSetUp',
      footer: (totalRow.enterprisesSetUp || 0).toLocaleString(),
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'center',
          wordWrap: 'break-word',
          background: '#20c997',
        }
      },
    },
  ]
  const rowClassNameFormat = (row, index) => {
    const style = { textAlign: 'center' }
    if (row.id === 'Grand Total') {
      style.backgroundColor = '#00cf00'
      style.fontWeight = 'bold'
      style.color = 'white'
    }

    return style
  }

  return (
    <div className="page-container">
      <Container fluid>
        <Row>
          <ToolkitProvider
            keyField="id"
            data={rows}
            columns={columns}
            exportCSV={{
              fileName: `LE Summary_Stats_${moment().format('DD-MM-YYYY_hh-mm-ss_a')}.csv`,
            }}
          >
            {props => (
              <React.Fragment>
                <Col md={10}>
                  <h3>Summary Stats</h3>
                  {/* <div className="title">{"District-wise Lead Stats"}</div> */}
                </Col>
                <Col md={2}>
                  <ExportCSVButton {...props.csvProps}>
                    Export as CSV
                  </ExportCSVButton>
                </Col>

                <div>
                  <hr />
                  {/* <div {className = "table-container"}> */}
                  <BootstrapTable
                    id="leads-stats-table"
                    rowStyle={rowClassNameFormat}
                    {...props.baseProps}
                    headerClasses="bootstrap-leads-table-header-class"
                    footerClasses="bootstrap-leads-table-footer-class"
                    // ref={(table) => {
                    //     this.table = table;
                    // }}
                    // remote={{ pagination: true }}
                    // onTableChange={this.onTableChange}

                    // {...paginationTableProps}
                  />

                  {/* <SizePerPageDropdownStandalone {...paginationProps} />
                              <PaginationListStandalone {...paginationProps} /> */}
                </div>
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Row>
      </Container>
    </div>
  )
}
