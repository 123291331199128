import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  farmersData: [],
  loading: false,
  error: '',
  message: '',
}

const currentToken = localStorage.getItem('humblebeeAuthToken')

export const deleteFarmer = createAsyncThunk(
  'deleteFarmer',
  async (_id, { rejectWithValue }) => {
    try {
      console.log('_id from deleteFarmer => ', _id)

      const res = await axios.delete(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/farmers/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )

      return res.data.singleFarmer
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const deleteFarmerSlice = createSlice({
  name: 'deleteFarmer',
  initialState,
  reducers: {},

  extraReducers: {
    [deleteFarmer.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [deleteFarmer.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.FarmersData = payload
      }
    },

    [deleteFarmer.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.loading = false
    },
  },
})

export default deleteFarmerSlice.reducer
