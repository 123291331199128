import React from 'react'
import { FieldErrorMessage } from '../ErrorMessage/FieldErrorMessage'
import { InputGroupContainer, Labels, CustomSelect } from './style'

export const SelectGroup = props => {
  const {
    PlaceholderText,
    LabelText,
    name,
    onChange,
    defaultValue,
    isDisabled,
    onBlur,
    star,
    options,
    value,
  } = props
  let tempDisabled = isDisabled === true ? isDisabled : false

  return (
    <InputGroupContainer>
      <Labels>
        {LabelText} <span style={{ color: 'red' }}>{star}</span>
      </Labels>

      <CustomSelect
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={tempDisabled}
        onBlur={onBlur}
        value={value}
      >
        <option hidden value="">
          {PlaceholderText}
        </option>
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </CustomSelect>
      <FieldErrorMessage name={name} />
    </InputGroupContainer>
  )
}
