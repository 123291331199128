import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  suppliersData: [],
  loading: false,
  error: '',
}

export const getAllSuppliers = createAsyncThunk('getAllSuppliers', async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/suppliers/`
    )
    console.log('this is res.data from getAllSupplerSlice => ', res)
    return res?.data?.allSuppliers
  } catch (err) {
    console.log('err', err)
    return err.response.data
  }
})

const getAllSupplierSlice = createSlice({
  name: 'getAllSuppliers',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllSuppliers.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllSuppliers.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.suppliersData = payload
      }
    },

    [getAllSuppliers.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllSupplierSlice.reducer
