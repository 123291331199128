import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { readCookie } from '../../cookie'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  Col,
  Row,
  Button,
  Modal,
  Dropdown,
  DropdownButton,
  Tabs,
  Tab,
} from 'react-bootstrap'
import moment from 'moment'
import CallLogDetails from './CallLogDetails'

const knowlarity_emails = require('../../../../emails_knowlarity.json')

const { SearchBar } = Search

const OutboundCalls = ({ userData }) => {
  const [callLogs, setCallLogs] = useState([])
  const [modalVisibility, setModalVisibility] = useState(false)
  const [callerNumber, setCallerNumber] = useState(null)

  const getAllOutboundCallDetails = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/click-to-call-logs/outbound-call',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setCallLogs(data.callLogs || [])
        }
      })
  }
  useEffect(() => {
    getAllOutboundCallDetails()
  }, [])

  const formatMobile = (cell, row) => {
    if (cell) {
      if (row.history && row.history.length) {
        return (
          <div className="d-flex align-items-center justify-content-between">
            {
              /*userData.userType.name==='Project Management Unit' ||*/ (knowlarity_emails.includes(
                userData.email
              ) ||
                /*userData.userType.name === 'Zonal Manager' ||*/ userData
                  .userType.name === 'Swavalamban Fellows') && (
                // && userData.fellowAccessType === 'Full'
                <span
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                    marginRight: '2px',
                  }}
                  className="call-icon"
                  onClick={this.callNumber.bind(this, cell)}
                >
                  <i
                    style={{ fontSize: '20px', color: '#00dd00' }}
                    title="Call this number"
                    className="fa fa-phone"
                  />
                </span>
              )
            }{' '}
            <span>{cell}</span>
            <span className="note">
              {'Attempts: ' + (row.history.length + 1)}
            </span>
          </div>
        )
      } else {
        return (
          <div className="d-flex ">
            {
              /*userData.userType.name==='Project Management Unit' ||*/ (knowlarity_emails.includes(
                userData.email
              ) ||
                /*userData.userType.name === 'Zonal Manager' ||*/ userData
                  .userType.name === 'Swavalamban Fellows') && (
                // && userData.fellowAccessType === 'Full'
                <span
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                    marginRight: '2px',
                  }}
                  className="call-icon"
                  onClick={this.callNumber.bind(this, cell)}
                >
                  <i
                    style={{ fontSize: '20px', color: '#00dd00' }}
                    title="Call this number"
                    className="fa fa-phone fa-lg"
                  />{' '}
                  &nbsp;
                </span>
              )
            }
            <span>{cell}</span>
          </div>
        )
      }
    } else return null
  }

  const formatDate = (cell, row) => {
    if (cell && moment(cell).isValid()) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return null
  }

  const formatSeconds = cell => {
    if (cell) {
      const time = new Date(cell * 1000).toISOString().slice(11, 19)
      return time || '00:00:00'
    } else {
      return '-'
    }
  }

  const formatViewHistory = cell => {
    if (cell)
      return (
        <Button
          onClick={() => {
            setModalVisibility(true)
            setCallerNumber(cell)
          }}
        >
          <i class="fa fa-history" aria-hidden="true"></i>
        </Button>
      )
    else return '-'
  }

  const paginationOptions = {
    page: 1,
    sizePerPageList: [
      {
        text: '100',
        value: 100,
      },
      {
        text: '200',
        value: 200,
      },
      {
        text: '500',
        value: 500,
      },
      {
        text: '1000',
        value: 1000,
      },
    ],
    sizePerPage: 500,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: '<',
    nextPage: '>',
    firstPage: '<<',
    lastPage: '>>',
    paginationPosition: 'bottom',
  }
  // const formatEdit = (cell, row) => {
  //     if (row._id) {
  //         // if(row.hasLead) return 'N/A';
  //         // else {
  //         return (
  //             <Button variant='primary' onClick={this.showEditModal.bind(this, row)}>Edit</Button>
  //         )
  //         // }
  //     } else return null;
  // }

  // const formatConvertLead = (cell, row) => {
  //     if (row._id) {
  //         if (row.hasLead) {
  //             return (
  //                 <Button variant='primary' onClick={() => window.location.pathname = "/lead/" + row.leadId}>View Lead</Button>
  //             )
  //         } else if (row.status !== 'Spam' && row.status !== "Unassigned") {
  //             return (
  //                 <Button className="p5" variant='primary' onClick={this.convertToLead.bind(this, row._id)}>Create Lead Profile</Button>
  //             )
  //         } else return 'N/A';
  //     } else return null;
  // }

  const ivrsColumns = [
    {
      text: '',
      sort: false,
      hidden: true,
      export: true,
      dataField: '_id',
      searchable: false,
    },
    {
      sort: true,
      export: true,
      hidden: false,
      text: 'Caller Number',
      searchable: true,
      dataField: 'From',
      formatter: formatMobile,
      headerStyle: () => {
        return { width: '220px' }
      },
    },
    {
      sort: true,
      export: true,
      hidden: false,
      text: 'Receiver Number',
      searchable: true,
      dataField: 'To',
      formatter: formatMobile,
      headerStyle: () => {
        return { width: '220px' }
      },
    },
    {
      sort: true,
      export: true,
      hidden: false,
      text: 'Timestamp',
      searchable: true,
      dataField: 'StartTime',
      formatter: formatDate,
      headerStyle: () => {
        return { width: '220px' }
      },
    },
    {
      sort: true,
      export: true,
      hidden: false,
      text: 'Status',
      searchable: true,
      dataField: 'Status',
      headerStyle: () => {
        return { width: '220px' }
      },
    },
    {
      sort: true,
      export: true,
      hidden: false,
      text: 'Call duration',
      searchable: true,
      formatter: formatSeconds,
      dataField: 'ConversationDuration',
      headerStyle: () => {
        return { width: '220px' }
      },
    },
    {
      sort: false,
      export: false,
      hidden: false,
      text: 'View Call Logs',
      searchable: true,
      formatter: formatViewHistory,
      dataField: 'To',
      headerStyle: () => {
        return { width: '50px' }
      },
    },
  ]

  return (
    <>
      <Col md={12} className="ivrs-leads-table">
        {/* {callLogs.length ? (
                <span id="drag-table-left">{'<'}</span>
            ) : (null)}
            {callLogs.length ? (
                <span id="drag-table-right">{'>'}</span>
            ) : (null)} */}
        {callLogs.length ? (
          <div>
            <Row className="mt10">
              <Col className="d-flex" style={{ alignItems: 'center' }}>
                {/* <span className="total-leads m0">
                                                {'Total Callers: ' + callLogs.length}
                                            </span> */}
                <span className="total-leads m0 ml5">
                  {'Total Call Counts: ' + callLogs.length}
                </span>
              </Col>
              {/* <Col className="ivrs-leads-table-search-container">
                                            <SearchBar {...props.searchProps} placeholder="Search by Mobile" className="ivrs-leads-table-search" delay={2000} />
                                        </Col> */}
              {/* <div className="filter-class d-flex align-items-center justify-content-center" onClick={this.showFilter}>Filter<i className="fas fa-filter"></i></div> */}
            </Row>
            {/* <div className="filter-container">
                                        <div className="close-filter" onClick={this.showFilter}>X</div>
                                        <div className="status-filter">
                                            <label className="control-label">Converted to Lead</label>
                                            <Select style={{ width: '100%' }} placeholder="Converted to Lead" onChange={this.changeFilterHandler.bind(this, 'hasLead')} value={this.state.filterData.hasLead}>
                                                <Option value="">All</Option>
                                                <Option value={true}>Yes</Option>
                                                <Option value={false}>No</Option>
                                            </Select>
                                        </div>
                                        <div className="status-filter">
                                            <label className="control-label">Status</label>
                                            <Select style={{ width: '100%' }} mode='multiple' placeholder="Select Status" onChange={this.changeFilterHandler.bind(this, 'status')} value={this.state.filterData.status}>
                                                <Option value="Assigned">Assigned</Option>
                                                <Option value="Unassigned">Unassigned</Option>
                                                <Option value="Spam">Spam</Option>
                                                <Option value="Unresponsive">Unresponsive</Option>
                                                <Option value="Test Call">Test Call</Option>
                                                <Option value="Information Call">Information Call</Option>
                                                <Option value="Not a focus region">Not a focus region</Option>
                                            </Select>
                                        </div>
                                        <div className="status-filter">
                                            <label className="control-label">Source</label>
                                            <Select style={{ width: '100%' }} placeholder="Source" onChange={this.changeFilterHandler.bind(this, 'isTrained')} value={this.state.filterData.isTrained}>
                                                <Option value="">All</Option>
                                                <Option value={true}>Offline Aspirants</Option>
                                                <Option value={false}>Toll-free Calls</Option>
                                            </Select>
                                        </div>

                                        {(JSON.parse(readCookie('userData')).type === 'le' && JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit') || JSON.parse(readCookie('userData')).type === 'sidbi' ? (
                                            <div className="assigned-to-filter">
                                                <label className="control-label">Select User Type</label>
                                                <Select style={{ width: '100%' }} placeholder="User Type" onChange={this.changeFilterHandler.bind(this, 'userType')} value={this.state.filterData.userType}>
                                                    <Option value="">All</Option>
                                                    {this.state.allUserTypes.map((userType, userTypeIndex) => {
                                                        return (
                                                            <Option key={userTypeIndex} value={userType.customUrl} title={userType.name}>{userType.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        ) : (null)}
                                        <div className="d-flex filter-btn-container mt10">
                                            <div className="reset-btn mr10" onClick={this.resetFilter}>Reset</div>
                                            <div className="submit-btn" onClick={this.submitFilter}>Submit</div>
                                        </div>
                                    </div> */}

            <br />
            <ToolkitProvider
              keyField="_id"
              data={callLogs}
              columns={ivrsColumns}
              search
            >
              {props => (
                <div>
                  {callLogs && (
                    <BootstrapTable
                      id="business-ideas-table"
                      {...props.baseProps}
                      pagination={paginationFactory(paginationOptions)}
                      sizePerPage={20}
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
            <br />
          </div>
        ) : null}
      </Col>

      {modalVisibility && callerNumber ? (
        // <Modal dialogClassName="ivrs-call-logs-modal" className="ivrs-call-logs-modal" show={modalVisibility} size="xl" onHide={() => { }}>
        //     <Modal.Header>
        //         <Modal.Title className="modal-header-custom">
        //             Call Logs
        //         </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body className="mb20">
        //         <Row>
        //             <Col md={12} className="padding0 d-flex">
        //                 {/* <Col>
        //                     <label className="control-label is-imp">Mobile</label>
        //                     <input className="form-control" type="text" style={{ width: '100%' }} placeholder="Mobile" value={callerNumber} disabled={true} />
        //                 </Col> */}
        <CallLogDetails
          userData={userData}
          callerNumber={callerNumber}
          setModalVisibility={setModalVisibility}
          setCallerNumber={setCallerNumber}
          modalVisibility={modalVisibility}
          // formatSeconds={formatSeconds}
          // formatDate={formatDate}
          // formatMobile={formatMobile}
        />
      ) : //             </Col>
      //             </Row>
      //     </Modal.Body>
      //     <Modal.Footer className="footer-center">
      //         <Button className="btn btn-danger brand-color-background" onClick={() => { setModalVisibility(false); setCallerNumber(null); }}>Close</Button>
      //         {/* <Button className="btn btn-warning brand-color-background" onClick={this.updateLead} disabled={this.state.gettingDetailsForPincode}>Save</Button> */}
      //     </Modal.Footer>
      // </Modal>
      null}
    </>
  )
}

export default OutboundCalls
