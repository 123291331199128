import $ from 'jquery'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import React, { Component } from 'react'
import ReactPasswordStrength from 'react-password-strength'

export default class ChangePasswordLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      password: '',
      passwordData: null,
      confirmPassword: '',
      invalidError: false,
      submittedPassword: false,
    }
  }

  componentDidMount() {
    this.setLoginForm()
  }

  change(e) {
    this.setState({ error: false, confirmPassword: e.target.value })
  }

  passwordSubmit = e => {
    e.preventDefault()
    let password = this.state.password
    let confirmPassword = this.state.confirmPassword
    let passwordData = this.state.passwordData
    let token = this.props.match.params.token
    if (passwordData !== null) {
      if (password === confirmPassword && passwordData.isValid) {
        fetch(process.env.REACT_APP_API_URL + '/api/v1/user/reset-password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ password: password, token: token }),
        })
          .then(response => response.json())
          .then(data => {
            if (!data.status) {
              Swal.fire('Oops...', data.message, 'error')
            } else {
              this.setState({ submittedPassword: true })
              Swal.fire({
                title: 'Password saved!',
                html: '<h4>You have successfully updated your password</h4>',
                type: 'success',
              })
              setTimeout(() => {
                this.notifySuccess(
                  'Your password has been updated successfully.'
                )
              }, 1500)
              window.location.pathname = '/'
            }
          })
          .catch(error => {
            console.log(
              'There has been a problem with your fetch operation: ' +
                error.message
            )
          })
      } else {
        if (password !== confirmPassword) this.setState({ error: true })
        if (!passwordData.isValid) this.setState({ invalidError: true })
      }
    } else {
      this.setState({ invalidError: true })
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  setLoginForm() {
    $(document).ready(() => {
      $('.form')
        .find('input, textarea')
        .on('keyup blur focus', e => {
          var $this = $(e.target),
            label = $this.prev('label')
          if ($(this).hasClass('wrong')) $(this).removeClass('wrong')
          if (e.type === 'keyup') {
            if ($this.val() === '') label.removeClass('highlight')
            else label.addClass('active highlight')
          } else if (e.type === 'blur') {
            if ($this.val() === '') label.removeClass('active highlight')
            else label.removeClass('highlight')
          } else if (e.type === 'focus') {
            label.addClass('active highlight')
          }
        })

      $('body').click()

      if ($('input#password').val() !== '') {
        if (!$('input#password').siblings('label').hasClass('active'))
          $('input#password').siblings('label').addClass('active')
      }
    })
  }

  changeNewPassword(passwordData) {
    this.setState({
      passwordData,
      password: passwordData.password,
      invalidError: false,
      error: false,
    })
  }

  render() {
    return (
      <div id="login-container">
        <div className="sahaj-front sahaj-front-mobile"></div>
        <div className="form form-mobile">
          <div id="login" style={{ width: '70%', margin: '0 auto' }}>
            <div id="sahaj-heading">HumbleBee</div>
            <h1>Reset Password!</h1>
            <form
              id="login-form"
              className="text-center"
              onSubmit={this.passwordSubmit}
            >
              <ReactPasswordStrength
                minLength={4}
                minScore={1}
                className="new-password"
                scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
                changeCallback={this.changeNewPassword.bind(this)}
                inputProps={{
                  name: 'password_input',
                  autoComplete: 'off',
                  placeholder: 'Enter New Password',
                  className: 'form-control react-password-strength',
                }}
              />
              <span hidden={!this.state.invalidError}>
                <span className="required-span"> Invalid Password! </span>
              </span>
              <div className="field-wrap">
                <label> Confirm New Password </label>
                <input
                  id="confirmPassword"
                  type="password"
                  required
                  autoComplete="off"
                  onChange={this.change.bind(this)}
                />
              </div>
              {this.state.error === true ? (
                <div className="show-error mt10">
                  <span className="note">
                    Password did not match. Try again!
                  </span>
                </div>
              ) : null}
              <button className="button button-block">
                <span id="login-span">Submit</span>
                <i
                  className="fa fa-check"
                  hidden={!this.state.submittedPassword}
                ></i>
              </button>
              <img
                className="mt20"
                src="/images/le_black.png"
                alt=""
                height="80"
              />
            </form>
          </div>
        </div>
      </div>
    )
  }
}
