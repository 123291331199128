import React, { Component } from 'react'
import ReactPasswordStrength from 'react-password-strength'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import $ from 'jquery'

const readCookie = require('../cookie.js').readCookie
const createCookie = require('../cookie.js').createCookie

export default class Personal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_password_error: false,
      show_new_password_error: false,
      show_mismatch_error: false,
      changePasswordModalVisibility: false,
      current_password: '',
      new_password: '',
      confirm_password: '',
      passwordData: null,
    }
  }

  onChangePasswordClick = () => {
    this.setState({ changePasswordModalVisibility: true })
  }

  onChangeDetailsClick = () => {
    Swal.fire({
      title: 'Change Details',
      html:
        '<span class="is-imp">First Name</span><input type="input" id="swal-input1" class="swal2-input" placeholder="First Name" value="' +
        this.props.userData.fullName +
        '">',
    })
      .then(result => {
        let val1 = $('#swal-input1').val()
        if (val1 !== '' && result.value) {
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change!',
            cancelButtonText: 'No, cancel!',
          }).then(result => {
            if (result.value && val1 !== '') {
              let userData = JSON.parse(readCookie('userData')),
                new_data = {}
              new_data['fullName'] = val1
              fetch(
                process.env.REACT_APP_API_URL + '/api/v1/user/' + userData._id,
                {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                  },
                  body: JSON.stringify(new_data),
                }
              )
                .then(response => response.json())
                .then(data => {
                  if (data.status) {
                    userData.fullName = val1
                    createCookie(
                      'userData',
                      JSON.stringify({ ...userData, newKey: new Date() }),
                      30
                    )
                    Swal.fire({
                      title: 'Name updated',
                      icon: 'success',
                      timer: 1000,
                      showConfirmButton: false,
                    }).then(() => {
                      window.location.reload()
                    })
                  } else {
                    Swal.fire('Oops...', 'Something went wrong!', 'error')
                  }
                })
            }
          })
        }
      })
      .catch(() => {})
  }

  showProfPicUploadButton() {
    $('#uploadUserPic').removeClass('d-none')
    $('#cancelUploadUserPic').removeClass('d-none')
  }

  changeProfilePic = () => {
    var profPic = $('input#userProfPic')

    var data = new FormData()
    data.append('dp', profPic[0].files[0])
    let userData = JSON.parse(readCookie('userData'))
    fetch(process.env.REACT_APP_API_URL + '/api/v1/user/dp/' + userData._id, {
      method: 'PATCH',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
      body: data,
    })
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          userData.profilePicture = data.profilePicture
          createCookie(
            'userData',
            JSON.stringify({ ...userData, newKey: new Date() }),
            30
          )
          $('#uploadUserPic').addClass('d-none')
          $('#cancelUploadUserPic').addClass('d-none')
          $('input#userProfPic').val('')
          Swal.fire({
            title: 'Profile Picture updated',
            icon: 'success',
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            window.location.reload()
          })
        } else {
          Swal.fire('Oops...', 'Something went wrong!', 'error')
        }
      })
  }

  cancelChangeProfilePic() {
    $('#uploadUserPic').addClass('d-none')
    $('#cancelUploadUserPic').addClass('d-none')
    $('input#userProfPic').val('')
  }

  hideChangePasswordModal = () => {
    this.setState({ changePasswordModalVisibility: false })
  }

  changeNewPassword = passwordData => {
    this.setState({
      passwordData,
      new_password: passwordData.password,
      show_new_password_error: false,
    })
  }

  changePassword = () => {
    let current_password = this.state.current_password
    let new_password = this.state.new_password
    let confirm_password = this.state.confirm_password
    let passwordData = this.state.passwordData
    if (passwordData !== null) {
      if (
        current_password !== '' &&
        new_password === confirm_password &&
        passwordData.isValid
      ) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Change!',
          cancelButtonText: 'No, cancel!',
        }).then(result => {
          if (result.value) {
            let userData = JSON.parse(readCookie('userData'))
            fetch(
              process.env.REACT_APP_API_URL + '/api/v1/user/' + userData._id,
              {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
                body: JSON.stringify({
                  new_password: new_password,
                  old_password: current_password,
                }),
              }
            )
              .then(response => response.json())
              .then(data => {
                if (data.status) {
                  this.setState(
                    { changePasswordModalVisibility: false },
                    function () {
                      Swal.fire(
                        'Password updated',
                        'Password updated successfully',
                        'success'
                      )
                    }
                  )
                } else {
                  if (data.errorMsg)
                    Swal.fire('Oops...', data.errorMsg, 'error')
                  else Swal.fire('Oops...', 'Something went wrong!', 'error')
                }
              })
          }
        })
      } else {
        if (current_password === '')
          this.setState({ show_password_error: true })
        if (new_password !== confirm_password)
          this.setState({ show_mismatch_error: true })
        if (!passwordData.isValid)
          this.setState({ show_new_password_error: true })
      }
    } else {
      if (current_password === '') this.setState({ show_password_error: true })
      this.setState({ show_new_password_error: true })
    }
  }

  updateChange = (name, e) => {
    this.setState({
      [name]: e.target.value,
      show_password_error: false,
      show_mismatch_error: false,
    })
  }

  render() {
    return (
      <Row className="personal-settings">
        <Col md={12}>
          <Row id="personal-details" className="mt20">
            <Row className="flex-center">
              <Col md={4} className="mb10">
                <div className="ngo_image">
                  {!this.props.userData.profilePicture ? (
                    <img
                      src="/images/user_1.png"
                      alt=""
                      className="img-circle"
                    />
                  ) : (
                    <img
                      src={this.props.userData.profilePicture}
                      alt=""
                      onError={event =>
                        event.target.setAttribute('src', '/images/user_1.png')
                      }
                    />
                  )}
                  <input
                    id="userProfPic"
                    className="mb10"
                    type="file"
                    onChange={this.showProfPicUploadButton}
                  />
                  <Col md={12} className="mt10">
                    <Button
                      id="uploadUserPic"
                      className="btn-success ml5 mr5 d-none"
                      onClick={this.changeProfilePic}
                    >
                      Upload
                    </Button>
                    <Button
                      id="cancelUploadUserPic"
                      className="btn-success ml5 mr5 d-none"
                      onClick={this.cancelChangeProfilePic}
                    >
                      Cancel
                    </Button>
                  </Col>
                </div>
              </Col>
              <Col md={8} className="mb10">
                <Row>
                  <Col md={12} className="mb10">
                    <span className="detail-title settings-title">
                      Full Name:
                    </span>
                    <span className="detail-data">
                      {this.props.userData.fullName}
                    </span>
                  </Col>
                  {this.props.userData.organisationData ? (
                    <Col md={12} className="mb10">
                      <span className="detail-title settings-title">
                        Organisation:
                      </span>
                      <span className="detail-data">
                        {this.props.userData.organisationData.name}
                      </span>
                    </Col>
                  ) : null}
                  <Col md={12} className="mb10">
                    <span className="detail-title settings-title">Email:</span>
                    <span className="detail-data email">
                      {this.props.userData.email}
                    </span>
                  </Col>
                  <Col md={12} className="mb10">
                    <Button
                      className="btn-success ml5 mr5"
                      onClick={this.onChangeDetailsClick}
                    >
                      Change Details
                    </Button>
                    <Button
                      className="btn-success ml5 mr5"
                      onClick={this.onChangePasswordClick}
                    >
                      Change Password
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Col>

        {this.state.changePasswordModalVisibility ? (
          <div className="edit-org-details-modal">
            <Modal
              className="change-password-modal"
              show={this.state.changePasswordModalVisibility}
              onHide={this.hideChangePasswordModal}
            >
              <Modal.Header>
                <Modal.Title className="modal-header-custom">
                  Change Password
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col className="margin-col-50" md={12}>
                    <form id="change-password-modal-form" ref="vForm">
                      <Col className="form-group" md={12}>
                        <label className="control-label is-imp">
                          Current Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Current Password"
                          onChange={this.updateChange.bind(
                            this,
                            'current_password'
                          )}
                        />
                        <span hidden={!this.state.show_password_error}>
                          <span className="required-span">
                            {' '}
                            Incorrect Password!{' '}
                          </span>
                        </span>
                      </Col>

                      <Col className="form-group" md={12}>
                        <label className="control-label is-imp">
                          New Password
                        </label>
                        <ReactPasswordStrength
                          minLength={4}
                          minScore={1}
                          className="new-password"
                          scoreWords={[
                            'weak',
                            'okay',
                            'good',
                            'strong',
                            'stronger',
                          ]}
                          changeCallback={this.changeNewPassword}
                          inputProps={{
                            name: 'password_input',
                            autoComplete: 'off',
                            placeholder: 'New Password',
                            className: 'form-control',
                          }}
                        />
                        <span hidden={!this.state.show_new_password_error}>
                          <span className="required-span">
                            {' '}
                            Invalid Password!{' '}
                          </span>
                        </span>
                      </Col>

                      <Col className="form-group" md={12}>
                        <label className="control-label is-imp">
                          Repeat New Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Repeat New Password"
                          onChange={this.updateChange.bind(
                            this,
                            'confirm_password'
                          )}
                        />
                        <span hidden={!this.state.show_mismatch_error}>
                          <span className="required-span">
                            {' '}
                            Password mismatch!{' '}
                          </span>
                        </span>
                      </Col>
                    </form>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="footer-center">
                <Button
                  className="btn btn-danger brand-color-background"
                  onClick={this.hideChangePasswordModal}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-warning brand-color-background"
                  onClick={this.changePassword}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : null}
      </Row>
    )
  }
}
