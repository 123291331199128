import React, { Component } from 'react'
import {
  Row,
  Col,
  // Button
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import {  Select } from 'antd';

const readCookie = require('../cookie.js').readCookie
// const eraseCookie = require('../cookie.js').eraseCookie;
const createCookie = require('../cookie.js').createCookie

// const { Option } = Select;

export default class TopMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProject: readCookie('project_id'),
      accessToProject: this.props.userData.hasAccessToProjects,
      projects: [],
    }
  }
  componentDidMount() {
    // fetch(process.env.REACT_APP_API_URL + `/api/v1/project/project-details/${this.props.userData.id}`, {
    fetch(process.env.REACT_APP_API_URL + `/api/v1/project`, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
        // 'project': this.props.userData.id
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status === 'ok') {
          this.setState({ projects: data.projects })
          // if (data.currentProjectDetails) {
          //   createCookie('currentProjectDetails', JSON.stringify({ ...data.currentProjectDetails }), 30);
          // }
        }
      })
  }

  onChange = e => {
    this.setState({ selectedProject: e })
    fetch(process.env.REACT_APP_API_URL + `/api/v1/project/project-token`, {
      method: 'POST',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
        'Content-Type': 'application/json',

        // 'project': userData.id
      },
      body: JSON.stringify({
        projectId: e,
      }),
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          // setProjects(data.projects)
          if (data.project_token) {
            createCookie('project_id', e, 30)
            createCookie('access_token', JSON.stringify(data.project_token), 30)
            if (data['userData']) {
              createCookie(
                'userData',
                JSON.stringify({ ...data['userData'], newKey: new Date() }),
                30
              )
            }

            // window.location = '/dashboard';
            window.location.reload()
          }
        }
      })
    // .catch(error){
    // this.noti
    // }
    //  createCookie("project_id",JSON.stringify(e));
  }

  render() {
    return (
      //   <div className={this.props.sidebarMinimized && window.location.pathname === "/leads" ? "top-menu sidebar-minimized" : "top-menu"}>
      //     <Row className="full-width">
      //       {window.screen.width < 500 ? (
      //         this.props.sidebarMinimized ? (
      //           <span className="menu-toggle" onClick={this.props.toggleSidebar}><i className="fa fa-bars fa-2x" /></span>
      //         ) : (
      //           <span className="menu-toggle" onClick={this.props.toggleSidebar}><i className="fa fa-times fa-2x" /></span>
      //         )
      //       ) : (null)}
      //       {window.screen.width < 500 ? readCookie("currentProjectDetails") && JSON.parse(readCookie("currentProjectDetails")).displayTitle ? JSON.parse(readCookie("currentProjectDetails")).displayTitle : (
      //         (window.location.hostname.includes('vcap.me') || window.location.hostname.includes('udyamita')) ? (
      //           <span className="swati-title">UDYAMITA</span>
      //         ) : (
      //           <span className="swati-title">SWATI</span>
      //         )
      //       ) : (null)}

      //       {window.screen.width >= 500 && this.props.userData.organisation ? (
      //         <Col md={window.screen.width < 500 ? null : 6}>
      //           <img alt="" src={this.props.userData.organisation.logo ? this.props.userData.organisation.logo : ""} height="50" />
      //         </Col>
      //       ) : (null)}

      //       <Col md={window.screen.width < 500 ? null : (this.props.userData.organisation ? 6 : 12)} className="org-additional-buttons">
      //         <div className="current_user">
      //         {window.screen.width >= 500 && this.state.projects.length > 1
      //          ? (<Col className ="top-menu-dropdown" >
      //           {/* <Select
      //           showSearch
      //           style={ window.screen.width > 500? { width: "130px" } : { width: 300 }}
      //           value={this.state.selectedProject}
      //           onChange={this.onChange.bind(this)}
      //         >
      //             {this.state.projects.map((project, projectIndex) => {
      //               if (project) {
      //                   return (
      //                     <Option key={projectIndex} value={project._id}>
      //                       {project.name}
      //                     </Option>
      //                   );
      //               } else return null;
      //             })}

      //         </Select>    */}
      //               <Link to={'/project-landing'}
      //                 // to={"/leads"}
      //                 className="btn logout-btn mb-2"
      //                 style={{ width: "90%", backgroundColor: 'var(--themeColor)', color: 'white' }}>
      //               View all projects
      //               </Link>
      //  </Col>
      //   ) :null

      // }
      //           <Link to={'/dashboard'} className="loggedin_user">
      //             {this.props.userData.profilePicture ? (
      //               <img alt={this.props.userData.fullName ? this.props.userData.fullName : 'Subham Chatterjee'} src={this.props.userData.profilePicture} onError={(event) => event.target.setAttribute("src", "/images/user_1.png")} className="img-circle" />
      //             ) : (
      //               <img alt={this.props.userData.fullName ? this.props.userData.fullName : 'Subham Chatterjee'} src="/images/user_1.png" className="img-circle" />
      //             )}
      //             <span>{this.props.userData.fullName ? this.props.userData.fullName : 'Subham Chatterjee'}</span>
      //           </Link>
      //         </div>
      //       </Col>

      //     </Row>
      //   </div>
      <div></div>
    )
  }
}
