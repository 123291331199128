/* eslint-disable jsx-a11y/anchor-is-valid */
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import ToolkitProvider from 'react-bootstrap-table2-toolkit' // , { Search }
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { Switch, Select, Input, Icon } from 'antd'
import Swal from 'sweetalert2'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import CopyClipboard from 'react-copy-clipboard'

const readCookie = require('../cookie.js').readCookie
const ValidateData = require('../../../ValidateData.js')

// const { SearchBar } = Search;
const { Option, OptGroup } = Select

const getRatiosString = (creditCount, selfInvestmentCount) => {
  if (creditCount <= 0) return [].join('')

  if (selfInvestmentCount <= 0)
    return `Please ensure that the payment claims are raised only once a month and includes only credit linked candidates`

  let ratios = []
  // for ()
  let numer = Math.round(creditCount / 5)
  let denom = Math.round(selfInvestmentCount / 5)
  for (let i = 1; i <= 4; i++) {
    ratios.push(`${numer * i}:${denom * i}`)
  }
  // while (hcf < 5) {
  // 	numer = numer+1;
  // 	hcf = findHCF(numer, denom);
  // 	console.log(hcf, numer, denom);

  // }
  ratios.push(`${creditCount}:${selfInvestmentCount}`)
  return `Please ensure that the payment claims are raised only once a month and in the ratio (credit linked : 100 % self investment) - ${ratios.join(' or ')}`
}
const PlatformOrgsMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={props.defaultZoom}
    defaultCenter={props.defaultCenter}
  >
    {props.orgs.map((org, orgIndex) => {
      return (
        <Marker
          key={orgIndex}
          position={{ lat: org.location.latitude, lng: org.location.longitude }}
          onClick={() => props.onMarkerClick(orgIndex)}
        />
      )
    })}
  </GoogleMap>
))

export default class PlatformOrgs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showAllScks: false,
      userType: null,
      orgs: [],
      sizePerPage: 25,
      sizePerPageList: [25, 50, 100, 200, 500],
      selectedOrg: null,
      showTableView: true,
      verifyingIFSC: null,
      filterAllStates: [],
      filterAllDistricts: [],
      filterAllClusters: [],
      filterObj: { district: '', state: '', cluster: '', searchText: '' },
      newNGO: {
        ngoName: '',
        email: '',
        mobileNumber: '',
        fullName: '',
        logo: '',
        state: '',
        district: '',
        city: '',
        pincode: '',
        address1: '',
        address2: '',
        location: {
          latitude: '',
          longitude: '',
          label: '',
        },
        leProfileUrl: '',
        ngoUrl: '',
        bankIfsc: '',
        bankAccNo: '',
        bankBranchAddress: '',
      },
      newNGOModalVisibility: false,
      allCities: [],
      allStates: [],
      allDistricts: [],
      errorObj: {},
      defaultCenter: { lat: 20.5937, lng: 78.9629 },
      defaultZoom: 5,
      activeDistricts: 0,
    }
  }
  showIFSCIcon = () => {
    let verifyingIFSC = this.state.verifyingIFSC
    if (verifyingIFSC === 'Verified') {
      return (
        <Icon style={{ marginBottom: 6 }} type="check" title="IFSC Verified" />
      )
    } else if (verifyingIFSC === 'Verifying') {
      return (
        <Icon
          style={{ marginBottom: 6 }}
          type="loading"
          title="Verifying IFSC"
        />
      )
    } else if (verifyingIFSC === 'Failed') {
      return (
        <Icon style={{ marginBottom: 6 }} type="warning" title="Invalid IFSC" />
      )
    } else return null
  }
  verifyIFSC = e => {
    let ifsc = e.target.value,
      errorObj = this.state.errorObj
    if (ifsc) {
      this.setState({ verifyingIFSC: 'Verifying' })
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/bank-branches/verify-ifsc/' +
          ifsc,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            delete errorObj['bankIFSC']
            this.setState({ verifyingIFSC: data.result, errorObj })
          } else {
            errorObj['bankIFSC'] = 'Invalid Bank IFSC'
            this.setState({ verifyingIFSC: 'Failed', errorObj })
          }
        })
        .catch(err => {
          errorObj['bankIFSC'] = 'Invalid Bank IFSC'
          this.setState({ verifyingIFSC: 'Failed', errorObj })
        })
    } else {
      delete errorObj['bankIFSC']
      this.setState({ verifyingIFSC: null, errorObj })
    }
  }
  componentDidMount() {
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/user/user-type/' +
        this.props.match.params.type,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ userType: data.data })
        }
      })

    this.getOrgs()

    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({
            allStates: data.data.states,
            filterAllStates: data.data.states,
          })
        }
      })
  }

  getOrgs = (
    filterObj = this.state.filterObj,
    showAllScks = this.state.showAllScks
  ) => {
    this.setState({ loading: true })
    let API_QUERY = '?'
    if (!showAllScks) {
      API_QUERY += `status=Active&`
    }
    let {
      // cluster,
      district,
      searchText,
      state,
    } = filterObj
    // if (cluster) {
    // 	API_QUERY += `cluster=${cluster}&`;
    // }
    if (district) {
      API_QUERY += `district=${district}&`
    }
    if (searchText) {
      API_QUERY += `searchText=${searchText}&`
    }
    if (state) {
      API_QUERY += `state=${state}&`
    }
    // fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo' + query, {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo' + API_QUERY, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          let defaultCenter = this.state.defaultCenter,
            defaultZoom = this.state.defaultZoom
          if (data.ngos.length === 1) {
            defaultZoom = 9
            defaultCenter = {
              lat: data.ngos[0].location.latitude,
              lng: data.ngos[0].location.latitude,
            }
          }
          this.setState({
            orgs: data.ngos,
            defaultCenter,
            defaultZoom,
            activeDistricts: data.activeDistricts || 0,
            loading: false,
            filterAllDistricts: data.districts || [],
          })
        }
      })
  }

  formatName = (cell, row) => {
    if (cell && row._id) {
      return (
        <a
          className="org-name"
          href={'/org-types/' + this.props.match.params.type + '/' + row._id}
        >
          {cell}
        </a>
      )
    } else return null
  }

  formatDate = (cell, row) => {
    if (cell) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return null
  }

  formatAddressObj = (cell, row) => {
    if (cell && cell._id && cell.name) {
      return cell.name
    } else if (cell !== 'null' && typeof cell === 'string') return cell
    else if (cell === 'null' && typeof cell === 'string') return 'N/A'
    else return null
  }

  formatLocation = (cell, row) => {
    if (cell && cell.latitude && cell.longitude) {
      return (
        <a
          id="org-location"
          href={`https://maps.google.com/?q=${cell.latitude},${cell.longitude}`}
          target="#"
        >
          <span>
            {cell.latitude.toString().slice(0, 8)} <br />{' '}
            {cell.longitude.toString().slice(0, 8)}
          </span>
        </a>
      )
    } else return null
  }

  enterprisesSetupFormatter = cell => {
    if (cell !== undefined) {
      return (
        <span>
          {cell}{' '}
          {70 - cell > 0 ? (
            <span style={{ color: 'red' }}>({70 - cell} remaining)</span>
          ) : (
            <span style={{ color: 'green' }}>(none remaining)</span>
          )}
        </span>
      )
    } else return null
  }
  creditLinkageFormatter = cell => {
    if (cell !== undefined) {
      return (
        <span>
          {cell}{' '}
          {35 - cell > 0 ? (
            <span style={{ color: 'red' }}>({35 - cell} remaining)</span>
          ) : (
            <span style={{ color: 'green' }}>(none remaining)</span>
          )}
        </span>
      )
    } else return null
  }
  auditCountFormatter = (cell, row) => {
    if (cell !== undefined) {
      return (
        <span>
          {cell}{' '}
          {row.enterprisesCreatedSoFar - cell > 0 ? (
            <span style={{ color: 'red' }}>
              ({row.enterprisesCreatedSoFar - cell} remaining)
            </span>
          ) : (
            <span style={{ color: 'green' }}>(none remaining)</span>
          )}
        </span>
      )
    } else return null
  }
  formatDateOfDeboarding = (cell, row) =>
    row.status === 'Inactive' && cell ? moment(cell).format('DD/MM/YYYY') : '-'

  formatReasonForDeboarding = (cell, row) =>
    row.status === 'Inactive' ? cell || '-' : '-'

  formatTextToCopy = (_cell, row) => {
    let createdSoFar = row.enterprisesCreatedSoFar || 0,
      creditLinked = row.creditLinkedCandidates || 0,
      selfInvestment = row.selfInvestmentCandidates || 0,
      pendingEnterprises = 70 - createdSoFar > 0 ? 70 - createdSoFar : 0,
      pendingCreditLink = 35 - creditLinked > 0 ? 35 - creditLinked : 0

    let noticeString = `In this district, ${createdSoFar} enterprises have been created so far (${creditLinked} with credit-linkage and ${selfInvestment} with 100 % self - investment).\n `
    if (pendingCreditLink !== 0) {
      noticeString += `To complete the mission, ${
        pendingEnterprises > pendingCreditLink
          ? `${pendingEnterprises} more startups need to be created, out of which at least ${pendingCreditLink} need to be credit - linked (loan supported). `
          : `at least ${pendingCreditLink} new enterprises need to be credit - linked (loan supported).`
      } The more, the better.\n ${getRatiosString(pendingCreditLink, pendingEnterprises - pendingCreditLink)}.`
    } else if (pendingEnterprises > 0) {
      noticeString += `To complete the mission, ${pendingEnterprises} more startups need to be created`
    }
    return (
      <>
        <p style={{ fontSize: '10px' }}>
          {' '}
          {noticeString.replace('/n', '<br/>')} &nbsp;{' '}
          <CopyClipboard text={noticeString} suffix="(click to copy)">
            <i class="fa fa-copy " style={{ color: 'green' }} />
          </CopyClipboard>
        </p>
      </>
    )
  }
  formatAddedBy = (cell, row) => {
    if (cell && cell._id && cell.firstName) {
      return (
        <a>
          {cell.lastName
            ? cell.firstName + ' ' + cell.lastName
            : cell.firstName}
        </a>
      )
    } else if (cell && cell._id && cell.fullName) {
      return <a>{cell.fullName}</a>
    } else return null
  }

  formatStatus = (cell, row) => {
    if (cell && row._id) {
      if (cell === 'Active' || cell === true) {
        return (
          <span
            className="org-status-block active"
            // onClick={this.changeStatus.bind(this, row._id, 'Active')}
          >
            Active
          </span>
        )
      } else if (cell === 'Inactive' || cell === false) {
        return (
          <span
            className="org-status-block inactive"
            // onClick={this.changeStatus.bind(this, row._id, 'Inactive')}
          >
            Inactive
          </span>
        )
      } else {
        return (
          <span
            className="org-status-block"
            // onClick={this.changeStatus.bind(this, row._id, cell)}
          >
            {cell}
          </span>
        )
      }
    } else return null
  }

  changeStatus = async (id, status) => {
    if (id && status) {
      Swal.fire({
        title: 'Select to update status',
        input: 'select',
        inputOptions: {
          Active: 'Active',
          Inactive: 'Inactive',
        },
        inputPlaceholder: 'Select a status',
        showCancelButton: true,
        // 				inputValidator: (value) => {
        // 					// if (value !== status) {
        // 						if(value=='Inactive')
        // 						{
        // 				Swal.fire({
        // 				title: 'Select reason to mark as inactive',
        // 				input: 'select',
        // 				inputOptions: {
        // 					"Poor performance, not able to meet desired outcomes":"Poor performance, not able to meet desired outcomes",
        // "Passiveness despite numerous online and in-person training":"Passiveness despite numerous online and in-person training",
        // "Did not start the mission at all (<15 profiles)":"Did not start the mission at all (<15 profiles)",
        // "Organization adversely affected by COVID 19":"Organization adversely affected by COVID 19",
        // "Took the advance and left the mission":"Took the advance and left the mission",
        // "Got bigger opportunity in livelihood space":"Got bigger opportunity in livelihood space",
        // "Got bigger opportunity in livelihood space owing to Mission Swavalamban":"Got bigger opportunity in livelihood space owing to Mission Swavalamban",
        // "SCK's internal resource crunch":"SCK's internal resource crunch",
        // "Fraudulent or misleading activities":"Fraudulent or misleading activities",
        // "Left the mission after taking initial payment":"Left the mission after taking initial payment"
        // 				},
        // 				inputPlaceholder: 'Select a reason',
        // 				showCancelButton: true,
        // 				inputValidator: (reasonValue) => {
        // 					// if (reasonValue !== status) {
        // 						let orgs = this.state.orgs;
        // 						for (var i = 0; i < orgs.length; i++) {
        // 							if (orgs[i]._id === id) {
        // 								orgs[i].status = value;
        // 								fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo/' + orgs[i]._id, {
        // 									method: "PATCH",
        // 									headers: {
        // 										'Content-Type': 'application/json',
        // 										'x-auth-token': JSON.parse(readCookie('access_token'))
        // 									},
        // 									body: JSON.stringify({ status: value, reasonForDeboarding:reasonValue })
        // 								}).then((response) => {
        // 									return response.json();
        // 								}).then((data) => {
        // 									if (data.status) {
        // 										this.setState({ orgs });
        // 										if (!this.state.showTableView && this.state.selectedOrg !== null) {
        // 											let selectedOrg = this.state.selectedOrg;
        // 											selectedOrg.status = value;
        // 											selectedOrg.reasonForDeboarding = reasonValue;
        // 											this.setState({ selectedOrg });
        // 										}
        // 										this.notifySuccess('Successfully updated the status of the organisation!');
        // 									} else {
        // 										this.notifyError("Update NGO status failed!");
        // 									}
        // 								});
        // 							}
        // 						}
        // 					// }
        // 				}
        // 			});
        // 						}
        // 						else if(value !== status){
        // 							console.log(value, 'hhdjd')
        // 					let orgs = this.state.orgs;
        // 						for (var i = 0; i < orgs.length; i++) {
        // 							if (orgs[i]._id === id) {
        // 								orgs[i].status = value;
        // 								fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo/' + orgs[i]._id, {
        // 									method: "PATCH",
        // 									headers: {
        // 										'Content-Type': 'application/json',
        // 										'x-auth-token': JSON.parse(readCookie('access_token'))
        // 									},
        // 									body: JSON.stringify({ status: value })
        // 								}).then((response) => {
        // 									return response.json();
        // 								}).then((data) => {
        // 									if (data.status) {
        // 										this.setState({ orgs });
        // 										if (!this.state.showTableView && this.state.selectedOrg !== null) {
        // 											let selectedOrg = this.state.selectedOrg;
        // 											selectedOrg.status = value;
        // 											this.setState({ selectedOrg });
        // 										}
        // 										this.notifySuccess('Successfully updated the status of the organisation!');
        // 									} else {
        // 										this.notifyError("Update NGO status failed!");
        // 									}
        // 								});
        // 							}
        // 						}
        // 						}

        // 					// }
        // 				}
      }).then(x => {
        const value = x.value
        if (value && (value !== status || value === 'Inactive')) {
          if (value === 'Inactive') {
            Swal.fire({
              title: 'Select reason to mark as inactive',
              input: 'select',
              inputOptions: {
                'Poor performance, not able to meet desired outcomes':
                  'Poor performance, not able to meet desired outcomes',
                'Passiveness despite numerous online and in-person training':
                  'Passiveness despite numerous online and in-person training',
                'Did not start the mission at all (<15 profiles)':
                  'Did not start the mission at all (<15 profiles)',
                'Organization adversely affected by COVID 19':
                  'Organization adversely affected by COVID 19',
                'Took the advance and left the mission':
                  'Took the advance and left the mission',
                'Got bigger opportunity in livelihood space':
                  'Got bigger opportunity in livelihood space',
                'Got bigger opportunity in livelihood space owing to Mission Swavalamban':
                  'Got bigger opportunity in livelihood space owing to Mission Swavalamban',
                "SCK's internal resource crunch":
                  "SCK's internal resource crunch",
                'Fraudulent or misleading activities':
                  'Fraudulent or misleading activities',
                'Left the mission after taking initial payment':
                  'Left the mission after taking initial payment',
                'District discontinued': 'District discontinued',
              },
              inputPlaceholder: 'Select a reason',
              showCancelButton: true,
              inputValidator: reasonValue => {
                if (reasonValue) {
                  let orgs = this.state.orgs
                  for (var i = 0; i < orgs.length; i++) {
                    if (orgs[i]._id === id) {
                      orgs[i].status = value
                      orgs[i].reasonForDeboarding = reasonValue
                      orgs[i].deactivatedOn = moment()

                      fetch(
                        process.env.REACT_APP_API_URL +
                          '/api/v1/ngo/' +
                          orgs[i]._id,
                        {
                          method: 'PATCH',
                          headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': JSON.parse(
                              readCookie('access_token')
                            ),
                          },
                          body: JSON.stringify({
                            status: value,
                            reasonForDeboarding: reasonValue,
                          }),
                        }
                      )
                        .then(response => {
                          return response.json()
                        })
                        .then(data => {
                          if (data.status) {
                            this.setState({ orgs })
                            if (
                              !this.state.showTableView &&
                              this.state.selectedOrg !== null
                            ) {
                              let selectedOrg = this.state.selectedOrg
                              selectedOrg.status = value
                              selectedOrg.reasonForDeboarding = reasonValue
                              this.setState({ selectedOrg })
                            }
                            this.notifySuccess(
                              'Successfully updated the status of the organisation!'
                            )
                          } else {
                            this.notifyError('Update NGO status failed!')
                          }
                        })
                    }
                  }
                }
              },
            })
          } else {
            let orgs = this.state.orgs
            for (var i = 0; i < orgs.length; i++) {
              if (orgs[i]._id === id) {
                orgs[i].status = value
                orgs[i].reasonForDeboarding = ''
                orgs[i].deactivatedOn = ''
                fetch(
                  process.env.REACT_APP_API_URL + '/api/v1/ngo/' + orgs[i]._id,
                  {
                    method: 'PATCH',
                    headers: {
                      'Content-Type': 'application/json',
                      'x-auth-token': JSON.parse(readCookie('access_token')),
                    },
                    body: JSON.stringify({ status: value }),
                  }
                )
                  .then(response => {
                    return response.json()
                  })
                  .then(data => {
                    if (data.status) {
                      this.setState({ orgs })
                      if (
                        !this.state.showTableView &&
                        this.state.selectedOrg !== null
                      ) {
                        let selectedOrg = this.state.selectedOrg
                        selectedOrg.status = value
                        this.setState({ selectedOrg })
                      }
                      this.notifySuccess(
                        'Successfully updated the status of the organisation!'
                      )
                    } else {
                      this.notifyError('Update NGO status failed!')
                    }
                  })
              }
            }
          }
        }
      })
    }
  }

  changeViewType = () => {
    this.setState({ showTableView: !this.state.showTableView })
  }

  handlePlatformOrgsMapMarkerClick = index => {
    this.setState({ selectedOrg: this.state.orgs[index] })
  }

  toggleNewNGOModalVisibility = () => {
    this.setState({
      newNGOModalVisibility: !this.state.newNGOModalVisibility,
      newNGO: {
        ngoName: '',
        email: '',
        mobileNumber: '',
        fullName: '',
        logo: '',
        state: '',
        district: '',
        city: '',
        pincode: '',
        address1: '',
        address2: '',
        location: { latitude: '', longitude: '', label: '' },
        leProfileUrl: '',
        ngoUrl: '',
      },
    })
  }

  changeNewNGOHandler = (type, e) => {
    let newNGO = this.state.newNGO,
      errorObj = this.state.errorObj
    if (type === 'locationUrl')
      ValidateData.urlValidate(e.target.value, errorObj)
    if (type === 'email') ValidateData.emailValidate(e.target.value, errorObj)
    if (type === 'mobileNumber')
      ValidateData.mobileValidate('mobileNumber', e.target.value, errorObj)
    if (type === 'fullName') ValidateData.nameValidate(e.target.value, errorObj)
    if (type === 'bankAccNo')
      ValidateData.bankAccNoValidate(e.target.value, errorObj)

    this.setState({ errorObj })

    if (type === 'state') {
      newNGO[type] = e
      let focusStates = process.env.REACT_APP_FOCUS_STATES
      if (!focusStates)
        focusStates = [
          'Bihar',
          'Jharkhand',
          'Odisha',
          'Telangana',
          'Uttar Pradesh',
        ]

      if (e && focusStates.includes(e)) {
        newNGO['district'] = ''
        newNGO['city'] = ''
        this.setState({ newNGO })
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/location/get-address?stateId=' +
            e,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({
                allDistricts: data.focusDistricts,
                allCities: [],
              })
            }
          })
      } else {
        newNGO['district'] = null
        newNGO['city'] = null
        this.setState({ newNGO, allDistricts: [], allCities: [] })
      }
    } else if (type === 'district') {
      newNGO[type] = e
      newNGO['city'] = ''
      this.setState({ newNGO })
      if (e) {
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/location/get-address?stateId=' +
            newNGO.state +
            '&districtId=' +
            e,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({ allCities: data.cities })
            }
          })
      }
    } else if (type === 'logo') {
      if (e.target.files.length) newNGO['logo'] = e.target.files[0]
      else newNGO['logo'] = ''
      this.setState({ newNGO })
    } else if (type === 'officePhotos') {
      let officePhotos = []
      if (e.target.files.length) {
        if (e.target.files.length + officePhotos.length <= 3) {
          this.setState({ maxImagesErr: '' })
          this.updateErrorObject('officePhotos', true, '')
          newNGO['officePhotos'] = [...officePhotos, ...e.target.files]
        } else {
          //newNGO['officePhotos'] = [];
          this.updateErrorObject(
            'officePhotos',
            false,
            'At most, only three images could be added'
          )
          this.notifyError('At most, only three images could be added')
        }
      } else {
        newNGO['officePhotos'] = []
        this.updateErrorObject('officePhotos', true, '')
        this.setState({ maxImagesErr: '' })
      }

      this.setState({ newNGO })
    } else {
      newNGO[type] = e.target.value
      this.setState({ newNGO })
    }
  }

  onPreferenceLocationChange = preferenceLocation => {
    let newNGO = this.state.newNGO
    newNGO['location']['label'] = preferenceLocation
    this.setState({ newNGO }, () => {
      geocodeByAddress(preferenceLocation)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          let newNGO = this.state.newNGO
          newNGO['location']['latitude'] = latLng.lat
          newNGO['location']['longitude'] = latLng.lng
          this.setState({ newNGO })
        })
        .catch(error => console.error('Error', error))
    })
  }

  submitNewNGOModal = () => {
    let newNGO = this.state.newNGO,
      errorObj = this.state.errorObj,
      specialError = false
    if (errorObj['bankIfsc']) {
      this.notifyError('Please mention a valid IFSC')
      return
    } else if (errorObj['bankAccNo']) {
      this.notifyError("Please ensure you're entering a valid Account Number!")
      return
    }
    if (!newNGO.location) specialError = true
    else {
      if (
        !newNGO.location.label ||
        !newNGO.location.latitude ||
        !newNGO.location.longitude
      )
        specialError = true
    }

    if (
      !Object.keys(errorObj).length &&
      !specialError &&
      newNGO.fullName &&
      newNGO.mobileNumber &&
      newNGO.ngoName &&
      newNGO.email &&
      newNGO.pincode &&
      newNGO.address1 &&
      newNGO.state &&
      newNGO.logo &&
      newNGO.locationUrl
    ) {
      let data = new FormData()
      data.append('logo', newNGO.logo)
      data.append('city', newNGO.city)
      data.append('email', newNGO.email)
      data.append('state', newNGO.state)
      data.append('ngoUrl', newNGO.ngoUrl)
      data.append('ngoName', newNGO.ngoName)
      data.append('pincode', newNGO.pincode)
      data.append('fullName', newNGO.fullName)
      data.append('address1', newNGO.address1)
      data.append('address2', newNGO.address2)
      data.append('district', newNGO.district)
      data.append('mobileNumber', newNGO.mobileNumber)
      data.append('leProfileUrl', newNGO.leProfileUrl)
      data.append('location', JSON.stringify(newNGO.location))
      data.append('locationUrl', newNGO.locationUrl)
      data.append('bankIfsc', newNGO.bankIfsc)
      data.append('bankAccNo', newNGO.bankAccNo)
      data.append('bankBranchAddress', newNGO.bankBranchAddress)

      if (newNGO.officePhotos !== undefined && newNGO.officePhotos.length) {
        let officeLinks = []
        for (let index = 0; index < newNGO.officePhotos.length; index++) {
          if (typeof newNGO.officePhotos[index] !== 'string')
            data.append('officephoto-' + index, newNGO.officePhotos[index])
          else {
            officeLinks.push(newNGO.officePhotos[index])
          }
        }
        data.append('otherImageLinks', officeLinks)
      }
      fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
        method: 'POST',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: data,
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            this.toggleNewNGOModalVisibility()
            this.notifySuccess('Successfully added new NGO!')
            this.getOrgs()
          }
        })
    } else {
      this.notifyError('Please fill in the required fields!')
      specialError
        ? this.updateErrorObject('location', false, 'Location required')
        : this.updateErrorObject('location', true, '')
      newNGO.fullName
        ? this.updateErrorObject('fullName', true, '')
        : this.updateErrorObject('fullName', false, 'Full Name required')
      newNGO.mobileNumber
        ? this.updateErrorObject('mobileNumber', true, '')
        : this.updateErrorObject('mobileNumber', false, 'Mobile No. required')
      newNGO.ngoName
        ? this.updateErrorObject('ngoName', true, '')
        : this.updateErrorObject('ngoName', false, 'Ngo Name required')
      newNGO.email
        ? this.updateErrorObject('email', true, '')
        : this.updateErrorObject('email', false, 'Email required')
      newNGO.pincode
        ? this.updateErrorObject('pincode', true, '')
        : this.updateErrorObject('pincode', false, 'Pincode required')
      newNGO.address1
        ? this.updateErrorObject('address1', true, '')
        : this.updateErrorObject('address1', false, 'Address 1 required')
      newNGO.state
        ? this.updateErrorObject('state', true, '')
        : this.updateErrorObject('state', false, 'State required')
      newNGO.district
        ? this.updateErrorObject('district', true, '')
        : this.updateErrorObject('district', false, 'District required')
      newNGO.city
        ? this.updateErrorObject('city', true, '')
        : this.updateErrorObject('city', false, 'City required')
      newNGO.logo
        ? this.updateErrorObject('logo', true, '')
        : this.updateErrorObject('logo', false, 'Logo required')
      newNGO.locationUrl
        ? this.updateErrorObject('locationUrl', true, '')
        : this.updateErrorObject('locationUrl', false, 'Location URL required')
    }
  }

  updateErrorObject = (key, isValid, err) => {
    let errorObj = this.state.errorObj
    if (isValid) delete errorObj[key]
    else errorObj[key] = err
    this.setState({ errorObj })
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === 'string') {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
    }
  }

  handleStatusChange = e => {
    this.setState({ showAllScks: e })
    this.getOrgs(this.state.filterObj, e)
  }

  notifySuccess = text => toast.success(text)

  notifyError = text => toast.error(text)

  fetchFilterData = (filterObj = this.state.filterObj) => {
    // const { state, district, cluster, searchText } = filterObj;
    // const query = '?' + state ? ('state=' + state + '&') : '' + district ? ('district=' + district + '&') : '' + cluster ? ('cluster=' + cluster + '&') : '' + searchText ? ('searchText=' + searchText + '&') : '';
  }

  changeFilter = (e, type) => {
    // let {filterObj} = this.state;
    let filterObj =
      type === 'searchText'
        ? { ...this.state.filterObj, [type]: e.target.value }
        : { ...this.state.filterObj, [e]: type }
    this.setState({ filterObj })
    // this.fetchFilterData(filterObj);
    this.getOrgs(filterObj, this.state.showAllScks)
  }

  render() {
    const columns = [
      {
        text: '',
        sort: false,
        hidden: true,
        export: true,
        dataField: '_id',
        searchable: false,
      },
      {
        sort: true,
        export: true,
        text: 'Name',
        hidden: false,
        searchable: true,
        dataField: 'ngoName',
        formatter: this.formatName,
        headerStyle: () => {
          return { width: '180px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: false,
        text: 'State',
        searchable: true,
        dataField: 'state',
        formatter: this.formatAddressObj,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: false,
        searchable: true,
        text: 'District',
        dataField: 'district',
        formatter: this.formatAddressObj,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: false,
        searchable: true,
        dataField: 'membersCount',
        text: 'Total members',
        headerStyle: () => {
          return { width: '80px', textAlign: 'center' }
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        searchable: true,
        text: 'Location',
        dataField: 'location',
        formatter: this.formatLocation,
        headerStyle: () => {
          return { width: '90px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: false,
        searchable: true,
        text: 'Total Paid (INR)',
        dataField: 'amountClaimed',
        headerStyle: () => {
          return { width: '120px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: false,
        searchable: true,
        text: 'Added On',
        dataField: 'addedOn',
        formatter: this.formatDate,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' }
        },
      },
      {
        sort: false,
        export: true,
        hidden: false,
        searchable: true,
        text: 'Added By',
        dataField: 'addedBy',
        formatter: this.formatAddedBy,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' }
        },
      },
      {
        sort: false,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type === 'sidbi',
        text: 'Status',
        searchable: true,
        dataField: 'status',
        formatter: this.formatStatus,
        headerStyle: () => {
          return { width: '80px', textAlign: 'center' }
        },
      },

      {
        sort: false,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le',
        searchable: true,
        text: 'Notice',
        dataField: 'textToCopy',
        formatter: this.formatTextToCopy,
        headerStyle: () => {
          return { width: '270px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le',
        searchable: true,
        text: 'Date of deboarding',
        dataField: 'deactivatedOn',
        formatter: this.formatDateOfDeboarding,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le' && false,
        searchable: true,
        text: 'Reason for deboarding',
        dataField: 'reasonForDeboarding',
        formatter: this.formatReasonForDeboarding,
        headerStyle: () => {
          return { width: '100px', textAlign: 'center' }
        },
      },

      {
        sort: true,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le',
        searchable: true,
        text: 'Total Enterprises Setup in the District',
        dataField: 'enterprisesCreatedSoFar',
        formatter: this.enterprisesSetupFormatter,
        headerStyle: () => {
          return { width: '85px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le',
        searchable: true,
        text: 'Enterprsies successfully Audited',
        dataField: 'auditSuccessful',
        formatter: this.auditCountFormatter,
        headerStyle: () => {
          return { width: '85px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le',
        searchable: true,
        text: 'Credit Linked Enterprises in the District',
        dataField: 'creditLinkedCandidates',
        formatter: this.creditLinkageFormatter,
        headerStyle: () => {
          return { width: '85px', textAlign: 'center' }
        },
      },
      {
        sort: true,
        export: true,
        hidden: JSON.parse(readCookie('userData')).type !== 'le',
        searchable: true,
        text: 'Self Investment Enterprises in the District',
        dataField: 'selfInvestmentCandidates',
        headerStyle: () => {
          return { width: '85px', textAlign: 'center' }
        },
      },
    ]
    const pagination = paginationFactory({
      sizePerPage: this.state.sizePerPage,
      sizePerPageList: this.state.sizePerPageList,
    })

    const options = { types: ['(regions)'] },
      renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
          <input {...getInputProps({ className: 'form-control' })} />
          <div className="autocomplete-dropdown-container">
            {suggestions.map(suggestion => (
              <div {...getSuggestionItemProps(suggestion)}>
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )

    if (this.state.userType !== null) {
      return (
        <div className="org-type-page page-container">
          <Col
            style={{ padding: '0px', marginBottom: '20px' }}
            className="d-flex align-items-center org-type-header justify-content-between"
          >
            <div className="d-flex align-items-center org-type-header-left-container">
              <a
                className="go-back-btn d-flex align-items-center"
                href="/settings/user-types"
              >
                <i className="fas fa-chevron-left mr5"></i>Back
              </a>
              <h3 style={{ margin: '0px' }}>{this.state.userType.name}</h3>
            </div>

            <div className="d-flex align-items-center org-type-header-right-container">
              <span>Map View</span>
              <Switch
                className="mr5 ml5"
                checked={this.state.showTableView}
                onChange={this.changeViewType}
              />
              <span>Table View</span>
            </div>
          </Col>

          {this.state.showTableView ? (
            <Col md={12} className="platform-orgs-table-container">
              <ToolkitProvider
                keyField="_id"
                data={this.state.orgs}
                columns={columns}
                search={{ searchFormatted: true }}
              >
                {props => (
                  <div>
                    <Row className="mt10">
                      <Col
                        md={8}
                        className="d-flex"
                        style={{ alignItems: 'center' }}
                      >
                        <span className="total-organisations m0">
                          {'Total organisations: ' + this.state.orgs.length}
                        </span>
                        <span className="total-organisations m2">
                          {'SCKs operational in: ' +
                            this.state.activeDistricts || 0}{' '}
                          {(this.state.activeDistricts || 0) === 1
                            ? `district`
                            : `districts`}
                        </span>
                        {JSON.parse(readCookie('userData')).type !== 'sidbi' ? (
                          <button
                            type="button"
                            className={
                              'btn btn-success react-bs-table-add-btn hidden-print'
                            }
                            style={{ marginLeft: '5px' }}
                            onClick={this.toggleNewNGOModalVisibility}
                          >
                            <span>
                              <i
                                className="fa fa-user-plus"
                                aria-hidden="true"
                              ></i>
                              Add Organisation
                            </span>
                          </button>
                        ) : null}
                        {/*<button type='button' className={'btn btn-success react-bs-table-add-btn hidden-print'}
													style={{marginLeft: '5px'}} onClick={this.deleteMultipleUser} disabled={this.state.deleteButtonDisabled}>
													<span>
														<i className="fa fa-trash" aria-hidden="true"></i>
														Remove Users
													</span>
												</button>*/}
                      </Col>

                      <Col md={4}>
                        <Row>
                          {' '}
                          <label>
                            {' '}
                            <Switch
                              onChange={this.handleStatusChange.bind(this)}
                              defaultChecked={this.state.showAllScks}
                              checkedChildren="All"
                              unCheckedChildren="Only Active"
                            />
                            &nbsp;{' '}
                            <strong style={{ fontSize: 14 }}>
                              Show all SCKs
                            </strong>
                          </label>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <label className="control-label">State</label>

                        <Select
                          showSearch
                          style={{ width: '200px' }}
                          value={this.state.filterObj.state}
                          onChange={this.changeFilter.bind(this, 'state')}
                        >
                          <Option value="">All</Option>
                          <OptGroup label="Focus states">
                            {this.state.filterAllStates.map((state, index) => (
                              <Option key={index} value={state.name || ''}>
                                {state.name || ''}
                              </Option>
                            ))}
                          </OptGroup>
                        </Select>
                      </Col>
                      <Col md={3}>
                        <label className="control-label">District</label>

                        <Select
                          showSearch
                          style={{ width: '200px' }}
                          value={this.state.filterObj.district}
                          onChange={this.changeFilter.bind(this, 'district')}
                        >
                          <Option value="">All</Option>
                          <OptGroup label="Focus Districts">
                            {this.state.filterAllDistricts.map(
                              (district, index) => (
                                <Option key={index} value={district}>
                                  {district}
                                </Option>
                              )
                            )}
                          </OptGroup>
                        </Select>
                      </Col>
                      <Col
                        md={6}
                        style={{ marginTop: '2%' }}
                        className="platform-orgs-table-search-container"
                      >
                        <Input
                          onChange={e => {
                            this.changeFilter(e, 'searchText')
                          }}
                          placeholder="Search by State, District, Name, Mobile, Email"
                          className="platform-orgs-table-search"
                        />
                        {/* <SearchBar {...props.searchProps} searchText={this.state.filterObj.searchText} placeholder="Search by Name/Registration Date/Mobile/Email" className="platform-orgs-table-search" delay={500} /> */}
                      </Col>
                      {/* <Col md={4}>
												<label className="control-label">Cluster</label>
												<Select
													showSearch
													style={{ width: "200px" }}
													value={this.state.filterObj.cluster}
													onChange={this.changeFilter.bind(this, 'cluster')}
												>
													<Option value="">All</Option>
													<OptGroup label="Focus Clusters">
														{
															this.state.filterAllClusters.map((cluster, index) => <Option key={index} value={cluster}>{cluster}</Option>)
														}
													</OptGroup>
												</Select>
											</Col> */}

                      {/* <Col md={4}>

											</Col> */}
                    </Row>
                    <LoadingOverlay
                      active={this.state.loading || false}
                      spinner={<BounceLoader />}
                    >
                      <BootstrapTable
                        id="platform-orgs-table"
                        {...props.baseProps}
                        // footerData={this.footerData}
                        selectRow={this.state.selectRowProp}
                        ref={table => {
                          this.table = table
                        }}
                        pagination={pagination}
                      />
                    </LoadingOverlay>
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          ) : (
            <Col md={12} className="d-flex platform-orgs-map-container">
              <PlatformOrgsMap
                orgs={this.state.orgs}
                defaultCenter={this.state.defaultCenter}
                defaultZoom={this.state.defaultZoom}
                onMarkerClick={this.handlePlatformOrgsMapMarkerClick}
                containerElement={
                  <div style={{ height: '450px', width: '100%' }} />
                }
                mapElement={<div style={{ height: '100%', width: '100%' }} />}
              />
              {this.state.selectedOrg !== null ? (
                <Col md={4} className="platform-orgs-map-details-container">
                  <Row>
                    <span className="heading">NGO Name : </span>
                    <span className="value">
                      {this.state.selectedOrg.ngoName}
                    </span>
                  </Row>
                  <Row>
                    <span className="heading">State : </span>
                    <span className="value">
                      {this.state.selectedOrg.state}
                    </span>
                  </Row>
                  <Row>
                    <span className="heading">District : </span>
                    <span className="value">
                      {this.state.selectedOrg.district}
                    </span>
                  </Row>
                  <Row>
                    <span className="heading">Added On : </span>
                    <span className="value">
                      {moment(this.state.selectedOrg.addedOn).format(
                        'DD/MM/YYYY hh:mm A'
                      )}
                    </span>
                  </Row>
                  <Row>
                    <span className="heading">Total Members : </span>
                    <span className="value">
                      {this.state.selectedOrg.memberCount}
                    </span>
                  </Row>
                  <Row>
                    <span className="heading">Status : </span>
                    <span className="value">
                      {this.state.selectedOrg.status.toString().toUpperCase()}
                    </span>
                    {JSON.parse(readCookie('userData')).type !== 'sidbi' ? (
                      <span
                        className="selected-org-change-status"
                        onClick={this.changeStatus.bind(
                          this,
                          this.state.selectedOrg._id,
                          this.state.selectedOrg.status
                        )}
                      >
                        Change
                      </span>
                    ) : null}
                  </Row>
                  <Row>
                    <span className="heading">Location : </span>
                    <span className="value">
                      <a
                        href={`https://maps.google.com/?q=${this.state.selectedOrg.location.latitude},${this.state.selectedOrg.location.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.state.selectedOrg.location.label.trim()
                          ? this.state.selectedOrg.location.label.trim()
                          : `${this.state.selectedOrg.location.latitude},${this.state.selectedOrg.location.longitude}`}
                      </a>
                    </span>
                  </Row>
                  <Row>
                    <span className="heading"></span>
                    <span className="value"></span>
                  </Row>
                </Col>
              ) : null}
            </Col>
          )}

          <Modal
            className="edit-module-modal"
            show={this.state.newNGOModalVisibility}
            onHide={this.toggleNewNGOModalVisibility}
            size="lg"
            aria-labelledby="contained-modal-title-lg"
          >
            <Modal.Header className="edit-module-modal-header">
              <Modal.Title className="modal-header-custom">
                Add Organisation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="edit-module-modal-body">
              <Row className="body-conatiner">
                <Col md={6} className="mb15">
                  <label className="control-label is-imp">Enter NGO Name</label>
                  <input
                    type="text"
                    value={this.state.newNGO.ngoName}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'ngoName')}
                  />
                  {this.state.errorObj.hasOwnProperty('ngoName') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.ngoName}
                    </div>
                  ) : null}
                </Col>

                <Col md={6} className="mb15">
                  <label className="control-label is-imp">
                    Enter NGO Email
                  </label>
                  <input
                    type="email"
                    value={this.state.newNGO.email}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'email')}
                  />
                  {this.state.errorObj.hasOwnProperty('email') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.email}
                    </div>
                  ) : null}
                </Col>

                <Col md={6} className="mb15">
                  <label className="control-label is-imp">
                    Enter NGO Mobile No
                  </label>
                  <input
                    type="text"
                    value={this.state.newNGO.mobileNumber}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(
                      this,
                      'mobileNumber'
                    )}
                  />
                  {this.state.errorObj.hasOwnProperty('mobileNumber') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.mobileNumber}
                    </div>
                  ) : null}
                </Col>

                <Col md={6} className="mb15">
                  <label className="control-label is-imp">
                    Enter Admin Full Name
                  </label>
                  <input
                    type="text"
                    value={this.state.newNGO.fullName}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'fullName')}
                  />
                  {this.state.errorObj.hasOwnProperty('fullName') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.fullName}
                    </div>
                  ) : null}
                </Col>

                <Col md={3} className="mb15">
                  <label className="control-label">IFSC</label>
                  <Input
                    placeholder="Enter Bank IFSC"
                    // value={this.state.orgData.bankIfsc}
                    onChange={this.changeNewNGOHandler.bind(this, 'bankIfsc')}
                    addonAfter={this.showIFSCIcon()}
                    onBlur={this.verifyIFSC}
                  />
                  {/* <input type="text" value={this.state.orgData.mobileNumber} className="form-control" onChange={this.changeHandler.bind(this, 'mobileNumber')} /> */}
                  {this.state.errorObj.hasOwnProperty('bankIFSC') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.bankIFSC}
                    </div>
                  ) : null}
                </Col>
                <Col md={4} className="mb15">
                  <label className="control-label">Account Number</label>
                  <input
                    type="text"
                    // value={this.state.orgData.bankAccNo}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'bankAccNo')}
                    placeholder={'Enter Account Number'}
                  />
                  {this.state.errorObj.hasOwnProperty('bankAccNo') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.bankAccNo}
                    </div>
                  ) : null}
                </Col>
                <Col md={5} className="mb15">
                  <label className="control-label">Branch Address</label>
                  <input
                    type="text"
                    // value={this.state.orgData.bankBranchAddress}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(
                      this,
                      'bankBranchAddress'
                    )}
                    placeholder={'Enter Branch Address'}
                  />
                  {this.state.errorObj.hasOwnProperty('bankBranchAddress') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.bankBranchAddress}
                    </div>
                  ) : null}
                </Col>

                <Col md={4} className="mb15">
                  <label className="control-label is-imp">Select a State</label>
                  <Select
                    showSearch
                    size="large"
                    style={{ width: '100%' }}
                    value={this.state.newNGO.state}
                    onChange={this.changeNewNGOHandler.bind(this, 'state')}
                    filterOption={(input, option) =>
                      this.searchFilter(input, option)
                    }
                  >
                    <Option value="">All States</Option>
                    <OptGroup label="Focus States">
                      {this.state.allStates.map((state, stateIndex) => {
                        if (state.inFocusRegion) {
                          return (
                            <Option key={stateIndex} value={state.name}>
                              {state.name}
                            </Option>
                          )
                        } else return null
                      })}
                    </OptGroup>
                    <OptGroup label="Not Focussed States">
                      {this.state.allStates.map((state, stateIndex) => {
                        if (!state.inFocusRegion) {
                          return (
                            <Option key={stateIndex} value={state.name}>
                              {state.name}
                            </Option>
                          )
                        } else return null
                      })}
                    </OptGroup>
                  </Select>
                  {this.state.errorObj.hasOwnProperty('state') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.state}
                    </div>
                  ) : null}
                </Col>

                <Col md={4} className="mb15">
                  <label className="control-label is-imp">
                    Select a District
                  </label>
                  <Select
                    showSearch
                    size="large"
                    style={{ width: '100%' }}
                    value={this.state.newNGO.district}
                    onChange={this.changeNewNGOHandler.bind(this, 'district')}
                    disabled={!this.state.allDistricts.length}
                    filterOption={(input, option) =>
                      this.searchFilter(input, option)
                    }
                  >
                    <Option value="" disabled={true}>
                      Select District
                    </Option>
                    {this.state.allDistricts.length
                      ? this.state.allDistricts.map((district, index) => (
                          <Option value={district.name} key={district._id}>
                            {district.name}
                          </Option>
                        ))
                      : null}
                  </Select>
                  {this.state.errorObj.hasOwnProperty('district') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.district}
                    </div>
                  ) : null}
                </Col>

                <Col md={4} className="mb15">
                  <label className="control-label is-imp">Select a City</label>
                  <select
                    value={this.state.newNGO.city}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'city')}
                    disabled={!this.state.allCities.length}
                  >
                    <option value="" disabled={true}>
                      Select City
                    </option>
                    {this.state.allCities.length
                      ? this.state.allCities.map((city, index) => (
                          <option value={city.name} key={city._id}>
                            {city.name}
                          </option>
                        ))
                      : null}
                  </select>
                  {this.state.errorObj.hasOwnProperty('city') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.city}
                    </div>
                  ) : null}
                </Col>

                <Col md={12} className="mb15">
                  <label className="control-label is-imp">
                    Enter Address Line 1
                  </label>
                  <input
                    type="text"
                    value={this.state.newNGO.address1}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'address1')}
                  />
                  {this.state.errorObj.hasOwnProperty('address1') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.address1}
                    </div>
                  ) : null}
                </Col>

                <Col md={9} className="mb15">
                  <label className="control-label">Enter Address Line 2</label>
                  <input
                    type="text"
                    value={this.state.newNGO.address2}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'address2')}
                  />
                </Col>
                <Col md={3} className="mb15">
                  <label className="control-label is-imp">Enter Pincode</label>
                  <input
                    type="text"
                    value={this.state.newNGO.pincode}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'pincode')}
                  />
                  {this.state.errorObj.hasOwnProperty('pincode') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.pincode}
                    </div>
                  ) : null}
                </Col>

                <Col md={12} className="mb15">
                  <label className="control-label is-imp">
                    Enter NGO Location
                  </label>
                  <PlacesAutocomplete
                    onChange={this.onPreferenceLocationChange}
                    value={this.state.newNGO.location.label}
                    options={options}
                  >
                    {renderFunc}
                  </PlacesAutocomplete>
                  {this.state.errorObj.hasOwnProperty('location') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.location}
                    </div>
                  ) : null}
                </Col>

                <Col md={6} className="mb15">
                  <label className="control-label">Enter NGO Website Url</label>
                  <input
                    type="text"
                    value={this.state.newNGO.ngoUrl}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(this, 'ngoUrl')}
                  />
                </Col>

                <Col md={6} className="mb15">
                  <label className="control-label">Enter LE Profile Url</label>
                  <input
                    type="text"
                    value={this.state.newNGO.leProfileUrl}
                    className="form-control"
                    onChange={this.changeNewNGOHandler.bind(
                      this,
                      'leProfileUrl'
                    )}
                  />
                </Col>
                <Col md={12} className="mb5">
                  <label className="control-label is-imp">Location URL</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      value={this.state.newNGO.locationUrl}
                      className="form-control"
                      onChange={this.changeNewNGOHandler.bind(
                        this,
                        'locationUrl'
                      )}
                    />
                  </div>
                  {this.state.errorObj.hasOwnProperty('locationUrl') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.locationUrl}
                    </div>
                  ) : null}
                </Col>
                <Col md={12} className="mb15">
                  <label className="control-label is-imp">Upload Logo</label>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="form-control"
                    style={{ padding: '3px' }}
                    onChange={this.changeNewNGOHandler.bind(this, 'logo')}
                  />
                  {this.state.errorObj.hasOwnProperty('logo') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.logo}
                    </div>
                  ) : null}
                </Col>
                <Col md={12} className="mb15">
                  <label className="control-label">
                    Upload Office Photos (Upto 3 photos of the office front with
                    banners can be uploaded)
                  </label>
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      multiple
                      className="form-control col-md-6"
                      style={{ padding: '3px', marginRight: '10px' }}
                      onChange={this.changeNewNGOHandler.bind(
                        this,
                        'officePhotos'
                      )}
                    />
                    {/* {this.state.newNGO.officePhotos.length && this.state.newNGO.officePhotos.map((image,index)=>{
										 return typeof image==='string'? <div style={{textAlign:"center"}}><Row><img src={image} width="150" height="100" style={{borderWidth: 1, borderColor: '#eee', padding: '5px'}} /></Row><i style={{color:"#EF5A20"}} class="fa fa-trash" aria-hidden="true" onClick={this.deleteOfficeImage.bind(this,index)}></i>
</div>:null
									}) } */}
                  </div>
                  {this.state.errorObj.hasOwnProperty('officePhotos') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.officePhotos}
                    </div>
                  ) : null}
                  {/* <div style={{color: 'red', fontSize: '13px', fontWeight: 600}}>{this.state.maxImagesErr}</div> */}

                  {/* {this.state.errorObj.hasOwnProperty('logo') ? (
									<div style={{color: 'red', fontSize: '13px', fontWeight: 600}}>{this.state.errorObj.logo}</div>
								) : (null)} */}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col md={12} className="d-flex justify-content-between">
                <Button
                  variant="secondary"
                  onClick={this.toggleNewNGOModalVisibility}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.submitNewNGOModal}>
                  Submit
                </Button>
              </Col>
            </Modal.Footer>
          </Modal>
        </div>
      )
    } else return null
  }
}
