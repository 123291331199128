import React, { useState } from 'react'
import {
  Modal,
  Select,
  Alert,
  // Spin
} from 'antd'
import '../../../bank-branches/components/FormElements/button.css'
import { useHttpHook } from '../hooks/http-hook'

const { Option, OptGroup } = Select
const languages = [
  'English',
  'Hindi',
  'Odia',
  'Telugu',
  'Marathi',
  'Kannada',
  'Bengali',
  'Tamil',
  'Gujarati',
  'Punjabi',
  'Malayalam',
]

const readCookie = require('../../../cookie.js').readCookie

const Languagemodal = props => {
  const {
    // isLoading,
    error,
    sendRequest,
    // clearError
  } = useHttpHook()
  const [isvalid, setvalid] = useState(false)
  const [language, setLanguage] = useState('')

  const submitHandler = async event => {
    if (language === '') {
      setvalid(true)
    } else {
      setvalid(false)

      event.preventDefault()

      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            '/api/v1/masturation-messages/add-languages',
          'PATCH',
          JSON.stringify({
            language: language + 'valid',
          }),
          {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          }
        )
        console.log(responseData.message)
        props.hide()
        //  window.location.reload(false);
      } catch (err) {
        alert(error)
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        title="Add Language"
        style={{ top: 20 }}
        visible={props.show}
        okText="Add"
        onOk={submitHandler}
        onCancel={props.hide}
      >
        <label className="control-label">
          Select language
          <span className="mandatory">{'*'}</span>
        </label>
        <Select
          style={{ width: 200 }}
          onChange={e => {
            setLanguage(e)
          }}
          value={language === '' ? 'Select language' : language}
          placeholder="Select a language"
        >
          <OptGroup>
            {languages.map((language, languageIndex) => {
              return (
                <Option key={languageIndex} value={language}>
                  {language}
                </Option>
              )
            })}
          </OptGroup>
        </Select>
        <div style={{ margin: '24px 0' }} />
        {isvalid && (
          <Alert
            message="Error"
            description="All fieds are mandatory"
            type="error"
            showIcon
          />
        )}
      </Modal>
    </React.Fragment>
  )
}

export default Languagemodal
