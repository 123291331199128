import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  singleFarmer: {},
  loading: true,
  error: '',
  message: '',
}

export const resetMessageinMakeFarmer = createAction('resetMessageinMakeFarmer')
export const resetErrorinMakeFarmer = createAction('resetErrorinMakeFarmer')

export const makeFarmer = createAsyncThunk(
  'makeFarmer',
  async (body, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/farmers/addfarmer`,
        body,
        { 'Content-Type': 'multipart/form-data' }
      )
      console.log('body from makeFarmer ', body)
      console.log('res.data from makeFarmer  ', res.data)
      return res.data
    } catch (error) {
      console.log(error)
      console.log('makeFarmer', error.response.data)
      //@ts-ignore
      return rejectWithValue(error.response.data)
    }
  }
)

const makeFarmerSlice = createSlice({
  name: 'makeFarmer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(makeFarmer.rejected, (state, action) => {
      console.log('action: makeFarmer', action)
    })
    builder.addCase(makeFarmer.fulfilled, (state, action) => {
      console.log('action: fulfilled makeFarmer', action)
      state.error = action.error
      // state.message = message;
    })
    //@ts-ignore
    // [makeFarmer.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // //@ts-ignore
    // [makeFarmer.fulfilled]: (
    //   //@ts-ignore
    //   state,
    //   //@ts-ignore
    //   { payload: { error, singleFarmer, message } }
    // ) => {
    //   state.loading = false;
    //   if (error) {
    //     state.error = error;
    //   } else {
    //     state.singleFarmer = singleFarmer;
    //     state.message = message;
    //   }
    // },
    // //@ts-ignore
    // [makeFarmer.rejected]: (
    //   state,
    //   //@ts-ignore
    //   { payload: { message, error } }
    // ) => {
    //   console.log('error: ', error);
    //   state.error = error;
    //   state.message = message;
    // },
    // [resetErrorinMakeFarmer]: (state) => {
    //   state.error = "";
    // },
    // [resetMessageinMakeFarmer]: (state) => {
    //   state.message = "";
    // },
  },
})

export default makeFarmerSlice.reducer
