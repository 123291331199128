import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import {
  // ConfigProvider, DatePicker,
  Switch,
  Select,
} from 'antd'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  Col,
  Row,
  Button,
  Modal,
  Dropdown,
  DropdownButton,
  Tabs,
  Tab,
} from 'react-bootstrap'
import React, { Component } from 'react'
// import enUS from 'antd/es/locale/en_US';
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'
import $ from 'jquery'

import AddCandidatesToProjectModal from '../../AddCandidatesToProjectModal.js'
import OutboundCalls from './OutboundCalls.js'

const readCookie = require('../../cookie.js').readCookie

const { SearchBar } = Search
const { Option, OptGroup } = Select
const knowlarity_emails = require('../../../../emails_knowlarity.json')

const INITIAL_STATUS = []
export default class IVRSLeadsDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      leads: [],
      allNGOs: [],
      totalLeads: 0,
      editLeadId: '',
      editLead: null,
      searchText: '',
      sizePerPage: 100,
      tabKey: 1,
      filterData: {
        hasLead: '',
        status: INITIAL_STATUS,
        userType: '',
        isTrained: '',
        assignedTo: '',
        fromDate: '',
        toDate: '',
      },
      expanded: [],
      allStates: [],
      allDistricts: [],
      allUserTypes: [],
      allAssignedTo: [],
      updatedPin: false,
      showFilter: false,
      totalCallCounts: 0,
      editModalVisibility: false,
      gettingDetailsForPincode: false,
      sizePerPageList: [50, 100, 150, 200],
      selectedIds: [],
      selectMode: false,
      showAddIdsToProjectModal: false,
      allIds: [],
      projectMoveAction: '',
    }
  }

  hideAddIdsToProjectModal = (addedToProject = false) => {
    this.setState({ showAddIdsToProjectModal: false })
    if (addedToProject) {
      this.setState({
        selectedIds: [],
        selectMode: false,
      })
      this.submitFilter('page')
    }
  }
  componentDidMount() {
    this.getIVRSLeads()

    fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-types', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allUserTypes: data.userTypes })
        }
      })

    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allStates: data.data.states })
        }
      })
  }

  getIVRSLeads = query => {
    if (!query)
      query = `?page=${this.state.page}&perPage=${this.state.sizePerPage}`
    fetch(process.env.REACT_APP_API_URL + '/api/v1/ivrs' + query, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState(
            {
              leads: data.ivrs,
              totalCallCounts: data.count,
              totalLeads: data.ivrsLength,
              allIds: data.allIvrsIds || [],
            },
            () => {
              $(() => {
                // use UI arrows in page content to control scrolling
                this.scrolling = false
                // var that = this;

                $('#drag-table-left').mouseover(evt => {
                  this.scrolling = true
                  this.startScrolling(
                    $(
                      '.ivrs-leads-table > div > .react-bootstrap-table > table.table'
                    ),
                    15,
                    evt.target.id,
                    'right'
                  )
                })

                $('#drag-table-right').mouseover(evt => {
                  this.scrolling = true
                  this.startScrolling(
                    $(
                      '.ivrs-leads-table > div > .react-bootstrap-table > table.table'
                    ),
                    15,
                    evt.target.id,
                    'left'
                  )
                })

                $('#drag-table-left').mouseout(() => {
                  this.scrolling = false
                })
                $('#drag-table-right').mouseout(() => {
                  this.scrolling = false
                })
              })
            }
          )
        }
      })
  }

  handleSelectChange = row => {
    let selectedIds = this.state.selectedIds
    if (!selectedIds.includes(row._id)) {
      selectedIds.push(row._id)
    } else {
      const removeIndex = selectedIds.map(item => item._id).indexOf(row._id)
      removeIndex >= 0 && selectedIds.splice(removeIndex, 1)
    }
    this.setState({ selectedIds })
  }
  handleSelectAllChange = selected => {
    if (selected) {
      this.setState({ selectedIds: this.state.allIds })
    } else {
      this.setState({ selectedIds: [] })
    }
  }
  startScrolling = (obj, spd, btn, direction) => {
    var travelX = 0,
      stop = false
    if ($(obj).css('transform') !== 'none')
      travelX = parseInt($(obj).css('transform').split(',')[4].trim())

    if (direction === 'left') {
      let max =
        $('.ivrs-leads-table > div > .react-bootstrap-table').width() -
        $(obj).width()
      if (travelX - spd < max) {
        travelX = max
        stop = true
        $(obj).css('transform', 'translateX(' + travelX + 'px)')
      } else travelX -= spd
    } else if (direction === 'right') {
      if (travelX + spd > 0) {
        travelX = 0
        stop = true
        $(obj).css('transform', 'translateX(' + travelX + 'px)')
      } else travelX += spd
    }

    if (!this.scrolling || stop) {
      obj.stop()
    } else {
      $(obj).css('transform', 'translateX(' + travelX + 'px)')
      // recursively call startScrolling while mouse is hovered
      setTimeout(() => {
        this.startScrolling(obj, spd, btn, direction)
      }, spd)
    }
  }

  formatMobile = (cell, row) => {
    if (cell) {
      if (row.history && row.history.length) {
        return (
          <div className="d-flex align-items-center justify-content-between">
            {
              /*this.props.userData.userType.name==='Project Management Unit' ||*/ (knowlarity_emails.includes(
                this.props.userData.email
              ) ||
                /*this.props.userData.userType.name === 'Zonal Manager' ||*/ this
                  .props.userData.userType.name === 'Swavalamban Fellows') && (
                // && this.props.userData.fellowAccessType === 'Full'
                <span
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                    marginRight: '2px',
                  }}
                  className="call-icon"
                  onClick={this.callNumber.bind(this, cell)}
                >
                  <i
                    style={{ fontSize: '20px', color: '#00dd00' }}
                    title="Call this number"
                    className="fa fa-phone"
                  />
                </span>
              )
            }{' '}
            <span>{cell}</span>
            <span className="note">
              {'Attempts: ' + (row.history.length + 1)}
            </span>
          </div>
        )
      } else {
        return (
          <div className="d-flex ">
            {
              /*this.props.userData.userType.name==='Project Management Unit' ||*/ (knowlarity_emails.includes(
                this.props.userData.email
              ) ||
                /*this.props.userData.userType.name === 'Zonal Manager' ||*/ this
                  .props.userData.userType.name === 'Swavalamban Fellows') && (
                // && this.props.userData.fellowAccessType === 'Full'
                <span
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                    marginRight: '2px',
                  }}
                  className="call-icon"
                  onClick={this.callNumber.bind(this, cell)}
                >
                  <i
                    style={{ fontSize: '20px', color: '#00dd00' }}
                    title="Call this number"
                    className="fa fa-phone fa-lg"
                  />{' '}
                  &nbsp;
                </span>
              )
            }
            <span>{cell}</span>
          </div>
        )
      }
    } else return null
  }

  formatDate = (cell, row) => {
    if (cell) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return null
  }

  formatHistDate = (cell, row) => {
    if (cell) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return null
  }

  formatAssignedTo = (cell, row) => {
    if (cell && cell.hasOwnProperty('_id')) {
      if (cell.ngoName) {
        return (
          <a
            href={
              this.props.userData.type === 'partners'
                ? '/settings'
                : '/org-types/swavalamban-connect-kendra/' + cell._id
            }
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          >
            {cell.ngoName}
          </a>
        )
      } else if (cell.fullName) {
        if (!cell.userType) return null
        return (
          <a
            href={'/user-types/' + cell.userType.customUrl + '/' + cell._id}
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          >
            {cell.fullName}
          </a>
        )
      }
    } else return null
  }

  formatIsTrained = (cell, row) => {
    //if(cell) return 'Automated Calls';
    if (row.tcacrId) return 'Offline Aspirants'
    else return 'Toll-free Calls'
  }

  formatStatus = (cell, row) => {
    if (cell) return cell
    else return null
  }

  formatEdit = (cell, row) => {
    if (row._id) {
      // if(row.hasLead) return 'N/A';
      // else {
      return (
        <Button variant="primary" onClick={this.showEditModal.bind(this, row)}>
          Edit
        </Button>
      )
      // }
    } else return null
  }

  formatConvertLead = (cell, row) => {
    if (row._id) {
      if (row.hasLead) {
        return (
          <Button
            variant="primary"
            onClick={() => (window.location.pathname = '/lead/' + row.leadId)}
          >
            View Lead
          </Button>
        )
      } else if (row.status !== 'Spam' && row.status !== 'Unassigned') {
        return (
          <Button
            className="p5"
            variant="primary"
            onClick={this.convertToLead.bind(this, row._id)}
          >
            Create Lead Profile
          </Button>
        )
      } else return 'N/A'
    } else return null
  }

  showEditModal = (editLead, e) => {
    e.stopPropagation()
    let leadData = {
      userType: '',
      assignedTo: '',
      _id: editLead._id,
      mobile: editLead.mobile,
      status: editLead.status,
      pincode: editLead.pincode,
      comments: editLead.comments,
      state: editLead.state || '',
      isSpam: editLead.isSpam || false,
      district: editLead.district || '',
    }
    var promises = [],
      allNGOs = [],
      allDistricts = []
    if (leadData.state) {
      var promise0 = fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/get-address?stateId=' +
          leadData.state,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            allDistricts = data.districts
          }
        })
      promises.push(promise0)
    }
    if (editLead.assignedTo) {
      if (editLead.assignedTo.userType)
        leadData['userType'] = editLead.assignedTo.userType.customUrl
      leadData['assignedTo'] = editLead.assignedTo._id
      if (leadData['userType'] === 'swavalamban-connect-kendra') {
        var promise1 = fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              allNGOs = data.ngos
            }
          })
        promises.push(promise1)
      } else if (leadData['userType']) {
        var promise2 = fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/user/users/' +
            leadData['userType'],
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              allNGOs = data.users
            }
          })
        promises.push(promise2)
      }
    }

    Promise.all(promises).then(() => {
      this.setState({
        allNGOs,
        editModalVisibility: true,
        editLead: leadData,
        editLeadId: editLead._id,
        allDistricts,
        updatedPin: false,
      })
    })
  }

  hideEditModal = () => {
    this.setState({
      editModalVisibility: false,
      editLead: null,
      editLeadId: '',
      updatedPin: false,
    })
  }

  resetFilter = () => {
    let filterData = {
      hasLead: '',
      status: INITIAL_STATUS,
      userType: '',
      isTrained: '',
      assignedTo: '',
      fromDate: '',
      toDate: '',
    }
    let query = `?page=${this.state.page}&perPage=${this.state.sizePerPage}`
    this.setState({ filterData, allDistricts: [] }, () =>
      this.getIVRSLeads(query)
    )
    this.showFilter()
  }

  showFilter = () => {
    this.setState({ showFilter: !this.state.showFilter }, () => {
      if (this.state.showFilter) {
        $('.filter-container').css('display', 'block')
      } else {
        $('.filter-container').css('display', 'none')
      }
    })
  }

  submitFilter = (
    type = '',
    page = this.state.page,
    sizePerPage = this.state.sizePerPage,
    searchText = this.state.searchText
  ) => {
    let filterData = this.state.filterData,
      query = `?page=${page}&perPage=${sizePerPage}`
    if (filterData.hasLead !== '') query += `&hasLead=${filterData.hasLead}`
    // if (filterData.status !== '') query += `&status=${filterData.status}`;
    if (filterData.status.join(','))
      query += `&status=${filterData.status.join(',')}`
    if (filterData.userType !== '') query += `&userType=${filterData.userType}`
    if (filterData.isTrained !== '')
      query += `&isTrained=${filterData.isTrained}`
    if (filterData.assignedTo !== '')
      query += `&assignedTo=${filterData.assignedTo}`
    if (filterData.fromDate !== '')
      query += `&startDate=${moment(filterData.fromDate).format('DD-MM-YYYY')}`
    if (filterData.toDate !== '')
      query += `&endDate=${moment(filterData.toDate).format('DD-MM-YYYY')}`
    if (searchText) query += `&q=${searchText}`

    if (type !== 'page' && type !== 'sizePerPage' && type !== 'searchText')
      // this.showFilter();
      $('.filter-container').css('display', 'none')

    this.getIVRSLeads(query)
  }

  onTableChange = (type, obj) => {
    let changeType = ''
    if (this.state.page !== obj.page) changeType = 'page'
    else if (this.state.sizePerPage !== obj.sizePerPage)
      changeType = 'sizePerPage'
    else if (this.state.searchText !== obj.searchText) changeType = 'searchText'

    this.setState({
      page: obj.page,
      sizePerPage: obj.sizePerPage,
      searchText: obj.searchText,
    })
    this.submitFilter(changeType, obj.page, obj.sizePerPage, obj.searchText)
  }

  convertToLead = ivrsId => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'The IVRS Lead will convert into a Lead profile.',
      type: 'warning',
    }).then(res => {
      if (res.value) {
        window.location.pathname = '/ivrs-to-lead/' + ivrsId
      }
    })
  }

  changeHandler = (type, e) => {
    let editLead = this.state.editLead
    if (
      type === 'isSpam' ||
      type === 'status' ||
      type === 'assignedTo' ||
      type === 'userType' ||
      type === 'state' ||
      type === 'district'
    ) {
      if (
        type === 'isSpam' &&
        e &&
        JSON.parse(readCookie('userData')).type !== 'partners'
      )
        editLead['status'] = 'Spam'
      else if (
        type === 'isSpam' &&
        !e &&
        editLead.status === 'Spam' &&
        JSON.parse(readCookie('userData')).type !== 'partners'
      )
        editLead['status'] = 'Unassigned'
      else if (type === 'status' && e === 'Spam') editLead['isSpam'] = true
      else if (type === 'status' && e !== 'Spam' && editLead.status === 'Spam')
        editLead['isSpam'] = false
      else if (type === 'userType' && e === 'swavalamban-connect-kendra') {
        editLead['assignedTo'] = ''
        fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({ allNGOs: data.ngos })
            }
          })
      } else if (type === 'userType' && e) {
        editLead['assignedTo'] = ''
        fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/' + e, {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({ allNGOs: data.users })
            }
          })
      } else if (type === 'state' && e) {
        editLead['district'] = ''
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/location/get-address?stateId=' +
            e,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({ allDistricts: data.districts })
            }
          })
      }

      editLead[type] = e
    } else {
      if (type === 'pincode') {
        if (e.target.value.length <= 6) editLead[type] = e.target.value
      } else editLead[type] = e.target.value
    }
    this.setState({ editLead })
  }

  changeFilterHandler = (type, e) => {
    let filterData = this.state.filterData
    filterData[type] = e
    if (type === 'userType' && e === 'swavalamban-connect-kendra') {
      filterData['assignedTo'] = ''
      fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      })
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allAssignedTo: data.ngos })
          }
        })
    } else if (type === 'userType' && e) {
      filterData['assignedTo'] = ''
      fetch(process.env.REACT_APP_API_URL + '/api/v1/user/users/' + e, {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      })
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allAssignedTo: data.users })
          }
        })
    } else if (type === 'userType' && !e) {
      filterData['assignedTo'] = ''
      this.setState({ allAssignedTo: [] })
    }
    this.setState({ filterData })
  }

  updateLead = () => {
    let editLead = this.state.editLead
    if (editLead) {
      let leadData = {
        state: editLead.state,
        isSpam: editLead.isSpam,
        status: editLead.status,
        pincode: editLead.pincode,
        userType: editLead.userType,
        comments: editLead.comments,
        district: editLead.district,
        assignedTo: editLead.assignedTo,
      }
      if (editLead.status !== 'Assigned') {
        leadData['assignedTo'] = null
        leadData['userType'] = null
      }
      if (leadData['userType'] === 'swavalamban-connect-kendra')
        leadData['inModel'] = 'Ngo'
      else if (leadData.assignedTo === null) leadData['inModel'] = null
      else leadData['inModel'] = 'User'
      Swal.fire({
        title: 'Are you sure?',
        text: 'The IVRS Lead will be updated.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Update!',
        cancelButtonText: 'No, Cancel!',
      }).then(res => {
        if (res.value) {
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/ivrs/' +
              this.state.editLeadId,
            {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
              body: JSON.stringify(leadData),
            }
          )
            .then(response => {
              return response.json()
            })
            .then(data => {
              if (data.status) {
                this.notifySuccess('Successfully updated IVRS Lead!')
                // this.getIVRSLeads();
                this.submitFilter('page')
                this.setState({
                  editModalVisibility: false,
                  editLeadId: '',
                  editLead: null,
                })
              } else {
                this.notifyError(data.errorMsg)
              }
            })
        }
      })
    }
  }

  pincodeBlur = e => {
    let newPin = e.target.value
    if (newPin.match(/^\d{6}$/)) {
      this.setState({ updatedPin: newPin })
    }
  }

  autoAssign = () => {
    let updatedPin = this.state.updatedPin
    Swal.fire({
      title: 'Are you sure?',
      text: 'The details for this pincode will be updated!',
      type: 'warning',
      showCancelButton: true,
    }).then(res => {
      if (res.value) {
        this.setState({ gettingDetailsForPincode: true })
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/ivrs/get-details-for-pincode/' +
            updatedPin,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              let editLead = this.state.editLead
              if (data.details.state) editLead.state = data.details.state
              if (data.details.district)
                editLead.district = data.details.district
              if (data.details.userType)
                editLead.userType = data.details.userType
              if (data.details.assignedTo) {
                editLead.status = 'Assigned'
                editLead.assignedTo = data.details.assignedTo
              } else {
                editLead.userType = ''
                editLead.assignedTo = ''
                // editLead.status = "Unassigned";
                editLead.status = 'Not a focus region'
              }

              this.setState({
                editLead,
                updatedPin: false,
                gettingDetailsForPincode: false,
              })
              if (data.details.userType === 'swavalamban-connect-kendra') {
                // var promise1 =
                fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
                  method: 'GET',
                  headers: {
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                  },
                })
                  .then(data => data.json())
                  .then(data => {
                    if (data.status) {
                      this.setState({ allNGOs: data.ngos })
                    }
                  })
              }
              if (data.details.state) {
                fetch(
                  process.env.REACT_APP_API_URL +
                    '/api/v1/location/get-address?stateId=' +
                    data.details.state,
                  {
                    method: 'GET',
                    headers: {
                      'x-auth-token': JSON.parse(readCookie('access_token')),
                    },
                  }
                )
                  .then(data => data.json())
                  .then(data => {
                    if (data.status) {
                      this.setState({ allDistricts: data.districts })
                    }
                  })
              }
            } else {
              this.notifyError('Invalid pincode entered!')
              this.setState({ gettingDetailsForPincode: false })
            }
          })
      }
    })
  }

  rowClassNameFormat = (row, index) => {
    //if(row.isTrained) {
    if (row.tcacrId) {
      return { backgroundColor: '#FFDF80' }
    }
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === 'string') {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  callNumber(number) {
    if (number.length === 11) {
      number = number.slice(1, 11)
    }
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to place a call to ${number}?`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes, Please do',
      confirmButtonAriaLabel: 'Yes, Please do',
      cancelButtonText: 'No, Cancel',
      cancelButtonAriaLabel: 'No, Cancel',
    }).then(res => {
      if (res.value) {
        this.placeCall(number)
      }
    })
  }
  placeCall(number) {
    let knowlarity_makecall__url =
      'https://kpi.knowlarity.com/Basic/v1/account/call/makecall'
    fetch(knowlarity_makecall__url, {
      method: 'POST',
      headers: {
        // 'x-api-key': 'qRwE4Ugq4C3XJfNlUWUkl3CpwZ77ryWradPalKfY',
        // 'Authorization': 'f925251f-c0aa-4b89-bdf9-60f1b06ec111',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // "k_number": "+918047225386",
        // "caller_id": "+918047225386"
        to: '+91' + number,
        from: '+91' + this.props.userData.mobileNumber,
        // "customer_number": "+91" + number,
        // "agent_number": "+91" + this.props.userData.mobileNumber,
      }),
    })
      .then(data => data.json())
      .then(data => {
        console.log(data)
        if (!data.error) {
          this.notifySuccess(data.message)
        } else {
          this.notifyError(`Couldn't make call!`)
        }
      })
  }

  render() {
    let ivrsColumns = [
        {
          text: '',
          sort: false,
          hidden: true,
          export: true,
          dataField: '_id',
          searchable: false,
        },
        {
          sort: true,
          export: true,
          hidden: false,
          text: 'Mobile',
          searchable: true,
          dataField: 'mobile',
          formatter: this.formatMobile,
          headerStyle: () => {
            return { width: '220px' }
          },
        },
        {
          sort: true,
          export: true,
          hidden: false,
          text: 'Pin Code',
          searchable: true,
          dataField: 'correctPincode',
          headerStyle: () => {
            return { width: '70px' }
          },
        },
        {
          sort: true,
          export: true,
          hidden: false,
          text: 'State',
          searchable: true,
          dataField: 'state',
          headerStyle: () => {
            return { width: '110px' }
          },
        },
        {
          sort: true,
          export: true,
          hidden: false,
          text: 'District',
          searchable: true,
          dataField: 'district',
          headerStyle: () => {
            return { width: '110px' }
          },
        },
        {
          sort: true,
          export: true,
          hidden: false,
          searchable: true,
          text: 'Latest Attempt',
          dataField: 'latestAttempt',
          formatter: this.formatDate,
          headerStyle: () => {
            return { width: '140px' }
          },
        },
        // { sort: true, export: true, hidden: false, searchable: true, text: 'Created On', dataField: 'latestAttempt', formatter: this.formatDate, headerStyle: () => { return { width: '140px' } } },
        {
          sort: true,
          export: true,
          hidden: false,
          text: 'Status',
          searchable: true,
          dataField: 'status',
          headerStyle: () => {
            return { width: '130px' }
          },
          formatter: this.formatStatus,
        },
        {
          sort: true,
          export: true,
          hidden: false,
          searchable: true,
          text: 'Source',
          dataField: 'isTrained',
          formatter: this.formatIsTrained,
          headerStyle: () => {
            return { width: '150px' }
          },
        },
        {
          sort: true,
          export: true,
          hidden: false,
          searchable: true,
          text: 'Updated At',
          dataField: 'updatedAt',
          formatter: this.formatDate,
          headerStyle: () => {
            return { width: '150px' }
          },
        },
        {
          sort: true,
          export: true,
          hidden: false,
          searchable: true,
          text: 'Assigned To',
          dataField: 'assignedTo',
          formatter: this.formatAssignedTo,
          headerStyle: () => {
            return { width: '240px' }
          },
        },
      ],
      ivrsHistoryColumns = [
        {
          text: '',
          sort: false,
          hidden: true,
          export: true,
          dataField: '_id',
          searchable: false,
        },
        {
          sort: true,
          export: true,
          hidden: false,
          text: 'Pin Code',
          searchable: true,
          dataField: 'pincode',
        },
        {
          sort: true,
          export: true,
          hidden: false,
          searchable: true,
          text: 'Created On',
          dataField: 'timestamp',
          formatter: this.formatHistDate,
        },
        // { sort: true, export: true, hidden: false, text: 'Status', searchable: true, dataField: 'status', formatter: this.formatStatus },
        // { sort: true, export: true, hidden: false, searchable: true, text: 'Assigned To', dataField: 'assignedTo', formatter: this.formatAssignedTo }
      ]

    if (JSON.parse(readCookie('userData')).type !== 'sidbi') {
      ivrsColumns.splice(7, 0, {
        sort: false,
        export: false,
        hidden: false,
        searchable: false,
        text: 'Edit',
        dataField: 'edit',
        align: 'center',
        headerStyle: () => {
          return { width: '80px', textAlign: 'center' }
        },
        formatter: this.formatEdit,
      })
      ivrsColumns.splice(5, 0, {
        sort: false,
        export: false,
        hidden: false,
        searchable: false,
        text: 'View Lead/Create Lead',
        dataField: 'convert',
        align: 'center',
        headerStyle: () => {
          return { width: '165px', textAlign: 'center' }
        },
        formatter: this.formatConvertLead,
      })
      // ivrsColumns.push({ sort: false, export: false, hidden: false, searchable: false, text: 'View/Convert', dataField: 'convert', align: "center", headerStyle: () => {return { width: '110px', textAlign: 'center' }}, formatter: this.formatConvertLead });
    }

    let expandRow = {
      renderer: (row, rIndex) => {
        if (row.history && row.history.length) {
          return (
            <div className="expand-row-container">
              <h3>History:</h3>
              <BootstrapTable
                keyField="_id"
                data={row.history}
                columns={ivrsHistoryColumns}
              />
            </div>
          )
        } else return null
      },
      className: (isExpanded, row, rowIndex) => {
        if (!row.history.length) return 'd-none'
        return ''
      },
    }
    const selectRow = {
      mode: 'checkbox',
      clickToSelect:
        JSON.parse(readCookie('userData')).userType.name ===
          'Project Management Unit' && this.state.selectMode,
      selected: this.state.selectedIds,
      onSelect: this.handleSelectChange,
      onSelectAll: this.handleSelectAllChange,
      hideSelectColumn: !(
        JSON.parse(readCookie('userData')).userType.name ===
          'Project Management Unit' && this.state.selectMode
      ),
    }

    const InboundCallsTab = () => (
      <>
        {JSON.parse(readCookie('userData')).userType.name ===
          'Project Management Unit' &&
        this.state.selectMode &&
        this.state.selectedIds.length !== 0 ? (
          <AddCandidatesToProjectModal
            showModal={
              this.state.showAddIdsToProjectModal &&
              this.state.projectMoveAction
            }
            leadIds={this.state.selectedIds}
            leadsType={'ivrs'}
            hideModal={this.hideAddIdsToProjectModal}
            action={this.state.projectMoveAction}
          />
        ) : null}
        <Row>
          <Col md={3} />
          <Col md={9}>
            {false &&
              JSON.parse(readCookie('userData')).userType.name ===
                'Project Management Unit' && (
                <div
                  align="end"
                  className="row"
                  style={{ justifyContent: 'flex-end', paddingRight: 150 }}
                >
                  {this.state.selectMode && (
                    <h5
                      style={{
                        color: '#ef5a20',
                        fontWeight: 'bold',
                        marginRight: 10,
                        paddingTop: 5,
                      }}
                    >
                      {this.state.selectedIds.length} Selected
                    </h5>
                  )}
                  {this.state.selectMode > 0 && (
                    <DropdownButton
                      style={{ marginRight: 30 }}
                      disabled={this.state.selectedIds.length === 0}
                      id="dropdown-basic-button"
                      title="&emsp;Take Action&emsp;"
                      onChange={() => console.log('SELECTED LEADS')}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({
                            showAddIdsToProjectModal: true,
                            projectMoveAction: 'add',
                          })
                        }}
                      >
                        Add to another project
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          this.setState({
                            showAddIdsToProjectModal: true,
                            projectMoveAction: 'move',
                          })
                        }}
                      >
                        Move to another project
                      </Dropdown.Item>
                      {/* <Dropdown.Item onClick={() => {
                                      localStorage.setItem('selectedLeads', JSON.stringify(this.state.selectedIds));
                                      window.open(process.env.REACT_APP_CAMPAIGN_URL+"?token=" + JSON.parse(readCookie("access_token")), "_blank")
                                    }
                                  }>
                                  Trigger Campaign
                                </Dropdown.Item> */}
                    </DropdownButton>
                  )}
                  <h5 style={{ paddingTop: 2 }}>Selection Mode</h5>
                  <Switch
                    checked={this.state.selectMode}
                    style={{
                      transform: 'scale(1.3)',
                      width: 80,
                      marginLeft: 30,
                    }}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onChange={e =>
                      this.setState({ selectedIds: [], selectMode: e })
                    }
                  />
                </div>
              )}
          </Col>
        </Row>
        <Col md={12} className="ivrs-leads-table">
          {this.state.totalLeads ? (
            <span id="drag-table-left">{'<'}</span>
          ) : null}
          {this.state.totalLeads ? (
            <span id="drag-table-right">{'>'}</span>
          ) : null}
          {this.state.leads ? (
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                page: this.state.page,
                sizePerPage: this.state.sizePerPage,
                totalSize: this.state.totalLeads,
                sizePerPageList: this.state.sizePerPageList,
              })}
              keyField="_id"
              data={this.state.leads}
              columns={ivrsColumns}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  data={this.state.leads}
                  columns={ivrsColumns}
                  search={{ searchFormatted: true }}
                >
                  {props => (
                    <div>
                      <Row className="mt10">
                        <Col
                          className="d-flex"
                          style={{ alignItems: 'center' }}
                        >
                          <span className="total-leads m0">
                            {'Total Callers: ' + this.state.totalLeads}
                          </span>
                          <span className="total-leads m0 ml5">
                            {'Total Call Counts: ' + this.state.totalCallCounts}
                          </span>
                        </Col>
                        <Col className="ivrs-leads-table-search-container">
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Search by Mobile/Pincode/State/District"
                            className="ivrs-leads-table-search"
                            delay={2000}
                          />
                        </Col>
                        <div
                          className="filter-class d-flex align-items-center justify-content-center"
                          onClick={this.showFilter}
                        >
                          Filter<i className="fas fa-filter"></i>
                        </div>
                      </Row>
                      <div className="filter-container">
                        <div className="close-filter" onClick={this.showFilter}>
                          X
                        </div>
                        <div className="status-filter">
                          <label className="control-label">
                            Converted to Lead
                          </label>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Converted to Lead"
                            onChange={this.changeFilterHandler.bind(
                              this,
                              'hasLead'
                            )}
                            value={this.state.filterData.hasLead}
                          >
                            <Option value="">All</Option>
                            <Option value={true}>Yes</Option>
                            <Option value={false}>No</Option>
                          </Select>
                        </div>
                        <div className="status-filter">
                          <label className="control-label">Status</label>
                          <Select
                            style={{ width: '100%' }}
                            mode="multiple"
                            placeholder="Select Status"
                            onChange={this.changeFilterHandler.bind(
                              this,
                              'status'
                            )}
                            value={this.state.filterData.status}
                          >
                            {/* <Option value="">All</Option> */}
                            <Option value="Assigned">Assigned</Option>
                            <Option value="Unassigned">Unassigned</Option>
                            <Option value="Spam">Spam</Option>
                            <Option value="Unresponsive">Unresponsive</Option>
                            <Option value="Test Call">Test Call</Option>
                            <Option value="Information Call">
                              Information Call
                            </Option>
                            <Option value="Not a focus region">
                              Not a focus region
                            </Option>
                          </Select>
                        </div>
                        <div className="status-filter">
                          <label className="control-label">Source</label>
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Source"
                            onChange={this.changeFilterHandler.bind(
                              this,
                              'isTrained'
                            )}
                            value={this.state.filterData.isTrained}
                          >
                            <Option value="">All</Option>
                            <Option value={true}>Offline Aspirants</Option>
                            <Option value={false}>Toll-free Calls</Option>
                          </Select>
                        </div>

                        {(JSON.parse(readCookie('userData')).type === 'le' &&
                          JSON.parse(readCookie('userData')).userType.name ===
                            'Project Management Unit') ||
                        JSON.parse(readCookie('userData')).type === 'sidbi' ? (
                          <div className="assigned-to-filter">
                            <label className="control-label">
                              Select User Type
                            </label>
                            <Select
                              style={{ width: '100%' }}
                              placeholder="User Type"
                              onChange={this.changeFilterHandler.bind(
                                this,
                                'userType'
                              )}
                              value={this.state.filterData.userType}
                            >
                              <Option value="">All</Option>
                              {this.state.allUserTypes.map(
                                (userType, userTypeIndex) => {
                                  return (
                                    <Option
                                      key={userTypeIndex}
                                      value={userType.customUrl}
                                      title={userType.name}
                                    >
                                      {userType.name}
                                    </Option>
                                  )
                                }
                              )}
                            </Select>
                          </div>
                        ) : null}
                        {(JSON.parse(readCookie('userData')).type === 'le' &&
                          JSON.parse(readCookie('userData')).userType.name ===
                            'Project Management Unit') ||
                        JSON.parse(readCookie('userData')).type === 'sidbi' ? (
                          <div className="assigned-to-filter">
                            <label className="control-label">Assigned To</label>
                            <Select
                              style={{ width: '100%' }}
                              showSearch
                              disabled={
                                this.state.filterData.userType ? false : true
                              }
                              placeholder="Assign To"
                              onChange={this.changeFilterHandler.bind(
                                this,
                                'assignedTo'
                              )}
                              value={this.state.filterData.assignedTo}
                              filterOption={(input, option) =>
                                this.searchFilter(input, option)
                              }
                            >
                              <Option value="">All</Option>
                              {this.state.allAssignedTo.length
                                ? this.state.allAssignedTo.map(
                                    (assignedTo, assignedToIndex) => {
                                      if (
                                        this.state.filterData.userType ===
                                        'swavalamban-connect-kendra'
                                      ) {
                                        return (
                                          <Option
                                            key={assignedToIndex}
                                            value={assignedTo._id}
                                            title={assignedTo.ngoName}
                                          >
                                            {assignedTo.ngoName}
                                          </Option>
                                        )
                                      } else {
                                        return (
                                          <Option
                                            key={assignedToIndex}
                                            value={assignedTo._id}
                                            title={assignedTo.fullName}
                                          >
                                            {assignedTo.fullName +
                                              ' (' +
                                              assignedTo.email +
                                              ')'}
                                          </Option>
                                        )
                                      }
                                    }
                                  )
                                : null}
                            </Select>
                          </div>
                        ) : null}
                        {/*<div className="filter form-group">
													<label className="control-label">{'From: '}</label>
													<ConfigProvider locale={enUS}>
														<DatePicker locale={enUS} style={{ width: "120px" }} format="DD/MM/YYYY" value={this.state.filterData.fromDate} type="date" onChange={this.changeFilterHandler.bind(this, 'fromDate')} />
													</ConfigProvider>
												</div>
												<div className="filter form-group">
													<label className="control-label">{'To: '}</label>
													<ConfigProvider locale={enUS}>
														<DatePicker locale={enUS} style={{ width: "120px" }} format="DD/MM/YYYY" value={this.state.filterData.toDate} type="date" onChange={this.changeFilterHandler.bind(this, 'toDate')} />
													</ConfigProvider>
												</div>*/}
                        <div className="d-flex filter-btn-container mt10">
                          <div
                            className="reset-btn mr10"
                            onClick={this.resetFilter}
                          >
                            Reset
                          </div>
                          <div
                            className="submit-btn"
                            onClick={this.submitFilter}
                          >
                            Submit
                          </div>
                        </div>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        ref={table => {
                          this.table = table
                        }}
                        rowStyle={this.rowClassNameFormat}
                        remote={{ pagination: true }}
                        onTableChange={this.onTableChange}
                        {...paginationTableProps}
                        expandRow={expandRow}
                        selectRow={selectRow}
                      />
                      <PaginationListStandalone {...paginationProps} />
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          ) : null}
        </Col>

        {this.state.editModalVisibility && this.state.editLeadId ? (
          <Modal
            className="reassign-lead-modal"
            show={this.state.editModalVisibility}
            size="lg"
            onHide={() => {}}
          >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                Edit IVRS Lead
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb20">
              <Row>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">Mobile</label>
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: '100%' }}
                      placeholder="Mobile"
                      value={this.state.editLead.mobile}
                      disabled={true}
                    />
                  </Col>

                  <Col>
                    <label className="control-label is-imp">Pincode</label>
                    <div className="d-flex align-items-center">
                      <input
                        className="form-control"
                        type="text"
                        style={{ width: '100%' }}
                        placeholder="Pincode"
                        value={this.state.editLead.pincode}
                        onChange={this.changeHandler.bind(this, 'pincode')}
                        onBlur={this.pincodeBlur}
                      />
                      {this.state.updatedPin ? (
                        <Button
                          className="btn btn-warning brand-color-background auto-assign-btn"
                          onClick={this.autoAssign}
                          disabled={this.state.gettingDetailsForPincode}
                        >
                          Auto Assign
                        </Button>
                      ) : null}
                    </div>
                  </Col>

                  <Col md={2}>
                    <label className="control-label is-imp">Is Spam</label>
                    <Switch
                      checked={this.state.editLead.isSpam}
                      onChange={this.changeHandler.bind(this, 'isSpam')}
                      checkedChildren={'Yes'}
                      unCheckedChildren={'No'}
                    />
                  </Col>
                </Col>

                {JSON.parse(readCookie('userData')).type !== 'partners' ? (
                  <Col md={3}>
                    <label className="control-label is-imp">Status</label>
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      placeholder="Select Status"
                      onChange={this.changeHandler.bind(this, 'status')}
                      value={this.state.editLead.status}
                    >
                      {/* <Option value="Assigned">Assigned</Option>
											<Option value="Unassigned">Unassigned</Option> */}
                      <Option value="Spam">Spam</Option>
                      <Option value="Unresponsive">Unresponsive</Option>
                      <Option value="Test Call">Test Call</Option>
                      <Option value="Information Call">Information Call</Option>
                      {/* <Option value="Not a focus region">Not a focus region</Option> */}
                    </Select>
                  </Col>
                ) : null}

                {/* {!this.state.editLead.isSpam && this.state.editLead.status === 'Assigned' && JSON.parse(readCookie('userData')).type !== 'partners' ? (
									<Col md={5}>
										<label className="control-label is-imp">Select User Type</label>
										<Select size="large" style={{ width: '100%' }} placeholder="User Type" onChange={this.changeHandler.bind(this, 'userType')} value={this.state.editLead.userType}>
											{this.state.allUserTypes.map((userType, userTypeIndex) => {
												return (
													<Option key={userTypeIndex} value={userType.customUrl}>{userType.name}</Option>
												)
											})}
										</Select>
									</Col>
								) : (null)}

								{!this.state.editLead.isSpam && this.state.editLead.status === 'Assigned' && this.state.allNGOs.length && JSON.parse(readCookie('userData')).type !== 'partners' ? (
									<Col md={4}>
										<label className="control-label is-imp">Assign To</label>
										<Select size="large" showSearch style={{ width: '100%' }} placeholder="Assign To" onChange={this.changeHandler.bind(this, 'assignedTo')} value={this.state.editLead.assignedTo} filterOption={(input, option) => this.searchFilter(input, option)}>
											{this.state.allNGOs.map((ngo, ngoIndex) => {
												if (this.state.editLead.userType === 'swavalamban-connect-kendra') {
													return (
														<Option key={ngoIndex} value={ngo._id}>{ngo.ngoName}</Option>
													)
												} else {
													return (
														<Option key={ngoIndex} value={ngo._id}>{ngo.fullName + ' (' + ngo.email + ')'}</Option>
													)
												}
											})}
										</Select>
									</Col>
								) : (null)} */}

                <Col
                  md={
                    JSON.parse(readCookie('userData')).type !== 'partners'
                      ? 4
                      : 6
                  }
                >
                  <label className="control-label is-imp">State</label>
                  <Select
                    size="large"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select State"
                    onChange={this.changeHandler.bind(this, 'state')}
                    value={this.state.editLead.state}
                    filterOption={(input, option) =>
                      this.searchFilter(input, option)
                    }
                  >
                    <OptGroup label="Focus States">
                      {this.state.allStates.map((state, stateIndex) => {
                        if (state.inFocusRegion) {
                          return (
                            <Option key={stateIndex} value={state.name}>
                              {state.name}
                            </Option>
                          )
                        } else return null
                      })}
                    </OptGroup>
                    <OptGroup label="Not Focussed States">
                      {this.state.allStates.map((state, stateIndex) => {
                        if (!state.inFocusRegion) {
                          return (
                            <Option key={stateIndex} value={state.name}>
                              {state.name}
                            </Option>
                          )
                        } else return null
                      })}
                    </OptGroup>
                  </Select>
                </Col>

                {this.state.allDistricts.length ? (
                  <Col
                    md={
                      JSON.parse(readCookie('userData')).type !== 'partners'
                        ? 4
                        : 6
                    }
                  >
                    <label className="control-label is-imp">District</label>
                    <Select
                      size="large"
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select District"
                      onChange={this.changeHandler.bind(this, 'district')}
                      value={this.state.editLead.district}
                      filterOption={(input, option) =>
                        this.searchFilter(input, option)
                      }
                    >
                      {this.state.allDistricts.map(
                        (district, districtIndex) => {
                          return (
                            <Option key={districtIndex} value={district.name}>
                              {district.name}
                            </Option>
                          )
                        }
                      )}
                    </Select>
                  </Col>
                ) : null}

                <Col md={12}>
                  <label className="control-label is-imp">Comments</label>
                  <textarea
                    style={{ width: '100%', padding: 10, color: '#495057' }}
                    placeholder="Comments"
                    onChange={this.changeHandler.bind(this, 'comments')}
                    value={this.state.editLead.comments}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                className="btn btn-danger brand-color-background"
                onClick={this.hideEditModal}
                disabled={this.state.gettingDetailsForPincode}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-warning brand-color-background"
                onClick={this.updateLead}
                disabled={this.state.gettingDetailsForPincode}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </>
    )
    return (
      <div className="ivrs-leads-dashboard-page page-container">
        <h3>Toll-free Callers</h3>
        <Tabs
          activeKey={this.state.tabKey}
          id="mature-leads-tabs"
          onSelect={e => {
            this.setState({ tabKey: e })
          }}
        >
          <Tab eventKey={1} title={'Inbound Calls'}>
            <InboundCallsTab />
          </Tab>
          <Tab eventKey={2} title={'Outbound Calls'}>
            {/* <InboundCallsTab /> */}
            <OutboundCalls userData={this.props.userData} />
          </Tab>
        </Tabs>
      </div>
    )
  }
}
