import React, { Component } from 'react'
import classnames from 'classnames'
import './style.css'

export default class NotFound extends Component {
  render() {
    const { className } = this.props
    return (
      <div className={classnames('NotFound', className)}>
        <div className="c">
          <div className="_404">404</div>
          <hr />
          <div className="_1">THE PAGE</div>
          <div className="_2">WAS NOT FOUND</div>
          <a className="btn-nf" href="/">
            BACK TO MARS
          </a>
        </div>
      </div>
    )
  }
}
