import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const currentToken = localStorage.getItem('humblebeeAuthToken')
const initialState = {
  status: '',
  beeMitrasData: [],
  loading: false,
  error: '',
}

export const getAllBeeMitras = createAsyncThunk('getAllBeeMitras', async id => {
  console.log('id === project id', id)
  if (id == undefined) {
    console.log('id is undefined', id)
    return null
  }
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/bee-mitras/project/${id}`,
      {
        headers: {
          authorization: `Bearer ${currentToken}`,
        },
      }
    )

    // console.log("this is res.data from getAllSupplerSlice => ", res.data);
    return res.data.beemitras
  } catch (err) {
    console.log('err', err)
    return err.response.data
  }
})

const getAllBeeMitrasSlice = createSlice({
  name: 'getAllBeeMitras',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllBeeMitras.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllBeeMitras.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.beeMitrasData = payload
      }
    },

    [getAllBeeMitras.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllBeeMitrasSlice.reducer
