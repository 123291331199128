import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  suppliersData: [],
  loading: false,
  error: '',
}

export const getSingleSupplier = createAsyncThunk(
  'getSingleSupplier',
  async _id => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/suppliers/${_id}`
      )
      // console.log(
      //   "res.data from getSingleSupplier ==> ",
      //   res.data.singleSupplier
      // );
      return res.data.singleSupplier
    } catch (error) {
      return error.response.data
    }
  }
)

export const resetSingleSupplierData = createAction('resetSingleSupplierData')

const getSingleSupplierSlice = createSlice({
  name: 'getSingleSupplier',
  initialState,
  reducers: {},

  extraReducers: {
    [getSingleSupplier.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getSingleSupplier.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.suppliersData = payload
      }
    },

    [getSingleSupplier.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
    [resetSingleSupplierData]: state => {
      state.suppliersData = []
    },
  },
})

export default getSingleSupplierSlice.reducer
