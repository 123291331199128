/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select, Switch } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import {
  Col,
  Row,
  // Button,
  Modal,
} from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { toast, ToastContainer } from 'react-toastify'
import { readCookie } from '../cookie'
import paginationFactory from 'react-bootstrap-table2-paginator' // , { PaginationProvider }
// import { data } from 'jquery';
// import Text from 'antd/lib/typography/Text';
import TextArea from 'antd/lib/input/TextArea'
import Swal from 'sweetalert2'

const BusinessIdeas = ({ userData }) => {
  const [ideas, setIdeas] = useState(null)
  const [bPlans, setBPlans] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const [inEnglish, setInEnglish] = useState('')
  const [inHindi, setInHindi] = useState('')
  const [inTelugu, setInTelugu] = useState('')
  const [inOdia, setInOdia] = useState('')
  const [literature, setLiterature] = useState('')
  const [enterpriseCategory, setEnterpriseCategory] = useState('')
  const [licenseReqd, setLicenseReqd] = useState(false)
  const [skillTrainingReqd, setSkillTrainingReqd] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  // const [enabledBusinessIdeas, setEnabledBusinessIdeas] = useState([]);
  // const [ideaColumns, setIdeaColumns] = useState([]);

  const {
    Option,
    // OptGroup
  } = Select
  const { SearchBar } = Search
  var plans = []

  const categories = ['Manufacturing', 'Services', 'Trading']
  const getAllIdeas = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/bussinessIdeas/all-detailed',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          // console.log('DATATA RESPONSE', data);
          setIdeas(data.bussinessIdeas)
          // setEnabledBusinessIdeas(data.enabledBusinessIdeasOfProject || []);
          console.log(
            '🚀 ~ file: BusinessIdeas.js:59 ~ getAllIdeas ~ data.enabledBusinessIdeasOfProject:',
            data.enabledBusinessIdeasOfProject
          )
          // this.setState({ allBanks: data.allBanks });
        }
      })
  }

  const getAllMotherBPlans = () => {
    fetch(process.env.REACT_APP_BPLAN_API_URL + '/mbplan', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          console.log(data)
          setBPlans(data.bplans)
        }
      })
  }

  useEffect(() => {
    getAllIdeas()
    getAllMotherBPlans()
  }, [])

  const refreshState = () => {
    setInHindi('')
    setInEnglish('')
    setInTelugu('')
    setInOdia('')
    setEnterpriseCategory('')
    setLicenseReqd(false)
    setSkillTrainingReqd(null)
    setLiterature('')
  }

  const data = {
    inHindi: inHindi,
    inEnglish: inEnglish,
    inTelugu: inTelugu,
    inOdia: inOdia,
    enterpriseCategory: enterpriseCategory,
    combine: inHindi ? inEnglish + ' (' + inHindi + ')' : inEnglish,
    licenseReqd: licenseReqd,
    skillTrainingReqd: skillTrainingReqd,
    literature,
  }

  const nameFormatter = (cell, row) => {
    return <p style={{ color: '#ef5a20' }}>{cell}</p>
  }

  const lisenceFormatter = (cell, row) => {
    return <p style={{ color: '#ef5a20' }}>{cell ? 'Yes' : 'No'}</p>
  }

  const skillsFormatter = (cell, row) => {
    return <p style={{ color: '#ef5a20' }}>{cell ? 'Yes' : 'No'}</p>
  }
  const addNewIdea = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/bussinessIdeas/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
      body: JSON.stringify(data),
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setShowModal(false)
          toast.success('Idea Added succesfully', 2000)
          getAllIdeas()
          refreshState()
        }
      })
  }

  const notifySuccess = text => toast.success(text)
  const notifyError = text => toast.error(text)

  const tagMotherBPlan = row => {
    const dataObj = {
      inHindi: row.inHindi,
      inEnglish: row.inEnglish,
      inOdia: row.inOdia,
      inTelugu: row.inTelugu,
      enterpriseCategory: row.enterpriseCategory,
      combine: row.inHindi
        ? row.inEnglish + ' (' + row.inHindi + ')'
        : row.inEnglish,
      licenseReqd: row.licenseReqd,
      skillTrainingReqd: row.skillTrainingReqd,
      motherBusinessPlans: plans,
      literature,
    }
    fetch(process.env.REACT_APP_API_URL + `/api/v1/bussinessIdeas/${row._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
      body: JSON.stringify(dataObj),
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setShowModal(false)
          toast.success('Mother B Plan(s) tagged succesfully', 2000)
          getAllIdeas()
          refreshState()
        }
      })
  }

  const editIdea = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/bussinessIdeas/${selectedRow._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify(data),
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setShowModal(false)
          toast.success('Idea edited succesfully', 2000)
          getAllIdeas()
          refreshState()
        }
      })
  }
  const literatureFormatter = (cell, row) => {
    return cell ? (
      <p style={{ color: '#ef5a20' }}>Present</p>
    ) : (
      <p style={{ color: '#ef5a20' }}>Not Present</p>
    )
  }

  const toggleIdeaSelection = (selected, businessIdeaId) => {
    const ACTION = selected ? 'enable' : 'disable'

    Swal.fire({
      title: `Are you sure you want to ${ACTION} this idea for this project?`,
      // html: 'This Mother Business Plan will be deleted permanently',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'Yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'No',
    }).then(result => {
      if (result.value) {
        console.log(result, 'result', selected, businessIdeaId)
        fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/bussinessIdeas/${businessIdeaId}/${ACTION}`,
          {
            method: 'PATCH',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              notifySuccess(data.message)
              setIdeas(data.bussinessIdeas)
              // setEnabledBusinessIdeas(data.enabledBusinessIdeas || []);
              // getAllIdeas();
            } else {
              notifyError(data.errorMessage)
            }
            // console.log("🚀 ~ file: BusinessIdeas.js:232 ~ toggleIdeaSelection ~ data.enabledBusinessIdeas:", data.enabledBusinessIdeas)
          })
          .catch(e => {
            notifyError('Action failed! Please try again later!')
          })
      }
    })
  }

  // useEffect(() => {
  // console.log("🚀 ~ file: BusinessIdeas.js:262 ~ BusinessIdeas ~ enabledBusinessIdeas:", enabledBusinessIdeas)

  // setIdeaColumns(toSet);

  // console.log("🚀 ~ file: BusinessIdeas.js:303 ~ useEffect ~ toSet:", toSet)

  // }, [ enabledBusinessIdeas]);

  const editFormatter = (cell, row) => {
    return (
      <a
        style={{ color: '#ef5a20', paddingLeft: 15 }}
        onClick={() => editModal(row)}
      >
        <i className="fa fa-edit mr5"></i>
      </a>
    )
  }

  const businessPlanFormatter = (cell, row) => {
    return (
      <div style={{ flexDirection: 'row', position: 'relative' }}>
        {/* {bPlans && <div> */}
        {bPlans && (
          <Select
            mode="multiple"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            style={{ width: '80%' }}
            defaultValue={row.motherBusinessPlans}
            onChange={val => {
              plans = val
            }}
          >
            {bPlans.map(plan => (
              <Option value={plan._id}>{plan.name}</Option>
            ))}
          </Select>
        )}
        {bPlans && (
          <button
            style={{
              width: '18%',
              borderRadius: 0,
              marginTop: -4,
              height: 32,
              backgroundColor: '#ef5a20',
              color: 'white',
              borderWidth: 0,
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
            onClick={() => tagMotherBPlan(row)}
          >
            Save
          </button>
        )}
        {/* </div>} */}
      </div>
    )
  }
  const ideaColumns =
    userData.userType.name === 'Project Management Unit'
      ? [
          {
            text: '',
            sort: false,
            hidden: true,
            export: true,
            searchable: false,
            dataField: '_id',
          },
          {
            text: 'Name',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'combine',
            searchable: true,
            headerStyle: () => {
              return { width: '300px' }
            },
            formatter: nameFormatter,
          },
          {
            text: 'Enterprise Category',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'enterpriseCategory',
            searchable: false,
            headerStyle: () => {
              return { width: '120px' }
            },
          },
          {
            text: 'Literature',
            sort: false,
            hidden: false,
            export: true,
            dataField: 'literature',
            formatter: literatureFormatter,
            searchable: false,
            headerStyle: () => {
              return { width: '60px' }
            },
          },
          {
            text: 'Mother B Plans',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'motherBplans',
            searchable: false,
            headerStyle: () => {
              return { width: '250px' }
            },
            formatter: businessPlanFormatter,
          },
          {
            text: 'License Required',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'licenseReqd',
            searchable: false,
            headerStyle: () => {
              return { width: '120px' }
            },
            formatter: lisenceFormatter,
          },
          {
            text: 'Skill Training Required',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'skillTrainingReqd',
            searchable: false,
            headerStyle: () => {
              return { width: '120px' }
            },
            formatter: skillsFormatter,
          },
          {
            text: 'Enabled for this project',
            sort: false,
            hidden: false,
            export: true,
            formatter: function (cell, row) {
              return (
                <Switch
                  style={{ width: 60 }}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={cell}
                  onChange={e => toggleIdeaSelection(e, row._id)}
                />
              )
            },
            searchable: false,
            headerStyle: () => {
              return { width: '80px' }
            },
            dataField: 'enabledForThisProject',
          },
          {
            text: 'Edit Idea',
            sort: false,
            hidden: false,
            export: true,
            formatter: editFormatter,
            searchable: false,
            headerStyle: () => {
              return { width: '60px' }
            },
          },
        ]
      : [
          {
            text: '',
            sort: false,
            hidden: true,
            export: true,
            searchable: false,
            dataField: '_id',
          },
          {
            text: 'Name',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'combine',
            searchable: true,
            headerStyle: () => {
              return { width: '300px' }
            },
            formatter: nameFormatter,
          },
          {
            text: 'Enterprise Category',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'enterpriseCategory',
            searchable: false,
            headerStyle: () => {
              return { width: '120px' }
            },
          },
          {
            text: 'Literature',
            sort: false,
            hidden: false,
            export: true,
            dataField: 'literature',
            searchable: false,
            formatter: literatureFormatter,
            headerStyle: () => {
              return { width: '60px' }
            },
          },
          {
            text: 'License Required',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'licenseReqd',
            searchable: false,
            headerStyle: () => {
              return { width: '120px' }
            },
            formatter: lisenceFormatter,
          },
          {
            text: 'Skill Training Required',
            sort: true,
            hidden: false,
            export: true,
            dataField: 'skillTrainingReqd',
            searchable: false,
            headerStyle: () => {
              return { width: '120px' }
            },
            formatter: skillsFormatter,
          },
        ]
  const editModal = row => {
    setEditMode(true)
    setInEnglish(row.inEnglish)
    setInHindi(row.inHindi)
    setInOdia(row.inOdia)
    setInTelugu(row.inTelugu)
    setLicenseReqd(row.licenseReqd)
    setEnterpriseCategory(row.enterpriseCategory)
    setSkillTrainingReqd(row.skillTrainingReqd)
    setSelectedRow(row)
    setLiterature(row.literature)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    refreshState()
  }

  // const paginationOption = {
  // custom: true,
  // totalSize: ideas ?  ideas.length : 100
  // };

  const options = {
    page: 1,
    sizePerPageList: [
      {
        text: '100',
        value: 100,
      },
      {
        text: '200',
        value: 200,
      },
      {
        text: '500',
        value: 500,
      },
    ],
    sizePerPage: 500,
    pageStartIndex: 1,
    paginationSize: 3,
    prePage: '<',
    nextPage: '>',
    firstPage: '<<',
    lastPage: '>>',
    paginationPosition: 'bottom',
  }

  return (
    <div className={userData ? 'page-container schemes-page' : 'schemes-page'}>
      <ToastContainer position="bottom-center" />
      <div>
        {ideas && (
          <ToolkitProvider
            keyField="_id"
            data={ideas}
            columns={ideaColumns}
            search
          >
            {props => (
              //
              //    <PaginationProvider
              // 	pagination={ paginationFactory(paginationOption)}
              // 	>
              // 	{
              // 		({
              // 		paginationProps,
              // 		paginationTableProps
              // 		}) => (
              <div>
                <div className="page-heading d-flex align-items-center">
                  <h3>Business Ideas ({ideas.length})</h3>
                  <div
                    className="filter add-new-scheme-container"
                    style={{
                      right: '40px',
                      position: 'absolute',
                      flexDirection: 'row',
                      display: 'flex',
                    }}
                  >
                    <div style={{ marginRight: 20, width: 300 }}>
                      <SearchBar {...props.searchProps} />
                    </div>
                    <div style={{ height: 30 }}>
                      {userData.userType.name === 'Project Management Unit' && (
                        <button
                          className="btn add-new-scheme-btn"
                          onClick={() => setShowModal(!showModal)}
                        >
                          <i className="fa fa-plus mr5"></i>
                          Add Idea
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {console.log('...props.baseProps', { ...props.baseProps })}
                {ideas && ideaColumns && bPlans && (
                  <BootstrapTable
                    id="business-ideas-table"
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    sizePerPage={20}
                  />
                )}
              </div>
            )}
            {/* </PaginationProvider>)} */}
          </ToolkitProvider>
        )}
      </div>
      <Modal show={showModal} style={{ paddingBottom: 50 }} onHide={closeModal}>
        <Row>
          <div
            style={{ marginLeft: '95%', marginTop: 10 }}
            onClick={closeModal}
          >
            <a>X</a>
          </div>
          <Col md={12}>
            <label className="control-label is-imp">Name in English</label>
            <input
              type="text"
              className="form-control"
              placeholder="Idea in English"
              value={inEnglish}
              onChange={e => setInEnglish(e.target.value)}
            />
          </Col>
          <Col md={12}>
            <label className="control-label">Name in Hindi</label>
            <input
              className="form-control"
              placeholder="Idea in Hindi"
              value={inHindi}
              onChange={e => setInHindi(e.target.value)}
            />
          </Col>
          <Col md={12}>
            <label className="control-label">Name in Telugu</label>
            <input
              className="form-control"
              placeholder="Idea in Telugu"
              value={inTelugu}
              onChange={e => setInTelugu(e.target.value)}
            />
          </Col>
          <Col md={12}>
            <label className="control-label">Name in Odia</label>
            <input
              className="form-control"
              placeholder="Idea in Odia"
              value={inOdia}
              onChange={e => setInOdia(e.target.value)}
            />
          </Col>

          <Col md={12}>
            <label className="control-label is-imp">Enterprise Category</label>
            <Select
              placeholder="Select Idea Category"
              showSearch
              size="large"
              style={{ width: '100%' }}
              onChange={e => setEnterpriseCategory(e)}
              value={
                enterpriseCategory ? enterpriseCategory : 'Select Idea Category'
              }
            >
              <Option key="" value="">
                Select Idea Category
              </Option>
              {/* <OptGroup> */}
              {categories.map(category => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
              {/* </OptGroup> */}
            </Select>
          </Col>
          <Col md={6}>
            <label className="control-label">License Required</label>
            <Switch
              style={{ width: 60 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={licenseReqd}
              onChange={e => setLicenseReqd(e)}
            />
          </Col>
          <Col md={6}>
            <label className="control-label">Skill Training Required</label>
            <Switch
              style={{ width: 60 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={skillTrainingReqd}
              onChange={e => setSkillTrainingReqd(e)}
            />
          </Col>
          <Col md={6}>
            <label className="control-label">Literature</label>
            <TextArea
              value={literature}
              onChange={e => {
                setLiterature(e.target.value)
              }}
            />
            {/* <Switch style={{ width: 60 }} checkedChildren="Yes" unCheckedChildren="No" defaultChecked={literature} onChange={(e) => setLiterature(e)} /> */}
          </Col>
          <Col md={12} style={{ textAlign: 'end' }}>
            {editMode ? (
              <button
                style={{ marginLeft: '70%', marginBottom: 20, marginTop: 20 }}
                className="btn add-new-scheme-btn"
                onClick={() =>
                  inEnglish && enterpriseCategory
                    ? editIdea()
                    : toast.warn('Please fill all required fields', 2000)
                }
              >
                Save Changes
              </button>
            ) : (
              <button
                style={{ marginLeft: '80%', marginBottom: 20, marginTop: 20 }}
                className="btn add-new-scheme-btn"
                onClick={() =>
                  inEnglish && enterpriseCategory
                    ? addNewIdea()
                    : toast.warn('Please fill all required fields', 2000)
                }
              >
                Submit
              </button>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default BusinessIdeas
