/** @format */

import React, { useState, useRef, useMemo, useEffect } from 'react'
import * as Styles from './style'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'

import 'react-quill/dist/quill.snow.css'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import close from '../../../assets/images/close.png'
import { InputGroup } from '../../../shared/InputGroup/InputGroup'

import { BlockData } from '../../../shared/Constants/blocks'

import { SelectGroup } from '../../../shared/SelectGroup/SelectGroup'

import {
  createProject,
  resetErrorinProjectCreation,
  resetMessageinProjectCreation,
} from '../../../reduxLogic/reducers/projects/projectCreationSlice'
import {
  getSingleProject,
  resetSingleProjectData,
} from '../../../reduxLogic/reducers/projects/getSingleProjectDetailsSlice'
import {
  editSingleProject,
  resetErrorinEditProject,
  resetMessageinEditProject,
} from '../../../reduxLogic/reducers/projects/editSingleProjectSlice'

import { getAllProjects } from '../../../reduxLogic/reducers/projects/getAllProjectSlice'
import { getAllUsers } from '../../../reduxLogic/reducers/auth/getAllUsersSlice'
import Select from 'react-select'
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const initialValues = {
  name: '',
  status: '',
  funder: '',
  state: '',
  startDate: '',
  endDate: '',
  targetedBeekeepersCount: 0,
  targetedClustersCount: 0,
  targetedDistricts: [],
  targetedStates: [],
  maxBeekeepersPerDistrict: 0,
  fieldAdmins: [],
  // targetedHivesCount: 0,
  // targetedHoneyProduction: 0,
  // targetedWaxProduction: 0,
  // targetedPropolisProduction: 0,
  // targetedBeeVenomProduction: 0,
  // targetedRoyalJellyProduction: 0,
  // targetedBeeBreadProduction: 0,
  // targetedPollenProduction: 0,
  // targetedTrainingCount: 0,
}

const projectSchema = Yup.object().shape({
  name: Yup.string().required('Project Name is required'),
  funder: Yup.string().required('Funder is required'),
  startDate: Yup.date().required('Start Date is required'),
  status: Yup.string().required('Status is required'),
  targetedBeekeepersCount: Yup.number().required(
    'Targeted Beekeepers Count is required'
  ),
  targetedClustersCount: Yup.number().required(
    'Targeted Clusters Count is required'
  ),
})

const EditProjectForm = props => {
  const {
    openEditModal,
    setOpenEditModal,
    idToEdit,
    setIdToEdit,
    singleRow,
    setSingleRow,
  } = props
  console.log(singleRow)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedStatus, setSelectedStatus] = useState(initialValues.status)

  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [cities, setCities] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [selectedDistrict, setSelectedDistrict] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [projectDataCurrent, setprojectData] = useState(singleRow)
  const { error, message } = useSelector(state => state.createProject)
  const [selectedAdminUsers, setselectedAdminUsers] = useState(false)
  const { allUsers } = useSelector(state => state.allUsers)
  const currentToken = localStorage.getItem('humblebeeAuthToken')

  console.log('this is error from  project creation form ==> ', error)

  useEffect(() => {
    if (allUsers?.length > 0) {
      const updatedArray = singleRow.fieldAdmins.map(item => {
        const matchingItem = allUsers.find(obj => obj.userInfo._id === item)
        console.log(matchingItem.userInfo.name)
        return {
          value: item,
          label: matchingItem.userInfo.name ? matchingItem.userInfo.name : null,
        } // Copy the name if a match is found
      })
      console.log(updatedArray)
      setselectedAdminUsers(updatedArray)
    }
    console.log('all users', allUsers)
  }, [allUsers])

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenEditModal(prev => !prev)
  }

  const statusOptions = ['active', 'inactive']
  const handleStateChange = value => {
    setSelectedStatus(value)
  }
  function toggleState(array, value) {
    if (array.includes(value)) {
      // Remove the value
      return array.filter(item => item !== value)
    } else {
      // Add the value
      return [...array, value]
    }
  }

  const updateProjectdata = async (editId, projectData) => {
    try {
      const currentToken = cookies.get('access_token')
      console.log(currentToken, projectData, editId)

      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/${editId}`,
        projectData,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
            Accept: '/',
          },
        }
      )

      return res.data
    } catch (error) {
      console.log(error)
      toast.error(`${JSON.stringify(error)}`)
    }
  }
  const handleIndianStateChange = tempSelectedStates => {
    setSelectedState(tempSelectedStates)

    const uniqueDistrictsSet = new Set()

    for (let i = 0; i < tempSelectedStates.length; i++) {
      const state = tempSelectedStates[i].value // Assuming each state object has a 'value' field

      BlockData.forEach(item => {
        if (item.state === state) {
          uniqueDistrictsSet.add(item.district)
        }
      })
    }

    setDistricts(Array.from(uniqueDistrictsSet))
  }

  useEffect(() => {
    // Extract unique states from the JSON data
    const uniqueStates = [...new Set(BlockData.map(item => item.state))]
    console.log('uniqueStates', uniqueStates)
    setStates(uniqueStates)
  }, [])

  const handleSubmit = async values => {
    if (isSubmitting) {
      return
    }
    console.log('these are project details in handleSubmit => ', values)

    setIsSubmitting(true)

    const projectData = {
      name: values.name,
      status: projectDataCurrent.status,
      funder: values.funder,
      startDate: values.startDate,
      endDate: values.endDate,
      targetedStates: projectDataCurrent.targetedStates,
      targetedClustersCount: values.targetedClustersCount,
      targetedBeekeepersCount: values.targetedBeekeepersCount,
      targetedDistricts: values.targetedDistricts,
      maxBeekeepersPerDistrict: values.maxBeekeepersPerDistrict,
      fieldAdmins: selectedAdminUsers.map(fieldAdmin => fieldAdmin.value),
    }

    console.log(
      'prokcegkv esl vcs v',
      projectData,
      selectedAdminUsers,
      projectDataCurrent
    )

    let editId = singleRow?._id
    const res = await updateProjectdata(editId, projectData)
    console.log(res)
    if (res.status == 'ok') {
      setOpenEditModal(false)
      setIsSubmitting(false)
      dispatch(getAllProjects())
    }

    // dispatch(createProject(projectData))
    //   .then(() => dispatch(getAllProjects()))
    //   .then(() => setOpenEditModal(false));
  }

  useEffect(() => {
    if (error) {
      toast.error(`${error}`)
      dispatch(resetErrorinProjectCreation())
    }
    dispatch(getAllUsers('field_view_app_admin'))

    if (!error && message) {
      toast.success('Project created succesfully')
      dispatch(resetMessageinProjectCreation())
    }
  }, [error, dispatch, message])

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={projectDataCurrent || initialValues}
          onSubmit={handleSubmit}
          validationSchema={projectSchema}
          validateOnMount
        >
          {formik => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Edit Project</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainSupplierFormDiv>
                <InputGroup
                  PlaceholderText="Enter Project Name"
                  LabelText="Project Name"
                  name="name"
                  type="text"
                  defaultValue={projectDataCurrent.name}
                  star="*"
                  isDisabled={true}
                  onChange={formik.handleChange}
                />
                <InputGroup
                  PlaceholderText="Enter Funder"
                  LabelText="Funder"
                  name="funder"
                  defaultValue={projectDataCurrent.funder}
                  type="text"
                  onChange={formik.handleChange}
                />
                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Status"
                    LabelText="Select status"
                    defaultValue={projectDataCurrent.status}
                    name="status"
                    onChange={e => {
                      setprojectData(prev => {
                        return { ...prev, status: e.target.value }
                      })
                      handleStateChange(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={projectDataCurrent.status} // Add this line
                    options={statusOptions}
                    star="*"
                  />

                  {/* <InputGroup
                    PlaceholderText="Enter Start Date"
                    LabelText="Start Date"
                    defaultValue= {projectDataCurrent.startDate}
                    name="startDate"
                    type="date"
                    onChange={formik.handleChange}
                  />
                  <InputGroup
                    PlaceholderText="Enter End Date"
                    LabelText="End Date"
                    defaultValue={new Date(projectDataCurrent.endDate)}
                    name="endDate"
                    type="date"
                    onChange={formik.handleChange}
                  /> */}
                </Styles.RowFlex>
                <label htmlFor="fieldAdmins" style={{ marginTop: '15px' }}>
                  Assign Field Admins
                </label>
                <Styles.RowFlex>
                  <Select
                    isMulti={true}
                    value={selectedAdminUsers}
                    options={allUsers.map(fieldAdmin => ({
                      value: fieldAdmin.userInfo._id,
                      label: fieldAdmin.userInfo.name,
                    }))}
                    onChange={event => setselectedAdminUsers(event)}
                    styles={{
                      container: provided => ({
                        width: '500px',
                      }),
                      control: base => ({
                        ...base,
                        minHeight: '30px', // Control the dropdown height
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 9999, // Ensures dropdown appears above other elements
                      }),
                      menuPortal: base => ({
                        ...base,
                        zIndex: 9999, // Portal the dropdown so it doesn't get cut off
                      }),
                      option: base => ({
                        ...base,
                        cursor: 'pointer',
                      }),
                    }}
                    menuPortalTarget={document.body} // Ensure dropdown appears outside table
                  />
                </Styles.RowFlex>
                <Styles.RowFlex>
                  <Styles.ColHalf>
                    <label style={{ marginTop: '15px' }}>Select States</label>
                    <Select
                      isMulti
                      options={states.map(state => ({
                        value: state,
                        label: state,
                      }))}
                      onChange={selectedOptions => {
                        console.log(selectedOptions)
                        const valuesArray = selectedOptions.map(
                          item => item.value
                        )

                        // const s=toggleState(projectDataCurrent.targetedStates,selectedOptions.values)
                        setprojectData(prev => {
                          return { ...prev, targetedStates: valuesArray }
                        })
                        handleIndianStateChange(selectedOptions)
                        formik.setFieldValue('targetedStates', selectedOptions)
                      }}
                      value={
                        Array.isArray(projectDataCurrent.targetedStates)
                          ? projectDataCurrent.targetedStates.map(district => ({
                              value: district,
                              label: district,
                            }))
                          : null
                      }
                      // Use formik value for controlled input
                      styles={{
                        container: provided => ({
                          width: '500px',
                        }),
                        control: base => ({
                          ...base,
                          minHeight: '30px',
                        }),
                        menu: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                        option: base => ({
                          ...base,
                          cursor: 'pointer',
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </Styles.ColHalf>

                  <Styles.ColHalf>
                    <div style={{ marginTop: '1rem' }}>
                      <label>District</label>
                      <Select
                        isMulti
                        options={districts.map(district => ({
                          value: district,
                          label: district,
                        }))}
                        onChange={selectedOptions => {
                          const selectedDistricts = selectedOptions.map(
                            option => option.value
                          )
                          formik.setFieldValue(
                            'targetedDistricts',
                            selectedDistricts
                          )
                        }}
                        value={formik.values.targetedDistricts.map(
                          district => ({
                            value: district,
                            label: district,
                          })
                        )}
                      />
                    </div>
                  </Styles.ColHalf>
                </Styles.RowFlex>
                <Styles.RowFlex>
                  <Styles.ColHalf>
                    <InputGroup
                      PlaceholderText="Enter Targeted Beekeepers Count"
                      LabelText="Targeted Beekeepers Count"
                      name="targetedBeekeepersCount"
                      defaultValue={projectDataCurrent.maxBeekeepersPerDistrict}
                      type="number"
                      onChange={formik.handleChange}
                    />
                  </Styles.ColHalf>

                  <Styles.ColHalf>
                    <InputGroup
                      PlaceholderText="Enter Targeted Clusters Count"
                      LabelText="Targeted Clusters Count"
                      defaultValue={projectDataCurrent.targetedClustersCount}
                      name="targetedClustersCount"
                      type="number"
                      onChange={formik.handleChange}
                    />
                  </Styles.ColHalf>
                </Styles.RowFlex>
                <Styles.ColHalf>
                  <InputGroup
                    PlaceholderText="Max Beekeepers per District"
                    LabelText="Max Beekeepers per District"
                    defaultValue={projectDataCurrent.targetedBeekeepersCount}
                    name="maxBeekeepersPerDistrict"
                    type="number"
                    onChange={formik.handleChange}
                  />
                </Styles.ColHalf>
              </Styles.MainSupplierFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateSupplierButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </Styles.CreateSupplierButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  )
}

export default EditProjectForm
