import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  batchData: [],
  loading: false,
  error: '',
  message: '',
}

export const deleteBatch = createAsyncThunk(
  'deleteBatch',
  async (_id, { rejectWithValue }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/batches/${_id}`
      )
      // console.log(
      //   "res.data from getSingleBatch ==> ",
      //   res.data.singleBatch
      // );
      return res.data.singleBatch
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const deleteBatchSlice = createSlice({
  name: 'deleteBatch',
  initialState,
  reducers: {},

  extraReducers: {
    [deleteBatch.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [deleteBatch.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.BatchsData = payload
      }
    },

    [deleteBatch.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.loading = false
    },
  },
})

export default deleteBatchSlice.reducer
