import styled from 'styled-components'

export const BatchBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 85%;
  //overflow-x: scroll;
  padding: 24px;
  margin-left: 15%;
  margin-top: 5%;
`

export const BatchTopSection = styled.div`
  min-height: 50px;
  width: 100%;
`

export const TableContainer = styled.div`
  overflow-x: auto;

  /* Set a max-width to prevent the container from becoming too wide */
  max-width: 100%;
`

export const Table = styled.table`
  border: 1px solid black;
  border-top: 0;

  border-collapse: collapse;
  table-layout: fixed;

  /* Set a max-width to prevent the table from becoming too wide */
  width: 100%;
  /* Set a min-width to ensure that the table remains visible even when its contents are narrow */
  min-width: 1000px; /* or whatever minimum width you want */
`

export const THead = styled.thead`
  background-color: #ff8701;
  color: white;
`

export const THeadRow = styled.tr`
  //border: 2px solid red;
  /* background-color: #ff8701;

  color: white; */
`

export const TH = styled.th`
  //border: 1px solid blue;
  min-width: 60px;
  padding: 4px;
`

export const TBody = styled.tbody`
  /* border: 1px solid red; */
`

export const TBodyRow = styled.tr`
  :nth-child(even) {
    background-color: #e5e4e2;
  }

  :hover {
    background-color: #ff8701;
    color: black;
    //color: white;
    border-color: white;
  }
`

export const TD = styled.td`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-right: 1px solid gray;

  //border: 1px solid blue;
`

export const Button = styled.button`
  border: none;
  background-color: #ff8701;
  padding: 8px;
  border-radius: 4px;
  min-width: 100px;
  min-height: 30px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    font-weight: 700;
  }
`

export const PaginationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;

  input {
    width: 35px;
    outline: none;

    :focus {
      background-color: #ff8701;
    }
  }

  @media (max-width: 480px) {
    gap: 6px;
  }

  @media (max-width: 340px) {
    gap: 2px;
  }

  @media (max-width: 330px) {
    flex-direction: column;
  }
`

export const PaginationButton = styled.button`
  padding: 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #ff8701;
  min-width: 70px;
  border: none;
  cursor: pointer;
  :disabled {
    background-color: gray;
    color: white;
  }
`

export const FarmersSpecialDiv = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 4px;

  span {
    border: 1px solid gray;
    border-radius: 8px;
    padding: 4px;
  }
`

export const ModalButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  padding: 8px;
  background-color: #ff8701;
  width: fit-content;
  cursor: pointer;

  font-weight: bold;
`

export const PaginationInputDiv = styled.div`
  input {
    border-radius: 4px;
    text-align: center;
  }
`

export const PaginationmainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionSpan = styled.span`
  display: flex;
  justify-content: space-around;
  button {
    border: none;
    background-color: transparent;
    width: fit-content;
    cursor: pointer;
    img {
      width: 50%;
    }
  }
`
