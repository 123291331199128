import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const currentToken = cookies.get('access_token')
console.log('currentToken', currentToken)
const initialState = {
  status: '',
  projectData: [],
  loading: false,
  error: '',
}

export const getAllProjects = createAsyncThunk('getAllProjects', async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/`,
      {
        headers: {
          authorization: `Bearer ${currentToken}`,
        },
      }
    )
    console.log('ress', res.data)
    console.log('res.data.projects: ', res.data.projects)
    return res.data.projects
  } catch (err) {
    console.log('err', err)
    return err.response.data
  }
})

const getAllProjectSlice = createSlice({
  name: 'getAllProjects',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllProjects.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllProjects.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.projectData = payload

        state.message = payload.message
        console.log('state.projectData', state.projectData)
      }
    },

    [getAllProjects.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllProjectSlice.reducer
