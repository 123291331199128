import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  singleBatch: {},
  loading: true,
  error: '',
  message: '',
}
export const resetMessageinMakeBatch = createAction('resetMessageinMakeBatch')
export const resetErrorinMakeBatch = createAction('resetErrorinMakeBatch')

export const makeBatch = createAsyncThunk(
  'makeBatch',
  async (body, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/batches/addbatch`,
        body,
        { 'Content-Type': 'multipart/form-data' }
      )
      console.log('body from makeBatch ', body)
      console.log('res.data from makeBatch  ', res.data)
      return res.data
    } catch (error) {
      console.log(error)
      //@ts-ignore
      return rejectWithValue(error.response.data)
    }
  }
)

const makeBatchSlice = createSlice({
  name: 'makeBatch',
  initialState,
  reducers: {},
  extraReducers: {
    [makeBatch.pending]: (state, action) => {
      state.loading = true
    },

    [makeBatch.fulfilled]: (
      state,
      //@ts-ignore
      { payload: { error, singleBatch, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.singleBatch = singleBatch
        state.message = message
      }
    },

    [makeBatch.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.message = message
    },
    [resetErrorinMakeBatch]: state => {
      state.error = ''
    },
    [resetMessageinMakeBatch]: state => {
      state.message = ''
    },
  },
})

export default makeBatchSlice.reducer
