import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const initialState = {
  status: '',
  allStates: [],
  loading: false,
  error: '',
}

export const getAllStates = createAsyncThunk('getAllStates', async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_SWATI_BASE_URL}/api/v1/learning-module-leads/states`,
      {
        // headers: {
        //   authorization: `Bearer ${currentToken}`,
        // },
      }
    )
    console.log('🚀 ~ getAllStates ~ res.data.states:', res.data.data.states)
    return res.data.data.states
  } catch (err) {
    console.log('err in getting states', err)
    return err.response.data
  }
})

const getAllStatesSlice = createSlice({
  name: 'getAllStates',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllStates.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllStates.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.allStates = payload

        state.message = payload.message
      }
    },

    [getAllStates.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllStatesSlice.reducer
