import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePagination, useTable, useSortBy } from 'react-table'
import * as Styles from './style'
import { Link } from 'react-router-dom'
import { changeDateTimeFormat } from '../shared/Helper'
import SupplierForm from './components/SupplierForm/SupplierForm'
import { getAllSuppliers } from '../reduxLogic/reducers/suppliers/getAllSupplierSlice'
import editIcon from '../assets/images/edit.png'
import deleteIcon from '../assets/images/delete.png'
import EditSupplierForm from './components/EditSupplierForm/EditSupplierForm'
import { getSingleSupplier } from '../reduxLogic/reducers/suppliers/getSingleSupplierSlice'
import { deleteSupplier } from '../reduxLogic/reducers/suppliers/deleteSupplierSlice'
import { toast } from 'react-toastify'
import DeleteModal from './components/DeleteModal/DeleteModal'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const Supplier_TABLE_COLUMNS = [
  { Header: 'Supplier', accessor: 'supplierName' },
  { Header: 'Mobile', accessor: 'supplierPrimaryMobile' },
  { Header: 'State', accessor: 'supplierAddress.supplierState' },
  { Header: 'Email', accessor: 'supplierEmail' },
  { Header: 'Added By', accessor: 'addedBy.name' },
  { Header: 'Added On', accessor: 'addedOn' },
  { Header: 'Updated By', accessor: 'updatedBy.name' },
  { Header: 'Updated On', accessor: 'updatedOn' },
  { Header: 'Actions' },
]

const Suppliers = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [idToEdit, setIdToEdit] = useState('')
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState()
  const [singleRow, setSingleRow] = useState({})

  const { suppliersData } = useSelector(state => state.allSuppliers)
  const currentToken = cookies.get('access_token')
  const decoded = jwtDecode(currentToken)
  const role = decoded.role

  const dispatch = useDispatch()

  const fetchAllSuppliersData = () => {
    dispatch(getAllSuppliers())
  }

  useEffect(() => {
    fetchAllSuppliersData()
  }, [dispatch])

  const columns = useMemo(() => Supplier_TABLE_COLUMNS, [])

  const data = useMemo(() => suppliersData, [suppliersData])

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },

    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance
  const { pageIndex } = state

  const handleModalToggle = () => {
    setOpenModal(prev => !prev)
  }

  const handleOpenEditModal = (id, singleRow) => {
    //console.log("this is id in handleOpenEdit => ", id);
    //dispatch(getSingleSupplier(id));
    setSingleRow(singleRow)
    setIdToEdit(id)
    setOpenEditModal(true)
  }

  const handleOpenDeleteModal = id => {
    setDeleteModal(true)
    setIdToDelete(id)
  }

  return (
    <Styles.SupplierBox>
      <Styles.SupplierTopSection>
        <Styles.ModalButton onClick={handleModalToggle}>
          Create Supplier
        </Styles.ModalButton>
        {openModal ? (
          <SupplierForm openModal={openModal} setOpenModal={setOpenModal} />
        ) : null}
      </Styles.SupplierTopSection>
      <Styles.TableContainer>
        {suppliersData && suppliersData ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map(headerGroup => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(headerColumn => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render('Header')}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? ' 🔼'
                            : ' 🔽'
                          : ''}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((singleRow, index) => {
                prepareRow(singleRow)

                return (
                  <Styles.TBodyRow {...singleRow.getRowProps()}>
                    {singleRow.cells.map((singleCell, index) => {
                      // console.log("singleCell qrCode ", singleCell);

                      return (
                        <Styles.TD key={index}>
                          {singleCell.column.Header == 'QR Code' ? (
                            <span style={{ width: '100px' }}>
                              <img
                                style={{ width: '100%' }}
                                src={`${singleCell.value}`}
                                alt="qrcode"
                              />
                            </span>
                          ) : (
                            <span>
                              {singleCell.column.Header == 'Supplier' ? (
                                <Link
                                  to={`/suppliers/${singleCell?.row?.original?._id}`}
                                >
                                  {singleCell.render('Cell')}
                                </Link>
                              ) : (
                                <span>
                                  {singleCell.column.id == 'addedOn' ||
                                  singleCell.column.id == 'updatedOn' ? (
                                    <span>
                                      {changeDateTimeFormat(singleCell.value)}
                                    </span>
                                  ) : (
                                    <span>
                                      {singleCell.column.Header ===
                                      'Actions' ? (
                                        <Styles.ActionSpan>
                                          <button
                                            onClick={() =>
                                              handleOpenEditModal(
                                                singleCell?.row?.original?._id,
                                                singleRow?.original
                                              )
                                            }
                                          >
                                            <img src={editIcon} alt="Edit" />{' '}
                                          </button>

                                          {role == 'Admin' ? (
                                            <button
                                              onClick={() =>
                                                handleOpenDeleteModal(
                                                  singleCell?.row?.original?._id
                                                )
                                              }
                                            >
                                              <img
                                                src={deleteIcon}
                                                alt="Delete"
                                              />
                                            </button>
                                          ) : null}
                                        </Styles.ActionSpan>
                                      ) : (
                                        <span>{singleCell.render('Cell')}</span>
                                      )}
                                    </span>
                                  )}
                                </span>
                              )}
                            </span>
                          )}
                        </Styles.TD>
                      )
                    })}
                  </Styles.TBodyRow>
                )
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>There was some error displaying table</h3>
        )}
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page{''} {pageIndex + 1} of {pageOptions.length}{' '}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span> {'<<'} </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <span> Previous</span>
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(jumpToPageNumber)
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            <span>Next </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span>{'>>'} </span>
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>
      {openEditModal ? (
        <EditSupplierForm
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          idToEdit={idToEdit}
          setIdToEdit={setIdToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      ) : null}

      {openDeleteModal ? (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      ) : null}
    </Styles.SupplierBox>
  )
}

export default Suppliers
