import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  allChats: [],
  loading: false,
  error: '',
}

export const getAllChats = createAsyncThunk('getAllChats', async role => {
  try {
    if (!role) {
      role = 'all'
    }
    const res = await axios.get(
      `${process.env.REACT_APP_QA_MODEL_URL}/wa/chat-logs`
    )
    console.log('res.data from Users ==> ', res.data)
    return res.data
  } catch (error) {
    console.log('error while fetching all users ', error)
    return error.response.data
  }
})

const getAllChatsSlice = createSlice({
  name: 'getAllChats',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllChats.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllChats.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        console.log('error in getAllChats fullfilled ', error)
        state.error = error
      } else {
        console.log('payload in getAllChats fullfilled ', payload)
        state.allChats = payload
      }
    },

    [getAllChats.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllChatsSlice.reducer
