/* eslint-disable jsx-a11y/anchor-is-valid */
import ToolkitProvider from 'react-bootstrap-table2-toolkit' // , { Search }
// import { Redirect } from 'react-router-dom';
import {
  Tabs,
  Tab,
  Col,
  Row,
  // , Button, Modal
} from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
import {
  Steps,
  //  Select,
  Icon,
  Collapse,
} from 'antd'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'
import $ from 'jquery'

// import SingleLeadTasks from "./SingleLeadTasks";
import SingleLeadComments from './SingleLeadComments'
import SingleLeadStrengthRating from './SingleLeadStrengthRating'
import SingleLeadMaturityStages from './SingleLeadMaturityStages'
import AuditChecklists from './AuditChecklists'
import PaymentClaim from './PaymentClaim'
import SingleLeadCoverations from './SingleLeadCoverations'
import SingleLeadCourseDetails from './SingleLeadCourseDetails'
import Enterprise from './Enterprise'
import SingleLeadProfile from './SingleLeadProfile'
import CallLogDetails from '../5-ivrs/leads/CallLogDetails'

const readCookie = require('../cookie.js').readCookie
const knowlarity_emails = require('../../../emails_knowlarity.json')
const { Panel } = Collapse
// const missionSupport = [
//   "Counselling and Guidance",
//   "Securing necessary licenses",
//   "Location Selection",
//   "Skill/Enterprise Training Connect",
//   "Business Proposal Support",
//   "Shop Banner and other design support",
//   "Machinery/Material Procurement",
//   "Credit Linkage",
//   "Business Ideation",
//   "Business Connect/Market Linkage",
//   "Business Plan Preparation",
//   "Entrepreneurship/Financial Training or Training Connect",
//   "Incentive/Subsidy-related support",
//   "Digital Enablement (Location tagging, Product Listing,etc.)",
//   "Other Connections and Networking support",
// ];

const { Step } = Steps

export default class SingleLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayCallLogModal: false,
      branchIFSC: '',
      generatedThrough: null,
      comments: [],
      leadData: null,
      leadProjectsWise: null,
      currentStep: 0,
      businessIdea: '',
      defaultActiveTab:
        this.props.userData.type === 'le' &&
        this.props.userData.fellowAccessType !== 'Partial'
          ? 5
          : 1,
      totalInvestment: '',
      loanAmountRequired: '',
      businessIdeasColumns: [
        {
          text: '',
          sort: false,
          hidden: true,
          export: false,
          searchable: false,
          dataField: '_id',
        },
        {
          text: 'Enterprise Idea',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'enterpriseIdea',
          searchable: true,
        },
        {
          text: 'Enterprise Category',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'enterpriseCategory',
          searchable: true,
        },
        {
          text: 'Idea Details',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'ideaDetails',
          searchable: true,
        },
        {
          text: 'Hero Idea',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'heroIdea',
          searchable: true,
          formatter: this.yesNoFormatter,
        },
        {
          text: 'Ease of execution of idea',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'easeOfIdeaExecution',
          searchable: true,
        },
        {
          text: 'Confidence of demand',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'confidenceOfDemand',
          searchable: true,
        },
        {
          text: 'Confidence of supply',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'confidenceOfSupply',
          searchable: true,
        },
        {
          text: 'Requires Land',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'requiresLand',
          searchable: true,
          formatter: this.yesNoFormatter,
        },
        {
          text: 'Requires Skilling',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'requiresSkills',
          searchable: true,
          formatter: this.yesNoFormatter,
        },
        {
          text: 'Knowledge Level',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'knowledgeLevel',
          searchable: true,
        },
        {
          text: 'Supply Figured out',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'supplyFiguredOut',
          searchable: true,
          formatter: this.yesNoFormatter,
        },
        {
          text: 'Market Access figured out',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'marketAccessFiguredOut',
          searchable: true,
          formatter: this.yesNoFormatter,
        },
      ],
    }
  }

  // SUPPORT BY MISSION
  enterpriseSetupChange = (type, e) => {
    let value = e,
      stages = this.state.stages
    if (e.hasOwnProperty('target')) value = e.target.value
    if (
      type === 'officeShopFactoryAddressUrlLat' ||
      type === 'officeShopFactoryAddressUrlLong'
    ) {
      if (type === 'officeShopFactoryAddressUrlLat')
        stages['enterpriseSetup']['officeShopFactoryAddressUrl']['latitude'] =
          value
      else if (type === 'officeShopFactoryAddressUrlLong')
        stages['enterpriseSetup']['officeShopFactoryAddressUrl']['longitude'] =
          value
      stages['enterpriseSetup']['officeShopFactoryAddressUrl']['url'] =
        `https://maps.google.com/maps?q=${stages.enterpriseSetup.officeShopFactoryAddressUrl.latitude},${stages.enterpriseSetup.officeShopFactoryAddressUrl.longitude}`
    } else if (
      type === 'kycPAN' ||
      type === 'kycUdyogAadhar' ||
      type === 'kycAadhar'
    ) {
      if (type === 'kycPAN') stages.enterpriseSetup.kycDocuments.pan = value
      else if (type === 'kycAadhar')
        stages.enterpriseSetup.kycDocuments.aadhar = value
      else if (type === 'kycUdyogAadhar')
        stages.enterpriseSetup.kycDocuments.udyogAadhar = value
    } else stages['enterpriseSetup'][type] = value
    if (
      type !== 'entityName' &&
      type !== 'officeShopFactoryAddress' &&
      type !== 'officeShopFactoryAddressUrlLat' &&
      type !== 'officeShopFactoryAddressUrlLong' &&
      type !== 'entrepreneurStory' &&
      type !== 'bankAccountNum' &&
      type !== 'bankIFSC' &&
      type !== 'kycPAN' &&
      type !== 'kycUdyogAadhar' &&
      type !== 'kycAadhar'
    ) {
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/lead-maturity/' +
          this.props.leadId +
          '/enterpriseSetup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
          body: JSON.stringify({ [type]: value }),
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ stages })
          } else {
            this.notifyError(data.errorMsg)
          }
        })
    } else {
      this.setState({ stages })
    }
  }

  componentWillMount() {
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/tcacr/' +
        this.props.match.params.leadId,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (!data.error && data.leadInfo !== null) {
          let gen =
            ['DIC', 'Government Departments', 'Social Media'].indexOf(
              data.leadInfo.trainingCenter
            ) > -1
              ? data.leadInfo.trainingCenter
              : `Already Trained Candidate - ${data.leadInfo.trainingCenter}`
          this.setState({ generatedThrough: gen })
        }
      })
    if (this.props.match.params.type === 'comments')
      this.setState({ defaultActiveTab: 2 })
    else if (
      this.props.match.params.type === 'rating' &&
      this.props.userData.type === 'le'
    )
      this.setState({ defaultActiveTab: 3 })
    else if (this.props.match.params.type)
      window.location.pathname = '/lead/' + this.props.match.params.leadId

    if (this.props.match.params.leadId) {
      this.getLeadData(this.props.match.params.leadId)
    } else {
      // window.location.pathname = "/leads";
    }
  }

  getLeadData(leadId) {
    fetch(
      process.env.REACT_APP_API_URL +
        // "/api/v1/swati-lead/" +
        '/api/v1/swati-lead/project-wise-splits/' +
        leadId,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          let loanAmountRequired = 0,
            businessIdea = '',
            totalInvestment = 0
          if (data.lead.enterpriseStatus.loanAmountRequired)
            loanAmountRequired = data.lead.enterpriseStatus.loanAmountRequired

          if (
            data.lead.enterpriseStatus.canSelfFinanceTheCapitalRequiredUpToINR
          ) {
            totalInvestment =
              parseInt(loanAmountRequired) +
              parseInt(
                data.lead.enterpriseStatus
                  .canSelfFinanceTheCapitalRequiredUpToINR
              )
          }

          if (
            data.lead.enterpriseStatus.businessIdeas &&
            data.lead.enterpriseStatus.businessIdeas.length
          ) {
            for (
              let i = 0;
              i < data.lead.enterpriseStatus.businessIdeas.length;
              i++
            ) {
              if (data.lead.enterpriseStatus.businessIdeas[i].heroIdea)
                businessIdea =
                  data.lead.enterpriseStatus.businessIdeas[i].enterpriseIdea
            }
            if (
              !businessIdea &&
              data.lead.enterpriseStatus.businessIdeas[0].enterpriseIdea
            )
              businessIdea =
                data.lead.enterpriseStatus.businessIdeas[0].enterpriseIdea
          }
          let branchIFSC =
            data.lead.leadMaturity.capitalFulfilled.loanApplicationDetails &&
            data.lead.leadMaturity.capitalFulfilled.loanApplicationDetails
              .length &&
            data.lead.leadMaturity.capitalFulfilled.loanApplicationDetails[0][
              'branchIFSC'
            ]
          if (
            branchIFSC &&
            data.lead.leadMaturity.capitalFulfilled.capitalFulfilledStatus !==
              undefined &&
            ['Applied', 'Sanctioned', 'Rejected'].indexOf(
              data.lead.leadMaturity.capitalFulfilled.capitalFulfilledStatus
            ) > -1
          ) {
            this.showLoanModal()
          }
          this.setState({
            branchIFSC,
            leadData: data.lead,
            leadProjectsWise: data.leadProjectsWise,
            comments: data.lead.leadInfo.commentInfo,
            businessIdea,
            loanAmountRequired,
            totalInvestment,
          })
        } else {
          window.location.pathname = '/leads'
        }
      })
  }
  redirectToComment = () => {
    // window.location.pathname = `/bank-branches/${this.state.branchIFSC||""}?openModal=true`;
    this.props.history.push({
      pathname: `/bank-branches/${this.state.branchIFSC || ''}?openModal=true`,
      // order: dataOrders,
      openModal: true,
    })
  }

  showLoanModal = () => {
    Swal.fire({
      title: 'Add an interaction?',
      text: 'Have you or the candidate contacted the bank manager lately? Take a moment to record the interaction.',
      type: 'info',
      showCancelButton: true,
      cancelButtonText: 'No, Please',
      confirmButtonText: 'Yes, Add an interaction',
    }).then(res => {
      if (res.value) {
        this.redirectToComment()
      }
    })
  }
  goBackToLeads = () => {
    window.location.pathname = '/leads'
  }

  refreshLeadData = cb => {
    fetch(
      process.env.REACT_APP_API_URL +
        // "/api/v1/swati-lead/" +
        '/api/v1/swati-lead/project-wise-splits/' +
        this.props.match.params.leadId,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState(
            { leadData: data.lead, leadProjectsWise: data.leadProjectsWise },
            () => {
              if (cb && typeof cb === 'function') cb()
            }
          )
        } else if (cb && typeof cb === 'function') cb()
      })
      .catch(() => {
        if (cb && typeof cb === 'function') cb()
      })
  }

  setTab = tab => {
    this.setState({ defaultActiveTab: parseInt(tab) })
  }

  changeStep = step => {
    if (step !== this.state.currentStep) {
      this.setState({ currentStep: step })
    }
  }

  yesNoFormatter = (cell, row) => {
    if (cell === true) return 'Yes'
    else if (cell === false) return 'No'
    else return null
  }

  newCommentAdded = comment => {
    this.setState({ comments: [...this.state.comments, comment] })
  }

  changeOnHoldStatus = stage => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to remove this lead from hold!',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No, Cancel',
      confirmButtonText: 'Yes, Un-Hold',
    }).then(res => {
      if (res.value) {
        let url =
          process.env.REACT_APP_API_URL +
          '/api/v1/swati-leads/' +
          this.props.match.params.leadId +
          '?putOnHold=false'
        if (stage) url += '&from=' + stage
        fetch(url, {
          method: 'PATCH',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        })
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.notifySuccess('Successfully saved!')
              window.location.reload()
            } else {
              this.notifyError('Could not update')
            }
          })
      }
    })
  }

  changeDisqualificationStatus = () => {
    let leadData = this.state.leadData,
      title = 'Why do you want to disqualify this lead?',
      text = 'Kindly select the reason for disqualifying'

    if (leadData.leadInfo.disqualificationObj.disqualified) {
      title = 'Are you sure you wish to re-qualify this lead?'
      Swal.fire({
        title,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Qualify',
      }).then(res => {
        if (res.value) {
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/swati-leads/' +
              this.props.match.params.leadId +
              '?disqualified=false',
            {
              method: 'PATCH',
              headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
            }
          )
            .then(data => data.json())
            .then(data => {
              if (data.status) {
                this.notifySuccess('Successfully saved!')
                this.refreshLeadData()
              } else {
                this.notifyError('Could not update')
              }
            })
        }
      })
    } else {
      Swal.fire({
        title,
        text,
        input: 'select',
        inputOptions: {
          'No longer interested': 'No longer interested',
          'Has no idea/intention of starting up':
            'Has no idea/intention of starting up',
          'Spam caller': 'Spam caller',
          'Not able to get in touch at all': 'Not able to get in touch at all',
          Migrated: 'Migrated',
          'Passed away': 'Passed away',
          'Poor CIBIL score': 'Poor CIBIL score',
          'Only looking for bank loan (not very genuine)':
            'Only looking for bank loan (not very genuine)',
          'Scale-up: Has availed credit linkage before':
            'Scale-up: Has availed credit linkage before',
          'Duplicate lead': 'Duplicate lead',
          'Candidate started without Mission support':
            'Candidate started without Mission support',
          "Candidate doesn't acknowledge support of Mission Swavalamban":
            "Candidate doesn't acknowledge support of Mission Swavalamban",
          'Other Reason': 'Other Reason',
        },
        type: 'warning',
        showCancelButton: true,
        inputPlaceholder: 'Select the reason',
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Disqualify',
        inputValidator: value => {
          if (value) {
            fetch(
              process.env.REACT_APP_API_URL +
                '/api/v1/swati-leads/' +
                this.props.match.params.leadId +
                '?disqualified=true',
              {
                method: 'PATCH',
                headers: {
                  'Content-Type': 'application/JSON',
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
                body: JSON.stringify({ disqualificationReason: value }),
              }
            )
              .then(data => data.json())
              .then(data => {
                if (data.status) {
                  this.notifySuccess('Successfully saved!')
                  this.refreshLeadData()
                } else {
                  this.notifyError('Could not update')
                }
              })
          }
        },
      })
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)
  callLead() {
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to place a call to ${this.state.leadData.basic.mobileNumber}?`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes, Please do',
      confirmButtonAriaLabel: 'Yes, Please do',
      cancelButtonText: 'No, Cancel',
      cancelButtonAriaLabel: 'No, Cancel',
    }).then(res => {
      if (res.value) {
        this.placeCall()
      }
    })
  }
  placeCall() {
    // let knowlarity_makecall__url =
    //   "https://kpi.knowlarity.com/Basic/v1/account/call/makecall";
    let EXOTEL_MAKECALL_URL =
      process.env.REACT_APP_API_URL + '/api/v1/call-logs/exotel/click-To-Call'
    fetch(EXOTEL_MAKECALL_URL, {
      method: 'POST',
      headers: {
        // "x-api-key": "qRwE4Ugq4C3XJfNlUWUkl3CpwZ77ryWradPalKfY",
        // Authorization: "f925251f-c0aa-4b89-bdf9-60f1b06ec111",
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
      body: JSON.stringify({
        to: '+91' + this.state.leadData.basic.mobileNumber,
        from: '+91' + this.props.userData.mobileNumber,
      }),
    })
      .then(data => data.json())
      .then(data => {
        console.log(data)
        if (!data.error) {
          this.notifySuccess(data.message)
        } else {
          this.notifyError(`Couldn't make call!`)
        }
      })
  }

  render() {
    // var step1HasElem = false,
    //   step2HasElem = false,
    //   step3HasElem = false,
    //   step4HasElem = false;
    if (this.state.leadData !== null) {
      return (
        <div className="page-container single-lead-page">
          <div className="top-container">
            <span className="back" onClick={this.goBackToLeads}>
              <i className="fa fa-2x fa-chevron-left"></i>
            </span>
            <h2 className="lead-name">
              <span className="name">{this.state.leadData.basic.name}</span>
              <span>{' - ' + this.state.leadData.basic.mobileNumber}</span>

              {
                /*this.props.userData.userType.name==='Project Management Unit' ||*/ (knowlarity_emails.includes(
                  this.props.userData.email
                ) ||
                  // this.props.userData.userType.name === "Zonal Manager" ||
                  this.props.userData.userType.name ===
                    'Swavalamban Fellows') && (
                  // &&
                  //   this.props.userData.fellowAccessType === "Full"
                  <span
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                      marginRight: '2px',
                    }}
                    className="call-icon"
                    onClick={this.callLead.bind(this)}
                  >
                    <i
                      style={{ fontSize: 25 }}
                      title="Call Lead"
                      className="fa fa-phone"
                    />
                  </span>
                )
              }
              <a
                className="whatsapp-icon"
                href={
                  'https://wa.me/91' + this.state.leadData.basic.mobileNumber
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <i style={{ fontSize: 24 }} className="fa fa-whatsapp" />
              </a>

              {this.state.displayCallLogModal &&
              this.state.leadData.basic.mobileNumber ? (
                <CallLogDetails
                  userData={this.props.userData}
                  callerNumber={this.state.leadData.basic.mobileNumber}
                  setModalVisibility={value => {
                    this.setState({ displayCallLogModal: value })
                  }}
                  setCallerNumber={() => {}}
                  modalVisibility={this.state.displayCallLogModal}
                  // formatSeconds={formatSeconds}
                  // formatDate={formatDate}
                  // formatMobile={formatMobile}
                />
              ) : (
                false
              )}

              <div className="call-log-icon">
                <i
                  onClick={() => {
                    this.setState({ displayCallLogModal: true })
                  }}
                  title="Call history"
                  style={{ fontSize: 24 }}
                  className="fa fa-history"
                />
              </div>
              {this.state.leadData.leadInfo.audit !== undefined &&
              this.state.leadData.leadInfo.audit.status === 'Audited' ? (
                <img
                  src="/images/check.png"
                  width="16"
                  className="ml-2"
                  alt=""
                />
              ) : null}
            </h2>
          </div>
          {JSON.parse(readCookie('userData')).type !== 'sidbi' &&
          this.state.defaultActiveTab === 1 ? (
            <div className="buttons-container">
              {this.state.leadData.leadInfo.onHoldObj.onHold ? (
                <button
                  className="btn on-hold-lead-btn"
                  onClick={this.changeOnHoldStatus}
                >
                  UN-HOLD
                </button>
              ) : null}
              {JSON.parse(readCookie('userData')).userType.name !== 'Funder' ? (
                <button
                  className="btn edit-lead-btn"
                  onClick={() => {
                    window.location.pathname =
                      '/edit-lead/' + this.props.match.params.leadId
                  }}
                >
                  EDIT PROFILE
                </button>
              ) : null}
              {this.state.leadData.leadInfo.disqualificationObj.disqualified ? (
                <button
                  className={
                    this.state.leadData.leadInfo.disqualificationObj
                      .disqualified
                      ? 'btn disqualify-lead-btn disqualified'
                      : 'btn disqualify-lead-btn'
                  }
                  onClick={this.changeDisqualificationStatus}
                >
                  {this.state.leadData.leadInfo.disqualificationObj.disqualified
                    ? 'REQUALIFY'
                    : 'DISQUALIFY'}
                </button>
              ) : null}
            </div>
          ) : null}
          {this.state.leadData.leadInfo.disqualificationObj.disqualified ? (
            <div className="disqualified-container">
              <span className="disqualification-reason">
                {'Reason: '}
                <span>
                  {
                    this.state.leadData.leadInfo.disqualificationObj
                      .disqualificationReason
                  }
                </span>
              </span>
              <span className="disqualification-reason">
                {'Disqualified On: '}
                <span>
                  {moment(
                    this.state.leadData.leadInfo.disqualificationObj
                      .disqualifiedOn
                  ).format('LLL')}
                </span>
              </span>
              <span className="disqualification-reason">
                {'Disqualified By: '}
                <span>
                  {
                    this.state.leadData.leadInfo.disqualificationObj
                      .disqualifiedByName
                  }
                </span>
              </span>
            </div>
          ) : this.state.leadData.leadInfo.onHoldObj.onHold ? (
            <div className="put-on-hold-container">
              {this.state.leadData.leadInfo.onHoldObj.onHoldReason ? (
                <span className="disqualification-reason">
                  {'Reason: '}
                  <span>
                    {this.state.leadData.leadInfo.onHoldObj.onHoldReason}
                  </span>
                </span>
              ) : null}
              <span className="disqualification-reason">
                {'Put on Hold since: '}
                <span>
                  {moment(
                    this.state.leadData.leadInfo.onHoldObj.onHoldOn
                  ).format('LLL')}
                </span>
              </span>
              <span className="disqualification-reason">
                {'Put on Hold by: '}
                <span>
                  {this.state.leadData.leadInfo.onHoldObj.onHoldByName}
                </span>
              </span>
              {this.state.leadData.leadInfo.onHoldObj.nextFollowUpDate ? (
                <span className="disqualification-reason">
                  {'Put on Hold until: '}
                  <span>
                    {moment(
                      this.state.leadData.leadInfo.onHoldObj.nextFollowUpDate
                    ).format('LL')}
                  </span>
                </span>
              ) : null}
            </div>
          ) : null}
          <Tabs
            activeKey={this.state.defaultActiveTab}
            id="lead-tabs"
            onSelect={tab => this.setTab(tab)}
          >
            <Tab
              eventKey={1}
              title={
                <div className="lead-tab-header">
                  <i className="fa fa-user"></i>
                  <span> Lead Profile</span>
                </div>
              }
            >
              <SingleLeadProfile
                leadProjectsWise={this.state.leadProjectsWise}
                leadData={this.state.leadData}
                currentStep={this.state.currentStep}
                generatedThrough={this.state.generatedThrough}
                businessIdeasColumns={this.state.businessIdeasColumns}
              />
            </Tab>

            {this.props.userData.userType.name !== 'Funder' ||
            this.props.userData.userType.name !== 'sidbi' ? (
              <Tab
                eventKey={2}
                title={
                  <div className="lead-tab-header">
                    <i className="fa fa-sticky-note"></i>
                    <span>
                      {' '}
                      Comments{' '}
                      {this.state.comments.filter(comment => !comment.isDeleted)
                        .length
                        ? `(${
                            this.state.comments.filter(
                              comment => !comment.isDeleted
                            ).length
                          })`
                        : null}
                    </span>
                  </div>
                }
              >
                <SingleLeadComments
                  leadId={this.props.match.params.leadId}
                  comments={this.state.comments}
                  newCommentAdded={this.newCommentAdded}
                />
              </Tab>
            ) : null}

            {/* {this.props.userData.type === 'le' ? (
							<Tab eventKey={3} title={<div className="lead-tab-header"><i className='fa fa-tasks'></i><span> Tasks</span></div>}>
								<SingleLeadTasks leadId={this.props.match.params.leadId} leadNumber={this.state.leadData.basic.mobileNumber} leadName={this.state.leadData.basic.name} />
							</Tab>
						) : (null)} */}

            {this.props.userData.type === 'le' &&
            false &&
            this.state.leadData.leadInfo.profileCompeletion !== 100 ? (
              <Tab
                eventKey={4}
                title={
                  <div className="lead-tab-header">
                    <i className="fa fa-star"></i>
                    <span>
                      {this.state.leadData.strengthRating
                        ? ' Strength Rating (' +
                          this.state.leadData.strengthRating +
                          ')'
                        : ' Strength Rating'}
                    </span>
                  </div>
                }
              >
                <SingleLeadStrengthRating
                  leadId={this.props.match.params.leadId}
                />
              </Tab>
            ) : null}

            {['le', 'partners', 'sidbi'].includes(this.props.userData.type) ? (
              <Tab
                eventKey={5}
                title={
                  <div className="lead-tab-header">
                    <i className="fas fa-chart-line"></i>
                    <span> Maturity Stages</span>
                  </div>
                }
              >
                {this.state.leadProjectsWise &&
                this.state.leadProjectsWise.length > 1 ? (
                  <Collapse
                    accordion
                    defaultActiveKey={
                      this.state.leadProjectsWise[0].projectId._id
                    }
                  >
                    {this.state.leadProjectsWise.map((project, index) => (
                      <Panel
                        header={project.projectId.name}
                        key={project.projectId._id}
                      >
                        <SingleLeadMaturityStages
                          leadId={this.props.match.params.leadId}
                          projectId={project.projectId._id}
                          selfInvestment={
                            project.enterpriseStatus
                              .canSelfFinanceTheCapitalRequiredUpToINR
                              ? parseInt(
                                  project.enterpriseStatus
                                    .canSelfFinanceTheCapitalRequiredUpToINR
                                )
                              : 0
                          }
                          isEditable={index === 0}
                          loanAmountRequired={this.state.loanAmountRequired}
                          totalInvestment={this.state.totalInvestment}
                          // businessIdea={this.state.businessIdea}
                          businessIdea={
                            project.enterpriseStatus.businessIdeas &&
                            project.enterpriseStatus.businessIdeas.length
                              ? project.enterpriseStatus.businessIdeas.filter(
                                  x => x.heroIdea
                                ).length
                                ? project.enterpriseStatus.businessIdeas.filter(
                                    x => x.heroIdea
                                  )[0].enterpriseIdea || ''
                                : project.enterpriseStatus.businessIdeas[0]
                                    .enterpriseIdea
                              : ''
                          }
                          leadData={project}
                          refreshLeadData={this.refreshLeadData}
                          changeOnHoldStatus={this.changeOnHoldStatus}
                          changeDisqualificationStatus={
                            this.changeDisqualificationStatus
                          }
                        />
                      </Panel>
                    ))}
                  </Collapse>
                ) : (
                  <SingleLeadMaturityStages
                    leadId={this.props.match.params.leadId}
                    selfInvestment={
                      this.state.leadData.enterpriseStatus
                        .canSelfFinanceTheCapitalRequiredUpToINR
                        ? parseInt(
                            this.state.leadData.enterpriseStatus
                              .canSelfFinanceTheCapitalRequiredUpToINR
                          )
                        : 0
                    }
                    projectId={this.state.leadProjectsWise[0].projectId._id}
                    isEditable={true}
                    loanAmountRequired={this.state.loanAmountRequired}
                    totalInvestment={this.state.totalInvestment}
                    businessIdea={this.state.businessIdea}
                    leadData={this.state.leadData}
                    refreshLeadData={this.refreshLeadData}
                    changeOnHoldStatus={this.changeOnHoldStatus}
                    changeDisqualificationStatus={
                      this.changeDisqualificationStatus
                    }
                  />
                )}
              </Tab>
            ) : null}

            {this.props.userData.type === 'le' && false ? (
              <Tab
                eventKey={6}
                title={
                  <div className="lead-tab-header">
                    <i className="fa fa-comments-o"></i>
                    <span> Conversations</span>
                  </div>
                }
              >
                <SingleLeadCoverations />
              </Tab>
            ) : null}

            {/* currently disabled until the feature is finished */}
            {this.props.userData.type === 'le' && false ? (
              <Tab
                eventKey={7}
                title={
                  <div className="lead-tab-header">
                    <i className="fa fa-university"></i>
                    <span> Course Details</span>
                  </div>
                }
              >
                <SingleLeadCourseDetails
                  leadMobileNumber={this.state.leadData?.basic?.mobileNumber}
                />
              </Tab>
            ) : null}

            {(this.props.userData.type === 'le' ||
              this.props.userData.type === 'partners') &&
            (this.state.leadData.leadMaturity.enterpriseSetup
              .enterpriseSetupStatus === 'Started up' ||
              this.state.leadData.leadMaturity.enterpriseSetup
                .enterpriseSetupStatus === 'Scaled up') ? (
              <Tab
                eventKey={3}
                title={
                  <div className="lead-tab-header">
                    <i className="fa fa-check"></i>
                    <span> Audit Checklist</span>
                  </div>
                }
              >
                {/* <SingleLeadTasks leadId={this.props.match.params.leadId} leadNumber={this.state.leadData.basic.mobileNumber} leadName={this.state.leadData.basic.name} /> */}
                <AuditChecklists
                  userData={this.props.userData}
                  leadData={this.state.leadData}
                  refreshLeadData={this.refreshLeadData}
                />
              </Tab>
            ) : null}
            {this.props.userData.type !== 'sidbi' &&
            this.props.userData.userType.name !== 'Funder' &&
            (this.state.leadData.leadMaturity.enterpriseSetup
              .enterpriseSetupStatus === 'Started up' ||
              this.state.leadData.leadMaturity.enterpriseSetup
                .enterpriseSetupStatus === 'Scaled up') ? (
              <Tab
                eventKey={4}
                title={
                  <div className="lead-tab-header">
                    <i className="fas fa-receipt"></i>
                    <span> Payment Claim</span>
                  </div>
                }
              >
                {/* <SingleLeadTasks leadId={this.props.match.params.leadId} leadNumber={this.state.leadData.basic.mobileNumber} leadName={this.state.leadData.basic.name} /> */}
                {/* <AuditChecklists
                  userData={this.props.userData}
                  leadData={this.state.leadData}
                  refreshLeadData={this.refreshLeadData}
                /> */}
                <PaymentClaim
                  userData={this.props.userData}
                  leadData={this.state.leadData}
                />
              </Tab>
            ) : null}
            {this.props.userData.type === 'le' ||
            this.props.userData.accountType === 'Udaan' ? (
              <Tab
                eventKey={8}
                title={
                  <div className="lead-tab-header">
                    <i class="fa fa-ticket"></i>
                    <span> Enterprise Support Service </span>
                  </div>
                }
              >
                <Enterprise
                  className="mt-5"
                  userData={this.props.userData}
                  leadData={this.state.leadData}
                  refreshLeadData={this.refreshLeadData}
                />
              </Tab>
            ) : null}
          </Tabs>
        </div>
      )
    } else return null
  }
}
