import React from 'react'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'

const StyledError = styled.div`
  color: red;
  margin-top: 4px;
`

export const FieldErrorMessage = props => {
  //console.log("props from FieldErrorMessage => ", props);
  return <ErrorMessage name={props.name || ''} component={StyledError} />
}
