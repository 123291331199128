import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { useForm } from '../../hooks/form-hook'
import Label from '../../components/FormElements/label'
import moment from 'moment'
import { DatePicker, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Input from '../../components/FormElements/Input'
import { VALIDATOR_REQUIRE } from '../../util/validators'
import { useHttpHook } from '../../hooks/http-hook'
import Swal from 'sweetalert2'

const { RangePicker } = DatePicker
const readCookie = require('../../../cookie.js').readCookie
const PageLayout = props => {
  const centerId = props.match.params.centerId
  const {
    isLoading,
    // error,
    sendRequest,
    clearError,
  } = useHttpHook()

  const [program, setProgram] = useState([
    'Bee-Training',
    'paperplates manufacturing',
  ])
  const [fromdate, setFromDate] = useState()
  // eslint-disable-next-line no-unused-vars
  const [loadedTraining, setLoadedTraining] = useState()
  const [todate, setToDate] = useState()
  const [formState, inputHandler] = useForm(
    {
      program: {
        value: '',
        isValid: false,
      },
    },
    false
  )

  //fetch programs
  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/center/training-schedule/view/${centerId}`
        )
        setLoadedTraining(responseData.training)
        setProgram(prevProgram =>
          prevProgram.filter(
            program =>
              program !== responseData.training.map(prog => prog.program)
          )
        )
      } catch (err) {
        //alert(err);
      }
    }
    fetchSchedule()
  }, [sendRequest, centerId])

  const dateValue = current => {
    if (current) {
      setFromDate(current[0])
      setToDate(current[1])
      console.log(current)
    }
  }

  const submitTraining = async event => {
    if (todate && fromdate) {
      event.preventDefault()

      try {
        await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/center/training-schedule/',
          'POST',
          JSON.stringify({
            program: formState.inputs.program.value,
            date: [
              {
                from: fromdate,
                to: todate,
              },
            ],
            centerId: centerId,
          }),
          {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          }
        )
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'Successfully created',
          showConfirmButton: false,
          timer: 1500,
        })
      } catch (err) {
        alert('error')
      }
      props.onClose()
      window.location.reload(false)
    }
    setFromDate(undefined)
    setToDate(undefined)
  }

  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }

  return (
    <Container
      hidden={props.onShow}
      style={{ boxShadow: ' 5px 5px 5px 5px #888888 ', padding: ' 10px ' }}
    >
      {isLoading && <Spinner animation="border" variant="dark" />}
      {clearError}
      <CloseOutlined style={{ float: 'right' }} onClick={props.onClose} />
      <Row>
        <Col sm={3}>
          <Label name="Select Program" />
          {!isLoading && (
            <Input
              element="Select"
              id="program"
              onInput={inputHandler}
              errorText="Please Select Correctly"
              validators={[VALIDATOR_REQUIRE()]}
              option={program}
              name="trainingname"
            />
          )}
        </Col>
        <Col sm={6}>
          <Label name="From - To Date" />
          <RangePicker
            disabledDate={disabledDate}
            size="large"
            onChange={dateValue}
          />
          {isLoading ? (
            <Spinner animation="border" variant="success" />
          ) : (
            <Button
              type="dashed"
              disabled={!formState.isValid}
              onClick={submitTraining}
              style={{ float: 'right' }}
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default PageLayout
