/** @format */

import React from 'react'
import { DatePicker, Select } from 'antd'
import {
  Col,
  Row,
  // Button, Modal
} from 'react-bootstrap'
import moment from 'moment'
const { OptGroup, Option } = Select
const { MonthPicker } = DatePicker

const ExistingEnterpriseRelated = ({
  leadData,
  projectData,
  globalDDFormState,
  setGlobalDDFormState,
  isEditable,
  dueDiligenceChange,
}) => {
  return (
    <>
      {leadData.enterpriseStatus.phase === 'Scale-up' ? (
        <div className="scale-up-container">
          <h3>EXISTING ENTERPRISE RELATED</h3>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              When did you start the business?
            </label>
            <MonthPicker
              picker="month"
              size="large"
              style={{ width: '50%' }}
              placeholder="Select start month"
              value={
                globalDDFormState.existingenterpriserelated.businessStartMonth
                  ? moment(
                      globalDDFormState.existingenterpriserelated
                        .businessStartMonth
                    )
                  : null
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'businessStartMonth'
                )
              }
              format="MMM YYYY"
            />
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              How much investment have you made in your business till date?
              (INR)
            </label>
            <div className="inr-input">
              <span className="inr-symbol">₹</span>
              <input
                type="text"
                placeholder="Investment made in business (INR)"
                value={
                  globalDDFormState.existingenterpriserelated
                    .investmentInBusiness
                    ? String(
                        globalDDFormState.existingenterpriserelated
                          .investmentInBusiness
                      )
                        .replace(/\D/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
                }
                className={'form-control'}
                disabled={!isEditable}
                onChange={e =>
                  dueDiligenceChange(
                    e,
                    'existingenterpriserelated',
                    // "questions",
                    'investmentInBusiness'
                  )
                }
              />
            </div>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Enterprise has GST registration?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.hasGSTRegistration
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'hasGSTRegistration'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">Enterprise has GST returns?</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={globalDDFormState.existingenterpriserelated.hasGSTReturns}
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'hasGSTReturns'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Enterprise has Income Tax (IT) returns?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={globalDDFormState.existingenterpriserelated.hasITReturns}
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'hasITReturns'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Enterprise has a TIN number?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={globalDDFormState.existingenterpriserelated.hasTINNumber}
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'hasTINNumber'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Enterprise is registered under MSME/Udyog Aadhar?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated
                  .registeredUnderMSMEUdyogAadhar
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'registeredUnderMSMEUdyogAadhar'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Enterprise has all business relevant licenses?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.hasRelevantLicenses
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'hasRelevantLicenses'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Any plant/machinery owned, any other assets linked to the
              business?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.otherBusinessAssets
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'otherBusinessAssets'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">Worth of the asset (INR)</label>
            <div className="inr-input">
              <span className="inr-symbol">₹</span>
              <input
                type="text"
                placeholder="Worth of the asset (INR)"
                value={
                  globalDDFormState.existingenterpriserelated
                    .worthOfBusinessAsset
                    ? String(
                        globalDDFormState.existingenterpriserelated
                          .worthOfBusinessAsset
                      )
                        .replace(/\D/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
                }
                className={'form-control'}
                disabled={!isEditable}
                onChange={e =>
                  dueDiligenceChange(
                    e,
                    'existingenterpriserelated',
                    // "questions",
                    'worthOfBusinessAsset'
                  )
                }
              />
            </div>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Enterprise has an active current bank account?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.hasActiveBankAccount
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'hasActiveBankAccount'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              What is the average monthly transaction size (sum of deposits and
              withdrawals)?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated
                  .avgMonthlyTransactionSize
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'avgMonthlyTransactionSize'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {[
                '<INR 10000',
                'INR 10000-25000',
                'INR 25000-INR 50000',
                'INR 50000-100000',
                '>INR 100000',
              ].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Do you maintain your book of accounts?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.maintainAccounts
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'maintainAccounts'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">
              Do you have purchase invoices?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.havePurchaseInvoices
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'havePurchaseInvoices'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">Do you have sales invoices?</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              placeholder="Select"
              value={
                globalDDFormState.existingenterpriserelated.haveSalesInvoices
              }
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'existingenterpriserelated',
                  // "questions",
                  'haveSalesInvoices'
                )
              }
            >
              <Option value="" disabled>
                Select Option
              </Option>
              {['Yes', 'No'].map((option, index) => {
                return (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                )
              })}
            </Select>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">Current Monthly Sales (INR)</label>
            <div className="inr-input">
              <span className="inr-symbol">₹</span>
              <input
                type="text"
                placeholder="Current Monthly Sales (INR)"
                value={
                  globalDDFormState.existingenterpriserelated
                    .currentMonthlySales
                    ? String(
                        globalDDFormState.existingenterpriserelated
                          .currentMonthlySales
                      )
                        .replace(/\D/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
                }
                className={'form-control'}
                disabled={!isEditable}
                onChange={e =>
                  dueDiligenceChange(
                    e,
                    'existingenterpriserelated',
                    // "questions",
                    'currentMonthlySales'
                  )
                }
                onBlur={
                  globalDDFormState.existingenterpriserelated
                    .promptCurrentMonthlyIncome
                }
              />
            </div>
          </Col>

          <Col md={12} className="due-diligence-question same-line">
            <label className="control-label">Current Monthly Costs (INR)</label>
            <div className="inr-input">
              <span className="inr-symbol">₹</span>
              <input
                type="text"
                placeholder="Current Monthly Costs (INR)"
                value={
                  globalDDFormState.existingenterpriserelated
                    .currentMonthlyCosts
                    ? String(
                        globalDDFormState.existingenterpriserelated
                          .currentMonthlyCosts
                      )
                        .replace(/\D/g, '')
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''
                }
                className={'form-control'}
                disabled={!isEditable}
                onChange={e =>
                  dueDiligenceChange(
                    e,
                    'existingenterpriserelated',
                    // "questions",
                    'currentMonthlyCosts'
                  )
                }
                onBlur={
                  globalDDFormState.existingenterpriserelated
                    .promptCurrentMonthlyIncome
                }
              />
            </div>
          </Col>
        </div>
      ) : null}
    </>
  )
}

export default ExistingEnterpriseRelated
