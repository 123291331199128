import styled from 'styled-components'
import { Field, Form } from 'formik'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: rgba(32, 35, 40, 0.1);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled.div`
  background-color: white;
  width: 70vw;
  /* height: 30vh; */
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 720px) {
    width: 75vw;
  }
`
export const FormWithCloseButton = styled(Form)`
  display: flex;
  flex-direction: column;
  box-shadow:
    rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding: 20px;
  width: 100%;
  height: 90vh;
  overflow-y: scroll;
  gap: 8px;
`

export const CreateBatchButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 4px 24px;
  background-color: #ff8701;
  height: 24px;
  width: 7vw;
  min-width: 70px;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover {
    font-weight: bold;
  }

  :disabled {
    background-color: gray;
  }
`

export const FormCloseButton = styled.button`
  border: none;
  cursor: pointer;

  background-color: white;
  height: 12px;
  margin-right: -12px;
  margin-top: -6px;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  :hover {
    font-weight: bold;
  }
  img {
    height: 20px;
  }
`

export const SelectOption = styled.option`
  padding: 4px;

  margin-bottom: 4px;
`

export const CloseButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const SelectSingleFormikField = styled(Field)`
  padding: 4px;
`

export const SelectMultiFormikField = styled(Field)`
  height: 20px;
  :focus {
    height: 100px;
    z-index: 2;
    position: absolute;
  }
`

export const DateGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @media (max-width: 630px) {
    flex-direction: column;
    gap: 12px;
    width: 100%;
    margin-bottom: 10px;

    div {
      width: 100%;
    }
  }
`

export const DynamicbatchItemsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
`

export const BatchLabels = styled.label`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  letter-spacing: 0.01em;
  margin-bottom: 10px;
  color: #000000;
  margin-top: 20px;
`

////////////////////  Field Array Styles ////////////////

export const RemoveButton = styled.button`
  border: 1px solid red;
  border-radius: 8px;
  padding: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: red;
  min-width: 20px;
  min-height: 20px;
`
export const AddButton = styled.button`
  border: 1px solid #ff8701;
  border-radius: 8px;
  padding: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ff8701;
  min-width: 20px;
  min-height: 20px;
`
export const FieldArrayLabel = styled.label`
  font-weight: bold;
`

export const FieldArrayInput = styled.input`
  width: 20px;
`

export const FieldArrayField = styled(Field)`
  padding: 5px;
  width: 100%;
`

export const LabelFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  //height: 100%;
  position: relative;
  @media (max-width: 820px) {
    gap: 12px;
  }
`

export const BatchItemsContainer = styled.div`
  display: grid;
  //flex-direction: row;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  gap: 6px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid gray;
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;

  @media (max-width: 820px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`

export const AddRemoveButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  @media (max-width: 820px) {
    justify-content: center;
  }
`

export const MultiSelectContainer = styled.select`
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 12px auto;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 1; // add z-index

  &:focus {
    outline: none;
    border-color: #66afe9;
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.25);
    z-index: 15; // increase z-index when focused
  }

  &:hover {
    background-color: #f0f0f0;
  }

  & option {
    font-weight: normal;
  }

  &::-ms-expand {
    display: none;
  }

  & option {
    position: relative;
    z-index: 1; // add z-index to dropdown list
  }
`

export const MultiSelectSingleShowDiv = styled.div`
  border: 1px dotted gray;
  padding: 2px;
  border-radius: 4px;
`

export const MultiSelectAllShowDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 4px;
  @media (max-width: 820px) {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`

// For Supplier Name
export const LFGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
`

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
`

export const MarginTopBottomBox = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`

export const IMGTextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  border: 1px dotted gray;
  border-radius: 4px;
  padding: 4px;
`

export const IMGBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;

  img {
    // max-height: 200px;
    // max-width: 45%;
  }
`

export const Labels = styled.label`
  height: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  letter-spacing: 0.01em;

  color: #000000;
  margin-top: 4px;
`

export const FileInputClearButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  button {
    max-width: 10px;
    border: none;
    background-color: white;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
`

export const FileInput = styled.input`
  &::-webkit-file-upload-button {
    /* Styles for the "Choose File" button */
    border-radius: 8px;
    border: none;
    padding: 4px;
    min-height: 30px;
    min-width: 90px;
    :hover {
      border: 1px solid gray;
    }
  }

  &::-webkit-file-upload-dummy {
    /* Styles for the "No File Chosen" text */
    /* ... */
  }
`
