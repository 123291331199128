import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const currentToken = cookies.get('access_token')

const initialState = {
  singleProject: {},
  loading: true,
  error: '',
  message: '',
}

export const resetMessageinEditCluster = createAction(
  'resetMessageinEditProject'
)
export const resetErrorinEditCluster = createAction('resetErrorinEditProject')

export const editSingleCluster = createAsyncThunk(
  'editSingleCluster',
  async ({ editId, clusterData }, { rejectWithValue }) => {
    console.log('formdata', clusterData)
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/clusters/update/${editId}`,
        clusterData,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
            Accept: '/',
          },
        }
      )

      return res.data
    } catch (error) {
      console.log(error)
      //@ts-ignore
      return rejectWithValue(error.response.data)
    }
  }
)

const editSingleClusterSlice = createSlice({
  name: 'editSingleCluster',
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [editSingleCluster.pending]: (state, action) => {
      state.loading = true
    },
    //@ts-ignore
    [editSingleCluster.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleProject, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.message = message
      }
    },
    //@ts-ignore
    [editSingleCluster.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.message = message
    },
    [resetErrorinEditCluster]: state => {
      state.error = ''
    },
    [resetMessageinEditCluster]: state => {
      state.message = ''
    },
  },
})

export default editSingleClusterSlice.reducer
