import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Pagelayout from '../trainingpage/pageLayout'

const Trainingpage = props => {
  const [input, showInput] = useState(true)

  const showDataHAndler = () => {
    showInput(false)
  }

  return (
    <React.Fragment>
      <Button
        onClick={showDataHAndler}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#ef5920',
          border: '1px solid white ',
          borderRadius: '10px',
          alignSelf: 'flex-start',
        }}
      >
        ADD A NEW TRAINING PROGRAM
      </Button>
      <Pagelayout
        onShow={input}
        onClose={() => {
          showInput(true)
        }}
      />
    </React.Fragment>
  )
}

export default Trainingpage
