import React, { Component } from 'react'
import { toast } from 'react-toastify'
import TimeAgo from 'react-timeago'
import Swal from 'sweetalert2'
import moment from 'moment'

const readCookie = require('../cookie.js').readCookie

export default class BPlans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allMotherBPlans: [],
    }
  }

  componentDidMount() {
    this.refreshMotherBPlans()
  }

  refreshMotherBPlans = () => {
    fetch(process.env.REACT_APP_BPLAN_API_URL + '/mbplan', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allMotherBPlans: data.bplans })
        }
      })
  }

  editBPlan = (bplanId, createdDate) => {
    const BPLAN_VERSION_REVISION_DATE = moment()
      .date(22)
      .month(5)
      .year(2022)
      .hours(20)
      .toDate()
    const version =
      moment(createdDate).toDate() > BPLAN_VERSION_REVISION_DATE ? '/v2' : ''
    window.open(
      process.env.REACT_APP_BPLAN_FRONTEND_URL +
        version +
        '/mother-bplan/' +
        bplanId +
        '?token=' +
        JSON.parse(readCookie('access_token')),
      '_blank'
    )
  }

  deleteBPlan = bplanId => {
    if (bplanId) {
      Swal.fire({
        title: 'Are you sure?',
        html: 'This Mother Business Plan will be deleted permanently',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, Delete',
        confirmButtonAriaLabel: 'Yes, Delete',
        cancelButtonText: 'No, Cancel',
        cancelButtonAriaLabel: 'No, Cancel',
      }).then(result => {
        if (result.value) {
          fetch(process.env.REACT_APP_BPLAN_API_URL + '/mbplan/' + bplanId, {
            method: 'DELETE',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          })
            .then(data => data.json())
            .then(data => {
              if (data.status) {
                this.notifySuccess(data.messsage)
                this.refreshMotherBPlans()
              } else {
                this.notifyError(data.messsage)
              }
            })
            .catch(e => {
              this.notifyError('Mother Business Plan deletion failed!')
            })
        }
      })
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  render() {
    return (
      <div
        className={
          this.props.userData ? 'page-container bplans-page' : 'bplans-page'
        }
      >
        <div className="page-heading d-flex align-items-center">
          <h3>Mother Business Plans</h3>
          <div
            className="filter add-new-bplan-container"
            style={{ right: '40px', position: 'absolute' }}
          >
            <a
              className="btn add-new-bplan-btn"
              href={
                process.env.REACT_APP_BPLAN_FRONTEND_URL +
                '/v2/mother-bplan/new?token=' +
                JSON.parse(readCookie('access_token'))
              }
              rel="noreferrer noopener"
              target="_blank"
            >
              <i className="fa fa-plus mr5"></i>
              Add Mother Business Plan
            </a>
          </div>
        </div>

        <div className="mother-bplans-container">
          {this.state.allMotherBPlans.map((bplan, index) => {
            return (
              <div className="mother-bplan-container" key={index}>
                <button
                  className="btn edit-button"
                  onClick={this.editBPlan.bind(
                    this,
                    bplan._id,
                    bplan.createdAt
                  )}
                >
                  <i className="fa fa-pencil"></i>
                  Edit
                </button>
                <button
                  className="btn delete-button"
                  onClick={this.deleteBPlan.bind(this, bplan._id)}
                >
                  <i className="fa fa-trash"></i>
                  Delete
                </button>
                <div className="heading-container">
                  <span className="heading">{bplan.name}</span>
                  <span className="type">{bplan.type}</span>
                </div>
                <div className="timing d-flex align-items-center">
                  <span>
                    <b>Created At:</b>
                    {moment(bplan.createdAt).format('L')}
                  </span>
                  <span>
                    <b>Last Updated:</b>
                    <TimeAgo date={bplan.lastUpdate} />
                  </span>
                </div>
              </div>
            )
          })}
          {!this.state.allMotherBPlans.length ? (
            <div className="no-bplans">
              <h2>
                There are no Business Plans currently.
                <br />
                <span>Please create a new one!</span>
              </h2>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
