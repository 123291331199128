import React, { useState } from 'react'
import CenterModal from '../pages/modal.js'
import { Button } from 'react-bootstrap'
//import { AnimationWrapper } from 'react-hover-animation'
import './button.css'

function ShowButton() {
  const [modalShow, setModalShow] = useState(false)

  return (
    <React.Fragment>
      <Button
        bsPrefix="design-center-button"
        onClick={() => setModalShow(true)}
        style={{
          float: 'right',
          position: 'absolute',
          top: '50px',
          left: '50rem',
        }}
      >
        ADD A CENTER
      </Button>

      <CenterModal show={modalShow} onHide={() => setModalShow(false)} />
    </React.Fragment>
  )
}
export default ShowButton
