/** @format */

import React, { useState, useRef, useEffect } from 'react'
import * as Styles from './style'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import close from '../../../assets/images/close.png'
import { InputGroup } from '../../../shared/InputGroup/InputGroup'
import { SelectGroup } from '../../../shared/SelectGroup/SelectGroup'

import Select from 'react-select'
import {
  editSingleCluster,
  resetErrorinEditCluster,
  resetMessageinEditCluster,
} from '../../../reduxLogic/reducers/clusters/editSingleClusterSlice'

import { getAllProjects } from '../../../reduxLogic/reducers/projects/getAllProjectSlice'
import { getAllClusters } from '../../../reduxLogic/reducers/clusters/getAllClustersSlice'
import { getAllUsers } from '../../../reduxLogic/reducers/auth/getAllUsersSlice'

// Update initial values to include new fields
const initialValues = {
  project: '',
  clusterName: '',
  beeMitras: [],
  status: '',
  state: '',
  district: '',
  comments: '',
}

const projectSchema = Yup.object().shape({})

const EditProjectForm = props => {
  const { error, message } = useSelector(state => state.editProject)
  const { openEditModal, setOpenEditModal, idToEdit, singleRow, data } = props
  console.log('Single Row: ', props)

  const [singleClusterData, setSingleClusterData] = useState(singleRow || {})
  const [isEditing, setIsEditing] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(singleClusterData.status)

  const [selectedBeeMitras, setselectedBeeMitras] = useState(false)
  const { allUsers } = useSelector(state => state.allUsers)

  const formikRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllProjects())
  }, [idToEdit])

  const { projectData } = useSelector(state => state.allProjects || {})

  useEffect(() => {
    if (singleClusterData) {
      formikRef.current.setValues(singleClusterData)
    }
    dispatch(getAllUsers('bee_mitra'))

  }, [singleClusterData])

  useEffect(() => {
    if(allUsers){
      //setBeemitra to selectedBeemitras from singleRow beeMitras id's
      const updatedArray = singleRow?.beeMitras?.map(item => {
        const matchingItem = allUsers?.find(obj => obj.userInfo._id === item)
        console.log(matchingItem?.userInfo?.name)
        return {
          value: item,
          label: matchingItem?.userInfo.name ? matchingItem?.userInfo.name : null,
        } // Copy the name if a match is found
      })


      setselectedBeeMitras(updatedArray)
    console.log('Beemitras: ', selectedBeeMitras)
    }
  }, [allUsers])


  const handleStateChange = value => {
    setSelectedStatus(value)
  }

  const handleClose = () => {
    // dispatch(resetSingleClusterData());
    setOpenEditModal(false)
  }

  const statusOptions = ['active', 'inactive']
  const handleSubmit = values => {
    setIsEditing(true)
    const clusterData = {
      ...values,
    }
    let editId = singleClusterData?._id

    clusterData.beeMitras = selectedBeeMitras.map(beeMitra => beeMitra.value)

    console.log('Edit ID: ', editId)

    console.log('Cluster Data: ', clusterData)

    dispatch(editSingleCluster({ editId, clusterData }))
      .then(() => dispatch(getAllClusters()))
      .then(() => setOpenEditModal(false))
      .then(() => setIsEditing(false))
      .catch(err => toast.error(`${err}`))
  }

  useEffect(() => {
    if (error) {
      toast.error(`${error}`)
      dispatch(resetErrorinEditCluster())
      dispatch(resetMessageinEditCluster())
    }
    if (!error && message) {
      toast.success('Project was updated successfully')
      dispatch(resetMessageinEditCluster())
    }
  }, [error, dispatch, message])

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {singleClusterData ? (
          <Formik
            initialValues={singleClusterData || initialValues}
            onSubmit={handleSubmit}
            validationSchema={projectSchema}
            innerRef={formikRef}
            validateOnMount
          >
            {formik => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Cluster</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                <Styles.MainSupplierFormDiv>
                  {/* <Select
                    isSearchable
                    options={projectData?.map((project) => ({
                      value: project._id,
                      label: project.name,
                    }))}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("project", selectedOption.value);
                    }}
                    selectedOption={projectData?.filter(
                      (project) => project._id === singleClusterData.projectId
                    )}
                  /> */}
                  {/* <Styles.RowFlex>
                    <SelectGroup
                      PlaceholderText="Status"
                      LabelText="Select status"
                      styles={{
                        container: provided => ({
                          width: '50px',
                        }),
                      }}
                      name="status"
                      onChange={e => {
                        handleStateChange(e.target.value)
                        formik.handleChange(e)
                      }}
                      value={selectedStatus}
                      options={statusOptions}
                      star="*"
                    />
                  </Styles.RowFlex> */}
                  <div style={{ display: 'flex', gap: '20px' }}>
                    {/* First column */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <label htmlFor="projectName">Project Name:</label>
                      <span
                        style={{
                          border: '1px solid gray',
                          borderRadius: '8px',
                          padding: '4px',
                        }}
                      >
                        {props.singleRow.projectId.name}
                      </span>
                    </div>

                    {/* Second column */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <label htmlFor="clusterName">Cluster Name:</label>
                      <span
                        style={{
                          border: '1px solid gray',
                          borderRadius: '8px',
                          padding: '4px',
                        }}
                      >
                        {props.singleRow.clusterName}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ display: 'flex', gap: '20px', marginTop: '10px' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <label htmlFor="clusterName">State:</label>
                      <span
                        style={{
                          border: '1px solid gray',
                          borderRadius: '8px',
                          padding: '4px',
                        }}
                      >
                        {props.singleRow.state}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <label htmlFor="clusterName">District:</label>
                      <span
                        style={{
                          border: '1px solid gray',
                          borderRadius: '8px',
                          padding: '4px',
                        }}
                      >
                        {props.singleRow.district}
                      </span>
                    </div>
                  </div>

                  <label htmlFor="beeMitras" style={{ marginTop: '15px' }}>
                    Assign BeeMitra
                  </label>
                  <Styles.RowFlex>
                    <Select
                      isMulti={true}
                      value={selectedBeeMitras}
                      options={allUsers.map(beeMitra => ({
                        value: beeMitra.userInfo._id,
                        label: beeMitra.userInfo.name,
                      }))}
                      onChange={event => setselectedBeeMitras(event)}
                      styles={{
                        container: provided => ({
                          width: '450px',
                        }),
                        control: base => ({
                          ...base,
                          minHeight: '30px', // Control the dropdown height
                        }),
                        menu: base => ({
                          ...base,
                          zIndex: 9999, // Ensures dropdown appears above other elements
                        }),
                        menuPortal: base => ({
                          ...base,
                          zIndex: 9999, // Portal the dropdown so it doesn't get cut off
                        }),
                        option: base => ({
                          ...base,
                          cursor: 'pointer',
                        }),
                      }}
                      menuPortalTarget={document.body} // Ensure dropdown appears outside table
                    />
                  </Styles.RowFlex>
                </Styles.MainSupplierFormDiv>

                <Styles.CreateSupplierButton type="submit">
                  {!isEditing ? 'Save' : '...Saving'}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>
            There was an error fetching data for Project with ID {idToEdit}
          </h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  )
}

export default EditProjectForm
