import React, { Component } from 'react'
import { Col, Row, Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Select } from 'antd'
const { Option } = Select
const readCookie = require('./cookie.js').readCookie

export default class AddCandidatesToProjectModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allProjects: [],
      currentProject: readCookie('project_id'),
      selectedProject: '',
      submitting: false,
    }

    this.addToProject = this.addToProject.bind(this)
  }
  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  addToProject = () => {
    if (
      this.props.leadIds &&
      this.props.leadIds.length &&
      this.state.selectedProject
    ) {
      const leadIds = this.props.leadIds
      const projectId = this.state.selectedProject

      this.setState({ submitting: true })
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/project/assign-to-other-projects?type=' +
          this.props.leadsType +
          '&action=' +
          this.props.action,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
          body: JSON.stringify({ leadIds, projectId }),
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({
              allProjects: [],
              currentProject: readCookie('project_id'),
              selectedProject: '',
            })
            this.notifySuccess(data.message)
            this.setState({ submitting: false })
            this.props.hideModal(true)
          } else {
            this.notifyError('Could not update')
          }
        })
        .catch(err => {
          this.notifyError('Could not update')
          this.setState({ submitting: false })
        })
    }
  }

  fetchProjects = () => {
    fetch(process.env.REACT_APP_API_URL + `/api/v1/project`, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status === 'ok') {
          this.setState({
            allProjects: (data.projects || []).filter(
              x => (x._id || '') !== this.state.currentProject
            ),
          })
        }
      })
  }

  componentDidMount = () => {
    this.fetchProjects()
  }

  render() {
    return (
      <Modal
        className="view-scheme-modal"
        size="md"
        show={this.props.showModal}
        onHide={() => this.props.hideModal(false)}
      >
        <Modal.Header>
          <Modal.Title className="modal-header-custom">
            Assign {this.props.leadsType || ''} (
            {(this.props.leadIds || []).length}) to another project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb20">
          <Row>
            <Col md={12}>
              <label className="control-label">Project</label>
              {this.state.allProjects.length ? (
                <Select
                  style={{ width: '300px' }}
                  defaultValue={this.state.selectedProject}
                  onChange={e => this.setState({ selectedProject: e })}
                >
                  <Option value={''}>Select a project</Option>
                  {this.state.allProjects.map(project => {
                    return (
                      <Option key={project._id} value={project._id}>
                        {project.name}
                      </Option>
                    )
                  })}
                </Select>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="footer-center">
          <Button
            className="btn btn-danger brand-color-background"
            onClick={() => this.props.hideModal(false)}
          >
            Close
          </Button>
          <Button
            className="btn btn-success brand-color-background"
            disabled={
              !this.state.selectedProject ||
              this.state.submitting ||
              !this.props.action
            }
            onClick={this.addToProject}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
