import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import {
  // Tabs, Tab,
  Col,
  Row,
  Container,
  // Button, Modal,
  Carousel,
} from 'react-bootstrap'
import {
  Switch,
  // ConfigProvider,
  // DatePicker,
  Select,
  // Input
} from 'antd'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
// import enUS from 'antd/es/locale/en_US';
// import Swal from 'sweetalert2';
import moment from 'moment'
import $ from 'jquery'
import { toast } from 'react-toastify'
// import { CSVLink } from 'react-csv';
const readCookie = require('../cookie.js').readCookie

const { SearchBar } = Search
// const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select

export default class EnterpriseCreated extends Component {
  constructor(props) {
    super(props)
    this.state = {
      milestones: [],
      allLeads: [],
      allStates: [],
      searchText: '',
      allDistricts: [],
      allBusinessIdeas: [],
      enterprisesCreated: [],
      filterData: {
        idea: '',
        financeType: '',
        milestone: '',
        phase: '',
        state: '',
        district: [],
        toDate: null,
        fromDate: null,
        hasBusinessIdea: '',
        overallSentiment: '',
        hasABusinessPlanAlready: '',
        enterpriseSetupStatus: 'Started up,Scaled up',
        dueDiligenceStages: '',
        businessPlanStages: '',
        capitalFulfilledStages: 'Not required,Disbursed',
        disqualified: false,
      },
      page: 1,
      totalLeads: 0,
      sizePerPage: 100,
      showFilter: false,
      showTableView: true,
      defaultActiveTab: '1',
      sizePerPageList: [50, 100, 150, 200],
      csvData: [],
    }
  }

  componentWillMount() {
    // if (JSON.parse(readCookie('userData')).type === 'sidbi' ||
    // 	JSON.parse(readCookie('userData')).userType.name === 'Project Management Unit')
    // {
    // 	this.exportCSV();
    // 	}
    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allStates: data.data.states })
        }
      })

    this.getAllLeads()
    // this.getAllBusinessIdeas('');

    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/swati-leads/successful-enterprise',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ enterprisesCreated: data.enterprisesCreated })
        }
      })
  }

  changeViewType = () => {
    this.setState({ showTableView: !this.state.showTableView })
  }

  getAllLeads = query => {
    if (!query)
      query = `?pageNumber=${this.state.page - 1}&limit=${this.state.sizePerPage}&enterpriseSetupStatus=${this.state.filterData.enterpriseSetupStatus}&dueDiligenceStages=${this.state.filterData.dueDiligenceStages}&businessPlanStages=${this.state.filterData.businessPlanStages}&capitalFulfilledStages=${this.state.filterData.capitalFulfilledStages}&disqualified=${this.state.filterData.disqualified}`

    // let path = process.env.REACT_APP_API_URL + '/api/v1/swati-leads/brief' + query;
    let path =
      process.env.REACT_APP_API_URL + '/api/v1/swati-leads/all-in-one' + query
    if (
      this.props.type === 'swavalamban-connect-kendra' &&
      this.props.view === 'tab'
    )
      path =
        process.env.REACT_APP_API_URL +
        '/api/v1/swati-leads/' +
        this.props.orgId
    fetch(path, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState(
            {
              allLeads: data.simpleLeadInfo,
              totalLeads: data.totalLeads,
              milestones: data.milestones || [],
              allBusinessIdeas: data.ideas,
            },
            () => {
              $(() => {
                // use UI arrows in page content to control scrolling
                this.scrolling = false
                // var that = this;

                $('#drag-table-left').mouseover(evt => {
                  this.scrolling = true
                  this.startScrolling(
                    $('table.table'),
                    15,
                    evt.target.id,
                    'right'
                  )
                })

                $('#drag-table-right').mouseover(evt => {
                  this.scrolling = true
                  this.startScrolling(
                    $('table.table'),
                    15,
                    evt.target.id,
                    'left'
                  )
                })

                $('#drag-table-left').mouseout(() => {
                  this.scrolling = false
                })
                $('#drag-table-right').mouseout(() => {
                  this.scrolling = false
                })
              })
            }
          )
        }
      })
  }

  // getAllBusinessIdeas = (query) => {
  // 	fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/lead-business-ideas' + query, {
  // 		method: 'GET',
  // 		headers: {
  // 			'x-auth-token': JSON.parse(readCookie('access_token'))
  // 		}
  // 	}).then(data => data.json())
  // 		.then(data => {
  // 			if (data.status) {
  // 				this.setState({ allBusinessIdeas: data.ideas });
  // 			}
  // 		});
  // }

  startScrolling = (obj, spd, btn, direction) => {
    var travelX = 0,
      stop = false
    if ($(obj).css('transform') !== 'none')
      travelX = parseInt($(obj).css('transform').split(',')[4].trim())

    if (direction === 'left') {
      let max = $('.react-bootstrap-table').width() - $(obj).width()
      if (travelX - spd < max) {
        travelX = max
        stop = true
        $(obj).css('transform', 'translateX(' + travelX + 'px)')
      } else travelX -= spd
    } else if (direction === 'right') {
      if (travelX + spd > 0) {
        travelX = 0
        stop = true
        $(obj).css('transform', 'translateX(' + travelX + 'px)')
      } else travelX += spd
    }

    if (!this.scrolling || stop) {
      obj.stop()
    } else {
      $(obj).css('transform', 'translateX(' + travelX + 'px)')
      // recursively call startScrolling while mouse is hovered
      setTimeout(() => {
        this.startScrolling(obj, spd, btn, direction)
      }, spd)
    }
  }

  resetFilter = () => {
    let filterData = {
      idea: '',
      phase: '',
      state: '',
      district: [],
      fromDate: null,
      toDate: null,
      hasBusinessIdea: '',
      hasABusinessPlanAlready: '',
      overallSentiment: '',
      enterpriseSetupStatus: 'Started up,Scaled up',
      dueDiligenceStages: 'Completed',
      milestone: '',
      businessPlanStages: 'Completed',
      capitalFulfilledStages: 'Not required,Disbursed',
      disqualified: false,
      financeType: '',
    }
    let query = `?pageNumber=${this.state.page - 1}&limit=${this.state.sizePerPage}&enterpriseSetupStatus=Started up,Scaled up&dueDiligenceStages=${this.state.filterData.dueDiligenceStages}&businessPlanStages=${this.state.filterData.businessPlanStages}&capitalFulfilledStages=${this.state.filterData.capitalFulfilledStages}&disqualified=${this.state.filterData.disqualified}`
    this.setState({ filterData, allDistricts: [] }, () =>
      this.getAllLeads(query)
    )
    this.showFilter()
  }

  submitFilter = (
    type = '',
    page = this.state.page,
    sizePerPage = this.state.sizePerPage,
    searchText = this.state.searchText,
    sortBy = '',
    sortOrder = ''
  ) => {
    let filterData = this.state.filterData,
      query = `?pageNumber=${page - 1}&limit=${sizePerPage}&dueDiligenceStages=${this.state.filterData.dueDiligenceStages}&businessPlanStages=${this.state.filterData.businessPlanStages}&capitalFulfilledStages=${this.state.filterData.capitalFulfilledStages}&disqualified=${this.state.filterData.disqualified}`

    if (searchText) query += `&q=${searchText}`
    if (filterData.idea !== '') query += `&idea=${filterData.idea}`
    if (filterData.phase !== '') query += `&phase=${filterData.phase}`
    if (filterData.state !== '') query += `&state=${filterData.state}`
    if (filterData.district.length && filterData.district[0])
      query += `&district=${filterData.district.join(',')}`
    if (filterData.toDate)
      query += `&toDate=${moment(filterData.toDate).format('DD-MM-YYYY')}`
    if (filterData.fromDate)
      query += `&fromDate=${moment(filterData.fromDate).format('DD-MM-YYYY')}`
    if (filterData.hasBusinessIdea !== '')
      query += `&hasABusinessIdea=${filterData.hasBusinessIdea}`
    if (filterData.overallSentiment !== '')
      query += `&overallSentiment=${filterData.overallSentiment}`
    if (filterData.enterpriseSetupStatus !== '')
      query += `&enterpriseSetupStatus=${filterData.enterpriseSetupStatus}`
    if (filterData.hasABusinessPlanAlready !== '')
      query += `&hasABusinessPlanAlready=${filterData.hasABusinessPlanAlready}`
    if (filterData.financeType !== '' && filterData.financeType !== undefined)
      query += `&financeType=${encodeURIComponent(filterData.financeType)}`
    if (filterData.milestone !== '' && filterData.milestone !== undefined)
      query += `&milestone=${encodeURIComponent(filterData.milestone)}`
    if (type === 'sort' && sortBy && sortOrder)
      query += `&sortBy=${sortBy}&sortOrder=${sortOrder}`

    if (
      type !== 'page' &&
      type !== 'sizePerPage' &&
      type !== 'searchText' &&
      type !== 'sort'
    )
      this.showFilter()

    this.getAllLeads(query)
  }

  changeStateFilter = e => {
    let filterData = this.state.filterData
    filterData['state'] = e
    if (e) {
      filterData['district'] = []
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/get-address?stateId=' +
          e,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allDistricts: data.districts })
          }
        })
    }

    let businessIdeasQuery = '?'
    if (filterData.state)
      businessIdeasQuery += 'state=' + filterData.state + '&'
    if (filterData.district.length && filterData.district[0])
      businessIdeasQuery += 'district=' + filterData.district.join(',')
    if (businessIdeasQuery === '?') businessIdeasQuery = ''
    this.getAllBusinessIdeas(businessIdeasQuery)
    filterData['idea'] = ''

    this.setState({ filterData })
  }

  changeFilterHandler = (type, e) => {
    let filterData = this.state.filterData,
      value = ''
    if (e && e.hasOwnProperty('target')) value = e.target.value
    else value = e
    if (type === 'fromToDate') {
      filterData['fromDate'] = e[0]
      filterData['toDate'] = e[1]
    } else if (type === 'disqualified') {
      if (value === 'false') filterData[type] = true
      else filterData[type] = false
    } else if (type === 'district' || type === 'maturityStatus') {
      if (value.indexOf('') > -1) filterData[type] = ['']
      else filterData[type] = value
    } else {
      filterData[type] = value
    }

    if (type === 'district') {
      let businessIdeasQuery = '?'
      if (filterData.state)
        businessIdeasQuery += 'state=' + filterData.state + '&'
      if (filterData.district.length && filterData.district[0])
        businessIdeasQuery += 'district=' + filterData.district.join(',')
      if (businessIdeasQuery === '?') businessIdeasQuery = ''
      this.getAllBusinessIdeas(businessIdeasQuery)
      filterData['idea'] = ''
    }
    this.setState({ filterData }, () => {
      if (type === 'disqualified') this.submitFilter('searchText')
    })
  }

  addressFormatter = (cell, row) => {
    if (cell) return cell
    else return null
  }

  nameFormatter = (cell, row) => {
    if (cell) {
      if (row.id) {
        return (
          <a href={'/lead/' + row.id} rel="noopener noreferrer" target="_blank">
            {cell}
          </a>
        )
      } else return cell
    } else return null
  }

  assignToFormatter = (cell, row) => {
    if (cell && cell.hasOwnProperty('_id')) {
      if (cell && cell.fullName) {
        if (
          row.userType &&
          (JSON.parse(readCookie('userData')).type === 'le' ||
            JSON.parse(readCookie('userData')).type === 'sidbi')
        ) {
          return (
            <a href={'/user-types/' + row.userType + '/' + cell._id}>
              {cell.fullName}
            </a>
          )
        } else return cell.fullName
      } else if (cell && cell.ngoName) {
        return (
          <a
            href={
              JSON.parse(readCookie('userData')).type === 'partners'
                ? '/settings'
                : '/org-types/swavalamban-connect-kendra/' + cell._id
            }
          >
            {cell.ngoName}
          </a>
        )
      } else return null
    } else return null
  }

  yesnoFormatter = (cell, row) => {
    if (cell) return 'Yes'
    else if (cell === false) return 'No'
    else return null
  }

  formatCreatedAt = (cell, row) => {
    if (cell) {
      return moment(cell).format('DD/MM/YYYY hh:mm A')
    } else return null
  }

  formatEdit = (cell, row) => {
    if (row.id && JSON.parse(readCookie('userData')).type !== 'sidbi') {
      return (
        <button
          className="btn edit-lead-btn"
          onClick={() => (window.location.pathname = '/edit-lead/' + row.id)}
        >
          Edit
        </button>
      )
    } else return null
  }

  formatRating = (cell, row) => {
    if (cell) {
      return (
        <span className="note" style={{ fontSize: 18 }}>
          {cell}
        </span>
      )
    } else return null
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === 'string') {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
    }
  }

  showFilter = () => {
    this.setState({ showFilter: !this.state.showFilter }, () => {
      if (this.state.showFilter) {
        $('.filter-container').css('display', 'block')
      } else {
        $('.filter-container').css('display', 'none')
      }
    })
  }

  onTableChange = (type, obj) => {
    if (type === 'sort' && obj.sortOrder && obj.sortField) {
      this.submitFilter(
        type,
        obj.page,
        obj.sizePerPage,
        obj.searchText,
        obj.sortField,
        obj.sortOrder
      )
    } else {
      let changeType = ''
      if (this.state.page !== obj.page) changeType = 'page'
      else if (this.state.sizePerPage !== obj.sizePerPage)
        changeType = 'sizePerPage'
      else if (this.state.searchText !== obj.searchText)
        changeType = 'searchText'

      this.setState({
        page: obj.page,
        sizePerPage: obj.sizePerPage,
        searchText: obj.searchText,
      })
      this.submitFilter(changeType, obj.page, obj.sizePerPage, obj.searchText)
    }
  }
  exportCSV = () => {
    // 	fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-leads/brief/export/letsNdorse2307?disqualified=false&enterpriseSetupStatus=Scaled%20up,Started%20up&fields=mobileNumber,name,pincode,district,city,state,gender,category,phase,businessIdea,enterpriseCreationDate,enterpriseCreationMonth,enterpriseCreationYear,financeType,loanAmountRequired,canSelfFinanceTheCapitalRequiredUpToINR,loanScheme,bank,ifsc,enterpriseName,officeShopFactoryAddress', {
    // 		method: 'GET',
    // 		headers: {
    // 			// 'x-auth-token': JSON.parse(readCookie('access_token'))
    // 		}
    // 	}).then((r)=>r.json())
    // 		.then((r) => this.setState({ csvData: r.simpleLeadInfo }))
    // 		.catch((e) => console.error(e));
    // 		// .then(data =>
    // 		// // data.json())
    // 		// // .then(data => {
    // 		// 	// if (data.status) {
    // 		// 		// this.setState({ allStates: data.data.states });
    toast.success('Export must begin in a moment!')
    // 		// 	// }
    // 		// ).catch(err => {
    // 		// 	console.error(err);
    // 		// 	toast.error("Something went wrong, please try again!");
    // 		// });
  }
  render() {
    const // allSentiments = ['Very enthusiastic', 'Enthusiastic', 'Exploring enterprise options in addition to the current engagement', 'Not very enthusiastic'],
      columns = [
        {
          text: '',
          sort: false,
          hidden: true,
          export: true,
          searchable: false,
          dataField: 'id',
        },
        {
          text: 'Name',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '200px' }
          },
          dataField: 'name',
          formatter: this.nameFormatter,
        },
        {
          text: 'Mobile No.',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '110px' }
          },
          dataField: 'mobileNumber',
        },
        {
          text: 'SIDBI Payment Milestone',
          sort: true,
          hidden: !(
            JSON.parse(readCookie('userData')).type === 'sidbi' ||
            JSON.parse(readCookie('userData')).userType.name ===
              'Project Management Unit'
          ),
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'sidbiMilestone',
        },
        {
          text: 'State',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'state',
          formatter: this.addressFormatter,
        },
        {
          text: 'District',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'district',
          formatter: this.addressFormatter,
        },
        {
          text: 'City',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'city',
          formatter: this.addressFormatter,
        },
        {
          text: 'Pincode',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '70px' }
          },
          dataField: 'pincode',
        },
        {
          text: 'Assigned To',
          sort: false,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '150px' }
          },
          dataField: 'assignTo',
          formatter: this.assignToFormatter,
        },
        {
          text: 'Added By',
          sort: false,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '150px' }
          },
          dataField: 'addedBy',
          formatter: this.assignToFormatter,
        },
        {
          text: 'Phase',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '110px' }
          },
          dataField: 'stage',
        },
        {
          text: 'Generated Through',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'generatedThrough',
        },
        {
          text: 'Self Finance Amount (INR)',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'canSelfFinanceTheCapitalRequiredUpToINR',
        },
        {
          text: 'Loan Amount received (INR)',
          sort: true,
          hidden: false,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '130px' }
          },
          dataField: 'loanAmountRequired',
        },
        {
          text: 'Status',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '150px' }
          },
          dataField: 'status',
        },
        {
          text: 'Profile Completed (%)',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '150px' }
          },
          dataField: 'profileCompletion',
        },
        {
          text: 'Has Business Idea',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '140px' }
          },
          dataField: 'hasBusinessIdea',
        },
        {
          text: 'Has Business Plan',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '140px' }
          },
          dataField: 'hasABusinessPlanAlready',
          formatter: this.yesnoFormatter,
        },
        {
          text: 'Overall Sentiment',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '170px' }
          },
          dataField: 'overallSentiment',
        },
        {
          text: 'Strength Rating',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '140px', textAlign: 'center' }
          },
          align: 'center',
          dataField: 'strengthRating',
          formatter: this.formatRating,
        },
        {
          text: 'Created On',
          sort: true,
          hidden: true,
          export: true,
          searchable: true,
          headerStyle: () => {
            return { width: '150px', textAlign: 'center' }
          },
          dataField: 'createdAt',
          formatter: this.formatCreatedAt,
        },
      ]

    if (JSON.parse(readCookie('userData')).type !== 'sidbi') {
      columns.splice(7, 0, {
        text: 'Edit',
        sort: false,
        hidden: true,
        export: false,
        searchable: false,
        align: 'center',
        headerStyle: () => {
          return { width: '75px', textAlign: 'center' }
        },
        dataField: 'edit',
        formatter: this.formatEdit,
      })
    }

    return (
      <div className="page-container leads-page enterprise-created-leads-page">
        <Col
          style={{ padding: '0px', marginBottom: '20px' }}
          className="d-flex align-items-center enterprise-created-header justify-content-between"
        >
          <div className="enterprise-created-header-left-container">
            <h3 style={{ margin: '0px' }}>Enterprises Created</h3>
          </div>

          <div className="d-flex align-items-center enterprise-created-header-right-container">
            <span>Grid View</span>
            <Switch
              className="mr5 ml5"
              checked={this.state.showTableView}
              onChange={this.changeViewType}
            />
            <span>Table View</span>
          </div>
        </Col>

        {this.state.showTableView ? (
          <div className="leads-container">
            {this.state.totalLeads ? (
              <span id="drag-table-left">{'<'}</span>
            ) : null}
            {this.state.totalLeads ? (
              <span id="drag-table-right">{'>'}</span>
            ) : null}
            <Col md={12} className="leads-table-container">
              {this.state.allLeads ? (
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    page: this.state.page,
                    sizePerPage: this.state.sizePerPage,
                    totalSize: this.state.totalLeads,
                    sizePerPageList: this.state.sizePerPageList,
                  })}
                  keyField="_id"
                  data={this.state.allLeads}
                  columns={columns}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={this.state.allLeads}
                      columns={columns}
                      search={{ searchFormatted: true }}
                    >
                      {props => (
                        <div>
                          <Row className="mt10 mb5">
                            <Col
                              className="d-flex"
                              style={{ alignItems: 'center' }}
                            >
                              <span className="total-leads m0">
                                {'Total Enterprises: ' + this.state.totalLeads}
                              </span>
                            </Col>
                            {this.props.type !== 'swavalamban-connect-kendra' &&
                            this.props.view !== 'tab' ? (
                              <Col className="leads-table-search-container">
                                <SearchBar
                                  {...props.searchProps}
                                  placeholder="Search by Name/Mobile/Pincode"
                                  className="leads-table-search"
                                  delay={1500}
                                />
                              </Col>
                            ) : null}
                            {this.props.type !== 'swavalamban-connect-kendra' &&
                            this.props.view !== 'tab' ? (
                              <div
                                className="filter-class d-flex align-items-center justify-content-center"
                                onClick={this.showFilter}
                              >
                                Filter<i className="fas fa-filter"></i>
                              </div>
                            ) : null}
                            &nbsp;
                            {this.props.type !== 'swavalamban-connect-kendra' &&
                            this.props.view !== 'tab' &&
                            (JSON.parse(readCookie('userData')).type ===
                              'sidbi' ||
                              JSON.parse(readCookie('userData')).userType
                                .name === 'Project Management Unit') ? (
                              <Col md={2}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    process.env.REACT_APP_API_URL +
                                    '/api/v1/swati-leads/brief/export/letsNdorse2307?disqualified=false&enterpriseSetupStatus=Scaled%20up,Started%20up&dueDiligenceStages=Completed&businessPlanStages=Completed&capitalFulfilledStages=Not required,Disbursed&fields=name,mobileNumber,alternatemobile,milestone,state,district,block,city,poc,gender,category,pincode,phase,businessIdea,businessType,enterpriseCreationDate,enterpriseCreationMonth,enterpriseCreationYear,financeType,canSelfFinanceTheCapitalRequiredUpToINR,loanAmountRequired,loanScheme,bank,ifsc,disbursementProof,enterpriseName,officeShopFactoryAddress,googleBusinessLocationUrl,shopImageUrls,pan,aadhar,udyogAadhar,digitalPayment&csv=true'
                                  }
                                >
                                  <div
                                    className="filter-class d-flex align-items-center justify-content-center"
                                    onClick={this.exportCSV}
                                  >
                                    Download all data as CSV
                                    {/* <CSVLink
																	data={this.state.csvData}
																	filename={'enterprise-setup'+moment().format('DD-MM-YYYY-hh:mm:ss')+'.csv'}
																	className='hidden'
																	// ref={csvLink}
																	target='_blank'
																/> */}
                                    <i className="fas fa-download"></i>
                                  </div>
                                </a>
                              </Col>
                            ) : null}
                            {this.props.type !== 'swavalamban-connect-kendra' &&
                            this.props.view !== 'tab' &&
                            (JSON.parse(readCookie('userData')).type ===
                              'sidbi' ||
                              JSON.parse(readCookie('userData')).userType
                                .name === 'Project Management Unit') ? (
                              <Col md={2}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    process.env.REACT_APP_API_URL +
                                    `/api/v1/swati-leads/brief/export/letsNdorse2307?disqualified=false&enterpriseSetupStatus=${this.state.filterData.enterpriseSetupStatus ? this.state.filterData.enterpriseSetupStatus : 'Scaled%20up,Started%20up'}&${this.state.filterData.milestone ? 'milestone=' + this.state.filterData.milestone + '&' : ''}&${this.state.filterData.phase ? 'phase=' + this.state.filterData.phase + '&' : ''}${this.state.filterData.district.join(',') ? 'district=' + this.state.filterData.district.join(',') + '&' : ''}${this.state.filterData.state ? 'state=' + this.state.filterData.state + '&' : ''}${this.state.filterData.idea ? 'idea=' + this.state.filterData.idea + '&' : ''}${this.state.filterData.financeType ? 'financeType=' + this.state.filterData.financeType + '&' : ''}dueDiligenceStages=Completed&businessPlanStages=Completed&capitalFulfilledStages=Not required,Disbursed&fields=name,mobileNumber,alternatemobile,milestone,state,district,block,city,poc,gender,category,pincode,phase,businessIdea,businessType,enterpriseCreationDate,enterpriseCreationMonth,enterpriseCreationYear,financeType,canSelfFinanceTheCapitalRequiredUpToINR,loanAmountRequired,loanScheme,bank,ifsc,disbursementProof,enterpriseName,officeShopFactoryAddress,googleBusinessLocationUrl,shopImageUrls,pan,aadhar,udyogAadhar,digitalPayment&csv=true`
                                  }
                                >
                                  <div
                                    className="filter-class d-flex align-items-center justify-content-center"
                                    onClick={this.exportCSV}
                                  >
                                    Download Filtered Dataset
                                    {/* <CSVLink
																	data={this.state.csvData}
																	filename={'enterprise-setup'+moment().format('DD-MM-YYYY-hh:mm:ss')+'.csv'}
																	className='hidden'
																	// ref={csvLink}
																	target='_blank'
																/> */}
                                    <i className="fas fa-download"></i>
                                  </div>
                                </a>
                              </Col>
                            ) : null}
                          </Row>
                          {this.props.type !== 'swavalamban-connect-kendra' &&
                          this.props.view !== 'tab' ? (
                            <div className="filter-container">
                              <div
                                className="close-filter"
                                onClick={this.showFilter}
                              >
                                X
                              </div>
                              <div className="state-filter">
                                <label className="control-label">State</label>
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  placeholder="Select State"
                                  onChange={this.changeStateFilter}
                                  value={this.state.filterData.state}
                                  filterOption={(input, option) =>
                                    this.searchFilter(input, option)
                                  }
                                >
                                  <Option value="">All</Option>
                                  <OptGroup label="Focus States">
                                    {this.state.allStates.map(
                                      (state, stateIndex) => {
                                        if (state.inFocusRegion) {
                                          if (
                                            JSON.parse(readCookie('userData'))
                                              .userType.name ===
                                              'Swavalamban Fellows' &&
                                            JSON.parse(
                                              readCookie('userData')
                                            ).address.state.includes(state.name)
                                          ) {
                                            return (
                                              <Option
                                                key={stateIndex}
                                                value={state.name}
                                              >
                                                {state.name}
                                              </Option>
                                            )
                                          } else if (
                                            JSON.parse(readCookie('userData'))
                                              .userType.name !==
                                            'Swavalamban Fellows'
                                          ) {
                                            return (
                                              <Option
                                                key={stateIndex}
                                                value={state.name}
                                              >
                                                {state.name}
                                              </Option>
                                            )
                                          } else return null
                                        } else return null
                                      }
                                    )}
                                  </OptGroup>
                                  <OptGroup label="Not Focussed States">
                                    {this.state.allStates.map(
                                      (state, stateIndex) => {
                                        if (!state.inFocusRegion) {
                                          if (
                                            JSON.parse(readCookie('userData'))
                                              .userType.name ===
                                              'Swavalamban Fellows' &&
                                            JSON.parse(
                                              readCookie('userData')
                                            ).address.state.includes(state.name)
                                          ) {
                                            return (
                                              <Option
                                                key={stateIndex}
                                                value={state.name}
                                              >
                                                {state.name}
                                              </Option>
                                            )
                                          } else if (
                                            JSON.parse(readCookie('userData'))
                                              .userType.name !==
                                            'Swavalamban Fellows'
                                          ) {
                                            return (
                                              <Option
                                                key={stateIndex}
                                                value={state.name}
                                              >
                                                {state.name}
                                              </Option>
                                            )
                                          } else return null
                                        } else return null
                                      }
                                    )}
                                  </OptGroup>
                                </Select>
                              </div>
                              <div className="district-filter">
                                <label className="control-label">
                                  District
                                </label>
                                <Select
                                  mode="multiple"
                                  showSearch
                                  style={{ width: '100%' }}
                                  placeholder="Select District"
                                  onChange={this.changeFilterHandler.bind(
                                    this,
                                    'district'
                                  )}
                                  value={this.state.filterData.district}
                                  filterOption={(input, option) =>
                                    this.searchFilter(input, option)
                                  }
                                >
                                  <Option value="">All</Option>
                                  {this.state.allDistricts.map(
                                    (district, districtIndex) => {
                                      if (
                                        JSON.parse(readCookie('userData'))
                                          .userType.name ===
                                          'Swavalamban Fellows' &&
                                        JSON.parse(
                                          readCookie('userData')
                                        ).address.district.includes(
                                          district.name
                                        )
                                      ) {
                                        return (
                                          <Option
                                            key={districtIndex}
                                            value={district.name}
                                          >
                                            {district.name}
                                          </Option>
                                        )
                                      } else if (
                                        JSON.parse(readCookie('userData'))
                                          .userType.name !==
                                        'Swavalamban Fellows'
                                      ) {
                                        return (
                                          <Option
                                            key={districtIndex}
                                            value={district.name}
                                          >
                                            {district.name}
                                          </Option>
                                        )
                                      } else return null
                                    }
                                  )}
                                </Select>
                              </div>
                              <div className="district-filter">
                                <label className="control-label">
                                  Business Ideas
                                </label>
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  placeholder="Select Idea"
                                  onChange={this.changeFilterHandler.bind(
                                    this,
                                    'idea'
                                  )}
                                  value={this.state.filterData.idea}
                                  filterOption={(input, option) =>
                                    this.searchFilter(input, option)
                                  }
                                >
                                  <Option value="">All</Option>
                                  {this.state.allBusinessIdeas.map(
                                    (idea, ideaIndex) => {
                                      return (
                                        <Option
                                          key={ideaIndex}
                                          title={
                                            idea.combine +
                                            ' (' +
                                            idea.count +
                                            ')'
                                          }
                                          value={idea.combine}
                                        >
                                          {idea.name + ' (' + idea.count + ')'}
                                        </Option>
                                      )
                                    }
                                  )}
                                </Select>
                              </div>
                              <div className="district-filter">
                                <label className="control-label">
                                  Finance Type
                                </label>
                                <Select
                                  showSearch
                                  style={{ width: '100%' }}
                                  placeholder="Select Finance Type"
                                  onChange={this.changeFilterHandler.bind(
                                    this,
                                    'financeType'
                                  )}
                                  value={this.state.filterData.financeType}
                                  filterOption={(input, option) =>
                                    this.searchFilter(input, option)
                                  }
                                >
                                  <Option value="">All</Option>
                                  <Option value="Bank Loan required">
                                    Bank Loan required
                                  </Option>
                                  <Option value="100% self-financed">
                                    100% self-financed
                                  </Option>
                                </Select>
                              </div>
                              {JSON.parse(readCookie('userData')).type ===
                                'sidbi' ||
                              JSON.parse(readCookie('userData')).userType
                                .name === 'Project Management Unit' ? (
                                <div className="district-filter">
                                  <label className="control-label">
                                    SIDBI Payment Milestone
                                  </label>
                                  <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select Milestone"
                                    onChange={this.changeFilterHandler.bind(
                                      this,
                                      'milestone'
                                    )}
                                    value={this.state.filterData.milestone}
                                    filterOption={(input, option) =>
                                      this.searchFilter(input, option)
                                    }
                                  >
                                    <Option value="">All</Option>
                                    <Option value="No milestone assigned">
                                      No milestone assigned
                                    </Option>
                                    {this.state.milestones.map(milestone => (
                                      <Option key={milestone} value={milestone}>
                                        {milestone}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              ) : null}
                              <div className="phase-filter">
                                <label className="control-label">Phase</label>
                                <Select
                                  style={{ width: '100%' }}
                                  placeholder="Phase"
                                  onChange={this.changeFilterHandler.bind(
                                    this,
                                    'phase'
                                  )}
                                  value={this.state.filterData.phase}
                                >
                                  <Option value="">All</Option>
                                  <Option value="Start-up">Start-up</Option>
                                  <Option value="Scale-up">Scale-up</Option>
                                </Select>
                              </div>
                              {/* <div className="phase-filter">
																<label className="control-label">Business Idea</label>
																<Select style={{ width: '100%' }} placeholder="Business Idea" onChange={this.changeFilterHandler.bind(this, 'hasBusinessIdea')} value={this.state.filterData.hasBusinessIdea}>
																	<Option value="">All</Option>
																	<Option value="One">One</Option>
																	<Option value="Many">Many</Option>
																	<Option value="None">None</Option>
																</Select>
															</div>
															<div className="phase-filter">
																<label className="control-label">Business Plan</label>
																<Select style={{ width: '100%' }} placeholder="Business Plan" onChange={this.changeFilterHandler.bind(this, 'hasABusinessPlanAlready')} value={this.state.filterData.hasABusinessPlanAlready}>
																	<Option value="">All</Option>
																	<Option value="true">Yes</Option>
																	<Option value="false">No</Option>
																</Select>
															</div>
															<div className="phase-filter">
																<label className="control-label">Overall sentiment</label>
																<Select style={{ width: '100%' }} placeholder="Overall sentiment" onChange={this.changeFilterHandler.bind(this, 'overallSentiment')} value={this.state.filterData.overallSentiment}>
																	<Option value="">All</Option>
																	{allSentiments.map((sentiment, sentimentIndex) => {
																		return (
																			<Option key={sentimentIndex} value={sentiment}>{sentiment}</Option>
																		)
																	})}
																</Select>
															</div>

															<div className="filter form-group">
																<label className="control-label">{'From - To date: '}</label>
																<ConfigProvider locale={enUS}>
																	<RangePicker locale={enUS} style={{ width: "100%" }} format="DD/MM/YYYY" value={[this.state.filterData.fromDate, this.state.filterData.toDate]} type="date" onChange={this.changeFilterHandler.bind(this, 'fromToDate')} />
																</ConfigProvider>
															</div> */}
                              <div className="d-flex filter-btn-container mt10">
                                <div
                                  className="reset-btn mr10"
                                  onClick={this.resetFilter}
                                >
                                  Reset
                                </div>
                                <div
                                  className="submit-btn"
                                  onClick={this.submitFilter}
                                >
                                  Submit
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <BootstrapTable
                            {...props.baseProps}
                            id="leads-page-table"
                            remote={{ pagination: true }}
                            onTableChange={this.onTableChange}
                            {...paginationTableProps}
                          />
                          <PaginationListStandalone {...paginationProps} />
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              ) : null}
            </Col>
          </div>
        ) : (
          <Col md={12} className="enterprise-grid-view-container">
            <div className="enterprises-container">
              {this.state.enterprisesCreated.map((enterprise, index) => {
                return (
                  <div className="enterprise-container" key={index}>
                    <div className="enterprise-img-container">
                      <Carousel
                        controls={false}
                        interval={3000}
                        indicators={false}
                      >
                        {enterprise.enterpriseSetup.otherImageUrls.map(
                          (image, imageIndex) => {
                            return (
                              <Carousel.Item key={imageIndex}>
                                <img alt="" className="d-block" src={image} />
                              </Carousel.Item>
                            )
                          }
                        )}
                        {enterprise.enterpriseSetup.otherImageUrls
                          .length ? null : (
                          <Carousel.Item>
                            <img
                              alt=""
                              className="d-block"
                              src="/images/project-investment-placeholder-new.png"
                            />
                          </Carousel.Item>
                        )}
                      </Carousel>
                    </div>
                    <Container fluid>
                      <div className="enterprise-detail-container">
                        {enterprise.enterpriseSetup.entityName ? (
                          <div className="detail-container">
                            <span className="title">Enterprise Name: </span>
                            <span className="value">
                              {enterprise.enterpriseSetup.entityName}
                            </span>
                          </div>
                        ) : null}
                        <div className="detail-container">
                          <span className="title">Entrepreneur Name: </span>
                          <span className="value">
                            {enterprise.id ? (
                              <a
                                className="value"
                                href={`/lead/${enterprise.id}`}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {enterprise.name}
                              </a>
                            ) : (
                              <span>{enterprise.name}</span>
                            )}
                          </span>
                        </div>
                        {enterprise.enterpriseSetup.enterpriseState ? (
                          <div className="detail-container">
                            <span className="title">Enterprise State: </span>
                            <span className="value">
                              {enterprise.enterpriseSetup.enterpriseState}
                            </span>
                          </div>
                        ) : null}
                        {/* <div className="detail-container">
												<span className="title">Entrepreneur Contact: </span>
												<span className="value">{enterprise.mobileNumber}</span>
											</div> */}
                        <div className="detail-container">
                          <span className="title">City: </span>
                          <span className="value">{enterprise.city}</span>
                        </div>
                        <div className="detail-container">
                          <span className="title">District: </span>
                          <span className="value">{enterprise.district}</span>
                        </div>
                        <div className="detail-container">
                          <span className="title">State: </span>
                          <span className="value">{enterprise.state}</span>
                        </div>
                        {/* {enterprise.enterpriseSetup.officeShopFactoryAddress ? (
												<div className="detail-container">
													<span className="title">Address: </span>
													<span className="value">
														{enterprise.enterpriseSetup.officeShopFactoryAddressUrl ? (
															<a className="value" href={enterprise.enterpriseSetup.officeShopFactoryAddressUrl.url} rel="noopener noreferrer" target="_blank">{enterprise.enterpriseSetup.officeShopFactoryAddress}</a>
														) : (
															<span>{enterprise.enterpriseSetup.officeShopFactoryAddress}</span>
														)}
													</span>
												</div>
											) : (null)} */}
                        <div className="detail-container">
                          <span className="title">Pincode: </span>
                          <span className="value">{enterprise.pincode}</span>
                        </div>
                        {/* {enterprise.enterpriseSetup.enterpriseRegistered === 'Yes' ? (
												<div className="detail-container">
													<span className="title">Enterprise Type: </span>
													<span className="value">{enterprise.enterpriseSetup.entityType}</span>
												</div>
											) : (null)} */}
                        {/* {enterprise.enterpriseSetup.udyogAadharCertificateReceived ? (
												<div className="detail-container">
													<span className="title">Udyog Aadhar Certificate: </span>
													{enterprise.enterpriseSetup.udyogAadharCertificateReceived === 'Yes' && enterprise.enterpriseSetup.udyogAadharCertificateUrl ? (
														<a className="image-url value" href={enterprise.enterpriseSetup.udyogAadharCertificateUrl} rel="noopener noreferrer" target="_blank">View Certificate</a>
													) : (
														<span className="value">{enterprise.enterpriseSetup.udyogAadharCertificateReceived}</span>
													)}
												</div>
											) : (null)} */}
                        {/* {enterprise.enterpriseSetup.gstCertificate ? (
												<div className="detail-container">
													<span className="title">GST Certificate: </span>
													{enterprise.enterpriseSetup.gstCertificate === 'Yes' && enterprise.enterpriseSetup.gstCertificateUrl ? (
														<a className="image-url value" href={enterprise.enterpriseSetup.gstCertificateUrl} rel="noopener noreferrer" target="_blank">View Certificate</a>
													) : (
														<span className="value">{enterprise.enterpriseSetup.gstCertificate}</span>
													)}
												</div>
											) : (null)} */}
                        {/* {enterprise.enterpriseSetup.otherLicenses ? (
												<div className="detail-container">
													<span className="title">Other relevant licenses: </span>
													{enterprise.enterpriseSetup.otherLicenses === 'Yes' && enterprise.enterpriseSetup.otherLicensesUrl ? (
														<a className="image-url value" href={enterprise.enterpriseSetup.otherLicensesUrl} rel="noopener noreferrer" target="_blank">View Certificate</a>
													) : (
														<span className="value">{enterprise.enterpriseSetup.otherLicenses}</span>
													)}
												</div>
											) : (null)} */}
                        {/* {enterprise.enterpriseSetup.certificateOfShopAndEstablishmentAct ? (
												<div className="detail-container">
													<span className="title">Certificate of shop and establishment act: </span>
													{enterprise.enterpriseSetup.certificateOfShopAndEstablishmentAct === 'Yes' && enterprise.enterpriseSetup.certificateOfShopAndEstablishmentActUrl ? (
														<a className="image-url value" href={enterprise.enterpriseSetup.certificateOfShopAndEstablishmentActUrl} rel="noopener noreferrer" target="_blank">View Certificate</a>
													) : (
														<span className="value">{enterprise.enterpriseSetup.certificateOfShopAndEstablishmentAct}</span>
													)}
												</div>
											) : (null)} */}
                        {/* {enterprise.enterpriseSetup.supportReceived.length ? (
												<div className="detail-container">
													<span className="title">Support received from Mission Swavalamban: </span>
													<span className="value">{enterprise.enterpriseSetup.supportReceived.join(', ')}</span>
												</div>
											) : (null)} */}
                      </div>
                      <div className="enterprise-detail-container">
                        {enterprise.enterpriseSetup.entrepreneurStory ? (
                          <div className="detail-container">
                            <span className="title">Entrepreneur Story: </span>
                            <span className="value">
                              {enterprise.enterpriseSetup.entrepreneurStory}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </Container>
                  </div>
                )
              })}
            </div>
          </Col>
        )}
      </div>
    )
  }
}
