import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import styled from 'styled-components'
import SingleLeadMaturityStages from '../../2-leads/SingleLeadMaturityStages'

import { getClusterByProject } from '../reduxLogic/reducers/clusters/getClusterByProjectSlice'
import { useDispatch, useSelector } from 'react-redux'

import { Tabs, ConfigProvider } from 'antd'

const cookies = new Cookies()
const token = cookies.get('access_token') // Get token from storage
const project_id = cookies.get('project_id')

const Container = styled.div`
  margin-left: 20%;
  margin-top: 4%;
`

const TopTabs = styled.div`
  display: flex;
  margin-bottom: 10px;
`

const TopTab = styled.button`
  padding: 10px 20px;
  background-color: lightgray;
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &.active {
    background-color: #ff8701;
    color: white;
  }
`

const TabButton = styled.button`
  padding: 10px 15px;
  margin-right: 5px;
  margin-left: 2%;
  margin-bottom: 2%;
  background-color: lightgray;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &.active {
    background-color: #ff8701;
    color: white;
  }
`

const TabContent = styled.div`
  margin-left: 2%;
`

const SurveyTabs = () => {
  const [surveyData, setSurveyData] = useState([])
  const [userData, setUserData] = useState({})
  const [traineeData, setTraineeData] = useState({})

  const [apiaryData, setApiaryData] = useState([])
  const [hideApiaryTab, setHideApiaryTab] = useState(false)

  const dispatch = useDispatch()
  const { _id } = useParams()
  const urlParams = new URLSearchParams(window.location.search) // Get the query parameters
  const clusterName = urlParams.get('clusterName')

  useEffect(() => {
    dispatch(getClusterByProject(project_id))
    getUserSurvey(_id)
    getUser(_id)
  }, [dispatch, _id])

  const { clusterViaProjectData } = useSelector(
    state => state.clusterViaProjectData
  )

  const getUserSurvey = async id => {
    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_FIELD_URL}/surveys/user/${id}`,
        config
      )
      const stages = res.data?.survey?.stages || []
      const sortedStages = stages.sort((a, b) =>
        a.stage_name.localeCompare(b.stage_name)
      )
      setApiaryData(res.data.apiary)
      if (res.data.apiary.length === 0) {
        setHideApiaryTab(true)
      }
      console.log('apiaryData', res.data.apiary)
      setSurveyData(sortedStages)
      if (sortedStages.length > 0) {
        setSurveyActiveTab(sortedStages[0].stage_name)
      }
    } catch (error) {
      console.log('Error in getting survey data:', error)
    }
  }

  const getUser = async id => {
    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
          source: 'humblebee_admin',
        },
      }
      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_USER_URL}/user/user-id-details/${id}`,
        config
      )
      setTraineeData(res.data.trainee)
      setUserData(res.data.user)
    } catch (error) {
      console.log('Error in getting user data:', error)
    }
  }

  const formatLabel = label =>
    label.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())

  const renderTableData = data => (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
              color: 'orange',
              fontWeight: 'bold',
            }}
          >
            Field
          </th>
          <th
            style={{
              padding: '10px',
              borderBottom: '1px solid #ddd',
              color: 'orange',
              fontWeight: 'bold',
            }}
          >
            Value
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(data).map(([key, value]) => (
          <tr key={key}>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>{formatLabel(key)}</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              {key === 'photos' || key === 'otherphotos' ? (
                // If the key is 'photos', display the image(s)
                Array.isArray(value) ? (
                  value.map((photoUrl, index) => (
                    <img
                      key={index}
                      src={photoUrl}
                      alt={`photo-${index}`}
                      style={{
                        width: '100px',
                        height: 'auto',
                        margin: '5px',
                        objectFit: 'fill',
                      }}
                    />
                  ))
                ) : (
                  <img
                    src={value}
                    alt="photo"
                    style={{ width: '100px', height: 'auto', margin: '5px' }}
                  />
                )
              ) : Array.isArray(value) ? (
                value.join(', ')
              ) : typeof value === 'object' && value !== null ? (
                renderTableData(value)
              ) : (
                value.toString()
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderStages = () => (
    <div>
      <SingleLeadMaturityStages
        capitalInfo={traineeData?.capital_info}
        leadId={_id}
        // projectId={project.projectId._id}
        selfInvestment={0}
        isEditable={true}
        loanAmountRequired={true}
        totalInvestment={0}
        // businessIdea={this.state.businessIdea}
        businessIdea={''}
        leadData={userData}
        refreshLeadData={false}
        changeOnHoldStatus={true}
        // changeDisqualificationStatus={
        //   this.changeDisqualificationStatus
        // }
      />
    </div>
  )

  const renderApiaryData = () => (
    <div>
      {!apiaryData || apiaryData.length === 0 ? (
        <h2>No apiary data available</h2>
      ) : (
        <div>
          {apiaryData.map((apiary, index) => (
            <div
              key={apiary._id}
              style={{
                marginBottom: '20px',
                border: '1px solid #ddd',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <h3>{`${index + 1}. ${apiary.name}`}</h3>
              <img
                src={
                  apiary.apiaryImage ||
                  'https://static.vecteezy.com/system/resources/previews/026/148/953/non_2x/apiary-fill-outline-icon-design-illustration-nature-and-ecology-symbol-on-white-background-eps-10-file-vector.jpg'
                }
                alt={`${apiary.name} Image`}
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
              />
              <table
                style={{
                  width: '100%',
                  marginTop: '10px',
                  borderCollapse: 'collapse',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Location</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.location?.geoCoordinates.latitude &&
                      apiary.location?.geoCoordinates.longitude ? (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${apiary.location.geoCoordinates.latitude},${apiary.location.geoCoordinates.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Map Location
                        </a>
                      ) : (
                        'Location not available'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Environment Type</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.environmentType}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Bee Type</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.beeType}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Brood Chamber Count</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.broodChamberCount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Super Chamber Count</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.broodSuperChamberCount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Frame Count Per Chamber</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.frameCountPerChamber}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Total Chamber Count</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {apiary.totalChamberCount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      <strong>Setup Date</strong>
                    </td>
                    <td
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ddd',
                      }}
                    >
                      {new Date(apiary.setupDate).toLocaleDateString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}
    </div>
  )

  const renderUserProfile = () => (
    <div>
      <h2>Profile</h2>

      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <tbody>
          <tr>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>Profile Picture</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <img
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'fill',
                  border: '1px solid orange',
                  borderRadius: '50%',
                  backgroundColor: 'lightgray',
                }}
                src={
                  userData?.profilePictureUrl ||
                  'https://www.w3schools.com/howto/img_avatar.png'
                }
                alt="Profile Pic"
              />
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>Name</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              {userData?.name}
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>Mobile Number</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              {userData?.mobileNumber}
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>Gender</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              {userData?.gender}
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>Address</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              {userData?.fulladdress}
            </td>
          </tr>
          {userData?.address?.location?.latitude ? (
            <tr>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <strong>Location</strong>
              </td>
              <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${userData?.address?.location?.latitude},${userData?.address?.location?.longitude}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Map
                </a>
              </td>
            </tr>
          ) : null}
          <tr>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              <strong>Roles</strong>
            </td>
            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
              {userData?.userRoles?.map(role => (
                <span
                  key={role}
                  style={{
                    border: '1px solid gray',
                    padding: '4px',
                    borderRadius: '8px',
                    backgroundColor: 'orange',
                    margin: '2px',
                  }}
                >
                  {role}
                </span>
              ))}
            </td>
          </tr>
          <tr>
            {clusterName && (
              <>
                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                  <strong>Existing Cluster</strong>
                </td>
                <td
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #ddd',
                    color: 'green',
                  }}
                >
                  {clusterName ? (
                    clusterName
                  ) : (
                    <span style={{ color: 'red' }}>No cluster assigned</span>
                  )}
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>

      <div style={{ marginTop: '20px' }}>
        <label style={{ marginRight: '10px' }}>Select Cluster: </label>
        <select
          name="cluster"
          value={userData?.cluster}
          onChange={e => setUserData({ ...userData, cluster: e.target.value })}
        >
          <option value="">Select Cluster</option>
          {clusterViaProjectData?.map(cluster => (
            <option key={cluster.clusterId} value={cluster._id}>
              {cluster.projectId.name + ' - ' + cluster.clusterName}
            </option>
          ))}
        </select>
      </div>

      <div style={{ marginTop: '20px' }}>
        <button
          style={{
            backgroundColor: '#ff8701',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
          onClick={() => {
            const config = {
              headers: {
                Authorization: 'Bearer ' + token,
                source: 'humblebee_admin',
              },
            }
            axios
              .post(
                `${process.env.REACT_APP_API_APP_FIELD_URL}/clusters/clusters/add-lead`,
                {
                  user_id: _id,
                  cluster_id: userData.cluster,
                  project_id: project_id,
                },
                config
              )
              .then(res => {
                console.log(res)
                alert('User updated successfully')
              })
              .catch(err => {
                console.log(err)
                alert('Error updating user')
              })
          }}
        >
          Update User
        </button>
      </div>
    </div>
  )

  const [surveyActiveTab, setSurveyActiveTab] = useState(
    surveyData[0]?.stage_name
  )

  const handleTabChange = key => {
    setSurveyActiveTab(key)
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            cardBg: 'light',
            itemColor: 'orange',
            itemActiveColor: '#ff8701',
          },
        },
      }}
    >
      <Container>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
          <label style={{ fontSize: '20px', color: '#ff8701' }}>
            {userData?.name}
          </label>
          <span style={{ fontSize: '20px', marginLeft: '1%' }}>
            {' '}
            - {userData?.mobileNumber}
          </span>
          <a
            href={`https://api.whatsapp.com/send?phone=91${userData?.mobileNumber}`}
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: '1%' }}
          >
            <img
              src="https://img.icons8.com/color/48/000000/whatsapp.png"
              alt="whatsapp"
              style={{ width: '30px', height: '30px' }}
            />
          </a>
        </div>

        <Tabs
          tabBarStyle={{ color: '#ff8701' }}
          defaultActiveKey="1"
          type="card"
        >
          <Tabs.TabPane tab="Profile" key="1">
            {renderUserProfile()}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Survey" key="2">
            <Tabs
              tabBarStyle={{ color: '#ff8701' }} // Orange background for tabs
              activeKey={surveyActiveTab}
              onChange={handleTabChange}
              type="line"
            >
              {surveyData.map(stage => (
                <Tabs.TabPane
                  tab={stage.stage_name}
                  key={stage.stage_name}
                ></Tabs.TabPane>
              ))}
            </Tabs>
            <div>
              <TabContent>
                {!surveyData.length && <h2>No survey data available</h2>}
                {surveyData.map(stage => {
                  if (stage.stage_name === surveyActiveTab) {
                    return (
                      <div key={stage._id}>
                        {/* <h2 style={{ fontWeight: "bold" }}>{stage.stage_name}</h2> */}
                        {renderTableData(stage.form_data)}
                      </div>
                    )
                  }
                  return null
                })}
              </TabContent>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Capital" key="3">
            {renderStages()}
          </Tabs.TabPane>
          {!hideApiaryTab && (
            <Tabs.TabPane tab="Apiary" key="4">
              {renderApiaryData()}
            </Tabs.TabPane>
          )}
        </Tabs>
      </Container>
    </ConfigProvider>
  )
}

export default SurveyTabs
