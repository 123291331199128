/** @format */

import React, { useEffect, useMemo, useState } from 'react'
import * as Styles from './style'
import { Link } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { changeDateTimeFormat } from '../shared/Helper'
import { usePagination, useTable, useSortBy } from 'react-table'
import editIcon from '../assets/images/edit.png'
import { useDispatch, useSelector } from 'react-redux'
import deleteIcon from '../assets/images/delete.png'
import ProjectForm from './components/ProjectForm/ProjectForm'
import { getAllProjects } from '../reduxLogic/reducers/projects/getAllProjectSlice'
import EditProjectForm from './components/EditProjectForm/EditProjectForm'
import DeleteModal from './components/DeleteModal/DeleteModal'

import Cookies from 'universal-cookie'
import axios from 'axios'
const cookies = new Cookies()

const Projects = () => {
  const formatDate = date => {
    if (!date) return '-'
    const d = new Date(date)
    const day = String(d.getDate()).padStart(2, '0')
    const month = String(d.getMonth() + 1).padStart(2, '0') // getMonth() is zero-based
    const year = d.getFullYear()
    return `${day}-${month}-${year}`
  }
  const Project_TABLE_COLUMNS = [
    { Header: 'Project Id', accessor: 'projectId' },
    { Header: 'Project', accessor: 'name' },
    {
      Header: 'Clusters',
      accessor: 'clusterIds',
      Cell: ({ value }) => (value.length > 0 ? value.length : 'Create'),
    },
    {
      Header: 'Field Visits',
      // Cell: ({ value }) => value.length > 0 ? value.length : "-"
    },
    {
      Header: 'Group Meetings',
      // Cell: ({ value }) => value.length > 0 ? value.length : "-"
    },
    { Header: 'Status', accessor: 'status' },
    {
      Header: 'State',
      accessor: 'targetedStates',
      Cell: ({ value }) =>
        value?.length > 0 ? value.map(state => state).join(', ') : '-',
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
      Cell: ({ value }) => formatDate(value), // Format the start date here
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
      Cell: ({ value }) => formatDate(value), // Format the end date here
    },
    {
      Header: 'Targeted Districts',
      accessor: 'targetedDistricts',
      Cell: ({ value }) => (value.length > 0 ? value.join(', ') : '-'),
    },
    { Header: 'Edit' },
  ]
  const [openModal, setOpenModal] = useState(false)
  const [singleRow, setSingleRow] = useState({})
  const [idToEdit, setIdToEdit] = useState('')
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState()
  const [openEditModal, setOpenEditModal] = useState(false)
  const currentToken = cookies.get('access_token')
  const decoded = jwtDecode(currentToken)
  const [fieldVisits, setfieldVisits] = useState(false)
  const [groupMeetings, setgroupMeetings] = useState(false)
  const role = decoded.role

  const handleModalToggle = () => {
    setOpenModal(prev => !prev)
  }

  const getGroupMeeting = async id => {
    try {
      const token = await cookies.get('access_token') // Get token from storage
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
      console.log(process.env.API_APP_FIELD_URL, config)

      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_FIELD_URL}/group-meetings/web-app/project/${id}`,
        config
      )
      // console.log(res);
      return res
    } catch (error) {
      console.log('Error in getting group meeting data:', error)
      throw error
    }
  }
  const getFieldVisits = async id => {
    try {
      const token = await cookies.get('access_token') // Get token from storage
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
      console.log(process.env.API_APP_FIELD_URL, config)

      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_FIELD_URL}/field-visits/web-app/project/${id}`,
        config
      )
      // console.log(res);
      return res
    } catch (error) {
      console.log('Error in getting group meeting data:', error)
      throw error
    }
  }
  const handleOpenEditModal = (id, singleRow) => {
    //console.log("this is id in handleOpenEdit => ", id);
    //dispatch(getSingleSupplier(id));
    setSingleRow(singleRow)
    setIdToEdit(id)
    // setOpenModal(true);
    setOpenEditModal(true)
  }
  const { projectData } = useSelector(state => state.allProjects)

  const dispatch = useDispatch()

  const fetchAllProjectsData = () => {
    dispatch(getAllProjects())
  }
  useEffect(() => {
    fetchAllProjectsData()
  }, [dispatch])

  const columns = useMemo(() => {
    // Add a new column for S.No
    const newColumns = [...Project_TABLE_COLUMNS]
    // newColumns[0] = {
    //   Header: "S.No",
    //   accessor: (_, index) => `P${index + 1}`, // Dynamic serial numbers (P1, P2, ...)
    // };
    return newColumns
  }, [])
  //const columns = useMemo(() => Project_TABLE_COLUMNS, []);
  const data = useMemo(() => projectData, [projectData])
  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    if (data.length > 0) {
      const projectIdArray = data.map(project => project._id)
      const groupMeetingPromises = projectIdArray.map(id => getGroupMeeting(id))
      Promise.all(groupMeetingPromises)
        .then(responses => {
          const groupMeetingCounts = responses.map(
            response => response.data.length
          )
          // console.log(groupMeetingCounts);
          setgroupMeetings(groupMeetingCounts)
        })
        .catch(error =>
          console.error('Error in getting group meeting data:', error)
        )
      const fieldVisit = projectIdArray.map(id => getFieldVisits(id))
      Promise.all(fieldVisit)
        .then(responses => {
          const fieldVisitsCounts = responses.map(
            response => response.data.length
          )
          // console.log(fieldVisitsCounts);
          setfieldVisits(fieldVisitsCounts)
        })
        .catch(error =>
          console.error('Error in getting field visits data:', error)
        )
    }
  }, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance
  const { pageIndex } = state
  const handleOpenDeleteModal = id => {
    setDeleteModal(true)
    setIdToDelete(id)
  }

  return (
    <Styles.SupplierBox>
      <Styles.SupplierTopSection>
        <Styles.ModalButton onClick={handleModalToggle}>
          Add Project
        </Styles.ModalButton>
        {openModal ? (
          <ProjectForm openModal={openModal} setOpenModal={setOpenModal} />
        ) : null}
      </Styles.SupplierTopSection>

      <Styles.TableContainer>
        {projectData ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map(headerGroup => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(headerColumn => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render('Header')}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? ' 🔼'
                            : ' 🔽'
                          : ''}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((singleRow, idx) => {
                prepareRow(singleRow)

                return (
                  <Styles.TBodyRow {...singleRow.getRowProps()}>
                    {singleRow.cells.map((singleCell, index) => {
                      // console.log(singleCell.column,idx)
                      return (
                        <Styles.TD key={index}>
                          {singleCell.column.Header == 'Clusters' ? (
                            <Link
                              to={`/clusters/${singleCell?.row?.original?._id}`}
                              onClick={() => {
                                cookies.set(
                                  'project_id',
                                  singleCell?.row?.original?._id
                                )
                              }}
                            >
                              {singleCell.render('Cell')}
                            </Link>
                          ) : (
                            <span>
                              {singleCell.column.id == 'addedOn' ||
                              singleCell.column.id == 'updatedOn' ? (
                                <span>
                                  {changeDateTimeFormat(singleCell.value)}
                                </span>
                              ) : (
                                <span>
                                  {singleCell.column.Header === 'Edit' ? (
                                    <Styles.ActionSpan>
                                      <button
                                        onClick={() =>
                                          handleOpenEditModal(
                                            singleCell?.row?.original?._id,
                                            singleRow?.original
                                          )
                                        }
                                      >
                                        <img src={editIcon} alt="Edit" />{' '}
                                      </button>

                                      {role == 'Admin' ? (
                                        <button
                                          onClick={() =>
                                            handleOpenDeleteModal(
                                              singleCell?.row?.original?._id
                                            )
                                          }
                                        >
                                          <img src={deleteIcon} alt="Delete" />
                                        </button>
                                      ) : null}
                                    </Styles.ActionSpan>
                                  ) : singleCell.column.Header ===
                                    'Field Visits' ? (
                                    <Link
                                      to={`/fieldVisits/${singleCell?.row?.original?._id}`}
                                    >
                                      {fieldVisits && fieldVisits.length > 0
                                        ? fieldVisits[idx]
                                        : 0}
                                    </Link>
                                  ) : singleCell.column.Header ===
                                    'Group Meetings' ? (
                                    <Link
                                      to={`/groupMeetings/${singleCell?.row?.original?._id}`}
                                    >
                                      {groupMeetings && groupMeetings.length > 0
                                        ? groupMeetings[idx]
                                        : 0}
                                    </Link>
                                  ) : (
                                    <span>{singleCell.render('Cell')}</span>
                                  )}
                                </span>
                              )}
                            </span>
                          )}
                        </Styles.TD>
                      )
                    })}
                  </Styles.TBodyRow>
                )
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>There was some error displaying table</h3>
        )}
      </Styles.TableContainer>
      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page{''} {pageIndex + 1} of {pageOptions.length}{' '}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span> {'<<'} </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <span> Previous</span>
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(jumpToPageNumber)
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            <span>Next </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span>{'>>'} </span>
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>
      {openEditModal ? (
        <EditProjectForm
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          idToEdit={idToEdit}
          setIdToEdit={setIdToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      ) : null}
      {openDeleteModal ? (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      ) : null}
    </Styles.SupplierBox>
  )
}

export default Projects
