import moment from 'moment'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  DatePicker,
  Select,
  // Switch
} from 'antd'

const { Option } = Select

const specializationsOfEducation = [
  'Agriculture',
  'Architecture',
  'Arts',
  'Business',
  'Commerce',
  'Computer Application',
  'Dentistry',
  'Design',
  'Economics',
  'Education',
  'Engineering',
  'Environment',
  'Language',
  'Law',
  'Liberal Arts',
  'Medicine',
  'Multimedia',
  'Nursing',
  'Nutrition',
  'Performing Arts',
  'Pharmacy',
  'Physiotherapy',
  'Sciences',
  'Social Work',
  'Tourism',
]

export default class NewLeadBackgroundForm extends Component {
  disabledDate = current => {
    return (
      (current && moment().startOf('day') < moment(current).add(14, 'year')) ||
      (current &&
        moment().startOf('day') < moment(current).subtract(100, 'year'))
    )
  }

  render() {
    return (
      <Row className="step-container step-2">
        <Col md={4}>
          <label className="control-label is-imp">Employment Status</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.background.employmentStatus}
            onChange={this.props.changeHandler.bind(
              this,
              'background',
              'employmentStatus'
            )}
          >
            <Option value="">Select Employment Status</Option>
            <Option value="Unemployed">Unemployed</Option>
            <Option value="Employed">Employed</Option>
            <Option value="Student">Student</Option>
            <Option value="Businessperson">Business Person</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('employmentStatus') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.employmentStatus}
            </div>
          ) : null}
        </Col>

        {this.props.background.employmentStatus === 'Employed' ? (
          <Col md={4}>
            <label className="control-label">Name of the employer</label>
            <input
              type="text"
              placeholder="Employer Name"
              value={this.props.background.employer}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'background',
                'employer'
              )}
            />
          </Col>
        ) : null}

        <Col md={4}>
          <label className="control-label is-imp">
            Current Annual Income (Personal)
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.background.currentAnnualIncome}
            onChange={this.props.changeHandler.bind(
              this,
              'background',
              'currentAnnualIncome'
            )}
          >
            <Option value="">Select Annual Income</Option>
            <Option value="Under INR 25000">Under INR 25000</Option>
            <Option value="INR 25000- INR 1 lac">INR 25000- INR 1 lac</Option>
            <Option value="INR 1 lac- 5 lac">INR 1 lac- 5 lac</Option>
            <Option value="INR 5 lac- 10 lac">INR 5 lac- 10 lac</Option>
            <Option value="INR 10 lac- 15 lac">INR 10 lac- 15 lac</Option>
            <Option value="INR 15 lac - 25 lac">INR 15 lac - 25 lac</Option>
            <Option value="Over INR 25 lac">Over INR 25 lac</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('currentAnnualIncome') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.currentAnnualIncome}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Total Work Experience (in years)
          </label>
          <input
            type="number"
            min={0}
            placeholder="Total Work Experience"
            value={this.props.background.experience}
            className={'form-control'}
            onChange={this.props.changeHandler.bind(
              this,
              'background',
              'experience'
            )}
          />
          {this.props.errorObj.hasOwnProperty('experience') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.experience}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Family Background and Stability
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.background.backgroundAndStability}
            onChange={this.props.changeHandler.bind(
              this,
              'background',
              'backgroundAndStability'
            )}
            defaultActiveFirstOption={false}
          >
            <Option value="" disabled>
              Select Option
            </Option>
            <Option value="Stable (Ownership of assets (house/land/shop) by individual/family)">
              Stable (Ownership of assets (house/land/shop) by
              individual/family)
            </Option>
            <Option value="Somewhat stable (No dependents, Other earning members in the family)">
              Somewhat stable (No dependents, Other earning members in the
              family)
            </Option>
            <Option value="Unstable (Only earning member, with dependents)">
              Unstable (Only earning member, with dependents)
            </Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('backgroundAndStability') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.backgroundAndStability}
            </div>
          ) : null}
        </Col>

        {/*<Col md={4}>
					<label className="control-label">Identity Number</label>
					<input type="text" placeholder="Enter Identity Number" value={this.props.background.identityNumber} className={'form-control'} onChange={this.props.changeHandler.bind(this, 'background', 'identityNumber')} />
				</Col>*/}
      </Row>
    )
  }
}
