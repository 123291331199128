import React from 'react'
import {
  Col,
  Row,
  Button,
  // Modal
} from 'react-bootstrap'
import moment from 'moment'

export default class Basic extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      leadData: this.props.leadData,
    }
  }

  render() {
    return (
      <>
        <h2 id="basic" className="step-heading">
          BASIC
        </h2>
        <Row className="mt10 lead-data-container lead-basic">
          <Col md={4} sm={6} xs={6}>
            <label className="heading control-label">Full Name</label>
            <span>{this.state.leadData.basic.name}</span>
          </Col>

          <Col md={4} sm={6} xs={6}>
            <label className="heading control-label">Mobile</label>
            <span>{this.state.leadData.basic.mobileNumber}</span>
          </Col>
          {this.state.leadData.basic.email ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Email</label>
              <span>{this.state.leadData.basic.email}</span>
            </Col>
          ) : null}
          {this.state.leadData.basic.address.state ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">State</label>
              <span>{this.state.leadData.basic.address.state}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.address.district ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">District</label>
              <span>{this.state.leadData.basic.address.district}</span>
            </Col>
          ) : null}
          {this.state.leadData.basic.address.block ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Block</label>
              <span>{this.state.leadData.basic.address.block}</span>
            </Col>
          ) : null}
          {this.state.leadData.basic.address.city ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">City</label>
              <span>{this.state.leadData.basic.address.city}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.address.line1 ? (
            <Col md={12} sm={6} xs={6}>
              <label className="heading control-label">Address Line 1</label>
              <span>{this.state.leadData.basic.address.line1}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.address.line2 ? (
            <Col md={12} sm={6} xs={6}>
              <label className="heading control-label">Address Line 2</label>
              <span>{this.state.leadData.basic.address.line2}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.pincode ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Pincode</label>
              <span>{this.state.leadData.basic.pincode}</span>
            </Col>
          ) : null}

          {/* {this.state.leadData.basic.phase ? (
                    <Col md={4} sm={6} xs={6}>
                        <label className="heading control-label">Phase</label>
                        <span>{this.state.leadData.basic.phase === 'Start-up' ? 'Wants to start a new business' : 'Runs a business, Wants to grow it'}</span>
                    </Col>
                ) : null} */}

          {/* {this.state.leadData.basic.phase === "Scale-up" ? (
                    <Col md={4} sm={6} xs={6}>
                        <label className="heading control-label">
                            Taken business loan before
                        </label>
                        <span>
                            {this.state.leadData.basic.creditLinked ? "Yes" : "No"}
                        </span>
                    </Col>
                ) : null} */}

          {/* {this.state.leadData.basic.source ? (
									<Col md={4}>
										<label className="heading control-label">Source</label>
										<span>{this.state.leadData.basic.source}</span>
									</Col>
								) : (null)} */}

          {this.state.leadData.basic.gender ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Gender</label>
              <span>{this.state.leadData.basic.gender}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.generatedThrough ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Generated Through</label>
              {/* <span>{this.state.leadData.basic.generatedThrough==='Already trained candidate'?`${this.state.leadData.basic.generatedThrough} - ${this.state.leadData.leadMaturity.skillTrained.instituteType}`:this.state.leadData.basic.generatedThrough}</span> */}
              <span>
                {this.state.generatedThrough !== null
                  ? this.state.generatedThrough
                  : this.state.leadData.basic.generatedThrough}
              </span>
              {/* <span>{this.state.leadData.basic.generatedThrough} {this.state.generatedThrough!==null?` - ${this.state.generatedThrough}`:(null)}</span> */}
            </Col>
          ) : null}

          {this.state.leadData.basic.altMobileNumber ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Alt Mobile Number</label>
              <span>{this.state.leadData.basic.altMobileNumber}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.nativeOf.state &&
          this.state.leadData.basic.nativeOf.state ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Native State</label>
              <span>{this.state.leadData.basic.nativeOf.state}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.nativeOf.district &&
          this.state.leadData.basic.nativeOf.district ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Native District</label>
              <span>{this.state.leadData.basic.nativeOf.district}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.nativeOf.city &&
          this.state.leadData.basic.nativeOf.city ? (
            <Col md={4} sm={6} xs={6}>
              <label className="heading control-label">Native City</label>
              <span>{this.state.leadData.basic.nativeOf.city}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.educationalQualification ? (
            <Col md={4}>
              <label className="heading control-label">
                Educational Qualification
              </label>
              <span>{this.state.leadData.basic.educationalQualification}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.religion ? (
            <Col md={4}>
              <label className="heading control-label">Religion</label>
              <span>{this.state.leadData.basic.religion}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.category ? (
            <Col md={4}>
              <label className="heading control-label">Category</label>
              <span>{this.state.leadData.basic.category}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.pwd ? (
            <Col md={4}>
              <label className="heading control-label">
                Is a person with disability (PWD)
              </label>
              <span>{this.state.leadData.basic.pwd}</span>
            </Col>
          ) : null}

          {this.state.leadData.basic.numberOfFamilyMember !== null ? (
            <Col md={4}>
              <label className="heading control-label">
                Total number of family members
              </label>
              <span>{this.state.leadData.basic.numberOfFamilyMember}</span>
            </Col>
          ) : null}

          {/* {this.state.leadData.familyAndAsset.numberOfDependents !==
                    null ? (
                    <Col md={4}>
                        <label className="heading control-label">
                            Total number of dependents
                        </label>
                        <span>
                            {this.state.leadData.familyAndAsset.numberOfDependents}
                        </span>
                    </Col>
                ) : null} */}
          {this.state.leadData.basic.numberOfDependents !== null ? (
            <Col md={4}>
              <label className="heading control-label">
                Total number of dependents
              </label>
              <span>{this.state.leadData.basic.numberOfDependents}</span>
            </Col>
          ) : null}
          {this.state.leadData.basic.dob ? (
            <Col md={4}>
              <label className="heading control-label">Date Of Birth</label>
              <span>
                {moment(this.state.leadData.basic.dob).format('DD/MM/YYYY')}
              </span>
            </Col>
          ) : null}

          {this.state.leadData.basic.identityType &&
          this.state.leadData.basic.identityType.length ? (
            <Col md={4}>
              <label className="heading control-label">Identity Type</label>
              <span>{this.state.leadData.basic.identityType.join(', ')}</span>
            </Col>
          ) : null}

          {this.state.leadData?.leadMaturity?.enterpriseSetup
            ?.sidbiMilestone ? (
            <Col md={4}>
              <label className="heading control-label">Milestone/Batch</label>
              <span>
                {
                  this.state.leadData?.leadMaturity?.enterpriseSetup
                    ?.sidbiMilestone
                }
              </span>
            </Col>
          ) : null}

          {this.state.leadData.basic.candidateConsentDoc &&
          typeof this.state.leadData.basic.candidateConsentDoc === 'string' ? (
            <Col md={4}>
              <label className="heading control-label">
                Consent/documentation of the candidate
              </label>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={this.state.leadData.basic.candidateConsentDoc}
              >
                View File
              </a>
            </Col>
          ) : null}
        </Row>
      </>
    )
  }
}
