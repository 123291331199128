import React from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { ProjectContextProvider } from './components/partials/context/ProjectContext'
import { store } from './components/partials/humblebee/store.js'
import subdomainIsValid from './checkSubdomain.js'

import 'antd/dist/antd.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import dotenv from 'dotenv'
dotenv.config() // Load environment variables at the beginning

// Declare variables
let url = '',
  subdomain = '',
  appName = 'Humblebee',
  logo = '/images/s-logo.png',
  manifest = '%PUBLIC_URL%/manifest-s.json',
  title = 'HUMBLEBEE | ADMIN PANEL',
  description = 'Humblebee Admin Panel'

// Check if the hostname has a subdomain
if (
  window.location.hostname.split('.').length === 3 &&
  window.location.hostname.split('.')[0] !== 'www'
) {
  subdomain = window.location.hostname.split('.')[0]
}

// if (subdomain && subdomainIsValid(subdomain)) {
// 	// Apply theme based on subdomain
// 	url = window.location.origin + '/themes/' + subdomain + '.theme.css';
// 	document.body.innerHTML += `<style> @import '${url}'; </style>`;
// } else {
url = window.location.origin + '/themes/le.theme.css'
document.body.innerHTML += `<style> @import '${url}'; </style>`
//}

// Custom branding for certain hostnames
if (true) {
  appName = 'Humblebee'
  logo = '/images/u-logo.png'
  title = 'HUMBLEBEE | ADMIN PANEL'
  manifest = '%PUBLIC_URL%/manifest-u.json'
}

console.log('Starting app with subdomain:', subdomain)

// Render the main component
ReactDOM.render(
  <DndProvider backend={Backend}>
    <Provider store={store}>
      <ProjectContextProvider style={{ width: '10%' }}>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick={false}
          newestOnTop={false}
          pauseOnHover
        />
        <Helmet>
          <title>{title}</title>
          <link rel="manifest" href={manifest} />
          <link rel="apple-touch-icon" href={logo} />
          <meta name="description" content={description} />
          <link rel="icon" type="image/png" href={logo} sizes="16x16" />
          <link rel="icon" type="image/png" href={logo} sizes="32x32" />
          <meta property="og:image" content={logo} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <Routes />
      </ProjectContextProvider>
    </Provider>
  </DndProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
