import $ from 'jquery'
import moment from 'moment'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import React, { Component } from 'react'
import { Col, Row, Button, Modal } from 'react-bootstrap'
import {
  // ConfigProvider, DatePicker, Switch,
  Select,
} from 'antd'

const readCookie = require('../cookie.js').readCookie

const {
  Option,
  // OptGroup
} = Select
// const colors = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#ef5a20']

export default class MyTasks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorObj: {},
      loaded: false,
      allFellows: [],
      schedulerData: [],
      searchedLeads: [],
      selectedTask: null,
      taskCellPosition: {},
      maxParallelTasksOnDate: {},
      viewModalVisibility: false,
      editModalVisibility: false,
      startDate: moment().startOf('week'),
      newTask: {
        title: '',
        description: '',
        totalTime: '',
        color: '#222',
        bgColor: '#2ccce4',
        startTime: '',
        date: '',
        taskType: '',
        assignedTo: '',
        taggedLead: '',
      },
    }
  }

  componentDidMount() {
    this.refreshTasks()

    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/user/users/swavalamban-fellows',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) this.setState({ allFellows: data.users })
      })
  }

  refreshTasks = query => {
    this.setState({ loaded: false })
    if (!query)
      query =
        '?fromDate=' +
        this.state.startDate.format('DD-MM-YYYY') +
        '&toDate=' +
        moment(this.state.startDate).add(1, 'week').format('DD-MM-YYYY')
    fetch(process.env.REACT_APP_API_URL + '/api/v1/tasks' + query, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState(
            {
              loaded: true,
              schedulerData: data.tasks,
              taskCellPosition: data.taskCellPosition,
              maxParallelTasksOnDate: data.maxParallelTasksOnDate,
            },
            () => {
              // $(document).ready(function() {
              // 	$('div.tasks-page.page-container').scrollTop(1580);
              // });
            }
          )
        }
      })
  }

  showTaskDetails = (task, e) => {
    e.stopPropagation()
    this.setState({ selectedTask: task, viewModalVisibility: true })
  }

  hideShowModal = () => {
    this.setState({ selectedTask: null, viewModalVisibility: false })
  }

  editTask = () => {
    let selectedTask = this.state.selectedTask
    if (selectedTask) {
      let newTask = {
        title: selectedTask.title,
        description: selectedTask.description,
        totalTime: selectedTask.totalTime,
        color: selectedTask.color,
        bgColor: selectedTask.bgColor,
        startTime: selectedTask.startTime,
        date: selectedTask.date,
        taskType: selectedTask.taskType,
        assignedTo: selectedTask.assignedTo.id,
        taggedLead: selectedTask.taggedLead.id,
      }
      if (newTask.assignedTo === JSON.parse(readCookie('userData')).id)
        newTask.assignedTo = ''
      if (newTask.taggedLead) {
        // fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-lead/' + newTask.taggedLead, {
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/swati-lead/project-wise-splits/' +
            newTask.taggedLead,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              let searchedLeads = [
                {
                  id: data.lead._id,
                  name: data.lead.basic.name,
                  mobileNumber: data.lead.basic.mobileNumber,
                },
              ]
              this.setState({
                searchedLeads,
                viewModalVisibility: false,
                editModalVisibility: true,
                newTask,
              })
            } else {
              newTask.taggedLead = undefined
              this.setState({
                viewModalVisibility: false,
                editModalVisibility: true,
                newTask,
              })
            }
          })
      } else {
        this.setState({
          viewModalVisibility: false,
          editModalVisibility: true,
          newTask,
        })
      }
    }
  }

  hideEditModal = () => {
    this.setState({
      selectedTask: null,
      editModalVisibility: false,
      newTask: {
        title: '',
        description: '',
        totalTime: '',
        color: '#222',
        bgColor: '#2ccce4',
        startTime: '',
        date: '',
        taskType: '',
        assignedTo: '',
        taggedLead: '',
      },
    })
  }

  changeHandler = (type, e) => {
    let value = e,
      newTask = this.state.newTask,
      errorObj = this.state.errorObj
    if (e.target) value = e.target.value

    if (type === 'bgColor') newTask[type] = value.hex
    else if (type === 'date')
      newTask[type] = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    else newTask[type] = value
    if (value && errorObj.hasOwnProperty(type)) delete errorObj[type]
    this.setState({ newTask, errorObj })
  }

  saveTask = () => {
    let newTask = this.state.newTask,
      errorObj = this.state.errorObj

    if (
      newTask.title &&
      moment(newTask.date, 'DD/MM/YYYY').isValid() &&
      newTask.startTime &&
      newTask.totalTime &&
      newTask.taskType
    ) {
      newTask.color = $(
        '.task-details-modal .block-picker > div:nth-child(2) > div'
      ).css('color')
      let url = process.env.REACT_APP_API_URL + '/api/v1/tasks',
        method = 'POST'
      if (this.state.selectedTask) {
        url += '/' + this.state.selectedTask._id
        method = 'PATCH'
      }
      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify(newTask),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            this.hideEditModal()
            this.notifySuccess(data.result)
            this.refreshTasks()
          } else {
            Swal.fire('Oops! Save failed', 'Save failed', 'error')
          }
        })
    } else {
      if (!newTask.title) errorObj['title'] = 'Title is required!'
      if (!moment(newTask.date, 'DD/MM/YYYY').isValid())
        errorObj['date'] = 'Date is invalid!'
      if (!newTask.startTime) errorObj['startTime'] = 'Start Time is required!'
      if (!newTask.totalTime)
        errorObj['totalTime'] = 'Total duration is required!'
      if (!newTask.taskType) errorObj['taskType'] = 'Task type is required!'
      this.setState({ errorObj })
    }
  }

  deleteTask = () => {
    if (this.state.selectedTask) {
      Swal.fire({
        title: 'Are you sure about deleting this task?',
        html: 'You wont be able to revert this!',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, Delete',
        confirmButtonAriaLabel: 'Yes, Delete',
        cancelButtonText: 'No, Cancel',
        cancelButtonAriaLabel: 'No, Cancel',
      }).then(result => {
        if (result.value) {
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/tasks/' +
              this.state.selectedTask._id,
            {
              method: 'DELETE',
              headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
            }
          )
            .then(response => {
              return response.json()
            })
            .then(data => {
              if (data.status) {
                this.hideEditModal()
                this.notifySuccess(data.result)
                this.refreshTasks()
              } else {
                Swal.fire('Oops! Save failed', 'Delete failed', 'error')
              }
            })
        }
      })
    }
  }

  openNewTaskModal = (startTime = '', date = '') => {
    this.setState({
      selectedTask: null,
      editModalVisibility: true,
      newTask: {
        title: '',
        description: '',
        totalTime: undefined,
        color: '#222',
        bgColor: '#2ccce4',
        startTime,
        date,
        taskType: undefined,
        assignedTo: '',
        taggedLead: undefined,
      },
    })
  }

  gotoWeek = week => {
    let startDate = this.state.startDate
    if (week === 'next') {
      startDate = moment(startDate).add(1, 'week')
    } else if (week === 'prev') {
      startDate = moment(startDate).subtract(1, 'week')
    }
    this.setState({ startDate }, () => {
      this.refreshTasks()
    })
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === 'string') {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
    }
  }

  handleSearch = value => {
    if (value) {
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/swati-leads/brief?pageNumber=0&limit=20&disqualified=false&q=' +
          value,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ searchedLeads: data.simpleLeadInfo })
          } else {
            this.setState({ searchedLeads: [] })
          }
        })
        .catch(() => {
          this.setState({ searchedLeads: [] })
        })
    } else {
      this.setState({ searchedLeads: [] })
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  render() {
    return (
      <div className="tasks-page page-container">
        <h3>My Tasks</h3>
        <div className="date-changer">
          <div onClick={this.gotoWeek.bind(this, 'prev')}>
            <i className="fa fa-chevron-left fa-2x"></i>
          </div>
          {this.state.startDate.format('MMMM') ===
          moment(this.state.startDate).add(1, 'week').format('MMMM') ? (
            <h4 className="ml10 mr10">
              {this.state.startDate.format('MMMM YYYY')}
            </h4>
          ) : (
            <h4 className="ml10 mr10">
              {this.state.startDate.format('MMMM YYYY') +
                ' - ' +
                moment(this.state.startDate).add(1, 'week').format('MMMM YYYY')}
            </h4>
          )}
          <div onClick={this.gotoWeek.bind(this, 'next')}>
            <i className="fa fa-chevron-right fa-2x"></i>
          </div>
        </div>
        {this.state.loaded ? (
          <div className="table-container">
            <div
              className="my-table"
              style={{
                width:
                  (Object.values(this.state.maxParallelTasksOnDate).reduce(
                    function (a, b) {
                      return a + b
                    },
                    0
                  ) +
                    1) *
                    120 +
                  1,
              }}
            >
              <div className="table-header">
                <div className="table-header-cell cell">Tasks</div>
                <div
                  className={
                    moment(this.state.startDate).isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate).format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate).format('ddd, DD MMM YYYY')}
                </div>
                <div
                  className={
                    moment(this.state.startDate)
                      .add(1, 'days')
                      .isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate)
                          .add(1, 'days')
                          .format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate)
                    .add(1, 'days')
                    .format('ddd, DD MMM YYYY')}
                </div>
                <div
                  className={
                    moment(this.state.startDate)
                      .add(2, 'days')
                      .isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate)
                          .add(2, 'days')
                          .format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate)
                    .add(2, 'days')
                    .format('ddd, DD MMM YYYY')}
                </div>
                <div
                  className={
                    moment(this.state.startDate)
                      .add(3, 'days')
                      .isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate)
                          .add(3, 'days')
                          .format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate)
                    .add(3, 'days')
                    .format('ddd, DD MMM YYYY')}
                </div>
                <div
                  className={
                    moment(this.state.startDate)
                      .add(4, 'days')
                      .isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate)
                          .add(4, 'days')
                          .format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate)
                    .add(4, 'days')
                    .format('ddd, DD MMM YYYY')}
                </div>
                <div
                  className={
                    moment(this.state.startDate)
                      .add(5, 'days')
                      .isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate)
                          .add(5, 'days')
                          .format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate)
                    .add(5, 'days')
                    .format('ddd, DD MMM YYYY')}
                </div>
                <div
                  className={
                    moment(this.state.startDate)
                      .add(6, 'days')
                      .isSame(moment(), 'day')
                      ? 'table-header-cell cell today'
                      : 'table-header-cell cell'
                  }
                  style={{
                    width:
                      this.state.maxParallelTasksOnDate[
                        moment(this.state.startDate)
                          .add(6, 'days')
                          .format('DD/MM/YYYY')
                      ] *
                        120 +
                      20,
                  }}
                >
                  {moment(this.state.startDate)
                    .add(6, 'days')
                    .format('ddd, DD MMM YYYY')}
                </div>
              </div>
              {this.state.schedulerData.map((row, rowIndex) => {
                return (
                  <div key={rowIndex} className="table-content">
                    <div className="cell">
                      {row.time +
                        '-' +
                        moment(row.time, 'hh:mm A')
                          .add(15, 'minutes')
                          .format('hh:mm A')}
                    </div>
                    {row.dates.map((date, dateIndex) => {
                      return (
                        <div
                          className="cell"
                          key={dateIndex}
                          style={{
                            width:
                              this.state.maxParallelTasksOnDate[date.date] *
                                120 +
                              20,
                          }}
                          onClick={this.openNewTaskModal.bind(
                            this,
                            row.time,
                            date.date
                          )}
                        >
                          {date.tasks.map((task, taskIndex) => {
                            let intervalCount = task.totalTime / 15,
                              height = 41
                            if (intervalCount > 1)
                              height = 45 * intervalCount + intervalCount - 5
                            return (
                              <div
                                className="task-container"
                                key={taskIndex}
                                style={{
                                  color: task.color,
                                  backgroundColor: task.bgColor,
                                  height,
                                  left:
                                    this.state.taskCellPosition[task._id] *
                                      118 || 0,
                                }}
                                onClick={this.showTaskDetails.bind(this, task)}
                              >
                                <span>{task.title}</span>
                                <span className="time">
                                  {row.time +
                                    ' - ' +
                                    moment(row.time, 'hh:mm A')
                                      .add(task.totalTime, 'minutes')
                                      .format('hh:mm A')}
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}

        {this.state.viewModalVisibility && this.state.selectedTask ? (
          <Modal
            className="task-details-modal"
            show={this.state.viewModalVisibility}
            size="lg"
            onHide={this.hideShowModal}
          >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                <div className="title-container">
                  <div
                    className="tag"
                    style={{ backgroundColor: this.state.selectedTask.bgColor }}
                  ></div>
                  {this.state.selectedTask.title}
                </div>
                {this.state.selectedTask.taskType !== 'Note' ? (
                  <div
                    className={
                      'status-container ' +
                      this.state.selectedTask.taskStatus.toLowerCase()
                    }
                  >
                    {this.state.selectedTask.taskStatus}
                  </div>
                ) : null}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb20">
              <Row>
                <Col md={12} className="selected-task-details">
                  <div className="selected-task-date-container">
                    <span className="label">Task Date: </span>
                    <span className="value">
                      {this.state.selectedTask.date}
                    </span>
                  </div>
                  <div className="details">
                    <span className="label">Time: </span>
                    <span className="value">
                      {this.state.selectedTask.startTime +
                        ' - ' +
                        moment(this.state.selectedTask.startTime, 'hh:mm A')
                          .add(this.state.selectedTask.totalTime, 'minutes')
                          .format('hh:mm A')}
                    </span>
                  </div>
                  <div className="details">
                    <span className="label">Task Type: </span>
                    <span className="value">
                      {this.state.selectedTask.taskType || 'Note'}
                    </span>
                  </div>
                  {this.state.selectedTask.taggedLead.id ? (
                    <div className="details">
                      <span className="label">Tagged To Lead: </span>
                      <a
                        rel="noreferrer noopener"
                        href={'/lead/' + this.state.selectedTask.taggedLead.id}
                        className="value link"
                        target="_blank"
                      >
                        {this.state.selectedTask.taggedLead.name +
                          ' (' +
                          this.state.selectedTask.taggedLead.mobileNumber +
                          ')'}
                      </a>
                    </div>
                  ) : null}
                  <div className="details">
                    <span className="label">Assigned To: </span>
                    {JSON.parse(readCookie('userData')).id ===
                    this.state.selectedTask.assignedTo.id ? (
                      <span className="value">Myself</span>
                    ) : (
                      <a
                        href={
                          '/user-types/swavalamban-fellows/' +
                          this.state.selectedTask.assignedTo.id
                        }
                        className="value link"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {this.state.selectedTask.assignedTo.name}
                      </a>
                    )}
                  </div>
                  <div className="description details">
                    <span className="label">Description: </span>
                    <p className="value">
                      {this.state.selectedTask.description}
                    </p>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                className="btn btn-danger brand-color-background"
                onClick={this.hideShowModal}
              >
                Close
              </Button>
              <Button
                className="btn btn-warning brand-color-background"
                onClick={this.editTask}
              >
                Edit
              </Button>
              {JSON.parse(readCookie('userData')).id ===
              this.state.selectedTask.addedBy.id ? (
                <Button
                  className="btn btn-danger btn-delete-task"
                  onClick={this.deleteTask}
                >
                  Delete
                </Button>
              ) : null}
            </Modal.Footer>
          </Modal>
        ) : null}

        {this.state.editModalVisibility && this.state.newTask ? (
          <Modal
            className="task-details-modal"
            show={this.state.editModalVisibility}
            size="lg"
            onHide={this.hideEditModal}
          >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                {this.state.selectedTask ? 'Edit Task' : 'Add New Task'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb20">
              <Row>
                <Col md={8}>
                  <label className="control-label is-imp">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    style={{ width: '100%' }}
                    placeholder="Enter Title"
                    onChange={this.changeHandler.bind(this, 'title')}
                    value={this.state.newTask.title}
                  />
                  {this.state.errorObj.hasOwnProperty('title') ? (
                    <div
                      style={{
                        color: '#F68787',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.title}
                    </div>
                  ) : null}
                </Col>

                <Col md={4}>
                  <label className="control-label is-imp">Date</label>
                  <input
                    className="form-control"
                    type="date"
                    style={{ width: '100%' }}
                    placeholder="Enter Date"
                    onChange={this.changeHandler.bind(this, 'date')}
                    value={moment(this.state.newTask.date, 'DD/MM/YYYY').format(
                      'YYYY-MM-DD'
                    )}
                  />
                  {this.state.errorObj.hasOwnProperty('date') ? (
                    <div
                      style={{
                        color: '#F68787',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.date}
                    </div>
                  ) : null}
                </Col>

                <Col md={6}>
                  <label className="control-label is-imp">Start Time</label>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select Start Time"
                    onChange={this.changeHandler.bind(this, 'startTime')}
                    value={this.state.newTask.startTime}
                  >
                    {Array.apply(null, Array(96)).map((x, i) => {
                      return (
                        <Option
                          key={i}
                          value={moment()
                            .startOf('day')
                            .add(i * 15, 'minutes')
                            .format('hh:mm A')}
                        >
                          {moment()
                            .startOf('day')
                            .add(i * 15, 'minutes')
                            .format('hh:mm A')}
                        </Option>
                      )
                    })}
                  </Select>
                  {this.state.errorObj.hasOwnProperty('startTime') ? (
                    <div
                      style={{
                        color: '#F68787',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.startTime}
                    </div>
                  ) : null}
                </Col>

                <Col md={6}>
                  <label className="control-label is-imp">Total Duration</label>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select Total Duration"
                    onChange={this.changeHandler.bind(this, 'totalTime')}
                    value={this.state.newTask.totalTime}
                  >
                    <Option value="15">15 mins</Option>
                    <Option value="30">30 mins</Option>
                    <Option value="45">45 mins</Option>
                    <Option value="60">60 mins</Option>
                    <Option value="75">1 hour 15 mins</Option>
                    <Option value="90">1 hour 30 mins</Option>
                    <Option value="105">1 hour 45 mins</Option>
                    <Option value="120">2 hours</Option>
                    <Option value="135">2 hours 15 mins</Option>
                    <Option value="150">2 hours 30 mins</Option>
                    <Option value="165">2 hours 45 mins</Option>
                    <Option value="180">3 hours</Option>
                  </Select>
                  {this.state.errorObj.hasOwnProperty('totalTime') ? (
                    <div
                      style={{
                        color: '#F68787',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.totalTime}
                    </div>
                  ) : null}
                </Col>

                <Col md={6}>
                  <label className="control-label is-imp">Task Type</label>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select Task Type"
                    onChange={this.changeHandler.bind(this, 'taskType')}
                    value={this.state.newTask.taskType}
                  >
                    <Option value="Call">Call</Option>
                    <Option value="Meeting">Meeting</Option>
                    <Option value="Visit">Visit</Option>
                    <Option value="Note">Note</Option>
                  </Select>
                  {this.state.errorObj.hasOwnProperty('taskType') ? (
                    <div
                      style={{
                        color: '#F68787',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.taskType}
                    </div>
                  ) : null}
                </Col>

                {JSON.parse(readCookie('userData')).userType.name ===
                'Project Management Unit' ? (
                  <Col md={6}>
                    <label className="control-label is-imp">Assign To</label>
                    <Select
                      showSearch
                      size="large"
                      style={{ width: '100%' }}
                      placeholder="Assign To"
                      onChange={this.changeHandler.bind(this, 'assignedTo')}
                      value={this.state.newTask.assignedTo}
                      filterOption={(input, option) =>
                        this.searchFilter(input, option)
                      }
                    >
                      <Option value="">Myself</Option>
                      {this.state.allFellows.map((fellow, index) => {
                        return (
                          <Option value={fellow._id}>{fellow.fullName}</Option>
                        )
                      })}
                    </Select>
                    {this.state.errorObj.hasOwnProperty('assignedTo') ? (
                      <div
                        style={{
                          color: '#F68787',
                          fontSize: '13px',
                          fontWeight: 600,
                        }}
                      >
                        {this.state.errorObj.assignedTo}
                      </div>
                    ) : null}
                  </Col>
                ) : null}

                <Col md={6}>
                  <label className="control-label">Tag To Lead</label>
                  <Select
                    showSearch
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Search Lead by Name/Mobile"
                    onChange={this.changeHandler.bind(this, 'taggedLead')}
                    value={this.state.newTask.taggedLead}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    notFoundContent={null}
                  >
                    {this.state.searchedLeads.map((lead, index) => {
                      return (
                        <Option value={lead.id}>
                          {lead.name + ' (' + lead.mobileNumber + ')'}
                        </Option>
                      )
                    })}
                  </Select>
                  {this.state.errorObj.hasOwnProperty('taggedLead') ? (
                    <div
                      style={{
                        color: '#F68787',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.taggedLead}
                    </div>
                  ) : null}
                </Col>

                <Col md={12}>
                  <label className="control-label">Description</label>
                  <textarea
                    className="form-control"
                    type="text"
                    style={{ width: '100%' }}
                    placeholder="Enter Description"
                    onChange={this.changeHandler.bind(this, 'description')}
                    value={this.state.newTask.description}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                className="btn btn-danger brand-color-background"
                onClick={this.hideEditModal}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-warning brand-color-background"
                onClick={this.saveTask}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    )
  }
}
