/** @format */

import React from 'react'
import { Select } from 'antd'
import {
  Col,
  Row,
  // Button, Modal
} from 'react-bootstrap'
const { OptGroup, Option } = Select
const StabilityAndBackgroundDDFormComponent = ({
  leadData,
  projectData,
  globalDDFormState,
  isEditable,
  dueDiligenceChange,
  setGlobalDDFormState,
  saveToProfile,
}) => {
  return (
    <>
      <h3>STABILITY & BACKGROUND</h3>

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Number of earning members in the family
        </label>
        <input
          type="number"
          placeholder="Number of earning family members"
          value={globalDDFormState.stabilityAndBackground.earningFamilyMembers}
          className={'form-control'}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'stabilityAndBackground',
              // "questions",
              'earningFamilyMembers'
            )
          }
        />
      </Col>

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">Monthly household income (INR)</label>
        <div className="inr-input">
          <span className="inr-symbol">₹</span>
          <input
            type="text"
            placeholder="Monthly household income (INR)"
            value={
              globalDDFormState.stabilityAndBackground.monthlyHouseholdIncome
                ? String(
                    globalDDFormState.stabilityAndBackground
                      .monthlyHouseholdIncome
                  )
                    .replace(/\D/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            className={'form-control'}
            disabled={!isEditable}
            onChange={e =>
              dueDiligenceChange(
                e,
                'stabilityAndBackground',
                // "questions",
                'monthlyHouseholdIncome'
              )
            }
          />
        </div>
      </Col>

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">Do you file Income Tax?</label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.stabilityAndBackground.filedIncomeTax}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'stabilityAndBackground',
              // "questions",
              'filedIncomeTax'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {['Yes', 'No'].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>

      {globalDDFormState.stabilityAndBackground.filedIncomeTax === 'Yes' ? (
        <Col md={12} className="due-diligence-question same-line">
          <label className="control-label">
            How much income do you file in the IT return?
          </label>
          <div className="inr-input">
            <span className="inr-symbol">₹</span>
            <input
              type="text"
              placeholder="Income in ITR"
              value={
                globalDDFormState.stabilityAndBackground.incomeInITR
                  ? String(globalDDFormState.stabilityAndBackground.incomeInITR)
                      .replace(/\D/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : ''
              }
              className={'form-control'}
              disabled={!isEditable}
              onChange={e =>
                dueDiligenceChange(
                  e,
                  'stabilityAndBackground',
                  // "questions",
                  'incomeInITR'
                )
              }
            />
          </div>
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">Monthly individual income (INR)</label>
        <div className="inr-input">
          <span className="inr-symbol">₹</span>
          <input
            type="text"
            placeholder="Monthly individual income (INR)"
            value={
              globalDDFormState.stabilityAndBackground.monthlyIndividualIncome
                ? String(
                    globalDDFormState.stabilityAndBackground
                      .monthlyIndividualIncome
                  )
                    .replace(/\D/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            className={'form-control'}
            disabled={!isEditable}
            onChange={e =>
              dueDiligenceChange(
                e,
                'stabilityAndBackground',
                // "questions",
                'monthlyIndividualIncome'
              )
            }
          />
        </div>
      </Col>

      {globalDDFormState.stabilityAndBackground.monthlyIndividualIncome &&
      !isNaN(
        globalDDFormState.stabilityAndBackground.monthlyIndividualIncome
      ) ? (
        <Col md={12} className="due-diligence-question same-line">
          <label className="control-label">Source of Income</label>
          <textarea
            placeholder="Source of Income"
            value={globalDDFormState.stabilityAndBackground.incomeSource}
            className={'form-control'}
            disabled={!isEditable}
            onChange={e =>
              dueDiligenceChange(
                e,
                'stabilityAndBackground',
                // "questions",
                'incomeSource'
              )
            }
          />
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <div className="d-flex flex-column">
          <label className="control-label">Current occupation</label>
          <note>Changing this will update the profile</note>
        </div>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          defaultValue={
            globalDDFormState.stabilityAndBackground.employmentStatus
          }
          disabled={!isEditable}
          onChange={e => saveToProfile(e, 'employmentStatus')}
        >
          <Option value="" disabled>
            Select Employment Status
          </Option>
          <Option value="Unemployed">Unemployed</Option>
          <Option value="Employed">Employed</Option>
          <Option value="Student">Student</Option>
          <Option value="Businessperson">Businessperson</Option>
        </Select>
      </Col>

      {globalDDFormState.stabilityAndBackground.employmentStatus ===
      'Employed' ? (
        <Col md={12} className="due-diligence-question same-line">
          <div className="d-flex flex-column">
            <label className="control-label">Name of the employer</label>
            <note>Changing this will update the profile</note>
          </div>
          <input
            type="text"
            placeholder="Employer Name"
            defaultValue={globalDDFormState.stabilityAndBackground.employer}
            className={'form-control'}
            onBlur={e => saveToProfile(e, 'employer')}
          />
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Do you live in your own house or on rent?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.stabilityAndBackground.isOwnHouse}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'stabilityAndBackground',
              // "questions",
              'isOwnHouse'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {['Rent', 'Own house'].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>

      {globalDDFormState.stabilityAndBackground.isOwnHouse === 'Own house' ? (
        <Col md={12} className="due-diligence-question same-line">
          <label className="control-label">The house is in the name of?</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            placeholder="Select"
            value={globalDDFormState.stabilityAndBackground.houseOwnerName}
            disabled={!isEditable}
            onChange={e =>
              dueDiligenceChange(
                e,
                'stabilityAndBackground',
                // "questions",
                'houseOwnerName'
              )
            }
          >
            <Option value="" disabled>
              Select Option
            </Option>
            {[
              'Self',
              'Spouse',
              'Parents',
              'Grandparents',
              'Joint family ownership',
            ].map((option, index) => {
              return (
                <Option key={index} value={option}>
                  {option}
                </Option>
              )
            })}
          </Select>
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Since how many years are you living in this house?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.stabilityAndBackground.livingInHouse}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'stabilityAndBackground',
              // "questions",
              'livingInHouse'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {[
            '<1',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '10+',
            'Since childhood',
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>
    </>
  )
}

export default StabilityAndBackgroundDDFormComponent
