import ToolkitProvider from // { Search } // ,
'react-bootstrap-table2-toolkit'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
// import PlacesAutocomplete from 'react-places-autocomplete';
import BootstrapTable from 'react-bootstrap-table-next'
import { Col, Row, Modal, Button } from 'react-bootstrap'
import React, { Component } from 'react'
import {
  Select,
  // Switch
} from 'antd'
// import { allBanks } from '../../../../commonConfig.js';

const { Option } = Select
// const { SearchBar } = Search;

const readCookie = require('../../cookie.js').readCookie

export default class NewLeadFinancialForm extends Component {
  constructor(props) {
    super(props)
    let loanApplications = [],
      loanDetailsArray = [],
      loanAmountDisabled = false
    if (this.props.loanInfo?.loanApplicationDetails)
      loanApplications = this.props.loanInfo?.loanApplicationDetails
    if (loanApplications.length) {
      loanDetailsArray = loanApplications.filter(
        scheme => scheme.status === 'Disbursed'
      )
      loanAmountDisabled =
        this.props.financial.financeType === '100% self-financed' ||
        loanDetailsArray.length > 0
    }
    this.state = {
      ideaEditData: null,
      enterpriseIdeas: [],
      ideaEditModal: false,
      businessIdeasColumns: [],
      skillTrainingRecommended: false,
      loanAmountDisabled,
      allBanks: [],
      // allBanks: allBanks,
      //["//Allahabad Bank", "Andhra Bank", "Andhra Pradesh Grameena Vikas Bank", "Aryavart Bank", "Axis Bank", "Bandhan Bank", "Bank of Bahrain and Kuwait", "Bank of Baroda", "Bank of India", "Bank of Maharashtra", "Baroda Uttar Pradesh Gramin Bank", "Canara Bank", "Central Bank of India", "City Union Bank", "Corporation Bank", "Dakshin Bihar Gramin Bank", "Dena Bank", "Deutsche Bank", "Development Credit Bank", "Dhanlaxmi Bank", "Federal Bank", "Fino Payments Bank", "Gramin Bank", "HDFC Bank", "ICICI Bank", "IDBI Bank", "Indian Bank", "Indian Post Payments Bank", "Indian Overseas Bank", "IndusInd Bank", "ING Vysya Bank", "Jammu and Kashmir Bank", "Karnataka Bank Ltd", "Karur Vysya Bank", "Kashi Gomti Samyut Gramin Bank", "Kotak Bank", "Laxmi Vilas Bank", "Oriental Bank of Commerce", "Prathama Bank", "Punjab National Bank - Corporate Banking", "Punjab National Bank - Retail Banking", "Punjab &amp; Sind Bank", "RBL Bank", "Shamrao Vitthal Co-operative Bank", "South Indian Bank", "State Bank of Bikaner &amp; Jaipur", "State Bank of Hyderabad", "State Bank of India", "State Bank of Mysore", "State Bank of Patiala", "State Bank of Travancore", "Syndicate Bank", "Tamilnad Mercantile Bank Ltd.", "UCO Bank", "Union Bank of India", "United Bank of India", "Uttar Bihar Gramin Bank", "Vijaya Bank", "Yes Bank Ltd"]
    }
  }

  componentDidMount() {
    this.getAllBanks()
    fetch(process.env.REACT_APP_API_URL + '/api/v1/bussinessIdeas/all', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ enterpriseIdeas: data.ideas })
        }
      })

    let easeOfIdeaExecutionOptions = [
        {
          value: 'Very easy (standard idea)',
          label: 'Very easy (standard idea)',
        },
        { value: 'Moderate complexity', label: 'Moderate complexity' },
        {
          value: 'Complex/Niche business idea',
          label: 'Complex/Niche business idea',
        },
      ],
      confidenceOfDemandOptions = [
        { value: 'High demand', label: 'High demand' },
        { value: 'Moderate demand', label: 'Moderate demand' },
        { value: 'Low demand', label: 'Low demand' },
      ],
      confidenceOfSupplyOptions = [
        {
          value: 'Excellent supply of raw material',
          label: 'Excellent supply of raw material',
        },
        {
          value: 'Moderate supply of raw material',
          label: 'Moderate supply of raw material',
        },
        {
          value: 'Low supply of raw material',
          label: 'Low supply of raw material',
        },
      ],
      enterpriseCategoryOptions = [
        { value: 'Manufacturing', label: 'Manufacturing' },
        { value: 'Services', label: 'Services' },
        { value: 'Trading', label: 'Trading' },
      ]

    if (this.props.financial.businessIdeas) {
      for (let i = 0; i < this.props.financial.businessIdeas.length; i++) {
        if (this.props.financial.businessIdeas[i].heroIdea === 'true') {
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/bussinessIdeas/get-details-for-idea?combine=' +
              encodeURIComponent(
                this.props.financial.businessIdeas[i].enterpriseIdea
              ),
            {
              method: 'GET',
              headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
            }
          )
            .then(response => {
              return response.json()
            })
            .then(data => {
              if (data.status) {
                if (data.idea.skillTrainingReqd) {
                  this.setState({ skillTrainingRecommended: true })
                  if (this.props.leadMaturity.skillTrainedStatus === '')
                    this.props.changeHandler(
                      'leadMaturity',
                      'skillTrainedStatus',
                      'Skill training pending'
                    )
                } else if (
                  !data.idea.skillTrainingReqd &&
                  this.props.leadMaturity.skillTrainedStatus === ''
                ) {
                  this.props.changeHandler(
                    'leadMaturity',
                    'skillTrainedStatus',
                    'Skill training not required'
                  )
                }
              }
            })
        }
      }
    }

    let businessIdeasColumns = [
      { text: '', hidden: true, dataField: '_id' },
      {
        headerFormatter: () => (
          <div className="position-relative is-imp">Enterprise Ideas</div>
        ),
        dataField: 'enterpriseIdea',
        formatter: this.emptyFormatter.bind(this, 'Select Enterprise Idea'),
        headerStyle: () => {
          return { width: '200px' }
        },
        editable: false,
      },
      {
        headerFormatter: () => (
          <div className="position-relative is-imp">Enterprise Category</div>
        ),
        dataField: 'enterpriseCategory',
        editor: { type: Type.SELECT, options: enterpriseCategoryOptions },
        formatter: this.emptyFormatter.bind(this, 'Select Enterprise Category'),
        headerStyle: () => {
          return { width: '200px' }
        },
      },
      {
        headerFormatter: () => (
          <div className="position-relative is-imp">Idea Details</div>
        ),
        dataField: 'ideaDetails',
        formatter: this.emptyFormatter.bind(this, 'Enter Idea Details'),
        headerStyle: () => {
          return { width: '200px' }
        },
      },
      {
        text: 'Hero Idea',
        dataField: 'heroIdea',
        editor: {
          type: Type.SELECT,
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        formatter: this.yesNoFormatter,
        headerStyle: () => {
          return { width: '85px' }
        },
      },
      {
        headerFormatter: () => (
          <div className="position-relative is-imp">
            Ease of execution of idea
          </div>
        ),
        dataField: 'easeOfIdeaExecution',
        editor: { type: Type.SELECT, options: easeOfIdeaExecutionOptions },
        formatter: this.emptyFormatter.bind(
          this,
          'Select ease of idea execution'
        ),
        headerStyle: () => {
          return { width: '220px' }
        },
      },
      {
        headerFormatter: () => (
          <div className="position-relative is-imp">Confidence of demand</div>
        ),
        dataField: 'confidenceOfDemand',
        editor: { type: Type.SELECT, options: confidenceOfDemandOptions },
        formatter: this.emptyFormatter.bind(
          this,
          'Select Confidence of demand'
        ),
        headerStyle: () => {
          return { width: '220px' }
        },
      },
      {
        headerFormatter: () => (
          <div className="position-relative is-imp">Confidence of supply</div>
        ),
        dataField: 'confidenceOfSupply',
        editor: { type: Type.SELECT, options: confidenceOfSupplyOptions },
        formatter: this.emptyFormatter.bind(
          this,
          'Select Confidence of supply'
        ),
        headerStyle: () => {
          return { width: '200px' }
        },
      },
      {
        text: 'Requires Land',
        dataField: 'requiresLand',
        editor: {
          type: Type.SELECT,
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        formatter: this.yesNoFormatter,
        headerStyle: () => {
          return { width: '80px' }
        },
      },
      {
        text: 'Requires Skilling',
        dataField: 'requiresSkills',
        editor: {
          type: Type.SELECT,
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        formatter: this.yesNoFormatter,
        headerStyle: () => {
          return { width: '80px' }
        },
      },
      {
        text: 'Knowledge Level',
        dataField: 'knowledgeLevel',
        formatter: this.emptyFormatter.bind(this, 'Enter Knowledge level'),
        headerStyle: () => {
          return { width: '200px' }
        },
      },
      {
        text: 'Supply figured out',
        dataField: 'supplyFiguredOut',
        editor: {
          type: Type.SELECT,
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        formatter: this.yesNoFormatter,
        headerStyle: () => {
          return { width: '120px' }
        },
      },
      {
        text: 'Market Access figured out',
        dataField: 'marketAccessFiguredOut',
        editor: {
          type: Type.SELECT,
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
        formatter: this.yesNoFormatter,
        headerStyle: () => {
          return { width: '120px' }
        },
      },
      {
        text: 'Delete',
        dataField: 'delete',
        formatter: this.deleteFormatter.bind(this, 'businessIdeas'),
        align: 'center',
        headerStyle: () => {
          return { width: '60px', textAlign: 'center' }
        },
        editable: false,
      },
    ]
    this.setState({ businessIdeasColumns })
  }
  getAllBanks = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/banks', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allBanks: data.allBanks })
        }
      })
  }
  yesNoFormatter = (cell, row) => {
    if (cell === 'true') return 'Yes'
    else if (cell === 'false') return 'No'
    else return null
  }

  deleteFormatter = (type, cell, row) => {
    if (row._id) {
      return (
        <button
          className="btn btn-primary p5"
          onClick={this.props.deleteIdeaRow.bind(this, row._id, type)}
        >
          <i className="fa fa-trash"></i>
        </button>
      )
    } else return null
  }

  emptyFormatter = (type, cell, row) => {
    if (cell) {
      if (type === 'Select Enterprise Idea') {
        return (
          <div
            className="empty-container"
            onClick={this.openIdeaEditModal.bind(this, 'businessIdea', row)}
            onDoubleClick={this.doNothing}
          >
            {cell}
          </div>
        )
      } else if (type === 'Choose Enterprise Idea') {
        return (
          <div
            className="empty-container"
            onClick={this.openIdeaEditModal.bind(
              this,
              'recommendedVenture',
              row
            )}
            onDoubleClick={this.doNothing}
          >
            {cell}
          </div>
        )
      } else {
        return cell
      }
    } else {
      if (type === 'Select Enterprise Idea') {
        return (
          <div
            className="empty-container no-value"
            onClick={this.openIdeaEditModal.bind(this, 'businessIdea', row)}
            onDoubleClick={this.doNothing}
          >
            {type}
          </div>
        )
      } else if (type === 'Choose Enterprise Idea') {
        return (
          <div
            className="empty-container no-value"
            onClick={this.openIdeaEditModal.bind(
              this,
              'recommendedVenture',
              row
            )}
            onDoubleClick={this.doNothing}
          >
            {type}
          </div>
        )
      } else {
        return <div className="empty-container no-value">{type}</div>
      }
    }
  }

  doNothing = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  openIdeaEditModal = (ideaEditModal, ideaEditData) => {
    this.setState({ ideaEditModal, ideaEditData })
  }

  closeIdeaEditModal = () => {
    this.setState({ ideaEditModal: false, ideaEditData: null })
  }

  changeBusinessIdeaHandler = (type, data, inKey, e) => {
    let ideaEditData = this.state.ideaEditData,
      value = e
    if (e.hasOwnProperty('target')) value = e.target.value

    ideaEditData[inKey] = value
    let businessIdeasColumns = this.state.businessIdeasColumns
    this.setState({ ideaEditData, businessIdeasColumns: [] }, () => {
      this.setState({ businessIdeasColumns })
    })

    this.props.changeBusinessIdeaHandler(type, data, inKey, e)
  }

  afterSaveBusinessIdeaCell = (oldValue, newValue, row, column) => {
    if (column.dataField === 'heroIdea' && newValue === 'true') {
      this.setState({ skillTrainingRecommended: true })
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/bussinessIdeas/get-details-for-idea?combine=' +
          encodeURIComponent(row.enterpriseIdea),
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            if (
              data.idea.skillTrainingReqd &&
              this.props.leadMaturity.skillTrainedStatus === ''
            ) {
              this.props.changeHandler(
                'leadMaturity',
                'skillTrainedStatus',
                'Skill training pending'
              )
            } else if (
              !data.idea.skillTrainingReqd &&
              this.props.leadMaturity.skillTrainedStatus === ''
            ) {
              this.props.changeHandler(
                'leadMaturity',
                'skillTrainedStatus',
                'Skill training not required'
              )
            }
          }
        })
    } else if (column.dataField === 'heroIdea') {
      this.setState({ skillTrainingRecommended: false })
    }
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  render() {
    // const placesAutoCompleteOptions = { types: ['(regions)', 'establishment', 'address'] },
    // 	renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    // 		<div className="autocomplete-root">
    // 			<input {...getInputProps({placeholder: 'Start typing and select from dropdown', className: 'form-control'})} />
    // 			<div className="autocomplete-dropdown-container">
    // 				{suggestions.map(suggestion => (
    // 					<div {...getSuggestionItemProps(suggestion)}>
    // 						<span>{suggestion.description}</span>
    // 					</div>
    // 				))}
    // 			</div>
    // 		</div>
    // 	);

    return (
      <Row className="step-container step-1">
        <Col md={4}>
          <label className="control-label is-imp">Has Business Idea</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.financial.hasABusinessIdea}
            onChange={this.props.changeHandler.bind(
              this,
              'financial',
              'hasABusinessIdea'
            )}
          >
            <Option value="" disabled>
              Select Quantity
            </Option>
            <Option value="One">One</Option>
            <Option value="Many">Many</Option>
            <Option value="None">None</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('hasABusinessIdea') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.hasABusinessIdea}
            </div>
          ) : null}
        </Col>
        <Col md={3}>
          <label className="control-label is-imp">Phase</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.financial.phase || ''}
            onChange={this.props.changeHandler.bind(this, 'financial', 'phase')}
          >
            <Option value="" disabled>
              Select Phase
            </Option>
            <Option value="Start-up">Wants to start a new business</Option>
            <Option value="Scale-up">Runs a business, Wants to grow it</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('phase') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.phase}
            </div>
          ) : null}
        </Col>
        {this.props.financial.phase === 'Scale-up' ? (
          <Col md={3}>
            <label className="control-label is-imp">
              Taken business loan before
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.financial.creditLinked || ''}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'creditLinked'
              )}
            >
              <Option value="" disabled>
                Select Yes/No
              </Option>
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty('creditLinked') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.creditLinked}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.financial.hasABusinessIdea &&
        this.props.financial.hasABusinessIdea !== 'None' &&
        this.state.businessIdeasColumns.length ? (
          <Col md={12} className="mt15">
            <label className="control-label is-imp">
              Enlist business ideas
            </label>
            {this.props.errorObj.hasOwnProperty('businessIdeas') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.businessIdeas}
              </div>
            ) : null}
            <ToolkitProvider
              keyField="_id"
              data={this.props.financial.businessIdeas}
              columns={this.state.businessIdeasColumns}
            >
              {props => (
                <div>
                  <BootstrapTable
                    id="business-ideas-table"
                    {...props.baseProps}
                    ref={table => {
                      this.businessIdeasTable = table
                    }}
                    cellEdit={cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      afterSaveCell: this.afterSaveBusinessIdeaCell,
                    })}
                  />
                  <Row className="mt10 justify-content-center">
                    <button
                      type="button"
                      className={
                        'btn btn-success react-bs-table-add-btn hidden-print'
                      }
                      style={{ marginLeft: '5px' }}
                      onClick={this.props.addNewBusinessIdea}
                    >
                      <span>
                        <i className="fa fa-user-plus" aria-hidden="true"></i>
                        Add Business Idea
                      </span>
                    </button>
                  </Row>
                </div>
              )}
            </ToolkitProvider>
          </Col>
        ) : null}

        <Col md={4}>
          <label className="control-label is-imp">Finance Status</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.financial.financeType}
            onChange={this.props.changeHandler.bind(
              this,
              'financial',
              'financeType'
            )}
          >
            <Option value="" disabled>
              Select Finance Type
            </Option>
            <Option value="Bank Loan required">Bank Loan required</Option>
            {this.props.financial.phase !== 'Scale-up' ? (
              <Option value="100% self-financed">100% self-financed</Option>
            ) : null}
          </Select>
          {this.props.errorObj.hasOwnProperty('financeType') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.financeType}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Self investment amount (INR)
          </label>
          <input
            type="number"
            placeholder="Self investment amount"
            value={this.props.financial.canSelfFinanceTheCapitalRequiredUpToINR}
            className={'form-control'}
            onChange={this.props.changeHandler.bind(
              this,
              'financial',
              'canSelfFinanceTheCapitalRequiredUpToINR'
            )}
            min={1000}
          />
          {this.props.errorObj.hasOwnProperty(
            'canSelfFinanceTheCapitalRequiredUpToINR'
          ) ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.canSelfFinanceTheCapitalRequiredUpToINR}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label
            className={
              this.props.financial.financeType === 'Bank Loan required'
                ? 'control-label is-imp'
                : 'control-label'
            }
          >
            Loan amount required (INR){' '}
          </label>
          <input
            type="number"
            placeholder="Loan amount required"
            value={this.props.financial.loanAmountRequired}
            className={'form-control'}
            onChange={this.props.changeHandler.bind(
              this,
              'financial',
              'loanAmountRequired'
            )}
            disabled={
              this.props.financial.financeType === '100% self-financed' ||
              this.props.loanInfo?.loanApplicationDetails.filter(
                scheme => scheme.status === 'Disbursed'
              ).length > 0
            }
          />
          {this.props.errorObj.hasOwnProperty('loanAmountRequired') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.loanAmountRequired}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Has applied for enterprise loan before
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.financial.appliedForEnterpriseLoanBefore}
            onChange={this.props.changeHandler.bind(
              this,
              'financial',
              'appliedForEnterpriseLoanBefore'
            )}
          >
            <Option value="" disabled>
              Select
            </Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty(
            'appliedForEnterpriseLoanBefore'
          ) ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.appliedForEnterpriseLoanBefore}
            </div>
          ) : null}
        </Col>

        {this.props.financial.appliedForEnterpriseLoanBefore === 'Yes' ? (
          <Col md={4}>
            <label className="control-label">Select Loan Provider Type</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.financial.loanProviderType}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'loanProviderType'
              )}
            >
              <Option value="">Select Loan Provider Type</Option>
              <Option value="Banks">Banks</Option>
              <Option value="NBFC/MFI">NBFC/MFI</Option>
              <Option value="Moneylender">Moneylender</Option>
              <Option value="Family/Friends">Family/Friends</Option>
              <Option value="Others">Others</Option>
            </Select>
          </Col>
        ) : null}

        {this.props.financial.appliedForEnterpriseLoanBefore === 'Yes' &&
        this.props.financial.loanProviderType === 'Banks' ? (
          <Col md={4}>
            <label className="control-label">Select the Banks</label>
            <Select
              size="large"
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              value={this.props.financial.bankName}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'bankName'
              )}
              filterOption={(input, option) => this.searchFilter(input, option)}
            >
              <Option value="">Select Banks</Option>
              {this.state.allBanks.length
                ? this.state.allBanks.map((bank, bankIndex) => {
                    return (
                      <Option key={bankIndex} value={bank}>
                        {bank}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Col>
        ) : null}

        {this.props.financial.appliedForEnterpriseLoanBefore === 'Yes' ? (
          <Col md={4}>
            <label className="control-label">
              Status of latest enterprise loan
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.financial.statusOfEnterpriseLoan}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'statusOfEnterpriseLoan'
              )}
            >
              <Option value="">Select Status</Option>
              <Option value="Sanctioned">Sanctioned</Option>
              <Option value="Rejected">Rejected</Option>
              <Option value="Still in process">Still in process</Option>
            </Select>
          </Col>
        ) : null}

        {this.props.financial.appliedForEnterpriseLoanBefore === 'Yes' ? (
          <Col md={4}>
            <label className="control-label">Loan size category</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.financial.loanSizeCategory}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'loanSizeCategory'
              )}
            >
              <Option value="">Select Loan Size</Option>
              <Option value="Under INR 25000">Under INR 25000</Option>
              <Option value="INR 25000- INR 1 lac">INR 25000- INR 1 lac</Option>
              <Option value="INR 1 lac- 5 lac">INR 1 lac- 5 lac</Option>
              <Option value="INR 5 lac- 10 lac">INR 5 lac- 10 lac</Option>
              <Option value="INR 10 lac- 15 lac">INR 10 lac- 15 lac</Option>
              <Option value="INR 15 lac - 25 lac">INR 15 lac - 25 lac</Option>
              <Option value="Over INR 25 lac">Over INR 25 lac</Option>
            </Select>
          </Col>
        ) : null}

        <Col md={4}>
          <label className="control-label">
            Ancestral History of working in which sector
          </label>
          <input
            type="text"
            placeholder="Ancestral History of working in which sector"
            value={this.props.financial.ancestralHistoryOfWorkingInWhichSector}
            className={'form-control'}
            onChange={this.props.changeHandler.bind(
              this,
              'financial',
              'ancestralHistoryOfWorkingInWhichSector'
            )}
          />
        </Col>

        {this.props.financial.hasABusinessIdea !== 'None' ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Family background (in the same skill)
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.financial.familyBackground}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'familyBackground'
              )}
            >
              <Option value="" disabled>
                Select Family Background
              </Option>
              <Option value="Same business sector">Same business sector</Option>
              <Option value="Different sector">Different sector</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty('familyBackground') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.familyBackground}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.financial.hasABusinessIdea !== 'None' ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Experience (familiarity) in the sector of selected business (in
              years)
            </label>
            <input
              type="number"
              min={0}
              placeholder="Experience in sector (in years)"
              value={this.props.financial.experienceInSector}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'financial',
                'experienceInSector'
              )}
            />
            {this.props.errorObj.hasOwnProperty('experienceInSector') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.experienceInSector}
              </div>
            ) : null}
          </Col>
        ) : null}

        <Col md={4} id="skill-training">
          <label
            className={
              this.state.skillTrainingRecommended
                ? 'control-label is-imp mb0'
                : 'control-label is-imp'
            }
          >
            Skill Training
          </label>
          {this.state.skillTrainingRecommended ? (
            <note className="skill-training-recommended">
              Recommended for this idea
            </note>
          ) : null}
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.leadMaturity.skillTrainedStatus}
            onChange={this.props.changeHandler.bind(
              this,
              'leadMaturity',
              'skillTrainedStatus'
            )}
            defaultActiveFirstOption={false}
          >
            <Option value="" disabled>
              Select Skill Training
            </Option>
            <Option value="Skill training not required">
              Skill training not required (General business idea)
            </Option>
            <Option value="Skill training pending">
              Skill training pending
            </Option>
            <Option value="Skill training ongoing">
              Skill training ongoing
            </Option>
            <Option value="Skill training completed and has certificate">
              Skill training completed and has certificate
            </Option>
            <Option value="Skill training completed but no certificate">
              Skill training completed but no certificate
            </Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('skillTrainedStatus') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.skillTrainedStatus}
            </div>
          ) : null}
        </Col>

        {[
          'Skill training ongoing',
          'Skill training completed and has certificate',
          'Skill training completed but no certificate',
        ].indexOf(this.props.leadMaturity.skillTrainedStatus) > -1 ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Skill Training Institute Type
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.leadMaturity.skillTrainingInstituteType}
              onChange={this.props.changeHandler.bind(
                this,
                'leadMaturity',
                'skillTrainingInstituteType'
              )}
            >
              <Option value="">Select Training Institute Type</Option>
              <Option value="RSETI">RSETI</Option>
              <Option value="RUDSETI">RUDSETI</Option>
              <Option value="PMKK (NSDC)">PMKK (NSDC)</Option>
              <Option value="JSS (NSDC)">JSS (NSDC)</Option>
              <Option value="Others">Others</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty(
              'skillTrainingInstituteType'
            ) ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.skillTrainingInstituteType}
              </div>
            ) : null}
          </Col>
        ) : null}

        {[
          'Skill training ongoing',
          'Skill training completed and has certificate',
          'Skill training completed but no certificate',
        ].indexOf(this.props.leadMaturity.skillTrainedStatus) > -1 ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Skill Training Institute Name
            </label>
            <input
              type="text"
              placeholder="Training Institute Name"
              value={this.props.leadMaturity.skillTrainingInstituteName}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'leadMaturity',
                'skillTrainingInstituteName'
              )}
            />
            {this.props.errorObj.hasOwnProperty(
              'skillTrainingInstituteName'
            ) ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.skillTrainingInstituteName}
              </div>
            ) : null}
          </Col>
        ) : null}

        {[
          'Skill training ongoing',
          'Skill training completed and has certificate',
          'Skill training completed but no certificate',
        ].indexOf(this.props.leadMaturity.skillTrainedStatus) > -1 ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Skill Training Institute Location
            </label>
            <input
              type="text"
              placeholder="Training Institute Location"
              value={this.props.leadMaturity.skillTrainingInstituteLocation}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'leadMaturity',
                'skillTrainingInstituteLocation'
              )}
            />
            {/*<PlacesAutocomplete onChange={this.props.changeHandler.bind(this, 'leadMaturity', 'skillTrainingInstituteLocation')} value={this.props.leadMaturity.skillTrainingInstituteLocation.label} options={placesAutoCompleteOptions}>
							{renderFunc}
						</PlacesAutocomplete>*/}
            {this.props.errorObj.hasOwnProperty(
              'skillTrainingInstituteLocation'
            ) ? (
              <div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>
                {this.props.errorObj.skillTrainingInstituteLocation}
              </div>
            ) : null}
          </Col>
        ) : null}

        <Col md={4}>
          <label className="control-label is-imp">
            Enterprise Development Program (EDP)
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.leadMaturity.enterpriseTrainedStatus}
            onChange={this.props.changeHandler.bind(
              this,
              'leadMaturity',
              'enterpriseTrainedStatus'
            )}
          >
            <Option value="" disabled>
              Select EDP Training
            </Option>
            <Option value="EDP not required">EDP not required</Option>
            <Option value="EDP pending">EDP pending</Option>
            <Option value="EDP ongoing">EDP ongoing</Option>
            <Option value="Completed EDP with certificate">
              Completed EDP with certificate
            </Option>
            <Option value="Completed EDP without certificate">
              Completed EDP without certificate
            </Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('enterpriseTrainedStatus') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.enterpriseTrainedStatus}
            </div>
          ) : null}
        </Col>

        {[
          'EDP ongoing',
          'Completed EDP with certificate',
          'Completed EDP without certificate',
        ].indexOf(this.props.leadMaturity.enterpriseTrainedStatus) > -1 ? (
          <Col md={4}>
            <label className="control-label is-imp">EDP Institute Type</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.leadMaturity.enterpriseTrainingInstituteType}
              onChange={this.props.changeHandler.bind(
                this,
                'leadMaturity',
                'enterpriseTrainingInstituteType'
              )}
            >
              <Option value="">Select EDP Institute Type</Option>
              <Option value="RSETI">RSETI</Option>
              <Option value="RUDSETI">RUDSETI</Option>
              <Option value="PMKK (NSDC)">PMKK (NSDC)</Option>
              <Option value="JSS (NSDC)">JSS (NSDC)</Option>
              <Option value="Others">Others</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty(
              'enterpriseTrainingInstituteType'
            ) ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.enterpriseTrainingInstituteType}
              </div>
            ) : null}
          </Col>
        ) : null}

        {[
          'EDP ongoing',
          'Completed EDP with certificate',
          'Completed EDP without certificate',
        ].indexOf(this.props.leadMaturity.enterpriseTrainedStatus) > -1 ? (
          <Col md={4}>
            <label className="control-label is-imp">EDP Institute Name</label>
            <input
              type="text"
              placeholder="EDP Institute Name"
              value={this.props.leadMaturity.enterpriseTrainingInstituteName}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'leadMaturity',
                'enterpriseTrainingInstituteName'
              )}
            />
            {this.props.errorObj.hasOwnProperty(
              'enterpriseTrainingInstituteName'
            ) ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.enterpriseTrainingInstituteName}
              </div>
            ) : null}
          </Col>
        ) : null}

        {[
          'EDP ongoing',
          'Completed EDP with certificate',
          'Completed EDP without certificate',
        ].indexOf(this.props.leadMaturity.enterpriseTrainedStatus) > -1 ? (
          <Col md={4}>
            <label className="control-label is-imp">
              EDP Institute Location
            </label>
            <input
              type="text"
              placeholder="EDP Institute Location"
              value={
                this.props.leadMaturity.enterpriseTrainingInstituteLocation
              }
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'leadMaturity',
                'enterpriseTrainingInstituteLocation'
              )}
            />
            {/*<PlacesAutocomplete onChange={this.props.changeHandler.bind(this, 'leadMaturity', 'enterpriseTrainingInstituteLocation')} value={this.props.leadMaturity.enterpriseTrainingInstituteLocation.label} options={placesAutoCompleteOptions}>
							{renderFunc}
						</PlacesAutocomplete>*/}
            {this.props.errorObj.hasOwnProperty(
              'enterpriseTrainingInstituteLocation'
            ) ? (
              <div style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}>
                {this.props.errorObj.enterpriseTrainingInstituteLocation}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.state.ideaEditModal && this.state.ideaEditData ? (
          <Modal
            className="reassign-lead-modal"
            show={this.state.ideaEditModal ? true : false}
            onHide={this.doNothing}
          >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                Business Idea Edit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb20">
              <Row>
                <Col md={12}>
                  <label className="control-label">Select Business Idea</label>
                  <Select
                    size="large"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select Business Idea"
                    onChange={this.changeBusinessIdeaHandler.bind(
                      this,
                      this.state.ideaEditModal,
                      this.state.ideaEditData,
                      'enterpriseIdea'
                    )}
                    value={this.state.ideaEditData.enterpriseIdea}
                    filterOption={(input, option) =>
                      this.searchFilter(input, option)
                    }
                  >
                    {/*<Option value="Other (अन्य)">Other (अन्य)</Option>*/}
                    {this.state.enterpriseIdeas.map((idea, ideaIndex) => {
                      return (
                        <Option key={ideaIndex} value={idea}>
                          {idea}
                        </Option>
                      )
                    })}
                  </Select>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                className="btn btn-danger brand-color-background"
                onClick={this.closeIdeaEditModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </Row>
    )
  }
}
