import React, { useState, useEffect } from 'react'
import './Tab3.css'
import { Table } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import swal from 'sweetalert'
import axios from 'axios'
import { EditFilled, FileAddFilled } from '@ant-design/icons'
// import { Switch } from 'antd';

const readCookie = require('../../../cookie').readCookie
const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`
// const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYjk2YTA5NjYyNjAzNTE1M2M4NjgzYSIsInVzZXJUeXBlIjp7Il9pZCI6IjVkOGRlMjExMTBiYTkxMDNkMjFkMWE4NSIsIm5hbWUiOiJQcm9qZWN0IE1hbmFnZW1lbnQgVW5pdCJ9LCJ1c2VyUm9sZSI6bnVsbCwibmdvIjpudWxsLCJhY2NvdW50VHlwZSI6IlN1cGVyIFVzZXIiLCJwZXJtaXNzaW9ucyI6W10sImFwcGxpY2F0aW9uIjoiU3dhdGkiLCJpYXQiOjE2MjU1NDM4ODd9.zHckkR2zwaE66s__MthKV0DuSHyJLhNBdXAoDX28OPQ';//

function Tab3(attrs) {
  const { userId } = attrs
  const [modalShow, setModalShow] = useState(false)
  const [datasetDetails, setdatasetDetails] = useState(false)
  const [datasets, setdatasets] = useState([])
  async function fetchData() {
    const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/user/dataset/${userId}`
    const config = { headers: { Authorization: AUTH_TOKEN } }
    axios.get(url, config).then(response => {
      if (response.status === 200 && response.data.error === false) {
        // console.log(response.data.dataSets[0].dataset);
        setdatasets(response.data.dataSets[0].dataset)
      }
    })
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function MyVerticallyCenteredModal(props) {
    const { onHide } = props
    const [fields, setFields] = useState(datasets) // Input Fields
    const currDate = new Date()
    const [bothFields, setbothFields] = useState(['', false, ''])

    const [newDataset, setnewDataset] = useState({
      dateAdded: currDate.toString(),
      description: false,
      uploaded: false,
      dataFile: false,
    })

    function updateDatase() {
      if (datasetDetails !== false) {
        // No route for editing till now.
        setdatasets([...fields])
      } else {
        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/user/dataset/${userId}`
        const config = {
          headers: {
            Authorization: AUTH_TOKEN,
            'Content-Type': 'multipart/form-data',
          },
        }
        // eslint-disable-next-line no-undef
        const datasetForm = new FormData()
        datasetForm.append('dateAdded', newDataset.dateAdded)
        datasetForm.append('description', newDataset.description)
        datasetForm.append('uploaded', newDataset.uploaded)
        datasetForm.append('dataFile', newDataset.dataFile)

        // axios.post(url, newDataset, config).then((response) => {
        axios
          .post(url, datasetForm, config)
          .then(response => {
            // console.log(response);
            if (response.status === 200 && response.data.error === false) {
              // console.log(response.data);
              swal('Successfully Added', '', 'success')
              fetchData()
            }
          })
          .catch(err => {
            swal(err.response.data.errorMessage, '', 'error')
          })
        // datasets.push(newDataset); // New Dataset
      }
      onHide()
    }

    function handleInputChange(e, field) {
      const newFields = [...bothFields]
      if (field === 'description') newFields[0] = e.target.value
      else newFields[1] = !newFields[1]
      setbothFields(newFields)
      if (datasetDetails !== false) {
        const values = [...datasets]
        values[datasetDetails][field] =
          field === 'description' ? e.target.value : e.target.value === 'on'
        setFields(values)
      } else {
        const tempDataset = { ...newDataset }
        tempDataset[field] =
          field === 'description' ? e.target.value : e.target.value === 'on'
        setnewDataset(tempDataset) // New Dataset
      }
    }

    function handleFileChange(e) {
      /* eslint-disable */
      const newFields = [...bothFields]
      const tempDataset = { ...newDataset }
      if (e.target.files[0].type === 'application/pdf') {
        console.log('File format supported (.pdf)') // Send it to server
        newFields[2] = e.target.files[0]
        tempDataset.dataFile = e.target.files[0]
      } else if (e.target.files[0].type === 'text/csv') {
        console.log('File format supported (.csv)') // Send it to server
        newFields[2] = e.target.files[0]
        tempDataset.dataFile = e.target.files[0]
      } else if (
        e.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        console.log('File format supported (.xlsx)') // Send it to server
        newFields[2] = e.target.files[0]
        tempDataset.dataFile = e.target.files[0]
      } else {
        swal('', 'Update files with the listed format only.', 'error')
      }
      setbothFields(newFields)
      setnewDataset(tempDataset)
      /* eslint-enable */
      // console.log(e.target.files[0]);
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {datasetDetails !== false ? 'Edit Dataset' : 'Add New Dataset'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dynamicInputFields">
            <div
              style={{
                textAlign: 'right',
                width: '30%',
              }}
            >
              {'Dataset Description'}*
            </div>
            <input
              type="text"
              placeholder={'Dataset Description'}
              value={
                datasetDetails === false
                  ? datasetDetails.description
                  : datasets[datasetDetails].description
              }
              onChange={e => handleInputChange(e, 'description')}
              style={{
                width: '60%',
                height: '100%',
                borderRadius: 5,
                borderColor: 'transparent',
                borderBottomColor: 'lightgrey',
              }}
              required
            />
          </div>
          <div className="dynamicInputFields">
            <div
              style={{
                textAlign: 'right',
                width: '30%',
              }}
            >
              {'Uploaded To Platform?'}*
            </div>
            <input
              type="checkbox"
              defaultChecked={
                datasetDetails === false
                  ? datasetDetails.uploaded
                  : datasets[datasetDetails].uploaded
              }
              // placeholder={'Uploaded To Platform?'}
              onChange={e => handleInputChange(e, 'uploaded')}
              style={{
                width: '60%',
                textAlign: 'left',
                // height: '100%',
                borderRadius: 5,
                borderColor: 'transparent',
                borderBottomColor: 'lightgrey',
              }}
              // required
            />
          </div>
          <Form.Group style={{ marginTop: 20 }}>
            <Form.File
              className="position-relative"
              required
              name="file"
              label="UPLOAD DATASET (.xlsx, .csv, .pdf)"
              onChange={e => handleFileChange(e)}
              // isInvalid={!!errors.file}
              // feedback={errors.file}
              // id="validationFormik107"
              feedbackTooltip
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {datasetDetails !== false ? (
            <Button
              // onClick={updateDatase}
              onClick={() => {
                swal('Do you really want to edit this dataset?', {
                  buttons: {
                    catch: {
                      text: 'Yes',
                      value: 'Yes',
                    },
                    No: true,
                  },
                }).then(value => {
                  switch (value) {
                    case 'Yes':
                      updateDatase()
                      break

                    default:
                  }
                })
              }}
              disabled={
                !(
                  bothFields[0] &&
                  // bothFields[1]
                  // &&
                  bothFields[2]
                )
              }
            >
              UPDATE
            </Button>
          ) : (
            <Button
              // onClick={updateDatase}
              onClick={() => {
                swal('Do you really want to add a new dataset?', {
                  buttons: {
                    catch: {
                      text: 'Yes',
                      value: 'Yes',
                    },
                    No: true,
                  },
                }).then(value => {
                  switch (value) {
                    case 'Yes':
                      updateDatase()
                      break

                    default:
                  }
                })
              }}
              disabled={
                !(
                  newDataset.description &&
                  // && newDataset.uploaded
                  bothFields[2]
                )
              }
            >
              ADD
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }

  MyVerticallyCenteredModal.propTypes = {
    onHide: PropTypes.func,
  }

  return (
    <div className="Tab3Container">
      <div className="Tab3EditButtonContainer">
        <div className="Tab3EditButton">
          <div
            className="Tab3EditButtonText"
            // onClick={() => {
            //     swal('Do you really want to add a new dataset?', {
            //       buttons: {
            //         catch: {
            //           text: 'Yes',
            //           value: 'Yes',
            //         },
            //         No: true,
            //       },
            //     })
            //     .then((value) => {
            //       switch (value) {
            //         case 'Yes':
            //           setModalShow(true);
            //           break;

            //         default:
            //       }
            //     });
            // }}
            onClick={() => {
              setModalShow(true)
            }}
          >
            <FileAddFilled />
            Add New Dataset
          </div>
        </div>
      </div>
      <div className="Tab3Table">
        <Table striped hover variant="light" size="lr">
          <thead>
            <tr>
              <th>Date of Reciept</th>
              <th>Dataset Description</th>
              <th>Dataset Details</th>
              <th>Edit</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {datasets.map((item, index) => (
              <tr key={index}>
                {/* <td >{item.dateAdded}</td> */}
                <td>{item.dateAdded}</td>
                <td>{item.description}</td>
                <td>{item.uploaded ? 'Yes' : 'No'}</td>
                <td
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    setModalShow(true)
                    setdatasetDetails(index)
                  }}
                  // onClick={() => {
                  //     swal('Do you really want to edit this dataset?', {
                  //       buttons: {
                  //         catch: {
                  //           text: 'Yes',
                  //           value: 'Yes',
                  //         },
                  //         No: true,
                  //       },
                  //     })
                  //     .then((value) => {
                  //       switch (value) {
                  //         case 'Yes':
                  //           setModalShow(true);
                  //           setdatasetDetails(index);
                  //           break;

                  //         default:
                  //       }
                  //     });
                  // }}
                >
                  <EditFilled />
                </td>
                <td>
                  <a href={item.fileUrl}>View</a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          setdatasetDetails(false)
        }}
      />
    </div>
  )
}

export default Tab3
