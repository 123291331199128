import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  honeyData: [],
  loading: false,
  error: '',
}

export const getSingleHoney = createAsyncThunk('getSingleHoney', async _id => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/honeys/${_id}`
    )
    console.log('res.data from getSingleHoney ==> ', res.data)
    return res.data
  } catch (error) {
    return error.response.data
  }
})

export const resetSingleHoneyData = createAction('resetSingleHoneyData')

const getSingleHoneySlice = createSlice({
  name: 'getSingleHoney',
  initialState,
  reducers: {},

  extraReducers: {
    [getSingleHoney.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getSingleHoney.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.honeyData = payload.singleHoney
        state.message = payload.message
      }
    },

    [getSingleHoney.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
    [resetSingleHoneyData]: state => {
      state.honeyData = []
    },
  },
})

export default getSingleHoneySlice.reducer
