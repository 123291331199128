import React from 'react'
import {
  Col,
  Row,
  Button,
  // Modal
} from 'react-bootstrap'
import moment from 'moment'
import {
  Steps,
  //  Select,
  Icon,
  Collapse,
} from 'antd'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit' // , { Search }
import $ from 'jquery'
import Basic from './LeadProfile/Basic'
import AdvancedDetails from './LeadProfile/AdvancedDetails'

const { Panel } = Collapse
const { Step } = Steps

export default class SingleLeadProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      leadData: this.props.leadData,
      leadProjectsWise: this.props.leadProjectsWise,
      currentStep: this.props.currentStep,
      generatedThrough: this.props.generatedThrough,
      businessIdeasColumns: this.props.businessIdeasColumns,
    }
  }
  // componentDidMount() {

  // }

  render() {
    return (
      <>
        {' '}
        {this.state.leadData.leadStrength &&
        this.state.leadData.strengthRating ? (
          <div className="lead-current-rating d-flex">
            <Col>
              {this.state.leadData.leadStrength.respondedBy ? (
                this.state.leadData.leadStrength.respondedBy.fullName ? (
                  <h3>
                    Responded By:{' '}
                    <span>
                      {this.state.leadData.leadStrength.respondedBy.fullName}
                    </span>
                  </h3>
                ) : null
              ) : null}
              <h3>
                Responded At:{' '}
                <span>
                  {moment(this.state.leadData.leadStrength.respondedAt).format(
                    'LLL'
                  )}
                </span>
              </h3>
            </Col>
            <Col className="score">
              <h3>
                Score:{' '}
                <span>
                  {this.state.leadData.leadStrength.totalPoints + '%'}
                </span>
              </h3>
            </Col>
          </div>
        ) : null}
        <Basic leadData={this.state.leadData} />
        {this.state.leadProjectsWise &&
        this.state.leadProjectsWise.length > 1 ? (
          <Collapse
            accordion
            defaultActiveKey={this.state.leadProjectsWise[0].projectId._id}
          >
            {this.state.leadProjectsWise.map(project =>
              project.projectId ? (
                <Panel
                  header={project.projectId.name}
                  key={project.projectId._id}
                >
                  <AdvancedDetails
                    leadData={project}
                    projectId={project.projectId}
                    currentStep={this.state.currentStep}
                    generatedThrough={this.state.generatedThrough}
                    businessIdeasColumns={this.state.businessIdeasColumns}
                  />
                </Panel>
              ) : null
            )}
          </Collapse>
        ) : (
          <AdvancedDetails
            leadData={this.state.leadData}
            currentStep={this.state.currentStep}
            generatedThrough={this.state.generatedThrough}
            businessIdeasColumns={this.state.businessIdeasColumns}
          />
        )}
      </>
    )
  }
}
