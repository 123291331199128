import React, { useState } from 'react'
// import { Row, Col, Container, Button } from "react-bootstrap";
// import Label from "../../components/FormElements/label";
// import Input from "../../components/FormElements/Input";
// import { useForm } from "../../hooks/form-hook";
import { Select } from 'antd'
import { toast } from 'react-toastify'
import { useHttpHook } from '../../hooks/http-hook'
const readCookie = require('../../../cookie.js').readCookie

const {
  Option,
  // OptGroup
} = Select

const Commentlayout = props => {
  const centerId = props.match.params.centerId
  const [commentstate, setCommentstate] = useState({
    followup: { value: '', unit: 'days' },
    files: null,
    body: '',
    type: '',
  })
  // const [comment, setComment] = useState();
  const [saving, setSaving] = useState(false)
  const {
    // isLoading,
    error,
    sendRequest,
    clearError,
  } = useHttpHook()

  const submitHandler = async event => {
    //  description = event.target.value;
    if (
      commentstate.type &&
      commentstate.followup.unit &&
      commentstate.followup.value
    ) {
      setSaving(true)
      event.preventDefault()
      try {
        await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/center/comments',
          'POST',
          JSON.stringify({
            followup: commentstate.followup,
            type: commentstate.type,
            body: commentstate.body,
            files: commentstate.files,
            centerId: centerId,
          }),
          {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          }
        )
        console.log('done')
        alert()
        commentstate.files = null
        commentstate.type = ''
        commentstate.followup.value = ''
        commentstate.body = ''
      } catch (err) {
        alert(err + ' ' + error)
      }
    } else {
      toast.error('please fill the required items')
    }
    setSaving(false)
  }

  return (
    <React.Fragment>
      {clearError}
      <div className="comment-form" style={{ marginTop: '12rem' }}>
        <div className="form d-flex">
          <div className="form-group" style={{ width: '200px' }}>
            <label className="control-label is-imp">Status</label>
            <Select
              style={{ width: '200px' }}
              placeholder="Select Status"
              value={commentstate.type}
              onChange={e =>
                setCommentstate({
                  ...commentstate,
                  type: e,
                })
              }
            >
              <Option value="Called & Received" title="Called & Received">
                Called & Received
              </Option>
              <Option
                value="Called but No Response"
                title="Called but No Response"
              >
                Called but No Response
              </Option>
              <Option value="Visited" title="Visited">
                Visited
              </Option>
              <Option value="General Notes" title="General Notes">
                General Notes
              </Option>
            </Select>
          </div>
          <div className="form-group">
            <label className="control-label">Upload File(s)</label>
            <input
              className="form-control file-input"
              type="file"
              multiple
              onChange={e =>
                setCommentstate({
                  ...commentstate,
                  files: e,
                })
              }
            />
          </div>
          <div className="form-group">
            <label className="control-label is-imp">Follow Up</label>
            <div className="follow-up-container">
              <input
                className="form-control"
                type="number"
                min={1}
                max={12}
                value={commentstate.followup.value}
                onChange={e =>
                  setCommentstate({
                    ...commentstate,
                    followup: {
                      ...commentstate.followup,
                      value: e.target.value,
                    },
                  })
                }
              />
              <Select
                style={{ width: '50%' }}
                placeholder="Select Unit"
                value={commentstate.followup.unit}
                onChange={e =>
                  setCommentstate({
                    ...commentstate,
                    followup: { ...commentstate.followup, unit: e },
                  })
                }
              >
                <Option value="days" title="Day(s)">
                  Day(s)
                </Option>
                <Option value="weeks" title="Week(s)">
                  Week(s)
                </Option>
                <Option value="months" title="Month(s)">
                  Month(s)
                </Option>
                <Option value="years" title="Year(s)">
                  Year(s)
                </Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label">Description</label>
          <textarea
            className="comment-input"
            placeholder="Description"
            value={commentstate.body}
            onChange={e =>
              setCommentstate({
                ...commentstate,
                body: e.target.value,
              })
            }
          ></textarea>
        </div>
        <button
          className="btn btn-success react-bs-table-add-btn post-comment"
          onClick={submitHandler}
          disabled={saving}
        >
          POST
        </button>
      </div>
    </React.Fragment>
  )
}

export default Commentlayout
/*

import moment from 'moment';
import { Select } from 'antd';
import TimeAgo from 'react-timeago';
import Linkify from 'react-linkify';
import React, {Component} from 'react';
import { toast } from 'react-toastify';
import {Col, Row, Button, Modal} from 'react-bootstrap';

const readCookie = require('../../../cookie.js').readCookie;

const { Option, OptGroup } = Select;

class Comment extends Component {
	formatter(x, y, z) {
		if(y === 'second') return 'few seconds ago';
		else if (x === 1) return x + ' ' + y + ' ' + z;
		else return x + ' ' + y + 's ' + z;
	}

	render() {
		return (
			<div className="comment">
				<div className="comment-user-image-container">
					{this.props.comment.createdBy.fullName ? (
						<img className="comment-user" alt={this.props.comment.createdBy.fullName} src={this.props.comment.createdBy.profilePicture} onError={(event) => event.target.setAttribute("src","/images/user.png")} title={this.props.comment.createdBy.fullName} />
					) : (
						<img className="comment-user" alt={this.props.comment.createdBy.ngoName} src={this.props.comment.createdBy.logo} onError={(event) => event.target.setAttribute("src","/images/ngo.png")} title={this.props.comment.createdBy.ngoName} />
					)}
				</div>
				<div className="dialogbox">
					<div className="body">
						<span className="tip tip-left"></span>
						<div className="type-followup-container">
							<span className="type">{this.props.comment.type}</span>
							{this.props.comment.followUp ? (
								<span className="follow-up"><b>Follow Up Date:</b> {moment(this.props.comment.followUp.date).format('MMM DD, YYYY')}</span>
							) : (null)}
						</div>
						<div className="comment-content">
							<div className="message">
								<Linkify properties={{target: '_blank'}}>
									<span>{this.props.comment.body}</span>
								</Linkify>
								{this.props.comment.files && this.props.comment.files.length ? (
									<div className="files">
										{this.props.comment.files.map((file, index) => {
											if(index) {
												return (
													<span>, <a key={index} className="file" href={file} target="_blank">{index ? 'File ' + (index + 1) : 'File'}</a></span>
												)
											} else {
												return (
													<a key={index} className="file" href={file} target="_blank">{index ? 'File ' + (index + 1) : 'File'}</a>
												)
											}
										})}
									</div>
								) : (null)}
							</div>
							<div className={this.props.comment.body ? "timestamp" : "timestamp timestamp-flex"}>
								{this.props.comment.createdBy.fullName ? (
									<div>{this.props.comment.createdBy.fullName}</div>
								) : (
									this.props.comment.createdBy.ngoName ? (
										<div>{this.props.comment.createdBy.ngoName}</div>
									) : (null)
								)}
								<span><TimeAgo date={moment(this.props.comment.createdAt).toDate()} formatter={this.formatter} /></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class CommentList extends Component {
	render() {
		return (
			<div className="comment-list">
				{this.props.comments.map(function(comment, index) {
					return (
						<Comment key={index} comment={comment} />
					)
				})}
			</div>
		);
	}
}

class CommentForm extends Component {
	render() {
		return (
			<div className="comment-form">
				<div className="form d-flex">
					<div className="form-group" style={{width: '200px'}}>
						<label className="control-label is-imp">Status</label>
						<Select style={{width: '200px'}} placeholder="Select Status" value={this.props.comment.type} onChange={this.props.onCommentChange.bind(this, 'type')}>
							<Option value="Called & Received" title="Called & Received">Called & Received</Option>
							<Option value="Called but No Response" title="Called but No Response">Called but No Response</Option>
							<Option value="Visited" title="Visited">Visited</Option>
							<Option value="General Notes" title="General Notes">General Notes</Option>
						</Select>
					</div>
					<div className="form-group">
						<label className="control-label">Upload File(s)</label>
						<input className="form-control file-input" type="file" multiple onChange={this.props.onCommentChange.bind(this, 'files')} />
					</div>
					<div className="form-group">
						<label className="control-label is-imp">Follow Up</label>
						<div className="follow-up-container">
							<input className="form-control" type="number" min={1} max={12} value={this.props.comment.followUp.value} onChange={this.props.onCommentChange.bind(this, 'followUpValue')} />
							<Select style={{width: '50%'}} placeholder="Select Unit" value={this.props.comment.followUp.unit} onChange={this.props.onCommentChange.bind(this, 'followUpUnit')}>
								<Option value="days" title="Day(s)">Day(s)</Option>
								<Option value="weeks" title="Week(s)">Week(s)</Option>
								<Option value="months" title="Month(s)">Month(s)</Option>
								<Option value="years" title="Year(s)">Year(s)</Option>
							</Select>
						</div>
					</div>
				</div>
				<div className="form-group">
					<label className="control-label">Description</label>
					<textarea className="comment-input" placeholder="Description" value={this.props.comment.body} onChange={this.props.onCommentChange.bind(this, 'body')}></textarea>
				</div>
				<button className="btn btn-success react-bs-table-add-btn post-comment" onClick={this.props.onCommentSubmit} disabled={this.props.saving}>POST</button>
			</div>
		);
	}
}

export default class Commentlayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			addComment: false,
			comments: this.props.comments,
			comment: {
				body: '', type: '', files: null,
				followUp: { value: '', unit: 'days' }
			}
		};
	}

	handleCommentSubmit() {
		let comment = this.state.comment;

		if(comment.type && comment.followUp.value && comment.followUp.unit) {
			this.setState({ saving: true });
			let formData = new FormData();
			formData.append('body', comment.body);
			formData.append('type', comment.type);
			formData.append('followUp', JSON.stringify(comment.followUp));
			if(comment.files) {
				for(let x = 0; x < comment.files.length; x++) {
				  formData.append('files', comment.files[x]);
				}
			}

			fetch(process.env.REACT_APP_API_URL + '/api/v1/swati-lead-comments/' + this.props.leadId, {
				method: 'POST',
				headers: {
					'x-auth-token': JSON.parse(readCookie('access_token'))
				},
				body: formData
			}).then(data => data.json())
			.then(data => {
				if(data.status) {
					this.props.newCommentAdded(comment);
					this.setState({ comments: data.comments, saving: false });
					this.toggleCommentForm();
				} else {
					this.notifyError(data.errorMsg);
					this.setState({ saving: false });
				}
			});
		} else {
			this.setState({ saving: false });
			this.notifyError('Please fill all the required items');
		}
	}

	notifySuccess = (text) => toast.success(text);
	notifyError = (text) => toast.error(text);

	commentChange = (type, value) => {
		let comment = this.state.comment;

		if(value.target) {
			if(value.target.files) value = value.target.files;
			else value = value.target.value;
		}

		if(type === 'followUpValue') comment['followUp']['value'] = value;
		else if(type === 'followUpUnit') comment['followUp']['unit'] = value;
		else comment[type] = value;

		this.setState({ comment });
	}

	toggleCommentForm = () => {
		this.setState({
			addComment: !this.state.addComment,
			comment: { body: '', type: '', files: null, followUp: { value: '', unit: '' } }
		});
	}

	render() {
		return (
			<Col md={12} className="tab-container">
				{!this.state.comments.length ? (
					<div className="no-comments-container">
						<span>No Comments yet</span>
					</div>
				) : (null)}
				<div className="comment-box">
					<CommentList comments={this.state.comments} />
					{this.state.addComment ? (
						<CommentForm comment={this.state.comment} onCommentChange={this.commentChange} onCommentSubmit={this.handleCommentSubmit.bind(this)} saving={this.state.saving} />
					) : (
						<Col md={12} className="text-center">
							<button className="btn btn-success mt15 add-comment-btn" onClick={this.toggleCommentForm}>Add Comment</button>
						</Col>
					)}
				</div>
			</Col>
		);
	}
}
*/
