import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal, Row, Col, Button, Carousel } from 'react-bootstrap'
import moment from 'moment'
import { readCookie } from '../../cookie'
import { ConfigProvider, DatePicker, Select, Pagination } from 'antd'
import enUS from 'antd/es/locale/en_US'
import { fetchStates } from '../shared/StateDistrictData'

const cookies = new Cookies()
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker

export const GroupMeetings = () => {
  const { projectId } = useParams()
  const [allDistricts, setallDistricts] = useState([])
  const [filterData, setfilterData] = useState({
    state: [],
    district: [],
    toDate: null,
    fromDate: null,
  })
  const [allStates, setallStates] = useState([])
  const [projectInfo, setprojectInfo] = useState(false)
  const [groupMeetings, setgroupMeetings] = useState(false)
  const [showInfoModel, setshowInfoModel] = useState(false)
  const [showInfoVisit, setshowInfoVisit] = useState(false)
  const [loading, setloading] = useState(true)
  const [allData, setallData] = useState([])
  const [sizePerPage, setsizePerPage] = useState(10)
  const [page, setpage] = useState(1)

  const fetchProjectDetail = () => {
    const currentToken = cookies.get('access_token')

    const api = `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/${projectId}`
    // console.log(api);
    fetch(`${api}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${currentToken}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data.project,data);
        setprojectInfo(data.project)
      })
      .catch(error => {
        console.error('Error fetching districts:', error)
      })
  }

  const fetchState = async () => {
    const data = fetchStates()
    // console.log(data);
    setallStates(data)
  }

  const resetFilter = () => {
    let filterData = {
      state: [],
      district: [],
      fromDate: null,
      toDate: null,
    }
    // console.log(page,sizePerPage);
    setfilterData(filterData)
    fetchData(page, sizePerPage, filterData)
  }

  useEffect(() => {
    // console.log(window.innerWidth);
    fetchData()
    fetchProjectDetail()
    fetchState()
  }, [])

  const handleShowModal = idx => {
    setshowInfoModel(true)
    setshowInfoVisit(groupMeetings[idx])
  }

  const changeFilterHandler = (type, e) => {
    let filterDataCopy = { ...filterData }
    // console.log(e);
    if (type === 'district') {
      filterDataCopy['district'] = e
      if (e.length === 0) {
        filterDataCopy['state'] = []
      }
    } else if (type === 'fromToDate') {
      filterDataCopy['fromDate'] = e[0]
      filterDataCopy['toDate'] = e[1]
    } else if (type === 'state') {
      filterDataCopy['state'] = e
      if (e.length === 0) {
        filterDataCopy['district'] = []
      }
    } else {
      filterDataCopy[type] = e
    }
    setfilterData(filterDataCopy)
    submitFilter(filterDataCopy)
  }

  const changeStateFilter = e => {
    let filterDataCopy = { ...filterData }
    filterDataCopy['state'] = e
    // console.log(e);
    if (e.length !== 0) {
      filterDataCopy['district'] = []
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/districts?states=' +
          e.join(','),
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(response => {
          console.log('API call successful')
          return response.json()
        })
        .then(data => {
          console.log('Data received:', data)
          if (data.status) {
            setallDistricts(data.districts)
          }
        })
        .catch(error => {
          console.error('Error fetching districts:', error)
        })
    }
    // console.log(filterDataCopy);
    setfilterData(filterDataCopy)
    submitFilter(filterDataCopy)
  }

  const submitFilter = filterData => {
    fetchData(page, sizePerPage, filterData)
  }

  const searchFilter = (input, option) => {
    input = input.toLowerCase()

    // Check if option has children and handle both string and array cases
    const children = option.props.children

    // If children is an array, check each child
    if (Array.isArray(children)) {
      return children.some(child => {
        return typeof child === 'string' && child.toLowerCase().includes(input)
      })
    }

    // If children is a string, perform the check directly
    if (typeof children === 'string') {
      return children.toLowerCase().includes(input)
    }

    // Return false if children is neither a string nor an array
    return false
  }

  const paginationChange = (page, sizePerPage) => {
    setpage(page)
    setsizePerPage(sizePerPage)
    fetchData(page, sizePerPage)
  }

  const onShowSizeChange = (current, sizePerPage) => {
    setsizePerPage(sizePerPage)
    setpage(1)
    fetchData(1, sizePerPage)
  }

  const fetchData = (page, sizePerPage, filterdata) => {
    setloading(true)
    let filterd = filterdata || filterData
    const body = {
      fromDate: filterd.fromDate ? new Date(filterd.fromDate._d) : null,
      toDate: filterd.toDate ? new Date(filterd.toDate._d) : null,
      state: filterd.state,
      district: filterd.district,
      page,
      sizePerPage,
    }
    // console.log(body,process.env.REACT_APP_API_APP_FIELD_URL);
    fetch(
      process.env.REACT_APP_API_APP_FIELD_URL +
        '/group-meetings/web-app/project/' +
        projectId,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify(body),
      }
    )
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        setgroupMeetings(data.groupMeetings)
        setpage(data.page)
        setsizePerPage(data.sizePerPage)
        if (data.status) {
          // setgroupMeetings(data.groupMeetings);
        }
      })
      .catch(error => console.error('Error fetching Group Meetings:', error))
    setloading(false)
  }

  return (
    <>
      <div className="page-container">
        {loading ? (
          <div
            style={{
              width: '100%',
              justifyContent: 'center',
              display: 'flex',
              marginRight: 100,
            }}
          >
            <h2>Loading ...</h2>
          </div>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 100,
              }}
            >
              <h2>
                Group Meetings{' '}
                {projectInfo ? ' of Project - ' + projectInfo.name : null}
              </h2>
              <h5>
                Targeted State {'->'}{' '}
                {projectInfo ? projectInfo.targetedState : null}
              </h5>
              <h5>
                Targeted Districts {'->'}{' '}
                {projectInfo ? projectInfo.targetedDistricts.join(', ') : null}
              </h5>
            </div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'start',
                gap: 25,
                marginBottom: 40,
              }}
              className="filters-container"
            >
              <div className="filter form-group">
                <label className="control-label">{'From - To date: '}</label>
                <ConfigProvider locale={enUS}>
                  <RangePicker
                    locale={enUS}
                    style={{ width: '300px' }}
                    format="DD/MM/YYYY"
                    value={[filterData.fromDate, filterData.toDate]}
                    type="date"
                    onChange={changeFilterHandler.bind(this, 'fromToDate')}
                  />
                </ConfigProvider>
              </div>
              <div className="filter form-group">
                <label className="control-label">{'State: '}</label>
                <Select
                  mode="multiple"
                  showSearch
                  style={{ width: '150px' }}
                  value={filterData.state}
                  onChange={changeStateFilter}
                  filterOption={(input, option) => searchFilter(input, option)}
                >
                  <OptGroup label="Focus States">
                    {allStates.map((state, stateIndex) => {
                      if (state.inFocusRegion) {
                        return (
                          <Option key={stateIndex} value={state.name}>
                            {state.name}
                          </Option>
                        )
                      } else return null
                    })}
                  </OptGroup>
                  {/*<OptGroup label="Not Focussed States">
									{allStates.map((state, stateIndex) => {
										if(!state.inFocusRegion) {
											return (
												<Option key={stateIndex} value={state.name}>{state.name}</Option>
											)
										} else return null;
									})}
								</OptGroup>*/}
                </Select>
              </div>
              <div className="filter form-group">
                <label className="control-label">{'District: '}</label>
                <Select
                  mode="multiple"
                  showSearch
                  style={{ width: '160px' }}
                  value={filterData.district}
                  onChange={changeFilterHandler.bind(this, 'district')}
                  filterOption={(input, option) => searchFilter(input, option)}
                >
                  {filterData.state
                    ? allDistricts.map((district, districtIndex) => {
                        let districtName = district.name
                        if (district.state)
                          districtName += ' (' + district.state + ')'
                        return (
                          <Option value={district.name} key={districtIndex}>
                            {districtName}
                          </Option>
                        )
                      })
                    : null}
                </Select>
              </div>
              <div className="filter form-group">
                <button className="btn reset-filters" onClick={resetFilter}>
                  Reset
                </button>
              </div>
            </div>

            <div
              style={{
                marginTop: 20,
                width: '100%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 10,
              }}
            >
              {groupMeetings && groupMeetings.length > 0 ? (
                groupMeetings.map((item, idx) => (
                  <>
                    <div
                      key={idx}
                      style={{
                        width:
                          window.innerWidth > 1300
                            ? 'calc(25% - 20px)'
                            : window.innerWidth > 900
                              ? 'calc(33% - 20px)'
                              : window.innerWidth > 500
                                ? 'calc(50% - 20px)'
                                : 'calc(100% - 20px)',
                        padding: 10,
                        border: '1px solid #efefef',
                        boxShadow: '1px 1px 1px #ddd',
                      }}
                    >
                      <Carousel
                        controls={false}
                        interval={3000}
                        indicators={false}
                      >
                        {item.photos.map((image, imageIndex) => {
                          return (
                            <Carousel.Item key={imageIndex}>
                              <img
                                style={{
                                  width: '100%',
                                  borderRadius: 5,
                                  height: 200,
                                  objectFit: 'contain',
                                }}
                                src={image}
                                alt="img"
                              />
                            </Carousel.Item>
                          )
                        })}
                      </Carousel>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          gap: 5,
                          margin: '10px 5px',
                        }}
                      >
                        <div className="activity-date-container">
                          <span className="label">Group Meeting Date: </span>
                          <span className="value">
                            {moment(item.date).format('DD/MM/YYYY')}
                          </span>
                          <div className="activity-date-container">
                            <span className="label">Total Attendences: </span>
                            <span className="value">{item.attendees}</span>
                          </div>
                          <div className="activity-date-container">
                            <span className="label">Attendee Type: </span>
                            <span className="value">{item.attendee_type}</span>
                          </div>
                        </div>
                        <div className="activity-date-container">
                          <span className="label">State: </span>
                          <span className="value">{item.location.state}</span>
                        </div>
                        <div className="activity-date-container">
                          <span className="label">District: </span>
                          <span className="value">
                            {item.location.district}
                          </span>
                        </div>
                        <div className="activity-date-container">
                          <span className="label">Block: </span>
                          <span className="value">{item.location.block}</span>
                        </div>
                        <div className="activity-date-container">
                          <span className="label">Created By: </span>
                          <span className="value">{item.facilitator.name}</span>
                        </div>
                        {/* <div className="description">
                      <span className="label">
                        Meeting Notes And Outcomes:{" "}
                      </span>
                      {item.notes}
                    </div> */}
                        {/* <div className="description">
                    <span className="label"> Next Steps: </span>
                    {item.next_steps}
                  </div> */}
                      </div>
                      <div
                        style={{
                          width: '100%',
                          color: '#fff',
                          cursor: 'pointer',
                          textAlign: 'center',
                          background: `var(--themeColor)`,
                        }}
                        onClick={e => handleShowModal(idx)}
                      >
                        See More
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    marginRight: 100,
                  }}
                >
                  <h2>No Group Meetings Data is Available</h2>
                </div>
              )}
            </div>
          </>
        )}
        {groupMeetings.length > 0 && (
          <div
            style={{ paddingTop: 40 }}
            className="pagination-container text-center mt15"
          >
            <Pagination
              showSizeChanger
              onChange={paginationChange}
              onShowSizeChange={onShowSizeChange}
              current={page}
              total={groupMeetings.length}
              pageSize={sizePerPage}
              pageSizeOptions={['5', '10', '15', '20']}
            />
          </div>
        )}
      </div>

      {showInfoModel ? (
        <Modal
          centered
          className="show-activity-modal"
          show={showInfoModel}
          onHide={e => setshowInfoModel(false)}
          size="lg"
          aria-labelledby="contained-modal-title-lg"
        >
          <Modal.Body centered className="show-activity-modal-body">
            <Row>
              <Col md={5}>
                <div className="activity-img-container">
                  {showInfoVisit.photos.length > 0 ? (
                    <Carousel
                      controls={false}
                      interval={3000}
                      indicators={false}
                    >
                      {showInfoVisit.photos.map((image, imageIndex) => {
                        return (
                          <Carousel.Item key={imageIndex}>
                            <img alt="" className="d-block" src={image} />
                          </Carousel.Item>
                        )
                      })}
                    </Carousel>
                  ) : (
                    <div>
                      <img
                        alt=""
                        className="d-block w-100"
                        src="/images/image-upload.png"
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={7} className="selected-activity-details">
                <div className="activity-date-container">
                  <span className="label">Group Meeting Date: </span>
                  <span className="value">
                    {moment(showInfoVisit.date).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Total Attendences: </span>
                  <span className="value">{showInfoVisit.attendees}</span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Total Women Attendences: </span>
                  <span className="value">{showInfoVisit.no_of_women}</span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Attendee Type: </span>
                  <span className="value">{showInfoVisit.attendee_type}</span>
                </div>
                <div className="activity-date-container">
                  <span className="label">State: </span>
                  <span className="value">{showInfoVisit.location.state}</span>
                </div>
                <div className="activity-date-container">
                  <span className="label">District: </span>
                  <span className="value">
                    {showInfoVisit.location.district}
                  </span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Block: </span>
                  <span className="value">{showInfoVisit.location.block}</span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Village: </span>
                  <span className="value">{showInfoVisit.village_name}</span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Community Anchor Name: </span>
                  <span className="value">
                    {showInfoVisit.community_anchor.name}
                  </span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Community Anchor Organization: </span>
                  <span className="value">
                    {showInfoVisit.community_anchor.organization}
                  </span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Community Anchor Phone Number: </span>
                  <span className="value">
                    {showInfoVisit.community_anchor.phone_number}
                  </span>
                </div>
                <div className="activity-date-container">
                  <span className="label">Facilitator: </span>
                  <span className="value">
                    {showInfoVisit.facilitator.name}
                  </span>
                </div>
                <div className="description">
                  <span className="label">Meeting Notes And Outcomes: </span>
                  {showInfoVisit.notes}
                </div>
                <div className="description">
                  <span className="label"> Next Steps: </span>
                  {showInfoVisit.next_steps}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col md={12} className="d-flex justify-content-between">
              <div className="d-flex">
                <Button
                  className="mr10"
                  variant="warning"
                  disabled
                  onClick={e => console.log('asdsa')}
                >
                  Edit
                </Button>
                <Button disabled variant="danger">
                  Delete
                </Button>
              </div>
              <Button
                variant="secondary"
                onClick={e => setshowInfoModel(false)}
              >
                Close
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
