import React, { Component } from 'react'
import { Col, Row, Button, Modal } from 'react-bootstrap'

const readCookie = require('../cookie.js').readCookie

export default class ViewSchemeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewScheme: this.props.viewScheme,
    }
  }

  componentDidMount = () => {
    if (!this.props.viewScheme.hasOwnProperty('interestRate')) {
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/schemes/' +
          this.props.viewScheme._id,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ viewScheme: data.scheme })
          }
        })
    }
  }

  render() {
    if (this.state.viewScheme.hasOwnProperty('interestRate')) {
      return (
        <Modal
          className="view-scheme-modal"
          size="lg"
          show={this.state.viewScheme ? true : false}
          onHide={this.props.hideViewScheme}
        >
          <Modal.Header>
            <Modal.Title className="modal-header-custom">
              {this.state.viewScheme.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mb20">
            <Row>
              <Col md={6}>
                <label className="control-label">Interest Rate (Range)</label>
                <div className="value">
                  {this.state.viewScheme.interestRate}
                </div>
              </Col>
              <Col md={6}>
                <label className="control-label">Loan Tenure (in months)</label>
                <div className="value">{this.state.viewScheme.loanTenure}</div>
              </Col>
              <Col md={6}>
                <label className="control-label">Moratorium (in months)</label>
                <div className="value">{this.state.viewScheme.moratorium}</div>
              </Col>
              <Col md={6}>
                <label className="control-label">Processing Fee</label>
                <div className="value">
                  {this.state.viewScheme.processingFee}
                </div>
              </Col>
              <Col md={6}>
                <label className="control-label">Margin (%)</label>
                <div className="value">{this.state.viewScheme.margin}</div>
              </Col>
              <Col md={6}>
                <label className="control-label">
                  Can make multiple applications
                </label>
                <div className="value">
                  {this.state.viewScheme.multipleApplicationsAllowed
                    ? 'Yes'
                    : 'No'}
                </div>
              </Col>
              <Col md={12}>
                <label className="control-label">Subsidy</label>
                <div className="value">{this.state.viewScheme.subsidy}</div>
              </Col>
              <Col md={4}>
                <label className="control-label">Geography</label>
                <div className="value">
                  {this.state.viewScheme.geography.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Business Stage</label>
                <div className="value">
                  {this.state.viewScheme.businessStage.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Business Type</label>
                <div className="value">
                  {this.state.viewScheme.businessType.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Region</label>
                <div className="value">
                  {this.state.viewScheme.region.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Min loan limit (INR)</label>
                <div className="value">
                  {this.state.viewScheme.minLoanLimit}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Max loan limit (INR)</label>
                <div className="value">
                  {this.state.viewScheme.maxLoanLimit}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Requires collateral</label>
                <div className="value">
                  {this.state.viewScheme.requiresCollateral ? 'Yes' : 'No'}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">
                  Min age limit (in years)
                </label>
                <div className="value">{this.state.viewScheme.minAgeLimit}</div>
              </Col>
              <Col md={4}>
                <label className="control-label">
                  Max age limit (in years)
                </label>
                <div className="value">{this.state.viewScheme.maxAgeLimit}</div>
              </Col>
              <Col md={4}>
                <label className="control-label">Preferred Businesses</label>
                <div className="value">
                  {this.state.viewScheme.preferredBusinesses.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Preferred Category</label>
                <div className="value">
                  {this.state.viewScheme.preferredCategory.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Preferred Gender</label>
                <div className="value">
                  {this.state.viewScheme.preferredGender.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Minimum Qualification</label>
                <div className="value">
                  {this.state.viewScheme.minimumQualification}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">For PWD</label>
                <div className="value">
                  {this.state.viewScheme.forPWD.join(', ')}
                </div>
              </Col>
              <Col md={4}>
                <label className="control-label">Bank specificity</label>
                <div className="value">
                  {this.state.viewScheme.bankSpecificity.join(', ')}
                </div>
              </Col>
              <Col md={12}>
                <label className="control-label">Documents required</label>
                <div className="value">
                  {this.state.viewScheme.documentsRequired.join(', ')}
                </div>
              </Col>
              <Col md={12}>
                <label className="control-label">Good to have documents</label>
                <div className="value">
                  {this.state.viewScheme.goodToHaveDocuments.join(', ')}
                </div>
              </Col>
              <Col md={12}>
                <label className="control-label">How to apply</label>
                <div className="value">{this.state.viewScheme.howToApply}</div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="footer-center">
            <Button
              className="btn btn-danger brand-color-background"
              onClick={this.props.hideViewScheme}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )
    } else return null
  }
}
