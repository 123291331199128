import React, {
  // useContext,
  useEffect,
  useState,
} from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Tooltip, Select } from 'antd'
import './projects-landing-page.css'
// import ProjectContext from "../context/ProjectContext";
import { createCookie, eraseCookie, readCookie } from '../cookie'
import moment from 'moment'
import ToolkitProvider from 'react-bootstrap-table2-toolkit' // , { Search }
import BootstrapTable from 'react-bootstrap-table-next'
import { Multiselect } from 'multiselect-react-dropdown'
import { toast } from 'react-toastify'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const { Option } = Select

const ProjectsLandingPage = ({ userData, toggleSidebar, sidebarMinimized }) => {
  // const TOLL_FREE_NUMBER_REGEX = /^(\+?1)?(8(00|33|44|55|66|77|88)[2-9]\d{6})$/;
  // const PHONE_NUMBER_REGEX = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  const TOLL_FREE_NUMBER_REGEX = /^(\+\d{1,3}[- ]?)?(\d{10}|\d{11})$/
  const PHONE_NUMBER_REGEX = /^(\+\d{1,3}[- ]?)?(\d{10}|\d{11})$/
  const resetAccessToken = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/user/reset-access-token/no-pid-auth`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.error) {
          notifyError(`Couldn't reset access token. Please try again!`)
        }
        // fetchProjects();
        // notifySuccess(data.message);
        // resetModal();
        else {
          createCookie('access_token', JSON.stringify(data['access_token']), 30)
        }
      })
      .catch(error => {
        notifyError(`Couldn't reset access token. Please try again!`)
      })
  }
  useEffect(() => {
    resetAccessToken()
    eraseCookie('project_id')
    eraseCookie('currentProjectDetails')
  }, [])

  const isASuperUser = ['Super User'].includes(userData.accountType)
  const INITIAL_PROJECT_DETAILS = {
    name: '',
    fromDate: '',
    toDate: '',
    userTypes: [],
    goals: '',
    funder: '',
    status: '',
    displayTitle: '',
    themeColor: '#EF5A20',
    ivrsTollFreeNumber: '',
    ivrsVanityNumber: '',
    // "districts": [],
    // "states": []
  }

  const notifyError = text => toast.error(text)
  const notifySuccess = text => toast.success(text)

  const [availableStates, setAvailableStates] = useState([])
  const [
    availableDistricts,
    // setAvailableDistricts
  ] = useState([])
  const [userTypes, setUserTypes] = useState([])

  // const [
  //   selectedProject,
  //   setSelectedProject] = useState('');
  const [projects, setProjects] = useState([])
  // const {projectsHandler} = useContext(ProjectContext);
  const [showProjectModal, setShowModal] = useState(false)
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null)
  const handleChange = (e, attrName) => {
    let valueToSet = selectedProjectDetails
    if (e.target) {
      const { name, value } = e.target
      valueToSet = { ...valueToSet, [name]: value }
    } else if (e) {
      valueToSet = { ...valueToSet, [attrName]: e }
    }
    setSelectedProjectDetails(valueToSet)
  }
  const resetModal = () => {
    setShowModal(false)
    setSelectedProjectDetails(null)
  }
  const addProject = () => {
    setShowModal(true)
    setSelectedProjectDetails(INITIAL_PROJECT_DETAILS)
  }

  const editProject = projectDetails => {
    setShowModal(true)
    setSelectedProjectDetails({ ...INITIAL_PROJECT_DETAILS, ...projectDetails })
  }

  const currentToken = cookies.get('access_token')

  const saveProjectDetails = () => {
    let error = false
    const {
      _id,
      name,
      fromDate,
      toDate,
      userTypes,
      goals,
      funder,
      status,
      themeColor,
      displayTitle,
      ivrsTollFreeNumber,
      ivrsVanityNumber,
    } = selectedProjectDetails

    if (
      !name ||
      !fromDate ||
      !toDate ||
      !fromDate ||
      !(userTypes || []).length ||
      !goals ||
      !funder ||
      !status ||
      !themeColor ||
      !displayTitle
    )
      error = 'All fields are mandatory!'
    else if (fromDate > toDate) {
      error = "End date can't be before start date!"
    } else if (!userTypes.length) {
      error = 'Select at least one user type!'
    } else if (!userTypes.length) {
      error = 'Select at least one user type!'
    } else if (!userTypes.length) {
      error = 'Select at least one user type!'
    } else if (
      !TOLL_FREE_NUMBER_REGEX.test(ivrsTollFreeNumber) &&
      !PHONE_NUMBER_REGEX.test(ivrsTollFreeNumber)
    ) {
      error = 'Enter the valid IVRS toll-free number!'
    } else if (!PHONE_NUMBER_REGEX.test(ivrsVanityNumber)) {
      error = 'Enter the valid IVRS vanity number!'
    }

    if (error) {
      notifyError(error)
    } else {
      const dataToSend = {
        ...selectedProjectDetails,
        userTypes: userTypes.map(x => x.id || x._id),
      }
      const API_CALL_DETAILS = _id
        ? {
            URL: `${process.env.REACT_APP_API_URL}/api/v1/project/${_id}/no-pid-auth`,
            METHOD: 'PUT',
          }
        : {
            URL: `${process.env.REACT_APP_API_URL}/api/v1/humblebee/projects`,
            METHOD: 'POST',
          }
      const { URL, METHOD } = API_CALL_DETAILS
      fetch(URL, {
        method: METHOD,
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
          Authorization: `Bearer ${currentToken}`,
        },
        body: JSON.stringify(dataToSend),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.error) {
            notifyError(
              `Couldn't ${_id ? 'update' : 'save'} the project details. Please try again!`
            )
          }
          fetchProjects()
          notifySuccess(data.message)
          resetModal()
        })
        .catch(error => {
          notifyError(
            `Couldn't ${_id ? 'update' : 'save'} the project details. Please try again!`
          )
        })
    }
  }

  const fetchStates = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/location/states/no-pid-auth',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          // console.log(data)
          setAvailableStates(data.data.states)
        }
      })
  }
  useEffect(() => {
    fetchStates()
    fetchUserTypes()
  }, [])

  const fetchProjects = () => {
    fetch(process.env.REACT_APP_API_URL + `/api/v1/humblebee/projects`, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
        Authorization: `Bearer ${currentToken}`,
        // 'project': userData.id
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status === 'ok') {
          setProjects(data.projects)
          if (data.projects.length === 0) {
            window.location = '/projects'
          }
        }
      })
  }
  const fetchUserTypes = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/user/user-types/no-pid-auth',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          setUserTypes(data.userTypes)
        }
      })
  }
  const formatColumn = (cell, row, index, type) => {
    if (cell === null) return 'N/A'
    if (type === 'createdBy' || type === 'updatedBy') {
      if (cell && cell._id && cell.fullName) {
        return (
          <a href={'/user-types/project-management-unit/' + cell._id}>
            {cell.fullName}
          </a>
        )
      } else return 'N/A'
    } else if (type === 'documentsRequired') {
      return (
        <span className="docs-reqd" title={cell.join(', ')}>
          {cell.join(', ')}
        </span>
      )
    } else if (
      type === 'createdAt' ||
      type === 'updatedAt' ||
      type === 'startDate' ||
      type === 'endDate'
    ) {
      if (cell === null) return 'N/A'
      if (cell && moment(cell).isValid())
        return moment(cell).format('DD/MM/YYYY hh:mm A')
      else if (cell) return 'cell'
      else return 'N/A'
    } else if (['districts', 'states'].includes(type)) {
      const textToShow =
        cell && cell.length ? cell.join(', ') : `No ${type} filter`
      return (
        <Tooltip title={textToShow}>
          <span>
            {textToShow.length > 30
              ? textToShow.slice(0, 30) + '...'
              : textToShow}
          </span>
        </Tooltip>
      )
    } else if (type === 'name') {
      return (
        <h5
          style={{ cursor: 'pointer' }}
          onClick={() => {
            const projectId = row._id
            if (projectId) {
              changeProject(projectId)
            }
          }}
        >
          {cell} <i class="fa fa-arrow-right" aria-hidden="true" />
        </h5>
      )
    } else if (type === 'edit') {
      return (
        <div className="edit-delete-buttons">
          {/* <button className="btn edit-button"
            onClick={()=>editProject(row)}
          >
            <i className="fa fa-pencil"></i>
            Edit
          </button> */}
          {/* <button className="btn btn-danger delete-button"
            onClick={() => editProject(row._id)}
            // onClick={deleteScheme}
          >
            <i className="fa fa-trash"></i>
            Delete
          </button> */}
        </div>
      )
    } else if (['fromDate', 'toDate'].includes(type)) {
      return <span>{moment(cell).format('DD, MMM YYYY')}</span>
    }
  }

  const projectsColumns = [
    {
      text: '',
      sort: false,
      hidden: true,
      export: true,
      searchable: false,
      dataField: '_id',
    },
    {
      text: 'Name',
      sort: true,
      hidden: false,
      export: true,
      dataField: 'name',
      searchable: true,
      headerStyle: () => {
        return { width: '200px' }
      },
      formatter: formatColumn,
      formatExtraData: 'name',
    },
    {
      text: 'Funder(s)',
      sort: true,
      hidden: false,
      export: true,
      dataField: 'funder',
      searchable: true,
      headerStyle: () => {
        return { width: '100px' }
      },
    },
    {
      text: 'Goals',
      sort: true,
      hidden: false,
      export: true,
      dataField: 'goals',
      searchable: true,
      headerStyle: () => {
        return { width: '100px' }
      },
    },
    // { text: 'Districts', sort: true, hidden: false, export: true, dataField: 'districts', searchable: true, formatter: formatColumn, formatExtraData: 'districts', headerStyle: () => { return { width: '200px' } } },
    // { text: 'States', sort: true, hidden: false, export: true, dataField: 'states', searchable: true, formatter: formatColumn, formatExtraData: 'states', headerStyle: () => { return { width: '200px' } } },
    {
      text: 'From',
      sort: true,
      hidden: false,
      export: true,
      dataField: 'startDate',
      searchable: true,
      headerStyle: () => {
        return { width: '80px' }
      },
      formatter: formatColumn,
      formatExtraData: 'fromDate',
    },
    {
      text: 'To',
      sort: true,
      hidden: false,
      export: true,
      dataField: 'endDate',
      searchable: true,
      headerStyle: () => {
        return { width: '80px' }
      },
      formatter: formatColumn,
      formatExtraData: 'toDate',
    },
    {
      text: '',
      sort: false,
      hidden: !isASuperUser,
      export: false,
      searchable: false,
      align: 'center',
      headerStyle: () => {
        return { width: '50px', textAlign: 'center' }
      },
      dataField: 'edit',
      formatter: formatColumn,
      formatExtraData: 'edit',
    },
  ]

  useEffect(() => {
    fetchProjects()
  }, [userData.id])

  // const logoutUser = () => {
  //   fetch(process.env.REACT_APP_API_URL + '/logout', {
  //     method: "POST",
  //     headers: {
  //       'x-auth-token': JSON.parse(readCookie('access_token'))
  //     }
  //   }).then(function (response) {
  //     return response.json();
  //   }).then(function (data) {
  //     eraseCookie('userData');
  //     eraseCookie('project_id');
  //     eraseCookie('access_token');
  //     // this.setState({ userData: null });
  //     window.location.pathname = '';
  //   }.bind(this)).catch(function (error) {
  //     console.log('There has been a problem with your fetch operation: ' + error.message);
  //   });
  // }

  //   const onChange=(e)=>{
  //     setSelectedProject(e)
  //       projectsHandler(e)
  //  }
  const changeProject = id => {
    //  @Todo : update project param array (userData.hasAccessToApp Array)
    //  @Todo : update context Api with Selected Project
    if (id) {
      const mock_token = JSON.parse(readCookie('access_token'))
      createCookie('project_id', id, 30)
      createCookie('access_token', JSON.stringify(mock_token), 30)
      window.location = `/clusters/${id}`
    }
  }

  return (
    <>
      <div
        className={
          sidebarMinimized && window.location.pathname === '/leads'
            ? 'top-menu sidebar-minimized'
            : 'top-menu top-menu-bar'
        }
      >
        <Row className="full-width">
          {window.screen.width < 500 ? (
            sidebarMinimized ? (
              <span className="menu-toggle" onClick={toggleSidebar}>
                <i className="fa fa-bars fa-2x" />
              </span>
            ) : (
              <span className="menu-toggle" onClick={toggleSidebar}>
                <i className="fa fa-times fa-2x" />
              </span>
            )
          ) : null}

          {window.screen.width >= 500 && userData.organisation ? (
            <Col md={window.screen.width < 500 ? null : 6}>
              <img
                alt=""
                src={
                  userData.organisation.logo ? userData.organisation.logo : ''
                }
                height="60"
              />
            </Col>
          ) : null}
          <Col
            md={
              window.screen.width < 500 ? null : userData.organisation ? 6 : 12
            }
            className="org-additional-buttons"
          >
            <div className="current_user">
              <Link to={'/project-landing'} className="loggedin_user">
                {userData.profilePicture ? (
                  <img
                    alt={userData.fullName ? userData.fullName : ''}
                    src={userData.profilePicture}
                    onError={event =>
                      event.target.setAttribute('src', '/images/user_1.png')
                    }
                    className="img-circle"
                  />
                ) : (
                  <img
                    alt={userData.fullName ? userData.fullName : ''}
                    src="/images/user_1.png"
                    className="img-circle"
                  />
                )}
                <span>{userData.fullName ? userData.fullName : ''}</span>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            marginTop: '10%',
            padding: '5%',
          }}
        >
          {/* 
            {isASuperUser ? 
              <button className="btn add-new-project-button"
              onClick={addProject}
              >
                <i className="fa fa-pencil"></i>
                Add New Project
              </button> : null
            } */}
          {projects ? (
            <ToolkitProvider
              keyField="_id"
              data={projects}
              columns={projectsColumns}
            >
              {props => (
                <div>
                  <BootstrapTable
                    id="schemes-table"
                    {...props.baseProps}
                    // eslint-disable-next-line
                    ref={table => {
                      const schemesTable = table
                    }}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : null}
          {!projects.length ? (
            <div style={{ textAlign: 'center' }}>
              <h2>
                There are no projects assigned to you currently.
                <br />
                {/* <span>Please create a new one!</span> */}
              </h2>
            </div>
          ) : null}
        </div>
      </div>

      <div className="projects-landing-page-footer">
        <svg
          viewBox="0 0 700 110"
          width="100%"
          height="150"
          preserveAspectRatio="none"
        >
          <path
            d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z"
            fill="#ef5a20"
          />
        </svg>
        <p
          style={{
            color: 'white',
            textAlign: 'center',
            position: 'absolute',
            bottom: '-10px',
            left: 0,
            right: 0,
          }}
        >
          &copy; Copyright 2022 – LetsEndorse Development Private Limited. All
          rights reserved.
        </p>
      </div>
      <Modal
        show={showProjectModal}
        style={{ paddingBottom: 50 }}
        onHide={resetModal}
      >
        {selectedProjectDetails ? (
          <>
            {/* <Row>
            <div style={{ marginLeft: '95%', marginTop: 10 }} onClick={resetModal}><a>X</a>
            </div>
          </Row> */}
            <Modal.Header closeButton>
              <Modal.Title className="modal-header-custom">
                {/* {selectedProjectDetails._id ? 'Edit' : 'Add new'} Project */}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="mb20">
              <Row>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">
                      Name of the project:
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: '100%' }}
                      name="name"
                      placeholder="Enter project name"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={selectedProjectDetails.name}
                    />
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">
                      Display title:
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: '100%' }}
                      name="displayTitle"
                      placeholder="Enter display title in the platform"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={selectedProjectDetails.displayTitle}
                    />
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">Theme Color:</label>
                    <Row>
                      <Col md={8}>
                        {' '}
                        <HexColorPicker
                          color={selectedProjectDetails.themeColor}
                          onChange={color => {
                            setSelectedProjectDetails({
                              ...selectedProjectDetails,
                              themeColor: color,
                            })
                          }}
                        />
                      </Col>
                      <Col md={4} style={{ margin: 'auto' }}>
                        {' '}
                        <HexColorInput
                          style={{ width: '100%' }}
                          alpha={true}
                          prefixed={true}
                          color={selectedProjectDetails.themeColor}
                          onChange={color => {
                            setSelectedProjectDetails({
                              ...selectedProjectDetails,
                              themeColor: color,
                            })
                          }}
                        />
                      </Col>
                    </Row>{' '}
                    {/*<input className="form-control" type="text" style={{ width: '100%' }} name="name" placeholder="Pick theme color" onChange={(e) => { handleChange(e) }} value={selectedProjectDetails.themeColor} />
                     */}
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">Funder(s):</label>
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: '100%' }}
                      name="funder"
                      placeholder="Enter funder name"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={selectedProjectDetails.funder}
                    />
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">Goals:</label>
                    <input
                      className="form-control"
                      type="text"
                      style={{ width: '100%' }}
                      name="goals"
                      placeholder="Enter goals"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={selectedProjectDetails.goals}
                    />
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">
                      IVRS Toll-free number:
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      pattern={TOLL_FREE_NUMBER_REGEX}
                      style={{ width: '100%' }}
                      name="ivrsTollFreeNumber"
                      placeholder="Enter IVRS toll-free number"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={selectedProjectDetails.ivrsTollFreeNumber}
                    />
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">
                      IVRS vanity number:
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      pattern={PHONE_NUMBER_REGEX}
                      style={{ width: '100%' }}
                      name="ivrsVanityNumber"
                      placeholder="Enter IVRS vanity number"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={selectedProjectDetails.ivrsVanityNumber}
                    />
                  </Col>
                </Col>

                {false && (
                  <>
                    {' '}
                    <Col md={12} className="padding0 d-flex">
                      <Col>
                        <label className="control-label is-imp">State(s)</label>
                        <Multiselect
                          options={availableStates}
                          // key={districtIndex}
                          // style={{ height: '100%', width: '60%' }}
                          // style={{
                          //     width: "85%",
                          //     marginLeft: "15%"
                          // }}
                          selectedValues={
                            selectedProjectDetails.states
                              ? selectedProjectDetails.states.map(d => {
                                  return { name: d }
                                })
                              : []
                          }
                          placeholder={'Select District(s)'}
                          onSelect={e => handleChange(e, 'state')}
                          onRemove={e => handleChange(e, 'state')}
                          // ref={multiselectRef}
                          displayValue={'name'}
                        />
                        {/* <input className="form-control" type="text" style={{ width: '100%' }} name="states" placeholder="States" onChange={(e) => { handleChange(e) }} value={selectedProjectDetails.states} /> */}
                      </Col>
                    </Col>
                    <Col md={12} className="padding0 d-flex">
                      <Col>
                        <label className="control-label is-imp">
                          District(s)
                        </label>
                        <Multiselect
                          options={availableDistricts}
                          // key={districtIndex}
                          // style={{ height: '100%', width: '60%' }}
                          // style={{
                          //     width: "85%",
                          //     marginLeft: "15%"
                          // }}
                          selectedValues={
                            selectedProjectDetails.districts
                              ? selectedProjectDetails.districts.map(d => {
                                  return { name: d }
                                })
                              : []
                          }
                          placeholder={'Select District(s)'}
                          onSelect={e => handleChange(e, 'district')}
                          onRemove={e => handleChange(e, 'district')}
                          // ref={multiselectRef}
                          displayValue={'name'}
                        />
                        {/* <input className="form-control" type="text" style={{ width: '100%' }} name="districts" placeholder="Districts" onChange={(e) => { handleChange(e) }} value={selectedProjectDetails.districts} /> */}
                      </Col>
                    </Col>
                  </>
                )}
                <Col md={6} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">From Date</label>
                    <input
                      className="form-control"
                      type="date"
                      style={{ width: '100%' }}
                      name="fromDate"
                      placeholder="Select From Date"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={
                        selectedProjectDetails.fromDate
                          ? moment(selectedProjectDetails.fromDate).format(
                              'YYYY-MM-DD'
                            )
                          : ''
                      }
                    />
                  </Col>
                </Col>
                <Col md={6} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">End Date</label>
                    <input
                      className="form-control"
                      type="date"
                      min={
                        selectedProjectDetails.toDate
                          ? moment(selectedProjectDetails.toDate).format(
                              'YYYY-MM-DD'
                            )
                          : null
                      }
                      style={{ width: '100%' }}
                      name="toDate"
                      placeholder="Select To Date"
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={
                        selectedProjectDetails.toDate
                          ? moment(selectedProjectDetails.toDate).format(
                              'YYYY-MM-DD'
                            )
                          : ''
                      }
                    />
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">Status</label>
                    <Select
                      size="default"
                      mode="default"
                      defaultValue={selectedProjectDetails.status}
                      style={{ width: '100%', height: '38px' }}
                      placeholder="Select Project Status"
                      onChange={e => handleChange(e, 'status')}
                    >
                      <Option disabled value="">
                        Select Project Status
                      </Option>

                      {['Draft', 'Live', 'Completed', 'Closed'].map(
                        (status, statusIndex) => {
                          return (
                            <Option key={statusIndex} value={status}>
                              {status}
                            </Option>
                          )
                        }
                      )}
                    </Select>
                  </Col>
                </Col>
                <Col md={12} className="padding0 d-flex">
                  <Col>
                    <label className="control-label is-imp">
                      Select User Types
                    </label>
                    <Multiselect
                      options={userTypes}
                      style={{ width: '100%', height: '38px' }}
                      // key={districtIndex}
                      // style={{ height: '100%', width: '60%' }}
                      // style={{
                      //     width: "85%",
                      //     marginLeft: "15%"
                      // }}

                      selectedValues={
                        selectedProjectDetails.userTypes
                          ? selectedProjectDetails.userTypes
                          : []
                      }
                      placeholder={'Select User Types'}
                      onSelect={e => handleChange(e, 'userTypes')}
                      onRemove={e => handleChange(e, 'userTypes')}
                      // ref={multiselectRef}
                      displayValue={'name'}
                    />
                  </Col>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                className="btn btn-danger brand-color-background"
                onClick={resetModal}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-warning brand-color-background"
                onClick={saveProjectDetails}
              >
                {selectedProjectDetails._id ? 'Update' : 'Save'}
              </Button>
            </Modal.Footer>
          </>
        ) : null}
      </Modal>
    </>
  )
}

export default ProjectsLandingPage
