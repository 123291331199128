import React from 'react'
import PlaceCenter from './placeCenter'
import { Table } from 'react-bootstrap'

const CenterList = props => {
  //Dark Mode Settlement
  /*const [darkMode, setDark] = useState("light");
  const changeDarkMode = (checked) => {
    if(checked)
    setDark("dark")
    else
    setDark("light")
  }*/

  //date changer function

  //No Center Found
  if (!props.items) {
    return (
      <div
        style={{
          position: 'absolute',
          left: '40rem',
          top: '20rem',
          backgroundColor: 'gray',
          padding: '20px',
          borderRadius: '25px',
        }}
      >
        <h2>No center found. Maybe create one?</h2>
      </div>
    )
  }

  return (
    <Table
      striped
      responsive
      bordered
      hover
      size="sm"
      variant="light"
      style={{ marginTop: '2rem', marginLeft: '18rem', maxWidth: '85rem' }}
    >
      <thead>
        <tr>
          <th>Type</th>
          <th>State</th>
          <th>District</th>
          <th>Pincode</th>
          <th>Rating</th>
          <th>Database</th>
          <th>Trained Candidates</th>
          <th>Employed Candidates</th>
          <th>Settlement</th>
          <th> Edit </th>
          <th>Delete</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(center => (
          <PlaceCenter
            key={center.id}
            id={center.id}
            type={center.type}
            state={center.state}
            district={center.district}
            pincode={center.pincode}
            rating={center.rating}
            database={center.database}
            trained={center.trained}
            employed={center.employed}
            settlement={center.settlement}
            onDelete={props.onDeleteCenter}
          />
        ))}
      </tbody>
    </Table>
  )
}

export default CenterList
