import React, {
  useState,
  // useEffect
} from 'react'
import {
  Container,
  Row,
  Col,
  // Spinner,
  Button,
} from 'react-bootstrap'
import Label from '../../../partials/training-center/components/FormElements/label'
import { DatePicker, Input, Select, Spin } from 'antd'
import { UserOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useHttpHook } from '../../training-center/hooks/http-hook'
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify'
//function starting

const Tracker = props => {
  //object declaration
  const [input, setInput] = useState({
    followupDate: moment(props.data.followUpDate),
    mode: props.data.mode,
    sentiment: props.data.sentiment,
    comments: props.data.comments,
    nextFollowupDate: moment(props.data.nextfollowUp),
    id: props.data.id,
  })
  // const style = { padding: "8px 0" };
  const readCookie = require('../../cookie.js').readCookie
  const user = JSON.parse(readCookie('userData'))
  const { Option } = Select
  const {
    isLoading,
    error,
    sendRequest,
    // clearError
  } = useHttpHook()
  const { TextArea } = Input
  //console.log(user);

  function disabledDate(current) {
    return current && current < moment().startOf('day')
  }

  const savehandler = async event => {
    if (
      input.followupDate !== null &&
      input.mode !== '' &&
      input.sentiment !== '' &&
      input.comments !== '' &&
      input.nextFollowupDate !== null &&
      user.fullName
    ) {
      event.preventDefault()

      try {
        // const responseData =
        await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/follow-up-tracker/update/${input.id}`,
          'PATCH',
          JSON.stringify({
            followUpDate: input.followupDate,
            followedby: user.fullName,
            mode: input.mode,
            sentiment: input.sentiment,
            comments: input.comments,
            nextfollowUp: input.nextFollowupDate,
          }),
          {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          }
        )
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'Successfully updated',
          showConfirmButton: false,
          timer: 1500,
        })
        props.onHide()
        window.location.reload()
      } catch (err) {
        alert(err + ' ' + error)
      }
    } else {
      toast.error('All fields are mandatory', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <React.Fragment>
      <Container
        style={{
          boxShadow: ' 5px 5px 5px 5px #888888 ',
          padding: ' 10px ',
          marginTop: '100px',
          marginLeft: '20rem',
        }}
      >
        <CloseOutlined style={{ float: 'right' }} onClick={props.onHide} />
        <Row>
          <Col sm={2}>
            <Label name="Date of follow-up" />
            <DatePicker
              format="DD/MM/YYYY"
              value={input.followupDate}
              onChange={current => {
                setInput({ ...input, followupDate: current })
              }}
            />
          </Col>
          <Col sm={2}>
            <Label name="Followed up by" />
            <Input
              placeholder="default size"
              prefix={<UserOutlined />}
              disabled={true}
              value={user.fullName}
            />
          </Col>
          <Col sm={2}>
            <Label name="Mode" />
            <Select
              style={{ width: 150 }}
              value={input.mode}
              placeholder="Select Mode"
              onChange={data => {
                setInput({ ...input, mode: data })
              }}
            >
              <Option value="Call">Call</Option>
              <Option value="Visit">Visit</Option>
              <Option value="Escalation">Escalation</Option>
            </Select>
          </Col>
          <Col sm={2}>
            <Label name="Overall Sentiment" />
            <Select
              style={{ width: 150 }}
              placeholder="Select Sentiment"
              value={input.sentiment}
              onChange={data => {
                setInput({ ...input, sentiment: data })
              }}
            >
              <Option value="Positive">Positive</Option>
              <Option value="Negative">Negative</Option>
              <Option value="Neutral">Neutral</Option>
              <Option value="Couldn't connect">Couldn't connect</Option>
            </Select>
          </Col>
          <Col sm={2}>
            <Label name="Comments, Contact Details, Next Steps" />
            <TextArea
              rows={4}
              defaultValue={input.comments}
              onBlur={data => {
                setInput({ ...input, comments: data.target.value })
              }}
            />
          </Col>
          <Col sm={2}>
            <Label name="Next follow-up" />
            <DatePicker
              format="DD/MM/YYYY"
              disabledDate={disabledDate}
              value={input.nextFollowupDate}
              onChange={data => {
                setInput({ ...input, nextFollowupDate: data })
              }}
            />
          </Col>
        </Row>
        <Row>
          {isLoading ? <Spin /> : <Button onClick={savehandler}>Update</Button>}
        </Row>
      </Container>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </React.Fragment>
  )
}

export default Tracker
