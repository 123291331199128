import React, { Component } from 'react'
import { Col, Row, button, Modal } from 'react-bootstrap'
import { readCookie } from '../cookie'
import Swal from 'sweetalert2'
import moment from 'moment'
import { toast } from 'react-toastify'

const Razorpay = require('razorpay')
const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID
const razorpayKeySeceret = process.env.REACT_APP_RAZORPAY_KEY_SECERET
// import Logo from "../../../assets/images/le_black.png";

export default class EnterprisePaymentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // rowDetails: props.rowDetails,
      loading: false,
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  downloadInvoice(url, fileName) {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        this.notifySuccess('Your invoice dowload should begin soon.')
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }
  componentDidMount() {
    // useEffect(() => {
    if (
      !document.querySelector(
        'script[src="https://checkout.razorpay.com/v1/checkout.js"]'
      )
    ) {
      const script = document.createElement('script')
      script.src = 'https://checkout.razorpay.com/v1/checkout.js'
      document.body.appendChild(script)
    }
    // }, []);
    this.getServiceRequestDetails()
  }

  handelRazorpay = (index, row) => {
    this.openCheckout(index, row)
  }

  getServiceRequestDetails = () => {
    this.setState({ loading: true })
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/service-delivery/service-log/' +
        this.props.leadData.leadId +
        '/' +
        this.props.rowId,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.props.updateRowDetails(data.requestDetails)
          this.props.updateRequestDetails(data.requestDetails)
          this.setState({
            // rowDetails: data.requestDetails,
            loading: false,
          })
        }
      })
  }

  openCheckout = (index, row) => {
    const themeColor =
      readCookie('currentProjectDetails') &&
      JSON.parse(readCookie('currentProjectDetails')).themeColor
        ? JSON.parse(readCookie('currentProjectDetails')).themeColor
        : '#F37254'
    // Todo: modify actuals
    if (
      this.props.rowDetails.orderDetails &&
      this.props.rowDetails.orderDetails.length
    ) {
      let getServiceRequestDetails = this.getServiceRequestDetails
      let options = {
        // key: razorpayKeySeceret,
        key: razorpayKeyId,
        key_id: razorpayKeyId,

        amount:
          (this.props.rowDetails.orderDetails[index].amount ||
            this.props.rowDetails.orderDetails[index].netServiceValue) * 100,
        currency: 'INR',
        name: 'LetsEndorse Development',
        // name: "Udyamita Powered by LetsEndorse",
        description: row.description || '',
        image: 'https://uditi.udyamita.org/images/udyamita-powered-by-le.png',
        order_id: this.props.rowDetails.orderDetails[index].orderId,
        handler: function (response) {
          Swal.fire({
            title: '',
            text: 'Payment Successful!',
            type: 'success',
          })
          getServiceRequestDetails()
        },
        prefill: {
          name: this.props.leadData.basic.name || '',
          email: this.props.leadData.basic.email || '',
          contact: this.props.leadData.basic.mobileNumber || '',
        },
        notes: {
          address: 'LetsEndorse, BLR',
          leadId: this.props.leadData.leadId || '',
          serviceRequestId: this.props.rowDetails._id || '',
          serviceId: this.props.rowDetails.serviceId._id || '',
          serviceName: this.props.rowDetails.serviceId.serviceTitle || '',
          amount: row.amount * 100,
          amountWithoutGst: row.amountWithoutGst * 100,
          amountWithGst: row.amount * 100,
          order_reference_id: this.props.rowDetails.orderDetails[index].orderId,
          tranche: `Tranche ${index + 1}`,
        },
        theme: {
          color: themeColor,
        },
        upi_link: true,
        // options: {
        //   checkout: {
        //     method: {
        //       netbanking: 0,
        //       card: 0,
        //       upi: 1,
        //       wallet: 0

        //     }
        //   }
        // },
        method: {
          card: false,
          netbanking: false,
          wallet: false,
          upi: true,
          emi: false,
          // paylater: false,
        },
      }

      const rzp = new window.Razorpay(options)

      rzp.on('payment.failed', function (response) {
        Swal.fire({
          title: 'Payment Failed',
          text: 'Sorry your payment did not succeed. Please try again',
          type: 'error',
        })
        getServiceRequestDetails()
      })

      rzp.open()
    }
  }

  handelPaidByCash(index, row) {
    console.log(
      '🚀 ~ file: EnterprisePaymentDetails.js:182 ~ EnterprisePaymentDetails ~ handelPaidByCash ~ index, row:',
      index,
      row
    )
    const payload = {
      rzpPayload: {
        status: 'paid by cash',
        acquirer_data: {
          upi_transaction_id: '-',
          transaction_id: '-',
        },
        id: '-',
        method: 'Cash',
        notes: {
          ...row.paymentDetails.notes,
        },
        orderId: row.orderId,
      },
      orderId: row.orderId,
    }
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/service-delivery/payment-by-cash',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify({ payload }),
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.notifySuccess(
            'Marked as paid by cash! Please refresh to download invoice.'
          )
          console.log('Paid by cash...')
        }
      })
  }

  displayPaymentDetails = (index, row) => {
    const { paymentDetails } = row
    Swal.fire(
      'Payment Details',
      `<div>Payment ID: ${paymentDetails.id}</div> <div>Payment Method: ${paymentDetails.method}</div>`,
      'info'
    )
  }

  render() {
    return this.props.rowDetails ? (
      <div>
        <div className="row">
          <div className="col-8">
            {' '}
            <h4 className="font-weight-bold" style={{ marginBottom: '20px' }}>
              Payment Details
            </h4>
          </div>
          <div className="col-4">
            <button
              className="btn"
              onClick={() => {
                this.getServiceRequestDetails()
              }}
            >
              <i className="fa fa-refresh" />
            </button>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <h5 className="font-weight-bold">Type: </h5>
            <h5 style={{ paddingLeft: 's5px' }}>
              {' '}
              {this.props.rowDetails.paymentType}
            </h5>
          </div>
          <div className="d-flex">
            <h5 className="font-weight-bold">Service Value: </h5>

            <h5 style={{ paddingLeft: '5px' }}>
              INR {this.props.rowDetails.serviceValue}
            </h5>
          </div>

          {this.props.rowDetails.discountInRupees ? (
            <div>
              {' '}
              <div className="d-flex">
                <h5 className="font-weight-bold">Discount: </h5>

                <h5 style={{ paddingLeft: '5px' }}>
                  INR {this.props.rowDetails.discountInRupees}
                </h5>
              </div>
              <div className="d-flex">
                <h5 className="font-weight-bold">Net Service Value: </h5>

                <h5 style={{ paddingLeft: '5px' }}>
                  INR {this.props.rowDetails.netServiceValue}
                </h5>
              </div>
            </div>
          ) : null}

          {/* <div className="row"> */}
          {this.state.loading ? (
            <div className="col-md-12 loader-container">
              {/* <div className="loader-msg">Please wait while we redirect...</div> */}
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </div>
          ) : (
            this.props.rowDetails.orderDetails.map((row, index) => (
              <div className="container-fluid" key={index}>
                <p>Tranche {index + 1}</p>
                {row.paymentDetails &&
                ['captured', 'paid by cash', 'authorized'].includes(
                  row.paymentDetails.status
                ) ? (
                  <div className="row">
                    <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                      <button
                        className="btn btn-primary"
                        id="rzp-button1"
                        onClick={() => {
                          this.displayPaymentDetails(index, row)
                        }}
                      >
                        <i
                          class="fa fa-eye"
                          style={{ fontSize: '18px' }}
                          title={`View Payment Details`}
                        />
                        {`Details`}
                      </button>
                    </div>
                    {row.invoiceUrl ? (
                      <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <a
                          download={`Invoice-${row.orderId}-${moment().format('DD-MM-YYYYHHMMSS')}.pdf`}
                          href={row.invoiceUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <button
                            id="rzp-button1"
                            className="btn btn-secondary"
                            // onClick={() => { this.handelRazorpay(index) }}
                            // onClick={() => { this.downloadInvoice(row.invoiceUrl, `Invoice-${row.orderId}-${moment().format('DD-MM-YYYYHHMMSS')}.pdf`)}}
                          >
                            <i
                              class="fa fa-download"
                              style={{ fontSize: '18px' }}
                              title={`Download Invoice`}
                            />
                            {`Invoice`}
                          </button>
                        </a>
                      </div>
                    ) : (
                      <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <button
                          id="rzp-button1"
                          className="btn btn-secondary"
                          disabled
                          // onClick={() => { this.handelRazorpay(index) }}
                        >
                          {/* <a href="/images/myw3schoolsimage.jpg" download> */}
                          <i
                            class="fa fa-download"
                            style={{ fontSize: '18px' }}
                            title={`Download Invoice`}
                          />
                          {`Invoice`}
                          {/* </a> */}
                        </button>
                      </div>
                    )}
                  </div>
                ) : row.paymentDetails &&
                  ['failed'].includes(row.paymentDetails.status) ? (
                  <div className="row">
                    <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                      <button
                        id="rzp-button1"
                        className="btn btn-danger"
                        onClick={() => {
                          this.handelRazorpay(index, row)
                        }}
                      >
                        {`Try again`}
                      </button>
                    </div>
                    <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        title={`Share Payment Link on WhatsApp`}
                        href={`https://wa.me/91${this.props.leadData.basic.mobileNumber}?text=Hi from Team Udyamita, You make payment for the service requested here: ${row.paymentDetails.short_url}`}
                      >
                        <button
                          id="rzp-button1"
                          // onClick={() => { this.handelRazorpay(index) }}
                          className="btn btn-secondary"
                        >
                          <i
                            class="fa fa-whatsapp"
                            style={{ fontSize: '24px' }}
                            title={`Share Payment Link on WhatsApp`}
                          />{' '}
                          Share
                        </button>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                      <button
                        className="btn btn-primary"
                        disabled={
                          index === 1 &&
                          (!this.props.rowDetails.orderDetails[0]
                            .paymentDetails ||
                            ![
                              'captured',
                              'paid by cash',
                              'authorized',
                            ].includes(
                              this.props.rowDetails.orderDetails[0]
                                .paymentDetails.status
                            ))
                        }
                        id="rzp-button1"
                        onClick={() => {
                          this.handelRazorpay(index, row)
                        }}
                      >
                        {`Pay Now`}
                      </button>
                    </div>
                    <div className="col-6 col-sm-6 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                      {index === 1 &&
                      (!this.props.rowDetails.orderDetails[0].paymentDetails ||
                        !['captured', 'paid by cash', 'authorized'].includes(
                          this.props.rowDetails.orderDetails[0].paymentDetails
                            .status
                        )) ? null : (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          title={`Share Payment Link on WhatsApp`}
                          href={`https://wa.me/91${this.props.leadData.basic.mobileNumber}?text=Hi from Team Udyamita, You make payment for the service requested here: ${row.paymentDetails.short_url}`}
                        >
                          <button
                            id="rzp-button1"
                            // onClick={() => { this.handelRazorpay(index) }}
                            className="btn btn-secondary"
                          >
                            <i
                              class="fa fa-whatsapp"
                              style={{ fontSize: '18px' }}
                              title={`Share Payment Link on WhatsApp`}
                            />{' '}
                            Share
                          </button>
                        </a>
                      )}
                    </div>
                    {this.props.userData.userType.name ===
                    'Project Management Unit' ? (
                      <div className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                        <br />
                        <button
                          style={{ width: '100%' }}
                          className="btn btn-primary"
                          disabled={
                            index === 1 &&
                            (!this.props.rowDetails.orderDetails[0]
                              .paymentDetails ||
                              ![
                                'captured',
                                'paid by cash',
                                'authorized',
                              ].includes(
                                this.props.rowDetails.orderDetails[0]
                                  .paymentDetails.status
                              ))
                          }
                          id="rzp-button1"
                          onClick={() => {
                            this.handelPaidByCash(index, row)
                          }}
                        >
                          {`Mark as paid by cash`}
                        </button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ))
          )}
          {/* </div> */}
        </div>
        {/* <button
          onClick={this.handelRazorpay}
        >
          Pay Now
        </button> */}
      </div>
    ) : null
  }
}
