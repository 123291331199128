import moment from 'moment'
// import Swal from 'sweetalert2';
import { toast } from 'react-toastify'
import React, { Component } from 'react'
import {
  Collapse,
  // Space,
  Select,
} from 'antd'
import { Row, Col, Button, Modal } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

const readCookie = require('../cookie.js').readCookie
// const createCookie = require('../cookie.js').createCookie;

const { SearchBar } = Search
const { Panel } = Collapse
const { Option } = Select

function ClaimHeader({ claim }) {
  return (
    <div className="claim-header d-flex align-items-center justify-content-between">
      <span className="note">{claim.month + ', ' + claim.year}</span>
      <span className={claim.status === 'DISBURSED' ? 'note' : 'le-color'}>
        {claim.disbursedAmount
          ? '₹ ' + claim.disbursedAmount.toLocaleString() + ' - '
          : null}
        {claim.status}
      </span>
    </div>
  )
}

function ClaimDetails({ claim }) {
  return <div className="claim-details"></div>
}

export default class ManageClaims extends Component {
  constructor(props) {
    super(props)
    this.state = {
      claims: [], //@Todo Add actual claims array here [{ month: 'November', year: 2020, claimedEnterprises: 4, claimedAmount: 20000, invoice: null, status: 'DISBURSED', disbursedAmount: 20000, candidates: [] },{ month: 'December', year: 2020, claimedEnterprises: 4, claimedAmount: 20000, invoice: null, status: 'DISBURSED', disbursedAmount: 20000, candidates: [] },{ month: 'January', year: 2021, claimedEnterprises: 6, claimedAmount: 30000, invoice: null, status: 'DISBURSED', disbursedAmount: 30000, candidates: [] },{ month: 'February', year: 2021, claimedEnterprises: 5, claimedAmount: 25000, invoice: null, status: 'SUBMITTED', candidates: [] },{ month: 'March', year: 2021, claimedEnterprises: 4, claimedAmount: 20000, invoice: null, status: 'SUBMITTED', candidates: [] }],
      claimsSummary: {
        totalDisbursed: 0,
        totalPending: 0,
        totalApproved: 0,
        totalDisbursedAmount: 0,
      },
      /* 
			@Todo Add rea; claims summary object here.
			{
				totalDisbursed: 3,
				totalPending: 2,
				totalApproved: 43,
				totalDisbursedAmount: 70000
			},*/
      error: {},
      newClaim: null,
      claimedMonths: [],
      selectedRowCount: 0,
      selectedCandidates: {},
      allCandidatesAvailable: [],
      showCreateClaimModal: false,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect,
        onSelectAll: this.onAllRowSelect,
      },
    }
  }

  toggleCreateClaimModal = () => {
    this.setState({
      showCreateClaimModal: !this.state.showCreateClaimModal,
      error: {},
      newClaim: null,
    })
  }

  submitCandidates = () => {
    let newClaim = this.state.newClaim
    if (
      Object.keys(this.state.selectedCandidates).length &&
      this.state.newClaim.month &&
      this.state.newClaim.year
    ) {
      newClaim['candidates'] = Object.values(this.state.selectedCandidates)
      fetch(process.env.REACT_APP_API_URL + '/api/v1/claims', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify(newClaim),
      })
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.toggleCreateClaimModal()
            this.notifySuccess(data.message)
          } else {
            this.notifyError(data.message)
          }
        })
    }
  }

  updateNewClaim = (type, value) => {
    let newClaim = this.state.newClaim
    if (value.target) value = value.target.value
    newClaim[type] = value
    if (type === 'year') {
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/claims/get-claimed-months/' +
          value,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.error && data.message) this.notifyError(data.message)
          newClaim['month'] = ''
          this.setState({ claimedMonths: data.months, newClaim })
        })
    } else this.setState({ newClaim })
  }

  createNewClaim = () => {
    this.fetchAllCandidatesAvailable()
    this.setState({
      showCreateClaimModal: true,
      newClaim: { month: '', year: '' },
      error: {},
    })
  }

  fetchAllCandidatesAvailable = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/claims/get-available-candidates',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.error && data.message) this.notifyError(data.message)
        this.setState({ allCandidatesAvailable: data.candidates })
      })
  }

  onRowSelect = (row, isSelected) => {
    let selectedCandidates = this.state.selectedCandidates
    // ,deleteButtonDisabled = true;

    if (isSelected) {
      selectedCandidates[row._id] = row
      this.setState({
        selectedRowCount: this.state.selectedRowCount + 1,
        selectedCandidates,
      })
    } else {
      delete selectedCandidates[row._id]
      this.setState({
        selectedRowCount: this.state.selectedRowCount - 1,
        selectedCandidates,
      })
    }
  }

  onAllRowSelect = (isSelected, row) => {
    if (isSelected) {
      let selectedCandidates = {}
      for (let i = 0; i < this.state.allCandidatesAvailable.length; i++) {
        selectedCandidates[this.state.allCandidatesAvailable[i]._id] =
          this.state.allCandidatesAvailable[i]
      }
      this.setState({
        selectedCandidates,
        selectedRowCount: this.state.allCandidatesAvailable.length,
      })
    } else {
      this.setState({ selectedRowCount: 0, selectedCandidates: {} })
    }
  }

  formatAuditStatus = (cell, row) => {
    if (!cell) return 'N/A'
    else return cell
  }

  formatAction = (cell, row) => {
    if (!row.auditStatus) {
      return <Button>Request Audit</Button>
    } else {
      if (row.auditStatus === 'Failed') {
        return <Button>Take Action</Button>
      } else if (row.auditStatus === 'Unaudited') {
        return <Button>Request Audit</Button>
      } else if (row.auditStatus === 'Audited') {
        return <Button>Claim</Button>
      } else return 'N/A'
    }
  }

  checkSubmitDisability = () => {
    if (
      Object.keys(this.state.selectedCandidates).length &&
      this.state.newClaim.month &&
      this.state.newClaim.year
    )
      return false
    else return true
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  render() {
    const columns = [
      {
        dataField: '_id',
        text: '',
        hidden: true,
        export: true,
        searchable: false,
      },
      {
        dataField: 'mobileNo',
        text: 'Mobile',
        sort: true,
        hidden: false,
        export: true,
        searchable: true,
      },
      {
        dataField: 'name',
        text: 'Full Name',
        sort: true,
        hidden: false,
        export: true,
        searchable: true,
      },
      {
        dataField: 'auditStatus',
        text: 'Audit Status',
        sort: true,
        hidden: false,
        export: true,
        formatter: this.formatAuditStatus,
        searchable: false,
      },
      {
        dataField: 'claimStatus',
        text: 'Action',
        sort: false,
        hidden: false,
        export: false,
        formatter: this.formatAction,
        searchable: false,
      },
    ]
    return (
      <Row className="manage-claims">
        <Col md={12}>
          <Row id="claims-summary" className="mt20">
            {this.state.claimsSummary.totalDisbursed !== undefined ||
            this.state.claimsSummary.totalPending !== undefined ? (
              <Col md={4} className="claims-summary-item">
                <span className="title">Total Claims made</span>
                <span className="value">
                  {this.state.claimsSummary.totalDisbursed +
                    this.state.claimsSummary.totalPending}{' '}
                  ({this.state.claimsSummary.totalDisbursed} disbursed,{' '}
                  {this.state.claimsSummary.totalPending} pending)
                </span>
              </Col>
            ) : null}
            {this.state.claimsSummary.totalApproved !== undefined ? (
              <Col md={4} className="claims-summary-item">
                <span className="title">
                  Total Enterprises approved for claims
                </span>
                <span className="value">
                  {this.state.claimsSummary.totalApproved}
                </span>
              </Col>
            ) : null}
            {this.state.claimsSummary.totalDisbursedAmount !== undefined ? (
              <Col md={4} className="claims-summary-item">
                <span className="title">
                  Total Claim disbursed (INR) till date
                </span>
                <span className="value">
                  ₹{' '}
                  {this.state.claimsSummary.totalDisbursedAmount.toLocaleString()}
                </span>
              </Col>
            ) : null}
          </Row>

          <div className="d-flex justify-content-center mt20 mb20">
            <Button
              className="btn btn-warning brand-color-background"
              onClick={this.createNewClaim}
            >
              Create a new claim (for another month)
            </Button>
          </div>

          <Col id="claims-container" md={12}>
            <Collapse>
              {this.state.claims.map((claim, i) => {
                return (
                  <Panel
                    className="claim-container"
                    header={<ClaimHeader claim={claim} />}
                    key={i + 1}
                  >
                    <ClaimDetails claim={claim} />
                  </Panel>
                )
              })}
            </Collapse>
          </Col>
          {this.state.claims.length === 0 && (
            <Col className="claim-container">
              <p className="text-center font-weight-bold">
                No claims to show yet!
              </p>
            </Col>
          )}
        </Col>

        {this.state.showCreateClaimModal ? (
          <Modal
            size="lg"
            className="candidates-modal"
            show={this.state.showCreateClaimModal}
            onHide={this.toggleCreateClaimModal}
          >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                Create New Claim
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col className="form-group" md={{ span: 3, offset: 3 }}>
                  <label className="control-label is-imp">Select Year</label>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select Year"
                    onChange={this.updateNewClaim.bind(this, 'year')}
                    value={this.state.newClaim.year}
                  >
                    <Option value="" disabled>
                      Select Year
                    </Option>
                    <Option value={2020}>2020</Option>
                    <Option value={2021}>2021</Option>
                  </Select>
                  {this.state.error.year ? (
                    <span className="error-span">{this.state.error.year}</span>
                  ) : null}
                </Col>
                <Col className="form-group" md={3}>
                  <label className="control-label is-imp">Select Month</label>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    placeholder="Select Month"
                    onChange={this.updateNewClaim.bind(this, 'month')}
                    value={this.state.newClaim.month}
                    disabled={!this.state.newClaim.year}
                  >
                    <Option value="" disabled>
                      Select Month
                    </Option>
                    {moment.months().map((m, i) => (
                      <Option
                        value={m}
                        key={i}
                        disabled={this.state.claimedMonths.indexOf(m) > -1}
                      >
                        {m}
                      </Option>
                    ))}
                  </Select>
                  {this.state.error.month ? (
                    <span className="error-span">{this.state.error.month}</span>
                  ) : null}
                </Col>

                {this.state.newClaim.year &&
                this.state.newClaim.month &&
                !this.state.error.year &&
                !this.state.error.month ? (
                  <Col className="form-group" md={12}>
                    <label className="control-label is-imp">
                      Select Candidates
                    </label>
                    <ToolkitProvider
                      keyField="_id"
                      data={this.state.allCandidatesAvailable}
                      columns={columns}
                      search={{ searchFormatted: true }}
                    >
                      {props => (
                        <div>
                          <Row className="mt10">
                            <Col className="members-table-search-container">
                              <SearchBar
                                {...props.searchProps}
                                placeholder="Search by Name/Mobile"
                                className="members-table-search"
                                delay={500}
                              />
                            </Col>
                          </Row>
                          <BootstrapTable
                            {...props.baseProps}
                            selectRow={this.state.selectRowProp}
                            ref={table => {
                              this.table = table
                            }}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Col>
                ) : null}
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                className="btn btn-danger brand-color-background"
                onClick={this.toggleCreateClaimModal}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-warning brand-color-background"
                onClick={this.submitCandidates}
                disabled={this.checkSubmitDisability()}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </Row>
    )
  }
}
