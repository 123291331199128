import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  allUsers: [],
  loading: false,
  error: '',
}

export const getAllUsers = createAsyncThunk('getAllUsers', async role => {
  try {
    if (!role) {
      role = 'all'
    }
    const res = await axios.get(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/users?limit=1000&role=${role}`
    )
    console.log('res.data from Users ==> ', res.data)
    return res.data.userInfo
  } catch (error) {
    console.log('error while fetching all users ', error)
    return error.response.data
  }
})

const getAllUsersSlice = createSlice({
  name: 'getAllUsers',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllUsers.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllUsers.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        console.log('error in getAllUsers fullfilled ', error)
        state.error = error
      } else {
        console.log('payload in getAllUsers fullfilled ', payload)
        state.allUsers = payload
      }
    },

    [getAllUsers.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getAllUsersSlice.reducer
