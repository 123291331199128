import React, { useState, useEffect } from 'react'
import './Tab2.css'

// import { Table } from "react-bootstrap";

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import swal from 'sweetalert'
import axios from 'axios'
import moment from 'moment'
import { EditFilled, FileAddFilled } from '@ant-design/icons'
// import {
//   // ConfigProvider, Switch,
//   // Select
// } from "antd";

const readCookie = require('../../../cookie').readCookie
const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`
// const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYjk2YTA5NjYyNjAzNTE1M2M4NjgzYSIsInVzZXJUeXBlIjp7Il9pZCI6IjVkOGRlMjExMTBiYTkxMDNkMjFkMWE4NSIsIm5hbWUiOiJQcm9qZWN0IE1hbmFnZW1lbnQgVW5pdCJ9LCJ1c2VyUm9sZSI6bnVsbCwibmdvIjpudWxsLCJhY2NvdW50VHlwZSI6IlN1cGVyIFVzZXIiLCJwZXJtaXNzaW9ucyI6W10sImFwcGxpY2F0aW9uIjoiU3dhdGkiLCJpYXQiOjE2MjU1NDM4ODd9.zHckkR2zwaE66s__MthKV0DuSHyJLhNBdXAoDX28OPQ';//
// const { Option, OptGroup } = Select;

function Tab2(props) {
  const { userId, userName } = props
  const [modalShow, setModalShow] = useState(false)
  const [interactionDetails, setinteractionDetails] = useState(false)
  const { SearchBar } = Search
  const userData = JSON.parse(readCookie('userData'))
  const [interactions, setinteractions] = useState([])

  const poObj = {
    id: userData.id,
    name: userData.fullName,
  }

  const stakeHolderObj = {
    id: userId,
    name: userName,
  }

  // const [interactions, setinteractions] = useState([
  //   {
  //     userId: 4865437856294,
  //     date: '8:00:00 PM, 23-05-2021',
  //     initiator: 'Kushagra',
  //     mode: 'Phone',
  //     connectionStatus: 'Connected',
  //     sentiment: 'Positive',
  //     comments: 'Minutes of the interaction to go here',
  //     nextAction: 'To meet him at his office on 23/06',
  //     toTakeAction: 'Me',
  //     followUp: '27-06-2021',
  //   },
  // ]);

  function editButton(cell, row, rowIndex) {
    return (
      <div
        onClick={() => {
          setModalShow(true)
          setinteractionDetails(rowIndex)
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EditFilled />
      </div>
    )
  }
  function displayDate(cell, row) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {moment(row.date).format('DD/MM/YYYY HH:mm')}
      </div>
    )
  }
  function displayFollowUp(cell, row) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {moment(row.followUp).isValid()
          ? moment(row.followUp).format('DD/MM/YYYY')
          : 'No Follow Up Required'}
      </div>
    )
  }

  const columns = [
    {
      dataField: 'Edit',
      isDummyField: true,
      formatter: editButton,
      text: 'Update',
      headerStyle: () => ({ textAlign: 'center', width: '100px' }),
    },
    {
      dataField: 'date',
      text: 'Date of Interaction',
      // filter: textFilter({
      //     getFilter: (filter) => {
      //         stakeholderTypeFilter = filter;
      //     },
      // }),
      formatter: displayDate,
      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
    {
      dataField: 'initiator.name',
      text: 'Initiated by',
      // filter: textFilter({
      //     getFilter: (filter) => {
      //         designationFilter = filter;
      //     },
      // }),
      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
    {
      dataField: 'mode',
      text: 'Mode',
      // filter: textFilter({
      //     getFilter: (filter) => {
      //         stateFilter = filter;
      //     },
      // }),
      headerStyle: () => ({ textAlign: 'center', width: '100px' }),
    },
    {
      dataField: 'connectionStatus',
      text: 'Connection Status',
      // filter: textFilter({
      //     getFilter: (filter) => {
      //         districtFilter = filter;
      //     },
      // }),
      headerStyle: () => ({ textAlign: 'center', width: '100px' }),
    },
    {
      dataField: 'sentiment',
      text: 'Overall Sentiment',

      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
    {
      dataField: 'comments',
      text: 'Comments',
      headerStyle: () => ({ textAlign: 'center', width: '250px' }),
    },
    {
      dataField: 'nextAction',
      text: 'Next Action',

      headerStyle: () => ({ textAlign: 'center', width: '250px' }),
    },
    {
      dataField: 'toTakeAction.name',
      text: 'Action Pending on',

      headerStyle: () => ({ textAlign: 'center', width: '150px' }),
    },
    {
      dataField: 'followUp',
      text: 'Follow up date',
      formatter: displayFollowUp,
      headerStyle: () => ({ textAlign: 'center', width: '200px' }),
    },
  ]

  async function fetchData() {
    const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/interaction/${userId}`
    const config = { headers: { Authorization: AUTH_TOKEN } }
    axios.get(url, config).then(response => {
      if (response.status === 200 && response.data.error === false) {
        // console.log(response.data.interactions);
        setinteractions(response.data.interactions)
      }
    })
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function MyVerticallyCenteredModal(props) {
    const TODAY = new Date()
    const { onHide } = props
    const [fields, setFields] = useState(interactions) // Input Fields
    const [startDate, setStartDate] = useState(new Date()) // Date For Forms
    const [checkBox, setcheckBox] = useState(false)
    const [followUpDate, setFollowUpDateDate] = useState(new Date())
    const [newInteraction, setnewInteraction] = useState({
      userId: 4865437856294,
      date: moment().toDate(),
      initiator: false,
      mode: false,
      connectionStatus: false,
      sentiment: false,
      comments: false,
      nextAction: false,
      toTakeAction: false,
      followUp: moment().toDate(),
    })

    function updateUser() {
      const config = { headers: { Authorization: AUTH_TOKEN } }
      if (interactionDetails !== false) {
        setinteractions([...fields]) // Updating the changes from Modal
        const { _id } = interactions[interactionDetails]
        console.log(interactions[interactionDetails])
        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/interaction/${_id}`
        axios
          .put(
            url,
            {
              ...interactions[interactionDetails],
              stakeholder: stakeHolderObj,
            },
            config
          )
          .then(response => {
            // console.log(response);
            if (response.status === 200 && response.data.error === false) {
              swal('Successfully Updated', '', 'success')
              fetchData()
            }
          })
          .catch(err => {
            swal(err.response.data.errorMessage, '', 'error')
          })
      } else {
        interactions.push(newInteraction)
        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/interaction/add-interaction`
        axios
          .post(url, { ...newInteraction, stakeholder: stakeHolderObj }, config)
          .then(response => {
            // console.log(response);
            if (response.status === 200 && response.data.error === false) {
              // console.log(response.data);
              swal('Successfully Added', '', 'success')
              fetchData()
            }
          })
          .catch(err => {
            swal(err.response.data.errorMessage, '', 'error')
          })
      }
      onHide()
    }

    function handleInputChange(e, field) {
      // console.log(e.target.value, field);
      if (interactionDetails !== false) {
        const values = [...interactions]
        values[interactionDetails][field] = e.target.value
        setFields(values)
      } else {
        const tempInteraction = { ...newInteraction }
        tempInteraction[field] = e.target.value
        setnewInteraction(tempInteraction)
      }
    }

    function handleDropdownSelectChange(e, changeSource) {
      // console.log(e.target.value, changeSource);

      if (interactionDetails !== false) {
        const values = [...interactions]

        if (['toTakeAction', 'initiator'].indexOf(changeSource) > -1) {
          values[interactionDetails][changeSource] = e.target.value // === 'Me' ? poObj : stakeHolderObj;
          // console.log(values[interactionDetails], e.target.value, changeSource)
        } else {
          values[interactionDetails][changeSource] = e.target.value
          if (
            changeSource === 'connectionStatus' &&
            e.target.value === 'Unsuccessful Attempt'
          ) {
            values[interactionDetails]['sentiment'] = 'Cannot Say'
          }
        }
        setFields(values)
      } else {
        const tempInteraction = { ...newInteraction }
        if (['toTakeAction', 'initiator'].indexOf(changeSource) > -1) {
          console.log('heeer', e.target.value, changeSource)
          tempInteraction[changeSource] = e.target.value // === 'Me' ? poObj : stakeHolderObj;
        } else {
          tempInteraction[changeSource] = e.target.value
          if (
            changeSource === 'connectionStatus' &&
            e.target.value === 'Unsuccessful Attempt'
          ) {
            tempInteraction['sentiment'] = 'Cannot Say'
            setnewInteraction(tempInteraction)
          }
        }
        setnewInteraction(tempInteraction)
      }
    }

    function handleDateChange(e, field) {
      // console.log(e, field);
      if (field === 'date') setStartDate(new Date(e))
      else setFollowUpDateDate(new Date(e))
      // console.log(e.toString(), field);
      if (interactionDetails !== false) {
        const values = [...interactions]
        values[interactionDetails][field] = new Date(e) // change
        setFields(values)
      } else {
        const tempInteraction = { ...newInteraction }
        tempInteraction[field] = new Date(e) // change
        setnewInteraction(tempInteraction)
      }
    }

    // function isTrue(value) {
    //   return value !== false;
    // }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {interactionDetails !== false
              ? 'Edit Interaction'
              : 'Add New Interaction'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Date'}*
                </div>
                <div
                  style={{
                    height: '100%',
                    width: '60%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DatePicker
                    selected={
                      interactionDetails !== false
                        ? new Date(interactions[interactionDetails].date)
                        : startDate
                    }
                    className="datePicker"
                    maxDate={TODAY}
                    onChange={e => handleDateChange(e, 'date')}
                  />
                </div>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Initiator'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].initiator &&
                        interactions[interactionDetails]?.initiator.id ===
                          stakeHolderObj.id
                        ? 'stakeholder'
                        : 'Me'
                      : newInteraction.initiator || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={e => handleDropdownSelectChange(e, 'initiator')}
                >
                  {/* <option disabled value={-1}>Select Initiator</option>
                  <option>Me</option>
                  <option>User</option> */}
                  <option disabled value={-1}>
                    Select Initiator
                  </option>
                  <option value={'Me'}>{poObj.name} (Me)</option>
                  <option value={'stakeholder'}>
                    {stakeHolderObj.name} (Stakeholder)
                  </option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Mode Of Interaction'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].mode
                      : newInteraction.mode || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={e => handleDropdownSelectChange(e, 'mode')}
                >
                  <option disabled value={-1}>
                    Select Mode of Interaction
                  </option>
                  <option>Phone Call</option>
                  <option>Visit/Meeting</option>
                  <option>Conference Call</option>
                  <option>Video Call</option>
                  <option>Seminar</option>
                  <option>Fair</option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Connection Status'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].connectionStatus
                      : newInteraction.connectionStatus || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={e =>
                    handleDropdownSelectChange(e, 'connectionStatus')
                  }
                >
                  <option disabled value={-1}>
                    Select Connection Status
                  </option>
                  <option>Connected</option>
                  <option>Unsuccessful Attempt</option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Overall Sentiments'}*
                </div>
                <Form.Control
                  disabled={
                    interactionDetails !== false
                      ? interactions[interactionDetails].connectionStatus ===
                        'Unsuccessful Attempt'
                      : newInteraction.connectionStatus ===
                        'Unsuccessful Attempt'
                  }
                  value={
                    (
                      interactionDetails !== false
                        ? interactions[interactionDetails].connectionStatus ===
                          'Unsuccessful Attempt'
                        : newInteraction.connectionStatus ===
                          'Unsuccessful Attempt'
                    )
                      ? 'Cannot Say'
                      : interactionDetails !== false
                        ? interactions[interactionDetails].sentiment
                        : newInteraction.sentiment || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={e => handleDropdownSelectChange(e, 'sentiment')}
                >
                  <option disabled value={-1}>
                    Select Overall Sentiment
                  </option>
                  <option>Positive</option>
                  <option>Neutral</option>
                  <option>Negative</option>
                  <option>Cannot Say</option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Comments'}*
                </div>
                {/* <input
                  type="text"
                  placeholder={'Comments'}
                  onChange={(e) => handleInputChange(e, 'comments')}
                  style={{
                    width: '60%',
                    height: '100%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                  }}
                  required
                /> */}
                <textarea
                  type="text"
                  placeholder={'Comments'}
                  onChange={e => handleInputChange(e, 'comments')}
                  style={{
                    width: '60%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                    resize: 'none',
                  }}
                  defaultValue={
                    interactionDetails !== false
                      ? interactions[interactionDetails].comments
                      : ''
                  }
                  required
                ></textarea>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Next Action To Be Taken'}*
                </div>
                {/* <input
                  type="text"
                  placeholder={'Next Action To Be Taken'}
                  onChange={(e) => handleInputChange(e, 'nextAction')}
                  style={{
                    width: '60%',
                    height: '100%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                  }}
                  required
                /> */}
                <textarea
                  type="text"
                  placeholder={'Next Action To Be Taken'}
                  onChange={e => handleInputChange(e, 'nextAction')}
                  style={{
                    width: '60%',
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'lightgrey',
                    resize: 'none',
                  }}
                  defaultValue={
                    interactionDetails !== false
                      ? interactions[interactionDetails].nextAction
                      : ''
                  }
                  required
                ></textarea>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Action To Be Taken By'}*
                </div>
                <Form.Control
                  value={
                    interactionDetails !== false
                      ? interactions[interactionDetails].toTakeAction &&
                        interactions[interactionDetails]?.toTakeAction.id ===
                          stakeHolderObj.id
                        ? 'stakeholder'
                        : 'Me'
                      : newInteraction.toTakeAction || -1
                  }
                  as="select"
                  style={{ height: '100%', width: '60%' }}
                  onChange={e => handleDropdownSelectChange(e, 'toTakeAction')}
                >
                  {/* <option disabled value={-1}>Choose who has to take next action</option>
                  <option>Me</option>
                  <option>User</option> */}
                  <option disabled value={-1}>
                    Choose who has to take next action
                  </option>
                  <option value={'Me'}>{poObj.name} (Me)</option>
                  <option value={'stakeholder'}>
                    {stakeHolderObj.name} (Stakeholder)
                  </option>
                </Form.Control>
              </div>
              <div className="dynamicInputFields">
                <div
                  style={{
                    textAlign: 'right',
                    width: '30%',
                  }}
                >
                  {'Follow-Up on'} {checkBox ? null : '*'}
                </div>
                {interactionDetails !== false ? (
                  <div
                    style={{
                      height: '100%',
                      width: '60%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {interactions[interactionDetails].followUp !==
                      'No Follow Up' && (
                      <DatePicker
                        selected={
                          new Date(interactions[interactionDetails].followUp)
                        }
                        className="datePicker"
                        minDate={TODAY}
                        onChange={e => handleDateChange(e, 'followUp')}
                      />
                    )}

                    <Form>
                      <div key={'default-checkbox'}>
                        <Form.Check
                          checked={
                            interactions[interactionDetails].followUp ===
                            'No Follow Up'
                          }
                          type={'checkbox'}
                          id={'default-checkbox'}
                          label={'No scope of following up.'}
                          onChange={() => {
                            setcheckBox(!checkBox)
                            if (interactionDetails !== false) {
                              interactions[interactionDetails].followUp =
                                checkBox ? moment().toDate() : 'No Follow Up'
                            } else {
                              newInteraction.followUp = checkBox
                                ? moment().toDate()
                                : 'No Follow Up'
                            }
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                ) : (
                  <div
                    style={{
                      height: '100%',
                      width: '60%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {checkBox === false && (
                      <DatePicker
                        selected={followUpDate}
                        className="datePicker"
                        minDate={TODAY}
                        onChange={e => handleDateChange(e, 'followUp')}
                      />
                    )}
                    {/* {checkBox === false &&
                    {(
                    <DatePicker
                      selected={followUpDate}
                      className="datePicker"
                      minDate={moment().toDate()}
                      onChange={(e) => handleDateChange(e, 'followUp')}
                    />
                  )} */}
                    <Form>
                      <div key={'default-checkbox'}>
                        <Form.Check
                          type={'checkbox'}
                          id={'default-checkbox'}
                          label={'No scope of following up.'}
                          onChange={() => {
                            setcheckBox(!checkBox)
                            if (interactionDetails !== false) {
                              interactions[interactionDetails].followUp =
                                checkBox ? moment().toDate() : 'No Follow Up'
                            } else {
                              newInteraction.followUp = checkBox
                                ? moment().toDate()
                                : 'No Follow Up'
                            }
                          }}
                        />
                      </div>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          }
        </Modal.Body>
        <Modal.Footer>
          {interactionDetails !== false ? (
            // <Button onClick={updateUser}>Edit Interaction</Button>
            <Button
              onClick={() => {
                swal('Do you really want to edit this interaction?', {
                  buttons: {
                    catch: {
                      text: 'Yes',
                      value: 'Yes',
                    },
                    No: true,
                  },
                }).then(value => {
                  switch (value) {
                    case 'Yes':
                      updateUser()
                      break

                    default:
                  }
                })
              }}
            >
              Edit Interaction
            </Button>
          ) : (
            <Button
              // onClick={updateUser}
              onClick={() => {
                swal('Do you really want to create a new interaction?', {
                  buttons: {
                    catch: {
                      text: 'Yes',
                      value: 'Yes',
                    },
                    No: true,
                  },
                }).then(value => {
                  switch (value) {
                    case 'Yes':
                      updateUser()
                      break

                    default:
                  }
                })
              }}
              disabled={
                !newInteraction.comments ||
                !newInteraction.connectionStatus ||
                !newInteraction.followUp ||
                !newInteraction.date ||
                !newInteraction.initiator ||
                !newInteraction.mode ||
                !newInteraction.nextAction ||
                !newInteraction.userId ||
                !newInteraction.sentiment ||
                !newInteraction.toTakeAction
              }
            >
              Add Interaction
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
  MyVerticallyCenteredModal.propTypes = {
    onHide: PropTypes.func,
  }

  return (
    <div className="Tab2Container">
      <div className="Tab2EditButtonContainer">
        <div className="Tab2EditButton">
          <div
            className="Tab2EditButtonText"
            // onClick={() => {
            //   swal('Do you really want to create a new interaction?', {
            //     buttons: {
            //       catch: {
            //         text: 'Yes',
            //         value: 'Yes',
            //       },
            //       No: true,
            //     },
            //   })
            //   .then((value) => {
            //     switch (value) {
            //       case 'Yes':
            //         setModalShow(true);
            //         break;

            //       default:
            //     }
            //   });
            // }}
            onClick={() => {
              setModalShow(true)
            }}
          >
            <FileAddFilled />
            Add New Interaction
          </div>
        </div>
      </div>
      <div className="Tab2Table">
        <ToolkitProvider
          bootstrap4
          keyField="mobileNumber"
          data={interactions}
          columns={columns}
          search
        >
          {props => (
            <div className="homeTableContainer">
              <hr />
              <div style={{ paddingLeft: 20 }}>
                Search: <SearchBar {...props.searchProps} />
              </div>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory({
                  sizePerPage: 50,
                  sizePerPageList: [50, 100, 150, 200],
                })}
                // filter={ filterFactory() }
              />
            </div>
          )}
        </ToolkitProvider>

        {/* <Table striped hover variant="light" size="lr">
          <thead>
            <tr>
              <th className='tdStyle'>S. N0.</th>
              <th className='tdStyle'>Edit</th>
              <th className='tdStyle'>Date of Interaction</th>
              <th className='tdStyle'>Initiated by</th>
              <th className='tdStyle'>Mode</th>
              <th className='tdStyle'>Connection Status</th>
              <th className='tdStyle'>Overall Sentiment</th>
              <th className='tdStyle'>Comments</th>
              <th className='tdStyle'>Next Action</th>
              <th className='tdStyle'>Action Pending on</th>
              <th className='tdStyle'>Follow up date</th>
            </tr>
          </thead>
          <tbody>
            {interactions.map((item, index) => (
              <tr key={index}>
                <td className='tdStyle'>{interactions.length - index}</td>
                <td
                  style={{ cursor: 'pointer' }}
                  // onClick={() => {
                  //   swal('Do you really want to edit this interaction?', {
                  //     buttons: {
                  //       catch: {
                  //         text: 'Yes',
                  //         value: 'Yes',
                  //       },
                  //       No: true,
                  //     },
                  //   })
                  //   .then((value) => {
                  //     switch (value) {
                  //       case 'Yes':
                  //         setModalShow(true);
                  //         setinteractionDetails(index);
                  //         break;

                  //       default:
                  //     }
                  //   });
                  // }}
                  onClick={() => {
                    setModalShow(true);
                    setinteractionDetails(index);
                  }}
                >
                  <EditFilled/>
                </td>
                <td className='tdStyle'>{moment(item.date).format('DD/MM/YYYY HH:mm')}</td>
                <td className='tdStyle'>{item.initiator}</td>
                <td className='tdStyle'>{item.mode}</td>
                <td className='tdStyle'>{item.connectionStatus}</td>
                <td className='tdStyle'>{item.sentiment}</td>
                <td className='tdStyle'>{item.comments}</td>
                <td className='tdStyle'>{item.nextAction}</td>
                <td className='tdStyle'>{item.toTakeAction}</td>
                <td className='tdStyle'>{moment(item.followUp).format('DD/MM/YYYY HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          setinteractionDetails(false)
        }}
      />
    </div>
  )
}

export default Tab2
