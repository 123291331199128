import React, {
  // useCallback,
  useEffect,
  useState,
} from 'react'
import CenterList from './centerList'
import ShowButton from '../FormElements/addCenterButton'
import { useHttpHook } from '../../hooks/http-hook'
import { DatePicker, Select, Spin } from 'antd'
import { Button } from 'react-bootstrap'
import { LoadingOutlined } from '@ant-design/icons'

const readCookie = require('../../../cookie.js').readCookie
const userId = 'dsbcjds ' //JSON.parse(readCookie('userData')).id;
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker

const UserCenter = () => {
  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState()
  const OPTIONS = ['RSETI', 'PMKK', 'JSS', 'DDU-GKY', 'Other-Training-centre']
  const [loadedCenter, setLoadedCenter] = useState()
  const { isLoading, error, sendRequest, clearError } = useHttpHook()
  const [date, setDate] = useState({ fromDate: null, toDate: null })
  const [state, setState] = useState('States')
  const [district, setDistrict] = useState('District')
  const [types, setTypes] = useState('Type')
  const [stateload, setStateLoad] = useState()
  const [districtload, setDistrictLoad] = useState()
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, marginLeft: '50rem' }} spin />
  )

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + `/api/v1/center/user/All`
        )
        setLoadedCenter(responseData.center)
      } catch (err) {}
    }
    fetchPlaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest, userId])

  const reset = async () => {
    setState('State')
    setDistrict('District')
    setTypes('Types')
    setDate({
      fromDate: null,
      toDate: null,
    })
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL + `/api/v1/center/user/All`
      )
      setLoadedCenter(responseData.center)
    } catch (err) {}
  }

  //state load

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/location/states'
        )
        setStateLoad(responseData)
      } catch (err) {}
    }
    fetchStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //add user id dependency
  const centerDeleteHandler = deleteCenterId => {
    setLoadedCenter(prevCenter =>
      prevCenter.filter(center => center.id !== deleteCenterId)
    )
  }

  const dateChange = async current => {
    /* setLoadedCenter((prevCenter) =>
           prevCenter.filter((center) => moment(center.addedon).is(current[0], current[1]))     
          ); */
    setDate({
      ...date,
      fromDate: current[0],
      toDate: current[1],
    })

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL +
          `/api/v1/center/filter/date/${current[0]}/${current[1]}`
      )
      setLoadedCenter(responseData.center)
      // console.log(responseData);
    } catch (err) {
      alert(error + ' ' + err)
    }
  }

  const stateHandler = async (event, option) => {
    setState(event)
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/districts?state=' +
          event
      )
      //console.log("district  " + JSON.stringify(responseData));
      setDistrictLoad(responseData)
    } catch (err) {}

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL + `/api/v1/center/filter/states/${event}`
      )
      setLoadedCenter(responseData.center)
      //  console.log(responseData.center);
    } catch (err) {
      alert('Not Found')
    }
  }

  const districtHandler = (event, option) => {
    setDistrict(option.props.children)
    //  console.log(option.props.children);
    setLoadedCenter(prevCenter =>
      prevCenter.filter(center => center.district === option.props.children)
    )
  }

  const typeHandlerChange = item => {
    setType(item)
    setTypes(item)

    setLoadedCenter(prevCenter =>
      prevCenter.filter(center => center.type === item)
    )
  }

  return (
    <React.Fragment>
      {/** Errormodel */}

      <ShowButton />
      {clearError}
      <div style={{ marginLeft: '18rem', marginTop: '15rem' }}>
        <h5>Filter</h5>
        <RangePicker
          value={[date.fromDate, date.toDate]}
          onChange={dateChange}
        />
        <Select
          style={{ width: 200 }}
          value={state}
          onChange={stateHandler}
          placeholder="State"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <OptGroup label="Focus States">
            {!isLoading &&
              stateload !== undefined &&
              stateload.data.states.map((state, stateIndex) => {
                if (state.inFocusRegion) {
                  if (
                    JSON.parse(readCookie('userData')).userType.name ===
                      'Swavalamban Fellows' &&
                    JSON.parse(readCookie('userData')).address.state.includes(
                      state.name
                    )
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else if (
                    JSON.parse(readCookie('userData')).userType.name !==
                    'Swavalamban Fellows'
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else return null
                } else return null
              })}
          </OptGroup>
          <OptGroup label="Not Focussed States">
            {!isLoading &&
              stateload !== undefined &&
              stateload.data.states.map((state, stateIndex) => {
                if (!state.inFocusRegion) {
                  if (
                    JSON.parse(readCookie('userData')).userType.name ===
                      'Swavalamban Fellows' &&
                    JSON.parse(readCookie('userData')).address.state.includes(
                      state.name
                    )
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else if (
                    JSON.parse(readCookie('userData')).userType.name !==
                    'Swavalamban Fellows'
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else return null
                } else return null
              })}
          </OptGroup>
        </Select>
        <Select
          style={{ width: 200 }}
          placeholder="District"
          value={district}
          onChange={districtHandler}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {!isLoading &&
            districtload !== undefined &&
            districtload.districts.map((district, districtIndex) => {
              return (
                <Option key={districtIndex} value={district._id}>
                  {district.name}
                </Option>
              )
            })}
        </Select>
        <Select
          placeholder="Type"
          value={types}
          onChange={typeHandlerChange}
          style={{ width: 200 }}
        >
          {OPTIONS.map(type => {
            return (
              <Option key={type.toString()} value={type}>
                {type}
              </Option>
            )
          })}
        </Select>
        <Button onClick={reset}>Reset</Button>
      </div>

      {isLoading && <Spin indicator={antIcon} />}
      {!isLoading && (
        <CenterList items={loadedCenter} onDeleteCenter={centerDeleteHandler} />
      )}
    </React.Fragment>
  )
}

export default UserCenter
