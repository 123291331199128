import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  usePagination,
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table'
import ChatLogModal from './ChatModal'
import { jwtDecode } from 'jwt-decode'
import { getAllChats } from '../reduxLogic/reducers/chats/getAllChatsSlice'
import { searchMobileUser } from '../reduxLogic/reducers/auth/searchMobileUser'
import { getAllProjects } from '../reduxLogic/reducers/projects/getAllProjectSlice'
import editIcon from '../assets/images/edit.png'
import deleteIcon from '../assets/images/delete.png'
import { changeDateTimeFormat } from '../shared/Helper'
import * as Styles from './style'
import axios from 'axios'
import Select from 'react-select'
import UserForm from '../Farmers/components/UserForm/UserForm'
import Cookies from 'universal-cookie'
import { act } from 'react'
import Header from 'antd/lib/calendar/Header'
const cookies = new Cookies()

const options = [
  { value: 'super_admin', label: 'Super Admin' },
  { value: 'beekind_app_admin', label: 'Beekind App Admin' },
  { value: 'field_view_app_admin', label: 'Field View App Admin' },
  { value: 'bee_mitra', label: 'Bee Mitra' },
  { value: 'community_leader', label: 'Community Leader' },
  { value: 'beekeeper', label: 'Beekeeper' },
  { value: 'trainee_beekeeper', label: 'Trainee Beekeeper' },
  { value: 'farmer', label: 'Farmer' },
  { value: 'expert', label: 'Expert' },
  { value: 'funder', label: 'Funder' },
]

const roleFilterOptions = [
  { value: '', label: 'All' },
  ...options, // Reuse the same roles from the previous options array
]

const USERS_TABLE_COLUMNS = [
  {
    Header: 'Mobile',
    maxWidth: 80,
    minWidth: 80,
    width: 80,
    accessor: 'uid',
  },
  {
    Header: 'Total Messages Sent',
    maxWidth: 80,
    minWidth: 80,
    width: 80,
    accessor: 'total_messages',
  },
  {
    Header: 'Total Read',
    maxWidth: 80,
    minWidth: 80,
    width: 80,
    accessor: 'total_read',
  },
  {
    Header: 'Total UnRead',
    maxWidth: 80,
    minWidth: 80,
    width: 80,
    accessor: 'total_unread',
  },
  // {
  //   Header: 'Updated On',
  //   maxWidth: 80,
  //   minWidth: 80,
  //   width: 80,
  //   accessor: 'updatedAt',
  // },
  {
    Header: 'Actions',
  },
]

const ChatsTable = () => {
  const dispatch = useDispatch()
  const { token } = '' //useSelector((state) => state.auth || {});
  const currentToken = cookies.get('access_token')

  const [mobileNumber, setMobileNumber] = useState('')
  const [filterByRole, setFilterByRole] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [chatLogs, setChatLogs] = useState([])

  useEffect(() => {
    dispatch(getAllChats())
    dispatch(getAllProjects())
  }, [dispatch, token])

  let role
  if (currentToken?.length > 0) {
    const decoded = jwtDecode(currentToken)
    role = decoded?.role
  }
  const [searchInput, setSearchInput] = useState('') // Renamed for clarity

  const { allChats } = useSelector(state => state.allChats)
  const { mobileUser } = useSelector(state => state.mobileUser)

  const { projectData } = useSelector(state => state.allProjects)

  const columns = useMemo(() => USERS_TABLE_COLUMNS, [])

  const data = useMemo(() => {
    const sourceData = allChats?.chat_logs || []
    console.log('sourceData', sourceData)
    return sourceData
  }, [allChats])

  // console.log("data", data);
  // //if not data then return unable to fetch data
  // if (data.message === "Could not fetch users") {
  //   console.log("data.message", data.message);
  //   return <h3>Unable to fetch data</h3>;
  // }

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance
  const { pageIndex } = state

  const handlePatchRequestChangeRole = async (_id, newRole, action) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_APP_USER_URL}/user/update-user-role`,
        {
          userId: _id,
          action: action,
          userRole: newRole,
        },
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
            source: 'humblebee_admin',
          },
        }
      )
      dispatch(getAllChats())
      if (mobileNumber?.length === 10) {
        dispatch(searchMobileUser(mobileNumber))
      }
      alert(`Role ${action === 'add' ? 'added' : 'removed'} successfully`)
    } catch (error) {
      console.log('There was some error while changing role => ', error)
    }
  }

  const handleRoleChange = (_id, event) => {
    const selectedRoles = event.map(option => option.value)
    const existingRoles = data.find(user => user.userInfo._id === _id).userInfo
      .userRoles

    // Detect if a role is being added or removed
    let action = ''
    let changedRole = ''

    if (selectedRoles.length > existingRoles.length) {
      // Role added
      action = 'add'
      changedRole = selectedRoles.find(role => !existingRoles.includes(role))
    } else {
      // Role removed
      action = 'remove'
      changedRole = existingRoles.find(role => !selectedRoles.includes(role))
    }

    const confirmChange = window.confirm(
      `Are you sure you want to edit the role?\n\nDetails:\nName: ${
        data.find(user => user.userInfo._id === _id).userInfo.name
      }\nMobile: ${
        data.find(user => user.userInfo._id === _id).userInfo.mobileNumber
      }\nRole: ${changedRole}`
    )

    if (confirmChange) {
      handlePatchRequestChangeRole(_id, changedRole, action)
    } else {
      alert('Role change cancelled')
    }
  }
  // const handleMobileSearch = (mobileNumber) => {
  //   setMobileNumber(mobileNumber);
  //   if (mobileNumber?.length === 10) {
  //     dispatch(searchMobileUser(mobileNumber));
  //   } else if (mobileNumber?.length === 0) {
  //     dispatch(getAllUsers());
  //     dispatch(searchMobileUser(mobileNumber));
  //   }
  // };

  const handleMobileSearch = value => {
    setSearchInput(value) // Update searchInput state
    // Since filtering is done through useMemo, no need to dispatch here
  }

  const handleModalToggle = (id, log) => {
    setOpenModal(prev => !prev)

    // Reverse the array and set it
    setChatLogs(log.messages.slice().reverse())
  }

  const handleOpenEditModal = (_id, user) => {
    return
  }

  return (
    <Styles.UserBox>
      <div style={{ height: '10vh' }}></div>
      <Styles.TableContainer>
        <Styles.SearchContainer>
          {/* <Styles.SearchInput
            type="text"
            placeholder="Search by name / mobile number"
            onChange={e => handleMobileSearch(e.target.value)}
          /> */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '10px',
            }}
          >
            <div>
              <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Total Users Count:
              </p>
              <p>{data?.length}</p>
            </div>
            <div>
              <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Total Messages Sent:
              </p>
              <p>{allChats?.total_messages}</p>
            </div>
            <div>
              <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Total Messages Read:
              </p>
              <p>{allChats?.total_read}</p>
            </div>
            <div>
              <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                Total Messages Unread:
              </p>
              <p>{allChats?.total_unread}</p>
            </div>
          </div>

          {openModal ? (
            <ChatLogModal
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
              chatLogs={chatLogs}
            />
          ) : null}
        </Styles.SearchContainer>
        {data ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map(headerGroup => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(headerColumn => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps({
                        style: {
                          minWidth: headerColumn.minWidth,
                          width: headerColumn.width,
                        },
                        // headerColumn.getSortByToggleProps()
                      })}
                    >
                      {headerColumn.render('Header')}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? ' 🔼'
                            : ' 🔽'
                          : ''}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map(singleRow => {
                prepareRow(singleRow)

                return (
                  <Styles.TBodyRow {...singleRow.getRowProps()}>
                    {singleRow.cells.map((singleCell, index) => (
                      <Styles.TD key={index}>
                        <span>
                          {singleCell.column.id === 'userInfo.addedAt' ||
                          singleCell.column.id === 'userInfo.updatedAt' ? (
                            <span>
                              {singleCell?.value?.length > 0
                                ? changeDateTimeFormat(singleCell?.value)
                                : '-'}
                            </span>
                          ) : (
                            <>
                              <span>
                                {singleCell.column.id ===
                                'userInfo.userRoles' ? (
                                  <div
                                    style={{
                                      width: 'auto',
                                    }}
                                  >
                                    <Select
                                      isMulti={true}
                                      value={singleCell.value.map(role => {
                                        return options.find(
                                          option => option.value === role
                                        )
                                      })}
                                      options={options}
                                      onChange={event =>
                                        handleRoleChange(
                                          singleCell?.row?.original?.userInfo
                                            ?._id,
                                          event
                                        )
                                      }
                                      styles={{
                                        // container: (provided) => ({
                                        //   width: "200px",
                                        // }),
                                        control: base => ({
                                          ...base,
                                          minHeight: '30px', // Control the dropdown height
                                        }),
                                        menu: base => ({
                                          ...base,
                                          zIndex: 9999, // Ensures dropdown appears above other elements
                                        }),
                                        menuPortal: base => ({
                                          ...base,
                                          zIndex: 9999, // Portal the dropdown so it doesn't get cut off
                                        }),
                                        option: base => ({
                                          ...base,
                                          cursor: 'pointer',
                                        }),
                                      }}
                                      menuPortalTarget={document.body} // Ensure dropdown appears outside table
                                    />
                                  </div>
                                ) : (
                                  <span>{singleCell.render('Cell')}</span>
                                )}
                              </span>
                              <span>
                                {singleCell.column.Header === 'Actions' ? (
                                  <Styles.ActionSpan>
                                    <button
                                      style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        border: 'none',
                                        cursor: 'pointer',
                                        width: 'fit-content',
                                      }}
                                      onClick={() =>
                                        handleModalToggle(
                                          singleCell?.row?.original?._id,
                                          singleRow?.original
                                        )
                                      }
                                    >
                                      View Chat Log
                                    </button>
                                  </Styles.ActionSpan>
                                ) : null}
                              </span>
                            </>
                          )}
                        </span>
                      </Styles.TD>
                    ))}
                  </Styles.TBodyRow>
                )
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>There was some error displaying table</h3>
        )}
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page {pageIndex + 1} of {pageOptions.length}
            <br />
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            Previous
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(jumpToPageNumber)
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            Next
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>
    </Styles.UserBox>
  )
}

export default ChatsTable
