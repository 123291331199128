import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { useHttpHook } from '../../hooks/http-hook'
import { Spin, Table, Tag } from 'antd'

const Navpage = props => {
  const [Data, setData] = useState({
    id: {
      value: '',
    },
    type: {
      value: '',
    },
    state: {
      value: '',
    },
    district: {
      value: '',
    },
    pincode: {
      value: '',
    },
    rating: {
      value: '',
    },
    database: {
      value: '',
    },
    trained: {
      value: '',
    },
    employed: {
      value: '',
    },
    settlement: {
      value: '',
    },
  })

  //const [loadedCenter, setLoadedCenter] = useState();
  const {
    isLoading,
    // error,
    sendRequest,
    clearError,
  } = useHttpHook()
  const centerId = props.match.params.centerId

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + `/api/v1/center/${centerId}`
        )
        //setLoadedCenter(responseData.center);
        setData({
          id: {
            value: responseData.center.id,
          },
          type: {
            value: responseData.center.type,
          },
          state: {
            value: responseData.center.state,
          },
          district: {
            value: responseData.center.district,
          },
          pincode: {
            value: responseData.center.pincode,
          },
          rating: {
            value: responseData.center.rating,
          },
          database: {
            value: responseData.center.database,
          },
          trained: {
            value: responseData.center.trained,
          },
          employed: {
            value: responseData.center.employed,
          },
          settlement: {
            value: responseData.center.settlement,
          },
        })
        //console.log(responseData.center.id);
      } catch (err) {}
    }
    fetchPlaces()
  }, [sendRequest, centerId])

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: text => <span>{text}</span>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'District',
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: 'Pincode',
      dataIndex: 'pincode',
      key: 'pincode',
    },
    {
      title: 'Rating',
      key: 'rating',
      dataIndex: 'rating',
      render: tags => {
        let color = ''
        if (tags === 'Excellent') color = 'green'
        else if (tags === 'Average') color = 'geekblue'
        else color = 'volcano'
        return (
          <Tag color={color} key={tags}>
            {tags}
          </Tag>
        )
      },
    },
    {
      title: 'Database',
      dataIndex: 'database',
      key: 'database',
    },
    {
      title: 'Trained Candidate',
      dataIndex: 'trainedCandidate',
      key: 'trainedCandidate',
    },
    {
      title: 'Employed',
      dataIndex: 'employed',
      key: 'employed',
    },
    {
      title: 'Settlement',
      dataIndex: 'settlement',
      key: 'settlement',
    },
  ]

  const datta = [
    {
      key: Data.id.value,
      type: Data.type.value,
      state: Data.state.value,
      district: Data.district.value,
      pincode: Data.pincode.value,
      rating: Data.rating.value,
      database: Data.database.value,
      trainedCandidate: Data.trained.value,
      employed: Data.employed.value,
      settlement: Data.settlement.value,
    },
  ]

  return (
    <div>
      {isLoading && (
        <Spin size="large" style={{ marginTop: '6rem', marginLeft: '50rem' }} />
      )}
      {!isLoading && clearError && (
        <Table
          columns={columns}
          dataSource={datta}
          style={{ marginTop: '6rem', marginLeft: '16rem', maxWidth: '85rem' }}
        />
      )}
      <Navbar />
    </div>
  )
}

export default Navpage
