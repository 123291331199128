import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const currentToken = localStorage.getItem('humblebeeAuthToken')
const initialState = {
  status: '',
  beeMitraData: [],
  loading: false,
  error: '',
}

export const deleteBeeMitra = createAsyncThunk('deleteBeeMitra', async _id => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_HUMBLEBEE_API_URL}/bee-mitras/${_id}`,
      {
        headers: {
          authorization: `Bearer ${currentToken}`,
        },
      }
    )

    return res.data.beemitra
  } catch (error) {
    return error.response.data
  }
})

const deleteBeeMitraSlice = createSlice({
  name: 'deleteBeeMitra',
  initialState,
  reducers: {},

  extraReducers: {
    [deleteBeeMitra.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [deleteBeeMitra.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.beeMitraData = payload
      }
    },

    [deleteBeeMitra.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default deleteBeeMitraSlice.reducer
