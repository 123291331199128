/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  // Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { ToastContainer, toast } from 'react-toastify'
import { DatePicker, Spin, Select, Button } from 'antd'
import { useHttpHook } from '../components/hooks/http-hook.js'
import {
  LineChart,
  Line,
  // ReferenceLine
} from 'recharts'
import moment from 'moment'

const { RangePicker } = DatePicker
const readCookie = require('../../cookie.js').readCookie
const { Option, OptGroup } = Select
moment.locale('en')

const Analytics = () => {
  const [state, setState] = useState('')
  const [infoDistrict, setinfoDistrict] = useState()
  const [lineData, setlineData] = useState([])
  const [prev, setPrev] = useState()
  const {
    isLoading,
    error,
    sendRequest,
    // clearError
  } = useHttpHook()
  const [data, setData] = useState()
  const [district, setDistrict] = useState([])
  const [stateload, setStateLoad] = useState()
  const [fellows, setFellows] = useState([])
  const [infoDistrict1, setinfoDistrict1] = useState()
  const [cluster, setCluster] = useState('')
  const [linestate, setLineState] = useState('')
  const [linedistrict, setLineDistrict] = useState([])
  const [date, setDate] = useState({ fromDate: null, toDate: null })
  const [lineDate, setLineDate] = useState({ fromDate: null, toDate: null })
  const [checkbox, setCheckbox] = useState(true)
  const [checkBarDate, setCheckBarDate] = useState({
    fromDate: null,
    toDate: null,
  })

  const stateHandlerBar = async (event, option) => {
    setState(event)
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/get-address?stateId=' +
          event
      )

      //console.log(responseData.districts);
      setDistrict(responseData.districts.map(data => data.name))
    } catch (err) {
      alert('district not found')
    }
  }

  const stateHandlerLine = async (event, option) => {
    setLineState(event)
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/get-address?stateId=' +
          event
      )

      //console.log(responseData.districts);
      setLineDistrict(responseData.districts.map(data => data.name))
    } catch (err) {
      alert('district not found')
    }
  }

  useEffect(() => {
    if (
      linedistrict.length !== 0 &&
      lineDate.fromDate !== null &&
      lineDate.toDate !== null
    ) {
      const fetchData = async () => {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_API_URL +
              `/api/v1/clusterData/linechart-data?state=${linestate}&district=${linedistrict}&fromDate=${moment(
                lineDate.fromDate
              ).format('DD-MM-YYYY')}&toDate=${moment(lineDate.toDate).format(
                'DD-MM-YYYY'
              )}`
          )
          setlineData(responseData.resu)
          console.log(responseData)
        } catch (err) {}
      }
      fetchData()
    } else if (
      linedistrict.length !== 0 &&
      lineDate.fromDate === null &&
      lineDate.toDate === null
    ) {
      toast.error('Please select the date', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linedistrict])

  useEffect(() => {
    const fetchinfographic = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/clusterData/infographic-data?state=&district=Agra,Aligarh,Ambedkar Nagar,Amethi,Amroha,Aurangabad,Azamgarh,Bahraich,Balangir,Balrampur,Banda,Banka,Bargarh,Begusarai,Bhagalpur,Bhojpur,Bijnor,Bokaro,Budaun,Chandauli,Chitrakoot,Cuttack,Darbhanga,Deoghar,Deoria,Dhanbad,Dhenkanal,Dumka,East Singhbhum,Farrukhabad,Fatehpur,Firozabad,Gaya,Ghaziabad,Ghazipur,Giridih,Godda,Gonda,Gorakhpur,Gumla,Hardoi,Hathras,Hazaribagh,Hyderabad,Jagatsinghpur,Jajapur,Jalaun,Jamtara,Jangaon,Jaunpur,Jayashankar Bhupalapally,Jehanabad,Jharsuguda,Kalahandi,Kanpur Dehat,Kanpur Nagar,Karimnagar,Kaushambi,Kendrapara,Khagaria,Khammam,Khordha,Koraput,Lakhisarai,Lucknow,Madhubani,Mahabubabad,Mathura,Medak,Medchal,Mirzapur,Moradabad,Munger,Muzaffarpur,Nabarangapur,Nalanda,Nalgonda,Nawada,Nuapada,Pakur,Patna,Pratapgarh,Prayagraj,Puri,Purvi Champaran,Rae Bareli,Ramgarh,Rampur,Ranchi,Rangareddy,Rayagada,Saharanpur,Sahibganj,Samastipur,Sambalpur,Sangareddy,Sant Kabir Nagar,Sant Ravidas Nagar,Saran,Seraikela-Kharsawan,Shrawasti,Siddharthnagar,Siddipet,Sitamarhi,Siwan,Sonbhadra,Subarnapur,Suryapet,Unnao,Vaishali,Varanasi,Vikarabad,Warangal,Warangal Rural,Warangal Urban,West Singhbhum`
        )
        console.log(responseData)
        setinfoDistrict(responseData.resu)
        setPrev(responseData.resu)
      } catch (err) {}
    }
    fetchinfographic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //console.log(infoDistrict1);

  useEffect(() => {
    if (district.length !== 0) {
      let apiUrl = process.env.REACT_APP_API_URL + '/api/v1/clusterData',
        query = ''
      if (date.fromDate === null || date.toDate === null)
        query = `/infographic-data?state=${state}&district=${district}`
      else
        query = `/infographic-data?state=${state}&district=${district}&toDate=${date.toDate}&fromDate=${date.fromDate}`

      const fetchDistrict = async () => {
        try {
          const responseData = await sendRequest(apiUrl + query)
          //  console.log(district);
          setinfoDistrict(responseData.resu)
          //  setinfoDistrict1(responseData.resu);
        } catch (err) {}
      }
      fetchDistrict()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [district])

  const dateChangerCheckBox = async current => {
    setCheckBarDate({
      ...checkBarDate,
      fromDate: current[0],
      toDate: current[1],
    })
    let apiUrl = process.env.REACT_APP_API_URL + '/api/v1/clusterData',
      query = ''
    if (state === '' || district.length === 0) {
      query = `/infographic-data?state=&district=Agra,Aligarh,Ambedkar Nagar,Amethi,Amroha,Aurangabad,Azamgarh,Bahraich,Balangir,Balrampur,Banda,Banka,Bargarh,Begusarai,Bhagalpur,Bhojpur,Bijnor,Bokaro,Budaun,Chandauli,Chitrakoot,Cuttack,Darbhanga,Deoghar,Deoria,Dhanbad,Dhenkanal,Dumka,East Singhbhum,Farrukhabad,Fatehpur,Firozabad,Gaya,Ghaziabad,Ghazipur,Giridih,Godda,Gonda,Gorakhpur,Gumla,Hardoi,Hathras,Hazaribagh,Hyderabad,Jagatsinghpur,Jajapur,Jalaun,Jamtara,Jangaon,Jaunpur,Jayashankar Bhupalapally,Jehanabad,Jharsuguda,Kalahandi,Kanpur Dehat,Kanpur Nagar,Karimnagar,Kaushambi,Kendrapara,Khagaria,Khammam,Khordha,Koraput,Lakhisarai,Lucknow,Madhubani,Mahabubabad,Mathura,Medak,Medchal,Mirzapur,Moradabad,Munger,Muzaffarpur,Nabarangapur,Nalanda,Nalgonda,Nawada,Nuapada,Pakur,Patna,Pratapgarh,Prayagraj,Puri,Purvi Champaran,Rae Bareli,Ramgarh,Rampur,Ranchi,Rangareddy,Rayagada,Saharanpur,Sahibganj,Samastipur,Sambalpur,Sangareddy,Sant Kabir Nagar,Sant Ravidas Nagar,Saran,Seraikela-Kharsawan,Shrawasti,Siddharthnagar,Siddipet,Sitamarhi,Siwan,Sonbhadra,Subarnapur,Suryapet,Unnao,Vaishali,Varanasi,Vikarabad,Warangal,Warangal Rural,Warangal Urban,West Singhbhum&toDate=${moment(
        current[1]
      ).format('DD-MM-YYYY')}&fromDate=${moment(current[0]).format(
        'DD-MM-YYYY'
      )}`
    } else {
      query = `/infographic-data?state=${state}&district=${district}&toDate=${current[1]}&fromDate=${current[0]}`
    }
    try {
      const responseData = await sendRequest(apiUrl + query)
      // console.log(responseData);
      setinfoDistrict1(responseData.resu)
    } catch (err) {}
  }

  const dateChanger = async current => {
    setDate({
      ...date,
      fromDate: current[0],
      toDate: current[1],
    })
    //console.log(current[0] +" " +curr;
    let apiUrl = process.env.REACT_APP_API_URL + '/api/v1/clusterData',
      query = ''
    if (state === '' || district.length === 0) {
      query = `/infographic-data?state=&district=Agra,Aligarh,Ambedkar Nagar,Amethi,Amroha,Aurangabad,Azamgarh,Bahraich,Balangir,Balrampur,Banda,Banka,Bargarh,Begusarai,Bhagalpur,Bhojpur,Bijnor,Bokaro,Budaun,Chandauli,Chitrakoot,Cuttack,Darbhanga,Deoghar,Deoria,Dhanbad,Dhenkanal,Dumka,East Singhbhum,Farrukhabad,Fatehpur,Firozabad,Gaya,Ghaziabad,Ghazipur,Giridih,Godda,Gonda,Gorakhpur,Gumla,Hardoi,Hathras,Hazaribagh,Hyderabad,Jagatsinghpur,Jajapur,Jalaun,Jamtara,Jangaon,Jaunpur,Jayashankar Bhupalapally,Jehanabad,Jharsuguda,Kalahandi,Kanpur Dehat,Kanpur Nagar,Karimnagar,Kaushambi,Kendrapara,Khagaria,Khammam,Khordha,Koraput,Lakhisarai,Lucknow,Madhubani,Mahabubabad,Mathura,Medak,Medchal,Mirzapur,Moradabad,Munger,Muzaffarpur,Nabarangapur,Nalanda,Nalgonda,Nawada,Nuapada,Pakur,Patna,Pratapgarh,Prayagraj,Puri,Purvi Champaran,Rae Bareli,Ramgarh,Rampur,Ranchi,Rangareddy,Rayagada,Saharanpur,Sahibganj,Samastipur,Sambalpur,Sangareddy,Sant Kabir Nagar,Sant Ravidas Nagar,Saran,Seraikela-Kharsawan,Shrawasti,Siddharthnagar,Siddipet,Sitamarhi,Siwan,Sonbhadra,Subarnapur,Suryapet,Unnao,Vaishali,Varanasi,Vikarabad,Warangal,Warangal Rural,Warangal Urban,West Singhbhum&toDate=${moment(
        current[1]
      ).format('DD-MM-YYYY')}&fromDate=${moment(current[0]).format(
        'DD-MM-YYYY'
      )}`
    } else {
      query = `/infographic-data?state=${state}&district=${district}&toDate=${current[1]}&fromDate=${current[0]}`
    }
    try {
      const responseData = await sendRequest(apiUrl + query)
      console.log(responseData)
      setinfoDistrict(responseData.resu)
    } catch (err) {}
  }

  const resetHandler = async () => {
    setState('')
    setDate({
      fromDate: null,
      toDate: null,
    })

    setinfoDistrict(prev)
  }

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/location/states'
        )
        setStateLoad(responseData)
      } catch (err) {}
    }
    fetchStates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchFellows = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            '/api/v1/user/users/swavalamban-fellows?type=list&status=Active&fellowAccessType=Full'
        )
        console.log(responseData)
        setFellows({ swavalambanFellows: responseData.users })
      } catch (err) {}
    }
    fetchFellows()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //console.log(infographic);

  /**
   *    line chart functions
   *
   */

  useEffect(() => {
    if (infoDistrict1 !== undefined && checkbox === false) {
      let arr = []
      let profiles = 0,
        dds = 0,
        loans = 0,
        enters = 0,
        bps = 0
      let profiles1 = 0,
        dds1 = 0,
        loans1 = 0,
        enters1 = 0,
        bps1 = 0

      if (fellows.length !== 0 && infoDistrict !== undefined && state === '') {
        fellows.swavalambanFellows.map(f => {
          f.district.map(d => {
            profiles =
              profiles +
              infoDistrict[d.replace(/[-" "]+/g, '')].profileCreationStage
            dds =
              dds + infoDistrict[d.replace(/[-" "]+/g, '')].dueDiligenceStage
            loans =
              loans +
              infoDistrict[d.replace(/[-" "]+/g, '')].loanApplicationStage
            enters =
              enters +
              infoDistrict[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
            bps = bps + infoDistrict[d.replace(/[-" "]+/g, '')].bPlanStage
            profiles1 =
              profiles1 +
              infoDistrict1[d.replace(/[-" "]+/g, '')].profileCreationStage
            dds1 =
              dds1 + infoDistrict1[d.replace(/[-" "]+/g, '')].dueDiligenceStage
            loans1 =
              loans1 +
              infoDistrict1[d.replace(/[-" "]+/g, '')].loanApplicationStage
            enters1 =
              enters1 +
              infoDistrict1[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
            bps1 = bps1 + infoDistrict1[d.replace(/[-" "]+/g, '')].bPlanStage
          })
          arr.push({
            name: f.fullName,
            ProfilesCreated: profiles,
            DDcomplete: dds,
            LoansApplied: loans,
            Enterprisessetup: enters,
            Bplandone: bps,
            ProfilesCreated1: profiles1,
            DDcomplete1: dds1,
            LoansApplied1: loans1,
            Enterprisessetup1: enters1,
            Bplandone1: bps1,
          })
          profiles = 0
          dds = 0
          loans = 0
          enters = 0
          bps = 0
          profiles1 = 0
          dds1 = 0
          loans1 = 0
          enters1 = 0
          bps1 = 0
        })
        setData(arr)
        console.log(arr)
      } else if (
        fellows.length !== 0 &&
        infoDistrict !== undefined &&
        state !== '' &&
        checkbox === false
      ) {
        fellows.swavalambanFellows.map(f => {
          if (f.state.includes(state)) {
            f.district.map(d => {
              if (district.includes(d)) {
                profiles =
                  profiles +
                  infoDistrict[d.replace(/[-" "]+/g, '')].profileCreationStage
                dds =
                  dds +
                  infoDistrict[d.replace(/[-" "]+/g, '')].dueDiligenceStage
                loans =
                  loans +
                  infoDistrict[d.replace(/[-" "]+/g, '')].loanApplicationStage
                enters =
                  enters +
                  infoDistrict[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
                bps = bps + infoDistrict[d.replace(/[-" "]+/g, '')].bPlanStage
                profiles1 =
                  profiles1 +
                  infoDistrict1[d.replace(/[-" "]+/g, '')].profileCreationStage
                dds1 =
                  dds1 +
                  infoDistrict1[d.replace(/[-" "]+/g, '')].dueDiligenceStage
                loans1 =
                  loans1 +
                  infoDistrict1[d.replace(/[-" "]+/g, '')].loanApplicationStage
                enters1 =
                  enters1 +
                  infoDistrict1[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
                bps1 =
                  bps1 + infoDistrict1[d.replace(/[-" "]+/g, '')].bPlanStage
              }
            })
            arr.push({
              name: f.fullName,
              ProfilesCreated: profiles,
              DDcomplete: dds,
              LoansApplied: loans,
              Enterprisessetup: enters,
              Bplandone: bps,
              ProfilesCreated1: profiles1,
              DDcomplete1: dds1,
              LoansApplied1: loans1,
              Enterprisessetup1: enters1,
              Bplandone1: bps1,
            })
            profiles = 0
            dds = 0
            loans = 0
            enters = 0
            bps = 0
            profiles1 = 0
            dds1 = 0
            loans1 = 0
            enters1 = 0
            bps1 = 0
          }
        })
        console.log(arr)
        setData(arr)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDistrict1, infoDistrict])

  useEffect(() => {
    let arr = []
    let profiles = 0,
      dds = 0,
      loans = 0,
      enters = 0,
      bps = 0

    if (
      fellows.length !== 0 &&
      infoDistrict !== undefined &&
      state === '' &&
      checkbox
    ) {
      fellows.swavalambanFellows.map(f => {
        f.district.map(d => {
          if (
            infoDistrict[d.replace(/[-" "]+/g, '')] &&
            infoDistrict[d.replace(/[-" "]+/g, '')].profileCreationStage
          )
            profiles =
              profiles +
              infoDistrict[d.replace(/[-" "]+/g, '')].profileCreationStage
          if (
            infoDistrict[d.replace(/[-" "]+/g, '')] &&
            infoDistrict[d.replace(/[-" "]+/g, '')].dueDiligenceStage
          )
            dds =
              dds + infoDistrict[d.replace(/[-" "]+/g, '')].dueDiligenceStage
          if (
            infoDistrict[d.replace(/[-" "]+/g, '')] &&
            infoDistrict[d.replace(/[-" "]+/g, '')].loanApplicationStage
          )
            loans =
              loans +
              infoDistrict[d.replace(/[-" "]+/g, '')].loanApplicationStage
          if (
            infoDistrict[d.replace(/[-" "]+/g, '')] &&
            infoDistrict[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
          )
            enters =
              enters +
              infoDistrict[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
          if (
            infoDistrict[d.replace(/[-" "]+/g, '')] &&
            infoDistrict[d.replace(/[-" "]+/g, '')].bPlanStage
          )
            bps = bps + infoDistrict[d.replace(/[-" "]+/g, '')].bPlanStage
        })
        arr.push({
          name: f.fullName,
          ProfilesCreated: profiles,
          DDcomplete: dds,
          LoansApplied: loans,
          Enterprisessetup: enters,
          Bplandone: bps,
        })
        profiles = 0
        dds = 0
        loans = 0
        enters = 0
        bps = 0
      })
      setData(arr)
      console.log(arr)
    } else if (
      fellows.length !== 0 &&
      infoDistrict !== undefined &&
      state !== '' &&
      checkbox
    ) {
      fellows.swavalambanFellows.map(f => {
        if (f.state.includes(state)) {
          f.district.map(d => {
            if (district.includes(d)) {
              profiles =
                profiles +
                infoDistrict[d.replace(/[-" "]+/g, '')].profileCreationStage
              dds =
                dds + infoDistrict[d.replace(/[-" "]+/g, '')].dueDiligenceStage
              loans =
                loans +
                infoDistrict[d.replace(/[-" "]+/g, '')].loanApplicationStage
              enters =
                enters +
                infoDistrict[d.replace(/[-" "]+/g, '')].enterpriseSetupStage
              bps = bps + infoDistrict[d.replace(/[-" "]+/g, '')].bPlanStage
            }
          })
          arr.push({
            name: f.fullName,
            ProfilesCreated: profiles,
            DDcomplete: dds,
            LoansApplied: loans,
            Enterprisessetup: enters,
            Bplandone: bps,
          })
          profiles = 0
          dds = 0
          loans = 0
          enters = 0
          bps = 0
        }
      })
      console.log(arr)
      setData(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoDistrict, fellows])

  const dateLineChanger = async current => {
    setLineDate({
      ...lineDate,
      fromDate: current[0],
      toDate: current[1],
    })
    if (linestate === '') {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/clusterData/linechart-data?state=&district=Agra,Aligarh,Ambedkar Nagar,Amethi,Amroha,Aurangabad,Azamgarh,Bahraich,Balangir,Balrampur,Banda,Banka,Bargarh,Begusarai,Bhagalpur,Bhojpur,Bijnor,Bokaro,Budaun,Chandauli,Chitrakoot,Cuttack,Darbhanga,Deoghar,Deoria,Dhanbad,Dhenkanal,Dumka,East Singhbhum,Farrukhabad,Fatehpur,Firozabad,Gaya,Ghaziabad,Ghazipur,Giridih,Godda,Gonda,Gorakhpur,Gumla,Hardoi,Hathras,Hazaribagh,Hyderabad,Jagatsinghpur,Jajapur,Jalaun,Jamtara,Jangaon,Jaunpur,Jayashankar Bhupalapally,Jehanabad,Jharsuguda,Kalahandi,Kanpur Dehat,Kanpur Nagar,Karimnagar,Kaushambi,Kendrapara,Khagaria,Khammam,Khordha,Koraput,Lakhisarai,Lucknow,Madhubani,Mahabubabad,Mathura,Medak,Medchal,Mirzapur,Moradabad,Munger,Muzaffarpur,Nabarangapur,Nalanda,Nalgonda,Nawada,Nuapada,Pakur,Patna,Pratapgarh,Prayagraj,Puri,Purvi Champaran,Rae Bareli,Ramgarh,Rampur,Ranchi,Rangareddy,Rayagada,Saharanpur,Sahibganj,Samastipur,Sambalpur,Sangareddy,Sant Kabir Nagar,Sant Ravidas Nagar,Saran,Seraikela-Kharsawan,Shrawasti,Siddharthnagar,Siddipet,Sitamarhi,Siwan,Sonbhadra,Subarnapur,Suryapet,Unnao,Vaishali,Varanasi,Vikarabad,Warangal,Warangal Rural,Warangal Urban,West Singhbhum&toDate=${moment(
              current[1]
            ).format('DD-MM-YYYY')}&fromDate=${moment(current[0]).format(
              'DD-MM-YYYY'
            )}`
        )
        console.log(responseData)
        setlineData(responseData.resu)
      } catch (err) {
        alert(err + ' ' + error)
      }
    } else {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/clusterData/linechart-data?state=${linestate}&district=${linedistrict}&toDate=${moment(
              current[1]
            ).format('DD-MM-YYYY')}&fromDate=${moment(current[0]).format(
              'DD-MM-YYYY'
            )}`
        )
        console.log(responseData)
        setlineData(responseData.resu)
      } catch (err) {
        alert(err + ' ' + error)
      }
    }
  }

  const lineResetHandler = () => {
    setlineData([])
    setLineState('')
    setCluster('')
    setLineDistrict([])
    setLineDate({
      fromDate: null,
      toDate: null,
    })
  }

  const setClusterData = async data => {
    console.log(data + ' ' + fellows.swavalambanFellows.length)
    setCluster(data)
    try {
      for (let i = 0; i < fellows.swavalambanFellows.length; i++) {
        if (data.includes(fellows.swavalambanFellows[i]._id)) {
          const responseData = await sendRequest(
            process.env.REACT_APP_API_URL +
              '/api/v1/location/get-address?stateId=' +
              fellows.swavalambanFellows[i].state[0]
          )

          console.log(responseData)

          setLineDistrict(fellows.swavalambanFellows[i].district)
          setLineState(fellows.swavalambanFellows[i].state[0])
        }
      }
    } catch (error) {}
  }

  /**
   *
   * end of line chart functions
   *
   */

  return (
    <React.Fragment>
      <div
        style={{
          width: '75%',
          position: 'absolute',
          left: '15rem',
          top: '10rem',
          boxSizing: 'border-box',
          padding: '10px',
        }}
      >
        <span
          style={{
            fontSize: '30px',
            fontWeight: 'bold',
            marginBottom: '20px',
            marginLeft: '22rem',
          }}
        >
          CLUSTER COMPARATIVE PERFORMANCE
        </span>
        <br />
        <span>Select Date</span>
        <RangePicker
          bordered={false}
          onChange={dateChanger}
          format="DD/MM/YYYY"
          value={[date.fromDate, date.toDate]}
        />
        <Select
          style={{ width: 200 }}
          value={state === '' ? 'States' : state}
          onChange={stateHandlerBar}
          placeholder="State"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <OptGroup label="Focus States">
            {!isLoading &&
              stateload !== undefined &&
              stateload.data.states.map((state, stateIndex) => {
                if (state.inFocusRegion) {
                  if (
                    JSON.parse(readCookie('userData')).userType.name ===
                      'Swavalamban Fellows' &&
                    JSON.parse(readCookie('userData')).address.state.includes(
                      state.name
                    )
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else if (
                    JSON.parse(readCookie('userData')).userType.name !==
                    'Swavalamban Fellows'
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else return null
                } else return null
              })}
          </OptGroup>
          <OptGroup label="Not Focussed States">
            {!isLoading &&
              stateload !== undefined &&
              stateload.data.states.map((state, stateIndex) => {
                if (!state.inFocusRegion) {
                  if (
                    JSON.parse(readCookie('userData')).userType.name ===
                      'Swavalamban Fellows' &&
                    JSON.parse(readCookie('userData')).address.state.includes(
                      state.name
                    )
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else if (
                    JSON.parse(readCookie('userData')).userType.name !==
                    'Swavalamban Fellows'
                  ) {
                    return (
                      <Option key={stateIndex} value={state.name}>
                        {state.name}
                      </Option>
                    )
                  } else return null
                } else return null
              })}
          </OptGroup>
        </Select>
        <Button onClick={resetHandler}>RESET</Button>
        <br />

        <label>
          compare :
          <input
            name="compare"
            type="checkbox"
            onChange={() => {
              if (checkbox === true) setCheckbox(false)
              else setCheckbox(true)
            }}
          />
        </label>
        <br />
        <span>Select Date</span>
        <RangePicker
          bordered={false}
          onChange={dateChangerCheckBox}
          format="DD/MM/YYYY"
          value={[checkBarDate.fromDate, checkBarDate.toDate]}
          disabled={checkbox}
        />
        <Spin
          spinning={data === undefined || isLoading ? true : false}
          delay={500}
        >
          {infoDistrict !== undefined && fellows.length !== 0 && (
            <BarChart
              width={1300}
              height={500}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 80,
              }}
            >
              <CartesianGrid strokeDasharray="3 1" />
              <XAxis dataKey="name" textAnchor="end" angle={-35} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend wrapperStyle={{ top: 0 }} />
              <Bar dataKey="ProfilesCreated" stackId="a" fill="#2432A6" />
              <Bar dataKey="DDcomplete" stackId="a" fill="#C355BE" />
              <Bar dataKey="Bplandone" stackId="a" fill="#E57045" />
              <Bar dataKey="LoansApplied" stackId="a" fill="#98578B" />
              <Bar dataKey="Enterprisessetup" stackId="a" fill="#45B333" />
              {checkbox === false && (
                <Bar dataKey="ProfilesCreated1" stackId="b" fill="#2432A6" />
              )}
              {checkbox === false && (
                <Bar dataKey="DDcomplete1" stackId="b" fill="#C355BE" />
              )}
              {checkbox === false && (
                <Bar dataKey="Bplandone1" stackId="b" fill="#E57045" />
              )}
              {checkbox === false && (
                <Bar dataKey="LoansApplied1" stackId="b" fill="#98578B" />
              )}
              {checkbox === false && (
                <Bar dataKey="Enterprisessetup1" stackId="b" fill="#45B333" />
              )}
            </BarChart>
          )}
        </Spin>

        <div style={{ marginTop: '50px' }}>
          <span
            style={{
              fontSize: '30px',
              fontWeight: 'bold',
              marginBottom: '20px',
              marginLeft: '22rem',
            }}
          >
            PROGRESS OVER-TIME
          </span>
          <br />
          <span>Select Date</span>
          <RangePicker
            value={[lineDate.fromDate, lineDate.toDate]}
            onChange={dateLineChanger}
          />
          <Select
            style={{ width: 200 }}
            value={linestate === '' ? 'States' : linestate}
            placeholder="State"
            onChange={stateHandlerLine}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <OptGroup label="Focus States">
              {!isLoading &&
                stateload !== undefined &&
                stateload.data.states.map((state, stateIndex) => {
                  if (state.inFocusRegion) {
                    if (
                      JSON.parse(readCookie('userData')).userType.name ===
                        'Swavalamban Fellows' &&
                      JSON.parse(readCookie('userData')).address.state.includes(
                        state.name
                      )
                    ) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else if (
                      JSON.parse(readCookie('userData')).userType.name !==
                      'Swavalamban Fellows'
                    ) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else return null
                  } else return null
                })}
            </OptGroup>
            <OptGroup label="Not Focussed States">
              {!isLoading &&
                stateload !== undefined &&
                stateload.data.states.map((state, stateIndex) => {
                  if (!state.inFocusRegion) {
                    if (
                      JSON.parse(readCookie('userData')).userType.name ===
                        'Swavalamban Fellows' &&
                      JSON.parse(readCookie('userData')).address.state.includes(
                        state.name
                      )
                    ) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else if (
                      JSON.parse(readCookie('userData')).userType.name !==
                      'Swavalamban Fellows'
                    ) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else return null
                  } else return null
                })}
            </OptGroup>
          </Select>
          {fellows.length !== 0 && (
            <Select
              showSearch
              style={{ width: 200 }}
              value={cluster === '' ? 'Select a cluster' : cluster}
              placeholder="Select a cluster"
              optionFilterProp="children"
              onChange={setClusterData}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {fellows.swavalambanFellows.map((data, index) => {
                return (
                  <Option
                    key={index}
                    title={`${data.fullName} (${data.email})`}
                    value={data._id}
                  >
                    {data.fullName}
                  </Option>
                )
              })}
            </Select>
          )}
          <Select
            mode="tags"
            size="default"
            value={linedistrict}
            placeholder="District"
            style={{ width: '20%' }}
          >
            {linedistrict.map(data => {
              return <Option value={data}>{data}</Option>
            })}
          </Select>
          <Button onClick={lineResetHandler}> RESET</Button>
          <br />
          {lineData.length === 0 && isLoading ? (
            <Spin delay={500} />
          ) : (
            lineData.length === 0 && (
              <div
                style={{
                  fontSize: '50px',
                  fontFamily: 'cursive',
                  color: 'grey',
                  marginLeft: '350px',
                }}
              >
                {' '}
                Plz Select date{' '}
              </div>
            )
          )}
          {lineData.length !== 0 && lineDate.fromDate !== null && (
            <LineChart
              width={1000}
              height={500}
              data={lineData.map((d, k) => ({
                name: moment(moment(lineDate.fromDate).add(k, 'd')).format(
                  'DD/MM'
                ),
                ProfilesCreated: d.profileCreationStage,
                DDcomplete: d.dueDiligenceStage,
                Bplandone: d.bPlanStage,
                LoansApplied: d.loanApplicationStage,
                Enterprisessetup: d.enterpriseSetupStage,
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="ProfilesCreated"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="DDcomplete" stroke="#82ca9d" />
              <Line
                type="monotone"
                dataKey="Bplandone"
                stroke="#D81906"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="LoansApplied" stroke="#0000FF" />
              <Line
                type="monotone"
                dataKey="Enterprisessetup"
                stroke="#174511"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </React.Fragment>
  )
}

export default Analytics
