import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import Messagemodal from './message-modal'
import Languagemodal from './language-modal'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { message, Spin } from 'antd'
import { useHttpHook } from '../hooks/http-hook'
import { LoadingOutlined, DeleteTwoTone } from '@ant-design/icons'
import Swal from 'sweetalert2'

const readCookie = require('../../../cookie.js').readCookie
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, marginLeft: '50rem' }} spin />
)

function MessagePage(props) {
  const [messages, setMessages] = useState()
  const [count, setCount] = useState(0)
  const [messageModal, setMessageModal] = useState(false)
  const [languageModal, setLanguageModal] = useState(false)
  const [English, setEnglish] = useState(false)
  const [Hindi, setHindi] = useState(false)
  const [Odia, setOdia] = useState(false)
  const [Telugu, setTelugu] = useState(false)
  const [Marathi, setMarathi] = useState(false)
  const [Kannada, setKannada] = useState(false)
  const [Bengali, setBengali] = useState(false)
  const [Tamil, setTamil] = useState(false)
  const { isLoading, error, sendRequest, clearError } = useHttpHook()
  const [Gujarati, setGujarati] = useState(false)
  const [Punjabi, setPunjabi] = useState(false)
  const [Malayalam, setMalayalam] = useState(false)
  const [pre, setPre] = useState()

  const incrementRow = async (id, language) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL +
          `/api/v1/masturation-messages/count-update/${id}`,
        'PATCH',
        JSON.stringify({
          Englishcount: language === 'English' ? 1 : 0,
          Hindicount: language === 'Hindi' ? 1 : 0,
          Odiacount: language === 'Odia' ? 1 : 0,
          Telugucount: language === 'Telugu' ? 1 : 0,
          Marathicount: language === 'Marathi' ? 1 : 0,
          Kannadacount: language === 'Kannada' ? 1 : 0,
          Bengalicount: language === 'Bengali' ? 1 : 0,
          Tamilcount: language === 'Tamil' ? 1 : 0,
          Gujaraticount: language === 'Gujarati' ? 1 : 0,
          Punjabicount: language === 'Punjabi' ? 1 : 0,
          Malayalamcount: language === 'Malayalam' ? 1 : 0,
        }),
        {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        }
      )
      setPre(responseData.message)
      //  console.log(responseData.message);
      // console.log(responseData.message)
      //  window.location.reload(false);
    } catch (err) {
      alert(error)
    }
  }

  useEffect(() => {
    setMessages(pre)
  }, [pre])

  const englishformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard
            text={cell}
            onCopy={() => {
              message.success('Copied')
            }}
          >
            <Button
              onClick={() => {
                incrementRow(row.id, 'English')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Englishcount + ' times'}
        </React.Fragment>
      )
  }

  const hindiformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Hindi')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Hindicount + ' times'}
        </React.Fragment>
      )
  }

  const odiaformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Odia')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Odiacount + ' times'}
        </React.Fragment>
      )
  }
  const Teluguformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Telugu')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Telugucount + ' times'}
        </React.Fragment>
      )
  }
  const Marathiformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Marathi')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Marathicount + ' times'}
        </React.Fragment>
      )
  }
  const Kannadaformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Kannada')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Kannadacount + ' times'}
        </React.Fragment>
      )
  }
  const Bengaliformatter = (cell, row) => {
    //console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Bengali')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Bengalicount + ' times'}
        </React.Fragment>
      )
  }
  const Tamilformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Tamil')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Tamilcount + ' times'}
        </React.Fragment>
      )
  }
  const Gujaratiformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Gujarati')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Gujaraticount + ' times'}
        </React.Fragment>
      )
  }
  const Punjabiformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Punjabi')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Punjabicount + ' times'}
        </React.Fragment>
      )
  }

  const deleteMessage = cell => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.value) {
        setMessages(messages.filter(m => m.id !== cell))

        try {
          sendRequest(
            process.env.REACT_APP_API_URL +
              `/api/v1/masturation-messages/delete/${cell}`,
            'DELETE'
          )
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        } catch (err) {
          alert("coulsn't not deleted find some error try again !")
        }
      }
    })
  }

  const buttonFormatter = (cell, row, index) => {
    if (cell) {
      return (
        <React.Fragment>
          <DeleteTwoTone
            onClick={() => {
              deleteMessage(cell)
            }}
          />
        </React.Fragment>
      )
    }
  }

  const Malayalamformatter = (cell, row) => {
    // console.log(cell + " " + JSON.stringify(row));
    if (cell !== '')
      return (
        <React.Fragment>
          {cell}
          <br />
          <CopyToClipboard text={cell} onCopy={() => message.success('Copied')}>
            <Button
              onClick={() => {
                incrementRow(row.id, 'Malayalam')
              }}
            >
              Copy to clipboard
            </Button>
          </CopyToClipboard>
          {row.Malayalamcount + ' times'}
        </React.Fragment>
      )
  }

  useEffect(() => {
    if (messages !== undefined) {
      messages.forEach(d => {
        if (English === true && d.Englishvalid === false) setEnglish(false)
        if (Hindi === true && d.Hindi === false) setHindi(false)
        if (Odia === true && d.Odiavalid === false) setOdia(false)
        if (Telugu === true && d.Teluguvalid === false) setTelugu(false)
        if (Marathi === true && d.Marathivalid === false) setMarathi(false)
        if (Kannada === true && d.Kannadavalid === false) setKannada(false)
        if (Bengali === true && d.Bengalivalid === false) setBengali(false)
        if (Tamil === true && d.Tamilvalid === false) setTamil(false)
        if (Gujarati === true && d.Gujarativalid === false) setGujarati(false)
        if (Punjabi === true && d.Punjabivalid === false) setPunjabi(false)
        if (Malayalam === true && d.Malayalamvalid === false)
          setMalayalam(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  const columns = [
    {
      dataField: 'id',
      text: 'Edit',
      formatter: buttonFormatter,
      sort: false,
      hidden: false,
      export: false,
      searchable: false,
      align: 'center',
      headerStyle: () => {
        return { width: '80px', textAlign: 'center' }
      },
    },
    {
      dataField: 'Stage',
      text: 'Stage',
      editable: false,
      align: 'center',
      headerStyle: () => {
        return { width: '180px', textAlign: 'center' }
      },
    },
    {
      dataField: 'scenario',
      text: 'Scenario',
      editable: false,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
    },
    {
      dataField: 'English',
      text: 'English',
      formatter: englishformatter,
      hidden: English,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Hindi',
      text: 'Hindi',
      formatter: hindiformatter,
      hidden: Hindi,
      align: 'center',
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Odia',
      text: 'Odia',
      formatter: odiaformatter,
      hidden: Odia,
      align: 'center',
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Telugu',
      text: 'Telugu',
      formatter: Teluguformatter,
      hidden: Telugu,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Marathi',
      text: 'Marathi',
      formatter: Marathiformatter,
      hidden: Marathi,
      align: 'center',
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Kannada',
      text: 'Kannada',
      formatter: Kannadaformatter,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      hidden: Kannada,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Bengali',
      text: 'Bengali',
      hidden: false,
      formatter: Bengaliformatter,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      // hidden: Bengali,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Tamil',
      text: 'Tamil',
      hidden: false,
      formatter: Tamilformatter,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      // hidden: Tamil,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Gujarati',
      text: 'Gujarati',
      hidden: false,
      formatter: Gujaratiformatter,
      // hidden: Gujarati,
      align: 'center',
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Punjabi',
      text: 'Punjabi',
      hidden: false,
      formatter: Punjabiformatter,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      // hidden: Punjabi,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: 'Malayalam',
      text: 'Malayalam',
      hidden: false,
      formatter: Malayalamformatter,
      editable:
        JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit'
          ? true
          : false,
      // hidden: Malayalam,
      align: 'center',
      headerStyle: () => {
        return { width: '250px', textAlign: 'center' }
      },
      editor: {
        type: Type.TEXTAREA,
      },
    },
  ]

  const rowStyle = (row, index) => {
    if (row.Stage === 'Profile Creation') return { backgroundColor: '#daf5f2' }
    else if (row.Stage === 'Due-Diligence')
      return { backgroundColor: '#fcf3d7' }
    else if (row.Stage === 'Business Planning')
      return { backgroundColor: '#e5efff' }
    else if (row.Stage === 'Loan Application')
      return { backgroundColor: '#feeeec' }
    else if (row.Stage === 'Enterprise Set-up')
      return { backgroundColor: '#f39b94' }
  }

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/masturation-messages/all-messages`
        )
        //    console.log(responseData.message);
        setMessages(responseData.message)
        console.log(responseData.message)
        /*const data = responseData.message[0];
        setEnglish(data.Englishvalid);
        setHindi(data.Hindivalid);
        setOdia(data.Odiavalid);
        setTelugu(data.Teluguvalid);
        setMarathi(data.Marathivalid);
        setKannada(data.Kannadavalid);
        setBengali(data.Bengalivalid);
        setTamil(data.Tamilvalid);
        setGujarati(data.Gujarativalid);
        setPunjabi(data.Punjabivalid);
        setMalayalam(data.Malayalamvalid);*/

        setCount(responseData.message.length)
      } catch (err) {
        //   alert(err);
        // setCount(0);
      }
    }
    fetchMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {clearError}
      {JSON.parse(readCookie('userData')).userType.name ===
      'Project Management Unit' ? (
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            bsPrefix="design-center-button"
            onClick={() => {
              setMessageModal(true)
            }}
          >
            Add new message
          </Button>
          <Button
            bsPrefix="design-center-button"
            onClick={() => {
              setLanguageModal(true)
            }}
          >
            Add new language
          </Button>
        </div>
      ) : null}

      <Messagemodal
        show={messageModal}
        hide={() => {
          setMessageModal(false)
        }}
      />
      <Languagemodal
        show={languageModal}
        hide={() => {
          setLanguageModal(false)
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: '18rem',
          maxWidth: '80rem',
          top: '10rem',
        }}
      >
        {isLoading && <Spin indicator={antIcon} />}
        {!isLoading &&
          messages !== undefined &&
          (count === 0 ? (
            <span
              style={{
                position: 'absolute',
                left: '40rem',
                top: '20rem',
                backgroundColor: 'gray',
                padding: '60px',
                // top: 0,
                fontSize: '30px',
                borderRadius: '25px',
              }}
            >
              No data found
            </span>
          ) : (
            <React.Fragment>
              <Button>Total Count : {count}</Button>
              <BootstrapTable
                keyField="id"
                bootstrap4
                // keyField="id"
                striped
                deleteRow
                condensed
                wrapperClasses="table-responsive"
                data={messages}
                columns={columns}
                pagination={paginationFactory()}
                rowStyle={rowStyle}
                cellEdit={cellEditFactory({
                  mode: 'dbclick',
                  blurToSave: true,
                  onStartEdit: (row, column, rowIndex, columnIndex) => {
                    //console.log("start to edit!!!");
                  },
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    //console.log("Before Saving Cell!!");
                  },
                  afterSaveCell: async (oldValue, newValue, row, column) => {
                    // console.log(JSON.stringify(row) + "  " +JSON.stringify(column));
                    if (oldValue !== newValue) {
                      newValue.split('\n').map((item, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            {item}
                            <br />
                          </React.Fragment>
                        )
                      })

                      try {
                        const responseData = await sendRequest(
                          process.env.REACT_APP_API_URL +
                            `/api/v1/masturation-messages/updateData/${row.id}`,
                          'PATCH',
                          JSON.stringify({
                            English:
                              column.dataField === 'English'
                                ? newValue
                                : row.English,
                            Hindi:
                              column.dataField === 'Hindi'
                                ? newValue
                                : row.Hindi,
                            Odia:
                              column.dataField === 'Odia' ? newValue : row.Odia,
                            Telugu:
                              column.dataField === 'Telugu'
                                ? newValue
                                : row.Telugu,
                            Marathi:
                              column.dataField === 'Marathi'
                                ? newValue
                                : row.Marathi,
                            Kannada:
                              column.dataField === 'Kannada'
                                ? newValue
                                : row.Kannada,
                            Bengali:
                              column.dataField === 'Bengali'
                                ? newValue
                                : row.Bengali,
                            Tamil:
                              column.dataField === 'Tamil'
                                ? newValue
                                : row.Tamil,
                            Gujarati:
                              column.dataField === 'Gujarati'
                                ? newValue
                                : row.Gujarati,
                            Punjabi:
                              column.dataField === 'Punjabi'
                                ? newValue
                                : row.Punjabi,
                            Malayalam:
                              column.dataField === 'Malayalam'
                                ? newValue
                                : row.Malayalam,
                          }),
                          {
                            'Content-Type': 'application/json',
                            'x-auth-token': JSON.parse(
                              readCookie('access_token')
                            ),
                          }
                        )
                        setPre(responseData.message)
                        // console.log(responseData.message);
                        // window.location.reload(false);
                      } catch (err) {
                        alert(err + ' errors : ' + error)
                      }
                    }
                  },
                })}
              />
            </React.Fragment>
          ))}
      </div>
    </React.Fragment>
  )
}

export default MessagePage
