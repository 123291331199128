/** @format */

import { Select } from 'antd'
import { Col, Row, Button, Modal } from 'react-bootstrap'
import React, { Component } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'
import EnterpriseComments from './EnterpriseComments'
import EnterprisePaymentDetails from './EnterprisePaymentDetails'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const readCookie = require('../cookie.js').readCookie

const { Option } = Select

var currentDate = new Date()

let INITIAL_MODAL_VALUE = {
  _id: '',
  deliveredOn: currentDate,
  deliveredBy: '',
}

export default class CompleteServiceDelivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorObj: {},
      sizePerPageList: [50, 100, 150, 200],
      selectedCandidate: null,
      uploadModalVisibility: false,
      userData: props.userData,
      leadData: props.leadData,
      // requestDetails: props.rowDetails,
      viewType: props.viewType,
      allActiveAssignTo: props.allActiveAssignTo,
      _id: '',
      comment: '',
      serviceId: '',
      noOfProofs: [{ id: 1, name: 'Row 1' }],
      uploadedFile: null,
      selectedOption: '',
      options: [],
      deliveredOn: currentDate,
      deliveredBy: '',
      canSubmit: false,
      CSDcanSubmit: false,
      proofs: [{ name: '', file: null }],
      completeServiceDeliveryproofs: [{ name: '', file: null }],
      rowCount: 0,
      CSDrowCount: 0,
      showProofUpload: false,
      handleAllServicesClick: props.handleAllServicesClick,
      handelCDSEditModalClick: props.handelCDSEditModalClick,
      getAllServiceDetails: props.getAllServiceDetails,
      s3Urls: props.s3Urls,
      allComments: [],
      posting: false,
      posted: false,
      CSDposting: false,
      CSDposted: false,
    }
    this.handleCancel = this.handleCancel.bind(this)
  }
  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)
  // -----------------------------------------------------------------
  getProofOptions = serviceId => {
    const URL =
      process.env.REACT_APP_API_URL +
      '/api/v1/service-delivery/auxiliary/supported-file-types/' +
      serviceId
    fetch(URL, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({
            options: data.data,
          })
        }
      })
  }

  componentDidMount() {
    this.getProofOptions(this.props.selectedService._id || '')

    this.getAllComments()
    this.props.getServiceRequestDetails()
  }

  handleshowProofUpload = () => {
    this.setState({ showProofUpload: true })
    if (!this.state.proofs || this.state.proofs.length === 0) {
      this.setState({ proofs: [{ name: '', file: null }] })
    }
  }

  hideUploadModal = () => {
    this.setState({ uploadModalVisibility: false, selectedCandidate: null })
  }

  handleCancel = () => {
    this.setState({ comment: '', proofs: null, canSubmit: false })
  }
  // change halders

  changeHandler = (type, e) => {
    if (type === 'deliveredOn') {
      if (!e) {
        return
      } else {
        this.setState({ deliveredOn: e })
      }
    } else if (type === 'deliveredBy') {
      this.setState({ deliveredBy: e })
    }

    this.CSDcheckCanSubmit()
  }

  updateCompleteServiceDelivery = () => {
    let errorObj = this.state.errorObj

    let { deliveredOn, deliveredBy, completeServiceDeliveryproofs } = this.state

    let formData = new FormData()
    formData.append('deliveredBy', deliveredBy)
    formData.append('deliveredOn', deliveredOn)
    for (let ind = 0; ind < completeServiceDeliveryproofs.length; ind++) {
      formData.append(
        `supportingDocuments[${ind}].file`,
        completeServiceDeliveryproofs[ind].file
      )
      formData.append(
        `supportingDocuments[${ind}].type`,
        completeServiceDeliveryproofs[ind].name
      )
    }

    if (Object.keys(errorObj).length) {
      this.setState({ errorObj })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Update!',
        cancelButtonText: 'No, Cancel!',
      }).then(res => {
        if (res.value) {
          this.setState({ CSDposting: true })
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/service-delivery/service-log/mark-complete/' +
              this.props.rowDetails._id,
            {
              method: 'PUT',
              headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
              body: formData,
            }
          )
            .then(response => {
              return response.json()
            })
            .then(data => {
              if (data.status) {
                this.notifySuccess('Successfully added!')
                this.setState({ CSDposting: false })
                this.setState({ CSDposted: true })
                this.hideUploadModal()
                this.state.getAllServiceDetails()
                this.props.getServiceRequestDetails()
                this.getAllComments()

                setTimeout(() => {
                  this.setState({
                    CSDposting: false,
                    CSDposted: false,
                    deliveredBy: '',
                    // completeServiceDeliveryproofs: null,
                  })
                }, 2000)
              } else {
                this.setState({ CSDposting: false })
                this.notifyError(data.errorMessage)
              }
            })
        }
      })
    }
  }

  showUploadModal = selectedCandidate => {
    let completeServiceDeliveryData = selectedCandidate
      ? {
          _id: this.state._id,
          deliveredOn: this.state.deliveredOn || '',
          deliveredBy: this.state.deliveredBy || '',
        }
      : INITIAL_MODAL_VALUE
    this.setState({
      uploadModalVisibility: true,
      selectedCandidate: completeServiceDeliveryData,
    })
  }

  // -----------------------------------------------------------------

  formattedDate = (showDate, cell, row) => {
    // let showDate = this.props.rowDetails.createdAt;
    if (showDate) {
      return moment(showDate).format('DD/MM/YYYY HH:mm A')
    }
    return null
  }

  changeUploadHandler = value => {
    let uploadedFile = value.target.files[0]
    this.setState({ uploadedFile })
  }

  handleSubmit = () => {
    this.setState({ posting: true })
    let { comment, proofs, rowCount } = this.state
    let formData = new FormData()
    if (comment) formData.append('comment', comment)

    if (proofs[rowCount].name !== '' && proofs[rowCount].file !== null) {
      for (let ind = 0; ind < proofs.length; ind++) {
        formData.append(`supportingDocuments[${ind}].type`, proofs[ind].name)
        formData.append(`supportingDocuments[${ind}].file`, proofs[ind].file)
      }
    }

    formData.append('serviceRequestId', this.props.rowDetails._id)
    formData.append('serviceId', this.props.rowDetails.serviceId._id)
    formData.append('leadId', this.props.rowDetails.leadId)

    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/service-delivery/service-comments',
      {
        method: 'POST',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: formData,
      }
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.status) {
          this.notifySuccess('Successfully Added the Proofs')
          this.setState({ posting: false })
          this.setState({ posted: true })
          setTimeout(() => {
            this.setState({
              canSubmit: false,
              posting: false,
              posted: false,
              comment: '',
              proofs: null,
            })
          }, 2000)
          this.getAllComments()
        } else {
          this.notifyError(data.errorMessage)
          this.setState({ posting: true })
        }
      })
  }

  getAllComments = () => {
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/service-delivery/service-comments/' +
        this.props.leadData.leadId +
        '/' +
        this.props.rowId,
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allComments: data.comments })
        }
      })
  }

  updateRequestDetails = requestDetails => {
    this.setState({ requestDetails })
  }
  handleOptionChange = event => {
    this.setState({ selectedOption: event })
  }

  handleInputChange = event => {
    this.setState({ comment: event.target.value, canSubmit: true })
  }

  handleProofNameChange = (index, value) => {
    const { proofs } = this.state
    const newProofs = [...proofs]
    newProofs[index].name = value
    this.setState({ proofs: newProofs })
  }

  handleProofFileChange = (index, event) => {
    const { proofs, rowCount } = this.state
    const newProofs = [...proofs]
    newProofs[index].file = event.target.files[0]
    this.setState({ proofs: newProofs })

    if (proofs[rowCount].name && proofs[rowCount].file) {
      this.setState({ canSubmit: true })
    }
  }

  addProofInput = () => {
    const { proofs = [], rowCount } = this.state
    if (
      (proofs[rowCount].name !== '' && proofs[rowCount].file !== null) ||
      proofs.length === 0
    ) {
      this.setState({
        proofs: [...proofs, { name: '', file: null }],
        rowCount: rowCount + 1,
        canSubmit: false,
      })
    }
  }

  // -----------------------------------------------------------------------------

  CSDcheckCanSubmit = () => {
    const {
      completeServiceDeliveryproofs,
      CSDrowCount,
      deliveredOn,
      deliveredBy,
    } = this.state
    if (
      deliveredOn &&
      deliveredBy &&
      completeServiceDeliveryproofs[CSDrowCount].name !== '' &&
      completeServiceDeliveryproofs[CSDrowCount].file !== null
    ) {
      this.setState({ CSDcanSubmit: true })
    }
  }

  CSDhandleProofNameChange = (index, value) => {
    const { completeServiceDeliveryproofs } = this.state
    const newProofs = [...completeServiceDeliveryproofs]
    newProofs[index].name = value
    this.setState({ completeServiceDeliveryproofs: newProofs })
    this.CSDcheckCanSubmit()
  }

  CSDhandleProofFileChange = (index, event) => {
    const { completeServiceDeliveryproofs } = this.state
    const newProofs = [...completeServiceDeliveryproofs]
    newProofs[index].file = event.target.files[0]
    this.setState({ completeServiceDeliveryproofs: newProofs })
    this.CSDcheckCanSubmit()
  }

  CSDaddProofInput = () => {
    const { completeServiceDeliveryproofs, CSDrowCount } = this.state
    if (
      completeServiceDeliveryproofs[CSDrowCount].name !== '' &&
      completeServiceDeliveryproofs[CSDrowCount].file !== null
    ) {
      this.setState({
        completeServiceDeliveryproofs: [
          ...completeServiceDeliveryproofs,
          { name: '', file: null },
        ],
        CSDrowCount: CSDrowCount + 1,
        CSDcanSubmit: false,
      })
    }
  }

  formatDate = dateString => {
    const date = new Date(dateString)
    const formattedDate = date.toLocaleTimeString('en-IN')
    // const formattedDate = moment(date).format('hh:mm AA DD/MM/YYYY');
    return formattedDate
  }

  // -------------------------------------------------------------------------------

  render() {
    return this.props.rowDetails !== null ? (
      <div
        className="tcacr-page page-container"
        style={{ marginTop: window.innerWidth <= 767 ? '221px' : '120px' }}
      >
        <div
          className="d-flex"
          style={{ flexWrap: window.innerWidth <= 767 ? 'wrap' : 'nowrap' }}
        >
          <div
            style={{
              flex: '75%',
              marginRight: '20px',
            }}
          >
            <div
              class="d-flex justify-content-between align-items-center"
              style={{
                flexWrap: window.innerWidth <= 767 ? 'wrap' : 'nowrap',
              }}
            >
              <div
                class="d-flex align-items-center"
                style={{
                  justifyContent: window.innerWidth <= 767 ? 'center' : null,
                  alignItems: window.innerWidth <= 767 ? 'center' : null,
                  marginLeft: window.innerWidth <= 767 ? '17px' : null,
                  color: 'var(--themeColor)',
                }}
              >
                <div
                  class="font-weight-bold mr-2"
                  onClick={this.props.handleAllServicesClick}
                  style={{ cursor: 'pointer' }}
                >
                  All Services
                </div>
                <i class="fa fa-angle-right" style={{ fontSize: '20px' }}></i>
                <div class="ml-2">
                  {this.props.rowDetails.serviceId.serviceTitle}
                </div>
              </div>

              <button
                style={{
                  marginLeft: window.innerWidth <= 767 ? '82%' : null,
                  marginTop: window.innerWidth <= 767 ? '4px' : null,
                }}
                class="btn btn-primary"
                onClick={this.props.handelCDSEditModalClick}
              >
                Edit
              </button>
            </div>

            <div
              class="d-flex mt-2"
              style={{
                flexWrap: window.innerWidth <= 767 ? 'wrap' : 'nowrap',
                justifyContent: window.innerWidth <= 767 ? 'center' : null,
                alignItems: window.innerWidth <= 767 ? 'center' : null,
                borderWidth: window.innerWidth <= 767 ? '0' : null,
              }}
            >
              {/* <div> */}
              <div class="font-weight-bold">Created on:</div>
              <div
                class="pl-1"
                style={{
                  borderRight: '1px solid black',
                  paddingRight: '7px',

                  borderWidth:
                    window.innerWidth <= 767 ? '0' : '1px solid black',
                }}
              >
                {this.formattedDate(this.props.rowDetails.createdAt)}{' '}
              </div>
              {/* </div> */}
              <div class="d-flex pl-3">
                <div class="font-weight-bold">Created by:</div>
                <u>
                  <div
                    class="pl-1"
                    style={{
                      borderRight: '1px solid black',
                      paddingRight: '7px',
                      borderWidth:
                        window.innerWidth <= 767 ? '0' : '1px solid black',
                    }}
                  >
                    <a
                      href={
                        '/user-types/' +
                        this.props.userData.userType.name +
                        '/' +
                        this.props.rowDetails.createdBy._id
                      }
                      target="_blank"
                    >
                      {this.props.rowDetails.createdBy.fullName}
                    </a>
                  </div>
                </u>
              </div>
              <div class="d-flex pl-3">
                <div class="font-weight-bold">Assign To:</div>
                <u>
                  <div class="pl-1">
                    <a
                      href={
                        '/user-types/' +
                        this.props.userData.userType.name +
                        '/' +
                        this.props.rowDetails.assignedTo._id
                      }
                      target="_blank"
                    >
                      {this.props.rowDetails.assignedTo.fullName}
                    </a>
                  </div>
                </u>
              </div>
            </div>
            <div
              class="d-flex mt-2"
              style={{
                flexWrap: window.innerWidth <= 767 ? 'wrap' : 'nowrap',
                justifyContent: window.innerWidth <= 767 ? 'center' : null,
                alignItems: window.innerWidth <= 767 ? 'center' : null,
              }}
            >
              <div class="font-weight-bold">Last updated on:</div>
              <div
                class="pl-1"
                style={{
                  borderRight: '1px solid black',
                  paddingRight: '7px',

                  borderWidth:
                    window.innerWidth <= 767 ? '0' : '1px solid black',
                }}
              >
                {this.formattedDate(this.props.rowDetails.updatedAt)}{' '}
              </div>
              {/* </div> */}
              <div class="d-flex pl-3">
                <div class="font-weight-bold">Last updated by:</div>
                <u>
                  <div
                    class="pl-1"
                    style={{
                      borderRight: '1px solid black',
                      paddingRight: '7px',
                      borderWidth:
                        window.innerWidth <= 767 ? '0' : '1px solid black',
                    }}
                  >
                    <a
                      href={
                        '/user-types/' +
                        this.props.userData.userType.name +
                        '/' +
                        this.props.rowDetails.updatedBy._id
                      }
                      target="_blank"
                    >
                      {this.props.rowDetails.updatedBy.fullName}
                    </a>
                  </div>
                </u>
              </div>
            </div>
            <div
              class="d-flex mt-2"
              style={{
                flexWrap: window.innerWidth <= 767 ? 'wrap' : 'nowrap',
                justifyContent: window.innerWidth <= 767 ? 'center' : null,
                alignItems: window.innerWidth <= 767 ? 'center' : null,
              }}
            >
              <div class="font-weight-bold">Status:</div>
              <div class="pl-1">{this.props.rowDetails.status}</div>
              {this.props.rowDetails.status === 'Delivered' ? (
                <div
                  class="d-flex pl-3"
                  style={{
                    flexWrap: window.innerWidth <= 767 ? 'wrap' : 'nowrap',
                    justifyContent: window.innerWidth <= 767 ? 'center' : null,
                    alignItems: window.innerWidth <= 767 ? 'center' : null,
                  }}
                >
                  <div class="d-flex">
                    <div
                      class="font-weight-bold"
                      style={{
                        borderLeft: '1px solid black',
                        paddingLeft: '7px',
                        borderWidth:
                          window.innerWidth <= 767 ? '0' : '1px solid black',
                      }}
                    >
                      {' '}
                      Delivered on:
                    </div>
                    <div
                      class="pl-1"
                      style={{
                        borderRight: '1px solid black',
                        paddingRight: '7px',
                        borderWidth:
                          window.innerWidth <= 767 ? '0' : '1px solid black',
                      }}
                    >
                      {this.formatDate(this.props.rowDetails.deliveredOn)}
                    </div>
                  </div>
                  <div class="d-flex pl-3">
                    <div class="font-weight-bold">Delivered by:</div>
                    <u>
                      <div class="pl-1">
                        <a
                          href={
                            '/user-types/' +
                            this.props.userData.userType.name +
                            '/' +
                            this.props.rowDetails.deliveredBy._id
                          }
                        >
                          {this.props.rowDetails.deliveredBy.fullName}
                        </a>
                      </div>
                    </u>
                  </div>
                </div>
              ) : null}

              {this.props.rowDetails.status === 'Delivered' ? null : ( // <div> Reopen this</div> //Todo - to repoen - TBD
                <button
                  style={{
                    marginTop: window.innerWidth <= 767 ? '12px' : null,
                  }}
                  disabled={
                    this.props.rowDetails.paymentType !== 'Pro-bono' &&
                    (this.props.rowDetails &&
                    this.props.rowDetails.orderDetails &&
                    this.props.rowDetails.orderDetails.length &&
                    this.props.rowDetails.orderDetails[
                      this.props.rowDetails.orderDetails.length - 1
                    ].paymentDetails
                      ? ![
                          'captured',
                          'paid by cash',
                          // "authprized"
                        ].includes(
                          this.props.rowDetails.orderDetails[
                            this.props.rowDetails.orderDetails.length - 1
                          ].paymentDetails.status
                        )
                      : false)
                  }
                  class="btn btn-success ml-auto"
                  onClick={() => {
                    this.showUploadModal()
                  }}
                >
                  <div> Complete Service Delivery</div>
                </button>
              )}
            </div>

            <hr />
            <div className="d-flex">
              {this.props.userData.profilePicture ? (
                <img
                  style={{
                    width: '35px',
                    height: '35px',
                    marginTop: '6px',
                    borderRadius: '20px',
                  }}
                  src={this.props.userData.profilePicture}
                  alt="userImg"
                />
              ) : (
                <img
                  style={{
                    width: '35px',
                    height: '35px',
                    marginTop: '6px',
                    borderRadius: '20px',
                  }}
                  src="/images/user_1.png"
                  className="img-circle"
                  alt="userImg"
                />
              )}
              <form style={{ width: '90%' }}>
                <input
                  style={{
                    width: '90%',
                    height: '49px',
                    borderRadius: '10px',
                    marginLeft: '15px',
                    border: '1px solid 	rgb(227 227 227)',
                    paddingLeft: '16px',
                  }}
                  type="text"
                  placeholder="Enter your notes/comments for reference"
                  value={this.state.comment}
                  onChange={this.handleInputChange}
                />
              </form>
            </div>
            <div style={{ marginTop: '10px', marginBottom: '15px' }}>
              <span
                style={{ marginLeft: '60px', cursor: 'pointer' }}
                onClick={this.handleshowProofUpload}
              >
                <i class="fa fa-paperclip"></i>
                Upload document/photo
              </span>

              {this.state.showProofUpload && this.state.proofs ? (
                <div className="row d-flex">
                  <div
                    className="col-md-8"
                    style={{ marginLeft: '14px', marginTop: '12px' }}
                  >
                    <form>
                      {this.state.proofs.map((proof, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-5">
                            <Select
                              value={proof.name}
                              onChange={event =>
                                this.handleProofNameChange(index, event)
                              }
                            >
                              <Option value="" disabled>
                                Select a proof
                              </Option>
                              {this.state.options.map(option => (
                                <Option key={option} value={option}>
                                  {option}
                                </Option>
                              ))}
                            </Select>
                          </div>
                          <div>
                            <input
                              style={{
                                marginLeft:
                                  window.innerWidth <= 767 ? '15px' : null,
                                marginTop:
                                  window.innerWidth <= 767 ? '10px' : null,
                                width: window.innerWidth <= 767 ? '89%' : null,
                              }}
                              type="file"
                              onChange={event =>
                                this.handleProofFileChange(index, event)
                              }
                              accept=".jpg , .png, .jpge, .pdf , .doc"
                              className="form-control-file"
                            />
                          </div>
                        </div>
                      ))}
                    </form>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{
                      marginLeft: window.innerWidth <= 767 ? '82%' : null,
                    }}
                  >
                    <div className="mt-auto">
                      <i
                        className="fa fa-plus fa-2x"
                        onClick={this.addProofInput}
                        style={{ cursor: 'pointer', marginBottom: '17px' }}
                      ></i>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div style={{ marginLeft: '49px' }}>
              <Button
                className="btn btn-warning brand-color-background"
                disabled={
                  !this.state.canSubmit ||
                  this.state.posting ||
                  this.state.posted
                }
                onClick={this.handleSubmit}
              >
                {this.state.posting
                  ? 'Posting'
                  : this.state.posted
                    ? 'Posted'
                    : 'Submit'}
              </Button>
              <Button
                className="btn btn-danger"
                style={{ marginLeft: '4px', backgroundColor: '#D3D3D3' }}
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
            </div>
            <EnterpriseComments
              requestDetails={this.props.rowDetails}
              allComments={this.state.allComments}
              userData={this.props.userData}
            />
          </div>

          <div style={{ flex: '25%' }}>
            <div
              style={{
                borderLeft: '2px solid 	rgb(248 247 247)',
                height: '',
                marginRight: '20px',
              }}
            >
              {' '}
            </div>

            {this.props.rowDetails ? (
              <div>
                <EnterprisePaymentDetails
                  s3Urls={this.props.s3Urls}
                  leadData={this.props.leadData}
                  rowId={this.props.rowId}
                  userData={this.props.userData}
                  updateRowDetails={this.props.updateRowDetails}
                  updateRequestDetails={this.updateRequestDetails}
                  // getServiceRequestDetails={this.getServiceRequestDetails}
                  // data={this.props.data}
                  rowDetails={this.props.rowDetails}
                />
              </div>
            ) : null}
          </div>
        </div>

        {this.state.uploadModalVisibility ? (
          <Modal
            className="reassign-lead-modal"
            show={this.state.uploadModalVisibility}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title className="modal-header-custom">
                Complete Service Delivery
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mb20 d-flex">
              <Row
                style={{
                  display: 'inline-block',
                  width: '100%',
                }}
              >
                <div className="d-flex">
                  <Col>
                    <label className="control-label is-imp">
                      Upload Proof of Delivery
                    </label>
                    <div className="d-flex">
                      <form>
                        {this.state.completeServiceDeliveryproofs.map(
                          (proof, index) => (
                            <div key={index}>
                              <label>
                                <Select
                                  style={{
                                    width:
                                      window.innerWidth <= 767 ? '180%' : null,
                                  }}
                                  value={proof.name}
                                  onChange={event =>
                                    this.CSDhandleProofNameChange(index, event)
                                  }
                                >
                                  <Option value="" disabled>
                                    Select a proof
                                  </Option>
                                  {this.state.options.map(option => (
                                    <Option key={option} value={option}>
                                      {option}
                                    </Option>
                                  ))}
                                </Select>
                              </label>
                              <label>
                                <input
                                  type="file"
                                  onChange={event =>
                                    this.CSDhandleProofFileChange(index, event)
                                  }
                                  accept=".jpg , .png, .jpge, .pdf , .doc"
                                  style={{
                                    width: '100%',
                                    marginLeft:
                                      window.innerWidth <= 767 ? '0' : '15px',
                                  }}
                                />
                              </label>
                            </div>
                          )
                        )}
                      </form>

                      <div className="d-flex flex-column align-items-center">
                        <div className="mt-auto">
                          <i
                            className="fa fa-plus fa-lg"
                            onClick={this.CSDaddProofInput}
                            style={{
                              cursor: 'pointer',
                              marginBottom: '9px',
                              marginLeft: '22px',
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>

                <Col>
                  <label className="control-label">Service Delivered on:</label>
                  <DatePicker
                    className="datePicker border"
                    onChange={this.changeHandler.bind(this, 'deliveredOn')}
                    selected={this.state.deliveredOn}
                    dateFormat={'dd/MM/yyyy'}
                    maxDate={currentDate}
                    style={{ width: '100%', height: '100%' }}
                  />
                </Col>

                <Col>
                  <label className="control-label is-imp">
                    Service Delivered by:
                  </label>
                  <Select
                    size="large"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select Delivered By"
                    onChange={this.changeHandler.bind(this, 'deliveredBy')}
                    value={this.state.deliveredBy}
                  >
                    <Option value="" disabled>
                      All
                    </Option>
                    {this.state.allActiveAssignTo.map((item, index) => {
                      return (
                        <Option key={index} value={item._id}>
                          {item.fullName}
                        </Option>
                      )
                    })}
                  </Select>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="footer-center">
              <Button
                onClick={this.updateCompleteServiceDelivery}
                disabled={
                  !(
                    this.state.deliveredOn &&
                    this.state.deliveredBy &&
                    this.state.completeServiceDeliveryproofs[
                      this.state.CSDrowCount
                    ].name !== '' &&
                    this.state.completeServiceDeliveryproofs[
                      this.state.CSDrowCount
                    ].file !== null
                  ) ||
                  this.state.CSDposting ||
                  this.state.CSDposted
                }
              >
                {this.state.CSDposting
                  ? 'Posting'
                  : this.state.CSDposted
                    ? 'Posted'
                    : 'Complete Service'}
              </Button>
              <Button
                className="btn btn-danger"
                onClick={() => {
                  this.hideUploadModal()
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    ) : null
  }
}
