import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
const currentToken = localStorage.getItem('humblebeeAuthToken')
const initialState = {
  status: '',
  clusterData: [],
  loading: false,
  error: '',
}

export const getClusterByProject = createAsyncThunk(
  'getClusterByProject',
  async id => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/clusters/get/${id}`,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
          },
        }
      )
      console.log('ress', res.data)
      console.log('res.data.clusters: ', res.data.clusters)
      var data = res.data.clusters
      //in each entry of cluster add project name
      for (let i = 0; i < data.length; i++) {
        data[i].projectId = {
          _id: res.data.project._id,
          name: res.data.project.name,
        }
      }
      return data
    } catch (err) {
      console.log('err', err)
      return err.response.data
    }
  }
)

const getClusterByProjectSlice = createSlice({
  name: 'getClusterByProject',
  initialState,
  reducers: {},

  extraReducers: {
    [getClusterByProject.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getClusterByProject.fulfilled]: (state, { payload, error }) => {
      state.loading = false
      console.log('payload in getClusterByProject fulfilled', payload)
      if (error) {
        state.error = error
        console.log('error', error)
      } else {
        state.clusterViaProjectData = payload // This should be res.data.clusters
        console.log('state.clusterViaProjectData', state.clusterViaProjectData)
      }
    },

    [getClusterByProject.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default getClusterByProjectSlice.reducer
