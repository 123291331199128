/**
 * eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import { Select, Divider, Input, Modal, Tag } from 'antd'

import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { Col, Row, Button } from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'
const readCookie = require('../cookie.js').readCookie

const { Option } = Select

export default class Service extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allStates: [],
      allDistricts: [],
      supportingDocsReqd: [],
      selectedStates: [],
      districtOptionsToDisplay: [],
      selectedDistricts: [],
      viewService: null,
      allBusinessIdeas: [],
      selectedService: null,
      disableEdit: false,
      serviceColumns: [
        {
          text: 'Id',
          sort: false,
          hidden: false,
          export: true,
          searchable: false,
          dataField: 'showedServiceId',
          headerStyle: () => {
            return { width: '70px' }
          },
        },
        {
          text: 'Category',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'category',
          searchable: true,
          headerStyle: () => {
            return { width: '190px' }
          },
        },
        {
          text: 'Service Title',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'serviceTitle',
          searchable: true,
          headerStyle: () => {
            return { width: '150px' }
          },
        },
        {
          text: 'Perferred Payment Type',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'preferredPaymentType',
          searchable: true,
          headerStyle: () => {
            return { width: '170px', textAlign: 'center' }
          },
        },
        {
          text: 'Base Price',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'basePrice',
          searchable: true,
          headerStyle: () => {
            return { width: '90px', textAlign: 'center' }
          },
          formatter: this.formatColumn.bind(this, 'basePrice'),
        },

        {
          text: 'Max Discount ',
          sort: true,
          hidden: true,
          export: true,
          dataField: 'maxDiscountPercentagePercentage',
          searchable: true,
          headerStyle: () => {
            return { width: '100px', textAlign: 'center' }
          },
        },
        {
          text: 'Exception',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'exceptions.0.state',
          searchable: true,
          headerStyle: () => {
            return { width: '100px', textAlign: 'center' }
          },
          formatter: (cell, row) => {
            if (
              row.exceptions &&
              row.exceptions.length > 0 &&
              row.exceptions[0].state
            ) {
              return (
                <a
                  onClick={() => {
                    this.setState({ disableEdit: true })
                    this.editService(row)
                  }}
                >
                  {' '}
                  Present
                </a>
              )
            } else {
              return 'Not Present'
            }
          },
        },
        {
          text: 'Created By',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'createdBy',
          searchable: true,
          headerStyle: () => {
            return { width: '110px' }
          },
          formatter: this.formatColumn.bind(this, 'createdBy'),
        },
        {
          text: 'Created At',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'createdAt',
          searchable: true,
          headerStyle: () => {
            return { width: '90px' }
          },
          formatter: this.formatColumn.bind(this, 'createdAt'),
        },
        {
          text: 'Updated By',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'updatedBy',
          searchable: true,
          headerStyle: () => {
            return { width: '110px' }
          },
          formatter: this.formatColumn.bind(this, 'updatedBy'),
        },
        {
          text: 'Updated At',
          sort: true,
          hidden: false,
          export: true,
          dataField: 'updatedAt',
          searchable: true,
          headerStyle: () => {
            return { width: '90px' }
          },
          formatter: this.formatColumn.bind(this, 'updatedAt'),
        },
        {
          text: 'Edit/Delete',
          sort: false,
          hidden:
            this.props.userData.type === 'PMU' &&
            ['Swavalamban Fellows'].includes(this.props.userData.userType.name),
          export: false,
          searchable: false,
          align: 'center',
          headerStyle: () => {
            return { width: '85px', textAlign: 'center' }
          },
          dataField: 'edit',
          formatter: this.formatColumn.bind(this, 'editDelete'),
        },
      ],
      allServices: [],
      fetchedServices: [],
      groupedServices: [],
      rowCount: -1,
      // basePriceDisable: false,
      canSubmit: true,
    }
  }
  getAllStates = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allStates: data.data.states })
        }
      })
  }

  getAllDistricts = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/districts', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          // let districtObj = data.districts.reduce((acc, curr) => {
          //   if (curr.state in acc) {
          //     acc[curr.state].push(curr);
          //   } else {
          //     acc[curr.state] = [curr];
          //   }
          //   return acc;
          // }, {})
          this.setState({ allDistricts: data.districts })
        }
      })
  }
  async componentDidMount() {
    await this.getServicesData()
    this.refreshServices()
    this.getAllStates()
    this.getAllDistricts()

    setTimeout(() => {
      const groupedServices = this.state.allServices.reduce(
        (groups, service) => {
          const { category, serviceTitle, _id } = service
          const index = groups.findIndex(group => group.category === category)
          if (index === -1) {
            groups.push({ category, services: [{ _id, name: serviceTitle }] })
          } else {
            groups[index].services.push({ _id, name: serviceTitle })
          }
          return groups
        },
        []
      )
      this.setState({ groupedServices })
    }, 5000)
  }

  refreshServices = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/enterprise-support-service',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ fetchedServices: data.services })
        }
      })
  }

  formatColumn = (type, cell, row, index) => {
    if (type === 'createdBy' || type === 'updatedBy') {
      if (cell && cell._id && cell.fullName) {
        return (
          <a href={'/user-types/project-management-unit/' + cell._id}>
            {cell.fullName}
          </a>
        )
      } else return 'N/A'
    } else if (type === 'name') {
      return <a onClick={this.viewService.bind(this, row)}>{cell}</a>
    } else if (type === 'documentsRequired') {
      return (
        <span className="docs-reqd" title={cell.join(', ')}>
          {cell.join(', ')}
        </span>
      )
    } else if (type === 'createdAt' || type === 'updatedAt') {
      if (cell && moment(cell).isValid())
        return moment(cell).format('DD/MM/YYYY hh:mm A')
      else if (cell) return cell
      else return 'N/A'
    } else if (type === 'editDelete') {
      return (
        <div className="edit-delete-buttons">
          <button
            className="btn edit-button"
            onClick={this.editService.bind(this, row)}
          >
            <i className="fa fa-pencil"></i>
            Edit
          </button>
          <button
            className="btn delete-button"
            onClick={this.deleteService.bind(this, row._id)}
          >
            <i className="fa fa-trash"></i>
            Delete
          </button>
        </div>
      )
    } else if (type === 'basePrice') {
      if (row.basePrice) return row.basePrice
      else return 'N/A'
    }
  }

  disablebasePrice = () => {
    if (this.state.selectedService.preferredPaymentType === 'Pro-bono')
      this.setState({ basePriceDisable: true })
  }

  updateEditingService = (type, value) => {
    this.disablebasePrice()
    let selectedService = this.state.selectedService

    if (value.target) value = value.target.value

    // Ensure the value is a number between 0 and 100 for maxDiscountPercentage
    if (type === 'maxDiscountPercentage') {
      value = parseInt(value, 10)
      if (isNaN(value) || value < 0) {
        value = 0
      } else if (value > 100) {
        value = 100
      }
    }

    // Ensure the value is a number >= 0 for basePrice
    if (type === 'basePrice') {
      value = parseFloat(value)
      if (value < 0) {
        value = 0
      }
    }

    if (Array.isArray(value) && value.indexOf('Any') > -1) {
      selectedService[type] = ['Any']
    } else {
      selectedService[type] = value
    }

    this.setState({ selectedService })
  }

  editService = scheme => {
    let selectedService = scheme
    if (selectedService.__v) delete selectedService.__v
    if (selectedService.isDeleted) delete selectedService.isDeleted
    if (selectedService.createdAt) delete selectedService.createdAt
    if (selectedService.createdBy) delete selectedService.createdBy
    if (selectedService.updatedAt) delete selectedService.updatedAt
    if (selectedService.updatedBy) delete selectedService.updatedBy
    console.log(
      '🚀 ~ file: Service.js:386 ~ Service ~ selectedService:',
      selectedService,
      selectedService.exceptions
    )

    console.log(
      '🚀 ~ file: Service.js:391 ~ Service ~ selectedService?.exceptions?.length - 1:',
      selectedService?.exceptions?.length - 1
    )
    this.setState({
      selectedService,
      rowCount: ['', null, undefined].includes(
        selectedService?.exceptions?.length - 1
      )
        ? -1
        : selectedService?.exceptions?.length - 1,
    })
  }

  hideEditingService = () => {
    this.setState({ selectedService: null, disableEdit: false })
  }

  viewService = viewService => {
    this.setState({ viewService })
  }

  hideviewService = () => {
    this.setState({ viewService: null })
  }

  addService = () => {
    let selectedService = {
      category: '',
      serviceTitle: '',
      preferredPaymentType: '',
      basePrice: '',
      maxDiscountPercentage: 0,
      exceptions: [],
      supportingDocsReqd: [],
      documents: '',
    }
    this.setState({ selectedService })
  }

  deleteService = serviceId => {
    if (serviceId) {
      Swal.fire({
        title: 'Are you sure?',
        html: 'This Service will be deleted permanently',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, Delete',
        confirmButtonAriaLabel: 'Yes, Delete',
        cancelButtonText: 'No, Cancel',
        cancelButtonAriaLabel: 'No, Cancel',
      }).then(result => {
        if (result.value) {
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/enterprise-support-service/' +
              serviceId,
            {
              method: 'DELETE',
              headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
            }
          )
            .then(data => data.json())
            .then(data => {
              if (data.status) {
                this.notifySuccess(data.messsage)
                this.refreshServices()
              } else {
                this.notifyError(data.messsage)
              }
            })
            .catch(e => {
              this.notifyError('Service deletion failed!')
            })
        }
      })
    }
  }

  saveEditingService = () => {
    let { exceptions } = this.state.selectedService
    let { rowCount } = this.state

    let selectedService = this.state.selectedService

    if (
      selectedService.category &&
      selectedService.serviceTitle &&
      // selectedService.supportingDocsReqd &&
      selectedService.preferredPaymentType
    ) {
      let selectedServiceId = selectedService._id
      if (selectedServiceId) {
        delete selectedService._id
        Swal.fire({
          title: 'Are you sure you want to update this service?',
          html: 'This will update the service.',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Yes, Update',
          confirmButtonAriaLabel: 'Yes, Update',
          cancelButtonText: 'No, Cancel',
          cancelButtonAriaLabel: 'No, Cancel',
        }).then(result => {
          if (result.value) {
            fetch(
              process.env.REACT_APP_API_URL +
                '/api/v1/enterprise-support-service/' +
                selectedServiceId,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
                body: JSON.stringify(selectedService),
              }
            )
              .then(response => {
                return response.json()
              })
              .then(data => {
                if (data.status) {
                  this.refreshServices()
                  this.hideEditingService()
                  this.notifySuccess('Successfully updated service!')
                } else {
                  this.notifyError(data.errorMsg)
                }
              })
          }
        })
      } else {
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/enterprise-support-service/new',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
            body: JSON.stringify(selectedService),
          }
        )
          .then(response => {
            return response.json()
          })
          .then(data => {
            if (data.status) {
              this.refreshServices()
              this.hideEditingService()
              this.notifySuccess('Successfully added new Service!')
            } else {
              this.notifyError(data.errorMsg)
            }
          })
      }
    } else {
      this.notifyError('Please fill the required values to save')
    }
  }

  getServicesData = async () => {
    fetch(
      process.env.REACT_APP_API_URL + '/api/v1/service-delivery/service-list',
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allServices: data.services })
        }
      })
  }

  addExceptionInput = () => {
    const { exceptions } = this.state.selectedService
    const { rowCount } = this.state

    if (
      exceptions.length === 0 ||
      (exceptions[rowCount].state &&
        exceptions[rowCount].district &&
        exceptions[rowCount].state.length &&
        exceptions[rowCount].district.length &&
        exceptions[rowCount].amount !== '')
    ) {
      exceptions.push({ state: [], amount: '', district: [] })
      let selectedService = { ...this.state.selectedService, exceptions }

      this.setState({
        districtOptionsToDisplay: [],
        selectedService,
        rowCount: rowCount + 1,
        canSubmit: false,
      })
    }
  }

  handleStateChange = (index, value) => {
    const { exceptions } = this.state.selectedService
    const newException = [...exceptions]
    newException[index].state = value
    const selectedStates = newException.reduce(
      (acc, curr) => [...acc, ...curr.state],
      []
    )

    // let districtOptionsToDisplay = [];
    // for (let i = 0; i < selectedStates.length; i++) {
    //   districtOptionsToDisplay.push(...this.state.allDistricts[selectedStates[i]]);
    // }

    this.setState({
      selectedService: {
        ...this.state.selectedService,
        exceptions: newException,
      },
      selectedStates,
      // districtOptionsToDisplay,
    })
  }

  handleDistrictChange = (index, value) => {
    const { exceptions } = this.state.selectedService
    const newException = [...exceptions]
    newException[index].district = value
    const selectedDistricts = newException.reduce(
      (acc, curr) => [...acc, ...curr.district],
      []
    )

    this.setState({
      selectedService: {
        ...this.state.selectedService,
        exceptions: newException,
      },
      selectedDistricts,
    })
  }

  handleAmountChange = (index, event) => {
    const { exceptions } = this.state.selectedService
    const { rowCount } = this.state

    const newException = [...exceptions]
    newException[index].amount = event.target.value
    this.setState({ exceptions: newException })

    if (
      exceptions[rowCount].state !== '' &&
      exceptions[rowCount].amount !== ''
    ) {
      this.setState({ canSubmit: true })
    }
  }

  deleteExceptionInput = (index, event) => {
    const { exceptions } = this.state.selectedService
    const { rowCount } = this.state

    if (rowCount >= 0 && exceptions.length >= index) {
      const deletedRow = exceptions.splice(index, 1)[0]
      const selectedStates = this.state.selectedStates.filter(
        state => !deletedRow.state.includes(state)
      )
      let selectedService = { ...this.state.selectedService, exceptions }

      this.setState({
        selectedService,
        rowCount: rowCount - 1,
        selectedStates,
        canSubmit: true,
      })
    } else {
      console.log('Goes into else block')
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  handleAddDocument = () => {
    const { selectedService } = this.state
    const newTag = selectedService.documents.trim()
    if (!selectedService.supportingDocsReqd) {
      selectedService.supportingDocsReqd = []
    }
    if (newTag !== '') {
      selectedService.supportingDocsReqd.push(newTag) // Add new tag to supportingDocsReqd array
      selectedService.documents = '' // Clear the input field
      this.setState({ selectedService })
    }
  }

  handleRemoveDocument = tag => {
    const { selectedService } = this.state
    selectedService.supportingDocsReqd =
      selectedService.supportingDocsReqd.filter(t => t !== tag)
    this.setState({ selectedService })
  }
  render() {
    return (
      <div
        className={
          this.props.userData ? 'page-container schemes-page' : 'schemes-page'
        }
      >
        <div className="page-heading d-flex align-items-center">
          <h3>Services</h3>
          <div
            className="filter add-new-scheme-container"
            style={{ right: '40px', position: 'absolute' }}
          >
            {JSON.parse(readCookie('userData')).userType.name ===
            'Project Management Unit' ? (
              <button
                className="btn add-new-scheme-btn"
                onClick={this.addService}
              >
                <i className="fa fa-plus mr5"></i>
                Add Service
              </button>
            ) : null}
          </div>
        </div>

        <div className="schemes-container">
          {this.state.fetchedServices ? (
            <ToolkitProvider
              keyField="_id"
              data={this.state.fetchedServices}
              columns={this.state.serviceColumns}
            >
              {props => (
                <div>
                  <BootstrapTable
                    id="schemes-table"
                    {...props.baseProps}
                    ref={table => {
                      this.serviceTable = table
                    }}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : null}
          {!this.state.allServices.length ? (
            <div className="no-schemes">
              <h2>
                There are no Services currently.
                <br />
                <span>Please create a new one!</span>
              </h2>
            </div>
          ) : null}
        </div>

        {this.state.selectedService ? (
          <Modal
            // className="edit-scheme-modal"

            // open={show}
            visible={this.state.selectedService ? true : false}
            onCancel={() => {
              this.hideEditingService()
            }}
            width={1000}
            title={
              this.state.selectedService._id ? 'Edit Service' : 'New Service'
            }
            footer={null}
          >
            {/* <Modal.Header>
              <Modal.Title className="modal-header-custom">
                {this.state.selectedService._id
                  ? "Edit Service"
                  : "New Service"}
              </Modal.Title>
            </Modal.Header> */}
            {/* <Modal.Body className="mb20"> */}
            <Row>
              <Col md={12}>
                <label className="control-label is-imp">Category</label>
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={this.state.selectedService.category}
                  onChange={this.updateEditingService.bind(this, 'category')}
                  disabled={this.state.disableEdit}
                >
                  <Option value="" disabled>
                    All
                  </Option>
                  {this.state.groupedServices.map((item, index) => {
                    return (
                      <Option
                        key={index}
                        title={item.category}
                        value={item.category}
                      >
                        {item.category}
                      </Option>
                    )
                  })}
                </Select>
              </Col>
              <Col md={12}>
                <label className="control-label is-imp">Service Name</label>
                <input
                  className="form-control"
                  placeholder="Service Name"
                  value={this.state.selectedService.serviceTitle}
                  onChange={this.updateEditingService.bind(
                    this,
                    'serviceTitle'
                  )}
                  disabled={this.state.disableEdit}
                />
              </Col>
              <Col md={12}>
                <label className="control-label is-imp">
                  Perfered Payment Type
                </label>
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  value={this.state.selectedService.preferredPaymentType}
                  onChange={this.updateEditingService.bind(
                    this,
                    'preferredPaymentType'
                  )}
                  disabled={this.state.disableEdit}
                >
                  <Option value="" disabled>
                    All
                  </Option>
                  <Option value="Pro-bono">Pro-bono</Option>
                  <Option value="Pre-paid">Pre-paid</Option>
                  <Option value="50% Advance">50% Advance</Option>
                  <Option value="Post-paid">Post-paid</Option>
                </Select>
              </Col>

              <Col md={12}>
                <label className="control-label">Base Price (INR)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Base Price (INR)"
                  value={this.state.selectedService.basePrice}
                  min={1}
                  onChange={this.updateEditingService.bind(this, 'basePrice')}
                  disabled={this.state.disableEdit}
                />
              </Col>
              <Col md={12}>
                <label className="control-label">
                  Supporting documents required for this service
                </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Supporting documents required for this service"
                    value={this.state.selectedService.documents}
                    disabled={this.state.disableEdit}
                    onChange={this.updateEditingService.bind(this, 'documents')}
                  />
                  <Button
                    style={{ marginLeft: '10px', width: '200px' }}
                    onClick={this.handleAddDocument}
                  >
                    Add Document
                  </Button>
                </div>
                <div>
                  <div>
                    {(this.state.selectedService.supportingDocsReqd || []).map(
                      tag => (
                        <Tag
                          key={tag}
                          closable
                          onClose={() => this.handleRemoveDocument(tag)}
                        >
                          {tag}
                        </Tag>
                      )
                    )}
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <label className="control-label">Max Discount (%)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Discount (%)"
                  value={this.state.selectedService.maxDiscountPercentage}
                  // max={100}
                  step={1}
                  onChange={this.updateEditingService.bind(
                    this,
                    'maxDiscountPercentage'
                  )}
                  disabled={this.state.disableEdit}
                />
                <Button
                  style={{ marginTop: '20px' }}
                  onClick={this.addExceptionInput}
                  disabled={this.state.disableEdit}
                >
                  Add Exception
                </Button>
              </Col>

              {this.state.selectedService.exceptions.length > 0 ? (
                <div className="row" style={{ width: '100%' }}>
                  <div className="col-md-8">
                    <form>
                      {this.state.selectedService.exceptions.map(
                        (exception, index) => (
                          <div>
                            <Row key={index} style={{ width: '100%' }}>
                              <Col md={4}>
                                <label className="control-label">
                                  Choose State(s)
                                </label>
                                <Select
                                  mode="multiple"
                                  showSearch
                                  size="large"
                                  style={{ width: '100%' }}
                                  placeholder="Choose State(s)"
                                  onChange={event =>
                                    this.handleStateChange(index, event)
                                  }
                                  value={exception.state}
                                  disabled={this.state.disableEdit}
                                >
                                  <Option value="" disabled>
                                    Any
                                  </Option>

                                  {this.state.allStates.map(
                                    (state, stateIndex) => {
                                      if (state.inFocusRegion) {
                                        //   if (
                                        //     JSON.parse(readCookie("userData"))
                                        //       .userType.name ===
                                        //       "Project Management Unit" &&
                                        //     JSON.parse(
                                        //       readCookie("userData")
                                        //     ).address.state.includes(
                                        //       state.name
                                        //     ) &&
                                        //     !this.state.selectedStates.includes(
                                        //       state.name
                                        //     )
                                        // )
                                        // {
                                        return (
                                          <Option
                                            key={stateIndex}
                                            value={state.name}
                                          >
                                            {state.name}
                                          </Option>
                                        )
                                      }
                                      // else if (
                                      //     JSON.parse(readCookie("userData"))
                                      //       .userType.name ===
                                      //       "Project Management Unit" &&
                                      //     !this.state.selectedStates.includes(
                                      //       state.name
                                      //     )
                                      //   ) {
                                      //     return (
                                      //       <Option
                                      //         key={stateIndex}
                                      //         value={state.name}
                                      //       >
                                      //         {state.name}
                                      //       </Option>
                                      //     );
                                      //   } else return null;
                                      // }
                                      else return null
                                    }
                                  )}
                                </Select>
                              </Col>

                              <Col md={4}>
                                <label className="control-label">
                                  Choose District(s)
                                </label>
                                <Select
                                  mode="multiple"
                                  showSearch
                                  size="large"
                                  style={{ width: '100%' }}
                                  placeholder="Choose District(s)"
                                  onChange={event =>
                                    this.handleDistrictChange(index, event)
                                  }
                                  // value={exception.state}
                                  value={exception.district}
                                  disabled={
                                    this.state.disableEdit ||
                                    !exception.state.length
                                  }
                                >
                                  <Option value="" disabled>
                                    Any
                                  </Option>

                                  {this.state.allDistricts.map(
                                    (district, districtIndex) => {
                                      if (
                                        district.isActive &&
                                        exception.state.includes(district.state)
                                      ) {
                                        //   if (
                                        //     JSON.parse(readCookie("userData"))
                                        //       .userType.name ===
                                        //       "Project Management Unit" &&
                                        //     JSON.parse(
                                        //       readCookie("userData")
                                        //     ).address.district.includes(
                                        //       district.name
                                        //     ) &&
                                        //     !this.district.selecteddistrict.includes(
                                        //       district.name
                                        //     )
                                        // )
                                        // {
                                        return (
                                          <Option
                                            key={districtIndex}
                                            value={district.name}
                                            // disabled={this.state.selectedDistricts.includes(district.name)}
                                          >
                                            {district.name}
                                          </Option>
                                        )
                                      }
                                      // else if (
                                      //     JSON.parse(readCookie("userData"))
                                      //       .userType.name ===
                                      //       "Project Management Unit" &&
                                      //     !this.state.selectedStates.includes(
                                      //       state.name
                                      //     )
                                      //   ) {
                                      //     return (
                                      //       <Option
                                      //         key={stateIndex}
                                      //         value={state.name}
                                      //       >
                                      //         {state.name}
                                      //       </Option>
                                      //     );
                                      //   } else return null;
                                      // }
                                      else return null
                                    }
                                  )}
                                </Select>
                              </Col>
                              <Col md={4}>
                                <label className="control-label">Amount </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Amount (INR)"
                                  value={exception.amount}
                                  min={1}
                                  onChange={event =>
                                    this.handleAmountChange(index, event)
                                  }
                                  disabled={this.state.disableEdit}
                                />
                              </Col>
                              <div className="d-flex flex-column align-items-center">
                                <div
                                  style={{
                                    marginTop: '200%',
                                  }}
                                >
                                  <i
                                    class="fa fa-trash fa-2x"
                                    onClick={event =>
                                      this.deleteExceptionInput(index, event)
                                    }
                                  ></i>
                                </div>
                              </div>
                            </Row>
                          </div>
                        )
                      )}
                    </form>
                  </div>
                </div>
              ) : null}
            </Row>

            <div className="col-12 mt-3 mr-6">
              <Button
                className="btn brand-color-background"
                onClick={this.saveEditingService}
                disabled={
                  !this.state.selectedService.serviceTitle ||
                  !this.state.selectedService.category ||
                  !this.state.selectedService.preferredPaymentType ||
                  !this.state.canSubmit ||
                  this.state.disableEdit
                }
              >
                Save
              </Button>
              <Button
                className="btn btn-danger brand-color-background"
                onClick={this.hideEditingService}
              >
                Cancel
              </Button>
            </div>
          </Modal>
        ) : null}
      </div>
    )
  }
}
