import React, { useState } from 'react'
import { Row, Col, Button, Modal, Spinner } from 'react-bootstrap'
import { Input, DatePicker, Popconfirm, message } from 'antd'
import moment from 'moment'
import {
  DeleteFilled,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { useHttpHook } from '../../hooks/http-hook'

const readCookie = require('../../../cookie.js').readCookie
const { RangePicker } = DatePicker

const Dataloader = props => {
  // eslint-disable-next-line no-unused-vars
  const [loadedTraining, setLoadedTraining] = useState()
  const [newdate, showDate] = useState(false)
  const { isLoading, error, sendRequest, clearError } = useHttpHook()
  const [fromdate, setFromDate] = useState()
  const [todate, setToDate] = useState()
  const [disablebutton, setbutton] = useState(true)
  const [confirmData, setConfirmData] = useState(false)

  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }

  const confirm = async () => {
    try {
      sendRequest(
        process.env.REACT_APP_API_URL +
          `/api/v1/center/training-schedule/delete/${props.id}`,
        'DELETE'
      )
      message.success('Deleted Successfully')
      // {
      props.onDelete(props.id)
      // }
    } catch (err) {}
  }
  const dateDeleteHandler = async key => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL +
          `/api/v1/center/training-schedule/nesteddelete/${key}/${props.id}`,
        'DELETE'
      )
      //  alert(JSON.stringify(res));
      message.success('Deleted Successfully')
      window.location.reload()
    } catch (err) {
      console.log(err + ' ' + error)
    }
  }

  const setButtonHanler = e => {
    setFromDate(e[0])
    setToDate(e[1])
    setbutton(false)
  }

  const addBatchHandler = async event => {
    event.preventDefault()

    //check date collapse
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_API_URL +
          `/api/v1/center/training-schedule/${props.id}`
      )
      console.log(responseData.training)
      setLoadedTraining(responseData.training)

      let demo = ''
      // eslint-disable-next-line array-callback-return
      responseData.training.date.map(data => {
        demo =
          moment(fromdate).isBetween(data.from, data.to) ||
          moment(todate).isBetween(data.from, data.to)
      })

      if (demo) {
        setConfirmData(true)
        //end
      } else {
        addBatch()
      }

      //  console.log(responseData.training);
    } catch (err) {
      //alert(err);
    }

    //const center = loadedTraining.filt(center => center.id === props.id);

    //add batch
    showDate(false)
    setbutton(true)
  }

  const addBatch = async () => {
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL +
          `/api/v1/center/training-schedule/addBatch/${props.id}`,
        'POST',
        JSON.stringify({
          date: {
            from: fromdate,
            to: todate,
          },
        }),
        {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        }
      )
      //  alert(JSON.stringify(res));
      message.success('Added Successfully')
      //window.location.reload();
    } catch (err) {
      console.log(err + ' ' + error)
    }

    window.location.reload(false)
  }

  return (
    <React.Fragment>
      {clearError}
      <Row style={{ marginTop: '20px', padding: '5px' }}>
        <Col sm={6} style={{ marginTop: '5px' }}>
          <Input type="text" value={props.program} disabled />
          <Popconfirm
            title="Are you sure？"
            onConfirm={confirm}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <DeleteFilled
              style={{ cursor: 'pointer', float: 'left', marginTop: '10px' }}
            />
          </Popconfirm>
        </Col>
        <Col sm={6}>
          {props.date.map(data => (
            <li key={data.id} style={{ listStyle: 'none', padding: '5px' }}>
              <RangePicker
                defaultValue={[moment(data.from), moment(data.to)]}
                disabled
              />
              <DeleteOutlined
                key={data.id}
                onClick={() => {
                  dateDeleteHandler(data.id)
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
            </li>
          ))}
          <Button
            onClick={() => {
              showDate(true)
            }}
          >
            ADD NEW BATCH
          </Button>
        </Col>
      </Row>
      <Modal show={newdate} animation={true} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add new batch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RangePicker disabledDate={disabledDate} onChange={setButtonHanler} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              showDate(false)
            }}
          >
            CLOSE
          </Button>
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <Button
              variant="primary"
              onClick={addBatchHandler}
              disabled={disablebutton}
            >
              ADD
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={confirmData} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Date Collapsed</Modal.Title>
        </Modal.Header>
        <Modal.Body>Want to add this batch ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setConfirmData(false)
            }}
          >
            Cancel
          </Button>
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <Button variant="primary" onClick={addBatch}>
              Understood
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default Dataloader
