import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { Row, Col, Button } from 'react-bootstrap'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { Input, Icon } from 'antd'
// import moment from 'moment';

const readCookie = require('../../cookie.js').readCookie
const ValidateData = require('../../../../ValidateData.js')

export default class UdaanOrgEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orgData: null,
      verifyingIFSC: null,
      allUDAAN: [],
      errorObj: {},
      allCauses: [],
      allCities: [],
      allStates: [],
      allDistricts: [],
      maxImagesErr: '',
    }
  }
  showIFSCIcon = () => {
    let verifyingIFSC = this.state.verifyingIFSC
    if (verifyingIFSC === 'Verified') {
      return (
        <Icon style={{ marginBottom: 6 }} type="check" title="IFSC Verified" />
      )
    } else if (verifyingIFSC === 'Verifying') {
      return (
        <Icon
          style={{ marginBottom: 6 }}
          type="loading"
          title="Verifying IFSC"
        />
      )
    } else if (verifyingIFSC === 'Failed') {
      return (
        <Icon style={{ marginBottom: 6 }} type="warning" title="Invalid IFSC" />
      )
    } else return null
  }
  verifyIFSC = e => {
    let ifsc = e.target.value,
      errorObj = this.state.errorObj
    if (ifsc) {
      this.setState({ verifyingIFSC: 'Verifying' })
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/bank-branches/verify-ifsc/' +
          ifsc,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            delete errorObj['bankIFSC']
            this.setState({ verifyingIFSC: data.result, errorObj })
          } else {
            errorObj['bankIFSC'] = 'Invalid Bank IFSC'
            this.setState({ verifyingIFSC: 'Failed', errorObj })
          }
        })
        .catch(err => {
          errorObj['bankIFSC'] = 'Invalid Bank IFSC'
          this.setState({ verifyingIFSC: 'Failed', errorObj })
        })
    } else {
      delete errorObj['bankIFSC']
      this.setState({ verifyingIFSC: null, errorObj })
    }
  }
  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allStates: data.data.states })
        }
      })

    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/udaan/' +
        (this.props.match.params.orgId || this.props.match.params.userId),
      {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({
            orgData: {
              logo: data.udaan.logo,
              registrationCertificate: data.udaan.registrationCertificate || '',
              city: data.udaan.city,
              email: data.udaan.email,
              state: data.udaan.state,
              udaanUrl: data.udaan.udaanUrl,
              udaanName: data.udaan.udaanName,
              pincode: data.udaan.pincode,
              district: data.udaan.district,
              address1: data.udaan.address1,
              address2: data.udaan.address2,
              location: data.udaan.location,
              fullName: data.udaan.adminId.fullName,
              mobileNumber: data.udaan.mobileNumber,
              leProfileUrl: data.udaan.leProfileUrl,
              locationUrl: data.udaan.locationUrl || '',
              officePhotos: data.udaan.officePhotos || [],
              bankIfsc: data.udaan.bankIfsc || '',
              bankAccNo: data.udaan.bankAccNo || '',
              bankBranchAddress: data.udaan.bankBranchAddress,
              status: data.udaan.status || '',
              reasonForDeboarding: data.udaan.reasonForDeboarding || '',
            },
          })
          if (data.udaan.state) {
            fetch(
              process.env.REACT_APP_API_URL +
                '/api/v1/location/get-address?stateId=' +
                data.udaan.state,
              {
                method: 'GET',
                headers: {
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
              }
            )
              .then(data => data.json())
              .then(data => {
                if (data.status) {
                  this.setState({ allDistricts: data.focusDistricts })
                }
              })
          }
          if (data.udaan.district && data.udaan.state) {
            fetch(
              process.env.REACT_APP_API_URL +
                '/api/v1/location/get-address?stateId=' +
                data.udaan.state +
                '&districtId=' +
                data.udaan.district,
              {
                method: 'GET',
                headers: {
                  'x-auth-token': JSON.parse(readCookie('access_token')),
                },
              }
            )
              .then(data => data.json())
              .then(data => {
                if (data.status) {
                  this.setState({ allCities: data.cities })
                }
              })
          }
        }
      })
  }

  changeHandler = (type, e) => {
    let orgData = this.state.orgData,
      errorObj = this.state.errorObj
    if (type === 'locationUrl')
      ValidateData.urlValidate(e.target.value, errorObj)
    if (type === 'email') ValidateData.emailValidate(e.target.value, errorObj)
    if (type === 'mobileNumber')
      ValidateData.mobileValidate('mobileNumber', e.target.value, errorObj)
    if (type === 'fullName') ValidateData.nameValidate(e.target.value, errorObj)
    if (type === 'bankAccNo')
      ValidateData.bankAccNoValidate(e.target.value, errorObj)

    this.setState({ errorObj })

    if (type === 'state') {
      orgData[type] = e.target.value
      orgData['district'] = ''
      orgData['city'] = ''
      this.setState({ orgData })
      if (e.target.value) {
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/location/get-address?stateId=' +
            e.target.value,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({ allDistricts: data.districts, allCities: [] })
            }
          })
      }
    } else if (type === 'district') {
      orgData[type] = e.target.value
      orgData['city'] = ''
      this.setState({ orgData })
      if (e.target.value) {
        fetch(
          process.env.REACT_APP_API_URL +
            '/api/v1/location/get-address?stateId=' +
            orgData.state +
            '&districtId=' +
            e.target.value,
          {
            method: 'GET',
            headers: {
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
          }
        )
          .then(data => data.json())
          .then(data => {
            if (data.status) {
              this.setState({ allCities: data.cities })
            }
          })
      }
    } else if (type === 'logo') {
      if (e.target.files.length) orgData['logo'] = e.target.files[0]
      else orgData['logo'] = ''
      this.setState({ orgData })
    } else if (type === 'registrationCertificate') {
      if (e.target.files.length)
        orgData['registrationCertificate'] = e.target.files[0]
      else orgData['registrationCertificate'] = ''
      this.setState({ orgData })
    } else if (type === 'officePhotos') {
      let officePhotos = this.state.orgData.officePhotos
      if (e.target.files.length) {
        if (e.target.files.length + officePhotos.length <= 3) {
          this.setState({ maxImagesErr: '' })
          orgData['officePhotos'] = [...officePhotos, ...e.target.files]
        } else {
          //orgData['officePhotos'] = [];
          this.setState({
            maxImagesErr: 'At most, only three images could be added',
          })
          this.notifyError('At most, only three images could be added')
        }
      } else {
        orgData['officePhotos'] = []
        this.setState({ maxImagesErr: '' })
      }

      this.setState({ orgData })
    } else {
      orgData[type] = e.target.value
      this.setState({ orgData })
    }
  }

  onPreferenceLocationChange = preferenceLocation => {
    let orgData = this.state.orgData
    orgData['location']['label'] = preferenceLocation
    this.setState({ orgData }, () => {
      geocodeByAddress(preferenceLocation)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          let orgData = this.state.orgData
          orgData['location']['latitude'] = latLng.lat
          orgData['location']['longitude'] = latLng.lng
          this.setState({ orgData })
        })
        .catch(error => console.error('Error', error))
    })
  }

  deleteOfficeImage(index) {
    Swal.fire({
      title: 'Are you sure?',
      text: `You won't be able to revert this action.`,
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#EF1313',
      confirmButtonText: 'Yes, I am sure!',
      cancelButtonText: 'No, cancel it!',
      closeOnConfirm: true,
      closeOnCancel: true,
    }).then(result => {
      if (result.value) {
        let officeImages = this.state.orgData.officePhotos
        if (index > -1) {
          officeImages.splice(index, 1)
        }
        this.setState({ orgData: { ...this.state.orgData, officeImages } })
      }
    })
  }
  submitDetails = () => {
    let orgData = this.state.orgData,
      errorObj = this.state.errorObj,
      specialError = false
    if (errorObj['bankIfsc']) {
      this.notifyError('Please mention a valid IFSC')
      return
    } else if (errorObj['bankAccNo']) {
      this.notifyError("Please ensure you're entering a valid Account Number!")
      return
    }
    if (!orgData.location) specialError = true
    else {
      if (
        !orgData.location.label ||
        !orgData.location.latitude ||
        !orgData.location.longitude
      )
        specialError = true
    }

    if (
      !Object.keys(errorObj).length &&
      !specialError &&
      orgData.mobileNumber &&
      orgData.udaanName &&
      orgData.email &&
      orgData.pincode &&
      orgData.address1 &&
      orgData.state &&
      orgData.city &&
      orgData.district &&
      orgData.logo &&
      orgData.locationUrl &&
      orgData.status &&
      (orgData.status === 'Active' || orgData.reasonForDeboarding)
    ) {
      let data = new FormData()
      data.append('city', orgData.city)
      data.append('email', orgData.email)
      data.append('state', orgData.state)
      data.append('udaanUrl', orgData.udaanUrl)
      data.append('udaanName', orgData.udaanName)
      data.append('pincode', orgData.pincode)
      data.append('address1', orgData.address1)
      data.append('address2', orgData.address2)
      data.append('district', orgData.district)
      data.append('mobileNumber', orgData.mobileNumber)
      data.append('leProfileUrl', orgData.leProfileUrl)
      data.append('location', JSON.stringify(orgData.location))
      data.append('locationUrl', orgData.locationUrl)
      data.append('bankIfsc', orgData.bankIfsc)
      data.append('bankAccNo', orgData.bankAccNo)
      data.append('bankBranchAddress', orgData.bankBranchAddress)
      data.append('status', orgData.status)
      data.append(
        'reasonForDeboarding',
        orgData.status === 'Inactive' ? orgData.reasonForDeboarding : ''
      )
      if (orgData.officePhotos.length) {
        let officeLinks = []
        for (let index = 0; index < orgData.officePhotos.length; index++) {
          if (typeof orgData.officePhotos[index] !== 'string')
            data.append('officephoto-' + index, orgData.officePhotos[index])
          else {
            officeLinks.push(orgData.officePhotos[index])
          }
        }
        data.append('otherImageLinks', officeLinks)
      }
      if (orgData.logo && typeof orgData.logo !== 'string')
        data.append('logo', orgData.logo)
      if (
        orgData.registrationCertificate &&
        typeof orgData.registrationCertificate !== 'string'
      )
        data.append('registrationCertificate', orgData.registrationCertificate)

      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/udaan/' +
          (this.props.match.params.orgId || this.props.match.params.userId),
        {
          method: 'PATCH',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
          body: data,
        }
      )
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            this.notifySuccess('Successfully updated UDAAN!')
            setTimeout(() => {
              window.location.pathname =
                '/udaan-centres/udaan/' +
                (this.props.match.params.orgId ||
                  this.props.match.params.userId)
            }, 1000)
          } else {
            this.notifyError('Update UDAAN failed!')
          }
        })
    } else {
      this.notifyError('Please fill in the required fields!')
      specialError
        ? this.updateErrorObject('location', false, 'Location required')
        : this.updateErrorObject('location', true, '')
      orgData.mobileNumber
        ? this.updateErrorObject('mobileNumber', true, '')
        : this.updateErrorObject('mobileNumber', false, 'Mobile No. required')
      orgData.udaanName
        ? this.updateErrorObject('udaanName', true, '')
        : this.updateErrorObject('udaanName', false, 'UDAAN Name required')
      orgData.email
        ? this.updateErrorObject('email', true, '')
        : this.updateErrorObject('email', false, 'Email required')
      orgData.pincode
        ? this.updateErrorObject('pincode', true, '')
        : this.updateErrorObject('pincode', false, 'Pincode required')
      orgData.address1
        ? this.updateErrorObject('address1', true, '')
        : this.updateErrorObject('address1', false, 'Address 1 required')
      orgData.state
        ? this.updateErrorObject('state', true, '')
        : this.updateErrorObject('state', false, 'State required')
      orgData.district
        ? this.updateErrorObject('district', true, '')
        : this.updateErrorObject('district', false, 'District required')
      orgData.city
        ? this.updateErrorObject('city', true, '')
        : this.updateErrorObject('city', false, 'City required')
      orgData.logo
        ? this.updateErrorObject('logo', true, '')
        : this.updateErrorObject('logo', false, 'Logo required')
      orgData.locationUrl
        ? this.updateErrorObject('locationUrl', true, '')
        : this.updateErrorObject('locationUrl', false, 'Location URL required')
      orgData.status
        ? this.updateErrorObject('status', true, '')
        : this.updateErrorObject('status', false, 'UDAAN status required')
      orgData.status === 'Active' || orgData.reasonForDeboarding
        ? this.updateErrorObject('reasonForDeboarding', true, '')
        : this.updateErrorObject(
            'reasonForDeboarding',
            false,
            'Reason for deboarding is required'
          )
    }
  }

  updateErrorObject = (key, isValid, err) => {
    let errorObj = this.state.errorObj
    if (isValid) delete errorObj[key]
    else errorObj[key] = err
    this.setState({ errorObj })
  }

  notifySuccess = text => toast.success(text)

  notifyError = text => toast.error(text)

  render() {
    const options = { types: ['(regions)'] },
      renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
          <input {...getInputProps({ className: 'form-control' })} />
          <div className="autocomplete-dropdown-container">
            {suggestions.map(suggestion => (
              <div {...getSuggestionItemProps(suggestion)}>
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )

    return (
      <div className="Udaan-org-edit page-container">
        <Row>
          <Col
            md={12}
            className="d-flex align-items-center org-type-header-left-container"
            style={{ marginBottom: '20px' }}
          >
            <a
              className="go-back-btn d-flex align-items-center"
              href={`/udaan-centres/${this.props.match.params.type}/${this.props.match.params.orgId || this.props.match.params.userId}`}
            >
              <i className="fas fa-chevron-left mr5"></i>Back
            </a>
            <h3 className="header-title">EDIT ORGANISATION DETAILS</h3>
          </Col>
          {this.state.orgData ? (
            <Row>
              <Col md={6} className="mb15">
                <label className="control-label is-imp">Enter UDAAN Name</label>
                <input
                  type="text"
                  value={this.state.orgData.udaanName}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'udaanName')}
                />
                {this.state.errorObj.hasOwnProperty('udaanName') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.udaanName}
                  </div>
                ) : null}
              </Col>

              <Col md={6} className="mb15">
                <label className="control-label is-imp">
                  Enter UDAAN Email
                </label>
                <input
                  type="email"
                  value={this.state.orgData.email}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'email')}
                />
                {this.state.errorObj.hasOwnProperty('email') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.email}
                  </div>
                ) : null}
              </Col>

              <Col md={6} className="mb15">
                <label className="control-label is-imp">
                  Enter UDAAN Mobile No
                </label>
                <input
                  type="text"
                  value={this.state.orgData.mobileNumber}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'mobileNumber')}
                />
                {this.state.errorObj.hasOwnProperty('mobileNumber') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.mobileNumber}
                  </div>
                ) : null}
              </Col>

              <Col md={4} className="mb15">
                <label className="control-label is-imp">Select Status</label>
                <select
                  value={this.state.orgData.status}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'status')}
                  // disabled={!this.state.allCities.length}
                >
                  <option value="" disabled={true}>
                    Select Status
                  </option>
                  <option value={'Active'}>{'Active'}</option>
                  <option value={'Inactive'}> {'Inactive'}</option>

                  {/* {this.state.allCities.length ? (
										this.state.allCities.map((city, index) => (
											<option value={city.name} key={city._id}>{city.name}</option>
										))
									) : (null)} */}
                </select>
                {this.state.errorObj.hasOwnProperty('status') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.status}
                  </div>
                ) : null}
              </Col>

              {this.state.orgData.status === 'Inactive' ? (
                <Col md={4} className="mb15">
                  <label className="control-label is-imp">
                    Select Reason for Deboarding
                  </label>
                  <select
                    value={this.state.orgData.reasonForDeboarding}
                    className="form-control"
                    onChange={this.changeHandler.bind(
                      this,
                      'reasonForDeboarding'
                    )}
                    // disabled={!this.state.allCities.length}
                  >
                    <option value="" disabled={true}>
                      Select Reason for Deboarding
                    </option>
                    {[
                      'Poor performance, not able to meet desired outcomes',
                      'Passiveness despite numerous online and in-person training',
                      'Did not start the mission at all (<15 profiles)',
                      'Organization adversely affected by COVID 19',
                      'Took the advance and left the mission',
                      'Got bigger opportunity in livelihood space',
                      'Got bigger opportunity in livelihood space owing to Mission Swavalamban',
                      "UDAAN's internal resource crunch",
                      'Fraudulent or misleading activities',
                      'Left the mission after taking initial payment',
                      'District discontinued',
                    ].map(reason => (
                      <option value={reason} key={reason}>
                        {reason}
                      </option>
                    ))}

                    {/* {this.state.allCities.length ? (
										this.state.allCities.map((city, index) => (
											<option value={city.name} key={city._id}>{city.name}</option>
										))
									) : (null)} */}
                  </select>
                  {this.state.errorObj.hasOwnProperty('reasonForDeboarding') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.reasonForDeboarding}
                    </div>
                  ) : null}
                </Col>
              ) : null}

              <Col md={6} className="mb15">
                <label className="control-label is-imp">
                  Enter Admin Full Name
                </label>
                <input
                  type="text"
                  value={this.state.orgData.fullName}
                  className="form-control"
                  disabled={true}
                />
              </Col>

              <Col md={3} className="mb15">
                <label className="control-label">IFSC</label>
                <Input
                  placeholder="Enter Bank IFSC"
                  value={this.state.orgData.bankIfsc}
                  onChange={this.changeHandler.bind(this, 'bankIfsc')}
                  addonAfter={this.showIFSCIcon()}
                  onBlur={this.verifyIFSC}
                />
                {/* <input type="text" value={this.state.orgData.mobileNumber} className="form-control" onChange={this.changeHandler.bind(this, 'mobileNumber')} /> */}
                {this.state.errorObj.hasOwnProperty('bankIFSC') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.bankIFSC}
                  </div>
                ) : null}
              </Col>
              <Col md={4} className="mb15">
                <label className="control-label">Account Number</label>
                <input
                  type="text"
                  value={this.state.orgData.bankAccNo}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'bankAccNo')}
                  placeholder={'Enter Account Number'}
                />
                {this.state.errorObj.hasOwnProperty('bankAccNo') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.bankAccNo}
                  </div>
                ) : null}
              </Col>
              <Col md={5} className="mb15">
                <label className="control-label">Branch Address</label>
                <input
                  type="text"
                  value={this.state.orgData.bankBranchAddress}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'bankBranchAddress')}
                  placeholder={'Enter Branch Address'}
                />
                {this.state.errorObj.hasOwnProperty('bankBranchAddress') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.bankBranchAddress}
                  </div>
                ) : null}
              </Col>
              <Col md={4} className="mb15">
                <label className="control-label is-imp">Select a State</label>
                <select
                  value={this.state.orgData.state}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'state')}
                >
                  <option value="" disabled={true}>
                    Select state
                  </option>
                  {this.state.allStates.length
                    ? this.state.allStates.map((state, index) => (
                        <option value={state.name} key={state._id}>
                          {state.name}
                        </option>
                      ))
                    : null}
                </select>
                {this.state.errorObj.hasOwnProperty('state') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.state}
                  </div>
                ) : null}
              </Col>

              <Col md={4} className="mb15">
                <label className="control-label is-imp">
                  Select a District
                </label>
                <select
                  value={this.state.orgData.district}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'district')}
                  disabled={!this.state.allDistricts.length}
                >
                  <option value="" disabled={true}>
                    Select District
                  </option>
                  {this.state.allDistricts.length
                    ? this.state.allDistricts.map((district, index) => (
                        <option value={district.name} key={district._id}>
                          {district.name}
                        </option>
                      ))
                    : null}
                </select>
                {this.state.errorObj.hasOwnProperty('district') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.district}
                  </div>
                ) : null}
              </Col>

              <Col md={4} className="mb15">
                <label className="control-label is-imp">Select a City</label>
                <select
                  value={this.state.orgData.city}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'city')}
                  disabled={!this.state.allCities.length}
                >
                  <option value="" disabled={true}>
                    Select City
                  </option>
                  {this.state.allCities.length
                    ? this.state.allCities.map((city, index) => (
                        <option value={city.name} key={city._id}>
                          {city.name}
                        </option>
                      ))
                    : null}
                </select>
                {this.state.errorObj.hasOwnProperty('city') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.city}
                  </div>
                ) : null}
              </Col>

              <Col md={12} className="mb15">
                <label className="control-label is-imp">
                  Enter Address Line 1
                </label>
                <input
                  type="text"
                  value={this.state.orgData.address1}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'address1')}
                />
                {this.state.errorObj.hasOwnProperty('address1') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.address1}
                  </div>
                ) : null}
              </Col>

              <Col md={9} className="mb15">
                <label className="control-label">Enter Address Line 2</label>
                <input
                  type="text"
                  value={this.state.orgData.address2}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'address2')}
                />
              </Col>
              <Col md={3} className="mb15">
                <label className="control-label is-imp">Enter Pincode</label>
                <input
                  type="text"
                  value={this.state.orgData.pincode}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'pincode')}
                />
                {this.state.errorObj.hasOwnProperty('pincode') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.pincode}
                  </div>
                ) : null}
              </Col>

              <Col md={12} className="mb15">
                <label className="control-label is-imp">
                  Enter UDAAN Location
                </label>
                <PlacesAutocomplete
                  onChange={this.onPreferenceLocationChange}
                  value={this.state.orgData.location.label}
                  options={options}
                >
                  {renderFunc}
                </PlacesAutocomplete>
                {this.state.errorObj.hasOwnProperty('location') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.location}
                  </div>
                ) : null}
              </Col>

              <Col md={6} className="mb15">
                <label className="control-label">Enter UDAAN Website Url</label>
                <input
                  type="text"
                  value={this.state.orgData.udaanUrl}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'udaanUrl')}
                />
              </Col>

              <Col md={6} className="mb15">
                <label className="control-label">Enter LE Profile Url</label>
                <input
                  type="text"
                  value={this.state.orgData.leProfileUrl}
                  className="form-control"
                  onChange={this.changeHandler.bind(this, 'leProfileUrl')}
                />
              </Col>
              <Col md={12} className="mb5">
                <label className="control-label is-imp">Location URL</label>
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    value={this.state.orgData.locationUrl}
                    className="form-control"
                    onChange={this.changeHandler.bind(this, 'locationUrl')}
                  />
                </div>
                {this.state.errorObj.hasOwnProperty('locationUrl') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.locationUrl}
                  </div>
                ) : null}
              </Col>
              <Col md={12} className="mb15">
                <label className="control-label is-imp">Upload Logo</label>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    className="form-control col-md-6"
                    style={{ padding: '3px', marginRight: '10px' }}
                    onChange={this.changeHandler.bind(this, 'logo')}
                  />
                  {this.state.orgData.logo &&
                  typeof this.state.orgData.logo === 'string' ? (
                    <img
                      alt=""
                      src={this.state.orgData.logo}
                      width="110"
                      style={{
                        borderWidth: 1,
                        borderColor: '#eee',
                        padding: '5px',
                      }}
                    />
                  ) : null}
                </div>
                {this.state.errorObj.hasOwnProperty('logo') ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.logo}
                  </div>
                ) : null}
              </Col>
              <Col md={12} className="mb15">
                <label className="control-label">
                  Upload Registration Certificate
                </label>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg, .pdf, .docx"
                    className="form-control col-md-6"
                    style={{ padding: '3px', marginRight: '10px' }}
                    onChange={this.changeHandler.bind(
                      this,
                      'registrationCertificate'
                    )}
                  />
                  {this.state.orgData.registrationCertificate &&
                  typeof this.state.orgData.registrationCertificate ===
                    'string' ? (
                    <img
                      alt=""
                      src={this.state.orgData.registrationCertificate}
                      width="110"
                      style={{
                        borderWidth: 1,
                        borderColor: '#eee',
                        padding: '5px',
                      }}
                    />
                  ) : null}
                </div>
                {this.state.errorObj.hasOwnProperty(
                  'registrationCertificate'
                ) ? (
                  <div
                    style={{ color: 'red', fontSize: '13px', fontWeight: 600 }}
                  >
                    {this.state.errorObj.registrationCertificate}
                  </div>
                ) : null}
              </Col>
              <Col md={12} className="mb15">
                <label className="control-label">
                  Upload Office Photos (Upto 3 photos of the office front with
                  banners can be uploaded)
                </label>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    multiple
                    className="form-control col-md-6"
                    style={{ padding: '3px', marginRight: '10px' }}
                    onChange={this.changeHandler.bind(this, 'officePhotos')}
                  />
                  {this.state.orgData.officePhotos.length
                    ? this.state.orgData.officePhotos.map((image, index) => {
                        return typeof image === 'string' ? (
                          <div style={{ textAlign: 'center' }}>
                            <Row>
                              <img
                                src={image}
                                alt=""
                                width="150"
                                height="100"
                                style={{
                                  borderWidth: 1,
                                  borderColor: '#eee',
                                  padding: '5px',
                                }}
                              />
                            </Row>
                            <i
                              style={{ color: '#EF5A20' }}
                              class="fa fa-trash"
                              aria-hidden="true"
                              onClick={this.deleteOfficeImage.bind(this, index)}
                            ></i>
                          </div>
                        ) : null
                      })
                    : null}
                </div>
                {/* <div style={{color: 'red', fontSize: '13px', fontWeight: 600}}>{this.state.maxImagesErr}</div> */}

                {/* {this.state.errorObj.hasOwnProperty('logo') ? (
									<div style={{color: 'red', fontSize: '13px', fontWeight: 600}}>{this.state.errorObj.logo}</div>
								) : (null)} */}
              </Col>
            </Row>
          ) : null}
          <Col md={12} className="d-flex justify-content-center mt20">
            <Button variant="primary" onClick={this.submitDetails}>
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}
