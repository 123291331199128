import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  batchData: {},
  loading: false,
  error: '',
  message: '',
}

export const getSingleBatch = createAsyncThunk(
  'getSingleBatch',
  async (_id, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/batches/${_id}`
      )
      console.log('_id from getSingleBatch ==> ', _id)
      console.log('res.data from getSingleBatch ==> ', res.data)
      return res.data.singleBatch
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetSingleBatchData = createAction('resetSingleBatchData')

const getSingleBatchSlice = createSlice({
  name: 'getSingleBatch',
  initialState,
  reducers: {},

  extraReducers: {
    [getSingleBatch.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getSingleBatch.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        console.log('payload => ', payload)
        state.batchData = payload
      }
    },

    [getSingleBatch.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.loading = false
    },

    [resetSingleBatchData]: state => {
      state.batchData = []
    },
  },
})

export default getSingleBatchSlice.reducer
