import React from 'react'

export function getRandomAlphabets() {
  const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' // String containing all alphabets from A to Z
  let result = ''
  for (let i = 0; i < 3; i++) {
    // loop 3 times to get 3 random alphabets
    const randomIndex = Math.floor(Math.random() * alphabets.length) // generate a random index between 0 and the length of the alphabet string
    result += alphabets[randomIndex] // append the randomly selected alphabet to the result string
  }
  return result
}

export const generateId = () => {
  const now = new Date()
  // const year = now.getFullYear();
  const month = now.getMonth() + 1 // month is zero-indexed, so add 1
  const day = now.getDate()
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()
  const randomAlphabets = getRandomAlphabets()
  return `${randomAlphabets}${month}${day}${hours}${minutes}${seconds}`
}

export const changeDateTimeFormat = inputDate => {
  const date = new Date(inputDate)
  const formattedDateTime = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  return formattedDateTime
}

export function validateMobileNumber(mobileNumber) {
  // Remove any whitespace or special characters from the input
  const cleanedNumber = mobileNumber.replace(/\s+|\W+/g, '')

  // Regular expression pattern to validate the mobile number
  const mobileNumberPattern = /^(91\+)?\d{10}$/

  // Check if the cleaned number matches the pattern
  const isValid = mobileNumberPattern.test(cleanedNumber)
  console.log('isValid mobile number =>', isValid)

  return isValid
}
