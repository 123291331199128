import React, { Component } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { ConfigProvider, DatePicker, Select, Pagination } from 'antd'
import { Modal, Row, Col, Button, Carousel } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import moment from 'moment'

import enUS from 'antd/es/locale/en_US'

const readCookie = require('../cookie.js').readCookie
const ValidateData = require('../../../ValidateData.js')

const { Option, OptGroup } = Select
const { RangePicker } = DatePicker

export default class AllActivities extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      allSCKs: [],
      errorObj: {},
      allStates: [],
      sizePerPage: 12,
      allDistricts: [],
      filterData: {
        sck: '',
        state: [],
        district: [],
        toDate: null,
        fromDate: null,
      },
      activities: null,
      newActivity: null,
      totalActivities: 0,
      editActivity: false,
      savingActivity: false,
      selectedActivity: null,
      editActivityPhotoUrls: [],
      showMoreModalVisibility: false,
      addActivityModalVisibility: false,
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    let orgId = '',
      orgName = ''
    if (this.props.orgId) orgId = this.props.orgId
    else if (
      JSON.parse(readCookie('userData')).type === 'partners' &&
      JSON.parse(readCookie('userData')).ngo
    )
      orgId = JSON.parse(readCookie('userData')).ngo._id

    fetch(process.env.REACT_APP_API_URL + '/api/v1/location/states', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allStates: data.data.states })
        }
      })

    if (
      !this.props.orgId &&
      JSON.parse(readCookie('userData')).type !== 'partners'
    ) {
      fetch(process.env.REACT_APP_API_URL + '/api/v1/ngo', {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      })
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allSCKs: data.ngos })
          }
        })
    }

    if (
      !this.props.orgId &&
      JSON.parse(readCookie('userData')).type === 'partners' &&
      JSON.parse(readCookie('userData')).ngo
    ) {
      let state = JSON.parse(readCookie('userData')).ngo.state,
        district = JSON.parse(readCookie('userData')).ngo.district,
        orgId =
          JSON.parse(readCookie('userData')).ngo._id ||
          JSON.parse(readCookie('userData')).ngo.id

      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/get-address?stateId=' +
          state,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allDistricts: data.districts })
          }
        })

      fetch(
        process.env.REACT_APP_API_URL +
          `/api/v1/ngo?state=${state}&district=${district}`,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allSCKs: data.ngos })
          }
        })

      let filterData = this.state.filterData
      filterData.sck = orgId
      filterData.state = state
      filterData.district = district

      this.setState({ filterData }, () => this.submitFilter())
    } else {
      this.submitFilter()
    }
  }

  disabledFromDate = fromDate => {
    const { toDate } = this.state.filterData
    if (!fromDate || !toDate) {
      return false
    }
    return fromDate.valueOf() > toDate.valueOf()
  }

  disabledToDate = toDate => {
    const { fromDate } = this.state.filterData
    if (!toDate || !fromDate) {
      return false
    }
    return toDate.valueOf() <= fromDate.valueOf()
  }

  getAllActivities = (query = '') => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/activity-log' + query, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({
            activities: data.activities,
            totalActivities: data.totalActivities,
          })
        }
      })
  }

  resetFilter = () => {
    let filterData = {
      sck: '',
      state: '',
      district: '',
      fromDate: null,
      toDate: null,
    }
    let query = `?pageNumber=0&limit=${this.state.sizePerPage}`
    this.setState({ filterData, page: 1 }, () => this.getAllActivities(query))
  }

  submitFilter = (
    page = this.state.page,
    sizePerPage = this.state.sizePerPage
  ) => {
    let filterData = this.state.filterData,
      query = `?pageNumber=${page - 1}&limit=${sizePerPage}`

    if (filterData.sck !== '') query += `&sck=${filterData.sck}`
    if (filterData.state && filterData.state.length)
      query += `&state=${filterData.state}`
    if (filterData.district && filterData.district.length)
      query += `&district=${filterData.district}`
    if (filterData.toDate)
      query += `&toDate=${moment(filterData.toDate).format('DD-MM-YYYY')}`
    if (filterData.fromDate)
      query += `&fromDate=${moment(filterData.fromDate).format('DD-MM-YYYY')}`
    if (
      this.props.orgId &&
      this.props.type === 'swavalamban-connect-kendra' &&
      this.props.view === 'tab'
    )
      query += `&sck=${this.props.orgId}`
    this.getAllActivities(query)
  }

  changeStateFilter = e => {
    let filterData = this.state.filterData
    filterData['state'] = e
    if (e) {
      filterData['sck'] = ''
      filterData['district'] = []
      fetch(
        process.env.REACT_APP_API_URL +
          '/api/v1/location/districts?states=' +
          e.join(','),
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allDistricts: data.districts })
          }
        })

      fetch(
        process.env.REACT_APP_API_URL + '/api/v1/ngo?states=' + e.join(','),
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allSCKs: data.ngos })
          }
        })
    }
    this.setState({ filterData }, () => this.submitFilter())
  }

  changeFilterHandler = (type, e) => {
    let filterData = this.state.filterData,
      value = ''
    if (e && e.hasOwnProperty('target')) value = e.target.value
    else value = e
    if (type === 'district') {
      filterData['sck'] = ''
      filterData['district'] = value
      let state, district
      if (Array.isArray(filterData.state)) state = filterData.state.join(',')
      else state = filterData.state
      if (Array.isArray(value)) district = value.join(',')
      else district = value
      fetch(
        process.env.REACT_APP_API_URL +
          `/api/v1/ngo?states=${state}&districts=${district}`,
        {
          method: 'GET',
          headers: {
            'x-auth-token': JSON.parse(readCookie('access_token')),
          },
        }
      )
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ allSCKs: data.ngos })
          }
        })
    } else if (type === 'fromToDate') {
      filterData['fromDate'] = e[0]
      filterData['toDate'] = e[1]
    } else {
      filterData[type] = e
    }
    this.setState({ filterData }, () => this.submitFilter())
  }

  toggleAddActivityModal = () => {
    let ledBy = {}
    if (this.props.orgId) {
      ledBy.id = this.props.orgId
      ledBy.name = this.props.orgName
    } else if (JSON.parse(readCookie('userData')).ngo) {
      ledBy.id = JSON.parse(readCookie('userData')).ngo._id
      ledBy.name = JSON.parse(readCookie('userData')).ngo.ngoName
    } else if (
      JSON.parse(readCookie('userData')).type === 'le' &&
      (JSON.parse(readCookie('userData')).accountType === 'fellow' || 'pmu') &&
      (JSON.parse(readCookie('userData')).userType.name ===
        'Swavalamban Fellows' ||
        'Project Management Unit')
    ) {
      ledBy.id = JSON.parse(readCookie('userData'))._id
      ledBy.name = JSON.parse(readCookie('userData')).fullName
    } else if (
      JSON.parse(readCookie('userData')).type === 'partners' &&
      JSON.parse(readCookie('userData')).accountType === 'localGovernment'
    ) {
      ledBy.id = JSON.parse(readCookie('userData'))._id
      ledBy.name = JSON.parse(readCookie('userData')).fullName
    } else {
      ledBy = { id: '', name: '' }
    }
    this.setState({
      addActivityModalVisibility: !this.state.addActivityModalVisibility,
      newActivity: {
        dateOfActivity: '',
        location: { label: '', latitude: '', longitude: '' },
        pictures: [],
        audienceSize: '',
        description: '',
        ledBy,
        activityType: '',
      },
    })
  }

  changeNewActivityHandler = (type, e) => {
    let newActivity = this.state.newActivity,
      errorObj = this.state.errorObj

    if (type === 'audienceSize')
      ValidateData.numberValidate('audienceSize', e.target.value, errorObj)
    this.setState({ errorObj })

    if (type === 'pictures') {
      if (e.target.files.length) {
        if (e.target.files.length < 6) {
          delete errorObj['pictures']
          newActivity['pictures'] = e.target.files
        } else {
          newActivity['pictures'] = []
          errorObj['pictures'] = 'Please select a max of 5 pictures.'
        }
      } else newActivity['pictures'] = []
      this.setState({ newActivity, errorObj })
    } else if (type === 'dateOfActivity') {
      if (moment(e.target.value, 'YYYY-MM-DD').diff(moment(), 'days') < 0) {
        delete errorObj['dateOfActivity']
        newActivity['dateOfActivity'] = e.target.value
      } else if (
        moment(e.target.value, 'YYYY-MM-DD').diff(moment(), 'days') >= 0
      ) {
        if (moment(e.target.value, 'YYYY-MM-DD').diff(moment(), 'days') > 0) {
          errorObj['dateOfActivity'] = 'Please select a past/present date.'
        } else if (
          moment(e.target.value, 'YYYY-MM-DD').diff(moment(), 'days') === 0
        ) {
          if (
            parseInt(moment(e.target.value, 'YYYY-MM-DD').format('DD')) >
            parseInt(moment().format('DD'))
          )
            errorObj['dateOfActivity'] = 'Please select a past/present date.'
          else {
            delete errorObj['dateOfActivity']
            newActivity['dateOfActivity'] = e.target.value
          }
        }
      }
      this.setState({ newActivity, errorObj })
    } else {
      newActivity[type] = e.target.value
      this.setState({ newActivity })
    }
  }

  onPreferenceLocationChange = preferenceLocation => {
    let newActivity = this.state.newActivity
    newActivity['location']['label'] = preferenceLocation
    this.setState({ newActivity }, () => {
      geocodeByAddress(preferenceLocation)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          let newActivity = this.state.newActivity
          newActivity['location']['latitude'] = latLng.lat
          newActivity['location']['longitude'] = latLng.lng
          this.setState({ newActivity })
        })
        .catch(error => console.error('Error', error))
    })
  }

  submitActivity = () => {
    let newActivity = this.state.newActivity,
      errorObj = this.state.errorObj,
      specialError = false

    if (!newActivity.location) specialError = true
    else {
      if (
        !newActivity.location.label ||
        !newActivity.location.latitude ||
        !newActivity.location.longitude
      )
        specialError = true
    }
    if (
      !Object.keys(errorObj).length &&
      !specialError &&
      newActivity.dateOfActivity &&
      (newActivity.pictures.length ||
        (this.state.editActivity && this.state.editActivityPhotoUrls.length)) &&
      newActivity.audienceSize &&
      newActivity.description &&
      newActivity.ledBy &&
      newActivity.activityType
    ) {
      this.setState({ savingActivity: true })
      let data = new FormData()
      if (!this.state.editActivity) data.append('ledBy', newActivity.ledBy.id)
      if (newActivity.pictures.length) {
        for (var i = 0; i < newActivity.pictures.length; i++) {
          data.append('pictures-' + i, newActivity.pictures[i])
        }
      }
      data.append('description', newActivity.description)
      data.append('activityType', newActivity.activityType)
      data.append('totalPictures', newActivity.pictures.length)
      data.append('dateOfActivity', newActivity.dateOfActivity)
      data.append('location', JSON.stringify(newActivity.location))
      data.append('audienceSize', parseInt(newActivity.audienceSize))

      let path = process.env.REACT_APP_API_URL + '/api/v1/activity-log',
        method = 'POST'
      if (this.state.editActivity) {
        method = 'PATCH'
        path += '/' + this.state.editActivity
      }

      fetch(path, {
        method,
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: data,
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            this.setState({ savingActivity: false })
            this.toggleAddActivityModal()
            this.notifySuccess('Successfully added new activity!')
            this.resetFilter()
          } else {
            this.notifyError(data.errorMsg)
          }
        })
    } else {
      this.notifyError('Please fill in the required fields!')
      specialError
        ? this.updateErrorObject('location', false, 'Location required')
        : this.updateErrorObject('location', true, '')
      newActivity.ledBy
        ? this.updateErrorObject('ledBy', true, '')
        : this.updateErrorObject('ledBy', false, 'Led By required')
      newActivity.pictures
        ? this.updateErrorObject('pictures', true, '')
        : this.updateErrorObject('pictures', false, 'Pictures required')
      newActivity.description
        ? this.updateErrorObject('description', true, '')
        : this.updateErrorObject('description', false, 'Description required')
      newActivity.activityType
        ? this.updateErrorObject('activityType', true, '')
        : this.updateErrorObject(
            'activityType',
            false,
            'Activity Type required'
          )
      newActivity.dateOfActivity
        ? this.updateErrorObject('dateOfActivity', true, '')
        : this.updateErrorObject(
            'dateOfActivity',
            false,
            'Date Of Activity required'
          )
      newActivity.audienceSize
        ? this.updateErrorObject('audienceSize', true, '')
        : this.updateErrorObject(
            'audienceSize',
            false,
            'Audience Size required'
          )
    }
  }

  updateErrorObject = (key, isValid, err) => {
    let errorObj = this.state.errorObj
    if (isValid) delete errorObj[key]
    else errorObj[key] = err
    this.setState({ errorObj })
  }

  searchFilter = (input, option) => {
    input = input.toLowerCase()
    if (!Array.isArray(option.props.children)) {
      if (typeof option.props.children === 'string') {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }
    }
  }

  onShowSizeChange = (page, sizePerPage) => {
    this.setState({ sizePerPage })
    this.submitFilter(page, sizePerPage)
  }

  paginationChange = (page, sizePerPage) => {
    this.setState({ page })
    this.submitFilter(page, sizePerPage)
  }

  selectedActivity = activity => {
    this.setState({ selectedActivity: activity, showMoreModalVisibility: true })
  }

  toggleShowMoreModal = () => {
    this.setState({
      showMoreModalVisibility: !this.state.showMoreModalVisibility,
      selectedActivity: null,
    })
  }

  editActivity = activity => {
    if (activity) {
      let activityData = {
        pictures: [],
        ledBy: {
          id: activity.ledBy.id,
          name: activity.ledBy.ngoName,
        },
        location: activity.location,
        description: activity.description,
        activityType: activity.activityType,
        audienceSize: activity.audienceSize,
        dateOfActivity: moment(activity.dateOfActivity).format('YYYY-MM-DD'),
      }
      this.setState({
        addActivityModalVisibility: !this.state.addActivityModalVisibility,
        editActivity: activity._id,
        editActivityPhotoUrls: activity.pictures,
        newActivity: activityData,
        showMoreModalVisibility: false,
        selectedActivity: null,
      })
    }
  }

  deleteActivity = activityId => {
    if (activityId) {
      this.toggleShowMoreModal()
      Swal.fire({
        title: 'Are you sure you want to delete the activity?',
        text: `You wont be able to revert this.`,
        type: 'warning',
        showCancelButton: true,
      }).then(res => {
        if (res.value) {
          fetch(
            process.env.REACT_APP_API_URL +
              '/api/v1/activity-log/' +
              activityId,
            {
              method: 'DELETE',
              headers: {
                'x-auth-token': JSON.parse(readCookie('access_token')),
              },
            }
          )
            .then(data => data.json())
            .then(data => {
              if (data.status) {
                this.notifySuccess('Successfully deleted the activity')
                this.resetFilter()
              } else {
                this.notifyError('Deletion of the activity failed')
              }
            })
            .catch(err => console.log(err))
        }
      })
    }
  }

  cancelImageChange = () => {
    if (this.state.editActivity) {
      let newActivity = this.state.newActivity
      newActivity['pictures'] = []
      this.setState({ newActivity })
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  render() {
    const options = { types: ['(regions)'] },
      renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
          <input {...getInputProps({ className: 'form-control' })} />
          <div className="autocomplete-dropdown-container">
            {suggestions.map(suggestion => (
              <div {...getSuggestionItemProps(suggestion)}>
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )
    return (
      <div
        className={
          this.props.orgId
            ? 'all-activities-page'
            : 'all-activities-page page-container'
        }
      >
        {!this.props.orgId ? <h3 className="ml30 mt10">Activities</h3> : null}
        {!this.props.orgId ? (
          <div className="filters-container">
            <div className="filter form-group">
              <label className="control-label">{'From - To date: '}</label>
              <ConfigProvider locale={enUS}>
                <RangePicker
                  locale={enUS}
                  style={{ width: '300px' }}
                  format="DD/MM/YYYY"
                  value={[
                    this.state.filterData.fromDate,
                    this.state.filterData.toDate,
                  ]}
                  type="date"
                  onChange={this.changeFilterHandler.bind(this, 'fromToDate')}
                />
              </ConfigProvider>
            </div>
            <div className="filter form-group">
              <label className="control-label">{'State: '}</label>
              <Select
                mode="multiple"
                showSearch
                style={{ width: '150px' }}
                value={this.state.filterData.state}
                onChange={this.changeStateFilter}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="">All States</Option>
                <OptGroup label="Focus States">
                  {this.state.allStates.map((state, stateIndex) => {
                    if (state.inFocusRegion) {
                      return (
                        <Option key={stateIndex} value={state.name}>
                          {state.name}
                        </Option>
                      )
                    } else return null
                  })}
                </OptGroup>
                {/*<OptGroup label="Not Focussed States">
									{this.state.allStates.map((state, stateIndex) => {
										if(!state.inFocusRegion) {
											return (
												<Option key={stateIndex} value={state.name}>{state.name}</Option>
											)
										} else return null;
									})}
								</OptGroup>*/}
              </Select>
            </div>
            <div className="filter form-group">
              <label className="control-label">{'District: '}</label>
              <Select
                mode="multiple"
                showSearch
                style={{ width: '160px' }}
                value={this.state.filterData.district}
                onChange={this.changeFilterHandler.bind(this, 'district')}
                filterOption={(input, option) =>
                  this.searchFilter(input, option)
                }
              >
                <Option value="">All Districts</Option>
                {this.state.filterData.state
                  ? this.state.allDistricts.map((district, districtIndex) => {
                      let districtName = district.name
                      if (district.state)
                        districtName += ' (' + district.state + ')'
                      return (
                        <Option value={district.name} key={districtIndex}>
                          {districtName}
                        </Option>
                      )
                    })
                  : null}
              </Select>
            </div>
            <div className="filter form-group">
              <label className="control-label">{'SCK: '}</label>
              <Select
                style={{ width: '160px' }}
                value={this.state.filterData.sck}
                onChange={this.changeFilterHandler.bind(this, 'sck')}
              >
                <Option value="">All SCKs</Option>
                {this.state.allSCKs.map((sck, sckIndex) => {
                  return (
                    <Option value={sck._id} key={sckIndex}>
                      {sck.ngoName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className="filter form-group">
              <button className="btn reset-filters" onClick={this.resetFilter}>
                Reset
              </button>
            </div>
          </div>
        ) : null}
        {JSON.parse(readCookie('userData')).type === 'partners' ||
        JSON.parse(readCookie('userData')).type === 'le' ? (
          // &&
          // JSON.parse(readCookie('userData')).accountType === "fellow" && JSON.parse(readCookie('userData')).userType.name === "Swavalamban Fellows"
          <div className="edit-btn-container d-flex justify-content-end">
            <Button variant="primary" onClick={this.toggleAddActivityModal}>
              Add Activity
            </Button>
          </div>
        ) : null}
        {this.state.activities ? (
          this.state.activities.length ? (
            <div className="all-activities-container">
              {this.state.activities.map((activity, actIndex) => {
                return (
                  <div className="activity-container" key={actIndex}>
                    <div className="activity-img-container">
                      {activity.pictures.length ? (
                        <div className="carousel-inner">
                          <Carousel
                            controls={false}
                            interval={3000}
                            indicators={false}
                          >
                            {activity.pictures.map((image, imageIndex) => {
                              return (
                                <Carousel.Item key={imageIndex}>
                                  <img className="d-block" src={image} alt="" />
                                </Carousel.Item>
                              )
                            })}
                          </Carousel>
                        </div>
                      ) : null}
                    </div>
                    <div className="activity-date">
                      <span className="title">Event Date: </span>
                      <span className="value">
                        {moment(activity.dateOfActivity).format('DD/MM/YYYY')}
                      </span>
                    </div>
                    <div className="activity-audience">
                      <span className="title">Audience Size: </span>
                      <span className="value">{activity.audienceSize}</span>
                    </div>
                    <div className="activity-audience">
                      <span className="title">Activity Type: </span>
                      <span className="value">{activity.activityType}</span>
                    </div>
                    <div className="activity-description">
                      {activity.description}
                    </div>
                    <div
                      className="see-more-btn"
                      onClick={this.selectedActivity.bind(this, activity)}
                    >
                      See More
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="no-activity-container">
              <div className="no-activity">
                {JSON.parse(readCookie('userData')).type === 'partners' ||
                (JSON.parse(readCookie('userData')).type === 'le' &&
                  JSON.parse(readCookie('userData')).accountType === 'fellow' &&
                  JSON.parse(readCookie('userData')).userType.name ===
                    'Swavalamban Fellows')
                  ? 'No Activities Found!'
                  : 'No Activities Found! Add some to view here.'}
              </div>
            </div>
          )
        ) : null}

        {this.state.activities && this.state.activities.length ? (
          <div className="pagination-container text-center mt15">
            <Pagination
              showSizeChanger
              onChange={this.paginationChange}
              onShowSizeChange={this.onShowSizeChange}
              current={this.state.page}
              total={this.state.totalActivities}
              pageSize={this.state.sizePerPage}
              pageSizeOptions={['8', '12', '16', '20']}
            />
          </div>
        ) : null}

        {this.state.selectedActivity ? (
          <Modal
            className="show-activity-modal"
            show={this.state.showMoreModalVisibility}
            onHide={this.toggleShowMoreModal}
            size="lg"
            aria-labelledby="contained-modal-title-lg"
          >
            {/*<Modal.Header className="show-activity-modal-header">
							<Modal.Title className="modal-header-custom">{this.state.selectedActivity.title}</Modal.Title>
						</Modal.Header>*/}
            <Modal.Body className="show-activity-modal-body">
              <Row>
                <Col md={5}>
                  <div className="activity-img-container">
                    {this.state.selectedActivity.pictures.length ? (
                      <Carousel
                        controls={false}
                        interval={3000}
                        indicators={false}
                      >
                        {this.state.selectedActivity.pictures.map(
                          (image, imageIndex) => {
                            return (
                              <Carousel.Item key={imageIndex}>
                                <img alt="" className="d-block" src={image} />
                              </Carousel.Item>
                            )
                          }
                        )}
                      </Carousel>
                    ) : (
                      <div>
                        <img
                          alt=""
                          className="d-block w-100"
                          src="/images/image-upload.png"
                        />
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={7} className="selected-activity-details">
                  <div className="activity-date-container">
                    <span className="label">Event Date: </span>
                    <span className="value">
                      {moment(
                        this.state.selectedActivity.dateOfActivity
                      ).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <div className="activity-reach">
                    <span className="label">Audience Size: </span>
                    <span className="value">
                      {this.state.selectedActivity.audienceSize}
                    </span>
                  </div>
                  <div className="activity-reach">
                    <span className="label">Activity Type: </span>
                    <span className="value">
                      {this.state.selectedActivity.activityType}
                    </span>
                  </div>
                  <div className="activity-location">
                    <span className="label location">Location: </span>
                    {this.state.selectedActivity.location.label ? (
                      <a
                        className="value"
                        href={`https://maps.google.com/?q=${this.state.selectedActivity.location.latitude},${this.state.selectedActivity.location.longitude}`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {this.state.selectedActivity.location.label}
                      </a>
                    ) : (
                      <a
                        className="value"
                        href={`https://maps.google.com/?q=${this.state.selectedActivity.location.latitude},${this.state.selectedActivity.location.longitude}`}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        {this.state.selectedActivity.location.latitude},{' '}
                        {this.state.selectedActivity.location.longitude}
                      </a>
                    )}
                  </div>
                  <div className="description">
                    <span className="label">Description: </span>
                    {this.state.selectedActivity.description}
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col md={12} className="d-flex justify-content-between">
                {JSON.parse(readCookie('userData')).accountType === 'Ngo' ? (
                  this.state.selectedActivity.ledBy.id ===
                  JSON.parse(readCookie('userData')).ngo.id ? (
                    <div className="d-flex">
                      <Button
                        className="mr10"
                        variant="warning"
                        onClick={this.editActivity.bind(
                          this,
                          this.state.selectedActivity
                        )}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={this.deleteActivity.bind(
                          this,
                          this.state.selectedActivity._id
                        )}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null
                ) : JSON.parse(readCookie('userData')).type === 'le' ? (
                  <div className="d-flex">
                    <Button
                      className="mr10"
                      variant="warning"
                      onClick={this.editActivity.bind(
                        this,
                        this.state.selectedActivity
                      )}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={this.deleteActivity.bind(
                        this,
                        this.state.selectedActivity._id
                      )}
                    >
                      Delete
                    </Button>
                  </div>
                ) : null}
                <Button variant="secondary" onClick={this.toggleShowMoreModal}>
                  Close
                </Button>
              </Col>
            </Modal.Footer>
          </Modal>
        ) : null}

        {this.state.newActivity ? (
          <Modal
            className="add-activity-modal"
            show={this.state.addActivityModalVisibility}
            onHide={this.toggleAddActivityModal}
            size="lg"
            aria-labelledby="contained-modal-title-lg"
          >
            <Modal.Header className="add-activity-modal-header">
              <Modal.Title className="modal-header-custom">
                {this.state.editActivity ? 'Edit Activity' : 'Add Activity'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="add-activity-modal-body">
              <Row className="body-conatiner">
                <Col md={4} className="mb15">
                  <label className="control-label is-imp">Activity Type</label>
                  <select
                    value={this.state.newActivity.activityType}
                    className="form-control"
                    onChange={this.changeNewActivityHandler.bind(
                      this,
                      'activityType'
                    )}
                  >
                    <option value="" disabled={true}>
                      Select Activity Type
                    </option>
                    <option value="Nukkad Natak">Nukkad Natak</option>
                    <option value="Inauguration">Inauguration</option>
                    <option value="Swavalamban Sabha">Swavalamban Sabha</option>
                    <option value="Other">Other</option>
                  </select>
                  {this.state.errorObj.hasOwnProperty('activityType') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.activityType}
                    </div>
                  ) : null}
                </Col>
                <Col md={4} className="mb15">
                  <label className="control-label is-imp">Led By</label>
                  <input
                    type="text"
                    value={this.state.newActivity.ledBy.name}
                    className="form-control"
                    // disabled={true}
                  />
                </Col>
                <Col md={4} className="mb15">
                  <label className="control-label is-imp">
                    Enter Date of Activity
                  </label>
                  <input
                    type="date"
                    value={this.state.newActivity.dateOfActivity}
                    className="form-control"
                    onChange={this.changeNewActivityHandler.bind(
                      this,
                      'dateOfActivity'
                    )}
                  />
                  {this.state.errorObj.hasOwnProperty('dateOfActivity') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.dateOfActivity}
                    </div>
                  ) : null}
                </Col>
                <Col md={12} className="mb15">
                  <label className="control-label is-imp">Description</label>
                  <textarea
                    value={this.state.newActivity.description}
                    className="form-control"
                    onChange={this.changeNewActivityHandler.bind(
                      this,
                      'description'
                    )}
                  ></textarea>
                  {this.state.errorObj.hasOwnProperty('description') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.description}
                    </div>
                  ) : null}
                </Col>

                <Col md={9} className="mb15">
                  <label className="control-label is-imp">Location</label>
                  <PlacesAutocomplete
                    onChange={this.onPreferenceLocationChange}
                    value={this.state.newActivity.location.label}
                    options={options}
                  >
                    {renderFunc}
                  </PlacesAutocomplete>
                  {this.state.errorObj.hasOwnProperty('location') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.location}
                    </div>
                  ) : null}
                </Col>
                <Col md={3} className="mb15">
                  <label className="control-label is-imp">Audience Size</label>
                  <input
                    type="number"
                    value={this.state.newActivity.audienceSize}
                    className="form-control"
                    onChange={this.changeNewActivityHandler.bind(
                      this,
                      'audienceSize'
                    )}
                  />
                  {this.state.errorObj.hasOwnProperty('audienceSize') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.audienceSize}
                    </div>
                  ) : null}
                </Col>

                <Col md={12} className="mb15">
                  <label className="control-label is-imp">
                    Upload Pictures
                  </label>
                  {this.state.editActivity &&
                  this.state.editActivityPhotoUrls.length &&
                  !this.state.newActivity.pictures.length ? (
                    <div className="d-flex preview-img-container">
                      {this.state.editActivityPhotoUrls.map(
                        (photo, photoIndex) => {
                          return (
                            <img className="preview-img" src={photo} alt="" />
                          )
                        }
                      )}
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <input
                      type="file"
                      multiple
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                      style={{ padding: '3px' }}
                      onChange={this.changeNewActivityHandler.bind(
                        this,
                        'pictures'
                      )}
                    />
                    {this.state.editActivity &&
                    this.state.newActivity.pictures.length ? (
                      <Button
                        className="cancel-image-change"
                        variant="secondary"
                        onClick={this.cancelImageChange}
                        disabled={this.state.savingActivity}
                      >
                        Cancel Image Change
                      </Button>
                    ) : null}
                  </div>
                  {this.state.errorObj.hasOwnProperty('pictures') ? (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        fontWeight: 600,
                      }}
                    >
                      {this.state.errorObj.pictures}
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col md={12} className="d-flex justify-content-between">
                <Button
                  variant="secondary"
                  onClick={this.toggleAddActivityModal}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={this.submitActivity}>
                  Submit
                </Button>
              </Col>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
    )
  }
}
