import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import BootstrapTable from 'react-bootstrap-table-next'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { Select } from 'antd'
import moment from 'moment'

// import AddPlatformUser from './AddPlatformUser';

const readCookie = require('../cookie.js').readCookie

const { SearchBar } = Search
const { Option } = Select

export default class PlatformUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      searchVal: '',
      userType: null,
      currentPage: 1,
      sizePerPage: 50,
      totalDataSize: 0,
      status: 'Active',
      deletableUsers: [],
      selectedRowCount: 0,
      deleteButtonDisabled: true,
      selectRowProp: {
        mode: 'checkbox',
        onSelect: this.onRowSelect.bind(this),
        onSelectAll: this.onAllRowSelect.bind(this),
      },
    }
  }

  componentDidMount() {
    this.fetchUsers()
  }

  fetchUsers = () => {
    let type
    if (this.props.match) type = this.props.match.params.type
    else if (this.props.type) type = this.props.type

    if (type) {
      fetch(process.env.REACT_APP_API_URL + '/api/v1/user/user-type/' + type, {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      })
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            this.setState({ userType: data.data })
          }
        })

      let path =
        process.env.REACT_APP_API_URL +
        '/api/v1/user/users/' +
        type +
        '?status=' +
        this.state.status
      if (type === 'swavalamban-connect-kendra')
        path =
          process.env.REACT_APP_API_URL +
          '/api/v1/ngo/' +
          this.props.orgId +
          '/user'

      fetch(path, {
        method: 'GET',
        headers: {
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
      })
        .then(data => data.json())
        .then(data => {
          if (data.status) {
            let totalDataSize = 0
            if (type === 'swavalamban-connect-kendra')
              totalDataSize = data.users.length
            else totalDataSize = data.totalUsers
            this.setState({ users: data.users, totalDataSize })
          }
        })
    } else {
      window.location.pathname = '/404'
    }
  }

  changeStatusFilter = value => {
    this.setState({ status: value }, () => {
      this.fetchUsers()
    })
  }

  openAddMember = () => {
    if (this.props.match && this.state.userType.customUrl) {
      window.location.pathname =
        '/user-types/' + this.state.userType.customUrl + '/add-user'
    } else if (this.props.orgId && this.props.type) {
      window.location.pathname =
        '/org-types/' + this.props.type + '/' + this.props.orgId + '/add-user'
    }
  }

  deleteMultipleUser = () => {
    let deletableUsers = this.state.deletableUsers
    if (deletableUsers && deletableUsers.length) {
      Swal.fire({
        title: 'Are you sure?',
        html: 'These Users will be deleted permanently',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, Delete',
        confirmButtonAriaLabel: 'Yes, Delete',
        cancelButtonText: 'No, Cancel',
        cancelButtonAriaLabel: 'No, Cancel',
      }).then(result => {
        if (result.value) {
          fetch(process.env.REACT_APP_API_URL + '/api/v1/user', {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': JSON.parse(readCookie('access_token')),
            },
            body: JSON.stringify(deletableUsers),
          })
            .then(data => data.json())
            .then(data => {
              if (data.status) {
                this.notifySuccess(data.messsage)
                window.location.reload()
              } else {
                this.notifyError(data.messsage)
              }
            })
            .catch(e => {
              this.notifyError('User(s) deletion failed!')
            })
        }
      })
    }
  }

  onRowSelect(row, isSelected) {
    let deletableUsers = this.state.deletableUsers,
      deleteButtonDisabled = true
    let id = row['id']
    if (isSelected) {
      deletableUsers.push(id)
      this.setState({
        selectedRowCount: this.state.selectedRowCount + 1,
        deletableUsers,
        deleteButtonDisabled: false,
      })
    } else {
      deletableUsers.splice(deletableUsers.indexOf(id), 1)
      if (this.state.selectedRowCount - 1) deleteButtonDisabled = false
      this.setState({
        selectedRowCount: this.state.selectedRowCount - 1,
        deletableUsers,
        deleteButtonDisabled,
      })
    }
  }

  onAllRowSelect(isSelected, row) {
    if (isSelected) {
      let users = this.state.users
      let deletableUsers = []
      for (var i = 0; i < users.length; i++) {
        deletableUsers.push(users[i]['id'])
      }
      let deleteButtonDisabled = true
      if (deletableUsers.length) deleteButtonDisabled = false
      this.setState({
        deletableUsers,
        deleteButtonDisabled,
        selectedRowCount: this.state.users.length,
      })
    } else {
      this.setState({
        selectedRowCount: 0,
        deletableUsers: [],
        deleteButtonDisabled: true,
      })
    }
  }

  formatDate(cell, row) {
    if (cell) return moment(cell).format('DD/MM/YYYY hh:mm A')
    else return null
  }

  formatName = (cell, row) => {
    if (cell) {
      if (row.userType.customUrl) {
        return (
          <a href={'/user-types/' + row.userType.customUrl + '/' + row._id}>
            {cell}
          </a>
        )
      } else if (this.props.type) {
        return (
          <a href={'/user-types/' + this.props.type + '/' + row._id}>{cell}</a>
        )
      } else if (this.props.match.params.type) {
        return (
          <a
            href={'/user-types/' + this.props.match.params.type + '/' + row._id}
          >
            {cell}
          </a>
        )
      } else return cell
    } else return null
  }

  sizePerPageChange = (sizePerPage, page) => {
    console.log(sizePerPage, page)
  }

  pageChange = (page, sizePerPage) => {
    console.log(sizePerPage, page)
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  render() {
    const columns = [
      {
        dataField: 'id',
        text: '',
        width: '0',
        sort: false,
        hidden: true,
        export: true,
        searchable: false,
      },
      {
        dataField: 'fullName',
        text: 'Full Name',
        sort: true,
        hidden: false,
        export: true,
        searchable: true,
        formatter: this.formatName,
      },
      {
        dataField: 'mobileNumber',
        text: 'Mobile',
        sort: true,
        hidden: false,
        export: true,
        searchable: true,
        headerStyle: () => {
          return { width: '150px' }
        },
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
        hidden: false,
        export: true,
        searchable: true,
      },
      {
        dataField: 'createdAt',
        text: 'Registered On',
        sort: true,
        hidden: false,
        formatter: this.formatDate.bind(this),
        export: true,
        searchable: true,
        headerStyle: () => {
          return { width: '150px' }
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        hidden: false,
        export: true,
        searchable: true,
        headerStyle: () => {
          return { width: '150px' }
        },
      },
    ]

    const pagination = paginationFactory({
      sizePerPage: this.state.sizePerPage,
    })

    if (this.state.userType !== null) {
      return (
        <div
          className={
            this.props.match
              ? 'user-type-page page-container'
              : 'user-type-page'
          }
        >
          {this.props.match ? (
            <Col
              style={{ padding: '0px', marginBottom: '20px' }}
              className="d-flex align-items-center"
            >
              <a
                className="go-back-btn d-flex align-items-center"
                href="/settings/user-types"
              >
                <i className="fas fa-chevron-left mr5"></i>Back
              </a>
              <h3 style={{ margin: '0px' }}>{this.state.userType.name}</h3>
            </Col>
          ) : null}
          <Col md={12} className="user-settings-table">
            <ToolkitProvider
              keyField="_id"
              data={this.state.users}
              columns={columns}
              search={{ searchFormatted: true }}
            >
              {props => (
                <div>
                  <Row className="mt10">
                    <Col className="d-flex" style={{ alignItems: 'center' }}>
                      <span className="total-members m0">
                        {'Total members: ' + this.state.totalDataSize}
                      </span>
                      {JSON.parse(readCookie('userData')).type === 'le' &&
                      JSON.parse(readCookie('userData')).userType.name ===
                        'Project Management Unit' ? (
                        <button
                          type="button"
                          className={
                            'btn btn-success react-bs-table-add-btn hidden-print'
                          }
                          style={{ marginLeft: '5px' }}
                          onClick={this.openAddMember}
                        >
                          <span>
                            <i
                              className="fa fa-user-plus"
                              aria-hidden="true"
                            ></i>
                            Add Member
                          </span>
                        </button>
                      ) : null}
                      {JSON.parse(readCookie('userData')).type === 'le' &&
                      JSON.parse(readCookie('userData')).userType.name ===
                        'Project Management Unit' ? (
                        <button
                          type="button"
                          className={
                            'btn btn-success react-bs-table-add-btn hidden-print'
                          }
                          style={{ marginLeft: '5px' }}
                          onClick={this.deleteMultipleUser}
                          disabled={this.state.deleteButtonDisabled}
                        >
                          <span>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            Remove Users
                          </span>
                        </button>
                      ) : null}
                    </Col>
                    <div
                      className="status-container d-flex align-items-center"
                      style={{ maxWidth: 130 }}
                    >
                      <Select
                        showSearch
                        style={{ width: 100 }}
                        placeholder="Select Status"
                        onChange={this.changeStatusFilter}
                        value={this.state.status}
                      >
                        <Option value="" disabled>
                          Select Status
                        </Option>
                        <Option value="Active">Active</Option>
                        <Option value="Inactive">Inactive</Option>
                      </Select>
                    </div>
                    <Col className="members-table-search-container">
                      <SearchBar
                        {...props.searchProps}
                        placeholder="Search by Name/Registration Date/Mobile/Email"
                        className="members-table-search"
                        delay={500}
                      />
                    </Col>
                  </Row>
                  <BootstrapTable
                    id="platform-users-table"
                    {...props.baseProps}
                    selectRow={this.state.selectRowProp}
                    ref={table => {
                      this.table = table
                    }}
                    pagination={pagination}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </div>
      )
    } else return null
  }
}
