import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './ManageStakeholders.css'
import { Table, Row, Col } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import axios from 'axios'
import {
  EditFilled,
  CloseCircleFilled,
  PlusCircleFilled,
  CaretLeftFilled,
  FileAddFilled,
} from '@ant-design/icons'

const readCookie = require('../../../cookie').readCookie
const AUTH_TOKEN = `Bearer ${JSON.parse(readCookie('access_token'))}`
// const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVkYjk2YTA5NjYyNjAzNTE1M2M4NjgzYSIsInVzZXJUeXBlIjp7Il9pZCI6IjVkOGRlMjExMTBiYTkxMDNkMjFkMWE4NSIsIm5hbWUiOiJQcm9qZWN0IE1hbmFnZW1lbnQgVW5pdCJ9LCJ1c2VyUm9sZSI6bnVsbCwibmdvIjpudWxsLCJhY2NvdW50VHlwZSI6IlN1cGVyIFVzZXIiLCJwZXJtaXNzaW9ucyI6W10sImFwcGxpY2F0aW9uIjoiU3dhdGkiLCJpYXQiOjE2MjU1NDM4ODd9.zHckkR2zwaE66s__MthKV0DuSHyJLhNBdXAoDX28OPQ';//
function ManageStakeholders() {
  const [modalShow, setModalShow] = useState(false)
  const [stakeHolderDetails, setstakeHolderDetails] = useState(false)

  const [stakeholders, setstakeholders] = useState([])
  async function fetchData() {
    const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder`
    const config = { headers: { Authorization: AUTH_TOKEN } }
    axios.get(url, config).then(response => {
      if (response.status === 200 && response.data.error === false) {
        //   console.log(response.data.stakeHolders);
        setstakeholders(response.data.stakeHolders)
      }
    })
  }
  useEffect(() => {
    fetchData()
  }, [])

  function MyVerticallyCenteredModal(props) {
    const { onHide } = props
    const [stakeholderName, setstakeholderName] = useState('')
    const [fields, setFields] = useState([]) // Input Fields

    function addNewDesignation() {
      const values = [...fields]
      values.push({ value: null })
      setFields(values)
    }

    function handleChange(i, event) {
      const values = [...fields]
      values[i].value = event.target.value
      setFields(values)
      // console.log(values);
    }

    function handleRemove(i) {
      const values = [...fields]
      values.splice(i, 1)
      setFields(values)
    }

    function removePredefinedDesignation(index, stakeholderId) {
      stakeHolderDetails.designations.splice(index, 1)

      const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/${stakeholderId}`
      const config = { headers: { Authorization: AUTH_TOKEN } }
      axios
        .put(url, stakeHolderDetails, config)
        .then(response => {
          if (response.status === 200 && response.data.error === false) {
            swal('Successfully Updated', '', 'success')
            fetchData()
          }
        })
        .catch(err => {
          swal(err.response.data.errorMessage, '', 'error')
        })
      onHide()
    }

    const updateDesignation = e => {
      e.preventDefault()

      const addedDesignation = []
      if (addedDesignation.length === 0) {
        for (let i = 0; i < fields.length; i += 1) {
          // console.log(fields[i].value);
          if (fields[i].value) addedDesignation.push(fields[i].value)
        }
      }
      if (stakeHolderDetails !== false) {
        stakeHolderDetails.designations =
          stakeHolderDetails.designations.concat(addedDesignation)

        const { _id } = stakeHolderDetails
        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/${_id}`
        const config = { headers: { Authorization: AUTH_TOKEN } }
        axios
          .put(url, stakeHolderDetails, config)
          .then(response => {
            if (response.status === 200 && response.data.error === false) {
              swal('Successfully Updated', '', 'success')
              fetchData()
            }
          })
          .catch(err => {
            swal(err.response.data.errorMessage, '', 'error')
          })
      } else if (stakeholderName.length > 0) {
        const newStakeholder = {
          type: stakeholderName,
          designations: addedDesignation,
        }
        stakeholders.push(newStakeholder)

        const url = `${process.env.REACT_APP_STAKEHOLDER_BASE_URL}stakeholder/add-stakeholder`
        const config = { headers: { Authorization: AUTH_TOKEN } }
        axios
          .post(url, newStakeholder, config)
          .then(response => {
            // console.log(response);
            if (response.status === 200 && response.data.error === false) {
              // console.log(response.data);
              swal('Successfully Added', '', 'success')
              fetchData()
            }
          })
          .catch(err => {
            swal(err.response.data.errorMessage, '', 'error')
          })
        setstakeholders(stakeholders)
      }
      onHide()
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {stakeHolderDetails ? 'EDIT STAKEHOLDER' : 'ADD NEW STAKEHOLDER'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="DesignationInputArea">
            <div style={{ width: '35%' }}>Stakeholder Name : </div>
            <form onSubmit={e => updateDesignation(e)} style={{ width: '65%' }}>
              {stakeHolderDetails ? (
                <div style={{ justifySelf: 'center' }}>
                  {stakeHolderDetails.type}
                </div>
              ) : (
                <input
                  name="stakeholderName"
                  type="text"
                  placeholder="Enter Stakeholder Name Here"
                  onChange={e => setstakeholderName(e.target.value)}
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 5,
                    borderColor: 'transparent',
                    borderBottomColor: 'grey',
                  }}
                />
              )}
            </form>
          </div>
          <br />
          <p style={{ fontWeight: 700 }}>DESIGNATIONS</p>
          {stakeHolderDetails &&
            stakeHolderDetails.designations.map((item, index) => (
              <div key={index} className="dynamicInputFields">
                <p>{item}</p>
                <CloseCircleFilled
                  style={{ color: 'tomato', fontSize: 30 }}
                  onClick={() => {
                    const { _id } = stakeHolderDetails
                    removePredefinedDesignation(index, _id)
                  }}
                />
              </div>
            ))}
          {fields.map((field, idx) => (
            <div key={`${field}-${idx}`} className="dynamicInputFields">
              <input
                type="text"
                placeholder="Enter Designation Here"
                onChange={e => handleChange(idx, e)}
                style={{
                  height: '100%',
                  width: '50%',
                  border: 'none',
                }}
              />
              <CloseCircleFilled
                style={{ color: 'tomato', fontSize: 30 }}
                onClick={() => handleRemove(idx)}
              />
              {/* <Button type="button"
                                onClick={() => handleRemove(idx)}> Cancel</Button> */}
            </div>
          ))}
          <Row className="justify-content-md-center">
            <Col md={12}>
              {/* </Col> */}
              <Button onClick={addNewDesignation}>
                {/* <Col md={9}> */}
                <PlusCircleFilled
                  onClick={addNewDesignation}
                  style={{ color: 'tomato', fontSize: 30 }}
                />
                Add New Designation
              </Button>{' '}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={addNewDesignation}>Add New Designation </Button> */}
          <Button
            disabled={stakeholderName.length === 0 && fields.length <= 0}
            // onClick={updateDesignation}
            onClick={e => {
              const displayText = stakeHolderDetails ? 'edit this' : 'add a new'
              swal(`Do you really want to ${displayText} stakeholder?`, {
                buttons: {
                  catch: {
                    text: 'Yes',
                    value: 'Yes',
                  },
                  No: true,
                },
              }).then(value => {
                switch (value) {
                  case 'Yes':
                    updateDesignation(e)
                    break

                  default:
                }
              })
            }}
          >
            {stakeHolderDetails ? 'Update Stakeholder' : 'Save Stakeholder'}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
  MyVerticallyCenteredModal.propTypes = {
    onHide: PropTypes.func,
    // stakeHolderDetails: PropTypes.array,
    // designations: PropTypes.array,
  }

  return (
    <div className="ManageStakeholderContainers">
      <div className="ManageStakeholderHeader">
        <div className="ManageStakeholderHeaderButton">
          <Link to="/stakeholders" className="link">
            <div className="HeaderText">
              <CaretLeftFilled />
              Go Back
            </div>
          </Link>
        </div>
        <div className="ManageStakeholderHeaderTitle">
          <div className="HeaderText">
            MANAGE STAKEHOLDERS AND DESIGNATIONS PAGE
          </div>
        </div>
      </div>
      {JSON.parse(readCookie('userData')).userType.name ===
        'Project Management Unit' && (
        <div className="AddStakeholderContainer">
          <div className="AddStakeholder">
            <div
              className="HeaderText"
              onClick={() => {
                setModalShow(true)
              }}
              // onClick={() => {
              //     swal('Do you really want to add a new stakeholder?', {
              //       buttons: {
              //         catch: {
              //           text: 'Yes',
              //           value: 'Yes',
              //         },
              //         No: true,
              //       },
              //     })
              //     .then((value) => {
              //       switch (value) {
              //         case 'Yes':
              //           setModalShow(true);
              //           break;

              //         default:
              //       }
              //     });
              // }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  width: 200,
                }}
              >
                <FileAddFilled />
                Add New Stakeholder
              </div>
            </div>
          </div>
        </div>
      )}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          setstakeHolderDetails(false)
        }}
        // details={stakeHolderDetails} // Showing warning.
        details={stakeHolderDetails.toString()}
      />
      <div className="ManageStakeholdersTable">
        <Table striped hover variant="light" size="lr">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Stakeholder Type</th>
              <th>Designations</th>
              {JSON.parse(readCookie('userData')).userType.name ===
                'Project Management Unit' && <th>Edit</th>}
            </tr>
          </thead>
          <tbody>
            {stakeholders.map((item, index) => (
              <tr key={index}>
                <td style={{ fontWeight: '600' }}>{index + 1}</td>
                <td style={{ fontWeight: '600' }}>{item.type}</td>
                <td style={{ fontWeight: '600' }}>
                  {item.designations.length}
                </td>
                {JSON.parse(readCookie('userData')).userType.name ===
                  'Project Management Unit' && (
                  <td style={{ fontWeight: '600' }}>
                    <div
                      className="EditStakeholder"
                      onClick={() => {
                        setModalShow(true)
                        setstakeHolderDetails(item)
                      }}
                      // onClick={() => {
                      //     swal('Do you really want to edit this stakeholder?', {
                      //       buttons: {
                      //         catch: {
                      //           text: 'Yes',
                      //           value: 'Yes',
                      //         },
                      //         No: true,
                      //       },
                      //     })
                      //     .then((value) => {
                      //       switch (value) {
                      //         case 'Yes':
                      //           setModalShow(true);
                      //           setstakeHolderDetails(item);
                      //           break;
                      //         default:
                      //       }
                      //     });
                      // }}
                    >
                      <EditFilled />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ManageStakeholders
