/** @format */

import React from 'react'
import { Select } from 'antd'
import {
  Col,
  Row,
  // Button, Modal
} from 'react-bootstrap'
const { OptGroup, Option } = Select
const BusinessInformationDDFormComponent = ({
  leadData,
  projectData,
  globalDDFormState,
  dueDiligenceChange,
  isEditable,
  showHoldPrompt,
  setGlobalDDFormState,
}) => {
  return (
    <>
      <h3>BUSINESS INFORMATION</h3>

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Confirm that the business shall be started/scaled-up in{' '}
          {leadData.basic.address.city
            ? leadData.basic.address.city
            : leadData.basic.address.district}{' '}
          (as in profile).
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.businessInfo.businessLocationUnchanged}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,

              // "questions",
              'businessInfo',
              'businessLocationUnchanged'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {['Yes (confirmed)', 'No'].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Do you know exact location where you want to start your business?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.businessInfo.isExactBusinessLocationKnown}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'businessInfo',
              'isExactBusinessLocationKnown'
              // "questions",
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {[
            'Yes',
            'No but I will find within a week',
            'No I will take more time',
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>
      {globalDDFormState.businessInfo.isExactBusinessLocationKnown ===
      'No I will take more time' ? (
        <Col md={12}>
          <button
            className="btn btn-default put-on-hold-btn"
            onClick={e => showHoldPrompt('dueDiligence')}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Have you identified your target customers?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.businessInfo.areTargetCustomersIdentified}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'businessInfo',
              // "questions",
              'areTargetCustomersIdentified'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {[
            'Yes',
            'No but I will find within a week',
            'No I will take more time',
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>
      {globalDDFormState.businessInfo.areTargetCustomersIdentified ===
      'No I will take more time' ? (
        <Col md={12}>
          <button
            className="btn btn-default put-on-hold-btn"
            onClick={e => showHoldPrompt('dueDiligence')}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Can you estimate your monthly sales and costs?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.businessInfo.estimateMonthlySalesAndCosts}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'businessInfo',
              // "questions",
              'estimateMonthlySalesAndCosts'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {[
            'Yes',
            'No but I will find within a week',
            'No I will take more time',
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>
      {globalDDFormState.businessInfo.estimateMonthlySalesAndCosts ===
      'No I will take more time' ? (
        <Col md={12}>
          <button
            className="btn btn-default put-on-hold-btn"
            onClick={e => showHoldPrompt('dueDiligence')}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          How many similar businesses are nearby who are your competition?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.businessInfo.nearbyCompetitiveBusinesses}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'businessInfo',
              // "questions",
              'nearbyCompetitiveBusinesses'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {[
            'No/little competition',
            'Many similar businesses around',
            'I do not know but will find out in a week',
            'I need more time to find out',
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>
      {globalDDFormState.businessInfo.nearbyCompetitiveBusinesses ===
      'I need more time to find out' ? (
        <Col md={12}>
          <button
            className="btn btn-default put-on-hold-btn"
            onClick={e => showHoldPrompt('dueDiligence')}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}

      <Col md={12} className="due-diligence-question same-line">
        <label className="control-label">
          Do you know from where you will buy the goods/machines/raw materials?
        </label>
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder="Select"
          value={globalDDFormState.businessInfo.goodsPurchaseLocation}
          disabled={!isEditable}
          onChange={e =>
            dueDiligenceChange(
              e,
              'businessInfo',
              // "questions",
              'goodsPurchaseLocation'
            )
          }
        >
          <Option value="" disabled>
            Select Option
          </Option>
          {[
            'Yes',
            'No but I will find within a week',
            'No I will take more time',
          ].map((option, index) => {
            return (
              <Option key={index} value={option}>
                {option}
              </Option>
            )
          })}
        </Select>
      </Col>
      {globalDDFormState.businessInfo.goodsPurchaseLocation ===
      'No I will take more time' ? (
        <Col md={12}>
          <button
            className="btn btn-default put-on-hold-btn"
            onClick={e => showHoldPrompt('dueDiligence')}
            disabled={!isEditable}
          >
            THE CANDIDATE CAN BE PUT ON HOLD
          </button>
        </Col>
      ) : null}
    </>
  )
}

export default BusinessInformationDDFormComponent
