import Commenthandler from './commenthandler'
import React from 'react'

const DUMMY_Comment = [
  /*  {
          id : "p1",
          type : "primary",
          file : "abc",
          followup : "yes",
          description : "yes"
      }*/
]

const Commentlist = () => {
  const loaderComment = DUMMY_Comment

  return <Commenthandler items={loaderComment} />
}

export default Commentlist
