import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const initialState = {
  status: '',
  mobileUser: [],
  loading: false,
  error: '',
}

export const searchMobileUser = createAsyncThunk(
  'searchMobileUser',
  async mobileNumber => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_APP_USER_URL}/user/getUserFromMobile`,
        {
          params: {
            mobileNumber,
          },
          headers: {
            authorization: `Bearer ${cookies.get('access_token')}`,
            source: 'humblebee_admin',
          },
        }
      )
      console.log('res.data from Users ==> ', res.data)
      return res.data.user
    } catch (error) {
      return error.response.data
    }
  }
)

const searchMobileUserSlice = createSlice({
  name: 'searchMobileUser',
  initialState,
  reducers: {},

  extraReducers: {
    [searchMobileUser.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [searchMobileUser.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        console.log('payload in searchMobileUser fullfilled ', payload)
        state.mobileUser = payload
      }
    },

    [searchMobileUser.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
  },
})

export default searchMobileUserSlice.reducer
