import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'
const currentToken = localStorage.getItem('humblebeeAuthToken')
const initialState = {
  status: '',
  projectData: [],
  loading: false,
  error: '',
}

export const getSingleProject = createAsyncThunk(
  'getSingleProject',
  async _id => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/${_id}`,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
          },
        }
      )
      console.log('singlee', res.data.project)
      return res.data.project
    } catch (error) {
      return error.response.data
    }
  }
)

export const resetSingleProjectData = createAction('resetSingleProjectData')

const getSingleProjectDetailsSlice = createSlice({
  name: 'getSingleProject',
  initialState,
  reducers: {},

  extraReducers: {
    [getSingleProject.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getSingleProject.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.projectData = payload
      }
    },

    [getSingleProject.rejected]: (state, { message }) => {
      state.error = message
      state.loading = false
    },
    [resetSingleProjectData]: state => {
      state.projectData = []
    },
  },
})

export default getSingleProjectDetailsSlice.reducer
