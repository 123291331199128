import React, { useState, useRef, useMemo, useEffect } from 'react'
import * as Styles from './style'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import close from '../../../assets/images/close.png'
import { InputGroup } from '../../../shared/InputGroup/InputGroup'

import {
  makeSupplier,
  resetErrorinMakeSupplier,
  resetMessageinMakeSupplier,
} from '../../../reduxLogic/reducers/suppliers/makeSupplierSlice'
import { getAllSuppliers } from '../../../reduxLogic/reducers/suppliers/getAllSupplierSlice'
import { BlockData } from '../../../shared/Constants/blocks'
import { SelectGroup } from '../../../shared/SelectGroup/SelectGroup'
import { renderPreviewForCurrent } from '../../../shared/RenderPreview/RenderPreview'
import Swal from 'sweetalert2'

const initialValues = {
  supplierId: '',
  supplierName: '',
  supplierPrimaryMobile: '',
  supplierSecondaryMobile: '',
  supplierEmail: '',
  contactPerson: '',
  supplierAddress: {
    supplierAddressLine: '',
    supplierDistrict: '',
    supplierCity: '',
    supplierState: '',
    supplierPincode: '',
  },
  notes: '',
  GSTIN: '',
  documents: [],
  isActive: true,
  currentToken: '',
}

const suppliersSchema = Yup.object().shape({
  supplierName: Yup.string().required('Supplier Name is required'),
  supplierPrimaryMobile: Yup.string()
    .required('Farmer Mobile is required')
    .matches(/^(91\+)?\d{10}$/, 'Secondary Mobile number is not valid'),
  supplierSecondaryMobile: Yup.string()
    .nullable()
    .matches(/^(91\+)?\d{10}$/, 'Secondary Mobile number is not valid'),

  supplierEmail: Yup.string()
    .email('Invalid email')
    .required('Supplier Email is Required'),
  contactPerson: Yup.string().required('Contact Person is required'),
  supplierAddress: Yup.object().shape({
    supplierAddressLine: Yup.string().required('Address line is required'),
    supplierDistrict: Yup.string().required('District is required'),
    supplierCity: Yup.string().required('City is required'),
    supplierState: Yup.string().required('State is required'),
    supplierPincode: Yup.string()
      .required('Pincode is required')
      .matches(/^[0-9]{6}$/, 'Invalid pincode'),
  }),
  GSTIN: Yup.string()
    .nullable()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      'GSTIN is not Valid'
    ),
})

const SupplierForm = props => {
  const { openModal, setOpenModal } = props
  const [customNotes, setCustomNotes] = useState('')
  const [allDocuments, setAllDocuments] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [states, setStates] = useState([])
  const [districts, setDistricts] = useState([])
  const [cities, setCities] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [selectedDistrict, setSelectedDistrict] = useState('')
  const [selectedCity, setSelectedCity] = useState('')

  const { suppliersData } = useSelector(state => state.allSuppliers)
  const { error, message } = useSelector(state => state.makeSupplier)
  console.log('this is error from  SupplierForm ==> ', error)

  const handleDocumentsChange = (e, index) => {
    const { files } = e.target
    setAllDocuments(prevDocuments => [...prevDocuments, ...files])
  }

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenModal(prev => !prev)
  }
  const handleNotesChange = value => {
    setCustomNotes(value)
  }

  useEffect(() => {
    // Extract unique states from the JSON data
    const uniqueStates = [...new Set(BlockData.map(item => item.state))]
    setStates(uniqueStates)
  }, [])

  const handleStateChange = tempSelectedState => {
    setSelectedState(tempSelectedState)
    // Extract unique districts based on the selected state
    const uniqueDistricts = [
      ...new Set(
        BlockData.filter(item => item.state === tempSelectedState).map(
          item => item.district
        )
      ),
    ]
    setDistricts(uniqueDistricts)
  }

  const handleDistrictChange = tempSelectedDistrict => {
    setSelectedDistrict(tempSelectedDistrict)
    // Extract unique Cities based on the selected district
    const uniqueCities = [
      ...new Set(
        BlockData.filter(item => item.district === tempSelectedDistrict).map(
          item => item.city
        )
      ),
    ]
    setCities(uniqueCities)
  }

  const handleCityChange = tempSelectedCity => {
    setSelectedCity(tempSelectedCity)
  }

  useEffect(() => {
    console.log('these are cities => ', cities)
  }, [cities])

  const handleClearSelection = e => {
    e.preventDefault()
    const fileInput = document.querySelector('input[name="documents"]')
    if (fileInput) {
      fileInput.value = null
    }
    setAllDocuments([])
  }

  const handleSubmit = values => {
    console.log('these are supplier values in handleSubmit => ', values)
    setIsSubmitting(true)

    let formData = new FormData()
    const token = localStorage.getItem('humblebeeAuthToken')

    function getRandomNumber(suppliersData) {
      const min = 1000
      const max = 9999

      let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min

      // Check if the generated number already exists as a supplierId
      while (
        suppliersData.some(supplier => supplier.supplierId === randomNumber)
      ) {
        randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
      }

      return randomNumber
    }
    const newSupplierId = getRandomNumber(suppliersData)
    console.log('newSuplierId ', newSupplierId)

    formData.append('supplierId', newSupplierId)

    formData.append('supplierName', values.supplierName)
    formData.append('supplierPrimaryMobile', values.supplierPrimaryMobile)
    formData.append('supplierSecondaryMobile', values.supplierSecondaryMobile)
    formData.append(
      'supplierAddress.supplierCity',
      values?.supplierAddress?.supplierCity
    )
    formData.append(
      'supplierAddress.supplierState',
      values?.supplierAddress?.supplierState
    )
    formData.append(
      'supplierAddress.supplierDistrict',
      values?.supplierAddress?.supplierDistrict
    )
    formData.append(
      'supplierAddress.supplierPincode',
      values?.supplierAddress?.supplierPincode
    )
    formData.append(
      'supplierAddress.supplierAddressLine',
      values?.supplierAddress?.supplierAddressLine
    )

    formData.append('supplierEmail', values.supplierEmail)
    formData.append('contactPerson', values.contactPerson)
    formData.append('GSTIN', values.GSTIN)
    formData.append('notes', customNotes)

    // Add each file in the documents array to formData
    for (let i = 0; i < allDocuments.length; i++) {
      formData.append(`documents[${i}]`, allDocuments[i])
      // console.log("alldocuments[i] => ", alldocuments[i]);
      // console.log("typeOf ", typeof alldocuments[i]);
    }

    formData.append('isActive', values.isActive)
    formData.append('currentToken', token)

    console.log('this is just above final dispatch formData', formData)
    dispatch(makeSupplier(formData))
      .then(() => dispatch(getAllSuppliers()))
      .then(() => setOpenModal(false))

    // End of handleSubmit
  }

  useEffect(() => {
    if (error) {
      toast.error(`${error}`)
      dispatch(resetErrorinMakeSupplier())
    }

    if (!error && message) {
      toast.success('Supplier was added succesfully')
      dispatch(resetMessageinMakeSupplier())
    }
  }, [error, dispatch, message])

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={suppliersSchema}
          validateOnMount
        >
          {formik => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Supplier Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainSupplierFormDiv>
                <InputGroup
                  PlaceholderText="Enter Supplier Name"
                  LabelText="Supplier Name"
                  name="supplierName"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />
                <Styles.RowFlex>
                  <InputGroup
                    PlaceholderText="Enter Primary Mobile Number"
                    LabelText="Primary Mobile Number"
                    name="supplierPrimaryMobile"
                    type="number"
                    star="*"
                    onChange={formik.handleChange}
                  />

                  <InputGroup
                    PlaceholderText="Enter Secondary Mobile Number"
                    LabelText="Secondary Mobile Number"
                    name="supplierSecondaryMobile"
                    type="number"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <InputGroup
                  PlaceholderText="Enter Email"
                  LabelText="Email"
                  name="supplierEmail"
                  type="email"
                  star="*"
                  onChange={formik.handleChange}
                />

                <InputGroup
                  PlaceholderText="Enter Contact Person"
                  LabelText="Contact Person"
                  name="contactPerson"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />

                <InputGroup
                  PlaceholderText="Enter Address"
                  LabelText="Address"
                  name="supplierAddress.supplierAddressLine"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />

                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Select State"
                    LabelText="State"
                    name="supplierAddress.supplierState"
                    onChange={e => {
                      handleStateChange(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={selectedState} // Add this line
                    options={states}
                    star="*"
                  />

                  <SelectGroup
                    PlaceholderText="Select District"
                    LabelText="District"
                    name="supplierAddress.supplierDistrict"
                    onChange={e => {
                      handleDistrictChange(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={selectedDistrict} // Add this line
                    options={districts}
                    star="*"
                  />
                </Styles.RowFlex>

                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Select City"
                    LabelText="City"
                    name="supplierAddress.supplierCity"
                    options={cities}
                    onChange={e => {
                      handleCityChange(e.target.value)
                      formik.handleChange(e)
                    }}
                    value={selectedCity} // Add this line
                    star="*"
                  />

                  <InputGroup
                    PlaceholderText="Enter Pincode"
                    LabelText="Pincode"
                    name="supplierAddress.supplierPincode"
                    type="number"
                    star="*"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <Styles.MarginTopBottomBox>
                  <Styles.Labels>Notes</Styles.Labels>
                  <ReactQuill
                    style={{ marginTop: '10px' }}
                    onChange={value => handleNotesChange(value)}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link', 'image'],
                        ['clean'],
                      ],
                    }}
                  />
                </Styles.MarginTopBottomBox>

                <InputGroup
                  PlaceholderText="Enter GSTIN"
                  LabelText="GSTIN"
                  name="GSTIN"
                  type="text"
                  onChange={formik.handleChange}
                />

                <div style={{ marginTop: '10px' }}>
                  <Styles.Labels id="isSupplierActiveGroup">
                    Is Supplier Active
                    <span style={{ color: 'red', marginLeft: '2px' }}>*</span>
                  </Styles.Labels>
                  <Styles.RadioButtonGroup
                    role="isActive"
                    aria-labelledby="isSupplierActiveGroup"
                  >
                    <label>
                      <Field
                        type="radio"
                        name="isActive"
                        value={true}
                        checked={formik?.values?.isActive == String(true)}
                      />
                      <span style={{ marginLeft: '8px' }}>Yes</span>
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="isActive"
                        value={false}
                        checked={formik?.values?.isActive == String(false)}
                      />
                      <span style={{ marginLeft: '8px' }}>No</span>
                    </label>
                  </Styles.RadioButtonGroup>
                </div>

                <Styles.ColumnFlex>
                  <Styles.Labels>Documents</Styles.Labels>
                  <Styles.FileInputClearButtonDiv>
                    <button onClick={e => handleClearSelection(e)}>
                      <img src={close} alt="clear" />
                    </button>
                    <Styles.FileInput
                      type="file"
                      multiple
                      onChange={(e, index) => handleDocumentsChange(e, index)}
                      name="documents"
                      accept=".pdf, image/*"
                    />
                  </Styles.FileInputClearButtonDiv>

                  <Styles.IMGTextBox>
                    <p>Current</p>
                    <Styles.IMGBox>
                      {allDocuments?.length > 0
                        ? allDocuments?.map((item, index) => (
                            // <div key={index}>
                            //   {renderPreviewForCurrent(item, index)}
                            // </div>
                            <div
                              key={index}
                              className="image"
                              style={{
                                height: '100px',
                                width: '120px',
                                display: 'flex',
                              }}
                            >
                              <div>{renderPreviewForCurrent(item, index)}</div>
                              <button
                                className="delete-button"
                                type="button"
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Delete Confirmation',
                                    text: 'Are you sure you want to delete this item?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: 'rgb(255, 135, 1)',
                                    cancelButtonColor: 'lightgrey',
                                    confirmButtonText: 'Delete',
                                  }).then(result => {
                                    if (result.isConfirmed) {
                                      let filterImage = allDocuments.filter(
                                        (item, i) => i !== index
                                      )
                                      setAllDocuments(filterImage)
                                      // let filterImage = allMedia.filter((item,i) => i !== index );
                                      //                               setAllMedia(filterImage)
                                      Swal.fire(
                                        'Deleted!',
                                        'The item has been deleted.',
                                        'success'
                                      )
                                    }
                                  })
                                }}
                              >
                                <img
                                  src={close}
                                  alt="clear"
                                  style={{ height: '10px' }}
                                />
                              </button>
                            </div>
                          ))
                        : null}
                    </Styles.IMGBox>
                  </Styles.IMGTextBox>
                </Styles.ColumnFlex>
              </Styles.MainSupplierFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateSupplierButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Styles.CreateSupplierButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  )
}

export default SupplierForm
