import React from 'react'
import { Col } from 'react-bootstrap'

const createCookie = require('../cookie.js').createCookie

export default class PartnerVerification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      loader: true,
    }
  }

  componentDidMount() {
    let token = this.props.location.search.split('?token=')[1]
    fetch(process.env.REACT_APP_API_URL + '/check-partner-verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token }),
    })
      .then(data => data.json())
      .then(data => {
        this.setState({ loader: false }, () => {
          if (data.status === 'error') {
            this.setState({ message: data.message })
          } else if (data.status === 'ok') {
            createCookie(
              'userData',
              JSON.stringify({ ...data['data'], newKey: new Date() }),
              30
            )
            createCookie(
              'access_token',
              JSON.stringify(data['access_token']),
              30
            )
            window.location.href = '/dashboard'
          }
        })
      })
      .catch(error => console.log(error))
  }

  render() {
    return (
      <Col md={12} className="partner-verification">
        <Col md={12} className="partner-verification-container">
          {this.state.loader ? (
            <Col md={12} className="loader-container">
              <div className="loader-msg">Please wait while we redirect...</div>
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </Col>
          ) : null}
          {this.state.message ? (
            <div className="verify-message">{this.state.message}</div>
          ) : null}
        </Col>
      </Col>
    )
  }
}
