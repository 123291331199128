/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  Radio,
  Select,
  // Switch, Tooltip
} from 'antd'
import {
  // Col, Row,
  Button,
} from 'react-bootstrap'
import { readCookie } from '../cookie'
import moment from 'moment'
import Swal from 'sweetalert2'
// import { update } from "lodash";

const { Option } = Select

const AuditChecklists = ({ userData, leadData, refreshLeadData }) => {
  //   console.log("USERDATA", userData);
  //   console.log(leadData);
  const [statusChecked, setStatusChecked] = useState({
    candidateKnowsAboutMission: null,
    candidateHasStartedUpWithInvestment: null,
    startUpDate: null,
    startUpSector: null,
    enterpriseName: null,
    candidateId: null,
    activeBank: null,
    loanScheme: null,
    supportOfMissionInSecuringLoan: null,
    uploadedPhotosWithBanner: null,
    addedToGoogleMyBuisiness: null,
    storyAudited: null,
    registerdWithMSME: null,
    planPreparedByTeamSwavalamban: null,
    activelyInTouchWithSCK: null,
    sckSupportingCandidate: null,
    otherDataPoints: {
      candidateWillingToUndergoMentorshipProgram: null,
      candidateHasSmartphone: null,
      candidateUseBasicAppsOnSmartphone: null,
      candidateWillingToDigitalize: null,
      candiadteKeenToGoThroughVideoLessons: null,
      areasCandidateContinuesToNeedHelp: {
        businessRegistration: null,
        licensesAndCertification: null,
        businessPlanningAndGrowthStrategy: null,
        TrainingAndUpskilling: null,
        businessHealthManagement: null,
        supplyChain: null,
        scaleUpCredit: null,
        digitalFinancialLiteracy: null,
        brandingSupport: null,
        demandGeneration: null,
        legalAndFinancialAssistance: null,
        generalMentorship: null,
        others: '',
      },
    },
  })
  var subdomain = ''
  if (
    window.location.hostname.split('.').length === 3 &&
    window.location.hostname.split('.')[0] !== 'www'
  ) {
    subdomain = window.location.hostname.split('.')[0]
  }
  const [initialResponse, setInitialResponse] = useState(null)
  const [auditId, setAuditId] = useState(null)
  const [auditStatus, setAuditStatus] = useState(null)
  const [lastAuditUpdate, setLastAuditUpdate] = useState(null)
  const [lastUpdatedBy, setLastUpdatedBy] = useState(null)
  // const [secAnswered, setSecAnswered] = useState(true);
  const [loanScheme, setLoanScheme] = useState({})

  const disableForm = userData.userType.name === 'Swavalamban Connect Kendra'
  // const x =
  //   statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
  //     .businessRegistration;

  //   const checkSecQuestions = (otherDataPoints)=>{
  //     let answered = true;
  //     console.log('here')
  //     let prom=new Promise((rej,res)=>{
  //       Object.keys(otherDataPoints).forEach((field,index,array)=>{
  //         if(field==='areasCandidateContinuesToNeedHelp'){
  //           Object.keys(otherDataPoints[field]).forEach(anotherKey=>{
  //             if(anotherKey!=='others'){
  //               answered=answered&&otherDataPoints[field][anotherKey]!==null;
  // if(!answered)return setSecAnswered(answered);            }
  //           })
  //         }
  //         else{
  //           answered=answered&&otherDataPoints[field]!==null;
  //           if(!answered)return setSecAnswered(answered);
  //         }
  //       if(array.length-1===index)res();
  //       })
  //     })
  //     prom.then(()=>{
  //       setSecAnswered(answered);
  //     })

  //   }
  const getAudit = async () => {
    if (
      leadData?.enterpriseStatus?.loanAmountRequired &&
      leadData?.enterpriseStatus?.loanAmountRequired !== 0
    ) {
      leadData.leadMaturity.capitalFulfilled.loanApplicationDetails.forEach(
        application => {
          if (application.status === 'Disbursed') {
            setLoanScheme({
              schemeName: application.scheme,
              disbursedOn: application.dateOfDisbursement,
            })
          }
        }
      )
    }
    fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/audit-checklist/get-audit/${leadData.leadId}`,
      //  +
      // leadData.leadId,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        //   body: JSON.stringify({
        //     body: statusChecked,
        //     leadId: leadData?._id,
        //   }),
      }
    )
      .then(res => res.json())
      .then(data => {
        setInitialResponse(data.error)
        if (data.auditStatus) {
          setAuditStatus(data.auditStatus)
        }
        if (data.audit !== undefined) {
          // checkSecQuestions(data.audit.otherDataPoints);

          setLastUpdatedBy(data.audit.auditedBy)
          setLastAuditUpdate(data.audit.updatedAt || data.audit.createdAt)
          setStatusChecked({
            ...statusChecked,
            candidateKnowsAboutMission: data.audit.candidateKnowsAboutMission,
            candidateHasStartedUpWithInvestment:
              data.audit.candidateHasStartedUpWithInvestment,
            startUpDate: data.audit.startUpDate,
            startUpSector: data.audit.startUpSector,
            enterpriseName: data.audit.enterpriseName,
            candidateId: data.audit.candidateId,
            activeBank: data.audit.activeBank,
            loanScheme: data.audit.loanScheme,
            supportOfMissionInSecuringLoan:
              data.audit.supportOfMissionInSecuringLoan,
            uploadedPhotosWithBanner: data.audit.uploadedPhotosWithBanner,
            addedToGoogleMyBuisiness: data.audit.addedToGoogleMyBuisiness,
            storyAudited: data.audit.storyAudited,
            registerdWithMSME: data.audit.registerdWithMSME,
            planPreparedByTeamSwavalamban:
              data.audit.planPreparedByTeamSwavalamban,
            activelyInTouchWithSCK: data.audit.activelyInTouchWithSCK,
            sckSupportingCandidate: data.audit.sckSupportingCandidate,

            // ...statusChecked,
            otherDataPoints: {
              ...statusChecked.otherDataPoints,
              //   ...statusChecked.otherDataPoints,

              areasCandidateContinuesToNeedHelp: {
                ...statusChecked.otherDataPoints
                  .areasCandidateContinuesToNeedHelp,
                TrainingAndUpskilling:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .TrainingAndUpskilling,
                businessRegistration:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .businessRegistration,
                licensesAndCertification:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .licensesAndCertification,
                businessPlanningAndGrowthStrategy:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .businessPlanningAndGrowthStrategy,
                businessHealthManagement:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .businessHealthManagement,
                supplyChain:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .supplyChain,
                scaleUpCredit:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .scaleUpCredit,
                digitalFinancialLiteracy:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .digitalFinancialLiteracy,
                brandingSupport:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .brandingSupport,
                demandGeneration:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .demandGeneration,
                legalAndFinancialAssistance:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .legalAndFinancialAssistance,
                generalMentorship:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .generalMentorship,
                others:
                  data.audit.otherDataPoints.areasCandidateContinuesToNeedHelp
                    .others,
              },
              candidateWillingToDigitalize:
                data.audit.otherDataPoints.candidateWillingToDigitalize,
              candidateWillingToUndergoMentorshipProgram:
                data.audit.otherDataPoints
                  .candidateWillingToUndergoMentorshipProgram,
              candidateHasSmartphone:
                data.audit.otherDataPoints.candidateHasSmartphone,
              candidateUseBasicAppsOnSmartphone:
                data.audit.otherDataPoints.candidateUseBasicAppsOnSmartphone,
              candiadteKeenToGoThroughVideoLessons:
                data.audit.otherDataPoints.candiadteKeenToGoThroughVideoLessons,
            },
            // otherDataPoints: {
            //   ...statusChecked.otherDataPoints,
            //   candidateWillingToDigitalize:
            //     data.audit.otherDataPoints.candidateWillingToDigitalize,
            //   candidateWillingToUndergoMentorshipProgram:
            //     data.audit.otherDataPoints
            //       .candidateWillingToUndergoMentorshipProgram,
            //   candidateHasSmartphone:
            //     data.audit.otherDataPoints.candidateHasSmartphone,
            //   candidateUseBasicAppsOnSmartphone:
            //     data.audit.otherDataPoints.candidateUseBasicAppsOnSmartphone,
            //   candiadteKeenToGoThroughVideoLessons:
            //     data.audit.otherDataPoints.candiadteKeenToGoThroughVideoLessons,
            // },
          })
          // {
          if (data.error === false) {
            setAuditId(data.audit._id)
          }
          // }
        }
      })
  }

  useEffect(() => {
    getAudit()
  }, [])

  const enterpriseSetupChange = (type, e) => {
    let value = e
    if (e.hasOwnProperty('target')) value = e.target.value
    fetch(
      process.env.REACT_APP_API_URL +
        '/api/v1/lead-maturity/' +
        leadData.leadId +
        '/enterpriseSetup',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify({ [type]: value }),
      }
    )
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          refreshLeadData()
        } else {
          this.notifyError(data.errorMsg)
        }
      })
  }

  const addAudit = async () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/audit-checklist/new', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
      body: JSON.stringify({
        body: statusChecked,
        loanRequired: leadData?.enterpriseStatus?.loanAmountRequired !== 0,
        auditedBy: {
          auditedByName: userData.fullName,
          auditedById: userData._id,
        },
        leadId: leadData?._id,
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (!data.error) {
          Swal.fire(
            'Audit details saved!',
            'Details have been updated!',
            'success'
          ).then(() => {
            window.location.reload()
          })
        }
        // console.log(data);
      })
  }

  const updateAudit = async () => {
    fetch(
      process.env.REACT_APP_API_URL +
        `/api/v1/audit-checklist/update/${auditId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        },
        body: JSON.stringify({
          body: statusChecked,
          loanRequired: leadData?.enterpriseStatus?.loanAmountRequired !== 0,
          auditedBy: {
            auditedByName: userData.fullName,
            auditedById: userData._id,
          },
          leadId: leadData?._id,
        }),
      }
    )
      .then(res => res.json())
      .then(data => {
        if (!data.error) {
          Swal.fire(
            'Audit details updated!',
            'Details have been updated!',
            'success'
            // title: "Audit details updated!",
            // icon: "success",
            // //   timer: 1000,
            // showConfirmButton: true,
          ).then(() => {
            window.location.reload()
          })
        }
        // console.log(data);
      })
  }

  //   //   MISSION SUPPORT
  //   enterpriseSetupChange = (type, e) => {
  //     let value = e,
  //       stages = this.state.stages;
  //     if (e.hasOwnProperty("target")) value = e.target.value;
  //     if (
  //       type === "officeShopFactoryAddressUrlLat" ||
  //       type === "officeShopFactoryAddressUrlLong"
  //     ) {
  //       if (type === "officeShopFactoryAddressUrlLat")
  //         stages["enterpriseSetup"]["officeShopFactoryAddressUrl"][
  //           "latitude"
  //         ] = value;
  //       else if (type === "officeShopFactoryAddressUrlLong")
  //         stages["enterpriseSetup"]["officeShopFactoryAddressUrl"][
  //           "longitude"
  //         ] = value;
  //       stages["enterpriseSetup"]["officeShopFactoryAddressUrl"][
  //         "url"
  //       ] = `https://maps.google.com/maps?q=${stages.enterpriseSetup.officeShopFactoryAddressUrl.latitude},${stages.enterpriseSetup.officeShopFactoryAddressUrl.longitude}`;
  //     } else if (
  //       type === "kycPAN" ||
  //       type === "kycUdyogAadhar" ||
  //       type === "kycAadhar"
  //     ) {
  //       if (type === "kycPAN") stages.enterpriseSetup.kycDocuments.pan = value;
  //       else if (type === "kycAadhar")
  //         stages.enterpriseSetup.kycDocuments.aadhar = value;
  //       else if (type === "kycUdyogAadhar")
  //         stages.enterpriseSetup.kycDocuments.udyogAadhar = value;
  //     } else stages["enterpriseSetup"][type] = value;
  //     if (
  //       type !== "entityName" &&
  //       type !== "officeShopFactoryAddress" &&
  //       type !== "officeShopFactoryAddressUrlLat" &&
  //       type !== "officeShopFactoryAddressUrlLong" &&
  //       type !== "entrepreneurStory" &&
  //       type !== "bankAccountNum" &&
  //       type !== "bankIFSC" &&
  //       type !== "kycPAN" &&
  //       type !== "kycUdyogAadhar" &&
  //       type !== "kycAadhar"
  //     ) {
  //       fetch(
  //         process.env.REACT_APP_API_URL +
  //           "/api/v1/lead-maturity/" +
  //           this.props.leadId +
  //           "/enterpriseSetup",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-auth-token": JSON.parse(readCookie("access_token")),
  //           },
  //           body: JSON.stringify({ [type]: value }),
  //         }
  //       )
  //         .then((data) => data.json())
  //         .then((data) => {
  //           if (data.status) {
  //             this.setState({ stages });
  //           } else {
  //             this.notifyError(data.errorMsg);
  //           }
  //         });
  //     } else {
  //       this.setState({ stages });
  //     }
  //   };

  return (
    <div className="container mt-4">
      <div className="float-left">
        {auditStatus ? (
          <p
            style={{
              fontWeight: '900',
              color: `${auditStatus === 'Audited' ? 'Green' : 'Red'}`,
            }}
          >
            Current Status : {auditStatus}
          </p>
        ) : null}
      </div>
      <div className="float-right">
        {lastUpdatedBy?.name || lastAuditUpdate ? (
          auditStatus === 'Audit Pending' || auditStatus === 'Audit Failed' ? (
            <p style={{ fontWeight: '900', color: 'dodgerblue' }}>
              Last Updated By:{' '}
              <span style={{ color: 'orangered' }}> {lastUpdatedBy?.name}</span>{' '}
              on {moment(lastAuditUpdate).format('DD/MM/YYYY hh:mm A')}
            </p>
          ) : auditStatus === 'Audited' ? (
            <p style={{ fontWeight: '900', color: 'green' }}>
              Audited By{' '}
              <span style={{ color: 'orangered' }}>
                {' '}
                {lastUpdatedBy?.name}{' '}
              </span>
              on {moment(lastAuditUpdate).format('DD/MM/YYYY hh:mm A')}
            </p>
          ) : (
            <></>
          )
        ) : null}
      </div>
      <div
        style={
          window.screen.width < 500
            ? { width: '100%', overflow: 'scroll' }
            : null
        }
      >
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                <h4 className="font-weight-bold"> Status</h4>
              </th>
              <th scope="col">
                <h4 className="font-weight-bold">Criteria</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <td colspan="2">
              <h4 className="font-weight-bold">LEGITIMACY</h4>
            </td>
            <tr>
              <th scope="row">
                {' '}
                {/* <Switch
                checked={statusChecked.candidateKnowsAboutMission}
                onChange={(e) =>
                  setStatusChecked({
                    ...statusChecked,
                    candidateKnowsAboutMission: e,
                  })
                }
              /> */}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      candidateKnowsAboutMission: e.target.value,
                    })
                  }
                  checked={statusChecked.candidateKnowsAboutMission}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.candidateKnowsAboutMission === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.candidateKnowsAboutMission === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  Candidate knows about
                  <span>
                    {' '}
                    <b>
                      {subdomain.toLowerCase() === 'udyamita'
                        ? 'Project Udyamita'
                        : 'SIDBI MISSION SWAVALAMBAN'}
                    </b>
                  </span>{' '}
                  and duly acknowledges the program’s support in helping them{' '}
                  <b>{leadData?.enterpriseStatus?.phase}</b>
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      candidateHasStartedUpWithInvestment: e.target.value,
                    })
                  }
                  defaultValue={
                    statusChecked.candidateHasStartedUpWithInvestment
                  }
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={
                      statusChecked.candidateHasStartedUpWithInvestment === true
                    }
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={
                      statusChecked.candidateHasStartedUpWithInvestment ===
                      false
                    }
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  {leadData?.basic?.name} has{' '}
                  {leadData?.enterpriseStatus?.phase} a{' '}
                  {leadData?.enterpriseStatus?.businessIdeas[0]?.enterpriseIdea}{' '}
                  with a self-investment of INR{' '}
                  {
                    leadData?.enterpriseStatus
                      ?.canSelfFinanceTheCapitalRequiredUpToINR
                  }{' '}
                  and a loan sum of INR{' '}
                  {' ' + leadData?.enterpriseStatus?.loanAmountRequired}
                </h5>
              </td>
            </tr>
            <td colspan="2">
              <h4 className="font-weight-bold">BUSINESS DETAILS ACCURACY</h4>
            </td>
            {!(
              [null, 0].indexOf(
                leadData?.enterpriseStatus?.loanAmountRequired
              ) > -1
            ) ? (
              <React.Fragment>
                <tr>
                  <th scope="row">
                    {' '}
                    <Radio.Group
                      disabled={disableForm}
                      onChange={e =>
                        setStatusChecked({
                          ...statusChecked,
                          loanScheme: e.target.value,
                        })
                      }
                      checked={statusChecked.loanScheme}
                      // value={value}
                    >
                      <Radio
                        value={true}
                        checked={statusChecked.loanScheme === true}
                      >
                        Yes
                      </Radio>
                      <Radio
                        value={false}
                        checked={statusChecked.loanScheme === false}
                      >
                        No
                      </Radio>
                    </Radio.Group>
                  </th>
                  <td>
                    {' '}
                    <h5 className="mx-4">
                      The loan was granted under <b>{loanScheme.schemeName}</b>{' '}
                      {/*in/*/} on{' '}
                      {moment(loanScheme.disbursedOn).format('DD/MM/YYYY')}.
                    </h5>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {' '}
                    <Radio.Group
                      disabled={disableForm}
                      onChange={e =>
                        setStatusChecked({
                          ...statusChecked,
                          supportOfMissionInSecuringLoan: e.target.value,
                        })
                      }
                      checked={statusChecked.supportOfMissionInSecuringLoan}
                      // value={value}
                    >
                      <Radio
                        value={true}
                        checked={
                          statusChecked.supportOfMissionInSecuringLoan === true
                        }
                      >
                        Yes
                      </Radio>
                      <Radio
                        value={false}
                        checked={
                          statusChecked.supportOfMissionInSecuringLoan === false
                        }
                      >
                        No
                      </Radio>
                    </Radio.Group>
                  </th>
                  <td>
                    {' '}
                    <h5 className="mx-4">
                      The candidate acknowledges the support of{' '}
                      <b>
                        {subdomain.toLowerCase() === 'udyamita'
                          ? 'Project Udyamita'
                          : 'SIDBI MISSION SWAVALAMBAN'}
                      </b>{' '}
                      in securing enterprise loan.
                    </h5>
                  </td>
                </tr>
              </React.Fragment>
            ) : (
              <></>
            )}

            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      startUpDate: e.target.value,
                    })
                  }
                  checked={statusChecked.startUpDate}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.startUpDate === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.startUpDate === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The business was{' '}
                  {leadData?.enterpriseStatus?.phase === 'Start-up'
                    ? 'Started up'
                    : 'Scaled up'}
                  {leadData?.leadMaturity?.enterpriseSetup?.createdOn ===
                    undefined ||
                  leadData?.leadMaturity?.enterpriseSetup?.createdOn === null ||
                  leadData?.leadMaturity?.enterpriseSetup?.createdOn === ''
                    ? ` in ${leadData?.leadMaturity?.enterpriseSetup?.creationMonth} - ${leadData?.leadMaturity?.enterpriseSetup?.creationYear}`
                    : ` on ${moment(leadData?.leadMaturity?.enterpriseSetup?.createdOn).format('DD-MM-YYYY')}`}
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      startUpSector: e.target.value,
                    })
                  }
                  checked={statusChecked.startUpSector}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.startUpSector === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.startUpSector === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The business is in the{' '}
                  {
                    leadData?.enterpriseStatus?.businessIdeas[0]
                      ?.enterpriseCategory
                  }{' '}
                  Sector
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      enterpriseName: e.target.value,
                    })
                  }
                  checked={statusChecked.enterpriseName}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.enterpriseName === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.enterpriseName === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The name of the enterprise is{' '}
                  {leadData?.leadMaturity?.enterpriseSetup?.entityName}.
                </h5>
              </td>
            </tr>
            <td colspan="2">
              <h4 className="font-weight-bold">NECESSARY DOCUMENTATION</h4>
            </td>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={
                    disableForm ||
                    !(
                      leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                        ?.aadhar ||
                      leadData.leadMaturity?.enterpriseSetup?.kycDocuments
                        ?.pan ||
                      leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                        ?.udyogAadhar
                    )
                  }
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      candidateId: e.target.value,
                    })
                  }
                  // disabled={}
                  checked={statusChecked.candidateId}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={
                      !(
                        leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                          ?.aadhar ||
                        leadData.leadMaturity?.enterpriseSetup?.kycDocuments
                          ?.pan ||
                        leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                          ?.udyogAadhar
                      )
                        ? false
                        : statusChecked.candidateId === true
                    }
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={
                      !(
                        leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                          ?.aadhar ||
                        leadData.leadMaturity?.enterpriseSetup?.kycDocuments
                          ?.pan ||
                        leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                          ?.udyogAadhar
                      )
                        ? true
                        : statusChecked.candidateId === false
                    }
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The unique identity of the candidate is
                  {(() => {
                    if (
                      leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                        ?.aadhar
                    ) {
                      return ` Aadhar - ${leadData.leadMaturity.enterpriseSetup.kycDocuments.aadhar}`
                    } else if (
                      leadData?.leadMaturity?.enterpriseSetup?.kycDocuments?.pan
                    ) {
                      return ` PAN - ${leadData.leadMaturity.enterpriseSetup.kycDocuments.pan}`
                    } else if (
                      leadData?.leadMaturity?.enterpriseSetup?.kycDocuments
                        ?.udyogAadhar
                    ) {
                      return ` Udyog Aadhar - ${leadData.leadMaturity.enterpriseSetup.kycDocuments.udyogAadhar}`
                    } else {
                      return ' NOT AVAILABLE'
                    }
                  })()}
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={
                    disableForm ||
                    !(
                      leadData?.leadMaturity?.enterpriseSetup?.bankName &&
                      leadData?.leadMaturity?.enterpriseSetup?.bankIFSC
                    )
                  }
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      activeBank: e.target.value,
                    })
                  }
                  checked={statusChecked.activeBank}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={
                      !(
                        leadData?.leadMaturity?.enterpriseSetup?.bankName &&
                        leadData?.leadMaturity?.enterpriseSetup?.bankIFSC
                      )
                        ? false
                        : statusChecked.activeBank === true
                    }
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={
                      !(
                        leadData?.leadMaturity?.enterpriseSetup?.bankName &&
                        leadData?.leadMaturity?.enterpriseSetup?.bankIFSC
                      )
                        ? true
                        : statusChecked.activeBank === false
                    }
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The active bank of the candidate is
                  {leadData?.leadMaturity?.enterpriseSetup?.bankName
                    ? ' ' + leadData.leadMaturity.enterpriseSetup.bankName
                    : ' NOT AVAILABLE'}
                  {leadData?.leadMaturity?.enterpriseSetup?.bankName &&
                  leadData?.leadMaturity?.enterpriseSetup?.bankIFSC
                    ? ' - ' + leadData.leadMaturity.enterpriseSetup.bankIFSC
                    : ' - IFSC NOT AVAILABLE'}
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      uploadedPhotosWithBanner: e.target.value,
                    })
                  }
                  checked={statusChecked.uploadedPhotosWithBanner}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.uploadedPhotosWithBanner === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.uploadedPhotosWithBanner === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The uploaded photograph shows the shop banner with the
                  candidate clearly
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={
                    disableForm ||
                    leadData.leadMaturity.enterpriseSetup
                      .googleBusinessLocationUrl === undefined ||
                    leadData.leadMaturity.enterpriseSetup
                      .googleBusinessLocationUrl === null ||
                    leadData.leadMaturity.enterpriseSetup
                      .googleBusinessLocationUrl === ''
                  }
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      addedToGoogleMyBuisiness: e.target.value,
                    })
                  }
                  checked={statusChecked.addedToGoogleMyBuisiness}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={
                      leadData?.leadMaturity?.enterpriseSetup
                        ?.googleBusinessLocationUrl === undefined ||
                      leadData?.leadMaturity?.enterpriseSetup
                        ?.googleBusinessLocationUrl === null ||
                      leadData?.leadMaturity?.enterpriseSetup
                        ?.googleBusinessLocationUrl === ''
                        ? false
                        : statusChecked.addedToGoogleMyBuisiness === true
                    }
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={
                      leadData?.leadMaturity?.enterpriseSetup
                        ?.googleBusinessLocationUrl === undefined ||
                      leadData?.leadMaturity?.enterpriseSetup
                        ?.googleBusinessLocationUrl === null ||
                      leadData?.leadMaturity?.enterpriseSetup
                        ?.googleBusinessLocationUrl === ''
                        ? true
                        : statusChecked.addedToGoogleMyBuisiness === false
                    }
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The enterprise has been added to Google My Business and the
                  candidate has the link.
                </h5>
                {(leadData.leadMaturity.enterpriseSetup
                  .googleBusinessLocationUrl === undefined ||
                  leadData.leadMaturity.enterpriseSetup
                    .googleBusinessLocationUrl === null ||
                  leadData.leadMaturity.enterpriseSetup
                    .googleBusinessLocationUrl === '') && (
                  <small
                    className="mx-4"
                    style={{ color: 'var(--themeColor)' }}
                  >
                    <b>
                      Please fill the Google My Business URL in the Maturity
                      Stages Tab to enable editing this.
                    </b>
                  </small>
                )}
              </td>
            </tr>

            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      storyAudited: e.target.value,
                    })
                  }
                  checked={statusChecked.storyAudited}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.storyAudited === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.storyAudited === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The enterprise's story has been audited and proofread to make
                  it as accurate as possible.
                  <span>
                    <p>
                      {
                        leadData?.leadMaturity?.enterpriseSetup
                          ?.entrepreneurStory
                      }
                    </p>
                  </span>
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      registerdWithMSME: e.target.value,
                    })
                  }
                  checked={statusChecked.registerdWithMSME}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.registerdWithMSME === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.registerdWithMSME === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  <span style={{ color: 'var(--themeColor)' }}>(Optional)</span>{' '}
                  The enterprise has been registered on MSME portal/has the
                  relevant licenses as a proof of establishment. The name on the
                  document has been verified
                </h5>
              </td>
            </tr>

            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      planPreparedByTeamSwavalamban: e.target.value,
                    })
                  }
                  checked={statusChecked.planPreparedByTeamSwavalamban}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={
                      statusChecked.planPreparedByTeamSwavalamban === true
                    }
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={
                      statusChecked.planPreparedByTeamSwavalamban === false
                    }
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  The business plan was prepared by (i) Team Swavalamban, (ii)
                  Any other agency and was provided to the candidate.
                </h5>
              </td>
            </tr>
            <td colspan="2">
              <h4 className="font-weight-bold">NECESSARY DOCUMENTATION</h4>
            </td>
            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      activelyInTouchWithSCK: e.target.value,
                    })
                  }
                  checked={statusChecked.activelyInTouchWithSCK}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.activelyInTouchWithSCK === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.activelyInTouchWithSCK === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  Candidate is actively in touch with the
                  {leadData?.leadInfo?.assignTo?.ngoName
                    ? ' SCK - ' +
                      leadData?.leadInfo?.assignTo?.ngoName +
                      ' (' +
                      leadData?.leadInfo?.assignTo?.status +
                      ') '
                    : ' PO - ' +
                      leadData?.leadInfo?.assignTo?.fullName +
                      ' (' +
                      leadData?.leadInfo?.assignTo?.status +
                      ') '}
                  which supports them and knows of their address and contact
                  details.
                </h5>
              </td>
            </tr>

            <tr>
              <th scope="row">
                {' '}
                <Radio.Group
                  disabled={disableForm}
                  onChange={e =>
                    setStatusChecked({
                      ...statusChecked,
                      sckSupportingCandidate: e.target.value,
                    })
                  }
                  checked={statusChecked.sckSupportingCandidate}
                  // value={value}
                >
                  <Radio
                    value={true}
                    checked={statusChecked.sckSupportingCandidate === true}
                  >
                    Yes
                  </Radio>
                  <Radio
                    value={false}
                    checked={statusChecked.sckSupportingCandidate === false}
                  >
                    No
                  </Radio>
                </Radio.Group>
              </th>
              <td>
                {' '}
                <h5 className="mx-4">
                  {leadData?.leadInfo?.assignTo?.ngoName
                    ? ' SCK - ' +
                      leadData?.leadInfo?.assignTo?.ngoName +
                      ' (' +
                      leadData?.leadInfo?.assignTo?.status +
                      ') '
                    : ' PO - ' +
                      leadData?.leadInfo?.assignTo?.fullName +
                      ' (' +
                      leadData?.leadInfo?.assignTo?.status +
                      ') '}
                  is actively supporting and keeping in touch with the candidate
                </h5>
              </td>
            </tr>

            <td colspan="2">
              <h4 className="font-weight-bold">
                The areas of support acknowledged are (Kindly update the list,
                if required):
              </h4>
            </td>
            <tr>
              <td colspan="2">
                {/* <Col md={12} className="enterprise-setup-question"> */}
                <label className="control-label is-imp">
                  Support received from Mission Swavalamban
                </label>
                <Select
                  size="large"
                  mode="multiple"
                  showSearch
                  style={{ width: '100%' }}
                  defaultValue={
                    leadData.leadMaturity.enterpriseSetup.supportReceived
                  }
                  onChange={enterpriseSetupChange.bind(this, 'supportReceived')}
                >
                  <Option value="Counselling and Guidance">
                    Counselling and Guidance
                  </Option>
                  <Option value="Securing necessary licenses">
                    Securing necessary licenses
                  </Option>
                  <Option value="Location Selection">Location Selection</Option>
                  <Option value="Skill/Enterprise Training Connect">
                    Skill/Enterprise Training Connect
                  </Option>
                  <Option value="Business Proposal Support">
                    Business Proposal Support
                  </Option>
                  <Option value="Shop Banner and other design support">
                    Shop Banner and other design support
                  </Option>
                  <Option value="Machinery/Material Procurement">
                    Machinery/Material Procurement
                  </Option>
                  <Option value="Credit Linkage">Credit Linkage</Option>
                  <Option value="Business Ideation">Business Ideation</Option>
                  <Option value="Business Connect/Market Linkage">
                    Business Connect/Market Linkage
                  </Option>
                  <Option value="Business Plan Preparation">
                    Business Plan Preparation
                  </Option>
                  <Option value="Entrepreneurship/Financial Training or Training Connect">
                    Entrepreneurship/Financial Training or Training Connect
                  </Option>
                  <Option value="Incentive/Subsidy-related support">
                    Incentive/Subsidy-related support
                  </Option>
                  <Option value="Digital Enablement (Location tagging, Product Listing, etc.)">
                    Digital Enablement (Location tagging, Product Listing, etc.)
                  </Option>
                  <Option value="Other Connections and Networking support">
                    Other Connections and Networking support
                  </Option>
                </Select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-4" style={{ marginBottom: 100 }}>
        <div>
          <h5>1. Areas where the candidate continues to need help</h5>
        </div>

        {
          <div
            className="mt-4"
            style={
              window.screen.width < 500
                ? { marginLeft: 10 }
                : { marginLeft: 50 }
            }
          >
            <h5>a. Business Registration</h5>
            <Radio.Group
              disabled={disableForm}
              onChange={e =>
                setStatusChecked({
                  ...statusChecked,
                  otherDataPoints: {
                    ...statusChecked.otherDataPoints,
                    areasCandidateContinuesToNeedHelp: {
                      ...statusChecked.otherDataPoints
                        .areasCandidateContinuesToNeedHelp,
                      businessRegistration: e.target.value,
                    },
                  },
                })
              }
            >
              {['High', 'Medium', 'Low', 'Not Required'].map(x => {
                return (
                  <Radio.Button
                    className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                    value={x}
                    checked={
                      x ===
                      statusChecked?.otherDataPoints
                        ?.areasCandidateContinuesToNeedHelp
                        ?.businessRegistration
                    }
                  >
                    {x}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </div>
        }
        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>b. Licenses and Certifications (if relevant)</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    licensesAndCertification: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp
                      ?.licensesAndCertification
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>c. Business Planning and growth strategy</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    businessPlanningAndGrowthStrategy: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp
                      ?.businessPlanningAndGrowthStrategy
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>d. Training and upskilling</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    TrainingAndUpskilling: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp?.TrainingAndUpskilling
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>
            e. Business Health Management (Accounting, Inventory, Finance)
          </h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    businessHealthManagement: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp
                      ?.businessHealthManagement
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>
        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>f. Supply-chain (Material/Machinery procurement)</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    supplyChain: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp?.supplyChain
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>
        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>g. Scale-up credit linkage</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    scaleUpCredit: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp?.scaleUpCredit
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}{' '}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>h. Digital Financial Literacy (Using digital transactions)</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    digitalFinancialLiteracy: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp
                      ?.digitalFinancialLiteracy
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>i. Shop banner and other marketing/branding design support</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    brandingSupport: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp?.brandingSupport
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>
            j. Demand-generation (Online sales, Export, Bulk buyers, etc.)
          </h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    demandGeneration: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp?.demandGeneration
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>k. Legal and Financial Assistance</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    legalAndFinancialAssistance: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp
                      ?.legalAndFinancialAssistance
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>l. General Mentorship</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    generalMentorship: e.target.value,
                  },
                },
              })
            }
          >
            {['High', 'Medium', 'Low', 'Not Required'].map(x => {
              return (
                <Radio.Button
                  className={window.screen.width < 500 ? 'pl-2 pr-2' : 'mx-4'}
                  value={x}
                  checked={
                    x ===
                    statusChecked?.otherDataPoints
                      ?.areasCandidateContinuesToNeedHelp?.generalMentorship
                  }
                >
                  {x}
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </div>

        <div
          className="mt-4"
          style={
            window.screen.width < 500 ? { marginLeft: 10 } : { marginLeft: 50 }
          }
        >
          <h5>If Other Specify</h5>
          <input
            disabled={disableForm}
            type="text"
            defaultValue={
              statusChecked?.otherDataPoints?.areasCandidateContinuesToNeedHelp
                ?.others
            }
            className="col-6"
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  areasCandidateContinuesToNeedHelp: {
                    ...statusChecked.otherDataPoints
                      .areasCandidateContinuesToNeedHelp,
                    others: e.target.value,
                  },
                },
              })
            }
          />
        </div>

        <div className="mt-4">
          <h5>
            2. Is the candidate willing to undergo a structured mentorship
            program?
          </h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  candidateWillingToUndergoMentorshipProgram: e.target.value,
                },
              })
            }
          >
            <Radio.Button
              value="Yes"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints
                  .candidateWillingToUndergoMentorshipProgram === 'Yes'
              }
            >
              Yes
            </Radio.Button>
            <Radio.Button
              value="No"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints
                  .candidateWillingToUndergoMentorshipProgram === 'No'
              }
            >
              No
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="mt-4">
          <h5>3. Does the candidate have a smartphone?</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  candidateHasSmartphone: e.target.value,
                },
              })
            }
          >
            <Radio.Button
              value="Yes"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints.candidateHasSmartphone === 'Yes'
              }
            >
              Yes
            </Radio.Button>
            <Radio.Button
              value="No"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints.candidateHasSmartphone === 'No'
              }
            >
              No
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="mt-4">
          <h5>4. Does the candidate use basic apps on the smartphone?</h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  candidateUseBasicAppsOnSmartphone: e.target.value,
                },
              })
            }
          >
            <Radio.Button
              value="Yes"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints
                  .candidateUseBasicAppsOnSmartphone === 'Yes'
              }
            >
              Yes
            </Radio.Button>
            <Radio.Button
              value="No"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints
                  .candidateUseBasicAppsOnSmartphone === 'No'
              }
            >
              No
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="mt-4">
          <h5>
            5. Is the candidate willing to digitize their daily sales and
            purchase record on the app, for better bookkeeping and
            recommendations?
          </h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  candidateWillingToDigitalize: e.target.value,
                },
              })
            }
          >
            <Radio.Button
              value="Yes"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints.candidateWillingToDigitalize ===
                'Yes'
              }
            >
              Yes
            </Radio.Button>
            <Radio.Button
              value="No"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints.candidateWillingToDigitalize ===
                'No'
              }
            >
              No
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="mt-4 mb-4">
          <h5>
            6. Is the candidate keen to go through the video lessons on better
            business management?
          </h5>
          <Radio.Group
            disabled={disableForm}
            onChange={e =>
              setStatusChecked({
                ...statusChecked,
                otherDataPoints: {
                  ...statusChecked.otherDataPoints,
                  candiadteKeenToGoThroughVideoLessons: e.target.value,
                },
              })
            }
          >
            <Radio.Button
              value="Yes"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints
                  .candiadteKeenToGoThroughVideoLessons === 'Yes'
              }
            >
              Yes
            </Radio.Button>
            <Radio.Button
              value="No"
              className="mx-4"
              checked={
                statusChecked.otherDataPoints
                  .candiadteKeenToGoThroughVideoLessons === 'No'
              }
            >
              No
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      {/* <div className="justify-content-center text-center"> */}

      <div
        className="row justify-content-center fixed-bottom"
        style={{ backgroundColor: '#ccd', width: '100%' }}
      >
        {(userData.userType.name === 'Project Management Unit' ||
          userData.userType.name === 'Zonal Manager' ||
          (userData.userType.name === 'Swavalamban Fellows' &&
            userData.fellowAccessType === 'Partial')) && (
          <div>
            {initialResponse ? (
              <Button
                size="large"
                className="btn-success my-1 mr-4 px-4"
                style={{ fontSize: 20 }}
                onClick={addAudit}
                disabled={disableForm}
              >
                Save Progress
              </Button>
            ) : (
              <Button
                size="large"
                className="btn-success my-1 mr-4 px-4"
                style={{ fontSize: 20 }}
                onClick={updateAudit}
                disabled={disableForm}
              >
                Save Progress
              </Button>
            )}
          </div>
        )}
        {userData.userType.name === 'Project Management Unit' ||
        userData.userType.name === 'Zonal Manager' ||
        (userData.userType.name === 'Swavalamban Fellows' &&
          userData.fellowAccessType === 'Partial') ? (
          <div>
            {[null, 0].indexOf(
              leadData?.enterpriseStatus?.loanAmountRequired
            ) <= -1 ? (
              statusChecked.candidateKnowsAboutMission &&
              statusChecked.candidateHasStartedUpWithInvestment &&
              statusChecked.startUpDate &&
              statusChecked.startUpSector &&
              statusChecked.enterpriseName &&
              statusChecked.candidateId &&
              statusChecked.activeBank &&
              statusChecked.uploadedPhotosWithBanner &&
              statusChecked.addedToGoogleMyBuisiness &&
              statusChecked.storyAudited &&
              statusChecked.registerdWithMSME !== null &&
              statusChecked.planPreparedByTeamSwavalamban &&
              statusChecked.activelyInTouchWithSCK &&
              statusChecked.sckSupportingCandidate &&
              statusChecked.loanScheme &&
              statusChecked.supportOfMissionInSecuringLoan &&
              statusChecked.otherDataPoints
                .candidateWillingToUndergoMentorshipProgram !== null &&
              statusChecked.otherDataPoints.candidateHasSmartphone !== null &&
              statusChecked.otherDataPoints
                .candidateUseBasicAppsOnSmartphone !== null &&
              statusChecked.otherDataPoints.candidateWillingToDigitalize !==
                null &&
              statusChecked.otherDataPoints
                .candiadteKeenToGoThroughVideoLessons !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .businessRegistration !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .licensesAndCertification !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .businessPlanningAndGrowthStrategy !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .TrainingAndUpskilling !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .businessHealthManagement !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .supplyChain !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .scaleUpCredit !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .digitalFinancialLiteracy !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .brandingSupport !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .demandGeneration !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .legalAndFinancialAssistance !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .generalMentorship !== null ? (
                <Button
                  size="large"
                  className="btn btn-success my-1 px-4"
                  style={{ fontSize: 20 }}
                  disabled={disableForm}
                  onClick={() => (initialResponse ? addAudit() : updateAudit())}
                >
                  Mark as Audited
                </Button>
              ) : (
                <Button
                  size="large"
                  className="btn-success my-1 px-4"
                  style={{ fontSize: 20 }}
                  disabled
                  title="All fields must be Checked"
                >
                  Mark as Audited
                </Button>
              )
            ) : statusChecked.candidateKnowsAboutMission &&
              statusChecked.candidateHasStartedUpWithInvestment &&
              statusChecked.startUpDate &&
              statusChecked.startUpSector &&
              statusChecked.enterpriseName &&
              statusChecked.candidateId &&
              statusChecked.activeBank &&
              statusChecked.uploadedPhotosWithBanner &&
              statusChecked.addedToGoogleMyBuisiness &&
              statusChecked.storyAudited &&
              statusChecked.registerdWithMSME !== null &&
              statusChecked.planPreparedByTeamSwavalamban &&
              statusChecked.activelyInTouchWithSCK &&
              statusChecked.sckSupportingCandidate &&
              statusChecked.otherDataPoints
                .candidateWillingToUndergoMentorshipProgram !== null &&
              statusChecked.otherDataPoints.candidateHasSmartphone !== null &&
              statusChecked.otherDataPoints
                .candidateUseBasicAppsOnSmartphone !== null &&
              statusChecked.otherDataPoints.candidateWillingToDigitalize !==
                null &&
              statusChecked.otherDataPoints
                .candiadteKeenToGoThroughVideoLessons !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .businessRegistration !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .licensesAndCertification !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .businessPlanningAndGrowthStrategy !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .TrainingAndUpskilling !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .businessHealthManagement !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .supplyChain !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .scaleUpCredit !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .digitalFinancialLiteracy !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .brandingSupport !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .demandGeneration !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .legalAndFinancialAssistance !== null &&
              statusChecked.otherDataPoints.areasCandidateContinuesToNeedHelp
                .generalMentorship !== null ? (
              <Button
                size="large"
                className="btn-success my-1 px-4"
                style={{ fontSize: 20 }}
                disabled={disableForm}
                onClick={() => (initialResponse ? addAudit() : updateAudit())}
              >
                Mark as Audited
              </Button>
            ) : (
              <Button
                size="large"
                className="btn-success my-1 px-4"
                style={{ fontSize: 20 }}
                disabled
                title="All fields must be Checked"
              >
                Mark As Audited
              </Button>
            )}
          </div>
        ) : null}
      </div>
      {/* </div> */}
    </div>
  )
}

export default AuditChecklists
