import React, { useState, useEffect } from 'react'
import Traninglist from './trainingList'
// import moment from "moment";
import { useHttpHook } from '../../hooks/http-hook'
// import { Spin } from 'antd';

const Dataloader = props => {
  const centerId = props.match.params.centerId
  const [loadedTraining, setLoadedTraining] = useState()
  const {
    isLoading,
    // error,
    sendRequest,
    clearError,
  } = useHttpHook()

  const deleteHandler = deleteId => {
    setLoadedTraining(prevCenter =>
      prevCenter.filter(training => training.id !== deleteId)
    )
  }

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL +
            `/api/v1/center/training-schedule/view/${centerId}`
        )
        setLoadedTraining(responseData.training)
        //  console.log(responseData.training);
      } catch (err) {
        //alert(err);
      }
    }
    fetchSchedule()
  }, [sendRequest, centerId])

  return (
    <>
      {clearError}
      {!isLoading && (
        <Traninglist items={loadedTraining} onDeleteTraining={deleteHandler} />
      )}
    </>
  )
}
export default Dataloader
