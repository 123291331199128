import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useHttpHook } from '../../hooks/http-hook'

const Contactretrieve = props => {
  const {
    isLoading,
    // error,
    sendRequest,
    clearError,
  } = useHttpHook()
  const [showDelete, setDelete] = useState(false)

  const confirmDelete = async () => {
    setDelete(false)
    try {
      sendRequest(
        process.env.REACT_APP_API_URL + `/api/v1/center/contact/${props.id}`,
        'DELETE'
      )
      props.onDelete(props.id)
    } catch (err) {}
  }

  return (
    <React.Fragment>
      {clearError}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showDelete}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to proceed and delete this place? Please note that it
          can't be undone thereafter.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setDelete(false)
            }}
          >
            CANCEL
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {' '}
            DELETE{' '}
          </Button>
        </Modal.Footer>
      </Modal>

      <tr>
        <td>{props.type}</td>
        <td>{props.name}</td>
        <td>{props.designation}</td>
        <td>{props.email}</td>
        <td>{props.phone}</td>
        <td>{props.addedon}</td>
        <td>{props.lastupdate}</td>
        <td>
          <Link to={`/training-center/centers/contact/${props.id}`}>
            <Button variant="success">Edit</Button>
          </Link>
        </td>
        <td>
          {isLoading ? (
            <div className="center">
              {' '}
              <Spinner animation="border" variant="primary" />{' '}
            </div>
          ) : (
            <Button
              variant="danger"
              onClick={() => {
                setDelete(true)
              }}
            >
              Delete
            </Button>
          )}
        </td>
      </tr>
    </React.Fragment>
  )
}
export default Contactretrieve
