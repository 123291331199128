import React from 'react'
import { Col } from 'react-bootstrap'

export default class PartnerRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        current_pass: '',
        new_pass: '',
        re_pass: '',
      },
      errors: [],
      passwordIsValid: null,
    }
  }

  changeHandler(type, e) {
    let data = this.state.data,
      errors = this.state.errors
    data[type] = e.target.value
    if (e.target.value === '') {
      if (errors.indexOf(type) === -1) errors.push(type)
      else errors.splice(errors.indexOf(type), 1)
    } else {
      if (type === 'new_pass') {
        if (e.target.value.length < 6) {
          if (errors.indexOf('new_pass_len') === -1) errors.push('new_pass_len')
        } else {
          errors.splice(errors.indexOf('new_pass_len'), 1)
        }
      } else if (type === 're_pass') {
        if (e.target.value.length < 6) {
          if (errors.indexOf('re_pass_len') === -1) errors.push('re_pass_len')
        } else {
          errors.splice(errors.indexOf('re_pass_len'), 1)
        }
      }
      if (errors.length && errors.indexOf(type) > -1)
        errors.splice(errors.indexOf(type), 1)
    }
    this.setState({ data, errors }, () => {
      if (
        this.state.errors.indexOf('new_pass') === -1 &&
        this.state.errors.indexOf('re_pass') === -1 &&
        this.state.errors.indexOf('new_pass_len') === -1 &&
        this.state.errors.indexOf('re_pass_len') === -1 &&
        this.state.data.new_pass !== '' &&
        this.state.data.re_pass !== ''
      ) {
        if (this.state.data.new_pass === this.state.data.re_pass) {
          this.setState({ passwordIsValid: true })
        } else {
          this.setState({ passwordIsValid: false })
        }
      }
    })
  }

  submitPassword = () => {
    let data = this.state.data
    if (
      data.current_pass.trim() !== '' &&
      data.new_pass.trim() !== '' &&
      this.state.passwordIsValid
    ) {
      let userData = {}
      userData['current_password'] = data.current_pass
      userData['new_password'] = data.new_pass
      console.log(userData)
      // fetch(process.env.REACT_APP_API_URL + '', {
      // 	method: 'POST',
      // 	headers: {
      // 		'Content-Type': 'application/json',
      // 		'Auth': token
      // 	},
      // 	body: JSON.stringify({data: userData})
      // }).then(data => data.json())
      // .then(data => {
      // 	console.log(data)
      // }).catch(err => {
      // 	console.log(err);
      // })
    }
  }

  render() {
    return (
      <Col md={12} className="partner-register">
        <Col md={12} className="partner-register-container">
          <h3 className="register-header">Kindly create a new password</h3>
          <div className="current-pass-container form-group">
            <label className="control-label is-imp">Current password</label>
            <input
              type="password"
              style={
                this.state.errors.length &&
                this.state.errors.indexOf('current_pass') > -1
                  ? { borderColor: 'red' }
                  : {}
              }
              className="form-control"
              value={this.state.data.current_pass}
              placeholder="Enter current password"
              onChange={this.changeHandler.bind(this, 'current_pass')}
            />
          </div>
          <div className="new-pass-container form-group">
            <label className="control-label is-imp">New password</label>
            <input
              type="password"
              style={
                this.state.errors.length &&
                this.state.errors.indexOf('new_pass') > -1
                  ? { borderColor: 'red' }
                  : {}
              }
              className="form-control"
              value={this.state.data.new_pass}
              placeholder="Enter new password"
              onChange={this.changeHandler.bind(this, 'new_pass')}
            />
            {this.state.errors.indexOf('new_pass_len') > -1 ? (
              <div className="len-error">
                Password minimum length should be 6 characters.
              </div>
            ) : null}
            {this.state.passwordIsValid === false ? (
              <div className="valid-error">Both passwords should match.</div>
            ) : null}
          </div>
          <div className="re-pass-container form-group">
            <label className="control-label is-imp">Re-Enter password</label>
            <input
              type="password"
              style={
                this.state.errors.length &&
                this.state.errors.indexOf('re_pass') > -1
                  ? { borderColor: 'red' }
                  : {}
              }
              className="form-control"
              value={this.state.data.re_pass}
              placeholder="Re-Enter password"
              onChange={this.changeHandler.bind(this, 're_pass')}
            />
            {this.state.errors.indexOf('re_pass_len') > -1 ? (
              <div className="len-error">
                Password minimum length should be 6 characters.
              </div>
            ) : null}
            {this.state.passwordIsValid === false ? (
              <div className="valid-error">Both passwords should match.</div>
            ) : null}
          </div>
          <div className="submit-btn-container">
            <div
              className="btn btn-primary"
              disabled={
                !this.state.data.new_pass ||
                !this.state.data.current_pass ||
                !this.state.data.re_pass ||
                this.state.errors.indexOf('new_pass_len') > -1 ||
                this.state.errors.indexOf('re_pass_len') > -1 ||
                !this.state.passwordIsValid
                  ? true
                  : false
              }
              onClick={this.submitPassword}
            >
              Submit
            </div>
          </div>
        </Col>
      </Col>
    )
  }
}
