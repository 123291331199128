import React from 'react'
//import { Nav } from "react-bootstrap";
import './navpage.css'
import {
  ContactsOutlined,
  CommentOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import { Tab, Tabs, TabList, TabPanel, resetIdCounter } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
// import { Container } from "react-bootstrap";
import ContactPageShow from '../pages/contactUser'
import Commentlayout from '../../contactDetails/pages/commentLayout'
import Commentlist from '../../contactDetails/pages/Commentlist'
import Trainingpage from '../trainingpage/trainingPage'
import Dataloader from '../trainingpage/trainingData'

resetIdCounter()

const Navbar = () => {
  return (
    <div className="tabhandler" style={{ marginLeft: '17rem' }}>
      <Tabs defaultIndex={0}>
        <TabList>
          <Tab>
            {' '}
            <ContactsOutlined style={{ fontSize: '1.5em' }} />
            CONTACT DETAILS{' '}
          </Tab>
          <Tab>
            {' '}
            <ScheduleOutlined style={{ fontSize: '1.5em' }} />
            TRAINING SCHEDULE
          </Tab>
          <Tab>
            {' '}
            <CommentOutlined style={{ fontSize: '1.5em' }} /> COMMUNICATION LOG
          </Tab>
        </TabList>

        <TabPanel>
          <ContactPageShow />
        </TabPanel>

        <TabPanel>
          <Trainingpage />
          <Dataloader />
        </TabPanel>

        <TabPanel>
          <Commentlist />
          <Commentlayout />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default Navbar
