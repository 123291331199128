// import {  Row, Col, Container, Badge, Button } from "react-bootstrap";
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const readCookie = require('../cookie.js').readCookie

export default class FollowUpNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      counts: {
        overDue: 0,
        dueToday: 0,
        dueThisWeek: 0,
      },
    }
  }

  componentDidMount() {
    this.getCounts()
  }

  getCounts = () => {
    let apiUrl = process.env.REACT_APP_API_URL + '/api/v1/followup-counts'
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          console.log('HERE')
          this.setState({ counts: data.followUpCounts })
        }
      })
  }

  render() {
    return (
      <div>
        {window.screen.width > 500 ? (
          <div className="follow-up-nav-container d-flex">
            <span className="follow-up-text">HUMBLEBEE ADMIN PANEL</span>
            {/* <div className="count">
          {this.state.counts.overDue} OVERDUE
        </div>
        <div className="count">
          {this.state.counts.dueToday} DUE TODAY
        </div>
        <div className="count">
          {this.state.counts.dueThisWeek} DUE THIS WEEK
        </div> */}
            {/* <Link to={'/leads'} className='btn follow-up-button'>LEADS</Link> */}
          </div>
        ) : (
          <div className="row follow-up-nav-container d-flex">
            <span className="col-sm-8 col-xs-8 follow-up-text m-0 p-0 pl-2">
              HUMBLEBEE ADMIN PANEL
            </span>
            {/* <div style={{ flexDirection: "row", display: "flex" }}>
              <div
                className="col-sm-5 col-xs-5 count mt-0 ml-1 mr-1"
                style={{ width: "40%" }}
              >
                {this.state.counts.overDue} OVERDUE
              </div>
              <div
                className="col-sm-5 col-xs-5 count mt-0 ml-1 mr-1"
                style={{ width: "40%" }}
              >
                {this.state.counts.dueToday} DUE TODAY
              </div>
              <div
                className="col-sm-5 col-xs-5 count mt-0 ml-1 mr-1"
                style={{ width: "40%" }}
              >
                {this.state.counts.dueThisWeek} DUE THIS WEEK
              </div>
            </div> */}
            <Link
              to={'/leads'}
              className="btn follow-up-button mb-2"
              style={{ width: '90%' }}
            >
              LEADS
            </Link>
          </div>
        )}
      </div>
    )
  }
}
