import React, { Component } from 'react'
import { Tabs, Tab, Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'

import Members from './Members'
import Personal from './Personal'
import ManageClaims from './ManageClaims'
// import PlatformOrgs from './PlatformOrgs';

const readCookie = require('../cookie.js').readCookie

export default class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orgData: null,
      defaultActiveTab: 0,
    }
  }

  componentDidMount() {
    if (this.props.match.params.tab) {
      this.setState({ defaultActiveTab: this.props.match.params.tab })
    } else {
      this.setState({ defaultActiveTab: 2 })
    }
    // this.setState({ orgData: JSON.parse(readCookie('userData')).organisation });
  }

  setTab = tab => {
    this.setState({ defaultActiveTab: tab })
  }

  render() {
    return (
      <div className="organisation-container">
        <Tabs
          activeKey={this.state.defaultActiveTab}
          id="organisation-tab"
          onSelect={tab => this.setTab(tab)}
        >
          <Tab
            eventKey={1}
            title={
              <div className="organisation-tab-header">
                <i className="fa fa-cogs"></i>
                <span> Personal Settings</span>
              </div>
            }
          >
            <Personal userData={this.props.userData} />
          </Tab>
          {JSON.parse(readCookie('userData')).type === 'le' &&
          JSON.parse(readCookie('userData')).userType.name ===
            'Project Management Unit' ? (
            <Tab
              eventKey={2}
              title={
                <div className="organisation-tab-header">
                  <i className="fa fa-user"></i>
                  <span> Organisation</span>
                </div>
              }
            >
              <div>
                <div
                  style={{ marginTop: '10px' }}
                  className="edit-btn-container d-flex justify-content-end"
                >
                  <Button
                    variant="primary"
                    onClick={() => {
                      window.location.pathname =
                        'org-types/' +
                        this.props.match.params.type +
                        '/' +
                        this.props.match.params.orgId +
                        '/edit'
                    }}
                  >
                    Edit/Manage
                  </Button>
                </div>
                {this.state.orgData ? (
                  <Row className="org-details-overview-container">
                    {this.state.orgData.status ? (
                      <Col
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <div>
                          <span className="heading">STATUS : </span>
                          <span className="value">
                            {this.state.orgData.status}
                          </span>
                        </div>
                        <div>
                          <span
                            className="org-change-status"
                            onClick={this.changeStatus}
                          >
                            Change
                          </span>
                        </div>
                      </Col>
                    ) : null}

                    <Col md={4}>
                      <span className="heading">IS NGO PARTNER : </span>
                      <span className="value">
                        {this.state.orgData.isNgoPartner}
                      </span>
                      {this.state.orgData.isNgoPartner === 'Yes' ? (
                        <span className="value">
                          {' '}
                          [
                          <a href={this.state.orgData.ngoPartnerLink}>
                            {this.state.orgData.ngoPartnerLink}
                          </a>
                          ]
                        </span>
                      ) : null}
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">SCK TYPE : </span>
                      <span className="value">
                        {this.state.orgData.sckType}
                      </span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">State : </span>
                      <span className="value">{this.state.orgData.state}</span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">District : </span>
                      <span className="value">
                        {this.state.orgData.district}
                      </span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">OFFICE LOCATION : </span>
                      <span className="value">
                        <a
                          href={`https://maps.google.com/?q=${this.state.orgData.location.latitude},${this.state.orgData.location.longitude}`}
                        >
                          {this.state.orgData.location.latitude +
                            ', ' +
                            this.state.orgData.location.longitude}
                        </a>
                      </span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">TAGGED TO : </span>
                      <span className="value">
                        {this.state.orgData.taggedTo}
                      </span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">ADDED ON : </span>
                      <span className="value">
                        {moment(this.state.orgData.addedOn).format(
                          'DD/MM/YYYY hh:mm A'
                        )}
                      </span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">ADDED BY : </span>
                      <span className="value">
                        {this.state.orgData.addedBy.name}
                      </span>
                    </Col>
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">ESTD : </span>
                      <span className="value">{this.state.orgData.estd}</span>
                    </Col>
                    {this.state.orgData.causes.length ? (
                      <Col md={4}>
                        <span className="heading">CAUSES : </span>
                        <span className="value">
                          {this.state.orgData.causes.map((cause, index) => {
                            if (this.state.orgData.causes.length > 1) {
                              return cause + ', '
                            } else {
                              return cause
                            }
                          })}
                        </span>
                      </Col>
                    ) : null}
                    <Col md={4} className="d-flex align-items-center">
                      <span className="heading">CACHMENT POPULATION : </span>
                      <span className="value">
                        {this.state.orgData.population}
                      </span>
                    </Col>
                  </Row>
                ) : null}
              </div>
            </Tab>
          ) : null}
          {JSON.parse(readCookie('userData')).type === 'le' &&
          JSON.parse(readCookie('userData')).userType.name ===
            'Project Management Unit' ? (
            <Tab
              eventKey={3}
              title={
                <div className="organisation-tab-header">
                  <i className="fa fa-user"></i>
                  <span> Members</span>
                </div>
              }
            >
              <Members userData={this.props.userData} />
            </Tab>
          ) : null}
          {JSON.parse(readCookie('userData')).type === 'le' &&
          JSON.parse(readCookie('userData')).userType.name ===
            'Swavalamban Fellows' &&
          false ? (
            <Tab
              eventKey={2}
              title={
                <div className="organisation-tab-header">
                  <i className="fa fa-users"></i>
                  <span> SCKs</span>
                </div>
              }
            >
              {/*<PlatformOrgs userData={this.props.userData} />*/}
            </Tab>
          ) : null}
          {JSON.parse(readCookie('userData')).type === 'partners' ? (
            <Tab
              eventKey={2}
              title={
                <div className="organisation-tab-header">
                  <i className="fa fa-money"></i>
                  <span> Manage Claims</span>
                </div>
              }
            >
              <ManageClaims userData={this.props.userData} />
            </Tab>
          ) : null}
        </Tabs>
      </div>
    )
  }
}
