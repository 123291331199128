import { applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { configureStore } from '@reduxjs/toolkit'
//@ts-ignore
import getAllBatchesSlice from './reduxLogic/reducers/batches/getAllBatchesSlice'
import makeBatchSlice from './reduxLogic/reducers/batches/makeBatchSlice'
import getAllFarmersSlice from './reduxLogic/reducers/farmers/getAllFarmersSlice'
import makeFarmerSlice from './reduxLogic/reducers/farmers/makeFarmerSlice'
import authSlice from './reduxLogic/reducers/auth/authSlice'
import getSingleFarmerSlice from './reduxLogic/reducers/farmers/getSingleFarmerSlice'
import getAllSupplierSlice from './reduxLogic/reducers/suppliers/getAllSupplierSlice'
import makeSupplierSlice from './reduxLogic/reducers/suppliers/makeSupplierSlice'
import getSingleSupplierSlice from './reduxLogic/reducers/suppliers/getSingleSupplierSlice'
import getSingleBatchSlice from './reduxLogic/reducers/batches/getSingleBatchSlice'
import editSingleSupplierSlice from './reduxLogic/reducers/suppliers/editSingleSupplierSlice'
import deleteSupplierSlice from './reduxLogic/reducers/suppliers/deleteSupplierSlice'
import editSingleFarmerSlice from './reduxLogic/reducers/farmers/editSingleFarmerSlice'
import deleteFarmerSlice from './reduxLogic/reducers/farmers/deleteFarmerSlice'
import editSingleBatchSlice from './reduxLogic/reducers/batches/editSingleBatchSlice'
import deleteBatchSlice from './reduxLogic/reducers/batches/deleteBatchSlice'
import getAllUsersSlice from './reduxLogic/reducers/auth/getAllUsersSlice'
import getAllChatsSlice from './reduxLogic/reducers/chats/getAllChatsSlice'
import getMobileUserSlice from './reduxLogic/reducers/auth/searchMobileUser'
import getAllHoneySlice from './reduxLogic/reducers/honeys/getAllHoneySlice'
import getSingleHoneySlice from './reduxLogic/reducers/honeys/getSingleHoneySlice'
import makeHoneySlice from './reduxLogic/reducers/honeys/makeHoneySlice'
import editSingleHoneySlice from './reduxLogic/reducers/honeys/editSingleHoneySlice'
import projectCreationSlice from './reduxLogic/reducers/projects/projectCreationSlice'
import getAllProjectSlice from './reduxLogic/reducers/projects/getAllProjectSlice'
import createBeeMitraSlice from './reduxLogic/reducers/beeMitra/createBeeMitraSlice'
import getAllBeeMitrasSlice from './reduxLogic/reducers/beeMitra/getAllBeeMitrasSlice'
import editSingleProjectSlice from './reduxLogic/reducers/projects/editSingleProjectSlice'
import getSingleProjectDetailsSlice from './reduxLogic/reducers/projects/getSingleProjectDetailsSlice'
import deleteProjectSlice from './reduxLogic/reducers/projects/deleteProjectSlice'
import deleteBeeMitraSlice from './reduxLogic/reducers/beeMitra/deleteBeeMitraSlice'
import editBeeMitraSlice from './reduxLogic/reducers/beeMitra/editBeeMitraSlice'
import getSingleBeeMitraSlice from './reduxLogic/reducers/beeMitra/getSingleBeeMitraSlice'
import getAllStatesSlice from './reduxLogic/reducers/projects/getAllStatesSlice'
import getAllDistrictsSlice from './reduxLogic/reducers/projects/getAllDistrictsSlice'
import makeUserSlice from './reduxLogic/reducers/users/makeUserSlice'
import getAllClustersSlice from './reduxLogic/reducers/clusters/getAllClustersSlice'
import getClusterByProjectSlice from './reduxLogic/reducers/clusters/getClusterByProjectSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    allUsers: getAllUsersSlice,
    allChats: getAllChatsSlice,
    makeUser: makeUserSlice,
    mobileUser: getMobileUserSlice,
    allBatches: getAllBatchesSlice,
    makeBatch: makeBatchSlice,
    getSingleBatch: getSingleBatchSlice,
    updateBatch: editSingleBatchSlice,
    deleteBatch: deleteBatchSlice,
    allFarmers: getAllFarmersSlice,
    getSingleFarmer: getSingleFarmerSlice,
    makeFarmer: makeFarmerSlice,
    editFarmer: editSingleFarmerSlice,
    deleteFarmer: deleteFarmerSlice,
    allSuppliers: getAllSupplierSlice,
    makeSupplier: makeSupplierSlice,
    getSingleSupplier: getSingleSupplierSlice,
    editSingleSupplier: editSingleSupplierSlice,
    deleteSupplier: deleteSupplierSlice,
    allHoneys: getAllHoneySlice,
    singleHoney: getSingleHoneySlice,
    makeHoney: makeHoneySlice,
    editHoney: editSingleHoneySlice,
    createProject: projectCreationSlice,
    allProjects: getAllProjectSlice,
    clusterData: getAllClustersSlice,
    clusterViaProjectData: getClusterByProjectSlice,
    allDistricts: getAllDistrictsSlice,
    allStates: getAllStatesSlice,
    createBeeMitra: createBeeMitraSlice,
    allBeeMitras: getAllBeeMitrasSlice,
    editProject: editSingleProjectSlice,
    singleProject: getSingleProjectDetailsSlice,
    deleteProject: deleteProjectSlice,
    deleteBeeMitra: deleteBeeMitraSlice,
    editBeeMitra: editBeeMitraSlice,
    singleBeeMitra: getSingleBeeMitraSlice,
  },
})
