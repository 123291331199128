import React, { useState, useEffect } from 'react'
import { Modal, Select, Input, Alert, Spin } from 'antd'
import '../../../bank-branches/components/FormElements/button.css'
import { useHttpHook } from '../hooks/http-hook'

const { Option, OptGroup } = Select
const { TextArea } = Input

const Stage = [
  'Profile Creation',
  'Due-Diligence',
  'Business Planning',
  'Loan Application',
  'Enterprise Set-up',
]

const readCookie = require('../../../cookie.js').readCookie

const Messagemodal = props => {
  const [stage, setStage] = useState('')
  const [Scenario, setscenrio] = useState('')
  const [isvalid, setvalid] = useState(false)
  const userId = JSON.parse(readCookie('userData')).id
  const {
    isLoading,
    // error,
    sendRequest,
    clearError,
  } = useHttpHook()

  useEffect(() => {
    if (stage !== '' && Scenario !== '') setvalid(false)
  }, [stage, Scenario])

  const submitHandler = async event => {
    if (
      stage !== '' &&
      Scenario !== '' &&
      /^[A-Z a-z -]{1,50}$/.test(Scenario)
    ) {
      setvalid(false)

      event.preventDefault()

      try {
        await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/masturation-messages',
          'POST',
          JSON.stringify({
            Stage: stage,
            scenario: Scenario,
            addedby: userId,
          }),
          {
            'Content-Type': 'application/json',
            'x-auth-token': JSON.parse(readCookie('access_token')),
          }
        )
        props.hide()
        window.location.reload(false)
      } catch (err) {
        alert(err)
      }

      //alert(stage +" " +Scenario);
    } else {
      setvalid(true)
    }
  }

  return (
    <React.Fragment>
      {clearError}
      <Spin spinning={isLoading} delay={500}>
        <Modal
          title="Add a stage"
          style={{ top: 20 }}
          visible={props.show}
          onOk={submitHandler}
          okText={isLoading ? <Spin size="large" /> : 'Add'}
          onCancel={props.hide}
        >
          <label className="control-label">
            Stage<span className="mandatory">{'*'}</span>
          </label>
          <Select
            style={{ width: 200 }}
            value={stage === '' ? 'Select stage' : stage}
            placeholder="Select a stage"
            onChange={e => {
              setStage(e)
            }}
          >
            <OptGroup>
              {Stage.map((Stage, StageIndex) => {
                return (
                  <Option key={StageIndex} value={Stage}>
                    {Stage}
                  </Option>
                )
              })}
            </OptGroup>
          </Select>
          <div style={{ margin: '24px 0' }} />
          <label className="control-label">
            Enter Scenario with max 50 chars
            <span className="mandatory">{'*'}</span>
          </label>
          <TextArea
            showCount
            maxLength={50}
            minLength={0}
            value={Scenario}
            onChange={e => {
              setscenrio(e.target.value)
            }}
            placeholder="Enter Scenario"
          />
          <div style={{ margin: '24px 0' }} />
          {isvalid && (
            <Alert
              message="Error"
              description="All fieds are mandatory"
              type="error"
              showIcon
            />
          )}
        </Modal>
      </Spin>
    </React.Fragment>
  )
}

export default Messagemodal
