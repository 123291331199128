import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  status: '',
  batchData: [],
  loading: false,
  error: '',
  message: '',
}

export const getAllBatches = createAsyncThunk(
  'getAllBatches',
  async rejectWithValue => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/batches`
      )
      //console.log("res.data ==> ", res.data);
      return res.data.batches
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const getAllBatchesSlice = createSlice({
  name: 'getAllBatches',
  initialState,
  reducers: {},

  extraReducers: {
    [getAllBatches.pending]: (state, action) => {
      state.loading = true

      state.error = ''
    },

    [getAllBatches.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.batchData = payload
      }
    },

    [getAllBatches.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.loading = false
    },
  },
})

export default getAllBatchesSlice.reducer
