import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  singleSupplier: {},
  loading: true,
  error: '',
  message: '',
}

export const makeSupplier = createAsyncThunk(
  'makeSupplier',
  async (body, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/suppliers/`,
        body,
        { 'Content-Type': 'multipart/form-data' }
      )
      console.log('body from makeSupplier ', body)
      console.log('res.data from makeSupplier  ', res.data)
      return res.data
    } catch (error) {
      //@ts-ignore
      console.log('error in makeSupplier => ', error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetMessageinMakeSupplier = createAction(
  'resetMessageinMakeSupplier'
)
export const resetErrorinMakeSupplier = createAction('resetErrorinMakeSupplier')

const makeSupplierSlice = createSlice({
  name: 'makeSupplier',
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [makeSupplier.pending]: (state, action) => {
      state.loading = true
    },
    //@ts-ignore
    [makeSupplier.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleSupplier, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
        console.log('this is error in fullfiled make supplier', error)
      } else {
        state.singleSupplier = singleSupplier
        state.message = message
      }
    },
    //@ts-ignore
    [makeSupplier.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.message = message

      console.log('this is error in rejected makeSupplier => ', error)
    },
    [resetErrorinMakeSupplier]: state => {
      state.error = ''
    },
    [resetMessageinMakeSupplier]: state => {
      state.message = ''
    },
  },
})

export default makeSupplierSlice.reducer
