import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import Label from '../FormElements/label'
import { useForm } from '../../hooks/form-hook'
import { SaveTwoTone } from '@ant-design/icons'
import Input from '../FormElements/Input'
import { useHttpHook } from '../../hooks/http-hook'
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_PINCODE,
  VALIDATOR_TRAINEDcANDIDATE,
} from '../../util/validators'
import { Spin } from 'antd'
import Swal from 'sweetalert2'

const readCookie = require('../../../cookie.js').readCookie

const UpdateCenter = props => {
  const type = ['RSETI', 'PMKK', 'JSS', 'DDU-GKY', 'Other-Training-centre']
  const rating = ['Excellent', 'Average', 'Poor']
  const database = ['Yes', 'Will not share', 'Not yet shared']
  const [modalShow, setModalShow] = React.useState(true)
  const centerId = props.match.params.centerId
  const { isLoading, error, sendRequest, clearError } = useHttpHook()
  const [loadedCenter, setLoadedCenter] = useState()
  const [stateload, setStateLoad] = useState()
  const [districtload, setDistrictLoad] = useState()

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/location/states'
        )
        setStateLoad(responseData)
      } catch (err) {}
    }
    const fetchDistrict = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/location/districts'
        )
        //console.log("district  " + JSON.stringify(responseData));
        setDistrictLoad(responseData)
      } catch (err) {}
    }
    fetchStates()
    fetchDistrict()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [formState, inputHandler, setFormData] = useForm(
    {
      type: { value: '', isValid: false },
      state: { value: '', isValid: false },
      district: { value: '', isValid: false },
      pincode: { value: '', isValid: false },
      rating: { value: '', isValid: false },
      database: { value: '', isValid: false },
      trained: { value: '', isValid: false },
      employed: { value: '', isValid: false },
      settlement: { value: '', isValid: false },
    },
    false
  )

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + `/api/v1/center/${centerId}`
        )
        setLoadedCenter(responseData.center)
        setFormData(
          {
            type: {
              value: responseData.center.type,
              isValid: true,
            },
            state: {
              value: responseData.center.state,
              isValid: true,
            },
            district: {
              value: responseData.center.district,
              isValid: true,
            },
            pincode: {
              value: responseData.center.pincode,
              isValid: true,
            },
            rating: {
              value: responseData.center.rating,
              isValid: true,
            },
            database: {
              value: responseData.center.database,
              isValid: true,
            },
            trained: {
              value: responseData.center.trained,
              isValid: true,
            },
            employed: {
              value: responseData.center.employed,
              isValid: true,
            },
            settlement: {
              value: responseData.center.settlement,
              isValid: true,
            },
          },
          true
        )
      } catch (err) {}
    }
    fetchPlaces()
  }, [sendRequest, centerId, setFormData])

  const saveUpdate = async event => {
    event.preventDefault()
    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + `/api/v1/center/${centerId}`,
        'PATCH',
        JSON.stringify({
          type: formState.inputs.type.value,
          state: formState.inputs.state.value,
          district: formState.inputs.district.value,
          pincode: formState.inputs.pincode.value,
          rating: formState.inputs.rating.value,
          database: formState.inputs.database.value,
          trained: formState.inputs.trained.value,
          employed: formState.inputs.employed.value,
          settlement: formState.inputs.settlement.value,
        }),
        {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        }
      )
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Updated Successfully',
        showConfirmButton: false,
        timer: 1500,
      })
    } catch (err) {}
    window.location.pathname = '/training-center'
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <div style={{ position: 'absolute', left: '50rem', top: '15rem' }}>
          <Spin size="large" />
        </div>
      </React.Fragment>
    )
  }

  if (!loadedCenter && !error) {
    return (
      <React.Fragment>
        <h2
          style={{
            position: 'absolute',
            left: '0rem',
            top: '15rem',
            backgroundColor: 'gray',
            padding: '20px',
            borderRadius: '25px',
          }}
        >
          Couldn't find place
        </h2>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {clearError}
      {/** error model */}
      {!isLoading && loadedCenter && (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={modalShow}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update Place
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col sm={4}>
                  <Label name="Type" />
                  <Input
                    element="Select"
                    id="type"
                    onInput={inputHandler}
                    errorText="Please Select Correctly"
                    validators={[VALIDATOR_REQUIRE()]}
                    initialValue={loadedCenter.type}
                    initialValid={true}
                    option={type}
                    name="type"
                  />
                </Col>
                <Col sm={4}>
                  <Label name="State" />
                  {stateload === undefined && clearError && (
                    <div className="center">
                      {' '}
                      <Spin />{' '}
                    </div>
                  )}
                  {!isLoading && stateload !== undefined && (
                    <Input
                      element="Select"
                      id="state"
                      onInput={inputHandler}
                      errorText="Please Select Correctly"
                      validators={[VALIDATOR_REQUIRE()]}
                      initialValue={loadedCenter.state}
                      initialValid={true}
                      option={stateload.data.states}
                      name="state"
                      label={'All States'}
                    />
                  )}
                </Col>
                <Col sm={4}>
                  <Label name="District" />
                  {districtload === undefined && clearError && (
                    <div className="center">
                      {' '}
                      <Spin />{' '}
                    </div>
                  )}
                  {!isLoading && districtload !== undefined && (
                    <Input
                      element="Select"
                      id="district"
                      onInput={inputHandler}
                      errorText="Please Select Correctly"
                      validators={[VALIDATOR_REQUIRE()]}
                      initialValue={loadedCenter.district}
                      initialValid={true}
                      option={districtload.districts}
                      name="district"
                    />
                  )}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={4}>
                  <Label name="Pincode" />
                  <Input
                    element="input"
                    type="text"
                    onInput={inputHandler}
                    id="pincode"
                    placeholder="6-Digit-Pincode"
                    errorText="Pincode must be correct"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_PINCODE()]}
                    initialValue={loadedCenter.pincode}
                    initialValid={true}
                  />
                </Col>
                <Col sm={4}>
                  <Label name="Rating" />
                  <Input
                    element="Select"
                    id="rating"
                    onInput={inputHandler}
                    errorText="Please Select Correctly"
                    validators={[VALIDATOR_REQUIRE()]}
                    initialValue={loadedCenter.rating}
                    initialValid={true}
                    name="rating/database"
                    option={rating}
                  />
                </Col>
                <Col sm={4}>
                  <Label name="Database shared ?" />
                  <Input
                    element="Select"
                    id="database"
                    onInput={inputHandler}
                    errorText="Please Select Correctly"
                    validators={[VALIDATOR_REQUIRE()]}
                    initialValue={loadedCenter.database}
                    initialValid={true}
                    name="rating/database"
                    option={database}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={4}>
                  <Label name="No. of trained candidates every year" />
                  <Input
                    element="input"
                    type="text"
                    id="candidate"
                    onInput={inputHandler}
                    placeholder="Trained Candidate"
                    errorText="Number is Between 0 - 10000"
                    validators={[
                      VALIDATOR_REQUIRE(),
                      VALIDATOR_TRAINEDcANDIDATE(),
                    ]}
                    initialValue={loadedCenter.trained}
                    initialValid={true}
                  />
                </Col>
                <Col sm={4}>
                  <Label name="Self-employed/Trained candidates (%)" />
                  <Input
                    element="input"
                    id="employed"
                    onInput={inputHandler}
                    type="number"
                    min="0"
                    max="100"
                    errorText="Number is Between 0 - 100"
                    validators={[VALIDATOR_REQUIRE()]}
                    initialValue={loadedCenter.employed}
                    initialValid={true}
                  />
                </Col>
                <Col sm={4}>
                  <Label name="Settlement %" />
                  <Input
                    element="input"
                    id="settlement"
                    onInput={inputHandler}
                    type="number"
                    min="0"
                    max="100"
                    errorText="Number is Between 0 - 100"
                    validators={[VALIDATOR_REQUIRE()]}
                    initialValue={loadedCenter.settlement}
                    initialValid={true}
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              type="submit"
              disabled={!formState.isValid}
              onClick={saveUpdate}
            >
              Update <SaveTwoTone style={{ fontSize: '30px' }} />
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => {
                setModalShow(false)
                window.location.pathname = '/training-center'
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default UpdateCenter
