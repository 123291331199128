import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const currentToken = cookies.get('access_token')

const initialState = {
  singleProject: {},
  loading: true,
  error: '',
  message: '',
}

export const createProject = createAsyncThunk(
  'createProject',
  async (body, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_HUMBLEBEE_API_URL}/projects/`,
        body,
        {
          headers: {
            authorization: `Bearer ${currentToken}`,
          },
        }
      )
      console.log('body from createProject ', body)
      console.log('res.data from createProject  ', res.data)
      return res.data
    } catch (error) {
      //@ts-ignore
      console.log('error in createProject => ', error)
      return rejectWithValue(error.response.data)
    }
  }
)

export const resetMessageinProjectCreation = createAction(
  'resetMessageinProjectCreation'
)
export const resetErrorinProjectCreation = createAction(
  'resetErrorinProjectCreation'
)

const projectCreationSlice = createSlice({
  name: 'createProject',
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [createProject.pending]: (state, action) => {
      state.loading = true
    },
    //@ts-ignore
    [createProject.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleProject, message } }
    ) => {
      state.loading = false
      if (error) {
        state.error = error
        console.log('this is error in fullfiled project creation', error)
      } else {
        state.singleProject = singleProject
        state.message = message
      }
    },
    //@ts-ignore
    [createProject.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error
      state.message = message
    },
    [resetErrorinProjectCreation]: state => {
      state.error = ''
    },
    [resetMessageinProjectCreation]: state => {
      state.message = ''
    },
  },
})

export default projectCreationSlice.reducer
