import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  Input,
  Icon,
  Select,
  // Switch
} from 'antd'
// import { allBanks } from '../../../../commonConfig';

const readCookie = require('../../cookie').readCookie

const { Option } = Select

export default class NewLeadAdvancedForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyingIFSC: null,
      allBanks: [],
      // allBanks: allBanks,
      //["//Allahabad Bank", "Andhra Bank", "Andhra Pradesh Grameena Vikas Bank", "Aryavart Bank", "Axis Bank", "Bandhan Bank", "Bank of Bahrain and Kuwait", "Bank of Baroda", "Bank of India", "Bank of Maharashtra", "Baroda Uttar Pradesh Gramin Bank", "Bhadradri Bank", "Canara Bank", "Central Bank of India", "City Union Bank", "Corporation Bank", "Dakshin Bihar Gramin Bank", "Dena Bank", "Deutsche Bank", "Development Credit Bank", "Dhanlaxmi Bank", "Federal Bank", "Fino Payments Bank", "Gramin Bank", "HDFC Bank", "ICICI Bank", "IDBI Bank", "Indian Bank", "Indian Post Payments Bank", "Indian Overseas Bank", "IndusInd Bank", "ING Vysya Bank", "Jammu and Kashmir Bank", "Karnataka Bank Ltd", "Karur Vysya Bank", "Kashi Gomti Samyut Gramin Bank", "Kotak Bank", "Laxmi Vilas Bank", "Oriental Bank of Commerce", "Prathama Bank", "Punjab National Bank - Corporate Banking", "Punjab National Bank - Retail Banking", "Punjab &amp; Sind Bank", "RBL Bank", "Shamrao Vitthal Co-operative Bank", "South Indian Bank", "State Bank of Bikaner &amp; Jaipur", "State Bank of Hyderabad", "State Bank of India", "State Bank of Mysore", "State Bank of Patiala", "State Bank of Travancore", "Syndicate Bank", "Tamilnad Mercantile Bank Ltd.", "UCO Bank", "Union Bank of India", "United Bank of India", "Uttar Bihar Gramin Bank", "Vijaya Bank", "Yes Bank Ltd"],
      allSentiments: [
        'Very enthusiastic',
        'Enthusiastic',
        'Exploring enterprise options in addition to the current engagement',
        'Not very enthusiastic',
      ],
      allHorizons: [
        'Under 1 month',
        '1-3 months',
        '3-6 months',
        '6-12 months',
        '12-24 months',
        '>24 months',
      ],
      allPurposes: [
        'First interaction for comfort  level establishment',
        'To know more about the schemes',
        'Gaining an understanding of various business options',
        'Assessing how can we add value',
        'Business plan creation',
        'Demand/supply linkage',
        'Credit linkage',
        'General walk-in without a firm agenda',
        'Could not gauge',
      ],
    }
  }
  componentDidMount() {
    this.getAllBanks()
  }
  getAllBanks = () => {
    fetch(process.env.REACT_APP_API_URL + '/api/v1/bank-branches/banks', {
      method: 'GET',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(data => data.json())
      .then(data => {
        if (data.status) {
          this.setState({ allBanks: data.allBanks })
        }
      })
  }
  searchFilter = (input, option) => {
    input = input.toLowerCase()
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  showIFSCIcon = () => {
    let verifyingIFSC = this.props.verifyingIFSC
    if (verifyingIFSC === 'Verified') {
      return (
        <Icon style={{ marginBottom: 6 }} type="check" title="IFSC Verified" />
      )
    } else if (verifyingIFSC === 'Verifying') {
      return (
        <Icon
          style={{ marginBottom: 6 }}
          type="loading"
          title="Verifying IFSC"
        />
      )
    } else if (verifyingIFSC === 'Failed') {
      return (
        <Icon style={{ marginBottom: 6 }} type="warning" title="Invalid IFSC" />
      )
    } else return null
  }

  render() {
    return (
      <Row className="step-container step-3">
        <Col md={4}>
          <label className="control-label is-imp">Owns Land?</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.advanced.hasLand}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'hasLand'
            )}
          >
            <Option value="" disabled>
              Select Yes/No
            </Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('hasLand') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.hasLand}
            </div>
          ) : null}
        </Col>

        {this.props.advanced.hasLand === 'Yes' ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Relationship to Land Owner
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.advanced.relationshipWithLandOwner}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'relationshipWithLandOwner'
              )}
            >
              <Option value="" disabled>
                Select
              </Option>
              <Option value="Self">Self</Option>
              <Option value="Father/Mother">Father/Mother</Option>
              <Option value="Family Land">Family Land</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty('relationshipWithLandOwner') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.relationshipWithLandOwner}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.advanced.hasLand === 'Yes' ? (
          <Col md={4}>
            <label className="control-label">Land is in whose name</label>
            <input
              type="text"
              placeholder="Name of Land owner"
              value={this.props.advanced.landOwnerName}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'landOwnerName'
              )}
            />
          </Col>
        ) : null}

        {this.props.advanced.hasLand === 'Yes' ? (
          <Col md={2}>
            <label className="control-label">Area of Land</label>
            <input
              type="number"
              placeholder="Area of Land"
              value={this.props.advanced.area.value}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'areaValue'
              )}
            />
          </Col>
        ) : null}

        {this.props.advanced.hasLand === 'Yes' ? (
          <Col md={2}>
            <label className="control-label">Units</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.advanced.area.unit}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'areaUnit'
              )}
            >
              <Option value="">Select Unit</Option>
              <Option value="sq feet">Square Feet</Option>
              <Option value="sq mtr">Square Meter</Option>
              <Option value="sq mile">Square Mile</Option>
              <Option value="acre">Acre</Option>
              <Option value="hectare">Hectare</Option>
            </Select>
          </Col>
        ) : null}

        <Col md={8}>
          <label className="control-label">Has Other Assets</label>
          <Select
            size="large"
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Has Other Assets"
            value={this.props.advanced.hasOtherAssets}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'hasOtherAssets'
            )}
          ></Select>
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Willing to give collateral
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.advanced.collateral}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'collateral'
            )}
          >
            <Option value="">Select Yes/No</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('collateral') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.collateral}
            </div>
          ) : null}
        </Col>

        {this.props.advanced.collateral === 'Yes' ? (
          <Col md={4}>
            <label className="control-label is-imp">Collateral Type</label>
            <Select
              size="large"
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              value={this.props.advanced.collateralType || []}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'collateralType'
              )}
              filterOption={(input, option) => this.searchFilter(input, option)}
            >
              {/* <Option value="" disabled>Select Banks</Option> */}
              {[
                'Farm Land',
                'Commercial Land',
                'House',
                'Farmhouse',
                'Factory/Mill',
                'Automobile',
                'Machinery/Equipment',
                'Inventory',
                'Gold',
                'Personal investments(Stocks/Bonds/Mutual funds)',
              ].map((collateralType, collateralTypeIndex) => {
                return (
                  <Option key={collateralTypeIndex} value={collateralType}>
                    {collateralType}
                  </Option>
                )
              })}
            </Select>
            {this.props.errorObj.hasOwnProperty('collateralType') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.collateralType}
              </div>
            ) : null}
          </Col>
        ) : null}

        <Col md={4}>
          <label className="control-label is-imp">
            Has Active Bank Account
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.advanced.hasBankAccount}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'hasBankAccount'
            )}
          >
            <Option value="" disabled>
              Select Yes/No
            </Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('hasBankAccount') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.hasBankAccount}
            </div>
          ) : null}
        </Col>

        {this.props.advanced.hasBankAccount === 'Yes' ? (
          <Col md={4} id="bank-accounts">
            <label className="control-label is-imp">
              Which Bank is the account with
            </label>
            <Select
              size="large"
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              value={this.props.advanced.bankAccounts}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'bankAccounts'
              )}
              filterOption={(input, option) => this.searchFilter(input, option)}
            >
              <Option value="" disabled>
                Select Banks
              </Option>
              {this.state.allBanks.length
                ? this.state.allBanks.map((bank, bankIndex) => {
                    return (
                      <Option key={bankIndex} value={bank}>
                        {bank}
                      </Option>
                    )
                  })
                : null}
            </Select>
            {this.props.errorObj.hasOwnProperty('bankAccounts') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.bankAccounts}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.advanced.hasBankAccount === 'Yes' ? (
          <Col md={4} id="bank-accounts">
            <label className="control-label">IFSC of Primary Bank branch</label>
            <Input
              placeholder="Primary Bank IFSC"
              value={this.props.advanced.bankIFSC}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'bankIFSC'
              )}
              addonAfter={this.showIFSCIcon()}
              onBlur={this.props.verifyIFSC}
            />
            {this.props.errorObj.hasOwnProperty('bankIFSC') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.bankIFSC}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.advanced.hasBankAccount === 'Yes' ? (
          <Col md={4}>
            <label className="control-label is-imp">
              How far is the bank branch from your home?
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.advanced.proximityToBank}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'proximityToBank'
              )}
              defaultActiveFirstOption={false}
            >
              <Option value="" disabled>
                Select Proximity
              </Option>
              <Option value="Very close (less than 2km)">
                Very close (less than 2km)
              </Option>
              <Option value="Urban but not very close (2-5 km)">
                Urban but not very close (2-5 km)
              </Option>
              <Option value="Far (>5 km)">{'Far (>5 km)'}</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty('proximityToBank') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.proximityToBank}
              </div>
            ) : null}
          </Col>
        ) : null}

        <Col md={4} id="received-bank-loan">
          <label className="control-label is-imp">Previous loan history</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.advanced.receivedBankLoan}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'receivedBankLoan'
            )}
            defaultActiveFirstOption={false}
          >
            <Option value="" disabled>
              Select loan history
            </Option>
            <Option value="Never taken a loan">Never taken a loan</Option>
            <Option value="Loan repaid">Loan repaid</Option>
            <Option value="Loan taken and ongoing">
              Loan taken and ongoing
            </Option>
            <Option value="Loan taken and defaulted">
              Loan taken and defaulted
            </Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('receivedBankLoan') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.receivedBankLoan}
            </div>
          ) : null}
        </Col>

        {this.props.advanced.receivedBankLoan &&
        this.props.advanced.receivedBankLoan !== 'Never taken a loan' ? (
          <Col md={4}>
            <label className="control-label is-imp">Type of loan</label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.advanced.loanType}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'loanType'
              )}
            >
              <Option value="" disabled>
                Select Loan Type
              </Option>
              <Option value="Business loan">Business loan</Option>
              <Option value="House/Vehicle loan">House/Vehicle loan</Option>
              <Option value="Personal loan">Personal loan</Option>
              <Option value="Group/MFI loan">Group/MFI loan</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty('loanType') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.loanType}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.advanced.receivedBankLoan &&
        this.props.advanced.receivedBankLoan !== 'Never taken a loan' ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Select Loan Provider Type
            </label>
            <Select
              size="large"
              style={{ width: '100%' }}
              value={this.props.advanced.receivedLoanProviderType}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'receivedLoanProviderType'
              )}
            >
              <Option value="" disabled>
                Select Loan Provider Type
              </Option>
              <Option value="Banks">Banks</Option>
              <Option value="NBFC/MFI">NBFC/MFI</Option>
              <Option value="Moneylender">Moneylender</Option>
              <Option value="Family/Friends">Family/Friends</Option>
              <Option value="Others">Others</Option>
            </Select>
            {this.props.errorObj.hasOwnProperty('receivedLoanProviderType') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.receivedLoanProviderType}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.advanced.receivedBankLoan &&
        this.props.advanced.receivedBankLoan !== 'Never taken a loan' ? (
          <Col md={4}>
            <label className="control-label">Loan Installment Status</label>
            <input
              type="text"
              placeholder="Loan Installment Status"
              value={this.props.advanced.loansInstallmentStatus}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'loansInstallmentStatus'
              )}
            />
          </Col>
        ) : null}

        {this.props.advanced.receivedBankLoan &&
        this.props.advanced.receivedBankLoan === 'Loan taken and ongoing' ? (
          <Col md={4}>
            <label className="control-label is-imp">
              Enter outstanding loan amount (INR)
            </label>
            <input
              type="number"
              min={1000}
              placeholder="Enter outstanding loan amount"
              value={this.props.advanced.outstandingLoanAmount}
              className={'form-control'}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'outstandingLoanAmount'
              )}
            />
            {this.props.errorObj.hasOwnProperty('outstandingLoanAmount') ? (
              <div
                style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
              >
                {this.props.errorObj.outstandingLoanAmount}
              </div>
            ) : null}
          </Col>
        ) : null}

        {this.props.advanced.receivedBankLoan &&
        this.props.advanced.receivedBankLoan !== 'Never taken a loan' &&
        this.props.advanced.receivedLoanProviderType === 'Banks' ? (
          <Col md={4}>
            <label className="control-label">
              Which Banks have provided the loans
            </label>
            <Select
              size="large"
              mode="multiple"
              showSearch
              style={{ width: '100%' }}
              value={this.props.advanced.loanBanks}
              onChange={this.props.changeHandler.bind(
                this,
                'advanced',
                'loanBanks'
              )}
              filterOption={(input, option) => this.searchFilter(input, option)}
            >
              <Option value="">Select Banks</Option>
              {this.state.allBanks.length
                ? this.state.allBanks.map((bank, bankIndex) => {
                    return (
                      <Option key={bankIndex} value={bank}>
                        {bank}
                      </Option>
                    )
                  })
                : null}
            </Select>
          </Col>
        ) : null}

        <Col md={4}>
          <label className="control-label is-imp">
            Candidate's genuineness and humility
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.advanced.genuinenessAndHumility}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'genuinenessAndHumility'
            )}
            defaultActiveFirstOption={false}
          >
            <Option value="" disabled>
              Select Genuineness & Humility
            </Option>
            <Option value="Very genuine and humble">
              Very genuine and humble
            </Option>
            <Option value="Somewhat genuine and humble">
              Somewhat genuine and humble
            </Option>
            <Option value="Not genuine and passionate">
              Not genuine and passionate
            </Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('genuinenessAndHumility') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.genuinenessAndHumility}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Candidate's confidence as a Swavalambi
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.advanced.confidenceAsSwavalambi}
            onChange={this.props.changeHandler.bind(
              this,
              'advanced',
              'confidenceAsSwavalambi'
            )}
          >
            <Option value="" disabled>
              Select confidence as Swavalambi
            </Option>
            <Option value="High">High</Option>
            <Option value="Moderate">Moderate</Option>
            <Option value="Low">Low</Option>
          </Select>
          {this.props.errorObj.hasOwnProperty('confidenceAsSwavalambi') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.confidenceAsSwavalambi}
            </div>
          ) : null}
        </Col>

        <Col md={8}>
          <label className="control-label is-imp">Overall sentiment</label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.comments.overallSentiment}
            onChange={this.props.changeHandler.bind(
              this,
              'comments',
              'overallSentiment'
            )}
          >
            <Option value="" disabled>
              Select sentiment
            </Option>
            {this.state.allSentiments.length
              ? this.state.allSentiments.map((sentiment, sentimentIndex) => {
                  return (
                    <Option key={sentimentIndex} value={sentiment}>
                      {sentiment}
                    </Option>
                  )
                })
              : null}
          </Select>
          {this.props.errorObj.hasOwnProperty('overallSentiment') ? (
            <div
              style={{ color: '#F68787', fontSize: '13px', fontWeight: 600 }}
            >
              {this.props.errorObj.overallSentiment}
            </div>
          ) : null}
        </Col>

        <Col md={4}>
          <label className="control-label is-imp">
            Eagerness to start the enterprise
          </label>
          <Select
            size="large"
            style={{ width: '100%' }}
            value={this.props.comments.tentativeHorizonToCloseThis}
            onChange={this.props.changeHandler.bind(
              this,
              'comments',
              'tentativeHorizonToCloseThis'
            )}
          >
            <Option value="" disabled>
              Select horizon
            </Option>
            {this.state.allHorizons.length
              ? this.state.allHorizons.map((horizon, horizonIndex) => {
                  return (
                    <Option key={horizonIndex} value={horizon}>
                      {horizon}
                    </Option>
                  )
                })
              : null}
          </Select>
        </Col>
      </Row>
    )
  }
}
