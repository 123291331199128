import React, { useState, useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  // Tag,
  Spin,
  Button,
} from 'antd'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { useHttpHook } from '../training-center/hooks/http-hook'
const moment = require('moment')
const SessionSummary = () => {
  const [summary, setSummary] = useState([])
  const {
    isLoading,
    // error,
    sendRequest,
    // clearError
  } = useHttpHook()
  const lastActiveFormatter = (cell, row, index) => {
    if (cell) {
      return <div>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
    }
  }
  const sessionStatusFormatter = (cell, row, index) => {
    if (cell) {
      return (
        <React.Fragment>
          {row.status === 'Active' ? (
            <div style={{ color: 'green' }}>{row.status}</div>
          ) : (
            <div style={{ color: 'red' }}>{row.status}</div>
          )}
        </React.Fragment>
      )
    }
  }
  const ipFormatter = (cell, row, index) => {
    if (row.sessionHistory && row.sessionHistory.length) {
      return (
        <div className="d-flex align-items-center justify-content-between">
          <span>{cell}</span>
          <span className="note">
            {row.sessionHistory.length} more &emsp;
            <i /*style={{fontSize:"20px"}}*/ className="fa fa-arrow-down "></i>
          </span>
        </div>
      )
    } else {
      return cell
    }
    //   if (cell) {
    //     return (
    //       <React.Fragment>
    //         <div
    //         //style={{fontSize:"11px", width:"10px", float:"left"}}
    //         >
    //           {row.ipAddress}
    //         </div>
    //       </React.Fragment>
    //     );
    //   }
    //
  }
  const emailFormatter = (cell, row, index) => {
    if (cell) {
      return (
        <React.Fragment>
          <div style={{ wordBreak: 'break-word' }}>{row.email}</div>
        </React.Fragment>
      )
    }
  }
  const recordedAtFormatter = (cell, row, index) => {
    if (row.recordedAt) {
      return (
        <div className="text-center">
          {moment(row.recordedAt).format('DD/MM/YYYY hh:mm A')}
        </div>
      )
    }
  }
  // const indexFormatter = (cell,row,index)=>{
  //   if (row.recordedAt) {
  //     return (
  //       <div>
  //         {index+1}
  //       </div>
  //     );
  //   }
  // }
  let sessionHistoryColumns = [
    {
      text: '',
      sort: false,
      hidden: true,
      export: true,
      dataField: '_id',
      searchable: false,
    },
    // { sort: true, export: true, hidden: false, text: 'City', searchable: true, dataField: 'city' },
    // { sort: true, export: true, hidden: false, text: 'State', searchable: true, dataField: 'state' },

    //{ sort: true, export: true, hidden: false, searchable: false, text: 'Entry', dataField: 'recordedAt', formatter: indexFormatter },
    {
      sort: true,
      export: true,
      hidden: false,
      searchable: false,
      text: 'Recorded At',
      dataField: 'recordedAt',
      headerStyle: () => {
        return { width: '80px', textAlign: 'center' }
      },
      formatter: recordedAtFormatter,
    },
  ]
  let expandRow = {
    renderer: (row, rIndex) => {
      //console.log(row,rIndex);
      if (row.sessionHistory && row.sessionHistory.length) {
        return (
          <div className="expand-row-container">
            <h4>More logs:</h4>
            <BootstrapTable
              keyField="_id"
              data={row.sessionHistory}
              columns={sessionHistoryColumns}
            />
          </div>
        )
      } else return null
    },
    className: (isExpanded, row, rowIndex) => {
      if (!row.sessionHistory.length) return 'd-none'
      return ''
    },
  }
  // let expandRow = {
  //   renderer: (row, rIndex) => {
  //     if (summary[rIndex]['sessionHistory'] && summary[rIndex]['sessionHistory']) {
  //       return (
  //         <div className="expand-row-container">
  //           <h4>Log:</h4>
  //           <BootstrapTable	keyField="_id" data={row.sessionHistory} columns={sessionHistoryColumns} />
  //         </div>
  //       )
  //     } else return null;
  //   },
  //   className: (isExpanded, row, rowIndex) => {
  //     if(!row.sessionHistory.length) return 'd-none';
  //     return '';
  //   }
  // };

  const columns = [
    {
      dataField: 'ipAddress',
      text: 'IP Address',
      formatter: ipFormatter,
      sort: false,
      hidden: false,
      export: false,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '220px', textAlign: 'center' }
      },
    },
    {
      dataField: 'userName',
      text: 'Full Name',
      //formatter: buttonFormatter,
      sort: true,
      hidden: false,
      export: true,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '80px', textAlign: 'center' }
      },
    },
    {
      dataField: 'email',
      text: 'E-mail ID',
      formatter: emailFormatter,
      sort: true,
      hidden: false,
      export: true,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '80px', textAlign: 'center', wordBreak: 'break-word' }
      },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Active at',
      formatter: lastActiveFormatter,
      sort: true,
      hidden: false,
      export: true,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '80px', textAlign: 'center', wordBreak: 'break-word' }
      },
    },
    {
      dataField: 'city',
      text: 'Last Active City',
      // formatter: lastActiveFormatter,
      sort: true,
      hidden: false,
      export: true,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '50px', textAlign: 'center' }
      },
    },
    {
      dataField: 'state',
      text: 'Last Active State',
      // formatter: lastActiveFormatter,
      sort: true,
      hidden: true,
      export: true,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '40px', textAlign: 'center' }
      },
    },
    {
      dataField: 'status',
      text: 'Session Status',
      formatter: sessionStatusFormatter,
      sort: true,
      hidden: false,
      export: true,
      searchable: true,
      align: 'center',
      headerStyle: () => {
        return { width: '40px', textAlign: 'center' }
      },
    },
  ]

  useEffect(() => {
    const getData = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/session'
        )
        // console.log(responseData);
        if (!responseData.error) {
          setSummary(responseData.data)
        }
      } catch (err) {
        //   alert(err + " " +error);
      }
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="page-container">
      <Spin spinning={isLoading}>
        {!isLoading && summary !== undefined && (
          <>
            <Button>Total Sessions Count : {summary.length}</Button>
            <BootstrapTable
              expandRow={expandRow}
              keyField="_id"
              //rowStyle={rowClassNameFormat}
              // remote={{ pagination: true }}
              bootstrap4
              data={summary}
              columns={columns}
              striped
              hover
              deleteRow
              condensed
              wrapperClasses="table-responsive"
              pagination={paginationFactory()}
            />
          </>
        )}
      </Spin>
    </div>
  )
}

export default SessionSummary
