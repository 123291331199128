/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import Sidebar from './0-menus/Sidebar'
import TopMenu from './0-menus/TopMenu'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import $ from 'jquery'

const readCookie = require('./cookie.js').readCookie
const eraseCookie = require('./cookie.js').eraseCookie
const createCookie = require('./cookie.js').createCookie

// eslint-disable-next-line no-useless-escape
const email_validator =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      userData: null,
      subdomain: null,
      forgotPassword: false,
      showContactSuccess: false,
      forgotPasswordEmail: null,
      submittedForgotPassword: false,
      forgotPasswordEmailValid: true,
    }
  }

  checkUser = () => {
    if (readCookie('userData') !== null) {
      this.setState({ userData: JSON.parse(readCookie('userData')) })
    } else {
      eraseCookie('userData')
      eraseCookie('project_id')
      eraseCookie('access_token')
      this.setState({ userData: null })
      window.location.pathname = ''
    }
  }

  updateUser = (first_name, last_name, profile_picture) => {
    var userData = this.state.userData
    if (first_name !== '') userData['first_name'] = first_name
    if (last_name !== '') userData['last_name'] = last_name
    if (profile_picture !== '') userData['profile_picture'] = profile_picture
    this.setState({ userData: userData })
    eraseCookie('project_id')
    eraseCookie('userData')
    createCookie(
      'userData',
      JSON.stringify({ ...userData, newKey: new Date() }),
      30
    )
  }

  loginUser = e => {
    e.preventDefault()
    let email = $('#login-form #email').val()
    let password = $('#login-form #password').val()

    if (
      email !== '' &&
      email.match(email_validator) !== null &&
      password !== ''
    ) {
      $('#login-spinner').removeClass('d-none')
      $('#login-span').addClass('d-none')

      let data = {
        email, //: 'raysk7161@gmail.com',
        password, //: '96dmy17',
        type: 'sidbi',
      }

      if (this.state.subdomain !== null) data['type'] = this.state.subdomain
      else data['type'] = 'partners'

      fetch(process.env.REACT_APP_API_URL + '/api/v1/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            createCookie(
              'userData',
              JSON.stringify({ ...data['userData'], newKey: new Date() }),
              30
            )
            createCookie(
              'access_token',
              JSON.stringify(data['access_token']),
              30
            )
            console.log(
              'data.userData === === === === >>>>>',
              data.userData.access_token
            )
            if (data.currentProjectDetails) {
              createCookie(
                'currentProjectDetails',
                JSON.stringify({ ...data.currentProjectDetails }),
                30
              )
            }
            if (data['userData'].hasAccessToProjects.length === 1) {
              let project = {}
              project.projectId = data['userData'].hasAccessToProjects[0]
              project.user = data['userData']

              console.log('project ===== >>>> ', project)
              fetch(
                process.env.REACT_APP_API_URL + `/api/v1/project/project-token`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(project),
                }
              )
                .then(data => data.json())
                .then(project => {
                  console.log(
                    'project ==== ===== ===== >>>  >>>>',
                    project.project_token
                  )
                  createCookie(
                    'access_token',
                    JSON.stringify(project['project_token']),
                    30
                  )
                  if (data['userData']) {
                    createCookie(
                      'userData',
                      JSON.stringify({
                        ...data['userData'],
                        newKey: new Date(),
                      }),
                      30
                    )
                  }
                })

              fetch(
                process.env.REACT_APP_API_URL +
                  `/api/v1/project/project-details/${data['userData'].id}`,
                {
                  method: 'GET',
                  headers: {
                    'x-auth-token': JSON.parse(readCookie('access_token')),
                    // data['userData'].id
                  },
                }
              )
                .then(data => data.json())
                .then(data => {
                  if (data.status === 'ok') {
                    createCookie(
                      'project_id',
                      JSON.stringify(data.projects[0]._id)
                    )
                  }
                })
            }
            window.location.reload(true)
          } else if (data.error) {
            $('#login-spinner').addClass('d-none')
            $('#login-span').removeClass('d-none')

            if (data.errorMsg) $('.red-color.error').html(data.errorMsg)
            else $('.red-color.error').html('Oops! Something went wrong')
            $('.red-color.error').css('display', 'block')
          }
        })
    } else {
      if (email === '' || email.match(email_validator) === null) {
        $('#login-form #email').addClass('wrong')
        $('#login-form #email').siblings('label').addClass('wrong')
        $('#login-spinner').addClass('d-none')
        $('#login-span').removeClass('d-none')
      } else if (password === '') {
        $('#login-form #password').addClass('wrong')
        $('#login-form #password').siblings('label').addClass('wrong')
        $('#login-spinner').addClass('d-none')
        $('#login-span').removeClass('d-none')
      }
    }
  }

  logoutUser = () => {
    fetch(process.env.REACT_APP_API_URL + '/logout', {
      method: 'POST',
      headers: {
        'x-auth-token': JSON.parse(readCookie('access_token')),
      },
    })
      .then(function (response) {
        return response.json()
      })
      .then(
        function (data) {
          eraseCookie('userData')
          eraseCookie('project_id')
          eraseCookie('access_token')
          this.setState({ userData: null })
          window.location.pathname = ''
        }.bind(this)
      )
      .catch(function (error) {
        console.log(
          'There has been a problem with your fetch operation: ' + error.message
        )
      })
  }

  setLoginForm = () => {
    $(document).ready(() => {
      $('.form')
        .find('input, textarea')
        .on('keyup blur focus', e => {
          var $this = $(e.target),
            label = $this.prev('label')
          if ($(this).hasClass('wrong')) $(this).removeClass('wrong')
          if (e.type === 'keyup') {
            if ($this.val() === '') label.removeClass('highlight')
            else label.addClass('active highlight')
          } else if (e.type === 'blur') {
            if ($this.val() === '') label.removeClass('active highlight')
            else label.removeClass('highlight')
          } else if (e.type === 'focus') {
            label.addClass('active highlight')
          }
        })

      $('body').click()

      if ($('input#email').val() !== '') {
        if (!$('input#email').siblings('label').hasClass('active'))
          $('input#email').siblings('label').addClass('active')
      }
      if ($('input#password').val() !== '') {
        if (!$('input#password').siblings('label').hasClass('active'))
          $('input#password').siblings('label').addClass('active')
      }
    })
  }

  componentDidMount() {
    var subdomain = ''
    if (
      window.location.hostname.split('.').length === 3 &&
      window.location.hostname.split('.')[0] !== 'www'
    ) {
      subdomain = window.location.hostname.split('.')[0]
    }
    if (subdomain) this.setState({ subdomain })
    this.setLoginForm()
  }

  componentDidUpdate() {
    this.setLoginForm()
  }

  change() {
    if ($('#login-form #email').siblings('label').hasClass('wrong')) {
      $('#login-form #email').siblings('label').removeClass('wrong')
    }
    if ($('#login-form #password').siblings('label').hasClass('wrong')) {
      $('#login-form #password').siblings('label').removeClass('wrong')
    }
  }

  onChangeSwitch() {
    this.setState({
      donorDonationSwitchChecked: !this.state.donorDonationSwitchChecked,
    })
  }

  forgotPassword() {
    this.setState({ forgotPassword: !this.state.forgotPassword })
  }

  changeForgotPasswordEmail(e) {
    this.setState({
      forgotPasswordEmail: e.target.value,
      forgotPasswordEmailValid: email_validator.test(e.target.value),
    })
  }

  forgotPasswordSubmit() {
    if (
      this.state.forgotPasswordEmail !== '' &&
      email_validator.test(this.state.forgotPasswordEmail)
    ) {
      let data = {
        type: 'sidbi',
        email: this.state.forgotPasswordEmail,
        resetUrl: window.location.href + 'reset-password/',
      }
      if (this.state.subdomain !== null) data['type'] = this.state.subdomain
      else data['type'] = 'partners'

      this.setState({ submittedForgotPassword: true })
      fetch(process.env.REACT_APP_API_URL + '/api/v1/user/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          if (data.status) {
            Swal.fire({
              title: 'Request approved!',
              text:
                data.message ||
                'Please check your email. We have sent you a link for resetting your password.',
              type: 'success',
            })
          } else {
            this.notifyError(data.message)
          }
          this.setState({
            forgotPassword: false,
            forgotPasswordEmail: null,
            submittedForgotPassword: false,
          })
        })
    } else {
      this.setState({ forgotPasswordEmailValid: false })
    }
  }

  notifySuccess = text => toast.success(text)
  notifyError = text => toast.error(text)

  render() {
    if (this.state.userData === null) {
      return (
        <div id="login-container">
          <div className="sahaj-front sahaj-front-mobile"></div>
          {(() => {
            if (this.state.forgotPassword === false) {
              return (
                <div className="form form-mobile">
                  <div id="login" style={{ width: '70%', margin: '0 auto' }}>
                    <div id="sahaj-heading">HumbleBee</div>
                    <h1>Admin Panel</h1>
                    <form
                      id="login-form"
                      className="text-center"
                      onSubmit={this.loginUser}
                    >
                      <div className="field-wrap">
                        <label>
                          {' '}
                          Email Address<span className="req">*</span>{' '}
                        </label>
                        <input
                          id="email"
                          type="email"
                          required
                          autoComplete="off"
                          onChange={this.change}
                        />
                      </div>
                      <div className="field-wrap">
                        <label>
                          {' '}
                          Password<span className="req">*</span>{' '}
                        </label>
                        <input
                          id="password"
                          type="password"
                          required
                          autoComplete="off"
                          onChange={this.change}
                        />
                      </div>
                      <div className="field-wrap">
                        <span className="red-color error"></span>
                      </div>
                      <button className="button button-block">
                        <i
                          id="login-spinner"
                          className="fa fa-spinner fa-spin d-none"
                        ></i>
                        <span id="login-span">Log In</span>
                      </button>
                      <a className="forgot">
                        <span
                          id="forgot-span"
                          onClick={this.forgotPassword.bind(this)}
                        >
                          Forgot Password?
                        </span>
                      </a>
                      <img
                        className="mt20"
                        src="/images/le_black.png"
                        height="80"
                        alt=""
                      />
                    </form>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="form forgot form-mobile">
                  <div className="tab-content">
                    <h2>Forgot Password</h2>
                    <div id="forgot-password" className="text-center">
                      <div className="field-wrap">
                        <label>
                          {' '}
                          Email Address<span className="req">*</span>{' '}
                        </label>
                        <input
                          id="email"
                          type="email"
                          required
                          autoComplete="off"
                          onChange={this.changeForgotPasswordEmail.bind(this)}
                        />
                        <span hidden={this.state.forgotPasswordEmailValid}>
                          <span className="required-span">
                            {' '}
                            Incorrect Email entered!{' '}
                          </span>
                        </span>
                      </div>
                      <button
                        className="button button-block"
                        onClick={this.forgotPasswordSubmit.bind(this)}
                      >
                        <span id="login-span">Submit</span>
                        <i
                          className="fa fa-check"
                          hidden={!this.state.submittedForgotPassword}
                        ></i>
                      </button>
                      <h4>
                        Enter your registered email and a new password link will
                        be sent to the registered email.
                      </h4>
                      <a className="forgot">
                        <span
                          id="forgot-span"
                          onClick={this.forgotPassword.bind(this)}
                        >
                          <i className="fa fa-chevron-left"></i> Back
                        </span>
                      </a>
                      <img
                        className="mt20"
                        alt=""
                        src="/images/le_black.png"
                        height="80"
                      />
                    </div>
                  </div>
                </div>
              )
            }
          })()}
        </div>
      )
    } else {
      return (
        <div>
          <Sidebar
            userData={this.state.userData}
            logoutUser={this.logoutUser}
          />
          <TopMenu
            userData={this.state.userData}
            logoutUser={this.logoutUser}
          />
          {React.cloneElement(this.props.children, {
            userData: this.state.userData,
            updateUser: this.updateUser,
          })}
        </div>
      )
    }
  }
}
