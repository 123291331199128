import React, { useState } from "react";
import Modal from "react-modal";

const ChatLogModal = ({ isOpen, onClose, chatLogs }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Chat Logs"
      style={{
        content: {
          maxWidth: "600px",
          maxHeight: "500px",
          margin: "auto",
          padding: "20px",
          borderRadius: "8px",
        },
      }}
    >
      <h2>WhatsApp Chat Logs</h2>
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "transparent",
          border: "none",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        &times;
      </button>
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        {chatLogs.length > 0 ? (
          chatLogs.map((log, index) => (
            <div
              key={log._id}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <p>
                <strong>User Message:</strong> {log.message}
              </p>
              <p>
                <strong>Status:</strong> {log.status} {log.status === "read" && "✅"} {log.status === "sent" && "📦"}
              </p>
              <p>
                <strong>Response:</strong>{" "}
                {typeof log.response === "string"
                  ? log.response
                  : log.response?.eng_response || "No response available"}
              </p>
              <p>
                <strong>Sent At:</strong>{" "}
                {new Date(log.sent_timestamp).toLocaleString()}
              </p>
              {log.read_timestamp && (
                <p>
                  <strong>Read At:</strong>{" "}
                  {new Date(log.read_timestamp).toLocaleString()}
                </p>
              )}
            </div>
          ))
        ) : (
          <p>No chat logs available.</p>
        )}
      </div>
    </Modal>
  );
};

export default ChatLogModal;