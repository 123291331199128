import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import 'antd/dist/antd.css'
import { useForm } from '../../hooks/form-hook'
//import { useHistory } from "react-router-dom";
import Label from '../FormElements/label'
import { SaveOutlined } from '@ant-design/icons'
import { useHttpHook } from '../../hooks/http-hook'
import Input from '../FormElements/Input'
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_PINCODE,
  VALIDATOR_TRAINEDcANDIDATE,
} from '../../util/validators'
import { Spin } from 'antd'
import Swal from 'sweetalert2'

const readCookie = require('../../../cookie.js').readCookie

function CenterModal(props) {
  const [stateload, setStateLoad] = useState()
  const {
    isLoading,
    // error,
    sendRequest,
    clearError,
  } = useHttpHook()
  const [districtload, setDistrictLoad] = useState()
  const userId = JSON.parse(readCookie('userData')).id
  // /* KVIC
  // 																KVIB
  // 																Coir Board
  // 																Municipality
  // 																SLBC */
  const type = [
    'RSETI',
    'PMKK',
    'JSS',
    'DDU-GKY',
    'KVIC',
    'KVIB',
    'Coir Board',
    'Municipality',
    'SLBC',
    'SBI PM SVANidhi',
    'NULM - Sanctioned',
    'Bihar CM Udyami-Yojana',
    'NULM - Yet to be sanctioned',
    // "PMEGP- yet to be sanctioned",
    'PMEGP- Pending',
    'PMEGP- Sanctioned',
    'PMEGP- Rejected',
    'Other-Training-centre',
  ]
  const rating = ['Excellent', 'Average', 'Poor']
  const database = ['Yes', 'Will not share', 'Not yet shared']

  const [formState, inputHandler] = useForm(
    {
      type: {
        value: '',
        isValid: false,
      },
      state: {
        value: '',
        isValid: false,
      },
      district: {
        value: '',
        isValid: false,
      },
      pincode: {
        value: '',
        isValid: false,
      },
      rating: {
        value: '',
        isValid: false,
      },
      database: {
        value: '',
        isValid: false,
      },
      trained: {
        value: '',
        isValid: false,
      },
      employed: {
        value: '',
        isValid: false,
      },
      settlement: {
        value: '',
        isValid: false,
      },
    },
    false
  )

  //const history = useHistory();

  //get states

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/location/states'
        )
        setStateLoad(responseData)
      } catch (err) {}
    }
    const fetchDistrict = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_API_URL + '/api/v1/location/districts'
        )
        //console.log("district  " + JSON.stringify(responseData));
        setDistrictLoad(responseData)
      } catch (err) {}
    }
    fetchStates()
    fetchDistrict()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //end
  const submitFormHandler = async event => {
    event.preventDefault()

    try {
      await sendRequest(
        process.env.REACT_APP_API_URL + '/api/v1/center',
        'POST',
        JSON.stringify({
          type: formState.inputs.type.value,
          state: formState.inputs.state.value,
          district: formState.inputs.district.value,
          pincode: formState.inputs.pincode.value,
          rating: formState.inputs.rating.value,
          database: formState.inputs.database.value,
          trained: formState.inputs.trained.value,
          employed: formState.inputs.employed.value,
          settlement: formState.inputs.settlement.value,
          createdBy: userId,
        }),
        {
          'Content-Type': 'application/json',
          'x-auth-token': JSON.parse(readCookie('access_token')),
        }
      )
    } catch (err) {}
    props.onHide()
    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Successfully created',
      showConfirmButton: false,
      timer: 1500,
    })
    window.location.reload(false)
  }

  return (
    <React.Fragment>
      {/** <Errormodel error = {error} onClear={clearError}  */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create new center
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col sm={4}>
                <Label name="Type" />
                <Input
                  element="Select"
                  id="type"
                  onInput={inputHandler}
                  errorText="Please Select Correctly"
                  validators={[VALIDATOR_REQUIRE()]}
                  option={type}
                  name="type"
                />
              </Col>
              <Col sm={4}>
                <Label name="State" />
                {stateload === undefined && clearError && (
                  <div className="center">
                    {' '}
                    <Spin />{' '}
                  </div>
                )}
                {!isLoading && stateload !== undefined && (
                  <Input
                    element="Select"
                    id="state"
                    onInput={inputHandler}
                    errorText="Please Select Correctly"
                    validators={[VALIDATOR_REQUIRE()]}
                    option={stateload.data.states}
                    name="state"
                    label={'All States'}
                  />
                )}
              </Col>
              <Col sm={4}>
                <Label name="District" />
                {districtload === undefined && clearError && (
                  <div className="center">
                    {' '}
                    <Spin />{' '}
                  </div>
                )}
                {!isLoading && districtload !== undefined && (
                  <Input
                    element="Select"
                    id="district"
                    onInput={inputHandler}
                    errorText="Please Select Correctly"
                    validators={[VALIDATOR_REQUIRE()]}
                    option={districtload.districts}
                    name="district"
                  />
                )}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <Label name="Pincode" />
                <Input
                  element="input"
                  type="text"
                  onInput={inputHandler}
                  id="pincode"
                  placeholder="6-Digit-Pincode"
                  errorText="Pincode must be correct"
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_PINCODE()]}
                />
              </Col>
              <Col sm={4}>
                <Label name="Rating" />
                <Input
                  element="Select"
                  id="rating"
                  onInput={inputHandler}
                  errorText="Please Select Correctly"
                  validators={[VALIDATOR_REQUIRE()]}
                  name="rating/database"
                  option={rating}
                />
              </Col>
              <Col sm={4}>
                <Label name="Database shared ?" />
                <Input
                  element="Select"
                  id="database"
                  onInput={inputHandler}
                  errorText="Please Select Correctly"
                  validators={[VALIDATOR_REQUIRE()]}
                  name="rating/database"
                  option={database}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col sm={4}>
                <Label name="No. of trained candidates every year" />
                <Input
                  element="input"
                  type="text"
                  id="trained"
                  onInput={inputHandler}
                  placeholder="Trained Candidate"
                  errorText="Number is Between 0 - 10000"
                  validators={[
                    VALIDATOR_REQUIRE(),
                    VALIDATOR_TRAINEDcANDIDATE(),
                  ]}
                />
              </Col>
              <Col sm={4}>
                <Label name="Self-employed/Trained candidates (%)" />
                <Input
                  element="input"
                  id="employed"
                  onInput={inputHandler}
                  type="number"
                  min="0"
                  max="100"
                  errorText="Number is Between 0 - 100"
                  validators={[VALIDATOR_REQUIRE()]}
                />
              </Col>
              <Col sm={4}>
                <Label name="Settlement %" />
                <Input
                  element="input"
                  id="settlement"
                  onInput={inputHandler}
                  type="number"
                  min="0"
                  max="100"
                  errorText="Number is Between 0 - 100"
                  validators={[VALIDATOR_REQUIRE()]}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {isLoading ? (
            <div className="center">
              {clearError}
              <Spin size="large" />{' '}
            </div>
          ) : (
            <Button
              disabled={!formState.isValid}
              type="submit"
              onClick={submitFormHandler}
            >
              Save <SaveOutlined />{' '}
            </Button>
          )}
          <Button onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default CenterModal
