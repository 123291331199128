import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePagination, useTable, useSortBy, useFilters } from 'react-table'
import { Link } from 'react-router-dom'
//@ts-ignore
import { getAllBatches } from '../reduxLogic/reducers/batches/getAllBatchesSlice'
//import { BATCH_TABLE_COLUMNS } from "../../shared/Constants/Constants";
import * as Styles from './style'
import { BatchForm } from './components/BatchForm/BatchForm'
import { getAllSuppliers } from '../reduxLogic/reducers/suppliers/getAllSupplierSlice'
import { EditBatchForm } from './components/EditBatchForm/EditBatchForm'
import editIcon from '../assets/images/edit.png'
import deleteIcon from '../assets/images/delete.png'
import { getSingleBatch } from '../reduxLogic/reducers/batches/getSingleBatchSlice'
import { deleteBatch } from '../reduxLogic/reducers/batches/deleteBatchSlice'
import { toast } from 'react-toastify'
import DeleteModal from './components/DeleteModal/DeleteModal'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const BATCH_TABLE_COLUMNS = [
  { Header: 'Batch ID', accessor: 'batchId' },
  { Header: 'Supplier', accessor: 'supplier.supplierName' },
  // { Header: "Date of Order", accessor: "dateOfOrder" },
  {
    Header: 'Date of Delivery',
    accessor: 'dateOfDelivery',
  },
  { Header: 'Added By', accessor: 'addedBy.name' },
  { Header: 'Added On', accessor: 'addedOn' },
  { Header: 'Updated By', accessor: 'updatedBy.name' },
  { Header: 'Updated On', accessor: 'updatedOn' },
  { Header: 'Actions' },

  // { Header: "Batch Items", accessor: "batchItems" },
]

const Batches = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openEditBatchModal, setOpenEditBatchModal] = useState()
  const [openDeleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState()
  const [idToEdit, setIdToEdit] = useState()
  const [singleRow, setSingleRow] = useState(null)

  const { batchData } = useSelector(state => state.allBatches)
  const dispatch = useDispatch()
  const currentToken = cookies.get('access_token')
  const decoded = jwtDecode(currentToken)
  const role = decoded.role

  const fetchAllBatchesData = () => {
    dispatch(getAllBatches())
  }

  const fetchAllSupplierForBatches = () => {
    dispatch(getAllSuppliers())
  }

  useEffect(() => {
    fetchAllBatchesData()
    fetchAllSupplierForBatches()
  }, [dispatch])

  const columns = useMemo(() => BATCH_TABLE_COLUMNS, [])
  const data = useMemo(() => batchData, [batchData])

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },

    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance

  const { pageIndex, filters } = state

  const handleModalToggle = () => {
    setOpenModal(prev => !prev)
  }

  const changeDateFormat = input => {
    const date = new Date(input)
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    }
    const formattedDate = date.toLocaleString('en-US', options)
    return formattedDate
  }

  const handleOpenDeleteModal = id => {
    setDeleteModal(true)
    setIdToDelete(id)
  }

  const handleOpenEditModal = (id, singleRow) => {
    console.log('singleRow', singleRow)
    setIdToEdit(id)
    // dispatch(getSingleBatch(id));
    setOpenEditBatchModal(true)
    setSingleRow(singleRow)
  }

  return (
    <Styles.BatchBox>
      <Styles.BatchTopSection>
        <Styles.ModalButton onClick={handleModalToggle}>
          Create Batch
        </Styles.ModalButton>
        {openModal ? (
          <BatchForm openModal={openModal} setOpenModal={setOpenModal} />
        ) : null}
      </Styles.BatchTopSection>
      <Styles.TableContainer>
        <Styles.Table {...getTableProps()}>
          <Styles.THead>
            {headerGroups.map(headerGroup => (
              <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(headerColumn => (
                  <Styles.TH
                    {...headerColumn.getHeaderProps(
                      headerColumn.getSortByToggleProps()
                    )}
                  >
                    {headerColumn.render('Header')}

                    <span>
                      {headerColumn.isSorted
                        ? headerColumn.isSortedDesc
                          ? ' 🔼'
                          : ' 🔽'
                        : ''}
                    </span>
                  </Styles.TH>
                ))}
              </Styles.THeadRow>
            ))}
          </Styles.THead>

          <Styles.TBody {...getTableBodyProps()}>
            {page.map((singleRow, index) => {
              prepareRow(singleRow)

              return (
                <Styles.TBodyRow {...singleRow.getRowProps()}>
                  {singleRow.cells.map((singleCell, index) => {
                    return (
                      <Styles.TD key={index}>
                        {singleCell.column.id == 'dateOfDelivery' ? (
                          <span>
                            {singleCell.value
                              ? changeDateFormat(singleCell.value)
                              : '-'}
                          </span>
                        ) : (
                          <span>
                            {singleCell.column.id == 'addedOn' ||
                            singleCell.column.id == 'updatedOn' ? (
                              <span>{changeDateFormat(singleCell.value)}</span>
                            ) : (
                              <span>
                                {singleCell.column.id == 'batchId' ? (
                                  <Link
                                    to={`/batches/${singleCell?.row?.original?._id}`}
                                  >
                                    {singleCell.render('Cell')}
                                  </Link>
                                ) : (
                                  <span>
                                    {singleCell.column.Header === 'Actions' ? (
                                      <Styles.ActionSpan>
                                        <button
                                          onClick={() =>
                                            handleOpenEditModal(
                                              singleCell?.row?.original?._id,
                                              singleRow?.original
                                            )
                                          }
                                        >
                                          <img src={editIcon} alt="Edit" />{' '}
                                        </button>
                                        {role == 'Admin' ? (
                                          <button
                                            onClick={() =>
                                              handleOpenDeleteModal(
                                                singleCell?.row?.original?._id
                                              )
                                            }
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt="Delete"
                                            />
                                          </button>
                                        ) : null}
                                      </Styles.ActionSpan>
                                    ) : (
                                      <span>{singleCell.render('Cell')}</span>
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        )}
                      </Styles.TD>
                    )
                  })}
                </Styles.TBodyRow>
              )
            })}
          </Styles.TBody>
        </Styles.Table>
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page{''} {pageIndex + 1} of {pageOptions.length}{' '}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span> {'<<'} </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <span> Previous</span>
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(jumpToPageNumber)
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            <span>Next </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span>{'>>'} </span>
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>

      {openEditBatchModal ? (
        <EditBatchForm
          openEditBatchModal={openEditBatchModal}
          setOpenEditBatchModal={setOpenEditBatchModal}
          idToEdit={idToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      ) : null}

      {openDeleteModal ? (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      ) : null}
    </Styles.BatchBox>
  )
}

export default Batches
